import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Select, Spin, message } from "antd";
import { urlapi } from "../../lib/backend/data";
import { cerrarSesion } from "../../redux/actions/sesion";
import { ArrowDownOutlined, SearchOutlined } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import SelectorZona from "./selector_zona";
import { paises_nombres, paises_sistema } from "../../lib/global/data";
import Title from "antd/es/typography/Title";

const SelectorZonaAvanzadoInternacional = (props) => {
    const {
        defaultValue,
        titleLabel,
        title,
        condicion,
        error
    } = props
    const titulo_boton = titleLabel ? titleLabel : "SELECCIONAR ZONA"
    const [ loading, setLoading ] = useState(true)
    const [ value, setValue ] = useState(false)
    const [ geocerca, setGeocerca ] = useState(false)
    const [ geocerca2, setGeocerca2 ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ opcionesComplete, setOpcionesComplete ] = useState([])
    const [ opciones, setOpciones ] = useState([])
    const [ valorSeleccionado, setValorSeleccionado ] = useState(false)
    const pais = useSelector(state => state.pais)
    const [ paisSeleccionado, setPaisSeleccionado ] = useState(pais)
    const [ zonasAdministrativas, setZonasAdministrativas ] = useState([])
    const [ loadingStructure, setLoadingStructure ] = useState(true)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const dispatch = useDispatch()

    const obtenerEstructuraPais = async (pais_code) => {
      setLoadingStructure(true)
        return fetch(`${urlapi}/geodata/zonas/list/structure`, {
          method: 'POST',
          body: JSON.stringify({
            pais_code
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              setZonasAdministrativas(res.map(e => ({ local_name: e })))
            }
            return setLoadingStructure(false);
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingStructure(false);
          })
      }

      const obtenerDatos = async (cond) => {
        const query = cond ? cond : { }
        return fetch(`${urlapi}/geodata/zonas/list/select`, {
          method: 'POST',
          body: JSON.stringify({
            condicion: query
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              setOpcionesComplete(res)
              setOpciones(res.filter(m => m.titulo).map(op => ({ value: op._id, label: op.titulo.toUpperCase() })))
              setIsModalOpen(false)
            }
            return setLoading(false);
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }

      const obtenerDatosPorId = async () => {
        setLoadingDetalle(true)
        return fetch(`${urlapi}/geodata/zonas?id=${valorSeleccionado._id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(res._id){
                if(props.onSelected) props.onSelected(res)
                setValorSeleccionado(false)
                handleCancel()
                setValue(false)
            }
            return setLoadingDetalle(false);
          })
          .catch((error) => {
            console.log(error)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingDetalle(false);
          })
      }

      const onChangeValue = (e) => {
        const i = opcionesComplete.findIndex(m => m._id === e)
        if(props.onChange) props.onChange(opcionesComplete[i])
        setValorSeleccionado(opcionesComplete[i])
        setValue(e)
      }
      
      const onChangeValueNivel2 = (e) => {
        console.log(e)
        if(props.onSelected) props.onSelected(e)
        handleCancel()
        setValue(false)
        setValorSeleccionado(false)
      }

      useEffect(() => {
        // obtenerDatos(condicion)
        obtenerEstructuraPais(pais)
      }, [  ])

      const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };

      const tercerSelector = () => {
        switch (pais) {
          case "CL":
            return false
            case "BR":
              if(!geocerca) return false
              let nueva_condicion_br = { local_name: "municipio" }
              if(geocerca.properties){
                  if(typeof geocerca.properties === "object"){
                    console.log(geocerca.properties)
                      if(geocerca.sigla) nueva_condicion_br['properties.microrregiao.mesorregiao.UF.sigla'] = geocerca.sigla
                  }
              }
              return <SelectorZona title="Municipio" condicion={nueva_condicion_br} onChange={(data) => setGeocerca2(data)} title_button="AGREGAR ESTA ZONA" onSelected={(data) => onChangeValueNivel2(data)} />
          default:
            break;
        }
      }
      const segundoSelector = () => {
        switch (pais) {
          case "CL":
            if(!valorSeleccionado) return false
            let nueva_condicion = { local_name: "comuna" }
            if(valorSeleccionado.properties){
                if(typeof valorSeleccionado.properties === "object"){
                    if(valorSeleccionado.properties.codregion) nueva_condicion['properties.codregion'] = valorSeleccionado.properties.codregion
                }
            }
            return <SelectorZona title="Comuna" condicion={nueva_condicion} onChange={(data) => setGeocerca(data)} title_button="AGREGAR ESTA ZONA" onSelected={(data) => onChangeValueNivel2(data)} />
            case "BR":
              if(!valorSeleccionado) return false
              let nueva_condicion_br = { local_name: "estado" }
              console.log(valorSeleccionado.properties.sigla)
              if(valorSeleccionado.properties){
                  if(typeof valorSeleccionado.properties === "object"){
                      if(valorSeleccionado.properties.sigla) nueva_condicion_br['properties.regiao.sigla'] = valorSeleccionado.properties.sigla
                  }
              }
              return <SelectorZona title="Estado" condicion={nueva_condicion_br} onChange={(data) => setGeocerca(data)} title_button="AGREGAR ESTA ZONA" onSelected={(data) => onChangeValueNivel2(data)} />
          default:
            break;
        }
      }

      const changeCountry = (pais_code) => {
        setPaisSeleccionado(pais_code)
        obtenerEstructuraPais(pais_code)
      }

      const onSelected = (data) => {
        if(props.onSelected) props.onSelected(data)
        setValorSeleccionado(false)
        handleCancel()
        setValue(false)
      }

      const mostrarOpcionesEstructura = () => {
        if(loadingStructure) return <Spin />
        return <div>
          <Row gutter={15}>          
            {
              zonasAdministrativas.map((zona, i) => {
                return <Col md={12} key={`zona-${i}`}>
                  <Title level={3} className="mt-0 mb-0" style={{ textTransform: "uppercase" }}>{zona.local_name}</Title>
                  <SelectorZona  title={zona.local_name} condicion={{ local_name: zona.local_name, pais_code: paisSeleccionado }} onChange={(data) => console.log(data)} title_button="SELECCIONAR" onSelected={(data) => onSelected(data)} />
                </Col>
              })
            }
          </Row>
        </div>
      }

      const mostrarContenido = () => {
        return <div>
            <Form layout="vertical">
                <Form.Item label="País">
                <Select defaultValue={pais} options={paises_sistema.map(p => ({ value: p, label: paises_nombres[p] }))} onChange={(e) => changeCountry(e)} />
                </Form.Item>
            </Form>

          <Title level={2} className="mt-0 mb-0"><ArrowDownOutlined /> Selecciona una zona</Title>
            <Paragraph>Busca </Paragraph>
            {mostrarOpcionesEstructura()}
            {/* <Form layout="vertical" className="mb-0">
            <Row gutter={10}>
                <Col md={14}>
                <Form.Item label={title ? title : "Región"}>
                <Select
                    value={value}
                    style={ error === true ? {
                        width: "100%",
                        border: "1.5px solid red",
                        borderRadius: 8
                    } : { width: "100%" }}
                    showSearch={true}
                    filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                    loading={loading}
                    options={opciones}
                    onChange={onChangeValue}
                    />
                    </Form.Item>
                    {segundoSelector()}
                    {tercerSelector()}
                </Col>
                <Col md={10}>
                    <Form.Item label="Click para agregar zona">
                    <Button disabled={valorSeleccionado ? false : true } loading={loadingDetalle} type="primary" onClick={() => obtenerDatosPorId()}>AGREGAR ESTA ZONA</Button>
                    </Form.Item>
                </Col>
            </Row>
            
            </Form> */}
        </div>
      }

      return <div className="mb-5">
        {contextHolder}
        <Button onClick={showModal} style={{ width: "100%" }} icon={<SearchOutlined />} >{titulo_boton}</Button>
        <Modal width={"80%"} className="mb-0" open={isModalOpen} footer={[]} onCancel={handleCancel}>
            {mostrarContenido()}
      </Modal>

        
      </div>
}

export default SelectorZonaAvanzadoInternacional