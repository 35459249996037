import React, { useEffect } from 'react'
import { cerrarSesion } from '../../redux/actions/sesion'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../Header'
import Structure from '../Structure'
import Title from 'antd/es/typography/Title'
import { Avatar, Button, Card, Col, List, Pagination, Progress, Row, Segmented, Statistic, Tabs, Tooltip } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import { ArrowUpOutlined, CarOutlined, ClockCircleOutlined, GatewayOutlined, HeatMapOutlined, IdcardOutlined, NotificationOutlined, PlusCircleFilled } from '@ant-design/icons'
import MapaGeneral from '../Mapas/mapa_general'
import MapaGeocercas from '../Mapas/mapa_geocercas'
import MapaGeocercasAlert from '../Mapas/mapa_geocercas_alert'
import MapaGeocercasRedZones from '../Mapas/mapa_geocercas_red_zones'
import { Link } from 'react-router-dom'
import { rutas } from '../../lib/routes/routes'
import PorcentajeProgresoEmpresa from '../Empresa/porcentaje'
import { stringByStatusBusiness } from '../../lib/helpers/data/empresas'
import MapaZonasOperacionMantenedor from '../Mapas/operacion/mapa_mantenedor_operacion'
import { validarDNIPorPais } from '../../lib/helpers/data/internationa'
import ListadoAlertas from '../GeoAlertas/listado'
import MapaZonasAlertasMantenedor from '../Mapas/alertas/mapa_alertas'

const GeoAlertasMapa = (props) => {
  const dispatch = useDispatch()
  const { data, tokenSession } = useSelector(state => state.miusuario)
  const empresa = data.empresa
  
  const lista = [];

  const items = [
    {
      key: "3",
      label: "Alertas recientes",
      children: <MapaZonasAlertasMantenedor />
    },
  ]
  
  useEffect(() => {

  }, [])

  const mostrarNotificaciones = () => {

    const tabs_notificaciones = [
      {
        key: '1',
        label: `NOTIFICACIÓN`,
        children: <ListadoAlertas typeView="simple" condicion_default={{ tipo_notificacion: "info" }} />,
      },
      {
        key: '2',
        label: `ADVERTENCIAS`,
        children: <ListadoAlertas typeView="simple" condicion_default={{ tipo_notificacion: "warning" }} />,
      },
      {
        key: '3',
        label: `CRITICAS`,
        children: <ListadoAlertas typeView="simple" condicion_default={{ tipo_notificacion: "error" }} />,
      },
    ];

    return <div>
      <Tabs defaultActiveKey="1" items={tabs_notificaciones} />
    </div>
  }

  const render = () => {
    return <div style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}>
        <MapaZonasAlertasMantenedor />
    </div>
  }

  return <Structure component={render()} />

}

export default GeoAlertasMapa