import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Button, Card, Col, Pagination, Row, Tabs, message } from 'antd';
import { FileExcelFilled, PlusCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import HelperModal from "../../subComponents/general/helperModal";
import ListadoRutas from "./listado";

const Rutas = (props) => {
    const [ loading, setLoading ] = useState(false)
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const idioma                    = useSelector(state => state.idioma)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const dispatch                  = useDispatch()
    const sesion                    = useSelector(state => state.miusuario)
    const module                    = 'rutas'
    const titulo_module             = 'Rutas'

    const crearViaje = async ()=>{
        setLoadingCreacion(true)
        return fetch(`${urlapi}/${module}/draft`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                window.location = `${rutas.rutas.slug}/new/${res._id}`
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingCreacion(false)
        })
    }

    const render = () => {
        return <div>
            <Card>
            <Title className="mb-2 mt-0">{titulo_module}</Title>
            <Row gutter={15}>
              <Col span="auto">
                    <Link to={`${rutas.import_trips.slug}`}><Button style={{ marginRight: 10 }} type="primary" size="small" loading={loadingCreacion} ><PlusCircleFilled /> CREAR NUEVO</Button></Link>
              </Col>
              {/* <Col span="auto"><Button style={{ marginRight: 10 }} size="small"><Link to={rutas.import_trips.slug}><FileExcelFilled /> IMPORTADOR</Link></Button></Col> */}
              <Col span="auto"><HelperModal tipo={module} style="modal" /></Col>
            </Row>
            <ListadoRutas showSelection={true} showCustomFieldsData={true} showCounters={false} showFilters={true} condicion_default={{ }} />
            </Card>
        </div>
    }
    
    return <Structure component={render()} />
}

export default Rutas