import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Form, Row, Select, Spin, message } from "antd";
import debounce from 'lodash/debounce';
import { urlapi } from "../../lib/backend/data";
import { cerrarSesion } from "../../redux/actions/sesion";

function DebounceSelect({ condicion, llave, fetchOptions, onChange, debounceTimeout = 800, ...props }) {
  const [ value, setValue ] = useState(null)
  const [fetching, setFetching] = useState(true);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      if(!value){
        fetchOptions({...condicion}).then((newOptions) => {
          setOptions(newOptions);
          setFetching(false)
        })
      } else {
        fetchOptions({...condicion, razon_social: { $regex: value, $options: "i" } }).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(newOptions);
          setFetching(false);
        })
      }
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const init = async () => {
      const data = await fetchOptions(condicion)
      if(data) if(Array.isArray(data) !== false) setOptions(data)
      setFetching(false)
      let seleccionado = 'Cargando datos...'
      let opciones_selector = data
      if(props.value ){
        const i_val = opciones_selector.findIndex(e => e.value === props.value)
        if(i_val > -1) seleccionado = opciones_selector[i_val]
        console.log("Seleccionado", { opciones_selector, seleccionado, val: props.value })
      }
      
      if(seleccionado === "Cargando datos...") seleccionado = null

      setValue(seleccionado)
  }
  useEffect(() => {
    init()
  }, [])

  const onChangeValue = (e) => {
    setValue(e)
    onChange(e)
  }

  return <div>
    <Select
      value={value}
      key={llave}
      allowClear
      loading={fetching}
      filterOption={false}
      showSearch
      mode={props.mode ? props.mode : "default"}
      onSearch={debounceFetcher}
      onChange={onChangeValue}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options}
    />
  </div>
}


const SelectorClientes = (props) => {
    const {
        value,
        titulo,
        condicion,
        isMulti,
        key
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ opcionesComplete, setOpcionesComplete ] = useState([])
    const [ opciones, setOpciones ] = useState([])
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()
    const llave = key ? `${titulo}-${key}` : `${titulo}`
    const module = 'clientes'

     const obtenerRegistros = async (cond) => {
        return fetch(`${urlapi}/${module}/list/select`, {
          method: 'POST',
          body: JSON.stringify({
            condicion: cond
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              return res.filter(m => m.razon_social).map(op => ({ value: op._id, label: op.razon_social.toUpperCase() }))
            }
            return []
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return []
          })
      }

      const onChangeValue = (e) => {
        const i = opcionesComplete.findIndex(m => m._id === e)
        if(props.onChange) props.onChange(opcionesComplete[i])
        // setValorSeleccionado(opcionesComplete[i])
      }

      useEffect(() => {
        // obtenerDatos()
      }, [ condicion ])

      let valor_defecto = value
      if(valor_defecto){
        const i = opciones.findIndex(p => p.value === value)
        if(i > -1){
          valor_defecto = opciones[i]
        }
      }

      return <div>
        {contextHolder}
        <Form layout="vertical">
            <Form.Item label={titulo ? titulo : "Selecciona"}>
            <DebounceSelect
            llave={llave}
            mode={isMulti===true ? "multiple" : false}
            value={valor_defecto}
            condicion={condicion}
            // placeholder="Select users"
            fetchOptions={obtenerRegistros}
            onChange={(newValue) => {
              if(props.onChange) props.onChange(newValue)
              // setValue(newValue);
            }}
            style={{
              width: '100%',
            }}
          />
                    </Form.Item>
            </Form>
      </div>
}

export default SelectorClientes