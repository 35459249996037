import { Tooltip } from "antd"
import { IoMdRemoveCircle } from "react-icons/io"
import { useDispatch, useSelector } from "react-redux"
import { modificarInterfaz } from "../../../redux/actions/interfaz"
import { TbHelpCircleFilled } from "react-icons/tb"
import Paragraph from "antd/es/typography/Paragraph"
import { Esquema } from "../../../lib/esquemas/tablas"

const EncabezadoPesonalizado = (props) => {
    const {
        key_interfaz,
        key_esquema,
        slug
    } = props
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const session                   = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    const obtenerDetallesEsquema = (campo) => {
        const i = Esquema[ key_esquema ].findIndex(e => e.key === campo)
        if( i > -1){
            return {
                ...Esquema[ key_esquema ][i],
                llave: Esquema[ key_esquema ][i].key
            }
        }
        return {}
    }


    const model = obtenerDetallesEsquema(slug)
    if(!model) return <div></div>
    const { label , helptext } = model

    

    const activarDesactivarTabla = (value, column) => {
        const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario) )
        const i = instancia_esquema[key_interfaz].findIndex(e => e.key === column.llave)
        if(i > -1){
            instancia_esquema[key_interfaz][i].active = value
        }
        console.log({ instancia_esquema, i, column })
        return dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
    }


    const object_delete = <Tooltip title={"Ocultar columna"}><IoMdRemoveCircle style={{ verticalAlign: "middle" }} size={15} className="hover" onClick={() => activarDesactivarTabla(false, model)} /></Tooltip>

    if(helptext) {
        return <div>
        <Tooltip title={helptext}>
        <Paragraph className="mb-0 mt-0">{label} <TbHelpCircleFilled /></Paragraph>
        </Tooltip>
        {object_delete}            
        </div>
    }

    return <>
        <Paragraph className="mb-0 mt-0">{object_delete} {label}</Paragraph>
    </>
}

export default EncabezadoPesonalizado