import { Form, Select } from "antd"
import { tipos_notificacion_alertas } from "./data"

const SelectorTipoAlerta = (props) => {
    const {
        defaultValue
    } = props

    const handleChange = (data) => {
        if(props.onChange) props.onChange(data)
    }

    return <Form layout="horizontal" className="mb-0">
        <Form.Item label="Tipo de alerta" >
        <Select options={tipos_notificacion_alertas} onChange={handleChange} allowClear onClear={handleChange} />
        </Form.Item>
    </Form>
}

export default SelectorTipoAlerta