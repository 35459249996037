import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Button, Card, Divider, FloatButton, Input, List, Modal, Select, Spin, Table, Tabs, Tooltip, Typography, message, notification } from 'antd';
import { ArrowDownOutlined, DeleteOutlined, FileExcelFilled, InboxOutlined, PlusCircleFilled } from "@ant-design/icons";
import * as XLSX from 'xlsx'
import { TbAlertCircleFilled, TbCircleCheckFilled, TbHelpCircleFilled } from "react-icons/tb"
import { BiUpload } from "react-icons/bi"
import { formatoAyudaDNIPorPais, formatoAyudaMovilPorPais, nombreDNIPorPais } from "../../lib/helpers/data/internationa";
import { useDispatch, useSelector } from "react-redux";
import Dragger from "antd/es/upload/Dragger";
import React,{ useEffect, useMemo, useState } from "react";
import Column from "antd/es/table/Column";
import { urlapi } from "../../lib/backend/data";
import { cerrarSesion } from "../../redux/actions/sesion";
import Paragraph from "antd/es/typography/Paragraph";
import { fechaATexto, validateEmail } from "../../lib/helpers/helpers";
import { obtenerPrefijoMovil, validarLongitudNumeroMovil } from "../../lib/helpers/data/moviles";
import ExcelHelper from "../General/excel_helper";
  
const ImportadorConductores = (props) => {
    const [api, contextHolderPush] = notification.useNotification();
    const a = fechaATexto
    const [ showUploadBox, setShowUploadBox ] = useState(true)
    const [ procesados, setProcesados ] = useState(0)
    const [ modalProgress, setModalProgess ] = useState(false)
    const [ modalAsignacionColumna, setModalAsignacion ] = useState(false)
    const [ datos, setDatos ] = useState([])
    const [ llaveAsignar, setLlaveAsignar ] = useState(false)
    const [ validando, setValidando ] = useState(false)
    const [ datosExcelSinProcesar, setDatosExcelSinProcesar ] = useState([])
    const [ errores, setErrores ] = useState([])
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ columnasExcel, setColumnasExcel ] = useState([])
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [messageApi, contextHolder] = message.useMessage();

    

    const openNotification = (message, description) => {
        api.info({
            message,
          description,
          placement: "bottom",
        });
    }
    
    const esquema = [
        { value:'nombres', label: 'Nombres' },
        { value:'apellido_p', label: 'Primer Apellido' },
        { value:'apellido_m', label: 'Segundo Apellido' },
        { value:'rut', label: nombreDNIPorPais(pais), helptext: formatoAyudaDNIPorPais(pais) },
        { value:'sexo', label: 'Sexo', options: ["M","H"], denieUpload: true },
        { value:'email', label: 'Email' },
        { value:'phone', label: 'Móvil', helptext: formatoAyudaMovilPorPais(pais) }
    ]

    const obtenerNombrePorLlaveIdioma = (key) => {
        const i = esquema.findIndex(e => e.value === key)
        if(i > -1){
            return esquema[i].label
        }
        return key
    }

    const obtenerTextoAyudaPorLlaveIdioma = (key) => {
        const i = esquema.findIndex(e => e.value === key)
        if(i > -1){
            return esquema[i].helptext
        }
        return key
    }
    
    const obtenerEsquemaPorLlave = (key) => {
        const i = esquema.findIndex(e => e.value === key)
        if(i > -1){
            return esquema[i]
        }
        return key
    }

    const procesarExcel = (target) => {

        let reader = new FileReader()
            reader.readAsArrayBuffer(target)
            let hojas = []
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array', cellDates:true, dateNF:'dd.mm.yyyy' });
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })
            console.log(hojas)
            if(!hojas[0]) return messageApi.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return messageApi.error('No se encontraron datos')
            if(hojas[0].length < 1) return messageApi.error('No se encontraron datos')
            if(!hojas[0][0]) return messageApi.error('No se encontraron datos')
            
            let errores             = []
            let registros           = []
            let nombres_columnas    = []
            
            if(errores.length > 0) return messageApi.error(errores.join(', ')) // SI HAY ERRORES DETENER

            let pos = 0
            for( const hoja of hojas[0] ){

                // RECOPILAMOS LAS LLAVES DEL EXCEL
                Object.keys(hoja).map(key => {
                    const llave_limpia = key.toLocaleLowerCase()
                    if(!nombres_columnas.includes(llave_limpia)) nombres_columnas.push(llave_limpia)
                })

                pos++
                let objeto = {}
                for( const key of esquema ){
                    if(key.denieUpload === true) continue
                    const llaves_obtenidas = Object.keys(hoja)
                    const i = llaves_obtenidas.findIndex(llave => llave.toLowerCase() === key.label.toLocaleLowerCase())
                    if(i < 0){
                        if(key.optionalUpload === true) continue
                        errores.push(`${key.label} no encontrado en la columna ${pos}`)
                        continue
                    } else {

                        const valor = hoja[ llaves_obtenidas[i] ]

                        if(key.options){
                            if(key.options.length > 0){
                                if(!key.options.includes(valor)){
                                    errores.push(`${key.label} no encontrado en la columna ${pos}`)
                                    continue
                                }
                            }
                        }
                        objeto[key.value] = valor
                    }
                }
                registros.push(objeto)
            }

            if(errores.length > 0){
                openNotification('Observaciones encontradas en tu archivo', "No te preocupes, puedes corregiros ahora")
            } else {
                openNotification("¡Atención!","Debes completar algunos campos ahora desde la pantalla, ¡adelante! ya casi estás")
            }

            setColumnasExcel(nombres_columnas)
            const limpiar_llaves_para_filtro = hojas[0].map(info => {
                let objeto_limpio = {}
                Object.keys(info).map(ll => {
                    objeto_limpio[ll.toLowerCase()] = info[ll]
                })
                return objeto_limpio
            })
            setDatosExcelSinProcesar(limpiar_llaves_para_filtro)
            setShowUploadBox(false)
            
            return setDatos(registros)
        }
    }

    const generarExcelConDropdown = () => {
        // Crear un nuevo libro de Excel
        const workbook = XLSX.utils.book_new();
        const sheetName = 'Hoja 1';
      
        // Crear los datos para el dropdown y la hoja de cálculo
        const opcionesDropdown = ['Opción 1', 'Opción 2', 'Opción 3'];
      
        const data = [
          ['Nombre', 'Edad', 'Opción'],
          ['Juan', 30, ''], // Deja la celda vacía para que sea una celda editable
          ['María', 25, ''],
          ['Carlos', 28, ''],
        ]
      
        // Agregar los datos a la hoja de cálculo
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
      
        // Crear un objeto de validación de datos para el dropdown
        const dropdownValidation = {
          type: 'list',
          formulae: [`"${opcionesDropdown.join(',')}"`], // Convertir el array de opciones a una lista separada por comas y envolver en comillas dobles
        };
      
        // Establecer la validación de datos en la columna 'C' para todas las filas excepto la primera (encabezado)
        const range = { s: { c: 2, r: 1 }, e: { c: 2, r: data.length } }; // C2 hasta Cn (donde n es el número de filas de datos)
        worksheet['!dataValidation'] = [dropdownValidation];
        worksheet['!dataValidation'][0].range = range; // Establecer el rango de validación de datos
      
        // Crear un buffer con el archivo de Excel
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      
        // Convertir el buffer a un Blob solo si se generó correctamente
        if (excelBuffer) {
          const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
          // Crear un objeto de URL para el Blob
          const excelURL = URL.createObjectURL(excelBlob);
      
          // Crear un enlace temporal para iniciar la descarga
          const link = document.createElement('a');
          link.href = excelURL;
          link.download = 'datos_con_dropdown.xlsx';
      
          // Simular un clic en el enlace para iniciar la descarga
          link.click();
      
          // Liberar el objeto de URL
          URL.revokeObjectURL(excelURL);
        } else {
          console.error('Error al generar el archivo Excel');
        }
      };
      const handleDownload = () => {
        return generarExcelConDropdown()
        // Crear el archivo de Excel
        const wb = XLSX.utils.book_new();
        const wsData = [ esquema.filter(e => !e.denieUpload).map(e => e.label) ]
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
    
        // Convertir el libro de trabajo a un archivo binario
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    
        // Crear un Blob con los datos binarios
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    
        // Generar un objeto URL del Blob
        const url = URL.createObjectURL(blob);
    
        // Crear un enlace de descarga invisible y hacer clic en él
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Modelo Conductores.xlsx';
        a.click();
    
        // Liberar el objeto URL
        URL.revokeObjectURL(url);
      };
    
      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };

      const props_file_upload = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        onChange(e) {
            if(e.event) return false
            procesarExcel(e.file.originFileObj)
        }
      };
    
      const handleChange = (e,pos) => {
        const { name, value } = e.target
        console.log({ name, value, pos })
        datos[pos][name] = value
        console.log(datos[pos])
        return setDatos([...[], ...datos])
      }
      const handleChangeSelector = (e,pos,name) => {
        datos[pos][name] = e
        return setDatos([...[], ...datos])
      }

      const revisarLlavesFuncionPersonalizada = (registros, llave, funcion, params) => {
        let invalidos = []
        for( const registro of registros ){
            if(params){
                if(Array.isArray(params) !== false){
                    if(params.length > 0){
                        if(!funcion(registro[llave].toString(), ...params)) invalidos.push(registro[llave].toString())
                    }
                }
            } else {
                if(!funcion(registro[llave])) invalidos.push(registro[llave])
            }
        }
        return invalidos
      }

      const subirInformacion = async () => {
        const instancia_datos = JSON.parse( JSON.stringify(datos))

        const invalidos = instancia_datos.filter(dato => {
            for( const key of esquema ){
                const llaves_obtenidas = Object.keys(dato)
                const i = llaves_obtenidas.findIndex(llave => llave.toLowerCase() === key.value.toLocaleLowerCase())
                if(i < 0) return true
            }
            return false
        })

        
        if(invalidos.length > 0){
            return openNotification("Error", "Tienes registros inválidos, corrije la información e intenta de nuevo")
        }
        
        const email_invalidos = revisarLlavesFuncionPersonalizada(instancia_datos, 'email', validateEmail)
        if(email_invalidos.length > 0){
            return openNotification(`${obtenerNombrePorLlaveIdioma("email")} inválidos`, `Tienes registros inválidos, corrije la información e intenta de nuevo. Registros encontrados: ${email_invalidos.join(', ')}`)
        }        
        
        const numeros_invalidos = revisarLlavesFuncionPersonalizada(instancia_datos, 'phone', validarLongitudNumeroMovil, [pais])
        if(numeros_invalidos.length > 0){
            return openNotification(`${obtenerNombrePorLlaveIdioma("phone")} inválidos`, `Tienes registros inválidos, corrije la información e intenta de nuevo. Registros encontrados: ${numeros_invalidos.join(', ')}`)
        }

        let validations = []
        for( const u of datos){
            validations = [...validations, ...[
                { key: "rut", value: u.rut.toString() },
                { key: "phone", value: `${obtenerPrefijoMovil(pais)}${u.phone.toString()}` },
                { key: "email", value: u.email.toLowerCase() }
            ]]
        }
        const validacion_previa = await validarCampos(validations)
        if(!validacion_previa) return setValidando(false)

        setModalProgess(true)
        for( const user of datos ){
            await crearUsuairoIndividual(user)
        }
        setModalProgess(false)
        setTimeout(() => {
            setProcesados(0)
        }, 500);
        openNotification("Proceso finalizado", "Datos cargados exitosamente")
        setShowUploadBox(true)
        setDatos([...[], ...[]])
        return setDatosExcelSinProcesar([...[], ...[]])
      }

      const validarCampos = async (arreglo)=>{
        setErrores([...[], ...[]])
        setValidando(true)
        return fetch(`${urlapi}/conductores/validate`, {
            method:'POST',
            body: JSON.stringify(arreglo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            setValidando(false)
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos del servidor')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(typeof res.success !== "undefined"){
                if(res.success === true){
                    return true
                } else {
                    if(res.errors){
                        if(Array.isArray(res.errors) !== false){
                            const problemas_encontrados = res.errors.map(error => {
                                const mensaje = `${obtenerNombrePorLlaveIdioma(error.key)} ya está en uso: ${error.value}`
                                return mensaje
                            })
                            setShowModalErrores(true)
                            console.log(problemas_encontrados)
                            setErrores(problemas_encontrados)
                        }
                    }
                }
            }
            return false
        })
        .catch(error => {
            return false
        })
    }

      const crearUsuairoIndividual = async (usuario)=>{
        const progreso = procesados + 1
        const usuario_crear = JSON.parse( JSON.stringify(usuario))
        usuario_crear.status = "active"
        usuario_crear.phone = `${obtenerPrefijoMovil(pais)}${usuario_crear.phone}`
        usuario_crear.validado = true
        return fetch(`${urlapi}/conductores`,{
            method:'POST',
            body: JSON.stringify(usuario_crear),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            setProcesados(progreso)
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                return false
            } else if(res._id){
                return res._id
            }
        })
        .catch(error => {
            return false
        })
    }
    
    const handleCloseAsignacion = () => setModalAsignacion(false)

    const mostrarErrores = () => {
        return <Modal  style={{ padding: 30 }} open={showModalErrores} footer={[]} onCancel={() => {
            setShowModalErrores(false)
            setErrores([...[], ...[]])
        }}>
            <TbAlertCircleFilled style={{ fontSize: 40, color:"red" }} />
        <Title level={2} className="mt-0 mb-0"> Errores encontrados</Title>
        <Title level={5} className="mt-0 mb-3">Encontramos los siguientes errores al validar la información que intentas cargar, corrígelos y vuelve a intentarlo</Title>
        <List
            className="mt-3"
            dataSource={errores}
            renderItem={(item) => (
                <List.Item>{item}</List.Item>
            )}
            />
        </Modal>
    }

    const mostrarModalProgreso = () => {
        return <Modal  style={{ textAlign: "center", padding: 30 }} open={modalProgress} footer={[]} onCancel={() => false}>
            <Spin size="large"/>
        <Title level={2} className="mt-0 mb-0"> Cargando tu información</Title>
        <Title level={3} className="mt-0 mb-0">{procesados} procesados de {datos.length}</Title>
        <Title level={4} className="mb-0 mt-0">No cierres la ventana por favor...</Title>
        </Modal>
    }

    const asignarLlaveExcelADatos = (key) => {
        const instancia_datos = JSON.parse( JSON.stringify(datos))
        console.log(key,llaveAsignar,datosExcelSinProcesar,instancia_datos)
        const nuevo_arreglo = instancia_datos.map((dato,i) => {
            dato[llaveAsignar] = datosExcelSinProcesar[i][key]
            return dato
        })
        setDatos([...[], ...nuevo_arreglo])
        return setModalAsignacion(false)
    }
    
    const mostrarModalAsignacion = () => {
        return <Modal  style={{ textAlign: "center", padding: 30 }} open={modalAsignacionColumna} footer={[]} onCancel={handleCloseAsignacion}>
        <Title level={2} className="mt-0 mb-2"> Asigna una columna de tu excel al campo {obtenerNombrePorLlaveIdioma(llaveAsignar)}</Title>
        {
            columnasExcel.map(key => {
                return <Button style={{ margin: 4}} key={key} onClick={() => asignarLlaveExcelADatos(key)} >{key}</Button>
            })
        }
        </Modal>
    }

    const abrirAsignacion = (key) => {
        setLlaveAsignar(key)
        return setModalAsignacion(true)
    }

    const EncabezadoPesonalizado = ({ titulo, campo, esquema, helptext }) => {

        if(helptext) {
            return <Tooltip title={helptext}>
            <Paragraph className="mb-0 mt-0">{titulo} <TbHelpCircleFilled /></Paragraph>
            { esquema.denieUpload === true ? <Button size="small" disabled style={{ fontSize: 9 }} >CAMBIAR ASIGNACIÓN COLUMNA</Button> : <Button size="small" style={{ fontSize: 9 }} onClick={() => abrirAsignacion(campo)} >CAMBIAR ASIGNACIÓN COLUMNA</Button> }
            </Tooltip>
        }

        return <>
            <Paragraph className="mb-0 mt-0">{titulo}</Paragraph>
            { esquema.denieUpload === true ? <Button size="small" disabled style={{ fontSize: 9 }} >CAMBIAR ASIGNACIÓN COLUMNA</Button> : <Button size="small" style={{ fontSize: 9 }} onClick={() => abrirAsignacion(campo)} >CAMBIAR ASIGNACIÓN COLUMNA</Button> }
        </>
    }

    const eliminarColumna = (i) => {
        datos.splice(i,1)
        return setDatos([...[], ...datos])
    }

      const mostrarDatos = () => {
        if(datos.length < 1) return false

        return <div className="mt-3">
            <Title level={3} className="mt-0 mb-2">Datos cargados</Title>
            <Button size="small" loading={validando} type="primary" onClick={() => subirInformacion()} ><BiUpload style={{ marginRight: 5 }} /> SUBIR INFORMACIÓN</Button>
            <Divider />
            <Table dataSource={datos} pagination={false} >
            <Column render={(data,i,p) => {
                    return <div>
                        <DeleteOutlined onClick={() => eliminarColumna(p)} />
                    </div>
                }} />
                <Column title={<EncabezadoPesonalizado campo="nombres" esquema={obtenerEsquemaPorLlave("nombres")} titulo={obtenerNombrePorLlaveIdioma("nombres")} helptext={obtenerTextoAyudaPorLlaveIdioma('nombres')} />} render={(data,i,p) => {
                    return <Input 
                    status={!data.nombres ? "error": ""} 
                    name="nombres" 
                    value={data.nombres} 
                    onChange={(e) => handleChange(e,p)} 
                    prefix={!data.nombres ? <TbAlertCircleFilled style={{ color: "red"}} /> : <TbCircleCheckFilled style={{ color: "green"}} /> }
                />
                }} />
                <Column title={<EncabezadoPesonalizado campo="apellido_p" esquema={obtenerEsquemaPorLlave("apellido_p")} titulo={obtenerNombrePorLlaveIdioma("apellido_p")} helptext={obtenerTextoAyudaPorLlaveIdioma('apellido_p')} />} render={(data,i,p) => {
                    return <Input 
                    status={!data.apellido_p ? "error": ""} 
                    name="apellido_p" 
                    value={data.apellido_p} 
                    onChange={(e) => handleChange(e,p)} 
                    prefix={!data.apellido_p ? <TbAlertCircleFilled style={{ color: "red"}} /> : <TbCircleCheckFilled style={{ color: "green"}} /> }
                />
                }} />
                <Column title={<EncabezadoPesonalizado campo="apellido_m" esquema={obtenerEsquemaPorLlave("apellido_m")} titulo={obtenerNombrePorLlaveIdioma("apellido_m")} helptext={obtenerTextoAyudaPorLlaveIdioma('apellido_m')} />} render={(data,i,p) => {
                    return <Input 
                    status={!data.apellido_m ? "error": ""} 
                    name="apellido_m" 
                    value={data.apellido_m} 
                    onChange={(e) => handleChange(e,p)} 
                    prefix={!data.apellido_m ? <TbAlertCircleFilled style={{ color: "red"}} /> : <TbCircleCheckFilled style={{ color: "green"}} /> }
                />
                }} />
                <Column title={<EncabezadoPesonalizado campo="rut" esquema={obtenerEsquemaPorLlave("rut")} titulo={nombreDNIPorPais(pais)} helptext={obtenerTextoAyudaPorLlaveIdioma('rut')} />}  render={(data,i,p) => {
                    return <Input 
                    status={!data.rut ? "error": ""} 
                    name="rut" 
                    value={data.rut} 
                    onChange={(e) => handleChange(e,p)} 
                    prefix={!data.rut ? <TbAlertCircleFilled style={{ color: "red"}} /> : <TbCircleCheckFilled style={{ color: "green"}} /> }
                />
                }} />
                <Column title={<EncabezadoPesonalizado campo="email" esquema={obtenerEsquemaPorLlave("email")} titulo={obtenerNombrePorLlaveIdioma("email")} helptext={obtenerTextoAyudaPorLlaveIdioma('email')} />} render={(data,i,p) => {
                    return <Input 
                    status={!data.email ? "error": ""} 
                    name="email" 
                    value={data.email} 
                    onChange={(e) => handleChange(e,p)} 
                    prefix={!data.email ? <TbAlertCircleFilled style={{ color: "red"}} /> : <TbCircleCheckFilled style={{ color: "green"}} /> }
                />
                }} />
                <Column title={<EncabezadoPesonalizado campo="sexo" esquema={obtenerEsquemaPorLlave("sexo")} titulo={obtenerNombrePorLlaveIdioma("sexo")} helptext={obtenerTextoAyudaPorLlaveIdioma('sexo')} />} render={(data,i,p) => {
                    return <Select
                    showSearch={true}
                    style={ !data.sexo ? {
                        width: "100%",
                        border: "1.5px solid red",
                        borderRadius: 8
                    } : { width: "100%" }}
                    value={data.sexo}
                    options={[{ value: "H", label: "Hombre" },{ value:"M", label: "Mujer" }]}
                    onChange={(e) => handleChangeSelector(e,p,'sexo')} 
                    />
                }} />
                <Column title={<EncabezadoPesonalizado campo="phone" esquema={obtenerEsquemaPorLlave("phone")} titulo={obtenerNombrePorLlaveIdioma("phone")} helptext={obtenerTextoAyudaPorLlaveIdioma('phone')} />} render={(data,i,p) => {
                    return <Input 
                    status={!data.phone ? "error": ""} 
                    name="phone" 
                    value={data.phone} 
                    onChange={(e) => handleChange(e,p)} 
                    prefix={!data.phone ? <TbAlertCircleFilled style={{ color: "red"}} /> : <TbCircleCheckFilled style={{ color: "green"}} /> }
                />
                }} />
                
            </Table>
        </div>
      }
      
    const render = () => {
        return <div>
            <Card>
            <Title className="mb-2 mt-0">Importar conductores</Title>
            <Button style={{ marginRight: 10 }} size="small" onClick={() => handleDownload()}><FileExcelFilled /> DESCARGAR MODELO</Button>
            </Card>
            <div style={{ paddingRight: 20, paddingTop:10, paddingBottom: 10, paddingLeft: 20 }}><ExcelHelper /></div>
            <Card>
                {
                     showUploadBox ? <Dragger {...props_file_upload}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Has click o arrastra el archivo a esta sección</p>
                    <p className="ant-upload-hint">Solo está permitida la subida de archivos de tipo excel</p>
                    </Dragger> : false
                }
            {mostrarDatos()}
            </Card>
            {contextHolder}
            {contextHolderPush}
            {mostrarErrores()}
            {mostrarModalProgreso()}
            {mostrarModalAsignacion()}
        </div>
    }
    
    return <Structure component={render()} />
}

export default ImportadorConductores