import { Doughnut } from "react-chartjs-2";
import { estilo_moving_truck } from "../../../lib/estilo_sitio";
import Title from "antd/es/typography/Title";
import { url_images } from "../../../lib/backend/data";

const GraficoDona = (props) => {
    const { 
        datos,
        titulo
    } = props

    const sumar_todo = datos.reduce((prev,next) => {
      let cantidad = 0
      if(!isNaN(next.total)) cantidad = next.total
      return prev + cantidad
  }, 0)

  if(sumar_todo < 1) return <div style={{ textAlign: "center" }}>
    <img style={{ maxWidth: 200 }} src={`${url_images}/illustrations/graph-dashboard.png`} />
    <Title level={4} className="mt-0 mb-0">Sin datos para mostrar</Title>
  </div>

    const data = {
        labels: datos.map(da => da.titulo),
        datasets: [
          {
            label: titulo ? titulo : 'Cantidad invertida',
            data: datos.map(da => da.total),
            backgroundColor: [
              "#730000", "#000074", "#7a0000", "#16564d", "#000074", "#000095",
              "#00007f", "#6c0000", "#00008d", "#713f1f", "#353202", "#00006e",
              "#00006a", "#7e0000", "#0f1820", "#2b4e82", "#770000", "#690000",
              "#000086", "#00008d", "#75221c", "#650000", "#000093", "#000083",
              "#890000", "#650000", "#650000", "#00007e", "#103a39", "#00008f",
              "#710000", "#601a10", "#920000", "#700000", "#725e4a", "#00008c",
              "#3b3568", "#6f3955", "#293328", "#00006d", "#58252e", "#000074",
              "#6d0000", "#052c1a", "#77255d", "#8c0000", "#8a0000", "#00007a",
              "#730000", "#00006c"
            ]
          },
        ],
      };

    return <div>
        <Doughnut data={data} />
    </div>
}

export default GraficoDona