import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Alert, Breadcrumb, Button, Card, Col, Collapse, Divider, Form, Input, Layout, Modal, Popconfirm, Row, Segmented, Select, Spin, Switch, Table, Tabs, Tag, Tooltip, Tour, Upload, message } from "antd"
import { CameraOutlined, CarFilled, HomeOutlined, InfoCircleFilled, LoadingOutlined, PlusOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { calcularPesoVolumetricoUnitario, duracionTiempoString, fechaUTCATexto, fechaUTCATiempo, limpiarStringLetrasNumeros, limpiarStringNumeros, siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import { colores, config_s3, maps_key, url_images, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import S3FileUpload from 'react-s3';
import { Link, useParams } from "react-router-dom"
import { AiFillCar, AiFillMinusCircle, AiOutlineMail } from "react-icons/ai"
import { GrAdd, GrDocumentText } from "react-icons/gr"
import { FaArrowLeft, FaCircle, FaRegClock, FaRegTrashAlt, FaTruckLoading } from "react-icons/fa";
import { FiUser } from "react-icons/fi"
import ModalSeleccionRequisitos from "../Requisitos/modal_seleccion"
import Paragraph from "antd/es/typography/Paragraph"
import { BsTruckFlatbed } from "react-icons/bs"
import CargandoTabla from "./cargando"
import DetallePunto from "./detalle_punto"
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps"
import ReactGoogleAutocomplete from "react-google-autocomplete"
import { optiones_estricto_tiempo } from "./data"
import ModalSeleccionVehiculo from "../Vehiculos/modal_seleccion"
import DetalleVehiculo from "./detalle_vehiculo"
import { HiCursorClick } from "react-icons/hi";
import { dateFromTimeForToday } from "../../lib/helpers/dates"
import { IoSettingsSharp, IoWarning } from "react-icons/io5"
import MapaRuteo from "./mapa_ruta"
import { MdDeleteOutline, MdOpenInNew, MdOutlinePlace, MdPlace } from "react-icons/md"
import { modificarInterfaz } from "../../redux/actions/interfaz"
import { LuMousePointerClick } from "react-icons/lu"
import { IoIosTimer, IoMdAlert, IoMdTime } from "react-icons/io"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import Column from "antd/es/table/Column"

const DetallesRuteo = (props) => {
    const {
        id
    } = useParams()
    const [ loading, setLoading ] = useState(false)
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ loadingDefinicion, setLoadingDefinicion ] = useState(false)
    const [ loadingImagen, setLoadingImagen ] = useState(false)
    const [ loadingAcciones, setLoadingAcciones ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const [ origen, setOrigen ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ vehiculos, setVehiculos ] = useState([])
    const [ resultados, setResultados ] = useState(false)
    const [ vehicleHover, setVehicleHover ] = useState(false)
    const [ pointHover, setPointHover ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ usuario, setUsuario ] = useState(false)
    const [ showModalConfig, setShowModalConfig ] = useState(false)
    const dispatch = useDispatch()
    const [ requisitos, setRequisitos ] = useState([])
    const [ caracteristicas, setCaracteristicas ] = useState({})
    const [ registrosCreados, setRegistrosCreados ] = useState(false)
    const [ tipoVista, setTipoVista ] = useState("Puntos")
    const [ modalRuteo, setModalRuteo ] = useState(false)
    const [ puntos, setPuntos ] = useState([])
    const [ visualizationType, setVisualizationType ] = useState("routing")
    const [ marcadores, setMarcadores ] = useState([])
    const origenRef = useRef(null)
    const configuracionGeneralRef = useRef(null)
    const puntosVehiculosRef = useRef(null)
    const paradasRef = useRef(null)
    const interfaz = useSelector(state => state.interfaz)
    const botonRutearRef = useRef(null)
    const [ openTour, setOpenTour ] = useState(false)
    const [ configRuteo, setConfigRuteo ] = useState({ 
        duration_pickup: 1, 
        duration_dropoff: 1, 
        estrict_default: "soft", 
        horario_desde: "07:00", 
        horario_hasta: "18:00",
        dropoff_earliest: "07:00",
        dropoff_latest: "18:00",
        dropoff_type: "soft",
        pickup_earliest: "07:00",
        pickup_latest: "18:00",
        pickup_type: "soft",
        break_duration: 30,
        activate_boxes: false,
        boxes: 30,
        activate_weight: false,
        weight: 600,
    }) // 15 minutos
    const module = 'ruteo'
    const nextButtonProps = { children: 'Siguiente' }
    const prevButtonProps = { children: 'Anterior' }
    const steps = [
        {
          title: 'Configura tu origen',
          description: 'Lo primero que debes realizar es escribir la dirección de donde saldrán tus cargas',
          cover: (
            <img
              alt="tour.png"
              style={{ height: 200, width: "auto", marginBottom: -70, marginTop: -70 }}
              src={`${url_images}/Delivery Truck.gif`}
            />
          ),
          target: () => origenRef.current,
          nextButtonProps
        },
        {
          title: 'Configura los aspectos generales',
          description: 'Puedes definir el horario laboral, tiempos de descanso y horarios para las recogidas y entregas.',
          cover: (
            <img
              alt="tour.png"
              style={{ height: 200, width: "auto", marginBottom: -70, marginTop: -70 }}
              src={`${url_images}/Order List.gif`}
            />
          ),
          target: () => configuracionGeneralRef.current,
          nextButtonProps,
          prevButtonProps
        },
        {
          title: 'Configura las paradas y vehículos',
          description: 'Puedes definir la configuración de forma individual para las paradas y los vehículos, decide desde el horario de cada vehículo hasta el horario de entregas por parada.',
          cover: (
            <img
              alt="tour.png"
              style={{ height: 200, width: "auto", marginBottom: -70, marginTop: -70 }}
              src={`${url_images}/Delivery Tracking.gif`}
            />
          ),
          target: () => puntosVehiculosRef.current,
          nextButtonProps,
          prevButtonProps
        },
        {
          title: 'Optimiza tu ruta',
          description: 'Una vez hayas configurado todo, puedes dar click en el botón "Rutear" para que el sistema calcule la mejor ruta para ti.',
          target: () => botonRutearRef.current,
          nextButtonProps: { children: "Finalizar" },
          prevButtonProps
        },
      ];
    
    const inicializarDatosRuteo = (puntos) => {
        if(!puntos) return []
        if(Array.isArray(puntos) !== true) return []

        let markers = []
        for( const orden of puntos.filter(p => p.tipo === "entrega") ){
            if(typeof orden !== "object") continue

            const i = markers.findIndex(m => m.description === orden.description)
            if(i > -1){
                // markers[i].entregas.push(orden.id_viaje)
                continue
            }

            if(orden.location) if(typeof orden.location.latitude !== "undefined") if(typeof orden.location.longitude !== "undefined"){
                const posicion_misma_coordenada = markers.findIndex(m => {
                    if(typeof m.location === "object"){
                        if(typeof m.location.latitude !== "undefined") if(typeof m.location.longitude !== "undefined"){
                            if(m.location.latitude === orden.location.latitude && m.location.longitude === orden.location.longitude) return true
                        }
                    }
                    return false
                })
                if(posicion_misma_coordenada > -1) {
                    // markers[posicion_misma_coordenada].entregas.push(orden.id_viaje)
                    continue
                }
            }

            markers.push({
                ...orden,
                // entregas: [ orden.id_viaje ]
            })
        }

        let markers_origen = []

        // for( const orden of puntos.filter(p => p.tipo === "origen") ){
        //     if(typeof orden !== "object") continue

        //     const i = markers_origen.findIndex(m => m.description === orden.description)
        //     if(i > -1){
        //         const entregas_asociadas = markers.filter(m => m.id_viaje === orden.id_viaje).map(m => m._id)
        //             for( const parada of entregas_asociadas ){
        //                 const i_mark = markers.findIndex(m => m._id === parada)
        //                 if( i_mark > -1 ) markers[i_mark].from = markers_origen[i]._id
        //             }
        //         continue
        //     }

        //     if(orden.location) if(typeof orden.location.latitude !== "undefined") if(typeof orden.location.longitude !== "undefined"){
        //         const posicion_misma_coordenada = markers_origen.findIndex(m => {
        //             if(typeof m.location === "object"){
        //                 if(typeof m.location.latitude !== "undefined") if(typeof m.location.longitude !== "undefined"){
        //                     if(m.location.latitude === orden.location.latitude && m.location.longitude === orden.location.longitude) return true
        //                 }
        //             }
        //             return false
        //         })
        //         if(posicion_misma_coordenada > -1) {

        //             const entregas_asociadas = markers.filter(m => m.id_viaje === orden.id_viaje).map(m => m._id)
        //             for( const parada of entregas_asociadas ){
        //                 const i_mark = markers.findIndex(m => m._id === parada)
        //                 if( i_mark > -1 ) markers[i_mark].from = markers_origen[posicion_misma_coordenada]._id
        //             }

        //             continue
        //         }
        //     }

        //     const entregas_asociadas = markers.filter(m => m.id_viaje === orden.id_viaje).map(m => m._id)
        //     for( const parada of entregas_asociadas ){
        //         const i_mark = markers.findIndex(m => m._id === parada)
        //         if( i_mark > -1 ) markers[i_mark].from = orden._id
        //     }

        //     markers_origen.push({
        //         ...orden
        //     })
        // }

        return [...markers_origen, ...markers]
    }

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/${module}?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res){

                let puntos = inicializarDatosRuteo(res.puntos)
                console.log({ poiiints: puntos })
                setPuntos([...[], ...puntos])
                delete res.puntos
                setUsuario(res)
                if(res.detalles_conductor){
                    if(typeof res.detalles_conductor === "object"){
                        if(res._id) setConductor(res.detalles_conductor)
                    }
                }

                if(res.requisitos) if(Array.isArray(res.requisitos)) setRequisitos(res.requisitos)
                if(res.caracteristicas) if(typeof res.caracteristicas === "object") setCaracteristicas(res.caracteristicas)

                let markers = []
                      for( const orden of puntos ){
                        if(orden.location){
                            markers.push({
                                _id: orden._id,
                                lat: orden.location.latitude,
                                lng: orden.location.longitude,
                                description: orden.description,
                                tipo: orden.tipo,
                            })
                        }
                }
                setMarcadores(markers)
            }
            let activar_tour = true
            if(interfaz) if(interfaz.tours) if( typeof interfaz.tours.routing !== "undefined") activar_tour = interfaz.tours.routing
            setOpenTour(activar_tour)
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }
    
    const reprocesarPuntos = async (ids) => {
        setLoadingAcciones(true)
        return fetch(`${urlapi}/rutas/geocode-points`,{
            method:'POST',
            body: JSON.stringify({ ids }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.success){
                messageApi.success("Realizado exitosamente")
                return setTimeout(() => {
                    return window.location.reload()
                }, 500);
            }
            return setLoadingAcciones(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingAcciones(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const guardarCambios = (nueva_data) => {
        setTimeout(() => {
            crearConductorNuevo(nueva_data)
        }, 300);
    }
    
    const addRequeriment = (nuevo) => {
        const i = requisitos.findIndex(re => re._id === nuevo._id)
        if(i > -1) return 
        const nuevo_arreglo = [...requisitos, ...[nuevo]]
        setRequisitos(nuevo_arreglo)
        guardarCambios({ requisitos: nuevo_arreglo, caracteristicas })
    } 

    const onSelectRequisito = (data) => {
        addRequeriment(data)
    }

    const removerReq = (id) => {
        const registros = requisitos
        const i = registros.findIndex(re => re._id === id)
        if(i < 0) return 
        registros.splice(i,1)
        return setRequisitos(prev => [...registros, ...[]])
    }

    const visualizarRequisitosPorTipo = (tipo) => {


        const filtrados = requisitos.filter(e => e.tipo === tipo)

        return <div>
            <Row gutter={15}>
            {
                filtrados.map((req,i) => {
                    return <Col md={4} key={`req-${i}`}>
                        <Card size="small">
                        <Title level={5} className="m-0">{req.titulo}</Title>
                        <Paragraph level={5} className="m-0">{req.descripcion}</Paragraph>
                        <Button size="small" type="text" icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} /> } onClick={() => removerReq(req._id)} >ELIMINAR</Button>
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    const visualizarRequisitos = () => {

        const boton = <Button icon={<GrAdd /> } className="mb-3" >AÑADIR NUEVO</Button>

        const items = [
            {
                label: <div style={{ textAlign: "center" }}><AiFillCar style={{ fontSize: 20 }} /> <Title level={4} className="m-0">DOCUMENTACIÓN DEL VEHÍCULO</Title></div>,
                children: <div>
                    <ModalSeleccionRequisitos boton={boton} slug="subir_requisitos_vehiculo" typeView="modal" onSelect={(data) => onSelectRequisito(data)} condicion_busqueda={{ tipo: "vehiculo", status: "active" }} /> 
                    {visualizarRequisitosPorTipo('vehiculo')}
                </div>
            },
            {
                label: <div style={{ textAlign: "center" }}><FiUser style={{ fontSize: 20 }} /> <Title level={4} className="m-0">DOCUMENTACIÓN DEL CONDUCTOR</Title></div>,
                children: <div>
                    <ModalSeleccionRequisitos boton={boton} slug="subir_requisitos_conductor" typeView="modal" onSelect={(data) => onSelectRequisito(data)} condicion_busqueda={{ tipo: "conductor", status: "active" }} /> 
                    {visualizarRequisitosPorTipo('conductor')}
                </div>
            },
            {
                label: <div style={{ textAlign: "center" }}><BsTruckFlatbed style={{ fontSize: 20 }} /> <Title level={4} className="m-0">CARACTERÍSTICA DEL VEHÍCULO</Title></div>,
                children: <div>
                    <Paragraph >Solo los vehículos con las características que selecciones debajo podrán transportas esta carga</Paragraph>
                    
                    {visualizarRequisitosPorTipo('caracteristica_vehiculo')}
                </div>
            },
        ].map((item,i) => ({ key: (i+1), ...item}))

        return <div>
            <Title level={4} className="mt-0 mb-3"> Requisitos</Title>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    }

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        if(name === "rut"){
            usuario[name] = limpiarStringLetrasNumeros(value)
        } else if(name === "phone"){
            usuario[name] = limpiarStringNumeros(value)
        } else {
            usuario[name] = value
        }
        return setUsuario({...{}, ...usuario})
    }

    const uploadButton = (
        <div>
          {loadingImagen ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Subir foto
          </div>
        </div>
      );

    const handleChangeUsuarioSelect = (name, value) => {
        usuario[name] = value
        return setUsuario(usuario)
    }

    const preparePointPayload = (punto) => {


        let pickup_duration = parseInt(configRuteo.duration_pickup) * 60
        let dropoff_duration = parseInt(configRuteo.duration_dropoff) * 60

        if(punto.duration_pickup ) pickup_duration = parseInt(punto.duration_pickup) * 60
        if(punto.duration_dropoff) dropoff_duration = parseInt(punto.duration_dropoff) * 60

        let pickup_earliest         = dateFromTimeForToday(configRuteo.pickup_earliest)
        let pickup_latest           = dateFromTimeForToday(configRuteo.pickup_latest)

        let dropoff_earliest        = dateFromTimeForToday(configRuteo.dropoff_earliest)
        let dropoff_latest          = dateFromTimeForToday(configRuteo.dropoff_latest)

        if(punto.pickup_earliest)   pickup_earliest     = dateFromTimeForToday(punto.pickup_earliest)
        if(punto.pickup_latest)     pickup_latest       = dateFromTimeForToday(punto.pickup_latest)

        if(punto.dropoff_earliest)  dropoff_earliest    = dateFromTimeForToday(punto.dropoff_earliest)
        if(punto.dropoff_latest)    dropoff_latest      = dateFromTimeForToday(punto.dropoff_latest)


        let objeto_punto = {
            name: punto._id,
            from: "warehouse",
            to: punto._id,
            pickup_duration,
            dropoff_duration,
            pickup_times: [
                {
                    earliest: pickup_earliest,
                    latest: pickup_latest,
                    type: punto.type ? punto.type : configRuteo.pickup_type
                }
            ],
            dropoff_times: [
                {
                    earliest: dropoff_earliest,
                    latest: dropoff_latest,
                    type: punto.type ? punto.type : configRuteo.dropoff_type
                }
            ]
        }

        if(punto.capabilities) objeto_punto.requirements = punto.capabilities.map(e => e.title)
        
        if(configRuteo.activate_weight === true){
            if(!objeto_punto.size) objeto_punto.size = {}
            objeto_punto.size.weight = parseInt(punto.peso_vol) ? parseInt(punto.peso_vol) : parseInt(configRuteo.weight)
        }

        if(configRuteo.activate_boxes === true){
            if(!objeto_punto.size) objeto_punto.size = {}
            objeto_punto.size.boxes = parseInt(punto.bultos) ? parseInt(punto.bultos) : parseInt(configRuteo.boxes)
        }
        
        return objeto_punto
    }

    const prepareVehiclePayload = (veh) => {

        const calculate         = calcularPesoVolumetricoUnitario(veh)
        const weight            = calculate.weight
        const volume            = calculate.vol
        const earliest_start    = veh.horario_desde ? dateFromTimeForToday(veh.horario_desde) : dateFromTimeForToday(configRuteo.horario_desde)
        const latest_end        = veh.horario_hasta ? dateFromTimeForToday(veh.horario_hasta) : dateFromTimeForToday(configRuteo.horario_hasta)


        let objeto_vehiculo = {
            name: veh._id,
            start_location: veh.start_location ? veh.start_location : "warehouse",
            earliest_start,
            latest_end
        }

        if(configRuteo.activate_weight === true){
            if(!objeto_vehiculo.capacities) objeto_vehiculo.capacities = {}
            objeto_vehiculo.capacities.weight = parseInt(veh.peso) ? parseInt(veh.peso) : parseInt(configRuteo.weight)
        }

        if(configRuteo.activate_boxes === true){
            if(!objeto_vehiculo.capacities) objeto_vehiculo.capacities = {}
            objeto_vehiculo.capacities.boxes = parseInt(veh.bultos) ? parseInt(veh.bultos) : parseInt(configRuteo.boxes)
        }

        if(veh.end_location) objeto_vehiculo.end_location = veh.end_location
        if(veh.capabilities) objeto_vehiculo.capabilities = veh.capabilities.map(e => e.title)

        let break_times = []
        
        let duracion_por_defecto = configRuteo.break_duration ? configRuteo.break_duration : 30

        if(configRuteo.descanso === true){
            if(configRuteo.break_earliest_start && configRuteo.break_latest_end){
                break_times = [
                    {
                        earliest_start: dateFromTimeForToday(configRuteo.break_earliest_start),
                        latest_end: dateFromTimeForToday(configRuteo.break_latest_end),
                        duration: parseInt(duracion_por_defecto) 
                    }
                ]
            }
        }

        if(veh.descanso === true){
            if(veh.break_desde && veh.break_hasta){
                break_times = [
                    {
                        earliest_start: dateFromTimeForToday(veh.break_desde),
                        latest_end: dateFromTimeForToday(veh.break_hasta),
                        duration: veh.break_duration ? parseInt(veh.break_duration)  : parseInt(duracion_por_defecto) 
                    }
                ]
            }
        }

        if(break_times.length > 0) objeto_vehiculo.breaks = break_times
        return objeto_vehiculo
    }

    const prepareLocationsPayload = (paradas) => {
        let points = []

        points.push({
            name: "warehouse",
            coordinates: [ origen.location.longitude, origen.location.latitude ],
        })
        
        for( const punto of paradas ){
            console.log({ punto })
            if(typeof punto !== "object") continue
            if(!punto.location) continue
            const longitude = punto.location.longitude
            const latitude = punto.location.latitude
            if(typeof longitude !== "number") continue
            if(typeof latitude !== "number") continue
            points.push({
                name: punto._id,
                coordinates: [ longitude, latitude ],
            })
        }
        
        return points.filter(e => e)
    }

    const crearConductorNuevo = async (nueva_data) => {
        if(!origen) return messageApi.error("Debes definir un origen")
        if(vehiculos.length < 1) return messageApi.error("Debes definir al menos un vehículo")
        if(!configRuteo.horario_desde || !configRuteo.horario_hasta ) return messageApi.error("Debes definir un horario de trabajo por defecto")

        if(configRuteo.activate_boxes === true){
            const inactivos = puntos.filter(p => {
                let cantidad = 0
                if(!isNaN( parseInt(p.bultos) )) cantidad = parseInt(p.bultos)
                return cantidad < 1
            })
            console.log({ inactivos })
            if(inactivos.length > 0) messageApi.warning("Existen puntos sin bultos")
        }

        if( configRuteo.activate_weight === true){
            const inactivos = puntos.filter(p => {
                let cantidad = 0
                if(!isNaN( parseFloat(p.peso_vol) )) cantidad = parseFloat(p.peso_vol)
                return cantidad > 0 ? false : true
            })
            console.log({ inactivos })
            if(inactivos.length > 0) messageApi.warning("Existen puntos sin peso")
        }

        const puntos_invalidos = puntos.filter(punto => {
            if(typeof punto !== "object") return true
            if(!punto.location) return true
            const longitude = punto.location.longitude
            const latitude = punto.location.latitude
            if(typeof longitude !== "number") return true
            if(typeof latitude !== "number") return true
            return false
        })

        if(puntos_invalidos.length > 0) return messageApi.error("Existen puntos sin coordenadas válidas")

        const payload_enviar = {
            version: 1,
            locations: prepareLocationsPayload(puntos),
            vehicles: vehiculos.map(v => prepareVehiclePayload(v) ),
            shipments: puntos.map(p => preparePointPayload(p)).filter((p,i) => i < 200 ? p : false).filter(i => i)
        }
        // return console.log(payload_enviar)
        setLoading(true)

        const url = `${urlapi}/${module}/route-advance`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(payload_enviar),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.routes){
                setResultados(res)
                setTimeout(() => {
                    setVisualizationType("results")
                }, 200);
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }


    const confirmarRutas = async () => {
        if(!origen) return messageApi.error("Debes definir un origen")
        if(vehiculos.length < 1) return messageApi.error("Debes definir al menos un vehículo")
        if(!configRuteo.horario_desde || !configRuteo.horario_hasta ) return messageApi.error("Debes definir un horario de trabajo por defecto")

        if(configRuteo.activate_boxes === true){
            const inactivos = puntos.filter(p => {
                let cantidad = 0
                if(!isNaN( parseInt(p.bultos) )) cantidad = parseInt(p.bultos)
                return cantidad < 1
            })
            console.log({ inactivos })
            if(inactivos.length > 0) messageApi.warning("Existen puntos sin bultos")
        }

        if( configRuteo.activate_weight === true){
            const inactivos = puntos.filter(p => {
                let cantidad = 0
                if(!isNaN( parseFloat(p.peso_vol) )) cantidad = parseFloat(p.peso_vol)
                return cantidad > 0 ? false : true
            })
            console.log({ inactivos })
            if(inactivos.length > 0) messageApi.warning("Existen puntos sin peso")
        }

        const puntos_invalidos = puntos.filter(punto => {
            if(typeof punto !== "object") return true
            if(!punto.location) return true
            const longitude = punto.location.longitude
            const latitude = punto.location.latitude
            if(typeof longitude !== "number") return true
            if(typeof latitude !== "number") return true
            return false
        })

        if(puntos_invalidos.length > 0) return messageApi.error("Existen puntos sin coordenadas válidas")

        const payload_enviar = {
            resultados,
            id_ruta: id
        }
        // return console.log(payload_enviar)
        setLoading(true)

        const url = `${urlapi}/${module}/route-advance-confirm`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(payload_enviar),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            console.log(res)
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.rutas){
                setRegistrosCreados(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    const cambiarVehiculoConductor = async () => {
        setLoadingDefinicion(true)
        return fetch(`${urlapi}/vehiculos/definir-seleccionado`, {
            method: 'POST',
            body: JSON.stringify({
              id: usuario._id
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer: ${session.tokenSession}`,
              'country': pais,
              'lang': idioma
            },
          })
            .then((res) => {
              if (res.status === 401) return dispatch(cerrarSesion());
              return res.json();
            })
            .then((res) => {
              if (!res) {
                messageApi.error('Sin datos');
              } else if (res.errorMessage) {
                messageApi.error(res.errorMessage);
              } else if (res._id) {
                usuario.selected = true
                messageApi.success("Realizado exitosamente")
                setUsuario(prev => ({...{}, ...usuario}));
              }
              return setLoadingDefinicion(false);
            })
            .catch((error) => {
              messageApi.error('Error al consultar la información, intente nuevamente');
              return setLoadingDefinicion(false);
            })
      }

    const handleChangeArchivo = async (e) => {

        const archivo = e.file.originFileObj
        setLoadingImagen(true)
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            messageApi.error('No pudimos cargar tu imagen')
            return setLoadingImagen(false)
        }

        usuario.imagen_perfil = data.location
        setUsuario({...{}, ...usuario})
        return setLoadingImagen(false)

        })
        .catch(err => {
            console.log(err)
            setLoadingImagen(false)
            return messageApi.error('No pudimos cargar tu imagen')
        })
    }


    const handleChangeMovil = (e, pos, name) => {
        let value = e
        if(typeof e === "object") if(e.target) value = e.target.value
        usuario.moviles_adicionales[pos][name] = value
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const removerMovil = (i) => {
        usuario.moviles_adicionales.splice(i,1)
        return setUsuario(prev => ({...{}, ...usuario})) 
    }
    

    const removerEmail = (i) => {
        usuario.emails_adicionales.splice(i,1)
        return setUsuario(prev => ({...{}, ...usuario})) 
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        usuario.emails_adicionales[pos][name] = value
        usuario.emails_adicionales[pos].valid = validateEmail(value)
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const agregarFiltro = (key, value) => {
        console.log(value)
        return setUsuario(prev => {
            let actual = {...prev}
            if(value){
                if(typeof value === "object"){
                    actual[key] = value._id
                } else {
                    actual[key] = value
                }
            } else {
                delete actual[key]
            }
            return actual
        })
    }

    const modificarViajes = async (viajes) => {
        setLoadingAcciones(true)
        return fetch(`${urlapi}/viajes/masive`,{
            method:'POST',
            body: JSON.stringify({
                viajes
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res){
                messageApi.success("Actualizado exitosamente")
                setTimeout(() => {
                    window.location.reload()
                }, 300);
            }
            return setLoadingAcciones(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingAcciones(false)
        })
    }

    const componentes_accion = [
        {
            label: "REMOVER DE ESTA RUTA",
            action: modificarViajes
        }
    ]

    const onSelect = (datos,action) => {
        const i = componentes_accion.findIndex(it => it.label === action)
        if(i > -1){
            const funcion = componentes_accion[i].action
            funcion(datos.map(vi => ({ _id: vi._id, payload: { id_ruta: "" } })))
        }
    }

    const mostrarEstados = () => {
        if(!usuario.estados) return false
        if(!Array.isArray(usuario.estados)) return false
        if(usuario.estados.lenght < 1) return false
        return <div>
            <Row gutter={15}>
                {
                    usuario.estados.map((estado,ie) => {
                        return <Col md={4} key={`est-ado-${ie}`}>
                            <Card size="small">
                            <Title level={5} className="mb-0 mt-0">{estado.titulo}</Title>
                            <Title level={3} className="mb-0 mt-0">{estado.cantidad}</Title>
                            </Card>
                        </Col>
                    })
                }
            </Row>
        </div>
    }

    const agregarEquipo = (data) => {

        return setUsuario(prev => {

            let actual = {...prev}

            if(!actual.equipos) actual.equipos = []

            for( const equipment of data ){
                if(typeof equipment !== "object") continue
                const i = actual.equipos.findIndex(e => e._id === equipment._id)
                if(i < 0) actual.equipos.unshift(equipment)
            }
            crearConductorNuevo(actual)
            return actual
        })
    }

    const removerEquipo = (i) => {
        return setUsuario(prev => {
            let actual = {...prev}
            actual.equipos.splice(i,1)
            crearConductorNuevo(actual)
            return {...{}, ...actual }
        })
    }

    const visualizarEquiposAsociados = () => {
        if(!usuario.equipos) return false
        if(Array.isArray(usuario.equipos) !== true) return false

        return <div>
            <Row gutter={15} className="mt-3">
            {
                usuario.equipos.map((eq,iq) => {
                    return <Col md={4} key={`equip-${iq}`}>
                        <Card size="small">
                        <Title level={4} className="mt-0 mb-0">{eq.titulo}</Title>
                        <Button size="small" icon={<FaRegTrashAlt />} onClick={() => removerEquipo(iq)} >REMOVER</Button>
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    const onDeleteVehicle = (veh) => {
        return setVehiculos(prev => {
            let data = [...prev]
            const i = data.findIndex(e => e._id === veh._id)
            if(i > -1) data.splice(i,1)
            return [...[], ...data]
        })
    }

    const handleChangeVehiculo = (new_data) => {
        return setVehiculos(prev => {
            let data = [...prev]
            const i = data.findIndex(e => e._id === new_data._id)
            if(i < 0) return data
            data[i] = new_data
            return [...[], ...data]
        })
    }

    const handleChangePunto = (new_data) => {
        return setPuntos(prev => {
            let data = [...prev]
            const i = data.findIndex(e => e._id === new_data._id)
            console.log({i})
            if(i < 0) return data
            data[i] = new_data
            return [...[], ...data]
        })
    }

    const onSelectVehiculo = (e) => {
        return setVehiculos(prev => {
            let actual = [...prev]

            if(Array.isArray(e)){
                
                for( const veh of e ){
                    const existe = actual.findIndex(v => v._id === veh._id)
                    if( existe > -1 ) continue
                    veh.end_location = "warehouse"
                    actual.unshift(veh)
                }
            } else if(typeof e === "object"){
                const i = actual.findIndex(v => v._id === e._id)
                if(i < 0) {
                    e.end_location = "warehouse"
                    actual.unshift(e)
                }
            }
            return [...[], ...actual]
        })
    }

    const mostrarVehiculos = () => {

        return <div style={{ height: "80vh", maxHeight: "80vh", overflowY: "scroll", overflowX: "hidden" }}>
            <ModalSeleccionVehiculo showSelection={true} hideSeleccionados={true} onSelect={(data) => onSelectVehiculo(data)} />
            { vehiculos.length > 0 ? <Button className="mt-3" type="default" onClick={() => setVehiculos([...[], ...[]])} icon={<MdDeleteOutline />} >LIMPIAR VEHÍCULOS</Button> : false }
            <div style={{ marginTop: 20 }}>
            <Paragraph ><HiCursorClick /> Toca el punto para configurar el vehículo</Paragraph>
            <Title level={4} className="mt-0">{vehiculos.length} Vehículos</Title>
            {
                vehiculos.map((vehiculo,i) => {
                    return <div key={`veh-${i}`}>
                        <Card size="small" className="mb-3">
                        <Paragraph className="mb-0">Vehículo</Paragraph>
                        <DetalleVehiculo data={vehiculo} onChange={(punto) => handleChangeVehiculo(punto)} onDelete={(data) => onDeleteVehicle(data)} />
                        </Card>
                        {/* {(i+1) < vehiculos.length ? <Divider style={{ marginBottom: 10, marginTop: 10 }} /> : false} */}
                    </div>
                })
            }
            </div>
        </div>
    }
    const eliminarPunto = id => {
        return setPuntos(prev => {
            let actual = [...prev]
            const i = actual.findIndex(p => p._id === id)
            if(i > -1) actual.splice(i,1)
            return [...[], ...actual]
        })
    }

    const reprocesoCoordenadas = () => {
        const puntos_invalidos = puntos.filter(punto => {
            if(typeof punto !== "object") return true
            if(!punto.location) return true
            const longitude = punto.location.longitude
            const latitude = punto.location.latitude
            if(typeof longitude !== "number") return true
            if(typeof latitude !== "number") return true
            return false
        })

        if(puntos_invalidos.length < 1) return false

        return <div>
            <Card size="small" className="mb-3">
            <Paragraph type="danger" className="mb-0">Existen {puntos_invalidos.length} puntos sin coordenadas válidas</Paragraph>
            <Button type="default" loading={loadingAcciones} onClick={() => reprocesarPuntos(puntos_invalidos.map(p => p._id))} >REINTENTAR GEOLOCALIZACIÓN MASIVA</Button>
            </Card>
        </div>

    }

    const mostrarPuntos = () => {
        if(!puntos) return false
        if(Array.isArray(puntos) !== true) return false

        

        return <div style={{ height: "80vh", maxHeight: "80vh", overflowY: "scroll", overflowX: "hidden" }}>
            <Title level={4} className="mt-0 mb-0">{puntos.length} Puntos</Title>
            <Paragraph ><HiCursorClick /> Toca el punto para configurar la parada</Paragraph>
            {reprocesoCoordenadas()}
            <Row gutter={15} className="mt-3">
            {
                puntos.map((eq,iq) => {
                    const i_origin = puntos.findIndex(p => p._id === eq.from)
                    const origin = !eq.from || eq.from === "warehouse" ? origen : puntos[i_origin]
                    return <Col md={24} key={`punto-${eq._id}`}>
                        <DetallePunto origin={origin} data={eq} onChange={(punto) => handleChangePunto(punto)} onDelete={(id) => eliminarPunto(id)} />
                        { eq.capabilities ? eq.capabilities.map(item => <Tag style={{ marginTop: 10 }}>{item.title}</Tag> ) : false }
                        { configRuteo.activate_boxes === true ? eq.bultos > 0 ? <Tag style={{ marginTop: 10 }}>{eq.bultos} Bultos</Tag> : <Tag color="red">Sin bultos</Tag> : false }
                        { configRuteo.activate_weight === true ? eq.peso_vol > 0 ? <Tag style={{ marginTop: 10 }}>{eq.peso_vol} KG VOL.</Tag> : <Tag color="red">Sin peso</Tag> : false }
                        { eq.duration_dropoff ? <Tag className="mt-3"><FaRegClock /> {eq.duration_dropoff} Min.</Tag> : false }
                        {(iq+1) < puntos.length ? <Divider style={{ marginBottom: 10, marginTop: 10 }} /> : false}
                    </Col>
                })
            }
            </Row>
        </div>
    }

    const definirOrigen = (place) => {
        if(!place) return false
        return setOrigen(prev => {
            let actual = {...prev}
            const geo_data = procesarGeoDatosGeocode(place)

            actual.start_point      = {
                                        type: "Point",
                                        coordinates: [
                                            geo_data.lng, geo_data.lat
                                        ]
                                    }
            actual.location         = {
                                        latitude:geo_data.lat,
                                        longitude:geo_data.lng
                                    }
            actual.description      = place.formatted_address
            actual.geo_data         = geo_data
            actual.details          = place
                                    
            return actual
        })
                                
                            
    }

    const onChangeConfigRuteo = (e) => {
        const { name, value } = e.target
        return setConfigRuteo(prev => {
            let actual = {...prev}
            actual[name] = value
            return actual
        })
    }

    const onChangeConfigRuteoSelect = (val, name) => {
        return setConfigRuteo(prev => {
            let actual = {...prev}
            actual[name] = val
            return actual
        })
    }
    
    const handleChangeConfig = (value, name) => {
        return setConfigRuteo(prev => {
            let actual = {...prev}
            actual[name] = value
            return actual
        })
    }

    const mostrarRecursosSegunVista = () => {
        switch (tipoVista) {
            case "Puntos":
                return mostrarPuntos()
            case "Vehículos":
                return mostrarVehiculos()
            default:
                break;
        }
    }

    const handleChangeSegment = (value) => {
        setTipoVista(value)
    }

    const handleOk = () => {
        setShowModalConfig(false)
    }

    const handleCheckDescanso = (e) => {
        setConfigRuteo(prev => {
            let actual = {...prev}
            actual.descanso = e
            return actual
        })
    }
    
    const handleCheckConfigRuteo = (e, name) => {
        setConfigRuteo(prev => {
            let actual = {...prev}
            actual[name] = e


            if(name === "activate_boxes") if(e === true){
                const inactivos = puntos.filter(p => {
                    let cantidad = 0
                    if(!isNaN( parseInt(p.bultos) )) cantidad = parseInt(p.bultos)
                    return cantidad < 1
                })
                console.log({ inactivos })
                if(inactivos.length > 0) messageApi.warning("Existen puntos sin bultos")
            }
            if( name === "activate_weight" ) if(e === true){
                const inactivos = puntos.filter(p => {
                    let cantidad = 0
                    if(!isNaN( parseFloat(p.peso_vol) )) cantidad = parseFloat(p.peso_vol)
                    return cantidad > 0 ? false : true
                })
                console.log({ inactivos })
                if(inactivos.length > 0) messageApi.warning("Existen puntos sin peso")
            }
            return actual
        })
    }

    const modalConfiguracion = () => {

        const componente_capacidad = <div>
            <Form layout="vertical">
            <Row gutter={15}>
                            <Col span={24}>
                                <Title level={4} className="mt-0 mb-3">Capacidad general de vehículos</Title>
                            </Col>
                            <Col span={12}>
                                <Switch className="mb-3" checkedChildren="RUTEAR POR BULTOS ACTIVADO" unCheckedChildren="TOCA PARA TOMAR EN CUENTA LOS BULTOS" onChange={(val) => handleCheckConfigRuteo(val, 'activate_boxes')} defaultChecked={configRuteo.activate_boxes} />
                                <Form.Item label="Máximo de bultos por vehículo">
                                <Input disabled={!configRuteo.activate_boxes} type="number" name="boxes" defaultValue={configRuteo.boxes} onChange={onChangeConfigRuteo} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Switch className="mb-3" checkedChildren="RUTEAR POR PESO ACTIVADO" unCheckedChildren="TOCA PARA TOMAR EN CUENTA EL PESO" onChange={(val) => handleCheckConfigRuteo(val, 'activate_weight')} defaultChecked={configRuteo.activate_weight} />
                                <Form.Item label="Máximo de peso por vehículo">
                                <Input disabled={!configRuteo.activate_weight} type="number" name="weight" defaultValue={configRuteo.weight} onChange={onChangeConfigRuteo} />
                                </Form.Item>
                            </Col>
                        </Row>
                        </Form>
        </div>

        const componente_horario = <div>
            <Form layout="vertical">
            <Row gutter={15}>
                            <Col span={24}>
                                <Title level={4} className="mt-0 mb-0">Horario de trabajo de vehículos</Title>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Desde">
                                <Input type="time" name="horario_desde" defaultValue={configRuteo.horario_desde} onChange={onChangeConfigRuteo} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Hasta">
                                <Input type="time" name="horario_hasta" defaultValue={configRuteo.horario_hasta} onChange={onChangeConfigRuteo} />
                                </Form.Item>
                            </Col>
                        </Row>
                        </Form>
        </div>

        const componente_recogidas = <div>
            <Form layout="vertical">
            <Row gutter={15}>
                            <Col span={24}>
                                <Title level={4} className="mt-0 mb-0">Ventana horaria para recogidas</Title>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Desde">
                                <Input type="time" name="pickup_earliest" defaultValue={configRuteo.pickup_earliest} onChange={onChangeConfigRuteo} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Hasta">
                                <Input type="time" name="pickup_latest" defaultValue={configRuteo.pickup_latest} onChange={onChangeConfigRuteo} />
                                </Form.Item>
                            </Col>
                            <Col md={8}>
                                <Form.Item label="Tiempo">
                                    <Input type="number" name="duration_pickup" defaultValue={configRuteo.duration_pickup} onChange={onChangeConfigRuteo} />
                                </Form.Item>
                            </Col>
                            <Col md={24}>
                            <Form.Item label="Es estricto el tiempo de llegada en relación a la duración de la parada?">
                                <Select defaultValue={configRuteo.pickup_type} options={optiones_estricto_tiempo} onChange={(val) => onChangeConfigRuteoSelect(val,'pickup_type')} />
                            </Form.Item>
                            </Col>
                        </Row>
            </Form>
        </div>

        const componente_entregas = <div>
            <Form layout="vertical">
            <Row gutter={15}>
                            <Col span={24}>
                                <Title level={4} className="mt-0 mb-0">Ventana horaria para entregas</Title>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Desde">
                                <Input type="time" name="dropoff_earliest" defaultValue={configRuteo.dropoff_earliest} onChange={onChangeConfigRuteo} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Hasta">
                                <Input type="time" name="dropoff_latest" defaultValue={configRuteo.dropoff_latest} onChange={onChangeConfigRuteo} />
                                </Form.Item>
                            </Col>
                            <Col md={8}>
                                <Form.Item label="Tiempo en entrega">
                                    <Input type="number" name="duration_dropoff" defaultValue={configRuteo.duration_dropoff} onChange={onChangeConfigRuteo} />
                                </Form.Item>
                            </Col>
                            <Col md={24}>
                            <Form.Item label="Es estricto el tiempo de llegada en relación a la duración de la parada?">
                                <Select defaultValue={configRuteo.dropoff_type} options={optiones_estricto_tiempo} onChange={(val) => onChangeConfigRuteoSelect(val,'dropoff_type')} />
                            </Form.Item>
                            </Col>
                        </Row>
            </Form>
        </div>

        const componende_tiempo_descanso = <div>
            <Switch checkedChildren="DESCANSO ACTIVADO" unCheckedChildren="TOCA PARA ACTIVAR DESCANSO" onChange={handleCheckDescanso} defaultChecked={configRuteo.descanso} />
            {
                configRuteo.descanso === true ? <div>
                <Form layout="vertical">
                <Row gutter={15}>
                                <Col span={24}>
                                    <Title level={4} className="mt-0 mb-0">Horario de descanso para el conductor de vehículo</Title>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Duración (Min.)">
                                    <Input type="number" name="break_duration" defaultValue={configRuteo.break_duration} onChange={onChangeConfigRuteo} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Hora más temprana para el descanso">
                                    <Input type="time" name="break_earliest_start" defaultValue={configRuteo.break_earliest_start} onChange={onChangeConfigRuteo} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Hora más tarde para el descanso">
                                    <Input type="time" name="break_latest_end" defaultValue={configRuteo.break_latest_end} onChange={onChangeConfigRuteo} />
                                    </Form.Item>
                                </Col>
                            </Row>
                </Form>
                </div> : false
            }
            
        </div>

        const configuraciones = [
            {
                label: "Capacidad",
                children: componente_capacidad
            },
            {
                label: "Horario de trabajo",
                children: componente_horario
            },
            {
                label: "Horario de descanso",
                children: componende_tiempo_descanso
            },
            {
                label: "Ventana horaria para recogidas",
                children: componente_recogidas
            },
            {
                label: "Ventana horaria para entregas",
                children: componente_entregas
            }
        ]

        return <div ref={configuracionGeneralRef}>
            <Modal width={"70%"} title="Configura tus preferencias" open={showModalConfig} onOk={handleOk} onCancel={handleOk} footer={[]} >
                <Tabs defaultActiveKey="1" items={configuraciones.map((item,i) => ({...item, key: (i+1)}))} />
            </Modal>
            <Button style={{ width: "100%" }} className="mb-3" icon={<IoSettingsSharp />} onClick={() => setShowModalConfig(true)} >CONFIGURACIÓN GENERAL</Button>
        </div>
    }

    const resumenRuta = (paradas) => {
        if(!paradas) return false
        if(Array.isArray(paradas) !== true) return false
        if(paradas.lenght < 1) return false
        return <div>
            <Row gutter={15}>
                <Col xs={8}>
                <Paragraph className="mb-0" style={{ fontWeight: "bold" }} >Hora inicio</Paragraph>
                <Paragraph>{fechaUTCATiempo(paradas[0].eta)}</Paragraph>
                </Col>
                <Col xs={8}>
                <Paragraph className="mb-0" style={{ fontWeight: "bold" }} >Hora término</Paragraph>
                <Paragraph>{fechaUTCATiempo(paradas[(paradas.length - 1)].eta)}</Paragraph>
                </Col>
                <Col xs={8}>
                <Paragraph className="mb-0" style={{ fontWeight: "bold" }} >Puntos</Paragraph>
                <Paragraph>{paradas.length}</Paragraph>
                </Col>
            </Row>
        </div>
    }

    const handleMouseEnter = (id_vehiculo) => {
        return setVehicleHover(id_vehiculo)
    }
    
    const handleMouseEnterPoint = (id_vehicle, i_point) => {
        return setPointHover({ id_vehicle, i_point })
    }

    const handleMouseLeavePoint = () => {
        return setPointHover(false)
    }

    const handleMouseLeave = () => {
        return setVehicleHover(false)
    }

    const mostrarNoIncluidos = () => {
        if(!resultados) return false
        if(!resultados.dropped) return false
        if(!resultados.dropped.shipments) return false
        if(resultados.dropped.shipments.length < 1) return false

        let paradas = {
            warehouse: {
                description: origen.description
            }
        }
        for( const point of resultados.dropped.shipments ){
            paradas[point.name] = point
        }

        return <div>
            {JSON.stringify(resultados.dropped.shipments)}
            <Title level={4} className="mt-0 mb-0">{resultados.dropped.length} Puntos no incluidos</Title>
            {
                resultados.dropped.shipments.map((drop,i) => {
                    return <div key={`drop-${i}`}>
                        {drop.name}
                        <Paragraph className="mb-0" >{paradas[drop.name]?.description}</Paragraph>
                        {(i+1) < resultados.dropped.shipments.length ? <Divider style={{ marginBottom: 10, marginTop: 10 }} /> : false}
                    </div>
                })
            }
        </div>
    }

    const abrirModal = () => {
        return setModalRuteo(true)
    }

    const showByVisualizationType = () => {
        switch (visualizationType) {
            case "routing":
                return <div>
                    <Form layout="vertical">
                    <Row gutter={10}>
                        <Col xs={24}>
                        <Segmented defaultValue={tipoVista} ref={puntosVehiculosRef} onChange={handleChangeSegment} options={[`Puntos`,'Vehículos']} block />
                        <div ref={paradasRef} style={{ marginTop: 20 }}>{mostrarRecursosSegunVista()}</div>
                        </Col>
                        {/* <Col md={24}>
                        <SelectorGlobal fields_search={[ "nombres","apellido_m","apellido_p" ]} titulo="Conductor" value={usuario.id_conductor} condicion={{ }} module="conductores" key_label="nombres" key_value="_id" onChange={(data) => agregarFiltro('id_conductor', data)} />
                        </Col>
                        <Col md={24}>
                        <SelectorGlobal titulo="Vehículo" value={usuario.id_vehiculo} labelFunction={getLabelForVehicle} condicion={{ }} module="vehiculos" key_label="patente" key_value="_id" onChange={(data) => agregarFiltro('id_vehiculo', data)} />
                        </Col>
                        <Col xs={24}>
                        <Form.Item label="Acepta postulaciones de terceros">
                        <Switch checkedChildren="ACEPTAR" unCheckedChildren="NO ACEPTAR" checked={usuario.postullable === true ? true : false} onChange={(e) => agregarFiltro("postullable", e)} />
                        </Form.Item>
                        </Col> */}
                    </Row>     
                    
                    <Button ref={botonRutearRef} type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => abrirModal({})} >RUTEAR</Button>
                    </Form>
                </div>
            case "results":

                let paradas = {
                    warehouse: {
                        description: origen.description
                    }
                }
                for( const point of puntos ){
                    paradas[point._id] = point
                }

                const results = resultados.routes.map((route,pos) => {
                    let identificador = route.vehicle
                    const i = vehiculos.findIndex(v => v._id === identificador)
                    if( i > -1 ) identificador = vehiculos[i].titulo
                    return {
                        key: `route-${pos}`,
                        label: <div
                            onMouseEnter={(e) => handleMouseEnter(route.vehicle)}
                            onMouseLeave={handleMouseLeave}
                        ><Title level={5} className="mt-0 mb-0"><FaCircle color={colores[pos]} /> {identificador} <Tag >{route.stops.filter(p => p.type === "dropoff").length} Entregas</Tag> </Title></div>,
                        children: <div>
                            {resumenRuta(route.stops)}
                            {
                                route.stops.map((stop,ii) => {
                                    return <div key={`${route.vehicle}-${ii}`}
                                    onMouseEnter={(e) => handleMouseEnterPoint(route.vehicle, ii)}
                                    onMouseLeave={handleMouseLeavePoint}
                                    >
                                        <Paragraph className="mb-0" ><b>{(ii+1)}</b> {paradas[stop.location]?.description}</Paragraph>
                                        <Tag style={{ marginTop: 5 }} color={stop.type === "dropoff" ? "blue" : "orange"}><MdPlace /> {stop.type === "dropoff" ? "ENTREGA" : "RECOGIDA"}</Tag>
                                        <Tag color="blue" className="mb-3" >ETA {fechaUTCATiempo(stop.eta)}</Tag>
                                        {(ii+1) < route.stops.length ? <Divider style={{ marginBottom: 10, marginTop: 10 }} /> : false}
                                    </div>
                                })
                            }
                        </div>
                    }
                })

                return <div style={{ height: "80vh", maxHeight: "80vh", overflowY: "scroll", overflowX: "hidden" }}>
                    <Button className="mb-3" size="small" icon={<FaArrowLeft />} onClick={() => setVisualizationType('routing')} >VOLVER</Button>
                    {mostrarNoIncluidos()}
                    <Collapse defaultActiveKey="1" items={results} />
                    <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => confirmarRutas({})} >CONFIRMAR RUTAS</Button>
                </div>
            default:
                break;
        }
    }

    const botonVolver = () => {
        if(resultados){
            if( visualizationType !== "results" ) return <Button className="mb-3" type="primary" style={{ width: "100%" }} size="small" icon={<MdOutlinePlace />} onClick={() => setVisualizationType('results')} >VER RUTA SUGERIDA</Button>
        }
    }

    const dismissTour = (type) => {
        let instancia_esquema = JSON.parse( JSON.stringify(interfaz) )
        setOpenTour(false)
        if(!instancia_esquema.tours) instancia_esquema.tours = {}
        instancia_esquema.tours[type] = false
        return dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
    }

    const ErrorCreacion = () => {
        return <div></div>
    }

    const mensajeRegistrosCreados = () => {
        if(!registrosCreados.rutas) return <ErrorCreacion />
        if(!Array.isArray(registrosCreados.rutas)) return <ErrorCreacion />
        if(registrosCreados.rutas.length < 1) return <ErrorCreacion />
        return <div>
            <img src={`${url_images}/Mobile Gps.gif`} style={{ maxWidth: 200, marginBottom: -20, marginTop: -20 }} />
            <Title level={2} className="mb-0 mt-0">Registros creados exitosamente</Title>
            {
                registrosCreados.rutas.map((ruta,i) => {
                    return <div key={`ruta-${i}`}>
                        <Link to={`${rutas.rutas.slug}/${ruta._id}`} target="_blank" ><Title level={4} className="mt-0 mb-0">RUTA {ruta.id} <MdOpenInNew /></Title></Link>
                    </div>
                })
            }
        </div>
    }

    function calcularHorasOperativas(desde, hasta, objetos) {
        const tiempoEnMinutos = (hora) => {
          const [horas, minutos] = hora.split(':').map(Number);
          return horas * 60 + minutos;
        };
      
        let totalMinutos = 0;
        const horasPorObjeto = [];
      
        objetos.forEach(objeto => {
          const desdeObj = objeto.horario_desde || desde;
          const hastaObj = objeto.horario_hasta || hasta;
      
          const minutosDesde = tiempoEnMinutos(desdeObj);
          const minutosHasta = tiempoEnMinutos(hastaObj);
      
          const minutosOperativos = minutosHasta - minutosDesde;
          totalMinutos += minutosOperativos;
      
          horasPorObjeto.push({
              ...objeto,
              desde: desdeObj,
              hasta: hastaObj,
              horasOperativas: duracionTiempoString(minutosOperativos),
          });
        });
      
        return {
          totalHorasOperativas: totalMinutos,
          horasPorObjeto
        };
      }

      const mensaje_tolltip = (mensaje, content) => {
        return <Tooltip title={mensaje} >{content}</Tooltip>
    }


    const TotalDropoff = () => {
        if (puntos.length === 0) {
            const totalDropoffMinutes = configRuteo.duration_dropoff * vehiculos.length;
            const totalPickupMinutes = configRuteo.duration_pickup * vehiculos.length;
    
            return (
                <div>
                    <h6 className="" style={{fontSize:16}}><IoMdTime /> Tiempo destinado a la Entrega: {totalDropoffMinutes}{vehiculos.length === 1 ? 'min' : 'mins'}</h6>
                    <h6 className="" style={{fontSize:16}}><IoMdTime /> Tiempo destinado a la Recogida: {totalPickupMinutes}{vehiculos.length === 1 ? 'min' : 'mins'}</h6>
                    <hr />
                    <h6 className="mb-3 text-right" style={{fontSize:16}}><b>Tiempo total:</b> {(totalDropoffMinutes + totalPickupMinutes)}mins</h6>
                </div>
            );
        } else {
            
            const totalPickupMinutes = puntos.reduce((acc, orden) => {
                const durationPickup = parseInt(configRuteo.duration_pickup);
                return acc + durationPickup;
            }, 0);

            const totalDropoffMinutes_ = puntos.reduce((acc, orden) => {
                let duration = orden.duration_dropoff ? parseInt(orden.duration_dropoff) : parseInt(configRuteo.duration_dropoff);
                if (isNaN(duration)) duration = parseInt(configRuteo.duration_dropoff);
                return acc + duration;
            },0)

            const puntos_personalizados = puntos.filter(e => e.duration_dropoff)
            const calculos = calcularHorasOperativas(configRuteo.horario_desde, configRuteo.horario_hasta, vehiculos);
            const tiempo_para_ruteo = calculos.totalHorasOperativas - Math.round(totalDropoffMinutes_ + totalPickupMinutes)
            return (
                <div>
                    <Title className="mt-0 mb-0" level={4}>Tiempo entre las recogidas y entregas</Title>
                    <Row gutter={15}>
                        <Col md={8}>
                            <Card className="p-3 mb-3">
                                <FaTruckLoading size={40} />
                                <Title level={5} className="mb-0 mt-0"><IoIosTimer /> Recodiga</Title>
                                <Paragraph className="mb-0 mt-0" style={{ fontSize: 11 }}>Tiempo durante la acción de recoger</Paragraph>
                                <Title className="mb-0 mt-0" level={5}>{ Math.round(totalPickupMinutes) >= calculos.totalHorasOperativas ? mensaje_tolltip("Tiempo muy alto", <IoMdAlert color="red" />) : false } <b>{duracionTiempoString(Math.round(totalPickupMinutes))}</b></Title>
                            </Card>
                        </Col>
                        <Col md={8}>
                            <Card className="p-3 mb-3">
                                <FaTruckLoading size={40} />
                                <Title level={5} className="mb-0 mt-0"><IoIosTimer /> Entrega</Title>
                                <Paragraph className="mb-0 mt-0" style={{ fontSize: 11 }}>Tiempo durante la acción de entregar</Paragraph>
                                <Title level={5} className="mb-0 mt-0">{ Math.round(totalDropoffMinutes_) >= calculos.totalHorasOperativas ? mensaje_tolltip("Tiempo muy alto", <IoMdAlert color="red" />) : false } <b>{duracionTiempoString(Math.round(totalDropoffMinutes_))}</b></Title>
                            </Card>
                        </Col>
                        <Col md={8}>
                            <Card className="p-3 mb-3">
                                <IoIosTimer size={40} />
                                <Title level={5} className="mb-0 mt-0">Total</Title>
                                <Paragraph className="mb-0 mt-0" style={{ fontSize: 11 }}>Tiempo entre recoger y entregar, no incluye tránsito</Paragraph>
                                <Title level={5} className="mb-0 mt-0">{ Math.round(totalDropoffMinutes_ + totalPickupMinutes) >= calculos.totalHorasOperativas ? mensaje_tolltip("Tiempo muy alto", <IoMdAlert color="red" />) : false } <b>{(duracionTiempoString(Math.round(totalDropoffMinutes_ + totalPickupMinutes)))}</b></Title>
                            </Card>
                        </Col>
                    </Row>
                    { puntos_personalizados.length > 0 ? <Alert type="error" className="mb-3" description={<div>Configuraste {puntos_personalizados.length} puntos con tiempos de entrega especiales, los encontrarás con la etiqueta <Tag variant="dark"><FaRegClock /> Duración</Tag> debajo de la dirección en el listado</div>}></Alert> : false }
                    <Title className="mt-0 mb-0" level={4}>Resumen de vehículos</Title>
                    { Math.round(totalDropoffMinutes_ + totalPickupMinutes) >= calculos.totalHorasOperativas ? <Alert type="error" className="mb-3" description={<div>El tiempo total entre recoger y entregar supera el tiempo total de operación de los vehículos, agrega más vehículos o modifica los tiempos de recogida y/o entrega</div>} ></Alert> : false }
                            <Table className="mb-3" dataSource={calculos.horasPorObjeto} tableLayout="fixed" pagination={false} size="small" bordered scroll={estilo_moving_truck.scroll_row} >
                                    <Column title="Patente" render={(record) => <span>{record.patente}</span>} />
                                    <Column title="Horario" render={(record) => <span>De {record.desde} a {record.hasta}</span>} />
                                    <Column title="Horas operativas" render={(record) => <span>{record.horasOperativas}</span>} />
                            </Table>
                    <Card className="p-3 mt-2 text-center">
                    <Title className="mt-0 mb-0" level={4}>Tiempo disponible para el ruteo</Title>
                    <p className="mb-0" style={{ fontSize: 11 }}>Este es el tiempo que queda para la ruta después de descontar los tiempos operativos de los vehículos (recogida + entrega / horario de trabajo)</p>
                    { tiempo_para_ruteo <= 0 ? <Title level={5} className="mt-0 mb-0"><IoMdAlert color="red" /> No hay tiempo suficiente para rutear. {duracionTiempoString(tiempo_para_ruteo)}</Title> : <Title className="mb-0 mt-0" level={4} ><b>{duracionTiempoString(tiempo_para_ruteo)}</b></Title> }
                    </Card>
                </div>
            );
        }
    };


    
    const modalContent = () => {
        const totalPickupMinutes = puntos.reduce((acc, orden) => {
            const durationPickup = parseInt(configRuteo.duration_pickup);
            return acc + durationPickup;
        }, 0);

        const totalDropoffMinutes_ = puntos.reduce((acc, orden) => {
            let duration = orden.duration_dropoff ? parseInt(orden.duration_dropoff) : parseInt(configRuteo.duration_dropoff);
            if (isNaN(duration)) duration = parseInt(configRuteo.duration_dropoff);
            return acc + duration;
        },0)

        const calculos = calcularHorasOperativas(configRuteo.horario_desde, configRuteo.horario_hasta, vehiculos);
        const tiempo_para_ruteo = calculos.totalHorasOperativas - Math.round(totalDropoffMinutes_ + totalPickupMinutes)

        
        if (vehiculos.length === 0) {
            return <div style={{ textAlign: "center" }}>
                <IoWarning size={40} />
                <Title level={4} className="mt-0 mb-0"><LuMousePointerClick className='text-dark' style={{fontSize:25}}/> Seleccione un vehículo para mostrar más información.</Title>
            </div>
        } else {
            return (
                <div>
                    {TotalDropoff()}
                    <Button style={{ width: "100%" }} className="mt-3" type="primary" disabled={loading || tiempo_para_ruteo <= 0} variant="success" onClick={() => crearConductorNuevo()}>
                        {loading ? "RUTEANDO, ESPERE UN MOMENTO..." : 'RUTEAR'}
                    </Button>
                </div>
            );
        }
    }

    const modalFinal = () => {
        return <Modal  
        footer={[]}
        // title={}
        open={registrosCreados !== false} 
        >
            {mensajeRegistrosCreados()}
            
        </Modal>
    }

    const onSelectMapPin = (id) => {
        const identificador = `parada-${id}`
        if(document.getElementById(identificador)){
            document.getElementById(identificador).scrollIntoView({ behavior: 'smooth' })
            document.getElementById(identificador).click()
        }
    }

    const render = () => {
        if(loadingMaster) return <Card><CargandoTabla /></Card>
        if(!usuario) return <Card><Title>No hay suficientes datos para mostrar esta pantalla</Title> </Card>

        return <div>
            {modalFinal()}
            <Tour  open={openTour} onClose={() => dismissTour('routing')} steps={steps} />
            <Card className="mb-3">
            <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.rutas.slug,
                    title: <span>Rutas</span>,
                },
                {
                    title: 'Ruteo inteligente',
                },
                ]}
            />
            <Title className="mb-2 mt-0">Ruteo inteligente</Title>
            <Button size="small" onClick={() => setOpenTour(true)}>¿CÓMO FUNCIONA?</Button>
            </Card>
            <Layout style={{ padding: 20 }}>

            <Row gutter={20}>
                <Col md={16} style={{ marginBottom: 20 }}>
                <Card size="small">
                <Form layout="vertical">
                    <Row gutter={15}>
                        <Col md={24} ref={origenRef}>
                            <Form.Item label="Origen" className="mb-3" >
                            <ReactGoogleAutocomplete
                                className="inputlocation mb-0"
                                options={{
                                    types: ["address"],
                                    // cokmponentRestrictions: { country: pais },
                                }}                    
                                apiKey={maps_key}
                                style={{ marginBottom: 20 }}
                                onPlaceSelected={(place) => definirOrigen(place)}
                                />
                            </Form.Item>
                            
                        </Col>
                    </Row>
                </Form>
                </Card>
                    <MapaRuteo onSelect={(id) => onSelectMapPin(id)} hovered={vehicleHover} point_hovered={pointHover} height={"90vh"} markers={marcadores} resultados={resultados} />
                </Col>
                <Col md={8}>
                <Card size="small" style={{ marginBottom: 20 }}>
                {modalConfiguracion()}
                {botonVolver()}
                {showByVisualizationType()}
            </Card>

                </Col>

                {/* <Col md={24} className="mb-3">
                    <Card size="small">
                        <Title className="mt-0 mb-0"><MdOutlinePlace /> Viajes</Title>
                        {mostrarEstados()}
                        <ListadoViajes onSelect={(data,action) => onSelect(data,action)} componentAction={componentes_accion} showSelection={true} condicion_forzed={{ id_ruta: id }} condicion_default={{ id_ruta: id }} />
                    </Card>
                </Col> */}
            </Row>
            </Layout>
            <Modal  
            footer={[]}
            onCancel={() => setModalRuteo(false)}
            // title={}
            open={modalRuteo} 
            >
                {modalContent()}
                {/* <img src={`${url_images}/Mobile Gps.gif`} style={{ maxWidth: 200, marginBottom: -20, marginTop: -20 }} />
                <Title level={2} className="mb-0 mt-0">Espera un momento, estamos procesando la información</Title>
                <Title level={4} className="mb-0 mt-0">Límite de API 200 registros, se ignorará el resto</Title> */}
            </Modal>
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default DetallesRuteo