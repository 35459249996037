import Paragraph from "antd/es/typography/Paragraph"
import Title from "antd/es/typography/Title"
import { url_images } from "../../lib/backend/data"

const ProductosVacios = () => {

    return <div>
        <img src={`${url_images}/animations/Money.gif`} style={{ maxWidth: 200 }} />
        <Title level={4} style={{ marginTop: -30 }}>Agrega items a la cotización</Title>
        <Paragraph>Para agregar productos a la cotización, haz click en el botón "AÑADIR PRODUCTO"</Paragraph>
    </div>
}

export default ProductosVacios