import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import { PlusCircleFilled, SaveOutlined } from "@ant-design/icons";
import CargandoTabla from "./cargando";
import { AiFillMinusCircle, AiOutlinePlus } from "react-icons/ai";
import { estilo_moving_truck } from "../../lib/estilo_sitio";
import { tipos_ingreso } from "./data";

const EditarNuevoCampoPersonalizado = (props) => {
    const {
        id_registro
    } = props
    const [ campo, setCampo ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(true)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()

    const obtenerDatos = async () => {
        if(!id_registro) return messageApi.error("ID no válido")
        setLoading(true)
        return fetch(`${urlapi}/campos-personalizados?id=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setCampo(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const opcionesInvalids = () => {
        return messageApi.error("Opciones inválidas para tipo de selección")
    }

    const guardarCambios = async () => {
        if(!campo.title) return messageApi.error("Escribe un título")
        if(campo.title.length < 3) return messageApi.error("Escribe un título válido")

        if(campo.tipo_ingreso === "seleccion"){
            if(!campo.opciones) return opcionesInvalids()
            if(campo.opciones.length < 1) return opcionesInvalids()
            const filter = campo.opciones.filter(e => !e.value)
            if(filter.length > 0) return opcionesInvalids()
        }

        setLoadingSave(true)
        return fetch(`${urlapi}/campos-personalizados`,{
            method:'PUT',
            body: JSON.stringify(campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                setIsModalOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoadingSave(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
      obtenerDatos()
    };

    const handleOk = () => {
        return guardarCambios()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }

    const handleChangeSelect = (name, value) => {
        campo[name] = value
        return setCampo({...{}, ...campo})
    }

    const handleChangeOpcion = (e,pos) => {
        return setCampo(prev => {
            let actual = {...prev}
            actual.opciones[pos].value = e.target.value
            return {...{}, ...actual}
        })
    }

    const eliminarOpcion = (i) => {
        return setCampo(prev => {
            const actual = {...prev}
            actual.opciones.splice(i,1)
            return {...{}, ...actual}
        })
    }

    const mostrarOpciones = () => {
        if(!campo.opciones) return false
        return <div style={{ marginTop: 15 }}>
            {
                campo.opciones.map((opcio,ip) => {
                    return <div key={`op-${ip}`}>
                        <Row>
                            <Col xs={2}><AiFillMinusCircle className="hover"  onClick={() => eliminarOpcion(ip)} size={30} color={estilo_moving_truck.colors.danger} /></Col>
                            <Col xs={22}>
                                <Form.Item required>
                                    <Input style={{ width: "100%" }} value={opcio.value} onChange={(e) => handleChangeOpcion(e,ip)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        
                    </div>
                })
            }
        </div>
    }

    const agregarValor = () => {
        return setCampo(prev => {
            let actual = {...prev}
            if(!actual.opciones) actual.opciones = []
            actual.opciones.unshift({
                value: ""
            })
            return {...{}, ...actual}
        })
    }

    const opcionesBox = () => {
        if(!campo) return false
        if(campo.tipo_ingreso !== "seleccion") return false
        return <div style={{ marginTop: 15 }}>
            <Button size="small" onClick={() => agregarValor()}><AiOutlinePlus style={{ verticalAlign: "middle" }} /> AGREGAR NUEVO VALOR</Button>
            {mostrarOpciones()}
        </div>
    }
    
    const formulario = () => {
        if(loading) return <CargandoTabla />

        return <div>
            <Form layout="vertical">
                <Form.Item label="Nombre">
                    <Input name="title" defaultValue={campo.title} onChange={handleChange} />
                </Form.Item>
                <Form.Item label="Tipo de ingreso" className="mb-2" required>
                    <Select
                    options={tipos_ingreso}
                    defaultValue={campo.tipo_ingreso}
                    onChange={(e) => handleChangeSelect('tipo_ingreso',e)} 
                    />
                </Form.Item>
                <Button type="primary" loading={loadingSave} onClick={() => guardarCambios()} ><SaveOutlined />GUARDAR CAMBIOS</Button>
            </Form>
            {opcionesBox()}
        </div>
    }
    
    return <div>
        <Button  className="mb-3" type="link" onClick={showModal}>EDITAR</Button>
        <Modal 
            title="Crear nuevo campo personalizado" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
            footer={[]}
        >
        {formulario()}
      </Modal>
      {contextHolder}
    </div>
}

export default EditarNuevoCampoPersonalizado