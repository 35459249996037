import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Card, Col, Form, Input, List, Pagination, Popconfirm, Progress, Row, Table, Tooltip, Typography, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { calcularPorcentaje, fechaATextoSimple } from "../../lib/helpers/helpers"
import * as XLSX from 'xlsx'
import { FileExcelFilled, SearchOutlined } from "@ant-design/icons"
import ButtonGroup from "antd/es/button/button-group"
import { Esquema } from "../../lib/esquemas/tablas"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import { IoMdRemoveCircle } from 'react-icons/io'
import { TbHelpCircleFilled } from "react-icons/tb"
import { modificarInterfaz } from "../../redux/actions/interfaz"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import { main_data } from "./data"
import TagSinDatos from "../../subComponents/general/tag_sin_datos"
import ModalPersonalizado from "../../subComponents/general/modales/modal_personalizado"
import { formatoMoneda } from "../../lib/helpers/main"
import ListadoEntradas from "./entradas_list"
import { MdOutlinePendingActions } from "react-icons/md"
import { FaCheckCircle, FaFileExcel } from "react-icons/fa"
import { s2ab } from "../../lib/helpers/excel"
import AccionesTarea from "./acciones_tarea"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import EditarTarea from "./editar_tarea"

const ListadoTareas = (props)=> {
    const {
        condicion_default,
        formulario,
        hideSearch
    } = props
    const dispatch = useDispatch()
    const sesion                    = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [deleting, setDeleting]   = useState(false)
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ]     = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ]       = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const pais                      = useSelector(state => state.pais) 
    const session                   = useSelector(state => state.miusuario)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const idioma                    = useSelector(state => state.idioma)
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const trash                     = props.trash ? props.trash : false
    const tipo                      = props.tipo ? props.tipo : "link"
    const titulo                    = props.title ? props.title : 'registros'
    const key_interfaz              = main_data.key_interfaz
    const key_esquema               = main_data.key_esquema
    const slug_module               = main_data.slug_module

    
    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/category`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const obtenerRegistros = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${slug_module}/tareas/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    const eliminarRecurso = async (id) => {
        setDeleting(true)
        return fetch(`${urlapi}/${slug_module}?id=${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(res._id){
              messageApi.success("Eliminado exitosamente")
              obtenerRegistros(1, false)
            }
            return setDeleting(false)
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setDeleting(false)
          })
      }
  

    useEffect(() => {
        obtenerRegistros(1, condicion_default)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerRegistros(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination showSizeChanger={false} defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const activarDesactivarTabla = (value, column) => {
        const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario) )
        const i = instancia_esquema[key_interfaz].findIndex(e => e.key === column.llave)
        if(i > -1){
            instancia_esquema[key_interfaz][i].active = value
        }
        console.log({ instancia_esquema, i, column })
        return dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
    }

    const EncabezadoPesonalizado = (model) => {

        const { label, helptext } = model

        const object_delete = <Tooltip title={"No mostrar columna"}><IoMdRemoveCircle style={{ verticalAlign: "middle" }} size={20} className="hover" onClick={() => activarDesactivarTabla(false, model)} /></Tooltip>

        if(helptext) {
            return <div>
            <Tooltip title={helptext}>
            <Paragraph className="mb-0 mt-0">{label} <TbHelpCircleFilled /></Paragraph>
            </Tooltip>
            {object_delete}            
            </div>
        }

        return <>
            <Paragraph className="mb-0 mt-0">{object_delete} {label}</Paragraph>
            
        </>
    }
    
    const obtenerDetallesEsquema = (slug) => {
        const i = Esquema[ key_esquema ].findIndex(e => e.key === slug)
        if( i > -1){
            return {
                ...Esquema[ key_esquema ][i],
                llave: Esquema[ key_esquema ][i].key
            }
        }
        return {}
    }

    const procesarDatosExcel = (data) => {

        const iterar_datos = data.map(re => {

            let objeto = {
                identificador: re.identificador,
                email: re.email,
                sended: re.sended === true ? "RECIBIDO" : "NO RECIBIDO"
            }

            return Object.keys(objeto).map(key => typeof objeto[key] !== null ? objeto[key] : "Sin información")
        })

        const estructura_excel = {
            cabeceras: [
                "Nombre",
                "Email",
                "Estado"
            ],
            data: iterar_datos
        }

        return handleDownloadExcel({ cabeceras: estructura_excel.cabeceras, data: estructura_excel.data })


    }

    const handleDownloadExcel = ({cabeceras,data}) => {
        // Crear el archivo de Excel
        const wb = XLSX.utils.book_new();
        const wsData = [cabeceras]
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
        XLSX.utils.sheet_add_aoa(ws, data, { origin: -1 });
        // Convertir el libro de trabajo a un archivo binario
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    
        // Crear un Blob con los datos binarios
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    
        // Generar un objeto URL del Blob
        const url = URL.createObjectURL(blob);
    
        // Crear un enlace de descarga invisible y hacer clic en él
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Modelo Tarifario.xlsx';
        a.click();
    
        // Liberar el objeto URL
        URL.revokeObjectURL(url);
    }

    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />

        const component_title = <Column title="Descripción" render={(data) => {
            return <div>{data.descripcion}</div>
        }} />

        const component_users = <Column title="Usuarios" render={(data) => {
            const columns = [
                {
                    title: 'Identificador',
                    dataIndex: 'identificador',
                    key: 'identificador',
                    render: text => <Typography.Text style={{ fontWeight: "bold" }}>{text}</Typography.Text>,
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                    render: text => <Typography.Text style={{ fontWeight: "bold" }}>{text}</Typography.Text>,
                },
                {
                    title: 'Recibido',
                    dataIndex: 'sended',
                    key: 'sended',
                    render: sended => <Typography.Text style={{ fontWeight: "bold" }}>{sended === true ? <div style={{ color: "green" }}><FaCheckCircle /> RECIBIDO</div> : <div><MdOutlinePendingActions  /> NO RECIBIDO</div>}</Typography.Text>,
                },
            ];

            let users = data.usuarios ? data.usuarios : []
            if(!Array.isArray(users)) users = []

            const listos = users.filter(u => u.sended === true).length
            const porcentaje = calcularPorcentaje(users.length, listos)

            const component_h = <div>
                <Title level={5} className="mt-0" >{users.length} Usuarios involucrados. {users.filter(u => u.sended).length} enviaron la información, {users.filter(u => !u.sended).length} faltantes.</Title>
                <Title level={6} className="mt-0 mb-0" >Porcentaje de llenado</Title>
                <Progress percent={porcentaje} />
                <Button className="mb-3" icon={<FaFileExcel/> } onClick={() => procesarDatosExcel(users)} >EXPORTAR EXCEL</Button>
                <AccionesTarea id={data?._id} />
                <Table columns={columns} dataSource={users} rowKey="identificador" size="small" pagination={false} scroll={estilo_moving_truck.scroll_row} />
            </div>
            return <div><ModalPersonalizado typeView="modal"  version="v2" title={`VER ${users.length} USUARIOS`} component={component_h}  /></div>
        }} />

        const component_users_fill = <Column title="Recibido" render={(data) => {
            let users = data.usuarios ? data.usuarios : []
            if(!Array.isArray(users)) users = []

            const listos = users.filter(u => u.sended === true).length
            const porcentaje = calcularPorcentaje(users.length, listos)

            return <div><Progress size="small" type="circle" percent={porcentaje} /></div>
        }} />
        
        const component_entradas = <Column title="Entradas" render={(data) => {
            const component_b = <div>
                <ListadoEntradas formulario={formulario} condicion_default={{ id_regla: data._id }} />
            </div>
            return <div><ModalPersonalizado typeView="modal"  version="v2" title="VER ENTRADAS" component={component_b}  /></div>
        }} />

        const component_tipo = <Column title="Notificación" render={(data) => {
            return <div>{!data.send || data.send === "inmediatamente" ? "INMEDIATAMENTE" : "PROGRAMADO"}</div>
        }} />

        const component_slug = <Column title="Vencimiento" render={(data) => {
            return <div>{ data.vencimiento ? fechaATextoSimple(data.vencimiento) : <TagSinDatos/> }</div>
        }} />

        const component_fecha = <Column title="Creación" render={(data) => {
            return <div>{fechaATextoSimple(data.createdAt)}</div>
        }} />

        return <div>
            {paginacion(data.pagina, total)}
            <Table dataSource={conductores} size="small" pagination={false} scroll={estilo_moving_truck.scroll_row} >
                
                {component_title}
                <Column title="Entradas por revisar" render={(data) => {
                    return <div><EditarTarea id_tarea={data._id} /> </div>
                }} />
                <Column title="Entradas por revisar" render={(data) => {
                    return <div>{formatoMoneda(data.entradas_pendientes)}</div>
                }} />
                <Column title="Entradas Invalidas" render={(data) => {
                    return <div>{formatoMoneda(data.entradas_negadas)}</div>
                }} />
                <Column title="Entradas Aceptadas" render={(data) => {
                    return <div>{formatoMoneda(data.entradas_aprobadas)}</div>
                }} />
                <Column title="Entradas En revisión" render={(data) => {
                    return <div>{formatoMoneda(data.entradas_en_revision)}</div>
                }} />
                {component_users_fill}
                {component_entradas}
                {component_users}
                {component_tipo}
                {component_slug}
                {component_fecha}
{/*                 
                <Column title="Editar" render={(data) => {
                    return <div><EditarNuevoCampoPersonalizado id_registro={data._id} onCreate={() => obtenerRegistros(1, false)} /></div>
                }} />
                <Column title="Eliminar" render={(data) => {
                    return <div>
                        <Popconfirm
                    title="Eliminar"
                    description="¿Estás seguro que deseas eliminar recurso? Esta acción no se puede deshacer"
                    onConfirm={() => eliminarRecurso(data._id)}
                    onCancel={() => false}
                    okText="SI"
                    style={{ display: "inline-block"}}
                    cancelText="NO"
                  >
                  <Button loading={deleting} size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
                  </div>
                }} /> */}
            </Table>
        </div>
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setCondicionBusqueda(condicion_default);
            setPagina(1);
            return obtenerRegistros(1, condicion_default);
        }
        const condicion = {
            ...condicion_default,
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerRegistros(1, condicion);
      };

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

    const buscador = () => {
        if(hideSearch === true) return false
        return <Col span="auto"><Form onSubmitCapture={handleSubmit}>
                    <Row gutter={15}>
                        <Col md={14}>
                            <Form.Item label="Buscar">
                                <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Buscar por palabras clave`} />
                            </Form.Item>
                        </Col>
                        <Col md={10}>
                            <Form.Item>
                                <Button onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
        </Col>
      }

    return <div>
        {contextHolder}
        <Row gutter={15}>
            {buscador()}
            <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
            {/* <Col span="auto"><Button disabled className="mb-3" ><FileExcelFilled /> DESCARGAR PDF INFORMATIVO</Button></Col> */}
            <Col span="auto"><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green"  onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col>
        </Row>
        
        {mostrarRegistros()}
    </div>
}
export default ListadoTareas