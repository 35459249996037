import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Card, Col, Modal, Row, Skeleton, Tabs } from "antd"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { botones } from "../../lib/global/data"
import { TEXT_LANG } from '../../lib/lang/main'
import { BsCardChecklist } from "react-icons/bs"

const ContadoresEquipos = (props) => {
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ filtros, setFiltros ] = useState([])
    const [ loading, setLoading ] = useState(true)

    const obtenerDatos = async ()=>{
        setLoading(true)
        return fetch(`${urlapi}/equipos/contadores`,{
            method:'POST',
            body: JSON.stringify({
                condicion: {
                    status: "active"
                },
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
            } else if(Array.isArray(res) !== false){
                setFiltros(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const seleccionar = filtro => {
        if(filtro.condicion){
            if(typeof filtro.condicion === "object"){
                if(props.onFilter) props.onFilter(filtro.condicion)
            }
        }
    }

    const MostrarGrafico = (props) => {
        const { 
            campo
        } = props
        if(campo.values.length < 1) return <div><Title className="mt-0 mb-3" level={4}>Sin información</Title></div>
        
                return <div>
                    
                    <Row gutter={15}>
                    {
                        campo.values.map((filtro,i) => {
                            return <Col md={8} xs={12} key={`filtro-${i}`} onClick={() => seleccionar(filtro)}>
                            <Card size="small" className="mb-3 hover">
                                <Row>
                                    <Col xs={18}>
                                        <Paragraph className="m-0">{filtro.label.toUpperCase()}</Paragraph>
                                        <Title level={5} className="m-0">{filtro.cantidad}</Title>
                                    </Col>
                                    <Col xs={6}  style={{ textAlign: "right", marginTop:8 }}>
                                        { filtro.image ? <img style={{ width: "100%" }} src={filtro.image} /> : false}
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        })
                    }
                    </Row>
                    
            </div>
    }

    if(loading) return <Skeleton active />
    
    const items = filtros.map((filtro,i) => {
        return {
            key: i,
            label: filtro.label ? filtro.label.toUpperCase() : "Atributo desconocido",
            children: <MostrarGrafico campo={filtro} />
        }
    })
    return <div>
         <Tabs defaultActiveKey="0" items={items} />
    </div>
}

export default ContadoresEquipos