import React, { useEffect, useRef, useState } from 'react'
import Map, { FullscreenControl, GeolocateControl, Marker, NavigationControl } from 'react-map-gl';
import { cerrarSesion } from '../../redux/actions/sesion'
import { useDispatch, useSelector } from 'react-redux'
import Structure from '../Structure'
import Title from 'antd/es/typography/Title'
import { Button, Card, Col, Drawer, Popover, Row, Space, Spin, Tabs, Tooltip, message } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import { CarFilled } from '@ant-design/icons';
import { BsBoxArrowInUpRight } from "react-icons/bs"
import { ArrowUpOutlined, CarOutlined, ClockCircleOutlined, GatewayOutlined, HeatMapOutlined, IdcardOutlined, NotificationOutlined, PlusCircleFilled } from '@ant-design/icons'
import { mapbox_token, urlapi } from '../../lib/backend/data'
import ContadorVehiculosConectados from './contador_vehiculos_conectados'
import { rutas } from '../../lib/routes/routes'
import * as turf from '@turf/turf';
import Moment from 'react-moment';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa'
import ListadoConductoresMonitoreo from './conductores/listado';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import ListadoNotificaciones from '../Notificaciones/listado';
import { encontrar_elemento } from '../../lib/helpers/main';
import FuentesDato from '../../subComponents/general/fuente_datos';
import { RiSignalTowerLine } from 'react-icons/ri';
import ListadoVehiculos from '../Vehiculos/listado';
import ListadoVehiculosMonitoreo from './vehiculos/listado';
import { PiCursorClickFill } from 'react-icons/pi';

mapboxgl.accessToken = mapbox_token


const MonitoreoVehiculos = (props) => {
    const sesion = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const [ loading, setLoading] = useState(true)
    const [messageApi, contextHolder] = message.useMessage();
    const [ fuente, setFuente ] = useState('')
    const [ contadores, setContadores] = useState({})
    const pais = useSelector(state => state.pais)
    const [open, setOpen] = useState(true)
    const [ condicion_conectados, setCondicionConectados ] = useState({ conectado: true } )
    const idioma = useSelector(state => state.idioma)
    const { data, tokenSession } = useSelector(state => state.miusuario)
    const empresa = data.empresa
    const [ marcadores, setMarcadores ] = useState([])
    const initialViewState = obtenerCentroMapaPorPais(pais)
    const [intervalo, setIntervalo] = useState(false)
    const [viewState, setViewState] = useState(initialViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()
    const controls = []

    const handleGeocoderResult = (event) => {
        if (event.result && event.result.geometry && event.result.geometry.coordinates) {
          const [lng, lat] = event.result.geometry.coordinates;
          setViewState({ ...viewState, longitude: lng, latitude: lat, zoom: 12 });
        }
      };

    const geocodificador = () => {

        const findA = document.getElementsByClassName('mapboxgl-ctrl-geocoder').length > 0
        if(findA) return false

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false, // Desactivar el marcador de ubicación seleccionada
            placeholder: "Busca una dirección",
            countries: pais
        });
        geocoder.on('result', handleGeocoderResult);
        setTimeout(() => {
            if(controls.length < 1){
                mapRef.current?.addControl(geocoder, "top-right")
            }
          controls.push('geocoder')
        }, 200);
      }

      const handleLoadMap = () => {
          geocodificador()
      }

    const ajustarCentro = (markers) => {
        if(markers.length < 1) return false
        if(zoomInit) return false
        if(markers.length === 1){
            return centrarMapa(markers[0], 7)
          }
        setZoomInit(true)
        const points = markers.map(marker => turf.point([marker.longitude, marker.latitude]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 30 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current.fitBounds(bounds, options);
    }
  
    const consultarContador = async ()=>{
        return fetch(`${urlapi}/conductores/counter/actives`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.data){
                setContadores(res.data)
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Ocurrió un problema al cargar la información")
            return setLoading(false)
        })
    }
    

    useEffect(() => {
        consultarContador()

        refrescarListado(true)
        const id_intervalo = setInterval(() => {
            refrescarListado()
        }, 5000)

        setIntervalo(id_intervalo)
        
        return () => {
            clearInterval(id_intervalo)
        };
    }, [])

    const refrescarListado = async (ajustar_centro, canal)=>{
        let channel = (typeof canal === "undefined"  ? fuente : canal).toString()
        if(channel === 'undefined') channel = ''
        return fetch(`${urlapi}/vehiculos/vehicles-actives?canal=${channel}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return res.json() //dispatch(cerrarSesio())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                return false
            } else if(Array.isArray(res) !== false){
                if(ajustar_centro === true) ajustarCentro(res.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
                setMarcadores(prev => [...[], ...res])
                setCondicionConectados({ _id: { $in: res.map(con => con._id) } })
            }
            return false
        })
        .catch(error => {
            return false
        })
    }

    const obtenerValorContador = (key) => {
        if(loading) return <Spin />
        let valor = 0
        if(!key) return valor
        if(typeof contadores === "object"){
            if(typeof contadores[key] !== "undefined"){
                if(!isNaN(contadores[key])) valor = contadores[key]
            } 
        }
        return valor
    }


    const visualizarConductor = (driver) => {
        let max_zoom = 5
        
        if(typeof driver !== "object" ) return false
        if(!driver._id) return false
        const i = marcadores.findIndex(m => m.idusuario === driver._id.toString())
        if(i > -1){
            let longitude   = marcadores[i].lng
            let latitude    = marcadores[i].lat

            if(isNaN( parseFloat(longitude) ) || isNaN( parseFloat(latitude) ) ) return false
            centrarMapa({ longitude, latitude }, max_zoom)
        }
    }

    const mostrarInfoVehiculo = (veh) => {
        if(!veh) return false
        if(typeof veh !== "object") return false
        let modelo      = ''
        let marca       = ''

        if(typeof veh.modelo === "object") if(veh.modelo.titulo) modelo = veh.modelo.titulo
        if(typeof veh.marca === "object") if(veh.marca.titulo) marca = veh.marca.titulo

        return <div>
            <Title className='mt-0 mb-0' level={5} style={{ fontSize: 13 }}>{marca} {modelo} {veh.patente} <a target='_blank' href={`${rutas.vehicles.slug}/${veh._id}`}><BsBoxArrowInUpRight /></a></Title>
        </div>
    }
        
    const centrarMapa = (centro, max_zoom) => {
        const nuevo_zoom = initialViewState.zoom + (max_zoom ? max_zoom : 3)
        mapRef.current?.flyTo({center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000});
        setTimeout(() => {
          setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
        }, 2010);
      }

      const refrescarCiclo = async (data) => {
        clearInterval(intervalo)
        setFuente(data)
        await refrescarListado(true, data)
        const id_intervalo = setInterval(() => {
          refrescarListado(null, data)
        }, 4000);
        setIntervalo(id_intervalo)
      }

      const showDrawer = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }

    const render = () => {

        const items = [
            {
              key: '1',
              label: `CONECTADOS`,
              children: [],
            },
            {
              key: '2',
              label: `DESCONECTADOS`,
              children: <ListadoConductoresMonitoreo onSelect={(driver) => visualizarConductor(driver)} tableSize="small" typeView="funcion" hideExporter={true} condicion_default={{ conectado: false }} hideHeader={true}  />,
            }
        ]

        const items_main = [
            {
                key: "1",
                label: <Title level={3} className='m-0'>Vehículos</Title>,
                children: <div>
                    {/* <Title level={3} className='m-0'>Vehículos</Title> */}
                    <Row align="middle"><Col md={6}><Title level={4} style={{ fontSize: 15 }} className='mb-0 mt-0'><RiSignalTowerLine style={{ verticalAlign: "middle" }} /> Seleccione GPS</Title> </Col><Col md={18} style={{ textAlign: "right" }} ><FuentesDato onChange={(type) => refrescarCiclo(type)} /></Col> </Row>
                    <ListadoVehiculosMonitoreo onSelect={(driver) => visualizarConductor(driver)} tableSize="small" typeView="funcion" hideExporter={true} condicion_default={condicion_conectados}  />
                    </div>
            },
            {
                key: "2",
                label: <Title level={3} className='m-0'>Resumen</Title>,
                children: <ContadorVehiculosConectados marcadores={marcadores} />
            }
        ]

        return <div style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}>
            <Map
                id="monitoring-map"
                reuseMaps
                ref={mapRef}
                {...viewState}
                onMove={evt => setViewState(evt.viewState)}
                style={{width: "100%", height: "100vh" }}
                pitch={0}
                mapStyle="mapbox://styles/mapbox/streets-v9"
                mapboxAccessToken={mapbox_token}
                onLoad={handleLoadMap}
            >
                <GeolocateControl position="top-left" />
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
                <Button onClick={showDrawer} type="primary" icon={<PiCursorClickFill />} size="large" style={{ position: "absolute", top: 200, left: 10 }} >VEHÍCULOS</Button>
                {
                    marcadores.map(marker => {

                        const content = (
                            <div>
                                <Paragraph className='mb-0'><Moment locale="ES" fromNow>{marker.date}</Moment></Paragraph>
                                {mostrarInfoVehiculo(marker.vehiculo)}
                            </div>
                        )
                        return <Marker
                                key={marker._id}
                                longitude={marker.lng}
                                latitude={marker.lat}
                            >
                        <Popover content={content} title={<div>{marker.identificador_conductor} <a target='_blank' href={`${rutas.vehicles.slug}/${marker._id}`}><BsBoxArrowInUpRight /></a></div>}>
                            <CarFilled style={{ fontSize: 30 }} />
                        </Popover>
                        </Marker>
                    })
                }
            </Map>
            <Drawer title="LISTADO" onClose={onClose} open={open} size="large" extra={
            <Space>
            </Space>
            } >
            <Tabs defaultActiveKey="1" items={items_main} />
            </Drawer>
            {contextHolder}
        </div>
    }
    return render()
    return <Structure component={render()} />

}

export default MonitoreoVehiculos