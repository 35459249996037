import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { config_s3, urlapi } from "../../lib/backend/data";
import guardarUsuario from "../../redux/actions/guardarUsuario";
import S3FileUpload from 'react-s3';
import { fechaATextoSimple } from "../../lib/helpers/helpers";
import { Badge, Button, Card, Col, Divider, Form, Row, Skeleton, Spin, Upload, message } from "antd";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { UploadOutlined } from "@ant-design/icons";
import { gradient_primary } from "../../lib/global/style";
import ButtonGroup from "antd/es/button/button-group";
window.Buffer = window.Buffer || require("buffer").Buffer;

const ValidarEmpresa = (props) => {
    const [ empresa, setEmpresa ] = useState({ razon_social: "tu empresa"})
    const [ loading, setLoading ] = useState(true)
    const [ loadingReq, setLoadingReq ] = useState(false)
    const [ loadingValidacion, setLoadingValidacion ] = useState(false)
    const [ requisitos, setRequisitos ] = useState([])
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()

    useEffect(() => {
        if(session.data.empresa){
            console.log(session.data.empresa)
            if(typeof session.data.empresa === "object") setEmpresa({...{}, ...session.data.empresa})
        }
        validarEmpresa()
    }, [])

    const validarEmpresa = async (e) => {
        setLoading(true)
        const url = `${urlapi}/empresas/validar`
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.empresa){

                // VALIDAR SI LA EMPRESA FUE APROBADA
                if(res.empresa.status === "active"){
                    let copia_session = JSON.parse( JSON.stringify(session) )
                    copia_session.data.empresa = res.empresa
                    dispatch(guardarUsuario(copia_session))
                    return window.location = '/dashboard'
                }

                // DEFINIR REQUISITOS
                if(res.requisitos){
                    if(Array.isArray(res.requisitos) !== false){
                        setRequisitos(res.requisitos)
                    }
                }
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    const handleSubmit = (accion) => {
        setLoadingValidacion(true)
        const url = `${urlapi}/empresas/validar?accion=${accion}`
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.empresa){
                if(!["active","in_process"].includes(res.empresa.status)){
                    messageApi.error("Tu empresa aún debe validarse, asegúrate de haber cargado todos los requisitos")
                } else {
                    let copia_session = JSON.parse( JSON.stringify(session) )
                    copia_session.data.empresa = res.empresa
                    dispatch(guardarUsuario(copia_session))
                    return window.location = "/dashboard"
                }
            }
            return setLoadingValidacion(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoadingValidacion(false)
        })
    }

    const subirArchivo = async (req, valor) => {
        const payload = {
            id_target: empresa._id,
            id_permiso: req._id,
            valor
        }
        const url = `${urlapi}/empresas/requisito`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                return res
            }
            return false
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return false
        })
    }

    const handleChangeArchivo = async (e, req) => {
        if(e.file.status !== "error") return false
        setLoadingReq(req._id)
        const archivo = e.file.originFileObj
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            messageApi.error('No pudimos cargar tu imagen')
            return setLoadingReq(false)
        }

        const cargado = await subirArchivo(req, data.location)
        if(cargado){
            const pos = requisitos.findIndex(re => re._id ===  req._id)
            requisitos[pos].valor = cargado
            setRequisitos([...[], ...requisitos])
        }
            return setLoadingReq(false)
        })
        .catch(err => {
            console.log(err)
            setLoadingReq(false)
            return messageApi.error('No pudimos cargar tu imagen')
        })
    }

    const visualizarRequisitos = () => {
        if(loading) return <Skeleton active />

        return <div>
            <div className="mb-3">
            {
                requisitos.map((req,i) => {
                    return <div key={req._id} className="mb-3">
                        <Badge.Ribbon text={req.valor ? `Cargado ${fechaATextoSimple(req.valor.fecha_cargado)}` : `No has cargado ningún archivo`} color={req.valor ? "green" : "red"}>
                        <Card size="small">
                        { req.titulo ? <Title level={4} className="mb-2">{req.titulo}</Title> : false }
                        { req.descripcion ? <Paragraph>{req.descripcion}</Paragraph> : false }
                        { loadingReq === req._id ? <Spin /> : <Upload onChange={(e) => handleChangeArchivo(e, req)}> <Button icon={<UploadOutlined />}>SUBIR ARCHIVO</Button></Upload> }
                        { (requisitos.length === i+1) ? false : <Divider /> }
                        </Card>
                        </Badge.Ribbon>
                        
                  </div>
                })
            }
            </div>
            <ButtonGroup style={{ width: "100%" }}>
            <Button loading={loadingValidacion} style={{ width: "100%" }} type="primary" onClick={() => handleSubmit("")} >CONTINUAR</Button>
            <Button loading={loadingValidacion} style={{ width: "100%" }} type="default" onClick={() => handleSubmit("saltar")} >SALTAR ESTE PASO</Button>
            </ButtonGroup>
        </div>
    }

    return <div>
        <Row style={{ height: "100vh" }}>
        <Col md={8} style={{ display: "flex", padding: 30, alignItems: "center" }}>
        <Form onSubmit={handleSubmit} 
            className="login-form"
        >
       <Title level={4} className="mb-3">Carga la documentación de {empresa.razon_social}</Title>
                <Paragraph mb={5}>Sube los requisitos y valida tu empresa</Paragraph>
                {visualizarRequisitos()}
        </Form>
        </Col>
        <Col md={16} xs={0} style={{ background: gradient_primary, display: "flex", alignItems: "center" }}>
        <img style={{ width: "55%", margin: "0 auto"}} className='hidden-mobile' src="/images/1.png" />
        </Col>
        </Row>
                
                

    </div>
}

export default ValidarEmpresa