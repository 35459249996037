import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Button, Card, Col, Pagination, Row, Tabs } from 'antd';
import { FileExcelFilled, PlusCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores";
import ListadoClientes from "./listado";
import HelperModal from "../../subComponents/general/helperModal";

const EstadosDeCarga = (props) => {

    const render = () => {
        return <div>
            <Card>
            <Title className="mb-2 mt-0">Eventos</Title>
            <Row gutter={15}>
              {/* <Col span="auto"><Button style={{ marginRight: 10 }} type="primary" size="small"><Link to={`${rutas.clients.slug}/new`}><PlusCircleFilled /> CREAR NUEVO</Link></Button></Col> */}
              {/* <Col span="auto"><Button style={{ marginRight: 10 }} size="small" ><Link to={rutas.import_clients.slug}><FileExcelFilled /> IMPORTADOR</Link></Button></Col> */}
              <Col span="auto"><HelperModal tipo="clientes" style="modal" /></Col>
            </Row>
            <ListadoClientes showCustomFieldsData={true} showCounters={true} showFilters={true} condicion_default={{  }} />
            </Card>
        </div>
    }
    
    return <Structure component={render()} />
}

export default EstadosDeCarga