import React, { useEffect, useState } from "react"
import data from '../../lib/backend/data'
import { useDispatch, useSelector } from "react-redux"
import { fechaATexto, formatYMD } from "../../lib/helpers/helpers"
import { formatDateHoy } from "../../lib/helpers/dates"
import { Alert, Badge, Button, Card, Col, Form, Image, Input, Modal, Pagination, Progress, Row, Select, Spin, Tooltip, message } from "antd"
import Paragraph from "antd/es/typography/Paragraph"
import CargandoTabla from "./cargando"
import { cerrarSesion } from "../../redux/actions/sesion"
import { TbProgressAlert } from 'react-icons/tb'
import Title from "antd/es/typography/Title"


const ListadoRequisitosModelos = (props) => {
    const {
        id_target
    } = props
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const sesion = useSelector(state => state.miusuario)
    const [ vehiculos, setVehiculos] = useState([])
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ showModal, setShowModal] = useState(false)
    const [ currentRequisito, setCurrentRequisito] = useState(false)
    const [ loadingVehiculos, setLoadingVehiculos] = useState([])
    const [ loadingCambios, setLoadingCambios] = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ idLoading, setIdLoading ] = useState(false)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const [messageApi, contextHolder] = message.useMessage();
    const trash = props.trash ? props.trash : false
    const show_filter = props.show_filter ? props.show_filter : false
    const titulo = props.title ? props.title : 'registros'

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerRequisitos(page)
    }
    
    const handleChangePagina = (e,page) => {
        setPagina(page)
        return obtenerRequisitos(page, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination total={cantidad} defaultCurrent={pagina} onChange={handleChangePagina} />
        </div>
    }

    const obtenerRequisitos = async (page, query)=>{
        setLoadingVehiculos(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/requisitos/tipo/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                id_target,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingVehiculos(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingVehiculos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setVehiculos(res.datos)
                setTotal(res.total)
            }
            return setLoadingVehiculos(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingVehiculos(false)
        })
    }

    useEffect(() => {
        obtenerRequisitos(1, false)
    }, [])


    const handleChangeCampo = (e) => {
        const { value } = e.target
        return setCampoBusqueda(value)
    }

    const filtro = () => {
        if(!show_filter) return false
        return <div>
            <Row >
                <Col item xs={12}>
                    <Form layout="vertical">
                        <Form.Item label="Buscar por nombre, apellido, email o móvil">
                            <Input id="outlined-basic" variant="outlined" onChange={handleChangeCampo} />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <Row >
                <Col item xs={6}>{paginacion(data.pagina, total)}</Col>
                <Col item xs={6} style={{ textAlign: 'right' }}><h3 sx={{ m:0 }} >{total} {titulo}</h3></Col>
            </Row>
        </div>
    }

    const   seleccionarRegistro = (data) => {
        if(!props.onSelect){
            return obtenerRegistro(data._id)
        } else {
            props.onSelect(data)
        }
    }

    const obtenerRegistro = (id) => {
        if(idLoading !== false) return
        setIdLoading(id)
        return fetch(`${data.urlapi}/requisitos/asignar?id=${id}&target=${id_target}`, {
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Agregado exitosamente")
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
            return setIdLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setIdLoading(false)
        })
    }

    const handleModalRF = () => {
        return setShowModal(false)
    }

    const guardarCambios = () => {
        setLoadingCambios(true)
        return fetch(`${data.urlapi}/requisitos`,{
            method:'PUT',
            body: JSON.stringify(currentRequisito),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingCambios(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingCambios(false)
            } else if(res._id){
                messageApi.success('Actualizado exitosamente')
                const instance = vehiculos
                const i = instance.findIndex(v => v._id === res._id)
                if(i > -1){
                    instance[i] = res
                    setVehiculos(prev => [...[], ...instance])
                }
                setIsModalOpen(false)
            }
            return setLoadingCambios(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingCambios(false)
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        console.log(name, value)
        currentRequisito[name] = value
        if(name === "status"){
            if(value === "aprobado") currentRequisito.comentario = ""
        }
        return setCurrentRequisito(prev => ({...{}, ...currentRequisito}))
    }


    const handleCancel = () => {
        setIsModalOpen(false)
      };

    const ModalRegistrosFotograficos = () => {
        let titulo = 'No disponible'
        if(!isModalOpen) return false

        if(typeof currentRequisito.permiso_modelo === 'object'){
            titulo = currentRequisito.permiso_modelo.titulo
        }

        return <Modal open={isModalOpen} footer={[]} onCancel={handleCancel} >
            <Row gutter={20}>
                <Col md={12}>
                    {
                        currentRequisito.valor ? <div><Image src={currentRequisito.valor} style={{ width: '100%' }} /></div> : <div>
                            <TbProgressAlert style={{ fontSize: 20 }} />
                            <Paragraph variant="h6" xs={{ mb:0 }}>Sin datos cargados</Paragraph>
                        </div>
                    }
                </Col>
                <Col md={12}>
                    <Form layout="vertical">
                    <Paragraph variant="h6" sx={{ mb: 2 }}>Detalles</Paragraph>

                    </Form>
                    <Form.Item label="Observaciones" >
                    <Input
                        name="comentario" 
                        onChange={handleChange} 
                        value={currentRequisito.comentario} 
                        variant="outlined" fullWidth 
                    />
                    </Form.Item>
                        
                    <Form.Item label="Estado" >
                    <Select
                        name="status"
                        value={currentRequisito.status}
                        onChange={handleChange}
                        options={[
                            { value: "pendiente", label: "Pendiente" },
                            { value: "rechazado", label: "Rechazado" },
                            { value: "aprobado", label: "Aprobado" }
                        ]}
                        
                    />
                    </Form.Item>
                        
                    <Form.Item label="Fecha de caducidad (Si aplica)">
                    <Input
                        
                        type="date"
                        name="fecha_validez" 
                        defaultValue={currentRequisito.fecha_validez ? formatYMD(currentRequisito.fecha_validez) : ""}
                        onChange={handleChange}
                        />
                    </Form.Item>
                    <Button  loading={loadingCambios} variant="contained" color="success" fullWidth onClick={()=>guardarCambios()} >GUARDAR</Button>
                </Col>
            </Row>
        </Modal>
    }

    const mostrarRegistros = () => {
        if(vehiculos.length < 1) return <h3>No hay registros</h3>
        
        return <div>
            <Row gutter={10}>
            { vehiculos.map((data) => {
                let titulo = 'No disponible'
                let solicitado = false

                if(typeof data.permiso_modelo === 'object'){
                    titulo = data.permiso_modelo.titulo
                    if(data.permiso_modelo.status === "active") solicitado = true
                }

                return <Col md={8} xs={8}>
                    <Card key={data._id} size="small" className="mb-3" >
                        <Paragraph className="mt-0 mb-0" style={{ fontSize: 15 }} >{data.titulo}</Paragraph>
                        <Button size="small" loading={idLoading === data._id} onClick={() => seleccionarRegistro(data)} type="primary">AGREGAR</Button>
                </Card>
                </Col>
            })}
            </Row>
        </div>
    }

    if(loadingVehiculos) return <CargandoTabla />

    return <div>
        {filtro()}
        {ModalRegistrosFotograficos()}
        {mostrarRegistros()}
        {contextHolder}
    </div>
}

export default ListadoRequisitosModelos