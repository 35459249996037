import { Pagination, Spin, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { urlapi } from "../../lib/backend/data";
import { cerrarSesion } from "../../redux/actions/sesion";
import ListadoContactos from "../../components/Contactos/listado";
import Title from "antd/es/typography/Title";
import ModalSeleccionContactos from "../../components/Contactos/modal_seleccion";
import RegistrosVacios from "./registros_vacios";

const Relacionador = (props) => {
    const {
        condicion_default,
        type_destination,
        payload_origin_default
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ loadingActions, setLoadingActions ] = useState(false)
    const sesion = useSelector(state => state.miusuario)
    const [ loadingCreacion, setLoadingCreacion] = useState(false)
    const [ messageApi, contextHolder ] = message.useMessage();
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const [ condicionDestino, setCondicionDestino ] = useState(null)
    const dispatch = useDispatch()
    const modulo = 'general/relaciones'

    const onSelect = (datos,action) => {
        if(!action) return
        const i = componentes_accion.findIndex(it => it.label === action)
        console.log({ i, action })
        if(i > -1){
            const funcion = componentes_accion[i].action
            funcion(datos)
        }
    }

    const eliminarMultiples = async (viajes)=>{
        
        const condicion = {
            ...payload_origin_default,
            type_destination,
            id_destination: { $in: viajes.map(e => e._id) }
        }
        setLoadingActions(true)
        return fetch(`${urlapi}/${modulo}/masive`,{
            method:'DELETE',
            body: JSON.stringify({
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.deletedCount){
                obtenerRegistros(condicion_default)
            }
            return setLoadingActions(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingActions(false)
        })
    }
    
    const componentes_accion = [
        {
            label: "DESVINCULAR",
            action: eliminarMultiples
        }
    ]

    const crearRelaciones = async (registros)=>{
        setLoadingCreacion(true)
        return fetch(`${urlapi}/${modulo}/masive`,{
            method:'POST',
            body: JSON.stringify(registros),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.createdCounts){
                messageApi.success('¡Realizado exitosamente!')
                obtenerRegistros(condicion_default)
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingCreacion(false)
        })
    }

    const obtenerRegistros = async (cond)=>{
        setLoading(true)
        const enviar_condicion = cond ? cond : condicion_busqueda
        return fetch(`${urlapi}/${modulo}/conditions`,{
            method:'POST',
            body: JSON.stringify({
                condicion: enviar_condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.conditions){
                if(Array.isArray(res.conditions) !== false){
                    const i_cond = res.conditions.findIndex(e => e.type_destination === type_destination)
                    if(i_cond > -1){
                        if(typeof res.conditions[i_cond].query === "object") setCondicionDestino(res.conditions[i_cond].query)
                    } else {
                        setCondicionDestino(null)
                    }
                }
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerRegistros(condicion_default)
    }, [ condicion_default ])

    const onSelectContactos = async (registros) => {
        const ids = registros.map(contacto => contacto._id)
        if(!payload_origin_default) return
        if(!type_destination) return
        const relaciones = ids.map(id => {
            return {
                type_destination,
                id_destination: id,
                ...payload_origin_default
            }
        })
        await crearRelaciones(relaciones)
    }

    const renderComponentByTypeDestination = () => {
        if(loading) return <Spin spinning={loading} />
        if(type_destination === 'contacto'){
            
            return <div>
                <ModalSeleccionContactos slug="asociar_contactos" typeView="modal" onSelect={(data) => onSelectContactos(data)} />
                <Spin spinning={loadingCreacion} />
                { condicionDestino ? <ListadoContactos 
                        hideExcelButton={true} 
                        hideColumns={true}
                        hideActions={true}
                        onSelect={(data,action) => onSelect(data,action)} 
                        loadingActions={loadingActions}
                        componentAction={componentes_accion}
                        hideSearch={true} 
                        showSelection={true} 
                        showFilters={false} condicion_default={condicionDestino} /> : <RegistrosVacios /> }
            </div>
        }
    }

    return <div>
        {contextHolder}
        {renderComponentByTypeDestination()}
        </div>
}

export default Relacionador