import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import reduxStore from '../src/redux/store';
import RoutesApp from './components/RoutesApp';
import 'mapbox-gl/dist/mapbox-gl.css'; 
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
const { store, persistor } = reduxStore();

function App() {
  const session = true
  return (
    <Provider store={store}>
    <PersistGate persistor={persistor}>
    <BrowserRouter>
      <RoutesApp />
    </BrowserRouter>
    </PersistGate>
    <ToastContainer
          position="bottom-right"
          theme="colored"
          />
    </Provider>
  );
}

export default App;
