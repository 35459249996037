import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { urlapi } from "../../lib/backend/data";
import { useDispatch, useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import { cerrarSesion } from "../../redux/actions/sesion";
import { Button } from "antd";
import Title from "antd/es/typography/Title";
const Barcode = require('react-barcode');

const MostrarEtiquetasPersonalizadas = React.forwardRef((props, ref) => {

  if(!props.etiquetas) return false
  if(props.etiquetas.length < 1) return false
  if(!props.personalizacionEtiqueta) return false
  return <div className='' ref={ref} >
    {
      props.etiquetas.map((etiqueta,i) => {
        const cantidad_total = props.etiquetas.filter(e => e.idrecurso === etiqueta.idrecurso).length
        return <>
          <div className="etiquetabodega" key={`etiqueta-${etiqueta._id}`} style={{ width: '100%'}}>
            <div style={{ textAlign: "center", padding: 5 }}>
              {props.personalizacionEtiqueta ? mostrarLogotipo(props.personalizacionEtiqueta) : false}            
              {props.personalizacionEtiqueta ? mostrarTextoLogo(props.personalizacionEtiqueta) : false}     
              {props.personalizacionEtiqueta ? mostrarId(etiqueta, props.personalizacionEtiqueta) : false}            
              {props.personalizacionEtiqueta ? mostrarQr(etiqueta, props.personalizacionEtiqueta) : false}            
             
            </div>
            <div style={{ textAlign: "center" }}>
            {props.personalizacionEtiqueta ? mostrarReferencia(etiqueta, props.personalizacionEtiqueta) : false}
            {props.personalizacionEtiqueta ? mostrarTelefono(etiqueta, props.personalizacionEtiqueta) : false}
            {props.personalizacionEtiqueta ? mostrarDireccion(etiqueta, props.personalizacionEtiqueta) : false}         
            {props.personalizacionEtiqueta ? mostrarProducto(etiqueta, props.personalizacionEtiqueta) : false}         
            {props.personalizacionEtiqueta ? mostrarBultos(etiqueta, props.personalizacionEtiqueta, cantidad_total) : false} 
            {props.personalizacionEtiqueta ? mostrarTextoResumen(props.personalizacionEtiqueta) : false}
            {props.personalizacionEtiqueta ? mostrarCodigoBarras(etiqueta, props.personalizacionEtiqueta) : false}     
             
            </div>
          { i < props.etiquetas.length -1 ? <div className="page-break"></div> : false }
        </div>
        </>
      })
    }
  </div>
})

const mostrarLogotipo = (personalizacionEtiqueta) => {
  return false
  if(!personalizacionEtiqueta?.detalles?.modulos) return false
  const obj = personalizacionEtiqueta.detalles.modulos.find(per => per.slug === "logotipo")
  if(!obj) return false
  if(!obj?.imagen) return <img src="/images/logo.png" style={{ width: '40%' }} />
  return <img src={obj.imagen} style={{ width: '40%' }} />
  } 


const mostrarExtra = (obj) => {
  if(!obj?.extra) return false 
  return <p className="mb-1" style={{ fontSize: 16, fontWeight: "bold" }}>{obj.extra}</p>
  }
  

const mostrarId = (etiqueta, personalizacionEtiqueta) => {
  if(!etiqueta?.id) return false
  if(!personalizacionEtiqueta?.detalles?.campos) return false
  const obj = personalizacionEtiqueta.detalles.campos.find(per => per.slug === "id")
  if(!obj) return false
  if(obj.activo) return <Title className="mb-3 mt-0" level={3}><b>ID</b>: {etiqueta.id}</Title>
}

const mostrarQr = (etiqueta, personalizacionEtiqueta) => {
  if(!etiqueta?._id) return false
  if(!personalizacionEtiqueta?.detalles?.modulos) return false
  const obj = personalizacionEtiqueta.detalles.modulos.find(per => per.slug === "qr")
  if(!obj) return false
  if(obj.activo) return <div className="qr" style={{  margin: '0 auto' }}><QRCode value={etiqueta._id} renderAs="svg" />
  {mostrarExtra(obj)}</div>
}

const mostrarTextoResumen = (personalizacionEtiqueta) => {
  if(!personalizacionEtiqueta?.detalles?.modulos) return false
  const obj = personalizacionEtiqueta.detalles.modulos.find(per => per.slug === "resumen")
  if(!obj) return false
  if(obj.activo) {
    if(obj.extra) return <p className="mb-1" style={{ fontSize: 16, fontWeight: "bold" }}>{obj.extra}</p>
  } 
}

const mostrarTextoLogo = (personalizacionEtiqueta) => {
  if(!personalizacionEtiqueta?.detalles?.modulos) return false
  const obj = personalizacionEtiqueta.detalles.modulos.find(per => per.slug === "logotipo")
  if(!obj) return false
  if(obj.activo) {
    if(obj.extra) return <p className="mb-1" style={{ fontSize: 16, fontWeight: "bold" }}>{obj.extra}</p>
  } 
}

const mostrarReferencia = (etiqueta, personalizacionEtiqueta) => {
  if(!etiqueta?.identificador) return false
  if(!personalizacionEtiqueta?.detalles?.campos) return false
  const obj = personalizacionEtiqueta.detalles.campos.find(per => per.slug === "referencia")
  if(!obj) return false
  if(obj.activo) return <Title className="mb-0 mt-0" level={3}>REF: {etiqueta.identificador}</Title>
}

const mostrarTelefono = (etiqueta, personalizacionEtiqueta) => {
  if(!etiqueta?.destinatario) return false
  if(!etiqueta?.telefono) return false
  if(!personalizacionEtiqueta?.detalles?.campos) return false
  const obj = personalizacionEtiqueta.detalles.campos.find(per => per.slug === "telefono")
  if(!obj) return false
  if(obj.activo) return <div> {etiqueta.destinatario ? <p className="mb-0" style={{ fontSize: 25, fontWeight: "bold" }} >{etiqueta.destinatario} { etiqueta.telefono ? <b>TELF. {etiqueta.telefono}</b> : false }</p> : false }</div>
}

const mostrarDireccion = (etiqueta, personalizacionEtiqueta) => {
  if(!etiqueta?.direccion) return false
  if(!personalizacionEtiqueta?.detalles?.campos) return false
  const obj = personalizacionEtiqueta.detalles.campos.find(per => per.slug === "direccion")
  if(!obj) return false
  if(obj.activo) return <div>{etiqueta.direccion ? <p className="mb-0" style={{ fontSize: 25, fontWeight: "bold" }} >{etiqueta.direccion}</p> : false }</div>
}

const mostrarProducto = (etiqueta, personalizacionEtiqueta) => {
  if(!etiqueta?.note) return false
  if(!personalizacionEtiqueta?.detalles?.campos) return false
  const obj = personalizacionEtiqueta.detalles.campos.find(per => per.slug === "producto")
  if(!obj) return false
  if(obj.activo) return <div>{ etiqueta.note ? <Title className="mb-0 mt-0" level={3}>{etiqueta.note}</Title> : false }</div>
}

const mostrarBultos = (etiqueta, personalizacionEtiqueta, cantidad_total) => {
  if(!etiqueta?.numero) return false
  if(cantidad_total) return false
  if(!personalizacionEtiqueta?.detalles?.campos) return false
  const obj = personalizacionEtiqueta.detalles.campos.find(per => per.slug === "bulto")
  if(!obj) return false
  if(obj.activo) return <Title className="mb-0 mt-0" level={3}>BULTO {etiqueta.numero} de {cantidad_total}</Title>
  }

const mostrarCodigoBarras = (etiqueta, personalizacionEtiqueta, cantidad_total) => {
  if(!etiqueta?._id) return false
  if(cantidad_total) return false
  if(!personalizacionEtiqueta?.detalles?.modulos) return false
  const obj = personalizacionEtiqueta.detalles.modulos.find(per => per.slug === "barcode")
  if(!obj) return false
  if(obj.activo) return <div><Title className="mb-0 mt-0" level={3}>Tracking ID</Title>
<div className="barcode-etiqueta"><Barcode height={80} value={etiqueta._id} /></div>
{mostrarExtra(obj)}</div>
}

const MostrarEtiquetas = React.forwardRef((props, ref) => {
  if(!props.etiquetas) return false
  if(props.etiquetas.length < 1) return false
  return <div className='' ref={ref} >
    {
      props.etiquetas.map((etiqueta,i) => {
        const cantidad_total = props.etiquetas.filter(e => e.idrecurso === etiqueta.idrecurso).length
        return <>
          <div className="etiquetabodega" key={`etiqueta-${etiqueta._id}`} style={{ width: '100%' }}>
            <div style={{ textAlign: "center", padding: 5 }}>
              <Title className="mb-0 mt-0">Moving <b>Truck</b></Title>
              {/* <img src="/images/logo.png" style={{ width: '40%' }} /> */}
              { etiqueta.id ? <Title className="mb-0 mt-0" level={4}><b>ID</b>: {etiqueta.id}</Title> : false }
              <div className="qr" style={{  margin: '0 auto' }}><QRCode value={etiqueta._id} renderAs="svg" /></div>
            </div>
            <div style={{ textAlign: "center" }}>
              <Title className="mb-0 mt-0" level={3}>REF: {etiqueta.identificador}</Title>
              {etiqueta.destinatario ? <p className="mb-0" style={{ fontSize: 25, fontWeight: "bold" }} >{etiqueta.destinatario} { etiqueta.telefono ? <b>TELF. {etiqueta.telefono}</b> : false }</p> : false }
              {etiqueta.direccion ? <p className="mb-0" style={{ fontSize: 25, fontWeight: "bold" }} >{etiqueta.direccion}</p> : false }
              { etiqueta.note ? <Title className="mb-0 mt-0" level={5}>{etiqueta.note}</Title> : false }
              <Title className="mb-0 mt-0" level={3}>BULTO {etiqueta.numero} de {cantidad_total}</Title>
              <Title className="mb-0 mt-0" level={4}>Tracking ID</Title>
              <div className="barcode-etiqueta"><Barcode height={80} value={etiqueta._id} /></div>
            </div>
          { i < props.etiquetas.length -1 ? <div className="page-break"></div> : false }
        </div>
        </>
      })
    }
  </div>
})



const Etiquetas = (props) => {
  const [personalizacionEtiqueta, setPersonalizacionEtiqueta] = useState({})
  const [ loadingEtiquetas, setLoadingEtiquetas ] = useState(false)
  const dispatch = useDispatch()
  const session = useSelector(state => state.miusuario)
  const token = session.tokenSession
  const etiquetas = props.etiquetas
  const componentRef = useRef()
  const pageStyle = `
  @media print {
    .page-break {
      page-break-before: always;
    }
    h3 {
      font-size: 40px !important;
    }
    .qr svg {
      width: 50%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    .etiquetabodega {
      width: 100%
    }
  }  
`;

useEffect(() => {
  obtenerPersonalizaciones()
}, []) // eslint-disable-line react-hooks/exhaustive-deps

const obtenerPersonalizaciones = async () => {
setLoadingEtiquetas(true)
return fetch(`${urlapi}/configuracion/personalizar`,{
  method:'POST',
  body: JSON.stringify({
    tipo: "personalizacion",
    sub_tipo: "etiqueta"
  }),
  headers: {
      'Content-Type':'application/json',
      'Authorization': `Bearer: ${token}`
  }
})
.then(res => {
  if(res.status === 401) return dispatch(cerrarSesion())
  return res.json()
})
.then(res => {
console.log(res)
if(!res){
  toast.error("Sin respuesta")
} else if(res.errorMessage){
  toast.error(res.errorMessage)
} else if(typeof res === "object"){
  console.log("personalizacion etiquetas,", res)
  setPersonalizacionEtiqueta(res)
}
return setLoadingEtiquetas(false)
})
.catch(error => {
toast.error(error.message)
return setLoadingEtiquetas(false)
})
}

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle
  })

  const mostrarSegunPersonalizacion = () => {
    if(!personalizacionEtiqueta.detalles ) return <MostrarEtiquetas  etiquetas={etiquetas} ref={componentRef} />
    return <MostrarEtiquetasPersonalizadas etiquetas={etiquetas} ref={componentRef} personalizacionEtiqueta={personalizacionEtiqueta} /> 
    }

  const render = () => {
    return  <div className="mx-2">
   <Button className='shadow w-100 mb-3' variant="success" onClick={() => handlePrint()}><i className="fas fa-barcode mb-0"></i><b> IMPRIMIR {etiquetas.length === 1 ? etiquetas.length + " ETIQUETA" : etiquetas.length + " ETIQUETAS"}
</b></Button>
        {mostrarSegunPersonalizacion()}        
    </div>
  }

  return (
    <div>
      {render()}
    </div>
  );
}

export default Etiquetas;