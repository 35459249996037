import { Button, Col, Form, Input, Modal, Row, Select, Spin, Switch, Tabs, Tag, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi, urlapi_clientes } from "../../lib/backend/data";
import { PlusCircleFilled, SaveOutlined } from "@ant-design/icons";
import CargandoTabla from "./cargando";
import { AiFillMinusCircle, AiOutlinePlus } from "react-icons/ai";
import { estilo_moving_truck } from "../../lib/estilo_sitio";
import { debounce } from 'lodash';
import { tipos_ingreso } from "./data";
import Paragraph from "antd/es/typography/Paragraph";
import JsonView from '@uiw/react-json-view';
import SelectorClientes from "../Clientes/selector";
import Title from "antd/es/typography/Title";

const EditarNuevoCampoPersonalizado = (props) => {
    const {
        id_registro
    } = props
    const [ campo, setCampo ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(true)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const esquema = {
        "_id": "6759ead6d319edc49f0241b6",
        "pedido": "112233",
        "propietario": "76851659",
        "orden": {
          "geo_datos_origen": {
            "level1": "Región Metropolitana",
            "level2": "Santiago",
            "pais": "Chile",
            "level3": "Quilicura",
            "address_1": "El Juncal 100, 8700000 Quilicura, Región Metropolitana, Chile",
            "formatted_address": "El Juncal 100, 8700000 Quilicura, Región Metropolitana, Chile",
            "lat": -33.333647,
            "lng": -70.723336,
            "_id": "671fd2277b92ec92db19ebf9",
            "tipo_lugar": "bodega",
            "first_name": "Principal"
          },
          "geo_datos": {
            "level1": "Región Metropolitana",
            "level2": "Santiago",
            "pais": "Chile",
            "level3": "Huechuraba",
            "address_1": "Av. Sta. Clara 301, 8581150 Huechuraba, Región Metropolitana, Chile",
            "formatted_address": "Av. Sta. Clara 301, 8581150 Huechuraba, Región Metropolitana, Chile",
            "lat": -33.3885604,
            "lng": -70.61903040000001
          },
          "billing": {
            "phone": "56912345678",
            "first_name": "patricio lopez",
            "address_1": "Av. Sta. Clara 301, 8581150 Huechuraba, Región Metropolitana, Chile",
            "address_2": "--",
            "email": "plopez@delpa.cl"
          }
        },
        "bultos": 0,
        "congelado": 0,
        "fecha_min_entrega": "2024-12-11T19:40:12.815Z",
        "fecha_max_entrega": "2024-12-12T23:59:59.900Z",
        "nota": "mi primera integración",
        "peso": 0,
        "volumen": 0,
        "refrigerado": 0,
        "location": {
          "type": "Point",
          "coordinates": [
            -70.61903040000001,
            -33.3885604
          ]
        },
        "show_on_app": false,
        "gestionada": false,
        "posicion": 0,
        "finalizado": false,
        "anulado": false,
        "entrega_bloqueada": false,
        "recoleccion": false,
        "courrier": false,
        "label_courrier_generated": false,
        "label_courrier_printed": false,
        "manifest": false,
        "intentos": 0,
        "canal": "web",
        "status_picking": "pendiente",
        "createdAt": "2024-12-11T19:41:10.210Z",
        "updatedAt": "2024-12-11T19:41:10.210Z",
        "__v": 0,
        "conductor": false,
        "meta_data": [],
        "productos": [
          {
            "_id": "6759ead6d319edc49f0241b8",
            "id_orden": "6759ead6d319edc49f0241b6",
            "description": "Refrigerador",
            "codigo_item": "3434343",
            "cantidad_pickeada": 0,
            "peso": 20,
            "alto": 30,
            "ancho": 20,
            "largo": 40,
            "price": 0,
            "quantity": 2,
            "picked": false,
            "__v": 0
          }
        ],
        "estados": [],
        "manifiestos_hijo": [],
        "manifiestos_padre": [],
        "etiquetas": []
      }

    const obtenerDatos = async () => {
        if(!id_registro) return messageApi.error("ID no válido")
        setLoading(true)
        return fetch(`${urlapi}/endpoints?id=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setCampo(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const opcionesInvalids = () => {
        return messageApi.error("Opciones inválidas para tipo de selección")
    }

    const guardarCambios = async (datos) => {
        const actualizar_ = datos ? datos : campo
        if(!actualizar_.title) return messageApi.error("Escribe un título")
        if(actualizar_.title.length < 3) return messageApi.error("Escribe un título válido")

        setLoadingSave(true)
        return fetch(`${urlapi}/endpoints`,{
            method:'PUT',
            body: JSON.stringify(actualizar_),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                // setIsModalOpen(false)
                // if(props.onCreate) props.onCreate()
            }
            return setLoadingSave(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
      obtenerDatos()
    };

    const handleOk = () => {
        return guardarCambios()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            guardarAutomaticamente(actual)
            return {...{}, ...actual}
        })
    }

    const handleChangeSelect = (name, value) => {
        campo[name] = value
        return setCampo({...{}, ...campo})
    }

    const handleChangeOpcion = (e,pos) => {
        return setCampo(prev => {
            let actual = {...prev}
            actual.opciones[pos].value = e.target.value
            return {...{}, ...actual}
        })
    }

    const eliminarOpcion = (i) => {
        return setCampo(prev => {
            const actual = {...prev}
            actual.opciones.splice(i,1)
            return {...{}, ...actual}
        })
    }

    const mostrarOpciones = () => {
        if(!campo.opciones) return false
        return <div style={{ marginTop: 15 }}>
            {
                campo.opciones.map((opcio,ip) => {
                    return <div key={`op-${ip}`}>
                        <Row>
                            <Col xs={2}><AiFillMinusCircle className="hover"  onClick={() => eliminarOpcion(ip)} size={30} color={estilo_moving_truck.colors.danger} /></Col>
                            <Col xs={22}>
                                <Form.Item required>
                                    <Input style={{ width: "100%" }} value={opcio.value} onChange={(e) => handleChangeOpcion(e,ip)} />
                                </Form.Item>
                            </Col>
                        </Row>
                        
                    </div>
                })
            }
        </div>
    }

    const agregarValor = () => {
        return setCampo(prev => {
            let actual = {...prev}
            if(!actual.opciones) actual.opciones = []
            actual.opciones.unshift({
                value: ""
            })
            return {...{}, ...actual}
        })
    }

    const opcionesBox = () => {
        if(!campo) return false
        if(campo.tipo_ingreso !== "seleccion") return false
        return <div style={{ marginTop: 15 }}>
            <Button size="small" onClick={() => agregarValor()}><AiOutlinePlus style={{ verticalAlign: "middle" }} /> AGREGAR NUEVO VALOR</Button>
            {mostrarOpciones()}
        </div>
    }
    
    const handleChangeCheck = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = { ...prev }
            if(!actual.detalles) actual.detalles = {}
            actual.detalles[name] = value
            guardarAutomaticamente(actual)
            return { ...actual, ...{} }
        })
    }

    const guardarAutomaticamente = useCallback(debounce((data) => guardarCambios(data), 1000), []);

    const formulario = () => {
        if(loading) return <CargandoTabla />

            let detalles = campo.detalles || {}
        const formulario = () => <div>
        <Tag color="blue" style={{ fontSize: 11 }}>{urlapi_clientes}/hooks-integration/{id_registro}</Tag>
            <Paragraph className="mt-3">Usa esta URL para integrar con tu sistema, el método de seguridad es el mismo que el de nuetra API oficial</Paragraph>
            <Form layout="vertical">
                <Form.Item label="Nombre">
                    <Input name="title" defaultValue={campo.title} onChange={handleChange} />
                </Form.Item>
                <Row gutter={15}>
                    <Col md={12}>
                        <Form.Item className="mb-3" label="Generar maestro de productos" >
                        <Switch className="mt-0 mb-0" checkedChildren="SI" unCheckedChildren="NO" checked={detalles["generar-maestro"] === true} onChange={(e) => handleChangeCheck({ target: { name:"generar-maestro", value: e } })} />
                    </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item className="mb-3" label="Generar base de datos de clientes" >
                            <Switch className="mt-0 mb-0" checkedChildren="SI" unCheckedChildren="NO" checked={detalles["generar-cliente"] === true} onChange={(e) => handleChangeCheck({ target: { name:"generar-cliente", value: e } })} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item className="mb-3" label="Almacenar contactos de clientes" >
                            <Switch className="mt-0 mb-0" checkedChildren="SI" unCheckedChildren="NO" checked={detalles["generar-contacto"] === true} onChange={(e) => handleChangeCheck({ target: { name:"generar-contacto", value: e } })} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <SelectorClientes titulo="Asignar cliente específico" condicion={{}} />
                    </Col>
                </Row>
                </Form>
        </div>

        return <div>
            
                <Tabs items={[
                    {
                        label: <Title level={4} className="mb-0">General</Title>,
                        children: formulario()
                    },
                    {
                        label: <Title level={4} className="mb-0">Ejemplo de datos</Title>,
                        children: <JsonView value={esquema} />
                    }
                ].map((e,i) => ({ ...e, key: i+1 }))} />
                { loadingSave ? <Spin /> : false }
                {/* <Button type="primary" loading={loadingSave} onClick={() => guardarCambios()} ><SaveOutlined />GUARDAR CAMBIOS</Button> */}
        </div>
    }
    
    return <div>
        <Button  className="mb-3" type="link" onClick={showModal}>EDITAR</Button>
        <Modal 
            title="Editar" 
            width={800}
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
            footer={[]}
        >
        {formulario()}
      </Modal>
      {contextHolder}
    </div>
}

export default EditarNuevoCampoPersonalizado