import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Card, Col, Form, Input, Pagination, Popconfirm, Row, Table, Tag, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import { fechaATextoSimple } from "../../lib/helpers/helpers"
import EditarNuevoCampoPersonalizado from "./editar"
import { FileExcelFilled, SearchOutlined } from "@ant-design/icons"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado"
import { modulo } from "./data"
import ButtonGroup from "antd/es/button/button-group"
import Paragraph from "antd/es/typography/Paragraph"

const ListadoBodegas = (props)=> {
    const {
        condicion_default,
        hideSearch
    } = props
    const dispatch = useDispatch()
    const sesion                    = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [deleting, setDeleting]   = useState(false)
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ]     = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ]       = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const pais                      = useSelector(state => state.pais)
    const session                   = useSelector(state => state.miusuario)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const idioma                    = useSelector(state => state.idioma)
    const trash                     = props.trash ? props.trash : false
    const tipo                      = props.tipo ? props.tipo : "link"
    const titulo                    = props.title ? props.title : 'registros'
    const key_interfaz              = 'tabla_bodegas'
    const key_esquema               = 'BODEGAS'

    const obtenerRegistros = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${modulo}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            console.log(error.message)
            return setLoadingConductores(false)
        })
    }

    const eliminarRecurso = async (id) => {
        setDeleting(true)
        return fetch(`${urlapi}/${modulo}?id=${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(res._id){
              messageApi.success("Eliminado exitosamente")
              obtenerRegistros(1, false)
            }
            return setDeleting(false)
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setDeleting(false)
          })
      }
  

    useEffect(() => {
        obtenerRegistros(1, false)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerRegistros(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination showSizeChanger={false} defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />

        const component_title = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="titulo" />} render={(data) => {
            return <div>{data.titulo}</div>
        }} />
        const component_ciudad = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="ciudad" />} render={(data) => {
            return <div>{data.geodata?.level3}</div>
        }} />
        const component_pais = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="pais" />} render={(data) => {
            return <div>{data.geodata?.pais}</div>
        }} />
        const component_cliente = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="cliente" />} render={(data) => {
            return <div>{data.cliente?.nombre_comercial}</div>
        }} />

        const component_direccion = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="direccion" />} render={(data) => {
            return <div>{data.direccion}</div>
        }} />

        return <div>
            {paginacion(data.pagina, total)}
            
            {
                conductores.map((conductor, index) => {
                    return <Card size="small" key={`-${index}`}>
                        <Paragraph className="mb-0 mt-0">Título del enlace</Paragraph>
                        <Title level={4} className="mt-0 mb-0">{conductor.titulo}</Title>
                        <Row>
                            <Col md={12}>
                                <Title level={5} className="mt-0 mb-0">Emails asociados</Title>
                                { conductor.emails_adicionales.length > 0 ? conductor.emails_adicionales.map(email => email.email).join(', ') : 'Sin emails' }
                            </Col>
                            <Col md={12}>
                            <Title level={5} className="mt-0 mb-0">Móviles asociados</Title>
                                { conductor.moviles_adicionales.length > 0 ? conductor.moviles_adicionales.map(movil => `${movil.pais_codigo}${movil.movil}`).join(', ') : 'Sin móviles' }
                            </Col>
                        </Row>
                        <ButtonGroup>
                        <EditarNuevoCampoPersonalizado id_registro={conductor._id} onCreate={() => obtenerRegistros(1, false)} />
                        <Popconfirm
                            title="Eliminar"
                            description="¿Estás seguro que deseas eliminar recurso? Esta acción no se puede deshacer"
                            onConfirm={() => eliminarRecurso(conductor._id)}
                            onCancel={() => false}
                            okText="SI"
                            style={{ display: "inline-block"}}
                            cancelText="NO"
                        >
                        <Button loading={deleting} type="dashed" danger>ELIMINAR</Button>
                        </Popconfirm>
                        </ButtonGroup>
                    </Card>
                })
            }
        </div>
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setCondicionBusqueda(condicion_default);
            setPagina(1);
            return obtenerRegistros(1, condicion_default);
        }
        const condicion = {
            ...condicion_default,
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerRegistros(1, condicion);
      };

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

    const buscador = () => {
        if(hideSearch === true) return false
        return <Col span="auto"><Form onSubmitCapture={handleSubmit}>
                    <Row gutter={15}>
                        <Col md={14}>
                            <Form.Item label="Buscar">
                                <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Buscar por palabras clave`} />
                            </Form.Item>
                        </Col>
                        <Col md={10}>
                            <Form.Item>
                                <Button onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
        </Col>
      }

    return <div>
        {contextHolder}
        <Row gutter={15}>
            {buscador()}
            {/* <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
            <Col span="auto"><Button className="mb-3" ><FileExcelFilled /> DESCARGAR PDF INFORMATIVO</Button></Col>
            <Col span="auto"><Button className="mb-3" ><FileExcelFilled /> DESCARGAR EXCEL</Button></Col> */}
        </Row>
        
        {mostrarRegistros()}
    </div>
}
export default ListadoBodegas