import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { main_data } from "./data"
import { cerrarSesion } from "../../redux/actions/sesion"

const { Button, message } = require("antd")
const { default: ButtonGroup } = require("antd/es/button/button-group")
const { useState } = require("react")

const AccionesTarea = (props) => {
    const {
        id,
        email
    } = props
    const [ loading, setLoading ] = useState(false)
    const dispatch = useDispatch()
    const sesion                    = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();

    const notificar = async ()=>{
        setLoading(true)
        return fetch(`${urlapi}/${main_data.slug_module}/notify`,{
            method:'POST',
            body: JSON.stringify({
                id,
                email
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.enviados){
                messageApi.success(`${res.enviados.length} notificaciones enviadas`)
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    if(email) return <div>
        <Button onClick={() => notificar()}>ENVIAR NOTIFICACIÓN</Button>
    </div>

    return <Button loading={loading} onClick={() => notificar()}>REENVIAR NOTIFICACIÓN A PENDIENTES</Button>
}

export default AccionesTarea