import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Breadcrumb, Button, Card, Col, Collapse, DatePicker, Divider, Form, Image, Input, Layout, List, Modal, Popconfirm, Row, Select, Spin, Steps, Switch, Tabs, Typography, Upload, message, theme } from "antd"
import { HomeOutlined, LoadingOutlined, PlusOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fechaATexto, fechaUTCATextoSimple, filterOption, formatYMD, limpiarStringLetrasNumeros, limpiarStringNumeros, siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import { config_s3, maps_key, timezone, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import AutoComplete from "react-google-autocomplete";
import S3FileUpload from 'react-s3';
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios"
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps"
import { AiFillCar, AiFillMinusCircle, AiOutlineMail, AiOutlinePlus } from "react-icons/ai"
import { codigos_area } from "../../lib/internacional"
import { GrAdd } from "react-icons/gr"
import { GoDeviceMobile } from "react-icons/go"
import SelectorClientes from "../Clientes/selector"
import { BsFillBoxSeamFill, BsFillFileEarmarkPersonFill, BsSpeedometer, BsTruckFlatbed } from "react-icons/bs"
import ModalSeleccionContactos from "../Contactos/modal_seleccion"
import { MdError, MdModeEdit, MdOutlinePlace } from "react-icons/md"
import { FiUser } from "react-icons/fi"
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main"
import ModalSeleccionRequisitos from "../Requisitos/modal_seleccion"
import Paragraph from "antd/es/typography/Paragraph"
import CaracteristicasVehiculo from "./requisitos/caracteristicas_vehiculo"
import CamposPersonalizadosRecurso from "../../subComponents/campos_personalizados/listado_recurso"
import MediosBox from "../../subComponents/medios/medios"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"
import { DateTime } from "luxon"
import locale from 'antd/es/date-picker/locale/es_ES';
import ButtonGroup from "antd/es/button/button-group"
import { BiLeftArrowAlt, BiRightArrowAlt, BiTime } from "react-icons/bi"
import Costos from "../Costos"
import SelectorGlobal from "../../subComponents/general/selector"
import ModalSeleccionVehiculos from "../Conductores/modal_seleccion"
import ModalSeleccionVehiculo from "../Vehiculos/modal_seleccion"
import ModalSeleccionProductos from "../Productos/modal_seleccion"
import { FaCheckCircle, FaCircle, FaPlus, FaRegCalendarAlt, FaRegClock, FaRegEnvelope, FaRegUser, FaRegUserCircle, FaUserAlt } from "react-icons/fa";
import { CgTrack } from "react-icons/cg";
import { canalEstadosString } from "../../lib/helpers/viajes"
import { debounce } from 'lodash';
import ModalSeleccionRuta from "../Rutas/modal_seleccion"
import DetalleViajeCliente from "./publico/detalle_publico"
import ModalRelacionadorMiddleware from "../../subComponents/general/relacionador_middleware"
import AsignacionOrden from "../BusquedaRecursos/asignacion_orden"
import GeneradorEtiquetas from "./generador_etiquetas"
import { formatTime } from "../../lib/helpers/dates"
import { formatoMoneda } from "../../lib/helpers/main"

const CrearViaje = (props) => {
    const {
        id
    } = useParams()
    const [ loading, setLoading ] = useState(false)
    const [ showPassword, setShowPassword ] = useState(false)
    const [ loadingImagen, setLoadingImagen ] = useState(false)
    const [ loadingAutoSave, setLoadingAutoSave ] = useState(false)
    const [ deleting, setDeleting ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [ etiquetas, setEtiquetas ] = useState([])
    const [ uMDefault, setUMDefault ] = useState('')
    const [ seleccionaConductor, seleccionarConductor ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const [ estados, setEstados ] = useState([])
    const [ vehiculo, setVehiculo ] = useState(false)
    const [ routes, setRoutes ] = useState([])
    const [ uMPesoDefault, setUMPesoDefault ] = useState('')
    const default_parada = { description: "", tipo_entrega: "entrega"}
    const default_producto = { descripcion: "", tipo_entrega: "entrega"}
    const [ paradas, setParadas ] = useState([default_parada])
    const [ requisitos, setRequisitos ] = useState([])
    const [ productos, setProductos ] = useState([default_producto])
    const [messageApi, contextHolder] = message.useMessage();
    const [ usuario, setUsuario ] = useState({})
    const dispatch = useDispatch()
    const [ condicion_sub_categoria, setCondicionSubCategoria ] = useState({ tipo: "cliente" })
    const module = 'viajes/new'
    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
    const [ caracteristicas, setCaracteristicas ] = useState({})
    const [current, setCurrent] = useState(0);
    const { token } = theme.useToken();
    const [ fechaConsulta, setFechaConsulta ] = useState(formatYMD())
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ loadingRef, setLoadingRef ] = useState(false)
    const [ validRef, setValidRef ] = useState(true)

    const validarReferencia = async (id) => {
        setLoadingRef(true)
        const url = `${urlapi}/viajes/referencia?id=${id}`
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(typeof res.valid !== "undefined"){
                setValidRef(res.valid)
                if(res?.valid === true) setUsuario(prev => {
                    let actual = {...prev}
                    actual.identificador = id
                    return actual
                })
            }
            return setLoadingRef(false)
        })
        .catch(error => {
            return setLoadingRef(false)
        })
    }

    const contentStyle = {
        padding: 20,
        // lineHeight: '260px',
        // textAlign: 'center',
        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
      };
    
      const guardarAutomatico = async (trip, detenciones, ruts_) => {

        const registro = { ...trip }
        if(registro.emails_adicionales) if(registro.emails_adicionales.length > 0){
            registro.emails_adicionales = registro.emails_adicionales.filter(e => e.email)
        }
        
        if(registro.moviles_adicionales) if(registro.moviles_adicionales.length > 0){
            registro.moviles_adicionales = registro.moviles_adicionales.filter(e => e.movil && e.pais_codigo)
        }

        const steps = detenciones ? detenciones.filter(e => e.start_point?.type) : paradas.filter(e => e.start_point?.type)
        const routes_ = ruts_ ? ruts_ : routes
        if(validRef === false) return false
        setLoadingAutoSave(true)
        const url = `${urlapi}/${module}/autosave`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify({
                viaje: {
                    _id: id,
                    ...registro,
                    caracteristicas,
                    requisitos,
                },
                productos,
                paradas: steps,
                ruteo: routes_.length > 0 ? { routes: routes_, tipo: "planificacion" } : null
            }),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {

            if(res.origen){
                if(res.origen?._id){
                    setUsuario(prev => {
                        let actual = { ...prev }
                        actual.origen._id = res.origen?._id
                        return actual
                    })
                }
            }

            if(res?.paradas){

                setParadas(prev => {
                    let actual = [...prev]
                    actual = actual.map((stop,i) => {
                        if(!stop._id){
                            const i = res.paradas.findIndex(e => e.description === stop.description)
                            if(i > -1) stop._id = res.paradas[i]._id
                        }
                        return stop
                    })
                    return [ ...actual ]                    
                })
            }

            setLoadingAutoSave(false)
        })
        .catch(error => setLoadingAutoSave(false))
    }

    const guardarAutomaticamente = useCallback(debounce((data, detenciones, ruts_) => guardarAutomatico(data, detenciones, ruts_), 1000), [])
    const consultarReferencia = useCallback(debounce((id) => validarReferencia(id), 500), [])
    
    const eliminarRecurso = async ( ) => {
        setDeleting(true)
        const url = `${urlapi}/viajes?id=${id}`
        return fetch(url, {
            method: "DELETE",
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Realizado exitosamente")
                return setTimeout(() => {
                    window.location = `${rutas.trips.slug}`
                }, 1500);
            }
            return setDeleting(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setDeleting(false)
        })
    }


    const obtenerDetalles = async ( ) => {
        const url = `${urlapi}/viajes?id=${id}`
        return fetch(url, {
            method: "GET",
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.viaje){
                if(res?.ruteo?.routes) if(Array.isArray(res?.ruteo?.routes)){
                    setRoutes(res?.ruteo?.routes)
                }
                let trip = res.viaje
                let steps = []
                let origin = null
                if(res.viaje.conductor) setConductor(res.viaje.conductor)
                if(res.viaje.vehiculo) setVehiculo(res.viaje.vehiculo)
                if(res.estados) setEstados(res.estados)
                if(res.paradas) if(Array.isArray(res.paradas) !== false) if(res.paradas.length > 0){
                    const stops = res.paradas.filter(e => e.tipo !== "origen")
                    steps = stops
                    setParadas(stops)
                    const origen = res.paradas.filter(e => e.tipo === "origen")
                    if(origen.length > 0){
                        trip.origen = origen[0]
                        origin = origen[0]
                        trip.direccion = origen[0].description
                    }
                }
                if(res.etiquetas) setEtiquetas(res.etiquetas)
                if(res.productos) if(Array.isArray(res.productos) !== false) if(res.productos.length > 0){
                    setProductos(res.productos)
                }
                if(res.viaje.requisitos) if(Array.isArray(res.viaje.requisitos) !== false) if(res.viaje.requisitos.length > 0){
                    setRequisitos(res.viaje.requisitos)
                }
                if(res.viaje.caracteristicas) if(typeof res.viaje.caracteristicas === "object"){
                    setCaracteristicas(res.viaje.caracteristicas)
                }
                setUsuario(trip)
                calcularCaminos(origin, steps)
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoadingMaster(false)
        })
    }
    useEffect(() => {
        obtenerDetalles()
    }, [])
    const requeridos = [
        { value: 'origen', label: 'Origen' },
      ];

    const handleChangeSelect = (e,name) => {
        usuario[name] = e
        guardarAutomaticamente(usuario)
        return setUsuario({...{}, ...usuario})
    }
    
    const handleChangeSelectParada = (e,name,i) => {
        paradas[i][name] = e
        return setParadas([...[], ...paradas])
    }
    
    const handleChangeSelectProducto = (e,name,i) => {

        if(name === "unidad_medida"){
            setUMDefault(e)
        } else if(name === "unidad_medida_peso"){
            setUMPesoDefault(e)
        }
        productos[i][name] = e
        return setProductos([...[], ...productos])
    }
    
    const handleChangeParada = (e,i) => {
        const { name, value } = e.target
        paradas[i][name] = value
        return setParadas([...[], ...paradas])
    }
    
    const handleChangeProducto = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        productos[pos][name] = value
        return setProductos([...[], ...productos])
    }

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        
        return setUsuario(prev => {
            let actual = { ...prev }
            if(name === "rut"){
                actual[name] = limpiarStringLetrasNumeros(value)
            } else if(name === "phone"){
                actual[name] = limpiarStringNumeros(value)
            } else {
                actual[name] = value
            }
            guardarAutomaticamente(actual)
            return actual
        })
    }

    const uploadButton = (
        <div>
          {loadingImagen ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Subir foto
          </div>
        </div>
      );

    const crearConductorNuevo = async () => {
        
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  

        if(usuario.emails_adicionales) if(usuario.emails_adicionales.length > 0){
            const invalidos = usuario.emails_adicionales.filter(e => !validateEmail(e.email) || !e.email)
            if(invalidos.length > 0) return messageApi.error(`Emails inválidos: ${invalidos.map(ma => ma.email ? ma.email : "Email vacío").join(', ')}`)  
        }
        
        if(usuario.moviles_adicionales) if(usuario.moviles_adicionales.length > 0){
            const invalidos = usuario.moviles_adicionales.filter(e => !e.pais_codigo ||!e.movil)
            if(invalidos.length > 0) return messageApi.error(`Números de teléfono inválidos: ${invalidos.map(ma => {
                const codigo = ma.pais_codigo ? ma.pais_codigo : "Código area vacío"
                const numero = ma.movil ? ma.movil : "Número vacío"
                return `${codigo} ${numero}`
            }).join(', ')}`)  
        }



        setLoading(true)
        const url = `${urlapi}/${module}`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify({
                viaje: {
                    _id: id,
                    ...usuario,
                    caracteristicas,
                    requisitos,
                },
                productos,
                paradas,
                ruteo: routes.length > 0 ? { routes, tipo: "planificacion" } : null
            }),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                return setTimeout(() => {
                    return window.location = `${rutas.trips.slug}/new/${res._id}`
                }, 1000);
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    const handleChangeMovil = (e, pos, name) => {
        let value = e
        if(typeof e === "object") if(e.target) value = e.target.value
        usuario.moviles_adicionales[pos][name] = value
        guardarAutomaticamente(usuario)
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const removerMovil = (i) => {
        usuario.moviles_adicionales.splice(i,1)
        guardarAutomaticamente(usuario)
        return setUsuario(prev => ({...{}, ...usuario})) 
    }
    
    const anadirMovilAdicional = () => {
        if(!usuario.moviles_adicionales) usuario.moviles_adicionales = []
        usuario.moviles_adicionales.unshift({
            pais_codigo: '',
            movil: ''
        })
        guardarAutomaticamente(usuario)
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const anadirEmailAdicional = () => {
        if(!usuario.emails_adicionales) usuario.emails_adicionales = []
        usuario.emails_adicionales.unshift({
            email: ''
        })
        guardarAutomaticamente(usuario)
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const removerEmail = (i) => {
        usuario.emails_adicionales.splice(i,1)
        guardarAutomaticamente(usuario)
        return setUsuario(prev => ({...{}, ...usuario})) 
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        usuario.emails_adicionales[pos][name] = value
        if(name === "email") usuario.emails_adicionales[pos].valid = validateEmail(value)
        guardarAutomaticamente(usuario)
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const mostrarEmailsAdicionales = () => {
        if(!usuario.emails_adicionales) return false
        return <div>
            {
                usuario.emails_adicionales.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-3">
                        <Card size="small" title="Email" className="mb-3" extra={<Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>} >
                                <Form.Item label=""  required={true} className="mb-3">
                                <Input placeholder="Escribe aquí el nombre" prefix={<FaRegUserCircle />} className='mb-0'  pos={i}  name="name" value={field.name} onChange={handleChangeEmail} />
                                </Form.Item>
                                <Form.Item label="" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el email" prefix={<AiOutlineMail />} className='mb-0' status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} />
                                </Form.Item>
                        </Card>
                    </div>
                })
            }
        </div>
    }

    const mostrarMovilesAdicionales = () => {

        

        if(!usuario.moviles_adicionales) return false
        return <div>
            {
                usuario.moviles_adicionales.map((field,i) => {
                    return <div key={`movil-${i}`} className="mt-3">
                    <Card size="small" title="Móvil" className="mb-3" extra={<Button type="ghost" onClick={() => removerMovil(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />}>REMOVER</Button>} >
                        <Form layout="vertical">
                        <Row gutter={15}>
                            <Col xs={24}>
                                <Form.Item label=""  required={true} className="mb-3">
                                <Input placeholder="Escribe aquí el nombre" prefix={<FaRegUserCircle />} className='mb-0'  pos={i}  name="name" value={field.name} onChange={(e) => handleChangeMovil(e,i,'name')} />
                                </Form.Item>
                            </Col>
                            <Col md={8}>
                                <Form.Item label="COD. Área" required={true} className="mb-0">
                                <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={field.pais_codigo} onChange={(e) => handleChangeMovil(e,i,"pais_codigo")} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                                </Form.Item>
                            </Col>
                            <Col md={16}>
                                <Form.Item label="Número" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el número telefónico" className='form-control mb-3' name="movil" value={field.movil} onChange={(e) => handleChangeMovil(e,i,"movil")} />
                                </Form.Item>
                            </Col>
                        </Row>
                        </Form>
                    </Card>
                    </div>
                })
            }
        </div>
    }

    const addStop = () => {
        return setParadas(prev => [...prev, ...[default_parada]])
    } 
    const addRequeriment = (nuevo) => {
        const i = requisitos.findIndex(re => re._id === nuevo._id)
        if(i > -1) return 
        return setRequisitos(prev => [...prev, ...[nuevo]])
    } 
    
    const addProduct = () => {
        const producto = {...default_producto, unidad_medida: uMDefault, unidad_medida_peso: uMPesoDefault }
        return setProductos(prev => [...prev, ...[producto]])
    } 

    const removerParada = async (i) => {
    const registros = paradas
    const idParada = registros[i]._id;

    if (idParada) {
        setDeleting(true)
        await fetch(`${urlapi}/viajes/punto/${idParada}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            },
        })
        .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
        })
        .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(res.message){
                messageApi.success(res.message)
            }
            registros.splice(i,1)
            setParadas(prev => [...registros, ...[]])

            return setDeleting(false)
        })
        .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setDeleting(false)
        })
    } else {
        registros.splice(i,1)
        setParadas(prev => [...registros, ...[]])
    }
}
    const removerReq = (id) => {
        const registros = requisitos
        const i = registros.findIndex(re => re._id === id)
        if(i < 0) return 
        registros.splice(i,1)
        return setRequisitos(prev => [...registros, ...[]])
    }
    
    const eliminarProducto = async (id) => {

        const url = `${urlapi}/viajes/producto?id=${id}`
        return fetch(url, {
            method: "DELETE",
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            }
            return false
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return false
        })
    }


    const removerProducto = (i, pro) => {
        if(pro._id){
            eliminarProducto(pro._id)
        }
        const stops = productos
        stops.splice(i,1)
        return setProductos(prev => [...stops, ...[]])
    }

    const procesarGeoParada = (place,i) => {
        if(!place) return false
                                const geo_data = procesarGeoDatosGeocode(place)

                                const instancia = JSON.parse( JSON.stringify(paradas))
                                const instancia_parada = JSON.parse( JSON.stringify( paradas[i] ))
                                // return console.log({instancia})
                                const payload = {
                                    ...instancia_parada,
                                    tipo: "entrega",
                                    description: place.formatted_address,
                                    location: {
                                        latitude:geo_data.lat,
                                        longitude:geo_data.lng
                                    },
                                    geo_data,
                                    start_point: {
                                        type: "Point",
                                        coordinates: [
                                            geo_data.lng, geo_data.lat
                                        ]
                                    },
                                    details: place
                                }
                                
                                setParadas(prevParadas => {
                                    // Crea una copia del arreglo de paradas
                                    const updatedParadas = [...prevParadas];
                                    // Actualiza el ítem en el índice especificado
                                    updatedParadas[i] = payload;
                                    calcularCaminos(usuario.origen, updatedParadas)
                                    guardarAutomaticamente(usuario, updatedParadas, routes)
                                    return updatedParadas;
                                });
                            
    }

    const onSelectContactos = (data,i) => {
        return setParadas(prevParadas => {
            // Crea una copia del arreglo de paradas
            const updatedParadas = [...prevParadas];
            
            if(!updatedParadas[i].contactos) updatedParadas[i].contactos = []
            const final = updatedParadas[i].contactos

            for ( const co of data ){
                const i = final.findIndex(el => el._id === co._id)
                if(i > -1) continue
                final.push(co)
            }
            updatedParadas[i].contactos = [...[], ...final]
            return updatedParadas;
        });
    }

    const handleChangeRemitenteSelect = (e, key) => {
        return setUsuario(prev => {
            let actual = {...prev}
            if(!actual.remitente) actual.remitente = {}
            actual.remitente[key] = e
            guardarAutomaticamente(actual)
            return actual
        })
    }
    const handleChangeRemitente = (e) => {
        return setUsuario(prev => {
            let actual = {...prev}
            if(!actual.remitente) actual.remitente = {}
            actual.remitente[e.target.name] = e.target.value
            guardarAutomaticamente(actual)
            return actual
        })
    }
    const handleChangeContactoSelect = (e, key, i_parada, i_contacto) => {
        return setParadas(prev => {
            let actual = [...prev]
            actual[i_parada].contactos[i_contacto][key] = e
            guardarAutomaticamente(actual)
            return actual
        })
    }
    const handleChangeContacto = (e, i_parada, i_contacto) => {
        return setParadas(prev => {
            let actual = [...prev]
            actual[i_parada].contactos[i_contacto][e.target.name] = e.target.value
            guardarAutomaticamente(actual)
            return actual
        })
    }
    const eliminarContacto = (pos_contact, pos_stop) => {
        setParadas(prevParadas => {
                                    // Crea una copia del arreglo de paradas
                                    const updatedParadas = [...prevParadas];
                                    // Actualiza el ítem en el índice especificado
                                    updatedParadas[pos_stop].contactos.splice(pos_contact,1)
                                    return updatedParadas;
                                });
    }
    const mostrarContactos = (contactos,i) => {
        if(!contactos) return false
        if(Array.isArray(contactos) !== true) return false
        if(contactos.length < 1) return false

        return <div style={{ marginTop:20  }}>
            <Title level={5} className="mt-0 mb-2"><FaUserAlt /> {contactos.length} Contactos asociados a esta parada</Title>
            {
                contactos.map((contact,pos) => {
                    return <Card size="small" key={`contacto_${pos}`} className="mb-3" title={`${contact.nombres ? contact.nombres : ""} ${contact.apellidos ? contact.apellidos : ''}`} extra={<Button type="ghost" onClick={() => eliminarContacto(pos,i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>} >
                        <Form layout="vertical">
                            <Row gutter={15}>
                                <Col md={4}>
                                <Form.Item label="Nombres" required={true} className="mb-0">
                                    <Input placeholder="Nombres del contacto" className='mb-3' name="nombres" value={contact.nombres} onChange={(e) => handleChangeContacto(e, i, pos)} />
                                </Form.Item>
                                </Col>
                                <Col md={4}>
                                <Form.Item label="Apellidos"  className="mb-0">
                                    <Input placeholder="Apellidos" className='mb-3' name="apellidos" value={contact.apellidos} onChange={(e) => handleChangeContacto(e, i, pos)} />
                                </Form.Item>
                                </Col>
                                <Col md={6}>
                                <Form.Item label="Email" className="mb-0">
                                    <Input addonBefore={<FaRegEnvelope />} placeholder="Email" className='mb-3' name="email" value={contact.email} onChange={(e) => handleChangeContacto(e, i, pos)} />
                                </Form.Item>
                                </Col>
                                <Col md={10}>
                                <Form.Item label="Móvil" className="mb-0">
                                <Row gutter={15}>
                                    <Col md={12}>
                                        <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={contact.pais_codigo} onChange={(e) => handleChangeContactoSelect(e,"pais_codigo",i, pos)} 
                                                    filterOption={filterOption}
                                                    options={codigos_area}
                                                />
                                    </Col>
                                    <Col md={12}>
                                        <Input addonBefore={<GoDeviceMobile />} name="movil" value={contact.movil} onChange={(e) => handleChangeContacto(e, i, pos)} />
                                    </Col>
                                </Row>
                                </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                })
            }
            {/* <List
            // header={<div>Header</div>}
            // footer={<div>Footer</div>}
            bordered
            dataSource={contactos}
            renderItem={(contact,pos) => (
                <List.Item>
                    { contact.nombres ? <Title level={5} className="m-0" ><FiUser /> {contact.nombres} { contact.apellidos ? contact.apellidos : false} {contact.email ? `· ${contact.email}` : ''} <Button icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} onClick={() => eliminarContacto(pos,i)} > ELIMINAR</Button> </Title> : false}
                </List.Item>
            )}
            /> */}
        </div>
    }

    const visualizarDatosRuta = () => {
        if(routes.length < 1) return false

        return <div>
            <Title level={4} className="mt-0 mb-3">Rutas</Title>
            {
                routes.map((route,i) => {
                    return <div key={`route_${i}`} className="mb-3">
                        {/* <Paragraph className="m-0">Ruta {i+1}</Paragraph> */}
                        {mostrarPasos(route.legs)}
                        <Paragraph className="mb-0 mb-0" style={{ fontSize: 12 }}><BiTime size={15} /> Tiempo <b style={{ fontWeight: "bold" }}>{formatTime(route.duration / 60)}</b></Paragraph>
                        <Paragraph className="mb-0 mb-0" style={{ fontSize: 12 }}><BsSpeedometer size={13} /> Distancia <b style={{ fontWeight: "bold" }}>{formatoMoneda(parseInt(route.distance / 1000))} Km.</b></Paragraph>

                    </div>
                })
            }
        </div>
    }

    const visualizarParadas = () => {


        return <div className="mb-3">
            <Title level={4} className="mt-0 mb-3">Puntos</Title>
            <Button onClick={() => addStop()} className="mb-3" icon={<AiOutlinePlus /> } >AÑADIR PUNTO</Button>
            <Collapse accordion defaultActiveKey={1} items={paradas.map((stop,i) => {

        let condicion_busqueda = {}
        if(usuario.id_cliente) {
            condicion_busqueda.id_cliente = usuario.id_cliente
        }

        const agregarContacto = (i) => {
            return setParadas(prev => {
                const actual = [...prev]
                if(!actual[i].contactos) actual[i].contactos = []
                actual[i].contactos.push({})
                return [...[], ...actual]
            })
        }

        const contenido = () => <div key={`stop_${i}`} className="mb-3">
            <Row gutter={15}>
                <Col md={8}>
                <Form.Item label="Dirección" required={true} className="mb-0">
                <AutoComplete
                    className="ant-input inputlocation"
                    defaultValue={stop.description}
                    options={{
                        types: ["address"],
                        // cokmponentRestrictions: { country: pais },
                    }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => procesarGeoParada(place,i)}
                    />
                    </Form.Item>
                </Col>
                <Col md={4} xs={24}>
                <Form.Item label="Tipo de parada" className="mb-0" required>
                    <Select
                    value={stop.tipo_entrega}
                    options={[{ value: "entrega", label: "Entrega" },{ value:"recogida", label: "Recogida" }]}
                    onChange={(e) => handleChangeSelectParada('tipo_entrega',e,i)} 
                    />
                </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                <Form.Item label="Observaciones" required={true} className="mb-0">
                    <Input placeholder="Observaciones del a dirección" className='mb-3' name="notas" value={stop.notas} onChange={(e) => handleChangeParada(e,i)} />
                </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                { usuario.id_cliente ? <ModalRelacionadorMiddleware 
                                            condicion={{ type_origin: "empresa", type_destination: "contacto", id_origin: { $in: usuario.id_cliente }  }} 
                                            onSelect={(data) => onSelectContactos(data,i)}
                                        /> : <Title level={5} className="m-0">Selecciona un cliente para añadir contactos de dicho cliente a este punto</Title> }
                </Col>
                <Col md={24} xs={24}>
                <Button onClick={() => agregarContacto(i)} icon={ <FaPlus /> }>AGREGAR CONTACTO</Button>
                {mostrarContactos(stop.contactos, i)}
                </Col>
            </Row>
            
        </div>

        const label = <div>
            <Row>
                <Col md={12}><MdOutlinePlace /> {stop.description ? stop.description : `Punto ${(i+1)}`}</Col>
                <Col md={12} style={{ textAlign:"right" }} >{i ? <Button size="small" type="text" icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} /> } onClick={() => removerParada(i)} >ELIMINAR</Button> : false}</Col>
            </Row>
        </div>

        return {
            key: (i+1),
            label,
            children: contenido()
        }
        })} />
        </div>
    }

    const handleChangeCheck = (e) => {
        usuario.tipo_seleccion_productos = e === true ? "avanzado" : "simple"
        guardarAutomaticamente(usuario)
        return setUsuario({...{}, ...usuario})
    }
    
    const visualizacionSimpleProductos = () => {

        return <div>
            <Card size="small">
            <Row gutter={15}>
                    <Col md={6} xs={24}>
                        <Form.Item label="Cantidad de bultos" required={siEsRequeridoEnEsquema('bultos',requeridos)} >
                        <Input addonBefore={usuario.unidad_medida} mb={3}  value={usuario.bultos} variant="filled" type="number" name="bultos" onChange={handleChangeUsuario} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Unidad de medida de longitud" required={siEsRequeridoEnEsquema('unidad_medida',requeridos)} >
                            <Select value={usuario.unidad_medida} options={unidades_medida.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelect(e,"unidad_medida")} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Ancho" required={siEsRequeridoEnEsquema('ancho',requeridos)} >
                        <Input addonBefore={usuario.unidad_medida} mb={3}  value={usuario.ancho} variant="filled" type="number" name="ancho" onChange={handleChangeUsuario} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Alto" required={siEsRequeridoEnEsquema('alto',requeridos)} >
                        <Input addonBefore={usuario.unidad_medida} mb={3}  value={usuario.alto}  variant="filled" type="number" name="alto" onChange={handleChangeUsuario} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Largo" required={siEsRequeridoEnEsquema('largo',requeridos)} >
                        <Input addonBefore={usuario.unidad_medida} mb={3} value={usuario.largo} variant="filled" type="number" name="largo" onChange={handleChangeUsuario} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Unidad de medida de peso" required={siEsRequeridoEnEsquema('unidad_medida_peso',requeridos)} >
                            <Select value={usuario.unidad_medida_peso} options={unidades_medida_peso.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelect(e,"unidad_medida_peso")} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Peso" required={siEsRequeridoEnEsquema('peso',requeridos)} >
                        <Input mb={3} addonBefore={usuario.unidad_medida_peso} variant="filled" type="number" name="peso" onChange={handleChangeUsuario} />
                        </Form.Item>
                    </Col>
            </Row>
            </Card>
        </div>

    }
    const visualizacionAvanzadaProductos = () => {

        return <div>
            <Button onClick={() => addProduct()} className="mb-3" icon={<AiOutlinePlus /> } >AÑADIR PRODUCTO</Button>
                
                <Collapse accordion defaultActiveKey={1} items={productos.map((pro,i) => {
    
            const contenido = <div key={`pro_${i}`} className="mb-3">
                <Row gutter={15}>
                    <Col md={6}>
                    <Form.Item label="Descripción" required={true} className="mb-0">
                    <Input className=' mb-3' name="descripcion" pos={i} value={pro.descripcion} onChange={(e) => handleChangeProducto(e,i)} />
                    </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Cantidad" required={siEsRequeridoEnEsquema('cantidad',requeridos)} >
                        <Input mb={3} pos={i}  value={pro.cantidad} variant="filled" type="number" name="cantidad" onChange={handleChangeProducto} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Unidad de medida de longitud" required={siEsRequeridoEnEsquema('unidad_medida',requeridos)} >
                            <Select value={pro.unidad_medida} options={unidades_medida.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelectProducto(e,"unidad_medida",i)} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Ancho" required={siEsRequeridoEnEsquema('ancho',requeridos)} >
                        <Input addonBefore={pro.unidad_medida} mb={3} pos={i}  value={pro.ancho} variant="filled" type="number" name="ancho" onChange={handleChangeProducto} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Alto" required={siEsRequeridoEnEsquema('alto',requeridos)} >
                        <Input addonBefore={pro.unidad_medida} mb={3} pos={i} value={pro.alto}  variant="filled" type="number" name="alto" onChange={handleChangeProducto} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Largo" required={siEsRequeridoEnEsquema('largo',requeridos)} >
                        <Input addonBefore={pro.unidad_medida} mb={3} pos={i}  value={pro.largo} variant="filled" type="number" name="largo" onChange={handleChangeProducto} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Unidad de medida de peso" required={siEsRequeridoEnEsquema('unidad_medida_peso',requeridos)} >
                            <Select value={pro.unidad_medida_peso} options={unidades_medida_peso.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelectProducto(e,"unidad_medida_peso",i)} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Peso" required={siEsRequeridoEnEsquema('peso',requeridos)} >
                        <Input mb={3} addonBefore={pro.unidad_medida_peso} value={pro.peso} variant="filled" type="number" pos={i} name="peso" onChange={handleChangeProducto} />
                        </Form.Item>
                    </Col>
                </Row>
                
            </div>
    
            const label = <div>
                <Row>
                    <Col md={12}><BsFillBoxSeamFill /> {pro.descripcion ? pro.descripcion : `Producto ${(i+1)}`}</Col>
                    <Col md={12} style={{ textAlign:"right" }} >{i ? <Button size="small" type="text" icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} /> } onClick={() => removerProducto(i, pro)} >ELIMINAR</Button> : false}</Col>
                </Row>
            </div>
    
            return {
                key: (i+1),
                label,
                children: contenido
            }
            })} />
        </div>
    }

    const visualizarCamposPersonalizados = () => {


        return <div>
            <CamposPersonalizadosRecurso titulo="Más información" id_target={"id"} tipo="viaje" />            
        </div>
    }

    const onSelectProducto = (data) => {
        if(Array.isArray(data) !== false){

            if(usuario.tipo_seleccion_productos !== "avanzado") setUsuario(prev => {
                let actual = {...prev}
                actual.tipo_seleccion_productos = 'avanzado'
                return {...{}, ...actual}
            })

            let nuevos = []

            for( const pro of data ){
                pro.cantidad = 1
                delete pro._id
                if(pro.sku){
                    const i = productos.findIndex(p => p.sku === pro.sku)
                    if( i < 0 ) nuevos.push(pro)
                } else {
                    nuevos.push(pro)
                }
            }
            return setProductos(prev => {
                let actual = [...prev]
                if(actual.length > 0) if(!actual[0].descripcion) actual.splice(0,1)
                guardarAutomaticamente(usuario)
                return [...actual, ...nuevos]

            })
        }
    }

    const visualizarProductos = () => {


        return <div>
            <Title level={4} className="mt-0 mb-3">Productos</Title>
            <ModalSeleccionProductos typeView="modal" slug="productos" onSelect={(data) => onSelectProducto(data)} />
            <Form.Item label="Descripción de productos" className="mb-2" required>
                <Switch checkedChildren="AVANZADO" unCheckedChildren="SIMPLE" onChange={handleChangeCheck} checked={usuario.tipo_seleccion_productos === "avanzado" ? true : false} />
            </Form.Item>

            { usuario.tipo_seleccion_productos === "avanzado" ? visualizacionAvanzadaProductos() : visualizacionSimpleProductos() }
            
        </div>
    }
    const visualizarDocumentacion = () => {


        return <div>
            <Title level={4} className="mt-0 mb-3">Archivos</Title>
            <MediosBox id_destino={id} />
            
        </div>
    }
    const visualizarRequisitosPorTipo = (tipo) => {


        const filtrados = requisitos.filter(e => e.tipo === tipo)

        return <div>
            <Row gutter={15}>
            {
                filtrados.map((req,i) => {
                    return <Col md={4} key={`req-${i}`}>
                        <Card size="small">
                        <Title level={5} className="m-0">{req.titulo}</Title>
                        <Paragraph level={5} className="m-0">{req.descripcion}</Paragraph>
                        <Button size="small" type="text" icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} /> } onClick={() => removerReq(req._id)} >ELIMINAR</Button>
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    const onSelectRequisito = (data) => {
        addRequeriment(data)
    }
    
    const visualizarRequisitos = () => {

        const boton = <Button icon={<GrAdd /> } className="mb-3" >AÑADIR NUEVO</Button>

        const items = [
            {
                label: <div style={{ textAlign: "center" }}><AiFillCar style={{ fontSize: 20 }} /> <Title level={4} className="m-0">DOCUMENTACIÓN DEL VEHÍCULO</Title></div>,
                children: <div>
                    <ModalSeleccionRequisitos boton={boton} slug="subir_requisitos_vehiculo" typeView="modal" onSelect={(data) => onSelectRequisito(data)} condicion_busqueda={{ tipo: "vehiculo", status: "active" }} /> 
                    {visualizarRequisitosPorTipo('vehiculo')}
                </div>
            },
            {
                label: <div style={{ textAlign: "center" }}><FiUser style={{ fontSize: 20 }} /> <Title level={4} className="m-0">DOCUMENTACIÓN DEL CONDUCTOR</Title></div>,
                children: <div>
                    <ModalSeleccionRequisitos boton={boton} slug="subir_requisitos_conductor" typeView="modal" onSelect={(data) => onSelectRequisito(data)} condicion_busqueda={{ tipo: "conductor", status: "active" }} /> 
                    {visualizarRequisitosPorTipo('conductor')}
                </div>
            },
            {
                label: <div style={{ textAlign: "center" }}><BsTruckFlatbed style={{ fontSize: 20 }} /> <Title level={4} className="m-0">CARACTERÍSTICA DEL VEHÍCULO</Title></div>,
                children: <div>
                    <Paragraph >Solo los vehículos con las características que selecciones debajo podrán transportas esta carga</Paragraph>
                    <CaracteristicasVehiculo defaultData={caracteristicas} onChange={(data) => setCaracteristicas(data)} />
                    {visualizarRequisitosPorTipo('caracteristica_vehiculo')}
                </div>
            },
        ].map((item,i) => ({ key: (i+1), ...item}))

        return <div>
            <Title level={4} className="mt-0 mb-3">Requisitos</Title>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    }
    

    const onChangeStep = (e) => {
        setCurrent(e)
    }

    const getDateByDate = (name) => {
        switch (name) {
            case "hora_retiro_desde":
                return usuario.fecha_retiro_desde ? usuario.fecha_retiro_desde : false
            case "hora_retiro_hasta":
                return usuario.fecha_retiro_hasta ? usuario.fecha_retiro_hasta : false
            case "hora_entrega_desde":
                return usuario.fecha_entrega_desde ? usuario.fecha_entrega_desde : false
            case "hora_entrega_hasta":
                return usuario.fecha_entrega_hasta ? usuario.fecha_entrega_hasta : false
            default:
                return false
        }
    }
    const getHourByDate = (name) => {
        switch (name) {
            case "fecha_retiro_desde":
                return usuario.hora_retiro_desde ? usuario.hora_retiro_desde : false
            case "fecha_retiro_hasta":
                return usuario.hora_retiro_hasta ? usuario.hora_retiro_hasta : false
            case "fecha_entrega_desde":
                return usuario.hora_entrega_desde ? usuario.hora_entrega_desde : false
            case "fecha_entrega_hasta":
                return usuario.hora_entrega_hasta ? usuario.hora_entrega_hasta : false
            default:
                return false
        }
    }

    const redefinirValorFecha = (name, value, final) => {
        let valor = usuario[name] ? usuario[name] : null
        const hora = valor ? DateTime.fromISO(valor).hour : null
        const minutos = valor ? DateTime.fromISO(valor).minute : null
        if(value){
            if(final === true){
                valor = DateTime.fromISO(value).endOf('day')
            } else {
                valor = DateTime.fromISO(value).set({ hour: hora, minute: minutos })
            }
        }

        // const hour = getHourByDate(name) 
        // console.log({ hour, name })
        // if(hour) {
        //     const horario = hour.split(":")
        //     valor = valor.set({hour: horario[0], minute: horario[1]})
        // }
        return valor.toUTC().toISO()
    }
    

    const handleChangeHour = (e) => {
        const { name, value } = e.target
        let key_fecha = `fecha_${name}`
        let fecha       = usuario[key_fecha] ? usuario[key_fecha] : null
        
        return setUsuario(prev => {
            let actual = { ...prev }
            if(fecha){
                if(value){
                    const horario = value.split(":")
                    actual[key_fecha] = DateTime.fromISO(actual[key_fecha]).set({hour: parseInt(horario[0]), minute: parseInt(horario[1]) }).toUTC().toISO()
                } else {
                    if(["retiro_hasta","entrega_hasta"].includes(name)){
                        actual[key_fecha] = DateTime.fromISO(actual[key_fecha]).endOf('day').toUTC().toISO()
                    } else {
                        actual[key_fecha] = DateTime.fromISO(actual[key_fecha]).startOf('day').toUTC().toISO()
                    }
                }
            }
            // guardarAutomaticamente(actual)
            return {...{}, ...actual}
        
        })
    }

    const handleChangeDateSimple = (e, final) => {
        const { name, value } = e.target
        let valor = redefinirValorFecha(name, value, final)
        return setUsuario(prev => {
            let actual = { ...prev }
            actual[name] = valor
            guardarAutomaticamente(actual)
            return {...{}, ...actual}
        })
    }
    const handleChangeDate = (e,name, final) => {
        const valor_input = e ? e['$d'] : null
        let valor_utc = valor_input ? DateTime.fromJSDate(e['$d']) : null
        
        if(valor_utc) if(final) valor_utc = valor_utc.endOf('day')
        
        usuario[name] = valor_utc ? valor_utc.toUTC().toISO() : null
        guardarAutomaticamente(usuario)
        return setUsuario(usuario)
    }

    const onChangeCliente = (e) => {
        usuario.id_cliente = e
        guardarAutomaticamente(usuario)
        return setUsuario(usuario)
    }

    const pasoAnterior = () => {
        return setCurrent(current-1)
    }
    const pasoSiguiente = () => {
        return setCurrent(current+1)
    }

    const navigation = (todos) => {
        if((current+1) === 1){
            return <Button icon={<BiRightArrowAlt /> } type="primary" onClick={() => pasoSiguiente()}>SIGUIENTE</Button>
        } else if((current+1) === todos){
            return <ButtonGroup>
                <Button icon={<BiLeftArrowAlt />} onClick={() => pasoAnterior()}>ANTERIOR</Button>
                <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => crearConductorNuevo()} ><SaveOutlined /> GUARDAR</Button>
                </ButtonGroup>
        } else {
            return <div>
                <ButtonGroup>
                <Button icon={<BiLeftArrowAlt />} onClick={() => pasoAnterior()}>ANTERIOR</Button>
                <Button icon={<BiRightArrowAlt />} type="primary" onClick={() => pasoSiguiente()} >SIGUIENTE</Button>
                </ButtonGroup>
            </div>
        }
    }

    const showComponentByTipoFormulario = (campo) =>{
        switch (campo.tipo_accion) {
          case 'campo-texto':
            if(!campo.valor) return false
            return <div className="text-primary">
              <Title level={5} className='mb-0 mt-0' style={{fontWeight:900}}>{campo.titulo}</Title>
              <Paragraph className='mb-0 mt-0'>{campo.valor ? campo.valor : 'Sin información'}</Paragraph>
            </div>
          case 'selector':
            if(!campo.valor) return false
            return <div className="text-primary">
              <Title level={5} className='mb-0 mt-0' style={{fontWeight:900}}>{campo.titulo}</Title>
              <Paragraph className='mb-0 mt-0'>{campo.valor ? campo.valor : 'Sin información'}</Paragraph>
            </div>
          case 'carga-imagenes':
            if(campo.valores.length < 1) return false
            return <div className="text-primary">
              <Title level={5} className='mb-0 mt-0' style={{fontWeight:900}}>{campo.titulo}</Title>
              
              {
                campo.valores.length > 0 ? campo.valores.map((foto,i) => {
                  return <div md={3} key={`foto-estado-${i}`} style={{ display: "inline-block" }}>
                    <Image src={foto.url} width={100} />
                    </div>
                }) : <Col md={12}><p className='text-danger' style={{fontWeight:700}}><i className="fa-solid fa-triangle-exclamation text-warning"></i> Sin información</p></Col>
              }
            </div>
          default:
            break;
        }
    }


    const mostrarMetaDatosSubEstado = (metadatos) => {
        if(!metadatos) return false
        if(Array.isArray(metadatos) !== true) return false
        if(metadatos.length < 1) return false
  
        return metadatos.map((meta,i) => {
          return <div key={`meta-${i}`}>
            <Divider className="mt-0 mb-2" />
            {showComponentByTipoFormulario(meta)}
          </div>
        })
      }

    const mostrarEstados = () => {
        if(estados.length < 1) return false

        return <div className="mt-3" style={{ marginBottom: 20 }}>
            
            <Title level={3} className="mt-0 mb-3"><CgTrack /> Trazabilidad</Title>

            <Card size="small" className="mb-3">
            <Steps
                size="small"
                current={estados.length > 0 ? (estados.length-1) : 0 }
                items={estados.map( (estado,i) => {
                    return {
                        title: `${estado.estado} ${estado.subestado}`,
                        description: fechaUTCATextoSimple(estado.createdAt)
                    }
                })}
            />
            </Card>

            <Collapse accordion items={estados.map((estado,i) => {
                
                let lat = 0
                let lng = 0

                if(estado.location){
                if(Array.isArray(estado.location.coordinates) !== false){
                    if(estado.location.coordinates.length > 1){
                    lat = estado.location.coordinates[0]
                    lng = estado.location.coordinates[1]
                    }
                }
                }

                const label = <div>
                            <Paragraph className="mb-0 mt-0">{fechaATexto(estado.createdAt)}</Paragraph>
                                    <Title level={4} style={{ textTransform: "uppercase" }} className="mb-0 mt-0">{estado.estado}</Title>
                        </div>

                const contenido = <div className="p-0 mx-3 shadow-sm text-left">

                    <div className="pl-2 pb-0">
                    <span style={{ fontWeight: 700 }}>{fechaATexto(estado.createdAt)} · <a href={`https://google.com/maps?q=${lat},${lng}`} target="_blank"><i className="fas text-primary fa-map-marker-alt"></i></a></span>
                    <Title level={5} className="mb-0 mt-0" style={{ textTransform: "uppercase", fontWeight:900}}><FaCircle color={estado.estado_color} /> <b>{estado.estado}</b> <b>{estado.subestado}</b></Title>
                    </div>

                        <Paragraph className="mb-0"><b>Canal:</b> {canalEstadosString(estado.canal)}</Paragraph>
                        <Paragraph className="mb-0"><b>Por :</b> {estado.identificador_creador}</Paragraph>
                        {
                            mostrarMetaDatosSubEstado(estado.metadatos)
                        }
                        
                </div>

                return {
                    key: (i+1),
                    label,
                    children: contenido
                }
            })} />
        </div>
    }

    const formularioRemitente = () => {
        return <Layout>
            <Card size="small" title={<div><FaRegUser style={{ verticalAlign: "middle" }} /> Datos del remitente</div>} className="mb-3">
            { usuario.id_cliente ? <ModalRelacionadorMiddleware 
                                            condicion={{ type_origin: "empresa", type_destination: "contacto", id_origin: { $in: usuario.id_cliente }  }} 
                                            onSelect={(data) => setUsuario(prev => {
                                                let actual = {...prev}
                                                let agregar = null
                                                if(Array.isArray(data)) if(data.length > 0) agregar = data[0]
                                                actual.remitente = {...agregar}
                                                // guardarAutomaticamente(actual)
                                                return {...{}, ...actual }
                                            })}
                                        /> : <Title level={5} className="m-0">Selecciona un cliente para añadir contactos de dicho cliente a este punto</Title> }
            <Form layout="vertical">
            <Row gutter={15}>
                <Col md={4}>
                <Form.Item label="Nombres" required={true} className="mb-0">
                    <Input placeholder="Nombres del contacto" className='mb-3' name="nombres" value={usuario.remitente?.nombres} onChange={handleChangeRemitente} />
                </Form.Item>
                </Col>
                <Col md={4}>
                <Form.Item label="Apellidos"  className="mb-0">
                    <Input placeholder="Apellidos" className='mb-3' name="apellidos" value={usuario.remitente?.apellidos} onChange={handleChangeRemitente} />
                </Form.Item>
                </Col>
                <Col md={6}>
                <Form.Item label="Email" className="mb-0">
                    <Input addonBefore={<FaRegEnvelope />} placeholder="Email" className='mb-3' name="email" value={usuario.remitente?.email} onChange={handleChangeRemitente} />
                </Form.Item>
                </Col>
                <Col md={10}>
                <Form.Item label="Móvil" className="mb-0">
                <Row gutter={15}>
                    <Col md={12}>
                        <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={usuario.remitente?.pais_codigo} onChange={(e) => handleChangeRemitenteSelect(e,"pais_codigo")} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                    </Col>
                    <Col md={12}>
                        <Input addonBefore={<GoDeviceMobile />} name="movil" value={usuario.remitente?.movil} onChange={handleChangeRemitente} />
                    </Col>
                </Row>
                </Form.Item>
                </Col>
            </Row>
        </Form>
            </Card>
        </Layout>
    }

    const activarInputFecha = (key, title) => {

        let defaultTime = ''

        if(usuario[`fecha_${key}`]){
            const fecha = DateTime.fromISO(usuario[`fecha_${key}`])
            defaultTime = fecha.toFormat("HH:mm")
        }

        return <Row gutter={15}>
            <Col xs={12}>
                <Form.Item label={<div><FaRegCalendarAlt /> { title ? title : "Desde" }</div> }>
                <Input type="date" style={{ width: "100%"}} defaultValue={usuario[`fecha_${key}`] ? formatYMD(usuario[`fecha_${key}`]) : ""} name={`fecha_${key}`} onChange={(e) => handleChangeDateSimple(e)} />
                </Form.Item>
            </Col>
            <Col xs={12}>
                <Form.Item label={<div><FaRegClock style={{ verticalAlign: "middle" }} /> Hora</div>}>
                <Input type="time" defaultValue={defaultTime} name={key} style={{ width: "100%"}} onChange={handleChangeHour} />
                </Form.Item>
            </Col>
        </Row>
    }

    const handleChangeRef = async (e) => {
        const { value } = e.target
        const valor = value.trim()
        if(!valor){
            setValidRef(true)
            return setLoadingRef(false)
        }
        setValidRef(false)
        await consultarReferencia(valor)
    }

    const iconValirRef = () => {
        if(typeof validRef === "undefined"){
            return <MdModeEdit />
        } else if( validRef === true ){
            return <FaCheckCircle color="green" />
        } else if( validRef === false ){
            return <MdError color="red" />
        }
    }

    const calcularCaminos = async (origen, puntos) => {
        console.log({ origen, puntos })
        if(!origen?.start_point) return false
        const origin = { location: origen.start_point }
        const destinos = puntos.map(p => ({ location: p?.start_point }))
        if(destinos.filter(e => e.location).length < 1) return false
        buscarDirecciones(origin, destinos)
    }

    const validarRespuestaRutaMapbox = (sugerencias) => {
        let way_points          = []
        let rutas_consultadas   = []
        let errores_mensajes    = []

        for( const res of sugerencias){
            if(res.uuid){
                if(res.code) if(res.code.toString().toUpperCase() === "OK") {
                    if(res.routes) if(Array.isArray(res.routes) !== false) if(res.routes.length > 0){
                        const adherir_uuid = res.routes.map(r => {
                            r.uuid = res.uuid
                            return r
                        })
                        rutas_consultadas = [...rutas_consultadas, ...[ res.routes[0] ] ]
                        way_points = [...way_points, ...res.waypoints]
                    }
                } else {
                    errores_mensajes.push(res.message)
                }
            }
        }
        if(errores_mensajes.length > 0) messageApi.error(errores_mensajes.join(', '))
        // setWayPoints(way_points)
        setRoutes(rutas_consultadas)
        guardarAutomatico(usuario, null, rutas_consultadas)
    }


    const mostrarPasos = (legs) => {
        if(!legs) return false
        if(Array.isArray(legs) !== true) return false
        if(legs.length < 1) return false

        return <div>
            {
                legs.map((leg,il) => {
                    
                    let cantidad_puntos = 0
                    if(leg.steps) if(Array.isArray(leg.steps) !== false) cantidad_puntos = leg.steps.length

                    return <div key={leg.summary}>
                        <Paragraph style={{ fontSize: 14 }} className="mt-0 mb-0">Descripción de la ruta</Paragraph>
                        <Paragraph style={{ fontSize: 14, fontWeight: "bold" }} className="mt-0 mb-0">{leg.summary ? leg.summary : "Sin información"}</Paragraph>
                        {/* <Paragraph style={{ fontSize: 12 }} className="mt-0 mb-0">{cantidad_puntos} Puntos de control</Paragraph> */}
                    </div>
                })
            }
        </div>
    }



    const buscarDirecciones = async (origen, puntos) => {
        // setLoadingRoutes(true)
        messageApi.open({
            type: 'loading',
            content: 'Calculando rutas..',
            duration: 0,
        })
        return fetch(`${urlapi}/geodata/direcciones`,{
            method:'POST',
            body: JSON.stringify({
                origen,
                puntos
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                validarRespuestaRutaMapbox(res)
            }
            return messageApi.destroy()
        })
        .catch(error => {
            return messageApi.destroy()
        })
    }

    const render = () => {

        if(loadingMaster) return <Layout style={{ padding: 30, textAlign: "center" }}><Title level={4} className="mt-0 mb-0"><Spin /> Cargando información...</Title> </Layout>

        const componente_direcciones = () => <div>
            <Row gutter={10}>
                <Col md={6} xs={24}>
                    <Form.Item label="Identificador" required={siEsRequeridoEnEsquema('identificador',requeridos)} >
                        <Input defaultValue={usuario.identificador} onChange={handleChangeRef} addonBefore={iconValirRef()}  addonAfter={ loadingRef ? <Spin size="small" /> : false } />
                    </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                    <SelectorClientes value={usuario.id_cliente} onChange={(data) => onChangeCliente(data)} titulo="Cliente" condicion={{}} />
                </Col>
                <Col md={6} xs={24}>
                <SelectorGlobal titulo="Tipo de servicio" condicion={{ tipo: "servicios" }} module="estadoscarga" key_label="title" key_value="_id" value={usuario.tipo} onChange={(e) => handleChangeSelect(e ? e._id : '', 'tipo')} />
                </Col>
                <Col md={24} xs={24}>
                    <Form.Item label="Origen del viaje" required={siEsRequeridoEnEsquema('origen',requeridos)} >
                    <AutoComplete
                    className="ant-input inputlocation"
                    defaultValue={usuario.direccion}
                    options={{
                        types: ["address"],
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                        const geo_data = procesarGeoDatosGeocode(place)

                        return setUsuario(prev => {
                            let actual = {...prev}
                            actual.direccion = place.formatted_address
                            actual.origen = {
                                    tipo: "origen",
                                    description: place.formatted_address,
                                    location: {
                                        latitude:geo_data.lat,
                                        longitude:geo_data.lng
                                    },
                                    geo_data,
                                    start_point: {
                                        type: "Point",
                                        coordinates: [
                                            geo_data.lng, geo_data.lat
                                        ]
                                    },
                                    details: place
                            }
                            calcularCaminos(actual.origen, paradas)
                            guardarAutomaticamente(actual)
                            return actual
                        })
                    }}
                    />
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    {formularioRemitente()}
                </Col>
                <Col md={24} xs={24}>
                    {visualizarParadas()}
                </Col>
                <Col md={24} xs={24}>
                    {visualizarDatosRuta()}
                </Col>
            </Row>
        </div>

        const component_notificaciones = <div>
            <Title level={4} className="mt-0">Notificaciones personalizadas</Title>
                    <Row gutter={15}>
                        <Col md={12}>
                        <Title level={4} className="mt-0" ><GoDeviceMobile /> Teléfonos</Title>
                        <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirMovilAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarMovilesAdicionales()}
                        </Col>
                        <Col md={12}>
                        <Title level={4} className="mt-0" ><AiOutlineMail /> Emails</Title>
                        <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirEmailAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarEmailsAdicionales()}
                        </Col>
                    </Row>
        </div>

        const condicion_costo = { id_target: id }
        const component_costos = <div>
            <Costos showCustomFieldsData={true} showClientSelector={false} showImportButton={false} condicion={condicion_costo} default_payload={condicion_costo}  />
        </div>

        const component_asignacion = <div>

            <Row>
                <Col md={12} xs={24}>
                    <Title level={3} className="mb-3 mt-0">Conductor asignado</Title>
                    <ModalSeleccionVehiculos showSelection={false} onRemove={() => {
                                setConductor(false)
                                usuario.id_conductor = ''
                                guardarAutomaticamente(usuario)
                                return setUsuario({...{}, ...usuario})
                    }} defaultValue={conductor} onSelect={data => setUsuario(prev => {
                        let actual = {...prev}
                        let val = data?._id
                        if(Array.isArray(data)) val = data[0]?._id
                        actual.id_conductor = val
                        return actual
                    })} />
                    <Divider />
                    <Title level={3} className="mb-3 mt-0">Vehículo asignado</Title>
                    <ModalSeleccionVehiculo showSelection={false} onRemove={() => {
                                setVehiculo(false)
                                usuario.id_vehiculo = ''
                                guardarAutomaticamente(usuario)
                                return setUsuario({...{}, ...usuario})
                    }} defaultValue={vehiculo} onSelect={data => {
                        let valor = data
                        if(data) if(Array.isArray(data)) valor = data[0]
                        setVehiculo(valor)
                        setUsuario(prev => {
                            let actual = {...prev}
                            actual.id_vehiculo = valor?._id
                            guardarAutomaticamente(actual)
                            return actual
                        })
                    }} />
                </Col>
                <Col md={12} xs={24}>
                    <Title level={3} className="mb-3 mt-0">Ruta asignada</Title>
                    <ModalSeleccionRuta defaultValue={usuario.ruta ? usuario.ruta : false} onSelect={(data) => {
                        setUsuario(prev => {
                            let actual = {...prev}
                            actual.id_ruta = data?._id
                            guardarAutomaticamente(actual)
                            return actual
                        })
                    }} onRemove={() => {
                        setUsuario(prev => {
                            let actual = {...prev}
                            actual.id_ruta = ""
                            guardarAutomaticamente(actual)
                            return actual
                        })
                    }} />
                </Col>
            </Row>
            
        </div>

        const component_finalizar = <div>
            <Row gutter={15}>
                <Col md={12} xs={24}>
                    <Row gutter={15}>
                        <Col xs={24}><Title level={4} className="mt-0">¿Cuándo se puede retirar?</Title> </Col>
                        <Col md={12}>
                            {activarInputFecha('retiro_desde')}
                            {/* <Row gutter={15}>
                                <Col xs={12}>
                                    <Form.Item label="Desde">
                                    <Input type="date" style={{ width: "100%"}} defaultValue={usuario.fecha_retiro_desde ? formatYMD(usuario.fecha_retiro_desde) : ""} name="fecha_retiro_desde" onChange={(e) => handleChangeDateSimple(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12}>
                                    <Form.Item label="Hora">
                                    <Input type="time" name="hora_retiro_desde" style={{ width: "100%"}} onChange={handleChangeUsuario} />
                                    </Form.Item>
                                </Col>
                            </Row> */}
                        </Col>
                        <Col md={12}>
                        {activarInputFecha('retiro_hasta', "Hasta")}
                            {/* <Row gutter={15}>
                                <Col xs={12}>
                                    <Form.Item label="Hasta">
                                    <Input type="date" style={{ width: "100%"}} defaultValue={usuario.fecha_retiro_desde ? formatYMD(usuario.fecha_retiro_desde) : ""} name="fecha_retiro_desde" onChange={(e) => handleChangeDateSimple(e)} />
                                    </Form.Item>
                                </Col>
                                <Col xs={12}>
                                    <Form.Item label="Hora">
                                    <Input type="time" name="hora_retiro_desde" style={{ width: "100%"}} onChange={handleChangeUsuario} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item label="Hasta">
                            <Input type="date" style={{ width: "100%"}} defaultValue={usuario.fecha_retiro_hasta ? formatYMD(usuario.fecha_retiro_hasta) : ""} name="fecha_retiro_hasta" onChange={(e) => handleChangeDateSimple(e, true)} />
                            </Form.Item> */}
                        </Col>
                    </Row>
                </Col>
                <Col md={12} xs={24}>
                    <Row gutter={15}>
                    <Col xs={24}><Title level={4} className="mt-0">¿Cuándo se debe entregar?</Title> </Col>
                        <Col md={12}>
                        {activarInputFecha('entrega_desde')}
                        </Col>
                        <Col md={12}>
                        {activarInputFecha('entrega_hasta', "Hasta")}
                        </Col>
                    </Row>
                </Col>
            </Row>

        </div>

        const steps = [
            {
              title: 'Direcciones',
              content: componente_direcciones(),
            },
            {
              title: 'Productos',
              content: visualizarProductos(),
            },
            {
              title: 'Requisitos',
              content: visualizarRequisitos(),
            },
            {
              title: 'Más info',
              content: <CamposPersonalizadosRecurso saveOnBlur={true} id_target={usuario._id} titulo="Más info" tipo="viaje" />   ,
            },
            {
              title: 'Documentación',
              content: visualizarDocumentacion(),
            },
            {
              title: 'Notificaciones',
              content: component_notificaciones,
            },
            {
              title: 'Costos',
              content: component_costos,
            },
            {
              title: 'Asignación',
              content: component_asignacion,
            },
            {
              title: 'Guardar',
              content: component_finalizar,
            },
          ];
    
        const itemSteps = steps.map((item) => ({
            key: item.title,
            title: item.title,
            ...item
          }));

          const componente_principal = <div>
            <Steps current={current} size="small" items={itemSteps} onChange={onChangeStep} />
            <Card size="small" style={{ marginTop: 20 }}>
            <CamposObligatoriosLeyenda />
            <Form layout="vertical">
            <div style={contentStyle}>{itemSteps[current].content}
            <div style={{ marginTop: 10 }}>{navigation(steps.length)}</div>
            </div>
            </Form>
            </Card>
          </div>
          
          const data = {
            paradas,
            productos,
            requisitos,
            caracteristicas,
            viaje: usuario
          }
          
          const items_tabs = [
            {   
                key:"editar",
                label: <Title level={4} className="mt-0 mb-0">Detalles del viaje</Title>,
                children: componente_principal
            },
            {   
                key:"tracking",
                label: <Title level={4} className="mt-0 mb-0">Trazabilidad</Title>,
                children: <DetalleViajeCliente id={id} />
            },
            {   
                key:"etiquetas",
                label: <Title level={4} className="mt-0 mb-0">Etiquetas</Title>,
                children: <GeneradorEtiquetas idrecurso={id} etiquetas={etiquetas} token={token} />
            },
            {   
                key:"busqueda_conductores",
                label: <Title level={4} className="mt-0 mb-0">Buscar vehículo</Title>,
                children: <div>
                    <AsignacionOrden autoSearch={true} trip={data} id_orden_gestion={id} />
                </div>
            },
          ]

        return <div>
            <Card className="mb-3">
            <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.trips.slug,
                    title: <span>Viajes</span>,
                },
                {
                    title: `Detalles del viaje`,
                },
                ]}
            />
            <Title className="mb-2 mt-0">{loadingMaster ? <Spin /> : false } Detalles del viaje {usuario?.internal_id} {usuario?.identificador}</Title>
            <ButtonGroup>
            <Popconfirm
                    title="Eliminar"
                    description="¿Estás seguro que deseas eliminar recurso? Esta acción no se puede deshacer"
                    onConfirm={() => eliminarRecurso()}
                    onCancel={() => false}
                    okText="SI"
                    style={{ display: "inline-block"}}
                    cancelText="NO"
                  >
                  <Button loading={deleting} type="dashed" danger>{usuario.status === "draft" ? "DESCARTAR BORRADOR" : "ELIMINAR"}</Button>
                  </Popconfirm>
                  <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => crearConductorNuevo()} ><SaveOutlined /> GUARDAR</Button>
                  </ButtonGroup>
            </Card>
            <Layout style={{ padding: 20 }}>

            <Tabs defaultActiveKey="editar" items={items_tabs} />

            </Layout>
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default CrearViaje