import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Card, Col, Modal, Row, Tabs, message } from "antd"
import { urlapi } from "../../lib/backend/data"
import CargandoTabla from "./cargando"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { formatoMoneda } from "../../lib/helpers/main"
import { FaCircle } from "react-icons/fa"
import ListadoViajes from "../Viajes/listado"
import { TEXT_LANG } from "../../lib/lang/main"
import { botones } from "../../lib/global/data"
import { BsBoxes, BsCardChecklist } from "react-icons/bs"
import RutaRuteoAsignado from "./ruteo_asignado"
import { MdOutlineAltRoute } from "react-icons/md"
import ActividadConductores from "../../subComponents/conductores/actividad"

const ResumenRuta = (props) => {
    const {
        id_ruta,
        component_button,
        typeView
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ condicionViajes, setCondicionViajes ] = useState(false)
    const [ estados, setEstados ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const pais = useSelector(state => state.pais)
    const icon = null
    const title = "Resumen de ruta"
    const slug = "Resumen de ruta"

    const module = 'rutas'

    const componente_viajes = <div>
                <ListadoViajes condicion_default={{ id_ruta }} />
    </div>

    const componente_ruteo = <div><RutaRuteoAsignado id_ruta={id_ruta} /></div>

    const componente_actividad = <div><ActividadConductores priorizeQuery={{ type: "special", sub_type:"orders-from-route", id_target: id_ruta }} /></div>

    const items = [
        {
            label: <div><Title level={4} className="mb-0 mt-3"><BsBoxes style={{ verticalAlign: "middle" }} /> Viajes asociados a esta ruta</Title></div>,
            children: componente_viajes
        },
        {
            label: <div><Title level={4} className="mb-0 mt-3"><MdOutlineAltRoute style={{ verticalAlign: "middle" }} /> Ruteo asignado</Title></div>,
            children: componente_ruteo
        },
        {
            label: <div><Title level={4} className="mb-0 mt-3"><BsBoxes style={{ verticalAlign: "middle" }} /> Actividad de la ruta</Title></div>,
            children: componente_actividad 
        },
    ].map((item,i) => ({...item, key: `${i}` }))

    const handleCancel = () => {
        setIsModalOpen(false);
      };

    const obtenerDatos = async ()=>{
        if(!id_ruta) return setLoading(false)
        setLoading(true)
        return fetch(`${urlapi}/${module}/status?id=${id_ruta}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res.estados) !== false){
                setEstados(res.estados)
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }
    
    useEffect(() => {
        console.log({id_ruta})
        obtenerDatos()
    },[  ])

    const boxEstados = () => {
        return <div>
            <Row>
                {
                    estados.map((est,i) => {
                        return <Col key={`estado-${i}`} md={6}>
                            <Paragraph level={5} className="mb-0 mt-0"><FaCircle style={{ verticalAlign: "middle" }} color={est.color ? est.color : "black"} /> {est.label}</Paragraph>
                            <Title level={3} className="mb-0 mt-0">{formatoMoneda(est.value)}</Title>
                        </Col>
                    })
                }
            </Row>
                <ListadoViajes condicion_default={{ id_ruta }} />
        </div>
    }

    const showComponent = () => {
        if(component_button) return <div onClick={() => setIsModalOpen(true)}>{component_button}</div>
        return <Card size="small" className=" hover" onClick={() => setIsModalOpen(true)} >
            <Title level={5} className="m-0" >{ icon ? icon : <BsCardChecklist style={{ verticalAlign: "middle" }} size={20} />} { title ? title : slug }</Title>
        </Card>
    }

    const mostrarEstados = () => {
        if(loading) return <CargandoTabla />

        return <div>
            {boxEstados()}
        </div>
    }

    if(typeView==="modal"){

        return <div>
                    <Modal
                    width="80%"
                    footer={[]}
                    title={TEXT_LANG(botones[slug]?.label, pais)}
                    open={isModalOpen} 
                    onCancel={handleCancel}
                >
                    <Row>
                        {
                            estados.map((est,i) => {
                                return <Col key={`estado-${i}`} md={6}>
                                    <Paragraph level={5} className="mb-0 mt-0"><FaCircle style={{ verticalAlign: "middle" }} color={est.color ? est.color : "black"} /> {est.label}</Paragraph>
                                    <Title level={3} className="mb-0 mt-0">{formatoMoneda(est.value)}</Title>
                                </Col>
                            })
                        }
                    </Row>
                    <Tabs defaultActiveKey="1" items={items} />
                </Modal>
                {showComponent()}
                </div>
    }

    return <div>
        {contextHolder}
        {mostrarEstados()}
    </div>
}

export default ResumenRuta