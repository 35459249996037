import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Select, message } from "antd";
import { urlapi } from "../../lib/backend/data";
import { cerrarSesion } from "../../redux/actions/sesion";

const SelectorZona = (props) => {
    const {
        defaultValue,
        title,
        title_button,
        condicion,
        error
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ opcionesComplete, setOpcionesComplete ] = useState([])
    const [ opciones, setOpciones ] = useState([])
    const [ valorSeleccionado, setValorSeleccionado ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()

    const obtenerDatos = async () => {
        setLoading(true)
        return fetch(`${urlapi}/geodata/zonas/list/select`, {
          method: 'POST',
          body: JSON.stringify({
            condicion
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              setOpcionesComplete(res)
              setOpciones(res.filter(m => m.titulo).map(op => ({ value: op._id, label: op.titulo.toUpperCase() })))
            }
            return setLoading(false);
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }

      const obtenerDatosPorId = async () => {
        setLoadingDetalle(true)
        return fetch(`${urlapi}/geodata/zonas?id=${valorSeleccionado._id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(res._id){
                if(props.onSelected) props.onSelected(res)
                setValorSeleccionado(false)
            }
            return setLoadingDetalle(false);
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingDetalle(false);
          })
      }

      const onChangeValue = (e) => {
        const i = opcionesComplete.findIndex(m => m._id === e)
        if(props.onChange) props.onChange(opcionesComplete[i])
        setValorSeleccionado(opcionesComplete[i])
      }

      useEffect(() => {
        obtenerDatos()
      }, [  ])

      return <div>
        {contextHolder}
        <Form layout="vertical">
            <Row gutter={10}>
                <Col md={14}>
                <Form.Item label={title ? title : "Zona"}>
                <Select
                    defaultValue={defaultValue}
                    style={ error === true ? {
                        width: "100%",
                        border: "1.5px solid red",
                        borderRadius: 8
                    } : { width: "100%" }}
                    showSearch={true}
                    filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                    loading={loading}
                    options={opciones}
                    onChange={onChangeValue}
                    />
                    </Form.Item>
                </Col>
                <Col md={10}>
                    <Form.Item label="Click para agregar zona">
                    <Button disabled={valorSeleccionado ? false : true } loading={loadingDetalle} type="primary" onClick={() => obtenerDatosPorId()}>{title_button ? title_button : "SELECCIONAR"}</Button>
                    </Form.Item>
                </Col>
            </Row>
            
            </Form>
      </div>
}

export default SelectorZona