import { Button, Modal, Table } from "antd";
import Typography from "antd/es/typography/Typography";
import { useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { GoDeviceMobile } from "react-icons/go";
import Column from "antd/es/table/Column";

const MostrarProductos = (props) => {
    const {
        size,
        datos
    } = props
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    let esquema = [
        { value: 'descripcion', label: 'Producto', producto: true },
        { value: 'cantidad', label: 'Cantidad', producto: true, optionalUpload: true },
        { value: 'unidad_medida', label: 'Unidad medida', producto: true, optionalUpload: true, info: "Solo usar: MTR, INCH, CM, SQFT, SQM"  },
        { value: 'ancho', label: 'Ancho', producto: true, optionalUpload: true },
        { value: 'alto', label: 'Alto', producto: true, optionalUpload: true },
        { value: 'largo', label: 'Largo', producto: true, optionalUpload: true },
        { value: 'unidad_medida_peso', producto: true, label: 'Unidad medida peso', optionalUpload: true, info: "Solo usar: KG, LB, KGPCM, LBPCIN" },
        { value: 'peso', label: 'Peso', producto: true, optionalUpload: true },
    ]

    const sin_datos = <Typography><BsFillBoxSeamFill /> Sin datos</Typography>

    if(!datos) return sin_datos
    if(Array.isArray(datos) !== true) return sin_datos
    if(datos.length < 1) return sin_datos
    
    return (
        <>
        <Button size={size ? size : false} icon={<BsFillBoxSeamFill />} onClick={showModal}>VER PRODUCTOS</Button>
        <Modal title="Productos" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
        <Table dataSource={datos} pagination={false} scroll={{ x:"70vw" }}>
        
            {esquema.map((esq,posicion) => {
                return <Column key={`esq-${posicion}`} title={esq.label} render={(data,i) => {
                    return <div>
                        <Typography>{data[esq.value]}</Typography>
                    </div>
                }}>
                    
                </Column>
            })}
        </Table>
        </Modal>
        </>
    );
  }

export default MostrarProductos