import { Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Spin, Switch, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { maps_key, urlapi } from "../../lib/backend/data";
import { PlusCircleFilled } from "@ant-design/icons";
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios";
import AutoComplete from "react-google-autocomplete";
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps";
import { codigos_area, codigos_pais } from "../../lib/internacional";
import { filterOption, formatYMD } from "../../lib/helpers/helpers";
import Title from "antd/es/typography/Title";
import { GoDeviceMobile } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import SelectorClientes from "../Clientes/selector";
import { modulo } from "./data";
import dayjs, { locale } from "dayjs";
import { DateTime } from "luxon";
import { FaTruckFront, FaTruckRampBox } from "react-icons/fa6";
import { GiHandTruck } from "react-icons/gi";
import ModalSeleccionVehiculo from "../Vehiculos/modal_seleccion";
import { FaChevronRight, FaInfoCircle, FaRegTrashAlt } from "react-icons/fa";
import SelectorZonaAvanzadoInternacional from "../RadioOperacion/selector_zona_avanzado_internacional";
import Paragraph from "antd/es/typography/Paragraph";
import SelectorGlobal from "../../subComponents/general/selector";

const CrearNuevoContacto = (props) => {
    const {
        default_payload
    } = props
    const [ campo, setCampo ] = useState(default_payload ? default_payload : {})
    const pais = useSelector(state => state.pais)
    const [ showSelectorCliente, setShowSelectorCliente ] = useState(true)
    const idioma = useSelector(state => state.idioma)
    const [ loadingAgregado, setLoadingAgregado ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ vehiculos, setVehiculos ] = useState([])
    const [messageApi, contextHolder] = message.useMessage()
    const [ estadosCanal, setEstadosCanal ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ estadoSeleccionado, setEstadoSeleccionado ] = useState(false)
    const [ canal, setCanal ] = useState({})
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const [ loadingEliminado, setLoadingEliminado ] = useState(false)
    const [ guardandoEstado, setGuardandoEstado ] = useState(false)
    const [ propietarioBusqueda, setPropietarioBusqueda ] = useState(session.data.empresa)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const module = modulo

    const requeridos = [
        { value: 'titulo', label: 'Título' },
    ];

    useEffect(() => {
        if(default_payload) if(typeof default_payload === "object") if(default_payload.id_cliente) setShowSelectorCliente(false)
    }, [])
    
    const crearCampo = async () => {
        const instancia_campo = { ...campo }
        let faltantes = []
        requeridos.map(field => {
            if(!instancia_campo[field.value]) faltantes.push(field.label)
            return true
        })

        setLoading(true)
        return fetch(`${urlapi}/${module}`,{
            method:'POST',
            body: JSON.stringify(instancia_campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                setIsModalOpen(false)
                setCampo({...{}, ...{}})
                setVehiculos([...[], ...[]])
                if(props.onCreate) props.onCreate()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
        return crearCampo()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = { ...prev }
            actual[name] = value
            return { ...actual, ...{} }
        })
    }

    const handleChangeCustom = (value,name) => {
        campo[name] = value
        console.log(campo)
        return setCampo(campo)
    }


    const handleChangeSelect = (name, value) => {
        return setCampo(prev => {
            let actual = { ...prev }
            actual[name] = value
            return { ...actual, ...{} }
        })
    }

    const mostrarSelectorClientes = () => {
        if(!showSelectorCliente) return false

        return <div>
            <SelectorClientes key={key_input_filter} titulo="Cliente" onChange={(data) => handleChangeCustom(data,'id_cliente') } condicion={{}} />
        </div>
    }

    const remover = (i) => {
        setVehiculos(prev => {
            let actual = [...prev]
            actual.splice(i,1)
            return actual
        })
    }

    const mostrarVehiculos = () => {
        return <div>
            <Divider className="mt-3 mb-3" />
            {
                vehiculos.map((veh,iv) => {
                    return <div key={`veh-${iv}`}>
                        <Row gutter={15}>
                            <Col md={18}><Title level={5} className="mt-0 mb-2"><FaTruckFront style={{ verticalAlign: "middle" }} /> {veh.identificador}</Title></Col>
                            <Col md={6}><Button size="small" icon={<FaRegTrashAlt />} onClick={() => remover(iv)} >REMOVER</Button> </Col>
                        </Row>
                        { vehiculos.length > 1 ? <Divider className="mt-3 mb-3" /> : false}
                    </div>
                })
            }
        </div>
    }

    const handleChangeDate = (e,name, final) => {
        const valor_input = e ? e['$d'] : null
        let valor_utc = valor_input ? DateTime.fromJSDate(e['$d']) : null
        
        if(valor_utc) if(final) valor_utc = valor_utc.endOf('day')
        
        return setCampo(prev => {
            let actual = { ...prev }
            actual[name] = valor_utc ? valor_utc.toUTC().toISO() : null
            return actual
        })
    }

    const mostrarMantenedorFechas = () => {
        if(campo.tipo_disponibilidad === "unica"){
            return <Col md={24}><Row>
                <Col md={24}>
                        <Title level={3} className="mt-0 mb-2"><GiHandTruck style={{ verticalAlign: "middle" }} /> Carga</Title>
                            <Row gutter={15}>
                                <Col xs={24}>
                                    <Row gutter={15}>
                                        <Col md={12}>
                                        <Form.Item label="Desde" className="mb-3">
                                        {/* <DatePicker placeholder="Seleccionar fecha" type style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={campo.fecha_carga_desde ? dayjs(formatYMD(campo.fecha_carga_desde), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(e,'fecha_carga_desde')} /> */}
                                        <Input type="date" name="fecha_carga_desde" value={campo.fecha_carga_desde} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                        <Col md={12}>
                                        <Form.Item label="Hora" className="mb-3">
                                        <Input type="time" style={{ width: "100%"}} name="hora_carga_desde" value={campo.hora_carga_desde} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={15}>
                                        <Col md={12}>
                                        <Form.Item label="Hasta" className="mb-3">
                                        <Input type="date" name="fecha_carga_hasta" value={campo.fecha_carga_hasta} onChange={handleChange} />
                                        {/* <DatePicker placeholder="Seleccionar fecha" type style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={campo.fecha_carga_hasta ? dayjs(formatYMD(campo.fecha_carga_hasta), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(e,'fecha_carga_hasta')} /> */}
                                        </Form.Item>
                                        </Col>
                                        <Col md={12}>
                                        <Form.Item label="Hora" className="mb-3">
                                        <Input type="time" style={{ width: "100%"}} name="hora_carga_hasta" value={campo.hora_carga_hasta} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                    </Row>
                                   
                                    
                                </Col>
                            </Row>
                    </Col>
                    <Col md={24}>
                        <Title level={3} className="mt-0 mb-2"><FaTruckRampBox style={{ verticalAlign: "middle" }} /> Descarga</Title>
                            <Row gutter={15}>
                                <Col xs={24}>
                                    <Row gutter={15}>
                                    <Col md={12}>
                                    <Form.Item label="Desde" className="mb-3">
                                    {/* <DatePicker placeholder="Seleccionar fecha" type style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={campo.fecha_carga_desde ? dayjs(formatYMD(campo.fecha_carga_desde), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(e,'fecha_carga_desde')} /> */}
                                    <Input type="date" name="fecha_descarga_desde" value={campo.fecha_descarga_desde} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                    <Form.Item label="Hora" className="mb-3">
                                    <Input type="time" style={{ width: "100%"}} name="hora_descarga_desde" value={campo.hora_descarga_desde} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                    </Row>
                                </Col>
                                <Col xs={24}>
                                <Row gutter={15}>
                                    <Col md={12}>
                                    <Form.Item label="Hasta" className="mb-3">
                                    {/* <DatePicker placeholder="Seleccionar fecha" type style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={campo.fecha_carga_hasta ? dayjs(formatYMD(campo.fecha_carga_hasta), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(e,'fecha_carga_hasta')} /> */}
                                    <Input type="date" name="fecha_descarga_hasta" value={campo.fecha_descarga_hasta} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                    <Form.Item label="Hora" className="mb-3">
                                    <Input type="time" style={{ width: "100%"}} name="hora_descarga_hasta" value={campo.hora_descarga_hasta} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                </Row>
                                    
                                    
                                </Col>
                            </Row>
                    </Col>
            </Row>
            </Col>
        } else if (campo.tipo_disponibilidad === "recurrente"){
            return <Col md={24}>
                <Form.Item label="Vencimiento" className="mb-2">
                <Input type="date" name="vencimiento" value={campo.vencimiento} onChange={handleChange} />
                </Form.Item>
                </Col>
        }
    }

    const agregarUbicacion = (data, key) => {
        if(!data) return
        setCampo(prev => {
            let actual = {...prev}
            if(!actual[key]) actual[key] = []
            const i = actual[key].findIndex(e => e._id === data._id)
            if(i < 0) actual[key].push({ _id: data._id, titulo: data.titulo, slug: data.slug })
            return actual
        })
    }

    const mostrarUbicaciones = (key) => {
        if(!campo[key]) return false
        if(campo[key].length < 1) return false
        return <div>
            {
                campo[key].map((ubi,i) => <Title level={4} key={`ubi-${i}`} className="mt-0 mb-2"><FaChevronRight style={{ verticalAlign: "middle" }} /> {ubi.titulo}</Title>)
            }
        </div>
    }

    const agregarEstadoACanal = async () => {
        let guardar = JSON.parse(JSON.stringify(estadoSeleccionado))
        delete guardar._id
        // guardar.id_canal = idcanal
        guardar.id_estado = estadoSeleccionado._id
        guardar.propietario = propietarioBusqueda.rut
        guardar.cliente = propietarioBusqueda.razon_social
        setLoadingAgregado(true)
        return fetch(`${urlapi}/bodega/canal/estado`,{
            method: "POST",
            body: JSON.stringify(guardar),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                setEstadosCanal(prev => [...prev, ...[res]])
            }
            return setLoadingAgregado(false)
        })
        .catch(error => {
            messageApi.error(error.message)
            setLoadingAgregado(false)
        })
    }

    const eliminarEstadoACanal = async (id) => {
        setLoadingEliminado(id)
        return fetch(`${urlapi}/bodega/canal/estado?id=${id}`,{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Eliminado exitosamente")
                const i = estadosCanal.findIndex(channel => channel._id === id)
                if(i > -1){
                    estadosCanal.splice(i,1)
                    setEstadosCanal(prev => [...[], ...estadosCanal])
                }
            }
            return setLoadingEliminado(false)
        })
        .catch(error => {
            messageApi.error(error.message)
            setLoadingEliminado(false)
        })
    }

    const guardarEstadoACanal = async (id) => {
        const i = estadosCanal.findIndex(estado => estado._id === id)
        setGuardandoEstado(id)
        return fetch(`${urlapi}/bodega/canal/estado`,{
            method: "PUT",
            body: JSON.stringify(estadosCanal[i]),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                const i = estadosCanal.findIndex(channel => channel._id === id)
            }
            return setGuardandoEstado(false)
        })
        .catch(error => {
            messageApi.error(error.message)
            setGuardandoEstado(false)
        })
    }

    const setBoleanProperty = (key) => {
        const valor = canal[key] === true ? false : true
        canal[key] = valor
        return setCanal({...{}, ...canal})
    }

    const mostrarEsquemaEstados = () => {
        if(estadosCanal.length < 1) return false

        const empresas = []

        for( const registro of estadosCanal){
            const i = empresas.findIndex(empresa => empresa.rut === registro.propietario)
            if(i < 0){
                empresas.unshift({
                    rut: registro.propietario,
                    titulo: registro.cliente,
                    estados: [
                        registro
                    ]
                })
            } else {
                if(!empresas[i].titulo) empresas[i].titulo = registro.cliente
                empresas[i].estados.unshift(registro)
            }
        }

        return <div className='mt-3'>
            <h4 className='mb-2'>{estadosCanal.length} Estados asignados a este canal</h4>
            <hr className='mb-2 mt-0' />
            {
                empresas.map((empresa,iemp) => {
                    return <div key={`empresa-${iemp}`}>
                        <h4 className='mb-2'><i class="fas fa-arrow-circle-right"></i> {empresa.titulo}</h4>
                        <div className='pl-3'>
                        {
                            empresa.estados.map((estado,iestado) => {
                                return <div className='card p-3 mb-3' key={estado._id}>
                                    <Row>
                                        <Col md={16}><Title level={5} className="mb-0 mt-0"><i style={{ color: estado.color ? estado.color: 'black' }} class="fas fa-circle"></i> {estado.titulo}</Title></Col>
                                        <Col md={8} style={{ textAlign: "right" }}>{ loadingEliminado === estado._id ? <Spin animation='border' /> : <Button type="dashed" size="small" danger onClick={() => eliminarEstadoACanal(estado._id)} icon={<FaRegTrashAlt style={{ verticalAlign: "middle" }} />} > ELIMINAR</Button> }</Col>
                                    </Row>
                                </div>
                            })
                        }
                        </div>
                    </div>
                })
            }
        </div>
    }
    
    return <div>
        <Button className="mb-3" type="primary" size="small" onClick={() => {
            setKeyInputFilter(key_input_filter + 1)
            return showModal()
        }}><PlusCircleFilled /> CREAR NUEVO</Button>
        <Modal 
            title="Crear nuevo canal " 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <CamposObligatoriosLeyenda />
            {/* {mostrarSelectorClientes()} */}
            <Form layout="vertical">
                <Row gutter={15} >
                    {/* <Col md={24}>
                        <Form.Item label="Estado" className="mb-2">
                        <Select options={[{ value: 'active', label: "Activo" },{ value: "inactive", label: "Inactivo" }]} showSearch filterOption={filterOption} defaultValue={!campo.status ? "" : campo.status} name="status" onChange={(e) => handleChangeSelect("status", e)} />
                        </Form.Item>
                    </Col> */}
                    <Col md={12}>
                        <Form.Item label="Título" required className="mb-3">
                        <Input name="titulo" value={campo.titulo} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Descripción" required className="mb-3">
                        <Input name="descripcion" value={campo.descripcion} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Title level={4} className="mt-0 mb-0">Asignar app conductor</Title>
                        <Paragraph style={{ fontSize: 12 }} >Asignar órdenes al conductor a través en App Conductor</Paragraph>
                        <Form.Item className="mb-2" required>
                            <Switch checkedChildren="SI" unCheckedChildren="NO" onChange={(e) => handleChange({ target: { name:"asignar_app", value: e } })} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Title level={4} className="mt-0 mb-0">Asignar app conductor</Title>
                        <Paragraph style={{ fontSize: 12 }} >Las órdenes se reasignarán a una nueva ruta, saldrán de la ruta actual</Paragraph>
                        <Form.Item className="mb-2" required>
                            <Switch checkedChildren="SI" unCheckedChildren="NO" onChange={(e) => handleChange({ target: { name:"crear_ruta", value: e } })} />
                        </Form.Item>
                    </Col>
                    <Col md={24}>
                        <Title level={4} className="mt-0 mb-0">Estados asignados a este canal</Title>
                        <Paragraph style={{ fontSize: 12 }} >Estos son los estados que se disparan al confirmar órdenes dentro de este canal.</Paragraph>
                        <Title level={4} className="mt-0 mb-0"><FaInfoCircle style={{ verticalAlign: "middle" }} /> Una vez creado el canal podrás asignar estado</Title>
                        {/* <Row gutter={15}>
                            <Col md={16}><SelectorGlobal style={{ width: "100%" }} titulo="Estado de carga"  condicion={{ }} module="protocolos" key_label="titulo" key_value="_id" onChange={(data) => setEstadoSeleccionado(data)} /></Col>
                            <Col md={8}>
                                <Paragraph className="mb-2">Click para agregar</Paragraph>
                                <Button  type="primary" disabled={ estadoSeleccionado ? false : true } loading={loadingAgregado} onClick={() => agregarEstadoACanal()}  >AGREGAR</Button>
                            </Col>
                        </Row> */}
                        
                    </Col>
                </Row>
                    {mostrarEsquemaEstados()}
            </Form>
      </Modal>
      {contextHolder}
    </div>
}

export default CrearNuevoContacto