import React, { useEffect } from 'react'
import { cerrarSesion } from '../../redux/actions/sesion'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../Header'
import Structure from '../Structure'
import Title from 'antd/es/typography/Title'
import { Avatar, Button, Card, Carousel, Col, Divider, List, Pagination, Progress, Row, Segmented, Statistic, Tabs, Tooltip } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import { url_images } from '../../lib/backend/data'
import { MdArrowForwardIos, MdOutlineMonitorHeart, MdSpatialTracking } from 'react-icons/md'
import { estilo_moving_truck } from '../../lib/estilo_sitio'
import { Link } from 'react-router-dom'
import { LuWarehouse } from "react-icons/lu";
import { rutas } from '../../lib/routes/routes'
import { GoTable } from 'react-icons/go'
import { BiSolidMapPin, BiTrip, BiUser } from 'react-icons/bi'
import { FaBusinessTime, FaTruck } from 'react-icons/fa'
import { GiTowTruck } from 'react-icons/gi'
import { AiOutlineContacts } from 'react-icons/ai'
import ButtonGroup from 'antd/es/button/button-group'

const Home = (props) => {
  const dispatch = useDispatch()
  const { data, tokenSession } = useSelector(state => state.miusuario)
  const contentStyle = {
  margin: 0,
  width: "100%", 
  height: '90vh',
  display: "flex",
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

  const lista = [
    {
      "title": "Gestión de Flota",
      "subtitle": "Administra con Inteligencia, Actúa con Estrategia",
      "description": "Desde mantenimiento hasta rendimiento, una solución integral que eleva la administración de tu flota a niveles superiores de eficacia.",
      "image": `${url_images}/stock/drivers-stock.jpg`,
      actions:[
        <Link to={`${rutas.vehicles.slug}`}><FaTruck /> VEHICULOS</Link>,
        <Link to={`${rutas.equipment.slug}`}><GiTowTruck /> EQUIPOS</Link>,
      ]
    },
    {
      "title": "Geo Alertas",
      "subtitle": "Localización en Vivo, Seguridad Asegurada",
      "description": "Vigila cada movimiento con precisión GPS. Geo Alertas te ofrece tranquilidad al saber exactamente dónde están tus vehículos en todo momento.",
      "image": `${url_images}/stock/monitoreo.webp`,
      actions:[
        <Link to={`${rutas.alerts.slug}`}><GoTable /> VER LISTADO</Link>,
        <Link to={`${rutas.map_alerts.slug}`}><BiSolidMapPin /> VER MAPA</Link>
      ]
    },
    {
      "title": "Monitoreo en Tiempo Real",
      "subtitle": "Vigilancia Continua para una Operativa Impecable",
      "description": "Observa tu flota con monitoreo 24/7. Actúa rápidamente con datos en tiempo real y maximiza la productividad.",
      "image": `${url_images}/stock/truck-cenital.webp`,
      actions:[
        <Link to={`${rutas.drivers.slug}`}><MdOutlineMonitorHeart /> MONITOREO</Link>,
        <Link to={`${rutas.vehicles.slug}`}><MdSpatialTracking /> INFORME DE RASTREO</Link>,
      ]
    },
    {
      "title": "CRM Integrado",
      "subtitle": "Todos tus Clientes, Una Sola Plataforma",
      "description": "Centraliza la gestión de tus clientes y fortalece tus relaciones comerciales con nuestro CRM avanzado.",
      "image": `${url_images}/stock/crm.jpg`,
      actions:[
        <Link to={`${rutas.drivers.slug}`}><FaBusinessTime /> CLIENTES</Link>,
        <Link to={`${rutas.vehicles.slug}`}><AiOutlineContacts /> CONTACTOS</Link>,
        <Link to={`${rutas.warehouses.slug}`}><LuWarehouse /> BODEGAS</Link>,
      ]
    },
    {
      "title": "Gestión de Conductores",
      "subtitle": "Administra, Motiva y Desarrolla a tu Personal",
      "description": "Administra horarios, evalúa desempeño y gestiona capacitaciones con herramientas diseñadas para líderes de flota.",
      "image": `${url_images}/stock/conductores.webp`,
      actions:[
        <Link to={`${rutas.drivers.slug}`}><BiUser /> CONDUCTORES</Link>,
      ]
    },
    {
      "title": "Órdenes de Transporte",
      "subtitle": "Del Pedido al Envío con Facilidad",
      "description": "Organiza y gestiona tus cargas con eficiencia. Nuestra herramienta te ayuda a mantener todo en orden y a tiempo.",
      "image": `${url_images}/stock/ordenes.jpg`,
      actions:[
        <Link to={`${rutas.trips.slug}`}><BiTrip /> VIAJES</Link>
      ]
    }
  ]
  

  const render = () => {
    return <div style={{ height: "90vh" }}>
        <div style={{ background: "#f3f3f3", height: "90vh" }}>
        {/* <Title level={2} className='mt-0'>Bienvenido a Moving Truck</Title> */}
        <Carousel style={{ height: "100%" }} dotPosition="left" infinite={false}>
        {
            lista.map((item,i) => {
              return <div key={`a-${i}`} style={{ width: "100%"}}>
                <div style={{...contentStyle, ...{ background: `url("${item.image}")`, backgroundPosition: "center center", backgroundSize:"cover", backgroundPosition: 'center' } }}>
                <div style={{ padding: 20, background: "#00000094", borderRadius: 5 }}>
                <Title level={1} className='mt-0 mb-0' style={{ color: "white", fontSize: 40, fontWeight: "bold" }} >{item.title}</Title>
                <Paragraph style={{ color: "white", fontSize:14, borderRadius: 3, marginTop:5, textTransform: "uppercase", fontWeight: "bold" }}>{item.subtitle}</Paragraph>
                {/* {item.image ? <img src={item.image} style={{ height: 250 }} /> : false } */}
                {/* <Paragraph className='mt-0 mb-0'>{item.description}</Paragraph> */}

                <ButtonGroup>
                {
                  item.actions.map((action,pos) => {
                    return<Button type="default" key={`${i}-icon-${pos}`}>{action}</Button>
                  })
                }
                </ButtonGroup>
                </div>
                </div>
            </div>
            })
          }
        </Carousel>
        </div>
        
    </div>
  }

  return <Structure component={render()} />

}

export default Home
