const stringByStatus = (status) => {
    switch (status) {
        case "inactive":
            return 'Inactivo'
        case "active":
            return 'Activo'
        default:
            return 'Sin información'
    }
}

const requeridos = [
    { value: 'titulo', label: 'Título' },
    { value: 'tipo_disponibilidad', label: 'Tipo' },
];
const campos_fecha = [
    'fecha_carga_desde',
    'fecha_carga_hasta',
    'fecha_descarga_desde',
    'fecha_descarga_hasta'
]

const modulo = 'disponibilidades/solicitudes-externas'

const tipos_disponibilidad = [{ value: 'recurrente', label: "Recurrente" },{ value: "unica", label: "Única" }]

const stringByStatusSolicitud = (val) => {
    switch (val) {
        case "pendiente-cliente":
            return "Espera por propietario"
        case "rechazada-cliente":
            return "Rechazada por propietario"
        case "pendiente-proveedor":
            return "Enviada por el cliente"
        case "rechazada-proveedor":
            return "Rechazada por proveedor"
        case "aceptada-cliente":
            return "Aceptada por propietario"
        case "aceptada-proveedor":
            return "Aceptada por proveedor"
        case "cotizada":
            return "En negociación"
        case "cancelada":
            return "Cancelada"
        default:
            break;
    }
}

const estados_solicitud = [
    { value: 'pendiente-cliente', label: 'Pendiente por cliente' },
    { value: 'pendiente-proveedor', label: 'Pendiente por proveedor' },
    { value: 'rechazada-cliente', label: 'Rechazada por cliente' },
    { value: 'rechazada-proveedor', label: 'Rechazada por proveedor' },
    { value: 'aceptada-cliente', label: 'Aceptada por cliente' },
    { value: 'aceptada-proveedor', label: 'Aceptada por proveedor' }
]

module.exports = {
    stringByStatus,
    modulo,
    requeridos,
    campos_fecha,
    tipos_disponibilidad,
    stringByStatusSolicitud,
    estados_solicitud
}