import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Alert, Breadcrumb, Button, Card, Col, Divider, Form, Input, Layout, Popconfirm, Row, Select, Tabs, Upload, message } from "antd"
import { CameraOutlined, CarFilled, HomeOutlined, InfoCircleFilled, LoadingOutlined, PlusOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { filterOption, limpiarStringLetrasNumeros, limpiarStringNumeros, siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import { config_s3, maps_key, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import AutoComplete from "react-google-autocomplete";
import S3FileUpload from 'react-s3';
import CargandoTabla from "./cargando"
import { useParams } from "react-router-dom"
import CamposPersonalizadosRecurso from "../../subComponents/campos_personalizados/listado_recurso"
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main"
import SelectorGeneral from "../General/selector"
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps"
import { AiFillMinusCircle, AiOutlineMail } from "react-icons/ai"
import { GrAdd, GrDocumentText } from "react-icons/gr"
import { codigos_area } from "../../lib/internacional"
import SelectorClientes from "../Clientes/selector"

const DetallesProducto = (props) => {
    const {
        id
    } = useParams()
    const [ loading, setLoading ] = useState(false)
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ loadingDefinicion, setLoadingDefinicion ] = useState(false)
    const [ loadingImagen, setLoadingImagen ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ usuario, setUsuario ] = useState(false)
    const dispatch = useDispatch()
    const [ condicion_sub_categoria, setCondicionSubCategoria ] = useState({ tipo: "cliente" })
    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)

    const requeridos = [
        { value: 'descripcion', label: 'Descripción' }
      ];
    const module = 'products'

    const handleChangeSelectProducto = (e,name) => {
        usuario[name] = e ? e : ''
        return setUsuario({...{}, ...usuario})
    }

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/${module}?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res){
                setUsuario(res)
                if(res.detalles_conductor){
                    if(typeof res.detalles_conductor === "object"){
                        if(res._id) setConductor(res.detalles_conductor)
                    }
                }
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }
    
    const eliminarRecurso = async () => {
        setLoadingMaster(true)
        return fetch(`${urlapi}/${module}?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                return window.location = rutas.products.slug
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const handleChangeSelectSimple = (e,name) => {
        usuario[name] = e
        return setUsuario({...{}, ...usuario})
    }
    const handleChangeVehiculoCustom = (e,name) => {
        console.log({e,name})
        usuario[name] = e._id
        if(name === "id_tipo") usuario.tipo = e.tipo
        if(name === "marca") usuario.modelo = ""
        console.log(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const seleccionarConductor = (data) => {
            usuario.id_usuario = data._id
            console.log(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const handleChangeSelect = (e,name) => {
        console.log(e)
        usuario[name] = e
        return setUsuario({...{}, ...usuario})
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if(name === "rut"){
            usuario[name] = limpiarStringLetrasNumeros(value)
        } else if(name === "movil"){
            usuario[name] = limpiarStringNumeros(value)
        } else {
            usuario[name] = value
        }
        return setUsuario({...{}, ...usuario})
    }

    const uploadButton = (
        <div>
          {loadingImagen ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Subir foto
          </div>
        </div>
      );

    const handleChangeUsuarioSelect = (name, value) => {
        usuario[name] = value
        return setUsuario(usuario)
    }

    const crearConductorNuevo = async () => {
        
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
        usuario.autorizado = "si"

        if(usuario.emails_adicionales) if(usuario.emails_adicionales.length > 0){
            const invalidos = usuario.emails_adicionales.filter(e => !e.valid || !e.email)
            if(invalidos.length > 0) return messageApi.error(`Emails inválidos: ${invalidos.map(ma => ma.email ? ma.email : "Email vacío").join(', ')}`)  
        }
        
        if(usuario.moviles_adicionales) if(usuario.moviles_adicionales.length > 0){
            const invalidos = usuario.moviles_adicionales.filter(e => !e.pais_codigo ||!e.movil)
            if(invalidos.length > 0) return messageApi.error(`Números de teléfono inválidos: ${invalidos.map(ma => {
                const codigo = ma.pais_codigo ? ma.pais_codigo : "Código area vacío"
                const numero = ma.movil ? ma.movil : "Número vacío"
                return `${codigo} ${numero}`
            }).join(', ')}`)  
        }

        setLoading(true)
        const url = `${urlapi}/${module}`
        return fetch(url, {
            method: "PUT",
            body: JSON.stringify(usuario),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                return setTimeout(() => {
                    return window.location = `${rutas.products.slug}/${res._id}`
                }, 1000);
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    const cambiarVehiculoConductor = async () => {
        setLoadingDefinicion(true)
        return fetch(`${urlapi}/vehiculos/definir-seleccionado`, {
            method: 'POST',
            body: JSON.stringify({
              id: usuario._id
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer: ${session.tokenSession}`,
              'country': pais,
              'lang': idioma
            },
          })
            .then((res) => {
              if (res.status === 401) return dispatch(cerrarSesion());
              return res.json();
            })
            .then((res) => {
              if (!res) {
                messageApi.error('Sin datos');
              } else if (res.errorMessage) {
                messageApi.error(res.errorMessage);
              } else if (res._id) {
                usuario.selected = true
                messageApi.success("Realizado exitosamente")
                setUsuario(prev => ({...{}, ...usuario}));
              }
              return setLoadingDefinicion(false);
            })
            .catch((error) => {
              messageApi.error('Error al consultar la información, intente nuevamente');
              return setLoadingDefinicion(false);
            })
      }

    const handleChangeArchivo = async (e) => {

        const archivo = e.file.originFileObj
        setLoadingImagen(true)
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            messageApi.error('No pudimos cargar tu imagen')
            return setLoadingImagen(false)
        }

        usuario.imagen_perfil = data.location
        setUsuario({...{}, ...usuario})
        return setLoadingImagen(false)

        })
        .catch(err => {
            console.log(err)
            setLoadingImagen(false)
            return messageApi.error('No pudimos cargar tu imagen')
        })
    }

    const handleChangeCat = (e) => {
        usuario.id_cargo = e._id
        setUsuario(usuario)
        setCondicionSubCategoria(prev => ({...prev, id_cargo: e._id }))
    }

    const handleChangeSubCat = (e) => {
        usuario.id_sub_categoria = e._id
        setUsuario({...{}, ...usuario})
    }

    const handleChangeMovil = (e, pos, name) => {
        let value = e
        if(typeof e === "object") if(e.target) value = e.target.value
        usuario.moviles_adicionales[pos][name] = value
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const removerMovil = (i) => {
        usuario.moviles_adicionales.splice(i,1)
        return setUsuario(prev => ({...{}, ...usuario})) 
    }
    
    const anadirMovilAdicional = () => {
        if(!usuario.moviles_adicionales) usuario.moviles_adicionales = []
        usuario.moviles_adicionales.unshift({
            pais_codigo: '',
            movil: ''
        })
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const anadirEmailAdicional = () => {
        if(!usuario.emails_adicionales) usuario.emails_adicionales = []
        usuario.emails_adicionales.unshift({
            email: ''
        })
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const removerEmail = (i) => {
        usuario.emails_adicionales.splice(i,1)
        return setUsuario(prev => ({...{}, ...usuario})) 
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        usuario.emails_adicionales[pos][name] = value
        usuario.emails_adicionales[pos].valid = validateEmail(value)
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const mostrarEmailsAdicionales = () => {
        if(!usuario.emails_adicionales) return false
        return <div>
            {
                usuario.emails_adicionales.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-3">
                        <Card size="small" title="Email" className="mb-3" extra={<Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>} >
                                <Form.Item label="" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el email" prefix={<AiOutlineMail />} className='mb-0' status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} />
                                </Form.Item>
                        </Card>
                    </div>
                })
            }
        </div>
    }

    const mostrarMovilesAdicionales = () => {

        const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

        if(!usuario.moviles_adicionales) return false
        return <div>
            {
                usuario.moviles_adicionales.map((field,i) => {
                    return <div key={`movil-${i}`} className="mt-3">
                    <Card size="small" title="Móvil" className="mb-3" extra={<Button type="ghost" onClick={() => removerMovil(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />}>REMOVER</Button>} >
                        <Row gutter={15}>
                            <Col md={8}>
                                <Form.Item label="Código de área" required={true} className="mb-0">
                                <Select style={{ width: "100%" }} showSearch placeholder="Código de area" name="pais_codigo" value={field.pais_codigo} onChange={(e) => handleChangeMovil(e,i,"pais_codigo")} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                                </Form.Item>
                            </Col>
                            <Col md={16}>
                                <Form.Item label="Número" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el número telefónico" className='form-control mb-3' name="movil" value={field.movil} onChange={(e) => handleChangeMovil(e,i,"movil")} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    </div>
                })
            }
        </div>
    }

    const handleChangeCustom = (value,name) => {
        console.log(value)
        return setUsuario(prev => {
            let actual = {...prev}
            actual[name] = value
            return actual
        })
    }

    const render = () => {
        if(loadingMaster) return <Card><CargandoTabla /></Card>
        if(!usuario) return <Card><Title>No hay suficientes datos para mostrar esta pantalla</Title> </Card>

        return <div>
            <Card className="mb-3">
            <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.products.slug,
                    title: <span>Productos</span>,
                },
                {
                    title: 'Detalles de producto',
                },
                ]}
            />
            <Title className="mb-2 mt-0">Detalles de producto</Title>
            <Popconfirm
                    title="Eliminar definitivamente"
                    description="¿Estás seguro que deseas eliminar este recurso? Esta opción no se puede deshacer"
                    onConfirm={() => eliminarRecurso()}
                    onCancel={() => false}
                    okText="SI"
                    cancelText="NO"
                  >
                  <Button size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
            </Card>
            <Layout style={{ padding: 20 }}>
            <Row gutter={20}>
                <Col md={12}>
                        <Card size="small">
            <Form layout="vertical">
            <Row gutter={10}>
            <Col md={24}>
                 <SelectorClientes titulo="Cliente" value={usuario.id_cliente} onChange={(data) => handleChangeCustom(data,'id_cliente') } condicion={{}} />
            </Col>
            <Col md={12}>

                    <Form.Item label="Descripción" required={siEsRequeridoEnEsquema('descripcion',requeridos)} className="mb-0">
                    <Input className=' mb-3' name="descripcion"  value={usuario.descripcion} onChange={handleChange} />
                    </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="SKU" required={siEsRequeridoEnEsquema('sku',requeridos)} >
                        <Input mb={3}   value={usuario.sku} variant="filled" name="sku" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Cantidad" required={siEsRequeridoEnEsquema('cantidad',requeridos)} >
                        <Input mb={3}   value={usuario.cantidad} variant="filled" type="number" name="cantidad" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                        <Form.Item label="Unidad de medida de longitud" required={siEsRequeridoEnEsquema('unidad_medida',requeridos)} >
                            <Select value={usuario.unidad_medida} options={unidades_medida.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelectProducto(e,"unidad_medida")} />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Ancho" required={siEsRequeridoEnEsquema('ancho',requeridos)} >
                        <Input addonBefore={usuario.unidad_medida} mb={3}   value={usuario.ancho} variant="filled" type="number" name="ancho" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Alto" required={siEsRequeridoEnEsquema('alto',requeridos)} >
                        <Input addonBefore={usuario.unidad_medida} mb={3}  value={usuario.alto}  variant="filled" type="number" name="alto" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Largo" required={siEsRequeridoEnEsquema('largo',requeridos)} >
                        <Input addonBefore={usuario.unidad_medida} mb={3}   value={usuario.largo} variant="filled" type="number" name="largo" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Unidad de medida de peso" required={siEsRequeridoEnEsquema('unidad_medida_peso',requeridos)} >
                            <Select value={usuario.unidad_medida_peso} options={unidades_medida_peso.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelectProducto(e,"unidad_medida_peso")} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Peso" required={siEsRequeridoEnEsquema('peso',requeridos)} >
                        <Input mb={3} addonBefore={usuario.unidad_medida_peso} variant="filled" type="number" value={usuario.peso}  name="peso" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                    <SelectorGeneral module="categorias" value={usuario.id_embalaje} titulo="Tipo de embalaje" condicion_default={{ tipo: "producto-embalaje" }} onChange={(e) => handleChangeSelectProducto(e?._id,'id_embalaje')} />
                    </Col>
                
            </Row>     
               
            <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => crearConductorNuevo()} ><SaveOutlined /> GUARDAR CAMBIOS</Button>
            </Form>
            </Card>

                </Col>
                <Col md={12}>
                    <Card size="small">
                    <Tabs defaultActiveKey="1" items={[
                        {
                            key: "1",
                            label: <div size="small" style={{ textAlign: "center" }}><GrDocumentText style={{ fontSize: 20 }} /> <Title level={4} className="m-0">MÁS INFORMACIÓN</Title></div>,
                            children: <CamposPersonalizadosRecurso id_target={id} tipo="producto" />
                        }
                    ]} />
                    </Card>
                </Col>
            </Row>
            </Layout>
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default DetallesProducto