import { Button, Col, Divider, Row } from "antd"
import Paragraph from "antd/es/typography/Paragraph"
import Title from "antd/es/typography/Title"
import { useState } from "react"
import { BiLike } from 'react-icons/bi'
import { FiFileText } from 'react-icons/fi'

const ExcelHelper = (props) => {
    const [ show, setShow ] = useState(false)

    const mostrarInfo = () => {
        if(!show) return false 
        return <div>
            <Divider />
        <Row gutter={20} className="mb-3">
            <Col md={6}>
                <BiLike style={{ fontSize: 20 }} className="mb-0" />
                <Title className="mb-0 mt-0" level={4}>Modelo</Title>
                <Paragraph style={{ fontSize: 11, textAlign: "justify" }} className="mb-0 mt-0">Descargar el archivo de Excel modelo: Para facilitar el proceso de carga, te recomendamos descargar nuestro archivo de Excel modelo. Puedes obtenerlo haciendo clic en el enlace proporcionado en nuestra plataforma. Este archivo modelo contiene el formato y las columnas requeridas para una carga de datos correcta.</Paragraph>
            </Col>
            <Col md={6}>
                <BiLike style={{ fontSize: 20 }} className="mb-0" />
                <Title className="mb-0 mt-0" level={4}>Limpieza</Title>
                <Paragraph style={{ fontSize: 11, textAlign: "justify" }} className="mb-0 mt-0">Limpieza de los campos: Antes de comenzar a completar el archivo de Excel con tus datos, asegúrate de limpiar los campos. Elimina cualquier formato condicional, fórmulas o macros que puedan afectar la carga de datos. Los campos deben contener solo la información que deseas cargar en el sistema.</Paragraph>
            </Col>
            <Col md={6}>
                <BiLike style={{ fontSize: 20 }} className="mb-0" />
                <Title className="mb-0 mt-0" level={4}>Convertir en texto</Title>
                <Paragraph style={{ fontSize: 11, textAlign: "justify" }} className="mb-0 mt-0">Convertir campos en texto: Es importante convertir todos los campos en formato de texto antes de ingresar los datos. Esto evitará problemas de formato o interpretación incorrecta de los datos al cargarlos. Puedes hacerlo seleccionando las columnas y utilizando la opción "Formato de celdas" en Excel para establecer el formato como "Texto</Paragraph>
            </Col>
            <Col md={6}>
                <BiLike style={{ fontSize: 20 }} className="mb-0" />
                <Title className="mb-0 mt-0" level={4}>Asignación de columnas</Title>
                <Paragraph style={{ fontSize: 11, textAlign: "justify" }} className="mb-0 mt-0">Asignación de columnas: Si bien recomendamos utilizar el archivo de Excel modelo, entendemos que es posible que desees cargar datos de un formato diferente. En ese caso, asegúrate de asignar correctamente las columnas antes de cargar los datos. Nuestra plataforma te proporcionará una interfaz intuitiva para asignar las columnas del archivo a los campos correspondientes en nuestro sistema. Esto garantizará que los datos se carguen en los lugares correctos</Paragraph>
            </Col>
        </Row>
        </div>
    }

    return <div>
        <Title level={3} className="mb-0 mt-0">Carga excel</Title>
        <Button size="small" className="mb-3" onClick={() => setShow( show ? false : true)} ><FiFileText style={{ marginRight: 5 }} /> VER INDICACIONES</Button>
        {mostrarInfo()}
    </div>
}

export default ExcelHelper