import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { mapbox_token } from '../../../../lib/backend/data';
import mapboxGlDraw from '@mapbox/mapbox-gl-draw';
import { obtenerCentroMapaPorPais } from '../../../../lib/helpers/data/internationa';
import { useSelector } from 'react-redux';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import Paragraph from 'antd/es/typography/Paragraph';
import Map, { Layer, Marker, Source } from 'react-map-gl';
import polyline from '@mapbox/polyline';
import * as turf from '@turf/turf';
import { recuperarCoordenadas } from '../../../../lib/helpers/geo';
import { Button } from 'antd';

mapboxgl.accessToken = mapbox_token

const MapaDibujoPoligono = (props) => {
  const {
    default_geojson,
    height,
    redzone,
    resize,
    loading,
    geometries
  } = props
  const pais = useSelector(state => state.pais)
  const initialViewport = obtenerCentroMapaPorPais(pais)
  const [geometrias, setGeometrias] = useState(geometries ? geometries : []);
  const [viewport, setViewport] = useState(initialViewport);
  const controls = []
  const initialViewState = obtenerCentroMapaPorPais(pais)
  const [ coleccion, setColeccion ] = useState( default_geojson ? default_geojson :{
    type: 'FeatureCollection',
    features: []
  })
  const [viewState, setViewState] = useState(initialViewState);
    const mapRef = useRef(null);
    const drawRef = useRef(null);

    const handleGeocoderResult = (event) => {
      if (event.result && event.result.geometry && event.result.geometry.coordinates) {
        const [lng, lat] = event.result.geometry.coordinates;
        setViewport({ ...viewport, longitude: lng, latitude: lat, zoom: 12 });
      }
    };
  
    const ajustarMapaAGeocercas = (datos_procesar) => {

      let data = datos_procesar ? datos_procesar : coleccion.features
      const instancia_geocercas = JSON.parse( JSON.stringify( data ))
      const coordinates = instancia_geocercas.reduce((acc, geofence) => {
        const geofenceCoords = recuperarCoordenadas(geofence.geometry.coordinates)
        return [...acc, ...geofenceCoords];
      }, []);
      
      console.log({ coordenadas_finales: coordinates })
      if (coordinates.length > 0) {
        
          const points = coordinates.map(marker => turf.point([marker[0], marker[1]]));
          const collection = turf.featureCollection(points);
          const bounds = turf.bbox(collection);

          const newViewport = {
              ...viewState,
              latitude: (bounds[1] + bounds[3]) / 2,
              longitude: (bounds[0] + bounds[2]) / 2
          };

          const options = {
            padding: 120 // Ajusta el valor de padding según tus necesidades
          };

          setViewState(newViewport);
          mapRef.current?.fitBounds(bounds, options);
    }
    }


    useEffect(() => {
      console.log({ resize })
      if(mapRef) if(mapRef.current) mapRef.current.resize()
      ajustarMapaAGeocercas()
    }, [ resize ] )
  
    const handleDraw = () => {
      if (drawRef.current) {
        const data = drawRef.current.getAll(); // Obtener todas las geometrías dibujadas
        if(props.onChange) props.onChange(data)
      }
    }

    const handleMapLoad = () => {

      const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          marker: false, // Desactivar el marcador de ubicación seleccionada
          placeholder: "Busca una dirección",
          countries: pais
        });
        
        // Manejar el evento de resultado de búsqueda
        geocoder.on('result', handleGeocoderResult);
  
        let instancia_creacion = {
          displayControlsDefault: false,
          defaultMode: 'draw_polygon',
          controls: {
            polygon: true, // Habilitar dibujo de polígonos
            trash: true, // Habilitar la herramienta de borrado
          }
        }
        if(default_geojson) instancia_creacion.data = default_geojson
        const dibujo = new mapboxGlDraw(instancia_creacion);
  
        drawRef.current = dibujo; // Guardar la referencia del objeto draw en el ref

        
      if(!controls.includes('geocode')) mapRef.current?.addControl(geocoder, "top-right"); // Agregar el controlador de búsqueda en la esquina superior derecha
      controls.push('geocode')

      if(!controls.includes('draw')) mapRef.current?.addControl(dibujo, 'top-left'); // Agregar la herramienta de dibujo en la esquina superior izquierda
      controls.push('draw')

      // Asegurarse de que el mapa esté completamente cargado antes de acceder a drawRef.current
      mapRef.current?.on('draw.create', handleDraw); // Evento al crear un polígono
      mapRef.current?.on('draw.update', handleDraw); // Evento al actualizar un polígono
      mapRef.current?.on('draw.delete', handleDraw); // Evento al borrar un polígono
      mapRef.current?.resize();

  }

  const guardarCambios = () => {
    if(props.guardarCambios) props.guardarCambios()
  }
  
    return (<div style={{ height: '100vh', width: '100%' }}>
      {/* <Button loading={loading} className='mb-3' onClick={() => guardarCambios()}>GUARDAR CAMBIOS</Button> */}
      <Map
              ref={mapRef}
              {...viewState}
              onMove={evt => setViewState(evt.viewState)}
              style={{width: "100%", height: height ? height : 500}}
              mapStyle="mapbox://styles/mapbox/streets-v9"
              mapboxAccessToken={mapbox_token}
              onLoad={handleMapLoad}
            >
              {
                  geometrias.map((geometry,ir) => {

                      const decodedCoords = polyline.decode(geometry.geometry)
                      
                      return <Source
                          id={`route-${ir}`}
                          type='geojson'
                          data={{
                              type: 'Feature',
                              properties: {},
                              geometry: {
                                  type: "LineString",
                                  coordinates: decodedCoords.map(co => [co[1], co[0]])
                              }
                            }}
                      >
                          <Layer
                          id={`route-layer-${ir}`}
                          type="line"
                          layout={{
                              'line-join': 'round',
                              'line-cap': 'round',
                            }}                
                          paint={{
                              'line-color': 'black',
                              'line-width': 4,
                              'line-opacity': 0.75
                          }}
                      />
                      </Source>
                  })
              }
                  <Source
                          key={"ajsfkn"}
                          id={"geocerca._id"}
                          type="geojson"
                          data={coleccion}
                          >
                              
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="line"
                                paint={{
                                    'line-width': 4,
                                    'line-color': '#0080ef'
                                  }}                      
                                />
                              }
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="fill"
                                paint={{
                                    'fill-color': 'black',
                                    'fill-opacity': 0.5
                                  }}                      
                                />
                              }
                                  {
                                    redzone === true ? <Layer
                                    id="geocercas-layer"
                                    type="fill"
                                    paint={{
                                        'fill-color': 'red',
                                        'fill-opacity': 0.2
                                      }}                      
                                    /> : false
                                  }
                          </Source>
            </Map>
      </div>);
  };
  

export default MapaDibujoPoligono