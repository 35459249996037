const eventos = [
    { value: "nuevo-pedido", label: "Nueva orden generada" },
    // { value: "pedido-actualizado", label: "Pedido actualizado" },
    { value: "nueva-ruta", label: "Nueva ruta" },
    // { value: "ruta-actualizada", label: "Ruta actualizada" },
    { value: "nuevo-estado", label: "Nuevo evento de trazabilidad" },
]

const eventoToString = (valor) => {
    const i = eventos.findIndex(e => e.value === valor)
    if(i > -1) return eventos[i].label
    return valor
}

module.exports = {
    eventos,
    eventoToString
}