const getLabelForVehicle = (data) => {
    let identificador = ''
    if(data.marca)          identificador = data.marca
    if(data.modelo)         identificador = `${identificador} ${data.modelo}`
    if(data.ano)            identificador = `${identificador} ${data.ano}`
    if(data.patente)        identificador = `${identificador} ${data.patente}`
    return identificador
}

module.exports = {
    getLabelForVehicle
}