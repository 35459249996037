import { useDispatch, useSelector } from "react-redux"
import { useCallback, useEffect, useState } from "react"
import { Avatar, Button, Card, Checkbox, Col, Form, Input, Pagination, Progress, Row, Table, Tooltip, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { calcularPorcentaje, fechaATextoSimple } from "../../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { rutas } from "../../../lib/routes/routes"
import { QuestionCircleOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons"
import data, { limit_page_options, urlapi } from "../../../lib/backend/data"
import { cerrarSesion } from "../../../redux/actions/sesion"
import { debounce } from 'lodash';
import { TEXT_LANG } from "../../../lib/lang/main"
import SortBy from "../../general/sort_by"
import { obtenerLimitesPagina } from "../../../lib/helpers/main"

const ListadoTiposVehiculo = (props)=> {
    const {
        condicion_default,
        tableSize,
        exclude,
        showSelection,
        hideSearch,
        hideHeader,
        typeView
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [filtros, setFiltros] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const trash = props.trash ? props.trash : false
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'
    const module = 'tipos-vehiculo'
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const default_limit_page                = obtenerLimitesPagina(limit_page_options, 'limit_page', interfaz_usuario, module)

    const obtenerDatos = async (page, query, limit)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        setCondicionBusqueda(condicion)
        return fetch(`${urlapi}/vehiculos/tipo/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma,
                limit: limit ? limit : default_limit_page
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
                if(res.filtros) setFiltros(res.filtros)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerDatos(1, false)
    }, [])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerDatos(e, condicion_busqueda)
    }

    const sortBy = (data, type) => {
        if(type === "sort_config"){
            const {
                sort_column,
                sort_order
            } = data
            setPagina(1)
            obtenerDatos(1, null, null, { sort_column, sort_order })

        } else if( type === "limit_pagination" ){
            setPagina(1)
            obtenerDatos(1, null, data.limit_page)            
        }
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div>
            <Row gutter={15}>
                <Col span="auto"><SortBy type={module} onChange={(data, type) => sortBy(data, type)} /></Col>
            </Row>
            <Pagination defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const seleccionarRegistro = data => {
        deseleccionarTodos({ target: false })
        if(props.onSelect) props.onSelect(data)
    }

    const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <b className="hover" onClick={() => seleccionarRegistro([data])}>{data.tipo}</b>
            default:
                return <Link to={`${rutas.drivers.slug}/${data._id}`}>{data.tipo}</Link>
        }
    }

    const filtrarRegistros = (filtro) => {
        if(!filtro.condicion) return false
        if(typeof filtro.condicion !== "object") return false
        obtenerDatos(1, filtro.condicion)
    }

    const header = () => {
        if(hideHeader === true) return false
        return <Row gutter={15}>
            <Col md={3}>
                <Card size="small" className="mb-3">
                    <Paragraph level={3} style={{ fontSize: 12 }} className="m-0">Total de conductores filtrados</Paragraph>
                    <Title className="m-0">{total}</Title>
                </Card>
            </Col>
            {
                filtros.map((filtro,i) => {


                    const porcentaje = calcularPorcentaje(total, filtro.cantidad)
                    return <Col className="hover" md={3} key={`filtro-${i}`} onClick={() => filtrarRegistros(filtro)} >
                    <Card size="small" className="mb-3">
                        <Paragraph level={3} style={{ fontSize: 12 }} className="m-0">{filtro.label}
                        {
                            filtro.help_text ? <Tooltip title={filtro.help_text}>
                           <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                          </Tooltip> : false
                        }
                        </Paragraph>
                        <Row>
                            <Col xs={12}><Title className="m-0">{filtro.cantidad}</Title></Col>
                            <Col xs={12} style={{ textAlign: "right", marginTop:8 }}> <Progress size={35} type="circle" percent={porcentaje} /></Col>
                        </Row>
                    </Card>
                </Col>
                })
            }
        </Row>
    }

    const busquedaAutomaticamente = useCallback(debounce((data) => buscarRegistros(data), 1000), []);

    const handleChangeCampo = (e) => {
        const { value } = e.target
        busquedaAutomaticamente(value)
        return setCampoBusqueda(value);
      };


      const buscarRegistros = (val) => {
        if (!val){
            setCondicionBusqueda({});
            setPagina(1);
            return obtenerDatos(1, {});
        }
        const condicion = { tipo: { $regex: val.toLowerCase(), $options: "i" } }
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerDatos(1, condicion);
      };

      const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setCondicionBusqueda({});
            setPagina(1);
            return obtenerDatos(1, {});
        }
        const condicion = {
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerDatos(1, condicion);
      };
      
      const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit}>
        <Row gutter={15}>
            <Col md={14}>
                <Form.Item label="Buscar">
                    <Input autoFocus defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Tipo de vehículo`} />
                </Form.Item>
            </Col>
            {/* <Col md={4}>
                <Form.Item>
                    <Button onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                </Form.Item>
            </Col> */}
        </Row>
               
                </Form>
      }

      const handleChangeSeleccion = (e) => {
        const { name } = e.target
        const valor = e.target.checked
        const i = conductores.findIndex(ve => ve._id === name )
        conductores[i].seleccionado = valor
        setConductores([...[], ...conductores])
      }

      const deseleccionarTodos = (e) => {
        setConductores([...[], ...conductores.map(veh => { 
            if(exclude){
                if( Array.isArray(exclude) !== false ){
                    if(exclude.includes(veh._id)) return veh
                }
            }
          veh.seleccionado = e.target.checked
          return veh 
        })])
      }

      const cantidadSeleccionados = () => {
        if(exclude) if(Array.isArray(exclude) !== false){
            return <div>
                <Title className="m-0" level={4} >{(exclude.length) + conductores.filter(veh => veh.seleccionado === true).length } Seleccionados</Title>
            </div>
        }
      }

      const accionButtons = () => {
        if(showSelection !== true) return false
        const seleccionados = conductores.filter(veh => veh.seleccionado === true).length
        const enable = seleccionados > 0 ? false : true
        return <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Button disabled={enable} size="small" onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>SELECCIONAR</Button>
            {cantidadSeleccionados()}
        </div>
      }

      const checkForEnabledValue = (id) => {
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const validarChecked = (seleccionado, id) => {
        if(seleccionado === true) return true
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

    const mostrarTabla = () => {
        if(loadingConductores) return <CargandoTabla />
        return <div>
            <Table locale={{ emptyText: TEXT_LANG('empty_text', pais) }} size={tableSize ? tableSize : "middle"} dataSource={conductores} pagination={false} scroll={true} >
                {
                    showSelection !== true ? false : <Column title={<Checkbox onChange={deseleccionarTodos} />} render={(data) => {
                        return <Checkbox disabled={checkForEnabledValue(data._id)} checked={validarChecked(data.seleccionado, data._id)} name={data._id} onChange={handleChangeSeleccion}/>
                    }} />
                }
                <Column title="Título" render={(data) => {
                    return <div>{showByType(typeView, data)}</div>
                }} />
            </Table>
        </div>
    }
    const mostrarRegistros = () => {

        return <div>
            {header()}

            {buscador()}
            {paginacion(default_limit_page, total)}
            {accionButtons()}
            {mostrarTabla()}
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoTiposVehiculo