import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { maps_key, urlapi } from "../../lib/backend/data";
import { PlusCircleFilled } from "@ant-design/icons";
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios";
import AutoComplete from "react-google-autocomplete";
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps";
import { codigos_area } from "../../lib/internacional";
import { filterOption, siEsRequeridoEnEsquema } from "../../lib/helpers/helpers";
import Title from "antd/es/typography/Title";
import { GoDeviceMobile } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import SelectorClientes from "../Clientes/selector";
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main";
import { main_data } from "./data";
import SelectorGlobal from "../../subComponents/general/selector";

const CrearNuevoRegistro = (props) => {
    const {
        default_payload,
        showClientSelector
    } = props
    const [ campo, setCampo ] = useState(default_payload ? default_payload : {})
    const pais = useSelector(state => state.pais)
    const [ showSelectorCliente, setShowSelectorCliente ] = useState(typeof showClientSelector !== "undefined" ? showClientSelector : true)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const module = main_data.module

    const requeridos = [
        { value: 'identificador', label: 'Descripción' }
    ];

    useEffect(() => {
        if(default_payload) if(typeof default_payload === "object") if(default_payload.id_cliente) setShowSelectorCliente(false)
    }, [])
    
    const crearCampo = async () => {
        
        let faltantes = []
        requeridos.map(field => {
            if(!campo[field.value]) faltantes.push(field.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
        const other_data = default_payload ? default_payload : {}
        setLoading(true)
        return fetch(`${urlapi}/${module}`,{
            method:'POST',
            body: JSON.stringify({...campo, ...other_data}),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                setCampo({...{}, ...{}})
                setIsModalOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
        return crearCampo()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            if(name === "tarifa_confirmada"){
                if(!actual.tarifa){
                    actual.tarifa = value
                }
            }
            return actual
        })
    }

    const handleChangeCustom = (value,name) => {
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            return actual
        })
    }


    // const handleChangeSelect = (name, value) => {
    //     return setCampo(prev => {
    //         let actual = {...prev}
    //         actual[name] = value
    //         return actual
    //     })
    // }

    const mostrarSelectorClientes = () => {
        if(!showSelectorCliente) return false

        return <div>
            <SelectorClientes titulo="Cliente" onChange={(data) => handleChangeCustom(data,'id_cliente') } condicion={{}} />
        </div>
    }
    

    const handleChangeCategory = (e, name) => {
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = e?._id
            return actual
        })
    }
    
    return <div>
        <Button className="mb-3" type="primary" size="small" onClick={showModal}><PlusCircleFilled /> CREAR NUEVO</Button>
        <Modal 
            title="Crear nuevo producto" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <CamposObligatoriosLeyenda />
            {mostrarSelectorClientes()}
            <Form layout="vertical">
                <Row gutter={15} >
                <Col md={24}>
                    <Form.Item label="Descripción" required={siEsRequeridoEnEsquema('identificador',requeridos)} className="mb-0">
                    <Input className=' mb-3' name="identificador"  value={campo.identificador} onChange={handleChange} />
                    </Form.Item>
                </Col>
                <Col md={24}>
                    <Form.Item label="Observaciones" required={siEsRequeridoEnEsquema('observaciones',requeridos)} className="mb-0">
                    <Input className=' mb-3' name="observaciones"  value={campo.observaciones} onChange={handleChange} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                        <Form.Item label="Tarifa" required={siEsRequeridoEnEsquema('tarifa',requeridos)} >
                        <Input mb={3}   value={campo.tarifa} variant="filled" type="number" name="tarifa" onChange={handleChange} />
                        </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                        <Form.Item label="Tarifa confirmada" required={siEsRequeridoEnEsquema('tarifa_confirmada',requeridos)} >
                        <Input mb={3}   value={campo.tarifa_confirmada} variant="filled" type="number" name="tarifa_confirmada" onChange={handleChange} />
                        </Form.Item>
                </Col>
                <Col md={12}>
                <SelectorGlobal titulo="Categoría" condicion={{ tipo: main_data.module }} module="categorias" key_label="title" key_value="_id" onChange={(data) => handleChangeCategory(data, 'tipo')} />
                </Col>
                <Col md={12}>
                <SelectorGlobal titulo="Sub Categoría" condicion={{ tipo: main_data.module }} module="sub-categorias" key_label="title" key_value="_id" onChange={(data) => handleChangeCategory(data, 'subtipo')} />
                </Col>
                </Row>
            </Form>
      </Modal>
      {contextHolder}
    </div>
}

export default CrearNuevoRegistro