const obtenerPrefijoMovil = (codigoPais) => {
    const prefijosMoviles = {
      AR: "54",   // Argentina
      AU: "61",   // Australia
      BR: "55",   // Brasil
      CA: "1",    // Canadá
      CL: "56",   // Chile
      CN: "86",   // China
      CO: "57",   // Colombia
      DE: "49",   // Alemania
      ES: "34",   // España
      FR: "33",   // Francia
      GB: "44",   // Reino Unido
      IT: "39",   // Italia
      JP: "81",   // Japón
      MX: "52",   // México
      RU: "7",    // Rusia
      US: "1",    // Estados Unidos
      // Agrega más códigos de país y prefijos móviles aquí
    };
  
    if (codigoPais in prefijosMoviles) {
      return prefijosMoviles[codigoPais];
    } else {
      return false
    }
  };

  const validarLongitudNumeroMovil = (numero, codigoPais) => {
    return true
    const longitudesPorPais = {
      AR: 10,   // Argentina
      AU: 9,    // Australia
      BR: 11,   // Brasil
      CA: 10,   // Canadá
      CL: 9,    // Chile
      CN: 11,   // China
      CO: 10,   // Colombia
      ES: 9,    // España
      FR: 9,    // Francia
      IN: 10,   // India
      IT: 10,   // Italia
      JP: 10,   // Japón
      MX: 10,   // México
      GB: 10,   // Reino Unido
      RU: 10,   // Rusia
      US: 10,   // Estados Unidos
      PE: 9,   // Perú
      // Agrega más códigos de país y longitudes aquí
    };
  
    if (codigoPais in longitudesPorPais) {
      const longitudEsperada = longitudesPorPais[codigoPais];
      return numero.length === longitudEsperada;
    } else {
      return false; // Código de país no encontrado en la lista
    }
  };

  module.exports = {
    obtenerPrefijoMovil,
    validarLongitudNumeroMovil
  }