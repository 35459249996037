import { Col, Row } from "antd"

const SinDatos = (props) => {
    const {
        titulo,
        descripcion
    } = props
    return <div className='p-5'>
            <Row justify="center">
                <Col md={7} className="text-center">
                <h4>{titulo ? titulo : "Sin información"}</h4>
                <p>{descripcion ? descripcion : "No se obtuvieron suficientes datos para mostrar el recurso que intentas visualizar"}</p>
                </Col>
            </Row>
        </div>
}

export default SinDatos