import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Card, Col, Divider, Row, message } from 'antd';
import { useState } from "react";
import ListadoContactos from "./listado";
import HelperModal from "../../subComponents/general/helperModal";
import { cerrarSesion } from "../../redux/actions/sesion";
import { useDispatch, useSelector } from "react-redux";
import { urlapi } from "../../lib/backend/data";
import { modulo } from "./data";

const BusquedaRecursos = (props) => {
    const {
        default_payload,
        showCreateButton,
        titulo,
        display_full,
        condicion,
        showFilters,
        showCustomFieldsData
    } = props
    const [ loading, setLoading ] = useState(false)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion ? condicion : { incluir_flota_externa: true })
    const sesion = useSelector(state => state.miusuario)
    const [ messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()

    const onSelect = (datos,action) => {
        if(!action) return
        const i = componentes_accion.findIndex(it => it.label === action)
        console.log({ i, action })
        if(i > -1){
            const funcion = componentes_accion[i].action
            funcion(datos)
        }
    }

    const mostrarBotonCreacion = () => {
        if(showCreateButton === false) return false
        return <div>
            <Row gutter={15}>
              <Col span="auto"><HelperModal tipo="disponibilidades" style="modal" /></Col>
            </Row>
            <Divider />
        </div>
    }

    const eliminarMultiples = async (viajes)=>{
        setLoading(true)

        const seleccionados = viajes.map(e => e._id)
        return fetch(`${urlapi}/${modulo}/masive`,{
            method:'DELETE',
            body: JSON.stringify({
                ids: seleccionados
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.deletedCount){
                return window.location.reload()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }
    
    const componentes_accion = [
        {
            label: "ELIMINAR",
            action: eliminarMultiples
        }
    ]

    const render = () => {
        return <div>
            { titulo ? <Title className="mb-2 mt-0">{titulo}</Title> : false }
            {mostrarBotonCreacion()}
            <ListadoContactos autoSearch={true} showSelection={true} onSelect={(data,action) => onSelect(data,action)} loadingActions={loading} showFilters={showFilters} componentAction={componentes_accion} showCustomFieldsData={showCustomFieldsData} condicion_default={condicion_busqueda} />
        </div>
    }

    if(display_full === true ){

        const renderizar = <div><Card>{render()}</Card></div>

        return <Structure component={renderizar} />
    } else {
        return render()
    }
}

export default BusquedaRecursos