const opciones_tamano = [
    { value: 'micro', label: 'Micro' },
    { value: 'pequena', label: 'Pequeña' },
    { value: 'mediana', label: 'Mediana' },
    { value: 'grande', label: 'Grande' },
    { value: 'extra_grande', label: 'Extra grande' }
]

module.exports = {
    opciones_tamano
}