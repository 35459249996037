import { Button, Form, Input, Modal, message } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import { PlusCircleFilled } from "@ant-design/icons";

const CrearCampoPersonalizado = (props) => {
    const {
        tipo,
    } = props
    const [ campo, setCampo ] = useState({})
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()

    const crearCampo = async () => {
        if(!tipo) return messageApi.error("Tipo no válido")
        if(!campo.title) return messageApi.error("Escribe un título")
        if(campo.title.length < 3) return messageApi.error("Escribe un título válido")
        campo.tipo = tipo
        setLoading(true)
        return fetch(`${urlapi}/campos-personalizados`,{
            method:'POST',
            body: JSON.stringify(campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                setIsModalOpen(false)
                setCampo({...{}, ...{}})
                if(props.onCreate) props.onCreate()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
        return crearCampo()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }


    return <div>
        <Button className="mb-3" type="dashed" onClick={showModal}><PlusCircleFilled /> CREAR NUEVO</Button>
        <Modal 
            title="Crear nuevo campo personalizado" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <Form layout="vertical">
                <Form.Item label="Nombre">
                    <Input name="title" value={campo.title} onChange={handleChange} />
                </Form.Item>
            </Form>
      </Modal>
      {contextHolder}
    </div>
}

export default CrearCampoPersonalizado