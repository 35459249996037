import { Button, Col, DatePicker, Divider, Form, Input, Modal, Row, Select, Table, Tag, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { maps_key, urlapi } from "../../lib/backend/data";
import { PlusCircleFilled } from "@ant-design/icons";
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios";
import AutoComplete from "react-google-autocomplete";
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps";
import { codigos_area, codigos_pais } from "../../lib/internacional";
import { fechaUTCATexto, filterOption, formatYMD } from "../../lib/helpers/helpers";
import Title from "antd/es/typography/Title";
import { GoDeviceMobile } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import SelectorClientes from "../Clientes/selector";
import { modulo } from "./data";
import dayjs, { locale } from "dayjs";
import { DateTime } from "luxon";
import { FaTruckFront, FaTruckRampBox } from "react-icons/fa6";
import { GiHandTruck } from "react-icons/gi";
import ModalSeleccionVehiculo from "../Vehiculos/modal_seleccion";
import { FaChevronRight, FaRegTrashAlt } from "react-icons/fa";
import SelectorZonaAvanzadoInternacional from "../RadioOperacion/selector_zona_avanzado_internacional";
import HelperModal from "../../subComponents/general/helperModal";
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado";
import Column from "antd/es/table/Column";
import { calcularVolumen, formatoMoneda, stringByStatus } from "../../lib/helpers/main";
import { stringByPalletType } from "../Vehiculos/data";
import { estilo_moving_truck } from "../../lib/estilo_sitio";
import SelectorZona from "../RadioOperacion/selector_zona";
import BuscadorLocalidad from "../RadioOperacion/buscadorLocalidad";
import EmpresaValidadaPlataformasAsociadas from "../General/empresaValidadaPlataformasAsociadas";

const CrearNuevoContacto = (props) => {
    const {
        default_payload
    } = props
    const [ campo, setCampo ] = useState(default_payload ? default_payload : {})
    const pais = useSelector(state => state.pais)
    const [ showSelectorCliente, setShowSelectorCliente ] = useState(true)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [ vehiculos, setVehiculos ] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(false)
    const [ excludesVehicles, setExcludesVehicles ] = useState([])
    const [ isRestricted, setIsRestricted ] = useState(false)
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const module = modulo

    const requeridos = [
        { value: 'titulo', label: 'Título' },
        { value: 'tipo_disponibilidad', label: 'Tipo' },
    ];

    useEffect(() => {
        if(default_payload) if(typeof default_payload === "object") if(default_payload.id_cliente) setShowSelectorCliente(false)
    }, [])
    
    const crearCampo = async () => {
        const instancia_campo = { ...campo }
        let faltantes = []
        requeridos.map(field => {
            if(!instancia_campo[field.value]) faltantes.push(field.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
        if(!instancia_campo.ids_origin) return messageApi.error("Debe seleccionar al menos una ubicación de carga")
        if(instancia_campo.ids_origin.length < 1) return messageApi.error("Debe seleccionar al menos una ubicación de carga")
        if(!instancia_campo.ids_destination) return messageApi.error("Debe seleccionar al menos una ubicación de descarga")
        if(instancia_campo.ids_destination.length < 1) return messageApi.error("Debe seleccionar al menos una ubicación de descarga")
        if(vehiculos.length < 1) return messageApi.error("Debe seleccionar al menos un vehículo")
        instancia_campo.vehiculos = vehiculos

        if(instancia_campo.tipo_disponibilidad === "unica"){
            if(!instancia_campo.fecha_carga_desde) return messageApi.error("Debe seleccionar fecha de carga desde")
            if(!instancia_campo.fecha_carga_hasta) return messageApi.error("Debe seleccionar fecha de carga hasta")
            if(!instancia_campo.fecha_descarga_desde) return messageApi.error("Debe seleccionar fecha de descarga desde")
            if(!instancia_campo.fecha_descarga_hasta) return messageApi.error("Debe seleccionar fecha de descarga hasta")

            if(instancia_campo.fecha_carga_desde){
                if(instancia_campo.hora_carga_desde){
                    const horario = instancia_campo.hora_carga_desde.split(":")
                    const desde = DateTime.fromISO(instancia_campo.fecha_carga_desde).set({hour: parseInt(horario[0]), minute: parseInt(horario[1]) }).toUTC().toISO()
                    instancia_campo.fecha_carga_desde = desde
                } else {
                    instancia_campo.fecha_carga_desde = DateTime.fromISO(instancia_campo.fecha_carga_desde).startOf('day').toUTC().toISO()
                }
            }

            if(instancia_campo.fecha_carga_hasta){
                if(instancia_campo.hora_carga_hasta){
                    const horario = instancia_campo.hora_carga_hasta.split(":")
                    const hasta = DateTime.fromISO(instancia_campo.fecha_carga_hasta).set({hour: parseInt(horario[0]), minute: parseInt(horario[1]) }).toUTC().toISO()
                    instancia_campo.fecha_carga_hasta = hasta
                } else {
                    instancia_campo.fecha_carga_hasta = DateTime.fromISO(instancia_campo.fecha_carga_hasta).endOf('day').toUTC().toISO()
                }
            
            }

            if(instancia_campo.fecha_descarga_desde){
                if(instancia_campo.hora_descarga_desde){
                    const horario = instancia_campo.hora_descarga_desde.split(":")
                    const desde = DateTime.fromISO(instancia_campo.fecha_descarga_desde).set({hour: parseInt(horario[0]), minute: parseInt(horario[1]) }).toUTC().toISO()
                    instancia_campo.fecha_descarga_desde = desde
                } else {
                    instancia_campo.fecha_descarga_desde = DateTime.fromISO(instancia_campo.fecha_descarga_desde).startOf('day').toUTC().toISO()
                }
            }

            if(instancia_campo.fecha_descarga_hasta){
                if(instancia_campo.hora_descarga_hasta){
                    const horario = instancia_campo.hora_descarga_hasta.split(":")
                    const hasta = DateTime.fromISO(instancia_campo.fecha_descarga_hasta).set({hour: parseInt(horario[0]), minute: parseInt(horario[1]) }).toUTC().toISO()
                    instancia_campo.fecha_descarga_hasta = hasta
                } else {
                    instancia_campo.fecha_descarga_hasta = DateTime.fromISO(instancia_campo.fecha_descarga_hasta).endOf('day').toUTC().toISO()
                }
            
            }

        }

        setLoading(true)
        return fetch(`${urlapi}/${module}`,{
            method:'POST',
            body: JSON.stringify(instancia_campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                setIsModalOpen(false)
                setCampo({...{}, ...{}})
                setVehiculos([...[], ...[]])
                if(props.onCreate) props.onCreate()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const obtenerVehiculosOcupados = async () => {
        return fetch(`${urlapi}/${module}/availability`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
            } else if(res.ocupated){
                setExcludesVehicles(res.ocupated)
            }
            return setLoading(false)
        })
        .catch(error => setLoading(false))

    }

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
        return crearCampo()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = { ...prev }
            actual[name] = value
            return { ...actual, ...{} }
        })
    }

    const handleChangeCustom = (value,name) => {
        campo[name] = value
        console.log(campo)
        return setCampo(campo)
    }


    const handleChangeSelect = (name, value) => {
        return setCampo(prev => {
            let actual = { ...prev }
            actual[name] = value
            if( name === "tipo_disponibilidad") if(value === "recurrente") {
                delete actual.fecha_carga_desde
                delete actual.fecha_carga_hasta
                delete actual.fecha_descarga_desde
                delete actual.fecha_descarga_hasta
                delete actual.hora_carga_desde
                delete actual.hora_carga_hasta
                delete actual.hora_descarga_desde
                delete actual.hora_descarga_hasta
            }
            return { ...actual, ...{} }
        })
    }

    const mostrarSelectorClientes = () => {
        if(!showSelectorCliente) return false

        return <div>
            <SelectorClientes key={key_input_filter} titulo="Cliente" onChange={(data) => handleChangeCustom(data,'id_cliente') } condicion={{}} />
        </div>
    }

    const remover = (i) => {
        setVehiculos(prev => {
            let actual = [...prev]
            actual.splice(i,1)
            return actual
        })
    }

    const mostrarVehiculos = () => {
        const key_esquema               = 'VEHICULOS'
        const key_interfaz              = 'tabla_vehiculos'

        const acciones_botones = <Column title="Acciones" fixed="right" ellipsis render={(data, e, iv) => {
            return <div>
                          <Button onClick={() => remover(iv)} size="small" className="m-0" type="dashed" danger>ELIMINAR</Button>
          </div>
        }} />


        const component_patente = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="patente" />} render={(veh) => {
            const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}
            return <div>
                <b className="hover" style={{ textTransform: "uppercase" }}>{data.patente}</b>
            </div>
            }} />

        const component_conductor = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="conductor" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div style={{ textTransform: "uppercase" }} >{data.conductor?.nombres} {data.conductor?.apellido_p} {data.conductor?.apellido_m}</div>
        }} />

        const component_modelo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="modelo" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{data.detalle_modelo ? data.detalle_modelo.toUpperCase() : "SIN INFORMACIÓN"}</div>
        }} />

        const component_marca = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="marca" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{data.detalle_marca ? data.detalle_marca.toUpperCase() : "SIN INFORMACIÓN"}</div>
        }} />

        const component_ano = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="ano" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{data.ano}</div>
        }} />

        const component_tipo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tipo" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{data.tipo ? data.tipo.toUpperCase() : "SIN INFORMACIÓN"}</div>
        }} />
        
        const component_fecha = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="createdAt" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{fechaUTCATexto(data.createdAt)}</div>
        }} />
        
        const component_tipo_motor = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tipo_motor" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{data.detalle_tipo_motor ? data.detalle_tipo_motor : "SIN INFORMACIÓN"}</div>
        }} />
        const component_volumen = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="volumen" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            const vol = calcularVolumen(data, data.unidad_medida)
            return <div><Tag><b style={{ fontWeight: "bold" }}>{vol.unidadVolumen}</b> {vol.volumen.toFixed(2)}</Tag></div>
        }} />
        const component_peso = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="peso" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div><Tag><b style={{ fontWeight: "bold" }}>{data.unidad_medida_peso}</b> {formatoMoneda(data.peso)}</Tag> </div>
        }} />

        const component_capacidad = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="capacidad" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>
                { data.ancho ? <Tag>ANCHO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.ancho}</Tag> : false }
                { data.alto ? <Tag>ALTO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.alto}</Tag> : false }
                { data.largo ? <Tag>LARGO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.largo}</Tag> : false }
                </div>
        }} />
        const component_creador = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="creador" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div style={{ textTransform: "uppercase" }}>{data.creador ? data.creador?.nombres : "Sin información"}</div>
        }} />
        const component_status = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="status" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{stringByStatus(data.status)}</div>
        }} />
        
        const component_tipo_pallet = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tipo_pallet" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{stringByPalletType(data.tipo_pallet)}</div>
        }} />
        const component_cantidad_pallet = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="cantidad_pallet" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{data.cantidad_pallet}</div>
        }} />
        return <div>
            <Divider className="mt-3 mb-3" />

            <Table dataSource={vehiculos} pagination={false} scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="vehiculos" /> }}>
            {component_patente}
            {component_conductor}
            {component_modelo}
            {component_marca}
            {component_ano}
            {component_tipo}
            {component_tipo_pallet}
            {component_cantidad_pallet}
            {component_fecha}
            {component_tipo_motor}
            {component_volumen}
            {component_peso}
            {component_capacidad}
            {component_creador}
            {component_status}
            {acciones_botones}
            </Table>
            {/* {
                vehiculos.map((veh,iv) => {
                    return <div key={`veh-${iv}`}>
                        <Row gutter={15}>
                            <Col md={18}><Title level={5} className="mt-0 mb-2"><FaTruckFront style={{ verticalAlign: "middle" }} /> {veh.identificador}</Title></Col>
                            <Col md={6}><Button size="small" icon={<FaRegTrashAlt />} onClick={() => remover(iv)} >REMOVER</Button> </Col>
                        </Row>
                        { vehiculos.length > 1 ? <Divider className="mt-3 mb-3" /> : false}
                    </div>
                })
            } */}
        </div>
    }


    const handleChangeDate = (e,name, final) => {
        const valor_input = e ? e['$d'] : null
        let valor_utc = valor_input ? DateTime.fromJSDate(e['$d']) : null
        
        if(valor_utc) if(final) valor_utc = valor_utc.endOf('day')
        
        return setCampo(prev => {
            let actual = { ...prev }
            actual[name] = valor_utc ? valor_utc.toUTC().toISO() : null
            return actual
        })
    }

    const mostrarMantenedorFechas = () => {
        if(campo.tipo_disponibilidad === "unica"){
            return <Col md={24}><Row gutter={15}>
                <Col md={12}>
                        <Title level={3} className="mt-0 mb-2"><GiHandTruck style={{ verticalAlign: "middle" }} /> Carga</Title>
                            <Row gutter={15}>
                                <Col xs={24}>
                                    <Row gutter={15}>
                                        <Col md={12}>
                                        <Form.Item label="Desde" className="mb-3">
                                        {/* <DatePicker placeholder="Seleccionar fecha" type style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={campo.fecha_carga_desde ? dayjs(formatYMD(campo.fecha_carga_desde), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(e,'fecha_carga_desde')} /> */}
                                        <Input type="date" name="fecha_carga_desde" value={campo.fecha_carga_desde} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                        <Col md={12}>
                                        <Form.Item label="Hora" className="mb-3">
                                        <Input type="time" style={{ width: "100%"}} name="hora_carga_desde" value={campo.hora_carga_desde} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={15}>
                                        <Col md={12}>
                                        <Form.Item label="Hasta" className="mb-3">
                                        <Input type="date" name="fecha_carga_hasta" value={campo.fecha_carga_hasta} onChange={handleChange} />
                                        {/* <DatePicker placeholder="Seleccionar fecha" type style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={campo.fecha_carga_hasta ? dayjs(formatYMD(campo.fecha_carga_hasta), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(e,'fecha_carga_hasta')} /> */}
                                        </Form.Item>
                                        </Col>
                                        <Col md={12}>
                                        <Form.Item label="Hora" className="mb-3">
                                        <Input type="time" style={{ width: "100%"}} name="hora_carga_hasta" value={campo.hora_carga_hasta} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                    </Row>
                                   
                                    
                                </Col>
                            </Row>
                    </Col>
                    <Col md={12}>
                        <Title level={3} className="mt-0 mb-2"><FaTruckRampBox style={{ verticalAlign: "middle" }} /> Descarga</Title>
                            <Row gutter={15}>
                                <Col xs={24}>
                                    <Row gutter={15}>
                                    <Col md={12}>
                                    <Form.Item label="Desde" className="mb-3">
                                    {/* <DatePicker placeholder="Seleccionar fecha" type style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={campo.fecha_carga_desde ? dayjs(formatYMD(campo.fecha_carga_desde), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(e,'fecha_carga_desde')} /> */}
                                    <Input type="date" name="fecha_descarga_desde" value={campo.fecha_descarga_desde} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                    <Form.Item label="Hora" className="mb-3">
                                    <Input type="time" style={{ width: "100%"}} name="hora_descarga_desde" value={campo.hora_descarga_desde} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                    </Row>
                                </Col>
                                <Col xs={24}>
                                <Row gutter={15}>
                                    <Col md={12}>
                                    <Form.Item label="Hasta" className="mb-3">
                                    {/* <DatePicker placeholder="Seleccionar fecha" type style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={campo.fecha_carga_hasta ? dayjs(formatYMD(campo.fecha_carga_hasta), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(e,'fecha_carga_hasta')} /> */}
                                    <Input type="date" name="fecha_descarga_hasta" value={campo.fecha_descarga_hasta} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                    <Form.Item label="Hora" className="mb-3">
                                    <Input type="time" style={{ width: "100%"}} name="hora_descarga_hasta" value={campo.hora_descarga_hasta} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                </Row>
                                    
                                    
                                </Col>
                            </Row>
                    </Col>
            </Row>
            </Col>
        } else if (campo.tipo_disponibilidad === "recurrente"){
            return <Col md={24}>
                <Form.Item label="Vencimiento" className="mb-2">
                <Input type="date" name="vencimiento" value={campo.vencimiento} onChange={handleChange} />
                </Form.Item>
                </Col>
        }
    }

    const agregarUbicacion = (data, key) => {
        if(!data) return
        setCampo(prev => {
            let actual = {...prev}
            if(!actual[key]) actual[key] = []
            actual[key] = data
            return actual
        })
    }

    const removerZona = (key, i) => {
        setCampo(prev => {
            let actual = {...prev}
            actual[key].splice(i,1)
            return actual
        })
    }

    const mostrarUbicaciones = (key) => {
        if(!campo[key]) return false
        if(campo[key].length < 1) return false
        return <div>
            {
                campo[key].map((ubi,i) => <Title level={4} key={`ubi-${i}`} className="mt-0 mb-2"><FaChevronRight style={{ verticalAlign: "middle" }} /> {ubi.titulo} <Button onClick={() => removerZona(key, i)}>ELIMINAR</Button></Title>)
            }
        </div>
    }

    const restrict = () => setIsRestricted(true)

    const render = () => {
        if(isRestricted) return false
        return <div>
            <CamposObligatoriosLeyenda />
            {/* {mostrarSelectorClientes()} */}
            <Form layout="vertical">
                <Row gutter={15} >
                    {/* <Col md={24}>
                        <Form.Item label="Estado" className="mb-2">
                        <Select options={[{ value: 'active', label: "Activo" },{ value: "inactive", label: "Inactivo" }]} showSearch filterOption={filterOption} defaultValue={!campo.status ? "" : campo.status} name="status" onChange={(e) => handleChangeSelect("status", e)} />
                        </Form.Item>
                    </Col> */}
                    <Col md={12}>
                        <Form.Item label="Título" required className="mb-2">
                        <Input name="titulo" value={campo.titulo} onChange={handleChange} />
                        </Form.Item>
                    </Col>               
                    <Col md={12}>
                        <Form.Item label="Tipo" required className="mb-2">
                        <Select options={[{ value: 'recurrente', label: "Recurrente" },{ value: "unica", label: "Única" }]} showSearch filterOption={filterOption} defaultValue={!campo.tipo_disponibilidad ? "" : campo.tipo_disponibilidad} name="tipo_disponibilidad" onChange={(e) => handleChangeSelect("tipo_disponibilidad", e)} />
                        </Form.Item>
                    </Col>                    
                    <Col md={16}>
                        <Form.Item label="Días de anticipación para reservar" required className="mb-2">
                        <Input type="number" name="anticipacion" value={campo.anticipacion} onChange={handleChange} />
                        </Form.Item>
                    </Col>     
                    
                    <Col md={4}>
                        <Form.Item label="Precio referencial" required className="mb-2">
                        <Input type="number" name="precio_referencial" value={campo.precio_referencial} onChange={handleChange} />
                        </Form.Item>
                    </Col>         
                    <Col md={4}>
                        <Form.Item label="Moneda" required className="mb-2">
                        <Select options={[{ value: 'CLP', label: "CLP" },{ value: "USD", label: "USD" }]} showSearch filterOption={filterOption} defaultValue={!campo.moneda ? "" : campo.moneda} name="moneda" onChange={(e) => handleChangeSelect("moneda", e)} />
                        </Form.Item>
                    </Col>               
                    
                    {mostrarMantenedorFechas()}
                    <Col md={24}>
                        <Divider className="mt-3 mb-3" />
                        <Title level={4} className="mt-0 mb-2">Ubicación de carga</Title>
                        <BuscadorLocalidad defaultValue={campo.ids_origin ? campo.ids_origin.map(e => ({ ...e, value: e._id })) : []} onChange={(data) => agregarUbicacion(data,'ids_origin')} />
                        {/* <SelectorZonaAvanzadoInternacional onSelected={(data) => agregarUbicacion(data,'ids_origin')} /> */}
                        {/* {mostrarUbicaciones('ids_origin')} */}
                        <Divider className="mt-3 mb-3" />
                        <Title level={4} className="mt-0 mb-2">Ubicación de descarga</Title>
                        <BuscadorLocalidad defaultValue={campo.ids_destination ? campo.ids_destination.map(e => ({ ...e, value: e._id })) : []} onChange={(data) => agregarUbicacion(data,'ids_destination')} />
                        {/* <SelectorZonaAvanzadoInternacional onSelected={(data) => agregarUbicacion(data,'ids_destination')} /> */}
                        {/* {mostrarUbicaciones('ids_destination')} */}
                    </Col>
                    <Col md={24}>
                        <Divider className="mt-3 mb-3" />
                        <Title level={4} className="mt-0 mb-2">Vehículos asociados { vehiculos.length > 0 ? <Button size="small" icon={<FaRegTrashAlt />} onClick={() => setVehiculos([...[], ...[]])} >REMOVER TODO</Button> : false } </Title>
                            <ModalSeleccionVehiculo isMulti={true} extraColumns={["disponibilidades"]} exclude={excludesVehicles} showSelection={true} hideSeleccionados={true}  onSelect={data => {
                                let valor = data
                                setVehiculos(prev => {
                                    let actual = [ ...prev ]
                                    for( const veh of valor ){
                                        veh.identificador = veh.titulo
                                        const i = actual.findIndex(e => e._id === veh._id)
                                        if( i < 1 ) actual.push({...veh, detalle_vehiculo: {...veh, detalle_modelo: veh.modelo, detalle_marca: veh.marca } }) 
                                        }
                                    return actual
                                })
                            }} />
                        
                        {mostrarVehiculos()}
                    </Col>
                </Row>
            </Form>
        </div>
    }
    
    return <div>
        <Button className="mb-3" type="primary" size="small" onClick={() => {
            obtenerVehiculosOcupados()
            setKeyInputFilter(key_input_filter + 1)
            return showModal()
        }}><PlusCircleFilled /> CREAR NUEVO</Button>
        <Modal 
            width="70%"
            title="Crear nueva disponibilidad " 
            open={isModalOpen} 
            footer={ isRestricted ? [] : undefined}
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <EmpresaValidadaPlataformasAsociadas isRestricted={() => restrict()} />
            {render()}
      </Modal>
      {contextHolder}
    </div>
}

export default CrearNuevoContacto