import 'moment/locale/es';
import { Tag, Card, Collapse, Col, Row, Tooltip, Tabs } from 'antd'; // Cambiamos las importaciones
import { useEffect, useState } from "react";
import { canalEstadosString, getDireccion, getEmail, getNombreDestinatario, getTelefono } from "../../lib/helpers/pedidos/pedidos";
import { fechaATexto, fechaATextoSimple } from "../../lib/helpers/helpers";
import BoxConductor from "../conductores/box_conductor";
// import EmitirEstadoModal from '../estados_carga/emitir_modal';
// import AccionesModal from "./acciones_modal";
import { centerMapCoordinates } from '../../lib/helpers/maps';
// import BoxEditarGeoDatosOrdenIndividual from './box_editar_geodata_individual';
import MapaDetallePedido from '../../components/Mapas/mapa_detalle_pedido';
import { esNumero } from '../../lib/helpers/main';
import SinDatos from '../general/sin_registros';

const { Panel } = Collapse; // Para usar Collapse de Ant Design
const { TabPane } = Tabs; // Para usar Tabs de Ant Design

const DetailFull = (props) => {
    const [pedido, setPedido] = useState(false);
    const [estados, setEstados] = useState([]);
    const [etiquetas, setEtiquetas] = useState([]);
    const [centro_mapa, setCentroMapa] = useState({ lat: -33.458829, lng: -70.654644 });
    const zoom_mapa = 14;
    const [marcadores, setMarcadores] = useState([]);
    const token = props.token ? props.token : false;

    useEffect(() => {
        if (props.pedido.orden) {
            setPedido(props.pedido.orden);
            setEtiquetas(props.pedido.etiquetas);
        }
        let puntos = [];
        if (props.pedido.estados) setEstados(props.pedido.estados);
        if (props.pedido.orden) {
            if (props.pedido.orden.location) {
                if (Array.isArray(props.pedido.orden.location.coordinates)) {
                    if (props.pedido.orden.location.coordinates.length > 0) {
                        puntos.push({
                            lat: props.pedido.orden.location.coordinates[1],
                            lng: props.pedido.orden.location.coordinates[0],
                            ref: props.pedido.orden.pedido,
                            destinatario: getNombreDestinatario(props.pedido.orden)
                        });
                        setMarcadores(puntos);
                    }
                }
            }
        }

        if (puntos.length > 0) {
            const centro = centerMapCoordinates(puntos);
            setCentroMapa(centro);
        }
    }, [props.pedido]);

    const showComponentByTipoFormulario = (campo) => {
        switch (campo.tipo_accion) {
            case 'campo-texto':
                if (!campo.valor) return false;
                return (
                    <div className="mb-3">
                        <h6 className='mb-0'>{campo.titulo}</h6>
                        <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
                    </div>
                );
            case 'selector':
                if (!campo.valor) return false;
                return (
                    <div className="mb-3">
                        <h6 className='mb-0'>{campo.titulo}</h6>
                        <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
                    </div>
                );
            case 'carga-imagenes':
                if (campo.valores.length < 1) return false;
                return (
                    <div className="mb-3">
                        <h6 className='mb-2'>{campo.titulo}</h6>
                        <Row>
                            {campo.valores.length > 0 ? campo.valores.map((foto, i) => {
                                return (
                                    <Col md={3} key={`foto-estado-${i}`}>
                                        <a href={foto.url} target="_blank" className='pr-2'>
                                            <img src={foto.url} style={{ height: 50 }} alt="Estado" />
                                        </a>
                                    </Col>
                                );
                            }) : <Col md={12}><p>Sin información</p></Col>}
                        </Row>
                    </div>
                );
            case 'firma-digital':
                if (!campo.valor) return false;
                const isNumero = esNumero(campo.valor);
                if (isNumero === true) return false;

                return (
                    <div className="mb-3 text-primary">
                        <h6 className='mb-0' style={{ fontWeight: 900 }}>{campo.titulo}</h6>
                        <p className='mb-0'>
                            {campo.valor
                                ? <img src={campo.valor} alt="Firma digital" style={{ maxWidth: '25%' }} />
                                : 'Sin información'}
                        </p>
                    </div>
                );
            default:
                break;
        }
    };

    const actualizarEstadoListado = (estado_entrega, sub_estado_entrega) => {
        if (props.actualizarEstadoListado) return props.actualizarEstadoListado(estado_entrega, sub_estado_entrega);
    };

    const actualizarEstadoActual = (data) => {
        const actualizar_estado = {
            codigo_estado: data.codigo_estado,
            titulo: data.estado,
            color: data.estado_color ? data.estado_color : '#babbbb',
        };

        const actualizar_sub_estado = {
            codigo_estado: data.codigo_subestado,
            titulo: data.subestado,
            color: data.subestado_color ? data.subestado_color : '#babbbb',
        };
        actualizarEstadoListado(actualizar_estado, actualizar_sub_estado);
        return setEstados(prev => [...prev, ...[data]]);
    };

    const onSucessChange = (data) => {
        const { direccion, direccion2 } = data;
        if (!direccion || !direccion2) return false;
        pedido.orden.billing.address_1 = direccion;
        pedido.orden.billing.address_2 = direccion2;
        return setPedido(prev => ({ ...{}, ...pedido }));
    };

    const mostrarMetaDatosSubEstado = (metadatos) => {
        if (!metadatos) return false;
        if (!Array.isArray(metadatos)) return false;
        if (metadatos.length < 1) return false;

        return metadatos.map((meta, i) => {
            return <div key={`meta-${i}`}>{showComponentByTipoFormulario(meta)}</div>;
        });
    };

    if (!pedido) return (
        <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                    <img src="images/pedidos.png" style={{ width: "50%" }} alt="Sin pedidos" />
                    <h4>Selecciona una orden para visualizarla</h4>
                </Col>
            </Row>
        </div>
    );

    let defaultAccordion = false;

    if (estados.length > 0) defaultAccordion = `estado-${estados.length}`;

    return (
        <div>
            <h4 className='mb-0' style={{ fontWeight: "bold" }}>ORDEN {pedido.pedido}</h4>
            <Tag color="dark">CREADO {fechaATexto(pedido.createdAt)}</Tag>
            {/* <AccionesModal token={token} pedido={pedido} /> */}
            <Row className='mt-3'>
                <Col md={4}>
                    <h6 style={{ fontWeight: 'lighter' }}>Itinerario y detalles del conductor</h6>
                    {/* <EmitirEstadoModal token={token} pedido={pedido} onStatusEmited={(data) => actualizarEstadoActual(data)} /> */}
                    <div className="mb-2"></div>

                    <Collapse defaultActiveKey={defaultAccordion} className="mb-3">
                        {estados.map((estado, i) => {

                            let lat = 0;
                            let lng = 0;

                            if (estado.location) {
                                if (Array.isArray(estado.location.coordinates) !== false) {
                                    if (estado.location.coordinates.length > 1) {
                                        lat = estado.location.coordinates[0];
                                        lng = estado.location.coordinates[1];
                                    }
                                }
                            }

                            return (
                                <Panel header={
                                    <div className="pl-2 pb-0">
                                        <span style={{ fontWeight: 'lighter' }}>
                                            {fechaATexto(estado.fecha)} · 
                                            <a href={`https://google.com/maps?q=${lat},${lng}`} target="_blank" rel="noopener noreferrer">
                                                <i className="fas text-dark fa-map-marker-alt"></i>
                                            </a>
                                        </span>
                                        <p className="mb-0">
                                            <i className="fa-solid fa-circle" style={{ color: estado.estado_color }}></i>
                                            <b>{estado.estado}</b> <b>{estado.subestado}</b>
                                        </p>
                                    </div>
                                } key={`estado-${i + 1}`}>
                                    <p className="mb-0"><b>Canal:</b> {canalEstadosString(estado.canal)}</p>
                                    <p className="mb-0"><b>Por :</b> {estado.identificador_creador}</p>
                                    <hr />
                                    {mostrarMetaDatosSubEstado(estado.metadatos)}
                                </Panel>
                            );
                        })}
                    </Collapse>
                </Col>
                <Col md={8}>
                    <MapaDetallePedido height="100%" markers={marcadores} />
                </Col>
                <Col md={12} className="pt-4" style={{ borderTop: '1px solid #e8e8e8' }}>
                    <Row>
                        <Col md={4}>
                            <div className="mb-3 card p-3" style={{ backgroundColor: "#eceef1" }}>
                                <BoxConductor conductor={pedido.conductor} />
                            </div>
                            <h6 className="mb-0">Destinatario</h6>
                            <p>{getNombreDestinatario(pedido)}</p>
                            <h6><i className="fas fa-clock"></i> Fecha de compromiso</h6>
                            <p className="mb-0"><b>Desde</b> {fechaATextoSimple(pedido.fecha_min_entrega)}</p>
                            <p><b>Hasta</b> {fechaATextoSimple(pedido.fecha_max_entrega)}</p>
                            <Card className="p-2 mb-3" style={{ backgroundColor: '#eceef1' }}>
                                {/* <BoxEditarGeoDatosOrdenIndividual pedido={pedido} token={token} onSucessChange={(data) => onSucessChange(data)} /> */}
                                <p className="mb-0">Email</p>
                                <p><b>{getEmail(pedido)}</b></p>
                                <p className="mb-0">Teléfono</p>
                                <p><b>{getTelefono(pedido)}</b></p>
                            </Card>
                            <h6 className="mb-0">Observaciones</h6>
                            <p>{pedido.nota}</p>
                        </Col>
                        <Col md={8}>
                            <h6 className="mb-3">Detalles de la carga</h6>
                            <Row>
                                <Col className="mb-3">
                                    <Tooltip title="Peso en Kilogramos">
                                        <div>
                                            <p className="mb-0" style={{ fontSize: 14 }}><b>Peso</b></p>
                                            <p>{pedido.peso}</p>
                                        </div>
                                    </Tooltip>
                                </Col>
                                <Col className="mb-3">
                                    <Tooltip title="Metros Cúbicos">
                                        <div>
                                            <p className="mb-0" style={{ fontSize: 14 }}><b>Volumen</b></p>
                                            <p>{pedido.volumen}</p>
                                        </div>
                                    </Tooltip>
                                </Col>
                                <Col className="mb-3">
                                    <Tooltip title="Bultos secos">
                                        <div>
                                            <p className="mb-0" style={{ fontSize: 14 }}><b>Bultos</b></p>
                                            <p>{pedido.bultos}</p>
                                        </div>
                                    </Tooltip>
                                </Col>
                                <Col className="mb-3">
                                    <Tooltip title="Bultos regrigerados">
                                        <div>
                                            <p className="mb-0" style={{ fontSize: 14 }}><b>Bultos R.</b></p>
                                            <p>{pedido.refrigerados}</p>
                                        </div>
                                    </Tooltip>
                                </Col>
                                <Col className="mb-3">
                                    <Tooltip title="Bultos congelados">
                                        <div>
                                            <p className="mb-0" style={{ fontSize: 14 }}><b>Bultos C.</b></p>
                                            <p>{pedido.congelados}</p>
                                        </div>
                                    </Tooltip>
                                </Col>
                                <Col md={12}>
                                    <Tabs defaultActiveKey="home">
                                        <TabPane tab="Campos personalizados" key="home" className='pt-4'>
                                            {pedido.meta_data.length < 1 ? <SinDatos /> : false}
                                            {pedido.meta_data.map(val => {
                                                return (
                                                    <div key={val.key}>
                                                        <p className="mb-0" style={{ fontSize: 14 }}><b>{val.key}</b></p>
                                                        <p>{val.value}</p>
                                                    </div>
                                                );
                                            })}
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default DetailFull;
