import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Navigate, Link as RouterLink, redirect } from "react-router-dom";
import { Badge, Button, Dropdown, Space, message, notification } from "antd";
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import Title from "antd/es/typography/Title";
import { DownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import SelectorIdioma from "./selector_idioma";
import { rutas } from "../../lib/routes/routes";
import { validarLongitudNumeroMovil } from "../../lib/helpers/data/moviles";
import socket from '../../lib/websockets';
import { useEffect } from "react";
import { IoNotifications } from 'react-icons/io5'
import { BiSolidUser } from 'react-icons/bi'
import { consultarNotificaciones, marcarNotificacionesLeidas } from "../../redux/actions/notificaciones";

const { Header, Content, Footer } = Layout;
const HeaderSite = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [api, contextHolderPush] = notification.useNotification();
  const dispatch = useDispatch()
  const session = useSelector(state => state.miusuario)
  const notificaciones = useSelector(state => state.notificaciones)
  
  const onClick = ({ key }) => {
    return false
  };
  const items = [
    {
      label: <RouterLink to={rutas.zones.slug} >GEO ZONAS</RouterLink>,
      key: rutas.zones.slug
    },
    {
      label: <RouterLink to={rutas.custom_fields.slug} >CAMPOS PERSONALIZADOS</RouterLink>,
      key: rutas.custom_fields.slug
    },
  ];

  const procesarNotificacion = (datos) => {
    dispatch(marcarNotificacionesLeidas([datos._id], session.tokenSession))
    api.info({
      message: datos.mensaje,
      placement: "bottomRight",
    })
  }
  
  useEffect(() => {
    dispatch(consultarNotificaciones(session.tokenSession))
    const identificador = `evento_${session.data.propietario}`
    socket.on(identificador, procesarNotificacion)
    return () => {
      socket.off(identificador, procesarNotificacion)
    }
  }, [])

  validarLongitudNumeroMovil()
  const styleButton = { color: "white"}
  return (
    <Layout className="layout">
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          background: "linear-gradient(to right, #f91a1a , #930000)"
        }} >
        <div className="demo-logo" />
        <Title level={4} className="mb-0" style={{ margin: 0, fontSize: 16, color: "white", marginRight: 20 }}>MOVING TRUCK 5.5</Title>
        <Button type="ghost" style={styleButton}><RouterLink to={rutas.monitoring.slug}>MONITOREO</RouterLink></Button>
        <Button type="ghost" style={styleButton}><RouterLink to={rutas.usuarios.slug} >USUARIOS</RouterLink></Button>
        <Dropdown
          menu={{
            items: [
              {
                label: <RouterLink to={rutas.map_alerts.slug} >MAPA</RouterLink>,
                key: rutas.map_alerts.slug
              },
              {
                label: <RouterLink to={rutas.alerts.slug} >LISTADO</RouterLink>,
                key: rutas.alerts.slug
              },
            ],
            onClick,
          }}
        >
        <Button type="ghost" style={styleButton} onClick={(e) => e.preventDefault()}>GEO ALERTAS<DownOutlined /></Button>
      </Dropdown>
        <Dropdown
          menu={{
            items: [
              {
                label: <RouterLink to={rutas.drivers.slug} >LISTADO</RouterLink>,
                key: rutas.drivers.slug
              },
              {
                label: <RouterLink to={rutas.tracking_driver.slug} >AUDITORIA</RouterLink>,
                key: rutas.tracking_driver.slug
              },
              {
                label: <RouterLink to={rutas.estadistica_tracking.slug} >INFORME DE RASTREO</RouterLink>,
                key: rutas.estadistica_tracking.slug
              }
            ],
            onClick,
          }}
        >
        <Button type="ghost" style={styleButton} onClick={(e) => e.preventDefault()}>CONDUCTORES<DownOutlined /></Button>
      </Dropdown>
        <Button type="ghost" style={styleButton}><RouterLink to={rutas.vehicles.slug} >VEHÍCULOS</RouterLink></Button>
        <Dropdown
          menu={{
            items,
            onClick,
          }}
        >
        <Button type="ghost" style={styleButton} onClick={(e) => e.preventDefault()}>CONFIGURACIÓN<DownOutlined /></Button>
      </Dropdown>

        <SelectorIdioma />
        <Button type="ghost" style={styleButton}>
          <RouterLink to={rutas.notifications.slug}>
          <Badge count={notificaciones}><IoNotifications style={{ color: "white", fontSize: 20 }} /></Badge>  
          </RouterLink>
        </Button>
        { /* <Button type="ghost" style={styleButton}><RouterLink><BiSolidUser style={{ color: "white", fontSize: 20 }} /></RouterLink></Button> */}
      <Button type="ghost" style={styleButton} onClick={() => dispatch(cerrarSesion())} ><LogoutOutlined /> CERRAR SESIÓN</Button>
      </Header>
      <Content
        style={{
          padding: '0 50px',
        }}>
        <div
          className="site-layout-content"
          style={{
            background: colorBgContainer,
          }}>
        </div>
      </Content>
      {contextHolderPush}
    </Layout>
  );
};

export default HeaderSite