import Map, { FullscreenControl, GeolocateControl, Marker, NavigationControl } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CarFilled } from '@ant-design/icons';
import Paragraph from 'antd/es/typography/Paragraph';
import { Popover } from 'antd';
import Moment from 'react-moment';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';


const MapaDetalleConductor = (props) => {
    const {
        id
    } = props
    const [ marcador, setMarcador ] = useState(false)
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const [viewState, setViewState] = useState(obtenerCentroMapaPorPais(pais));
    const mapRef = useRef()

      useEffect(() => {
        refrescarListado()
      }, [])

      const refrescarListado = async ()=>{
        if(!id) return false
        console.log("se dispara")
        return fetch(`${urlapi}/conductores/location?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return res.json() //dispatch(cerrarSesio())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                return false
            } else if(res){
                const mark = res
                if(!mark) return false
                if(!mark.location) return false
                if(!mark.location.coordinates) return false
                if(Array.isArray(mark.location.coordinates) !== true) return false
                if(mark.location.coordinates.length < 2) return false
                mapRef.current?.flyTo({center: [mark.location.coordinates[0], mark.location.coordinates[1]], duration: 2000, zoom: 15 });
                setMarcador(mark)
            }
            return false
        })
        .catch(error => {
            return false
        })
    }

    const incrustarMarcador = () => {
        if(!marcador) return false
        if(!marcador.location) return false
        if(!marcador.location.coordinates) return false
        if(Array.isArray(marcador.location.coordinates) !== true) return false
        if(marcador.location.coordinates.length < 2) return false

        const content = (
            <div>
                <Paragraph className='m-0'><Moment locale='ES' fromNow>{marcador.fecha_zona_horaria_local}</Moment></Paragraph>
            </div>
        )

        return <Marker
        key={marcador._id}
        latitude={marcador.location.coordinates[1]}
        longitude={marcador.location.coordinates[0]}
            >
                <Popover content={content} title="Última ubicación conocida">
            <CarFilled style={{ fontSize: 30 }} />
            </Popover>
        </Marker>
    }

    return <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    dragPan={false}
    scrollZoom={false}
    style={{width: "100%", height: 400}}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
  >
    <GeolocateControl position="top-left" />
    <FullscreenControl position="top-left" />
    <NavigationControl position="top-left" />
    {incrustarMarcador()}
  </Map>
}

export default MapaDetalleConductor