import { Col, Row } from 'antd'
import HelperModal from './helperModal'
import { url_images } from '../../lib/backend/data'
import Title from 'antd/es/typography/Title'

const RegistrosVacios = (props) => {
    const {
        titulo,
        descripcion
    } = props
    return <div>
        <div className="text-center">
            <div style={{ maxWidth: "70%", margin: "0 auto", textAlign: "center" , padding: 30 }}>
            <Row>
                            <Col md={24} xs={24}>
                            <img src={`${url_images}/animations/Search Not Found.gif`} style={{ height: 120 }} />
                            <Title level={1} className=" mb-0 mt-0" style={{ }}>Sin registros</Title>
                            <Title level={2} className="mt-0 mb-3" style={{ fontWeight: 400, fontSize: 15 }}>Aún no agregas registros a esta sección</Title>
                            </Col>
                        </Row>
            </div>
        </div>
        </div>
}

export default RegistrosVacios