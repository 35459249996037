import { Button, Col, Row, DatePicker } from "antd";
import dayjs from "dayjs"
import { useEffect, useState } from "react";
import { estilo_moving_truck } from "../../lib/estilo_sitio";
const { RangePicker } = DatePicker;
const { DateTime } = require("luxon")

const SelectorFechas = (props) => {
    const {
        defaultValue,
        key
    } = props
    const [ tipo, setTipo ] = useState('')
    const dateFormat = 'YYYY-MM-DD'
    const hoy = { createdAt: { $gte: DateTime.now().startOf('day').toUTC().toISO() } }
    const semana_pasada = { createdAt: { $gte: DateTime.now().toUTC().startOf('week').minus({ weeks: 1 }).toISO(), $lte: DateTime.now().toUTC().endOf('week').minus({ weeks: 1 }).toISO() } }
    const este_mes = { createdAt: { $gte: DateTime.now().toUTC().startOf('month').toISO(), $lte: DateTime.now().toUTC().endOf('month').toISO() } }
    const mes_pasado = { createdAt: { $gte: DateTime.now().toUTC().startOf('month').minus({ months: 1 }).toISO(), $lte: DateTime.now().toUTC().endOf('month').minus({ months: 1 }).toISO() } }
    const llave = key ? key : Math.random()

    const cambiarFecha = (condicion, slug) => {
        setTipo(slug)
        if(props.onChange) return props.onChange(condicion)
    }

    const onChangeFiltro = (name,e) => {
        console.log(name,e)
    }

    const handleChangeDate = (e) => {
        if(e){
            const from = DateTime.fromJSDate(e[1]['$d']).endOf("day").toUTC()
            const to =  DateTime.fromJSDate(e[0]['$d']).startOf("day").toUTC()
            const condicion = { createdAt: { $lte: from.toISO(), $gte: to.toISO() } }
            console.log({ condicion })
            setTipo('custom_dates')
            if(props.onChange) return props.onChange(condicion)
        }
        if(props.onChange) return props.onChange({})
    }

    return <div className="mb-3">
        <Row gutter={15} className="mb-3">
            <Col><Button type={tipo === "hoy" ? "primary" : "default" } onClick={() => cambiarFecha(hoy,'hoy')} >Hoy</Button></Col>
            <Col><Button type={tipo === "semana_pasada" ? "primary" : "default" } onClick={() => cambiarFecha(semana_pasada,'semana_pasada')} >Semana pasada</Button></Col>
            <Col><Button type={tipo === "este_mes" ? "primary" : "default" } onClick={() => cambiarFecha(este_mes,'este_mes')} >Este mes</Button></Col>
            <Col><Button type={tipo === "mes_pasado" ? "primary" : "default" } onClick={() => cambiarFecha(mes_pasado,'mes_pasado')} >Mes pasado</Button></Col>
            { tipo ? <Col><Button type="dashed" onClick={() => cambiarFecha({},'')}>Limpiar</Button></Col> : false }
        </Row>
        <Row gutter={15} className="mb-3">
            <Col>
            <RangePicker
                    // key={llave}
                    style={tipo === "custom_dates" ? { border: `1px solid ${estilo_moving_truck.colors.primary}` } : {}}
                    // defaultValue={[]}
                    placeholder={['Fecha inicial', 'Fecha final']}
                    format={dateFormat}
                    onChange={handleChangeDate}
                    />
            </Col>
        </Row>
    </div>
}

export default SelectorFechas