import { useRef, useState } from 'react'
import data from '../../lib/backend/data'
import "react-dropzone-uploader/dist/styles.css";
import { Avatar, Upload, message } from 'antd';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { InboxOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const bucketname = "https://delpasuite.s3.amazonaws.com/images"

const CargaImagen = (props) => {
    const {
        default_image
    } = props
    const image = default_image ? default_image : ''
    const [ loadingMedios, setLoadingMedios] = useState(true)
    const [ imageUrl, setImageUrl ] = useState(image)
    const [ uploading, setUploading ] = useState(false)
    const [ uploadPorcentaje, setUploadPorcentaje ] = useState(0)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const token = session.tokenSession

    const handleSubmitV2 = async (file) => {
    const name = `${Date.now()}-${file.name}`
    const original_name = file.name
    const type = file.type
    const size = file.size
    const medio = {
        nombre: original_name,
        type,
        size,
        nombre_server: name,
    }
    const url_firmada = await obtenerFirmaUrl(name, type)
    if(!url_firmada) return false
    setUploading(true)
    try {
        const result = await axios.put(url_firmada, file, {
            headers: {
              'Content-Type': file.type
            },
            onUploadProgress: (e) => {
                const { loaded, total } = e
                let porcentaje = Math.floor(  (loaded*100) / total )
                setUploadPorcentaje(porcentaje)
            }
            })
            const url = `${bucketname}/${name}`
            if(props.onChange) props.onChange(url)
            setImageUrl(url)
            return setUploading(false)
    } catch (error) {
        setUploading(false)
        console.log(error.message)
        messageApi.error("No se pudo cargar este archivo")
        }
    }

    
    
    const obtenerFirmaUrl = async (name,type)=>{
        return fetch(`${data.urlapi}/medios/presigne-url-public`,{
            method:'POST',
            body: JSON.stringify({
                name, type
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return false
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return false
            } else if(res.url){
                return res.url
            }
            return false
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return false
        })
    }

    
    const uploadButton = (
        <button
          style={{
            border: 0,
            background: 'none',
          }}
          type="button"
        >
          {uploading ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Subir
          </div>
        </button>
      )

      const beforeUpload = (file) => {
        console.log(file.type)
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 25;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
      }

      const customRequest = async ({ file, onSuccess, onError }) => {
        try {
            handleSubmitV2(file)
        } catch (error) {
          onError(error);
          message.error('Error al subir el archivo: ' + error.message);
        }
      }
      
    const component_subida = <div className='p-5'>
                    <Upload
                    text="Subir"
        name="avatar"
        listType="picture-circle"
        className="avatar-uploader"
        beforeUpload={beforeUpload}
        showUploadList={false}
        customRequest={customRequest}
      >
        {imageUrl ? (
          <Avatar
          shape='circle'
            src={imageUrl}
            alt="avatar"
            style={{
              height: "100%",
              width: "100%"
            }}
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>

    return <div>
        {component_subida}
        {contextHolder}
        </div>
}

export default CargaImagen