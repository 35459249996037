import { Col, Form, Input, Row, Select } from "antd"
import { filterOption } from "../../../lib/helpers/helpers"
import { codigos_area } from "../../../lib/internacional"
import { useState } from "react"
import Title from "antd/es/typography/Title"
import { FaMobileScreenButton } from "react-icons/fa6"

const InputMovil = (props) => {
    const [ codigoArea, setCodigoArea ] = useState('')
    const [ movil, setMovil ] = useState('')

    const valido = () => {
        if(codigoArea && movil) return true
        return false
    }

    return <div>
        <Row gutter={15}>
                            <Col md={8}>
                                <Form.Item label={<div><FaMobileScreenButton /> Móvil</div>}  className="mb-0">
                                <Select style={{ width: "100%" }} allowClear showSearch placeholder="COD. Área" name="pais_codigo" status={codigoArea ? "success" : "error"} value={codigoArea} onChange={(e) => setCodigoArea(prev => {
                                        if(props.onChange) props.onChange({
                                            pais_codigo: e ? e : "",
                                            movil
                                        })
                                        return e
                                
                                })} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                                </Form.Item>
                            </Col>
                            <Col md={16}>
                                <Form.Item label={<div></div>} className="mb-0"  >
                                <Input placeholder="Escribe aquí el número telefónico" status={ movil.length > 8 ? "success" : "error" } className='form-control mb-3' name="movil" value={movil} onChange={(e) => setMovil(prev => {
                                    console.log(e)
                                    if(props.onChange) props.onChange({
                                        pais_codigo: codigoArea,
                                        movil: e.target.value ? e.target.value : ""
                                    })
                                    return e.target.value
                                })} />
                                </Form.Item>
                            </Col>
                        </Row>
    </div>
}

export default InputMovil