import { Button, Form, Input, Modal, Select, message ,Row,Col} from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { maps_key, urlapi } from "../../lib/backend/data";
import { PlusCircleFilled } from "@ant-design/icons";
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios";
import AutoComplete from "react-google-autocomplete";
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps";
import SelectorClientes from "../Clientes/selector";
import { TimePicker } from 'antd';
import moment from 'moment';
import { dias, horariosVacios } from "./data";
import InputMovil from "../../subComponents/general/formularios/input_telefono";


const CrearNuevoCampoPersonalizado = (props) => {
    const {
        default_payload
    } = props
    const [campo, setCampo] = useState(default_payload ? { ...default_payload, horarios: horariosVacios } : { horarios: horariosVacios });
    const [ showInput, setShowInput ] = useState(false)
    const pais = useSelector(state => state.pais)
    const [ showSelectorCliente, setShowSelectorCliente ] = useState(true)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const module = 'bodegas'
    const addressRef = useRef(null)
     useEffect(() => {
        if(default_payload) if(typeof default_payload === "object") if(default_payload.id_cliente) setShowSelectorCliente(false)
    }, [])

    const crearCampo = async () => {
        if(!campo.direccion) return messageApi.error("Selecciona una dirección")
        if(!campo.titulo) return messageApi.error("Escribe un título")
        if(campo.titulo.length < 3) return messageApi.error("Escribe un título válido")
        
        setLoading(true)
        return fetch(`${urlapi}/${module}`,{
            method:'POST',
            body: JSON.stringify(campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setKeyInputFilter(key_input_filter + 1)
                messageApi.success("Creado exitosamente")
                setIsModalOpen(false)
                setCampo({...{}, ...{}})
                setShowInput(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
        return crearCampo()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setShowInput(false)
        setKeyInputFilter(key_input_filter + 1)
        return setCampo({...{}, ...{}})
      };

      const handleChangeMovil = (e) => {
        const { pais_codigo, movil } = e
        return setCampo(prev => {
            let actual = {...prev}
            actual.pais_codigo = pais_codigo
            actual.telefono = movil
            return {...{}, ...actual}
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }

    const handleChangeCustom = (value,name) => {
        campo[name] = value
        console.log(campo)
        return setCampo(campo)
    }

    const mostrarSelectorClientes = () => {
        if(!showSelectorCliente) return false

        return <div>
            <SelectorClientes key={key_input_filter} titulo="Cliente" onChange={(data) => handleChangeCustom(data,'id_cliente') } condicion={{}} />
        </div>
    }

    const handleChangeSelect = (name, value) => {
        campo[name] = value
        return setCampo(campo)
    }

    const abrirForm = () => {
        showModal()
        setTimeout(() => {
            setShowInput(true)
        }, 200);
    }
    
    return <div>
        <Button className="mb-3" type="primary" size="small" onClick={() => abrirForm()}><PlusCircleFilled /> CREAR NUEVO</Button>
        <Modal 
            title="Crear nueva bodega" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <CamposObligatoriosLeyenda />
            {mostrarSelectorClientes()}
            <Form layout="vertical">
                <Form.Item label="Título" required>
                    <Input name="titulo" value={campo.titulo} onChange={handleChange} />
                </Form.Item>
                <Form.Item label="Dirección" required={true} >
                    { showInput ? <AutoComplete
                    ref={addressRef}
                    className="ant-input inputlocation"
                    options={{
                        types: ["geocode"],
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                            const geo_data = procesarGeoDatosGeocode(place)
                        return setCampo(prev => {
                            let actual          = {...prev}
                            actual.direccion    = place.formatted_address
                            actual.geodata      = geo_data
                            actual.location     = {
                                type: "Point",
                                coordinates: [
                                    geo_data.lng, geo_data.lat
                                ]
                            }
                          return actual
                        })
                    }}
                    /> : false}
                    
                    </Form.Item>
                              
                    <Form.Item label="Nombre del encargado" className="mb-2" required={true}>
                    <Input name="encargado" value={campo.encargado} onChange={handleChange} />
                    </Form.Item>
        
                    <InputMovil onChange={handleChangeMovil} />
            
                    <Form.Item label="Email" className="mb-2" required={true}>
                    <Input name="email" value={campo.email} onChange={handleChange} />
                    </Form.Item>
            
                    <Form.Item label="Cargo" className="mb-2" required={true} >
                    <Input name="cargo" value={campo.cargo} onChange={handleChange} />
                    </Form.Item>


                    <Form.Item label="Horario de Funcionamiento" className="mb-2" required={true}>
                    {dias.map(dia => (
                        <div key={dia}>
                            <label>{dia}</label>
                                            <Row gutter={16}>
                        <Col span={6}>
                    <Input 
                        name={`inicio1_${dia}`} 
                        type="time"
                        placeholder="Desde"    
                        style={{ width: '100%' }} 
                        value={campo.horarios && campo.horarios.find(horario => horario.dia === dia)?.horario1.inicio} 
                        onChange={(e) => setCampo(prev => {
                            const newHorarios = prev.horarios && prev.horarios.length > 0
                                ? prev.horarios.map(horario => 
                                    horario.dia === dia 
                                        ? {dia, horario1: {inicio: e.target.value, final: horario.horario1.final}, horario2: horario.horario2} 
                                        : horario)
                                : horariosVacios;
                            return {...prev, horarios: newHorarios};
                        })} 
                            />
                        </Col>
                        <Col span={6}>
                            <Input type="time" name={`final1_${dia}`} placeholder="Hasta" style={{ width: '100%' }} value={campo.horarios && campo.horarios.find(horario => horario.dia === dia)?.horario1.final} 
                                onChange={(e) => setCampo(prev => {
                                    const newHorarios = prev.horarios && prev.horarios.length > 0
                                        ? prev.horarios.map(horario => 
                                            horario.dia === dia 
                                                ? {dia, horario1: {inicio: horario.horario1.inicio, final: e.target.value}, horario2: horario.horario2} 
                                                : horario)
                                        : horariosVacios;
                                    return {...prev, horarios: newHorarios};})}/>
                        </Col>
                        <Col span={6}>
                            <Input type="time" name={`inicio2_${dia}`} laceholder="Desde" style={{ width: '100%' }} value={campo.horarios && campo.horarios.find(horario => horario.dia === dia)?.horario2.inicio} 
                                onChange={(e) => setCampo(prev => {
                                    const newHorarios = prev.horarios && prev.horarios.length > 0
                                        ? prev.horarios.map(horario => 
                                            horario.dia === dia 
                                                ? {dia, horario1: horario.horario1, horario2: {inicio: e.target.value, final: horario.horario2.final}} 
                                                : horario)
                                        : horariosVacios;
                                    return {...prev, horarios: newHorarios};})}/>
                        </Col>
                        <Col span={6}>
                            <Input type="time" name={`final2_${dia}`} placeholder="Hasta" style={{ width: '100%' }} value={campo.horarios && campo.horarios.find(horario => horario.dia === dia)?.horario2.final} 
                                onChange={(e) => setCampo(prev => {
                                    const newHorarios = prev.horarios && prev.horarios.length > 0
                                        ? prev.horarios.map(horario => 
                                            horario.dia === dia 
                                                ? {dia, horario1: horario.horario1, horario2: {inicio: horario.horario2.inicio, final: e.target.value}} 
                                                : horario)
                                        : horariosVacios;
                                    return {...prev, horarios: newHorarios};})}/>
                        </Col>
                                    </Row>
                                </div>
                            ))}
                        </Form.Item>
                        </Form>
      </Modal>
      {contextHolder}
    </div>
}

export default CrearNuevoCampoPersonalizado