import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import Select from 'react-select';
// import BuscadorUsuarios from '../../usuarios/buscador';
import { useDispatch, useSelector } from 'react-redux';
import Cargando from '../general/cargando';
import { cerrarSesion } from '../../redux/actions/sesion';
import data, { urlapi } from '../../lib/backend/data';
import { Card, message, Modal, Row, Col, Button, Pagination, Popconfirm, Spin, Tooltip, Input, Form, Tag } from 'antd';
import { fechaATexto } from '../../lib/helpers/helpers';
import Structure from '../../components/Structure';
import Title from 'antd/es/typography/Title';

const ApiKeysListado = (props) => {
    const { display_full } = props
    const [ usuarios, setUsuarios ] = useState([{ value:'all_access', label:'Todos los permisos'}])
    const [ conductores, setConductores ] = useState([])
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ creando, setCreando ] = useState(false)
    const [ loadingUsuarios, setLoadingUsuarios ] = useState(true)
    const [ nuevaKey, setNuevaKey ] = useState({})
    const [ messageApi, contextHolder] = message.useMessage();
    const [ openModal, setOpenModal ] = useState(false)
    const [ idActualizando, setIdActualizando ] = useState(false)
    const [ openModalKC, setOpenModalKC ] = useState(false)
    const [ keyCreada, setKeyCreada ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const session = useSelector((state) => state.miusuario);
    const token = session?.tokenSession
    const trash = props.trash ? props.trash : false
    const dispatch = useDispatch()

    const crearNuevaKey = async () => {
        const requeridos = [
            { value:"titulo", label: "Título" },
            // { value:"id_user", label: "Permisos" }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevaKey[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        setCreando(true)
        return fetch(`${urlapi}/integraciones/apikeys`,{
            method:'POST',
            body: JSON.stringify(nuevaKey),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion ())
            return res.json()
        })
        .then(res => {
            setCreando(false)
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(res._id){
                setKeyCreada(res)
                conductores.unshift(res)
                setOpenModal(false)
                setOpenModalKC(true)
                setConductores(prev => [...[], ...conductores])
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            setCreando(false)
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })

    }

    const obtenerUsuarios = async () => {
        return fetch(`${urlapi}/usuarios/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingUsuarios(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingUsuarios(false)
            } else if(Array.isArray(res) !== false){
                const opciones = res.map(u => ({ value: u._id, label: `${u.email} · ${u.nombres}` }))
                setUsuarios(prev => [...prev, ...opciones])
            }
            return setLoadingUsuarios(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingUsuarios(false)
        })
    }

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/integraciones/apikeys/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerConductores(page)
    }

    const handleClose = () => {
        setOpenModal(false)
        setOpenModalKC(false)
    }

    const handleChangeNuevaKey = (e) => {
        const { name, value } = e.target
        nuevaKey[name] = value
        return setNuevaKey(nuevaKey)
    }

    const onChangeUsuario = (e) => {
        if(!e.value) return
        nuevaKey.id_user = e.value
        return setNuevaKey(nuevaKey)
    }

    const modalFiltro = () => {
        
        return <Modal 
        open={openModal} 
        onCancel={()=>handleClose()}
        onOk={() => crearNuevaKey()}
        confirmLoading={creando}
        centered
        >
            <Form layout='vertical'>
            <Row gutter={15}>
                <Col xs={24} className="mb-2">
                    <Form.Item label="Título">
                    <Input className='form-control' name="titulo" onChange={handleChangeNuevaKey} />
                    </Form.Item>
                </Col>
                <Col xs={12} className="mb-2">
                    {/* <BuscadorUsuarios token={token} onChange={(e) => onChangeUsuario(e)} label="Heredar permisos de usuario" /> */}
                </Col>
            </Row>
            </Form>
      </Modal>
    }

    const copiarTexto = (id) => {
        toast.info("Copiado")
        return navigator.clipboard.writeText(id)
    }
    
    const modalKC = () => {
        
        return <Modal open={openModalKC} size="lg" onCancel={()=>handleClose()} onOk={() =>handleClose()}
        centered
        >
            <h2 className='mb-0'>¡Generada exitosamente!</h2>
            <p>Esta es tu llave de API, solo podrás ver este valor una única vez por seguridad, asegúrate de guardarla en un lugar seguro.</p>
            <Tooltip title="Has click para copiar">
            <h3 className='hover' style={{ padding: 10, fontWeight:'lighter', backgroundColor: '#e9e9e9', borderRadius: 7 }} onClick={() => copiarTexto(keyCreada._id)} >{keyCreada._id} <i className="far fa-copy"></i></h3>
            </Tooltip>
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div className='mb-3'>
            <Pagination current={pagina} total={(cantidad*10)} showSizeChanger={false} onChange={(e) => paginar(e)} />
        </div>
    }

    useEffect(() => {
        obtenerConductores(1, false)
        // obtenerUsuarios()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Cargando />
    }

    const confirmarEliminado = async (id) => {
        setLoadingConductores(true)
        return fetch(`${urlapi}/integraciones/apikeys?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return obtenerConductores(1, false)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const actualizarApiKey = async (objeto) => {
        return fetch(`${urlapi}/integraciones/apikeys`,{
            method:'PUT',
            body: JSON.stringify(objeto),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setIdActualizando(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setIdActualizando(false)
            } else if(res._id){
                toast.success("Actualizado exitosamente")
            }
            return setIdActualizando(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setIdActualizando(false)
        })
    }

    const handleChangeSelect = (e,_id) => {
        setIdActualizando(_id)
        return actualizarApiKey({
            _id,
            id_user: e.value
        })
    }

    const cambiarStatus = ( _id, active ) => {
        setIdActualizando(_id)
        const i = conductores.findIndex(u => u._id === _id)
        conductores[i].active = active
        setConductores(prev => [...[], ...conductores])
        return actualizarApiKey({
            _id,
            active
        })
    }

    const tabla_registros = (registros) => {
        if(loadingConductores===true) return cargandoScreen()
        if(registros.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>
        return <div>
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            {
                        registros.map(usuario => {

                            let item_default = false
                            const i_default = usuarios.findIndex(u => u.value === usuario.id_user)
                            if(i_default > -1 ){
                                item_default = { value: usuarios[i_default].value, label: usuarios[i_default].label }
                            }

                            return <Card key={`user-${usuario._id}`} className="p-3 mb-3">
                                <Row>
                                    <Col md={12}>
                                        <Tag style={{ fontSize: 12 }}>Creada: { fechaATexto(usuario.createdAt) }</Tag>
                                        <Title level={3} className='mb-0'>{usuario.titulo}</Title>
                                        <h4 style={{ padding:10, fontSize:15, textAlign:'center', fontWeight:'lighter', backgroundColor: '#e9e9e9', borderRadius: 7 }}>
                                            ...{usuario._id.substr(-8)}
                                            { idActualizando === usuario._id ? <Spin animation="border" /> : false }
                                        </h4>
                                    </Col>
                                    {/* <Col md={8}>
                                    <label className="form-control-label">Heredar permisos de usuario</label>
                                    <Select 
                                                    style={{ marginBottom: 10 }}
                                                    onChange={(e)=>handleChangeSelect(e,usuario._id)}
                                                    options={usuarios}
                                                    defaultValue={item_default}
                                                    isLoading={loadingUsuarios}
                                                    noOptionsMessage={()=>'Sin opciones'}
                                                    placeholder="Selecciona un usuario..."
                                                />

                                    <h6 className='hover' style={{ marginTop: 14 }} onClick={() => cambiarStatus(usuario._id, usuario.active === true ? false : true )}>{ usuario.active === true ? 
                                    <i className="fas text-success fa-check-square"></i> :
                                    <i className="far fa-square"></i> } Activo</h6>
                                    
                                    </Col> */}
                                    <Col md={12} className="text-right">
                                                <Popconfirm
                                                    title="Eliminar definitivamente"
                                                    description="¿Estás seguro que deseas eliminar este recurso? Esta opción no se puede deshacer"
                                                    onConfirm={() => confirmarEliminado(usuario._id)}
                                                    onCancel={() => false}
                                                    okText="SI"
                                                    cancelText="NO"
                                                >
                                                <Button variant="danger" ><i className="far fa-trash-alt"></i></Button>
                                            </Popconfirm>
                                    </Col>
                                </Row>
                            </Card>
                        })
            }
        </div>
    }

    const render = () => {
        return <div>
            {modalFiltro()}
                {modalKC()}
                <Title className='mb-3' level={4}>Llaves de API <i className="fa-solid fa-circle-plus text-primary hover" onClick={() => setOpenModal(true)}></i></Title>
                {tabla_registros(conductores)}
        </div>
    }

    if(display_full === true ){

        const renderizar = <div>
            {contextHolder}
            <Card>{render()}</Card>
        </div>

        return <Structure component={renderizar} />
    } else {
        return render()
    }
}

export default ApiKeysListado