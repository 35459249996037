import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Breadcrumb, Button, Card, Col, Form, Input, Row, Switch, message } from "antd"
import { HomeOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import { obtenerPrefijoMovil } from "../../lib/helpers/data/moviles"
import Paragraph from "antd/es/typography/Paragraph"
import ReactFlagsSelect from "react-flags-select"
import { paises_codigos_telefonos, paises_sistema } from "../../lib/global/data"
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios"

const CrearUsuario = (props) => {
    const [ loading, setLoading ] = useState(false)
    const [ showPassword, setShowPassword ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ usuario, setUsuario ] = useState({ pais_code: pais, codigo_movil: obtenerPrefijoMovil(pais) })
    const dispatch = useDispatch()
    const requeridos = [
        { value:'nombres', label: 'Nombres' },
        { value:'apellidos', label: 'Apellidos' },
        { value:'email', label: 'Email' },
        { value:'codigo_movil', label: 'Código país' },
        { value:'movil', label: 'Móvil' },
        { value:'password', label: 'Contraseña' },
        { value:'repeat_password', label: 'Repetir contraseña' },
    ]

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        usuario[name] = value
        return setUsuario(usuario)
    }

    const registrarme = async () => {
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
        if(!validateEmail(usuario.email)) return messageApi.error(`Email inválido`)  
        if(usuario.password !== usuario.repeat_password) return messageApi.error(`Contraseñas no coinciden`)  
        usuario.status = "active"
        setLoading(true)
        const url = `${urlapi}/usuarios`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(usuario),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                setTimeout(() => {
                    return window.location = `${rutas.usuarios.slug}/${res._id}`
                }, 1000);
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    const render = () => {
        return <div>
            <Card className="mb-3">
            <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.usuarios.slug,
                    title: <span>Usuarios</span>,
                },
                {
                    title: 'Crear usuario',
                },
                ]}
            />
            <Title className="mb-2 mt-0">Crear usuario</Title>

            </Card>

            <Card>
                <CamposObligatoriosLeyenda />
            <Form>
            <Row gutter={10}>
                <Col md={12} xs={24}>
                    <Form.Item label="Nombres" required={siEsRequeridoEnEsquema('nombres',requeridos)} >
                    <Input mb={3} className="mb-3"  name="nombres" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>

                <Col md={12} xs={24}>
                    <Form.Item label="Apellidos" required={siEsRequeridoEnEsquema('apellidos',requeridos)} >
                    <Input mb={3} className="mb-3" variant="filled" name="apellidos" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item label="Email" required={siEsRequeridoEnEsquema('email',requeridos)} >
            <Input mb={3} className="mb-3" variant="filled" name="email" onChange={handleChangeUsuario} />
            </Form.Item>
            <Form.Item label="Móvil" required={siEsRequeridoEnEsquema('movil',requeridos)} >
            <Row className="mb-3" gutter={10}>
                <Col xs={10}>
                        <ReactFlagsSelect
                            countries={paises_sistema}
                            customLabels={paises_codigos_telefonos}
                            placeholder="CÓDIGO PAÍS"
                            searchPlaceholder="Buscar"
                            disabled
                            selected={usuario.pais_code}
                            onSelect={value => {
                                const prefijo = obtenerPrefijoMovil(value)
                                usuario.codigo_movil    = prefijo
                                usuario.pais_code            = value
                                console.log(usuario)
                                return setUsuario({...{}, ...usuario})
                            }}
                            />
                </Col>
                <Col xs={14}>
                <Input mb={3} variant="filled" style={{ height: 38 }} name="movil" onChange={handleChangeUsuario}  />
                </Col>
            </Row>        
            </Form.Item>
            <Form.Item label="Contraseña" required={siEsRequeridoEnEsquema('password',requeridos)} >
            <Switch
                    checked={showPassword}
                    checkedChildren="Ocultar contraseña"
                    unCheckedChildren="Mostrar contraseña"
                    onChange={() => {
                        setShowPassword(showPassword ? false : true);
                    }}
                    className="mb-3"
                    />
                <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Contraseña'
                    className="mb-3"
                    name="password"
                    autoComplete="off"
                    onChange={handleChangeUsuario}
                />
                <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Repetir Contraseña'
                    className="mb-3"
                    name="repeat_password"
                    onChange={handleChangeUsuario}
                />
                </Form.Item>
            <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => registrarme()} >REGISTRAR</Button>
            </Form>
            </Card>
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default CrearUsuario