const tipos_campos_personalizados = [
    { value: "conductor", label: "Conductor" },
    { value:"vehiculo", label: "Vehículo" },
    { value:"cliente", label: "Cliente" },
    { value:"equipo", label: "Equipo" },
    { value:"contacto", label: "Contacto de cliente" },
    { value:"viaje", label: "Viaje" },
    { value:"producto", label: "Producto" },
]

const tipos_ingreso = [
    { value: "", label: "Escritura" },
    { value: "seleccion", label: "Seleccion" },
]

module.exports = {
    tipos_campos_personalizados,
    tipos_ingreso
}