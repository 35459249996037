import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Button, Card, Col, Divider, Row, Tabs, message } from 'antd';
import ListadoConductores from "./listado";
import { FileExcelFilled, PlusCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores";
import HelperModal from "../../subComponents/general/helperModal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { urlapi } from "../../lib/backend/data";
import { cerrarSesion } from "../../redux/actions/sesion";

const Conductores = (props) => {
    const [ loading, setLoading ] = useState(false)
    const sesion = useSelector(state => state.miusuario)
    const [ messageApi, contextHolder] = message.useMessage();
    const modulo = 'conductores'
    const dispatch = useDispatch()

    const onSelect = (datos,action) => {
        if(!action) return
        const i = componentes_accion.findIndex(it => it.label === action)
        console.log({ i, action })
        if(i > -1){
            const funcion = componentes_accion[i].action
            funcion(datos)
        }
    }

    const eliminarMultiples = async (viajes)=>{
        setLoading(true)

        const seleccionados = viajes.map(e => e._id)
        return fetch(`${urlapi}/${modulo}/masive`,{
            method:'DELETE',
            body: JSON.stringify({
                ids: seleccionados
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.deletedCount){
                return window.location.reload()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }
    
    const componentes_accion = [
        {
            label: "ELIMINAR",
            action: eliminarMultiples
        }
    ]

    const render = () => {
        return <div>
            <Card>
            <Title className="mb-2 mt-0">Conductores</Title>
            <Row gutter={15}>
              <Col span="auto"><Button style={{ marginRight: 10 }} size="small" type="primary"><Link to={`${rutas.drivers.slug}/new`}><PlusCircleFilled /> CREAR NUEVO</Link></Button></Col>
              <Col span="auto"><Button style={{ marginRight: 10 }} size="small"><Link to={rutas.import_drivers.slug}><FileExcelFilled /> IMPORTADOR</Link></Button></Col>
              <Col span="auto"><HelperModal tipo="conductores" style="modal" /></Col>
            </Row>
            <ListadoConductores 
                loadingActions={loading}
                showFilters={true} showReport={true} 
                condicion_default={{ status: "active" }} 
                showSelection={true} 
                onSelect={(data,action) => onSelect(data,action)} 
                componentAction={componentes_accion}
            />
            </Card>
        </div>
    }
    
    return <Structure component={render()} />
}

export default Conductores