import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { maps_key, urlapi } from "../../lib/backend/data";
import { PlusCircleFilled } from "@ant-design/icons";
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios";
import AutoComplete from "react-google-autocomplete";
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps";
import { codigos_area } from "../../lib/internacional";
import { filterOption, siEsRequeridoEnEsquema } from "../../lib/helpers/helpers";
import Title from "antd/es/typography/Title";
import { GoDeviceMobile } from "react-icons/go";
import { AiOutlineMail } from "react-icons/ai";
import SelectorClientes from "../Clientes/selector";
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main";
import SelectorGeneral from "../General/selector";

const CrearNuevoRegistro = (props) => {
    const {
        default_payload
    } = props
    const [ campo, setCampo ] = useState(default_payload ? default_payload : {})
    const pais = useSelector(state => state.pais)
    const [ showSelectorCliente, setShowSelectorCliente ] = useState(true)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
    const module = 'products'
    const [ condicion_sub_categoria, setCondicionSubCategoria ] = useState({ tipo: module })

    const requeridos = [
        { value: 'descripcion', label: 'Descripción' }
    ];

    useEffect(() => {
        if(default_payload) if(typeof default_payload === "object") if(default_payload.id_cliente) setShowSelectorCliente(false)
    }, [])
    
    const crearCampo = async () => {
        
        let faltantes = []
        requeridos.map(field => {
            if(!campo[field.value]) faltantes.push(field.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
        
        if(campo.pais_codigo || campo.movil) if(!campo.pais_codigo || !campo.movil) return messageApi.error(`Completa el número de teléfono principal`)  

        setLoading(true)
        return fetch(`${urlapi}/${module}`,{
            method:'POST',
            body: JSON.stringify(campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                setIsModalOpen(false)
                setCampo({...{}, ...{}})
                if(props.onCreate) props.onCreate()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
        return crearCampo()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        campo[name] = value
        return setCampo({...{}, ...campo})
    }

    const handleChangeCustom = (value,name) => {
        campo[name] = value
        console.log(campo)
        return setCampo({...{}, ...campo})
    }


    const handleChangeSelect = (name, value) => {
        campo[name] = value
        return setCampo({...{}, ...campo})
    }

    const mostrarSelectorClientes = () => {
        if(!showSelectorCliente) return false

        return <div>
            <SelectorClientes titulo="Cliente" onChange={(data) => handleChangeCustom(data,'id_cliente') } condicion={{}} />
        </div>
    }

    const handleChangeSelectProducto = (e,name) => {
        console.log(e)
        campo[name] = e
        return setCampo({...{}, ...campo})
    }

    const handleChangeSelector = (val, key) => {
        setCampo(prev => {
            let actual = {...prev}
            actual[key] = val?._id
            return actual
        })
    }

    const handleChangeCat = (e) => {
        setCampo(prev => {
            const actual = {...prev}
            actual.id_sub_categoria = ''
            actual.id_categoria = e._id
            return actual
        })
        setCondicionSubCategoria(prev => ({...prev, id_categoria: e._id }))
    }

    const handleChangeSubCat = (e) => {
        setCampo(prev => {
            let actual = { ...prev }
            actual.id_sub_categoria = e._id
            return actual
        })
    }
    
    return <div>
        <Button className="mb-3" type="primary" size="small" onClick={showModal}><PlusCircleFilled /> CREAR NUEVO</Button>
        <Modal 
            title="Crear nuevo producto" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <CamposObligatoriosLeyenda />
            {mostrarSelectorClientes()}
            <Form layout="vertical">
                <Row gutter={15} >
                    <Col md={12}>
                        <Form.Item label="Descripción" required={siEsRequeridoEnEsquema('descripcion',requeridos)} className="mb-0">
                        <Input className=' mb-3' name="descripcion"  value={campo.descripcion} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="SKU" required={siEsRequeridoEnEsquema('sku',requeridos)} >
                        <Input mb={3}   value={campo.sku} variant="filled" name="sku" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Cantidad" required={siEsRequeridoEnEsquema('cantidad',requeridos)} >
                        <Input mb={3}   value={campo.cantidad} variant="filled" type="number" name="cantidad" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                        {/* <Form.Item label="SKU" required={siEsRequeridoEnEsquema('sku',requeridos)} >
                        <Input mb={3}   value={campo.sku} variant="filled" name="sku" onChange={handleChange} />
                        </Form.Item> */}
                        <SelectorGeneral module="categorias" value={campo.id_embalaje} titulo="Tipo de embalaje" condicion_default={{ tipo: "producto-embalaje" }} onChange={(e) => handleChangeSelector(e,'id_embalaje')} />
                    </Col>
                    <Col md={12} xs={24}>
                        <SelectorGeneral module="categorias" titulo="Categoría" condicion_default={{ tipo: module }} onChange={handleChangeCat} />
                    </Col>
                    <Col md={12} xs={24}>
                        <SelectorGeneral module="sub-categorias" value={campo.id_sub_categoria} titulo="Sub Categoría" condicion_default={condicion_sub_categoria} onChange={handleChangeSubCat} />
                    </Col>
                    <Col md={24} xs={24}>
                        <Form.Item label="Unidad de medida de longitud" required={siEsRequeridoEnEsquema('unidad_medida',requeridos)} >
                            <Select value={campo.unidad_medida} options={unidades_medida.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelectProducto(e,"unidad_medida")} />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Ancho" required={siEsRequeridoEnEsquema('ancho',requeridos)} >
                        <Input addonBefore={campo.unidad_medida} mb={3}   value={campo.ancho} variant="filled" type="number" name="ancho" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Alto" required={siEsRequeridoEnEsquema('alto',requeridos)} >
                        <Input addonBefore={campo.unidad_medida} mb={3}  value={campo.alto}  variant="filled" type="number" name="alto" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Largo" required={siEsRequeridoEnEsquema('largo',requeridos)} >
                        <Input addonBefore={campo.unidad_medida} mb={3}   value={campo.largo} variant="filled" type="number" name="largo" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Unidad de medida de peso" required={siEsRequeridoEnEsquema('unidad_medida_peso',requeridos)} >
                            <Select value={campo.unidad_medida_peso} options={unidades_medida_peso.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelectProducto(e,"unidad_medida_peso")} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Peso" required={siEsRequeridoEnEsquema('peso',requeridos)} >
                        <Input mb={3} addonBefore={campo.unidad_medida_peso} variant="filled" type="number"  name="peso" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
      </Modal>
      {contextHolder}
    </div>
}

export default CrearNuevoRegistro