import React from 'react';
import { url_images } from '../../lib/backend/data';
import Title from 'antd/es/typography/Title';

const options = {
  "calificaciones": {
    src: `${url_images}/animations/Shipment Tracking.gif`,
    message: "Buscando calificaciones..."
  },
  "default": {
    src: `${url_images}/animations/Shipment Tracking.gif`,
    message: "Buscando información..."
  },
  "vehiculos": {
    src: `${url_images}/animations/Shipment Tracking.gif`,
    message: "Obteniendo información de los vehículos..."
  },
  "home": {
    src: `${url_images}/animations/Shipment Tracking.gif`,
    message: "Cargando..."
  },
}

const Cargando = ({ slug }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <img className='mb-0' src={options[slug] ? options[slug].src : options.default.src} style={{ height: 400 }} />
      <Title  level={3}  style={{ marginTop: -60, marginBottom: 50 }}>{ options[slug] ? options[slug].message : options.default.message }</Title>
  </div>
  );
};

export default Cargando;
