import { Col, Form, Input, Row } from "antd"
import { useState } from "react"
import SelectorMarcas from "../../Vehiculos/selectores/marca"
import SelectorModelo from "../../Vehiculos/selectores/modelo"
import SelectorTipo from "../../Vehiculos/selectores/tipo_vehiculo"
import SelectorColor from "../../Vehiculos/selectores/color"
import SelectorTipoMotor from "../../Vehiculos/selectores/tipo_motor"

const CaracteristicasVehiculo = (props) => {
    const {
        defaultData
    } = props
    const [ caracteristicas, setCaracteristicas ] = useState(defaultData ? defaultData : {})

    const handleChangeSelect = (value,name) => {
        caracteristicas[name] = value ? value._id : ''
        if(name === "marca") caracteristicas.modelo = ""
        if(props.onChange) props.onChange(caracteristicas)
        return setCaracteristicas({...{}, ...caracteristicas})
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        caracteristicas[name] = value
        if(props.onChange) props.onChange(caracteristicas)
        return setCaracteristicas({...{}, ...caracteristicas})
    }

    return <div>
        <Form layout="vertical">
        <Row gutter={15}>
            <Col md={4}>
            <Form.Item label="Año del vehículo desde" >
                <Input name="ano" type="number" defaultValue={caracteristicas.ano} onChange={handleChange} />
            </Form.Item>
            </Col>
            <Col md={4} xs={24}>
                    <Form.Item label="Marca" >
                        <SelectorMarcas onChange={(e) => handleChangeSelect(e,"marca")} defaultValue={caracteristicas.marca} />
                    </Form.Item>
                </Col>
                <Col md={4} xs={24}>
                    <Form.Item label="Modelo" >
                        <SelectorModelo filterBy={caracteristicas.marca} value={caracteristicas.modelo} filterByKey="id_marca" onChange={(e) => handleChangeSelect(e,"modelo")} />
                    </Form.Item>
                </Col>
                <Col md={4} xs={24}>
                    <Form.Item label="Color" >
                        <SelectorColor onChange={(e) => handleChangeSelect(e,"color")} defaultValue={caracteristicas.color} />
                    </Form.Item>
                </Col>
                <Col md={4} xs={24}>
                    <Form.Item label="Tipo " >
                        <SelectorTipo onChange={(e) => handleChangeSelect(e,"id_tipo")} defaultValue={caracteristicas.id_tipo} />
                    </Form.Item>
                </Col>
                <Col md={4} xs={24}>
                    <Form.Item label="Tipo de motor" >
                        <SelectorTipoMotor onChange={(e) => handleChangeSelect(e,"tipo_motor")} defaultValue={caracteristicas.tipo_motor} />
                    </Form.Item>
                </Col>
        </Row>
        </Form>
    </div>
}

export default CaracteristicasVehiculo