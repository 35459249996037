const data_default = {
    tour_dismiss: false
}

function reducer( state = data_default, { type, payload } ){
    switch( type ){
        case 'modificarEsquema': {
            return payload
        }
        case 'reiniciarEsquema': {
            return data_default
        }
        default:
            return state
    }
}

export default reducer