import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Card, Col, Modal, Row, Tabs, message } from "antd"
import { urlapi } from "../../lib/backend/data"
import CargandoTabla from "./cargando"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { formatoMoneda } from "../../lib/helpers/main"
import { FaCircle } from "react-icons/fa"
import ListadoViajes from "../Viajes/listado"
import { TEXT_LANG } from "../../lib/lang/main"
import { botones } from "../../lib/global/data"
import { BsBoxes, BsCardChecklist } from "react-icons/bs"
import Map, { Layer, Marker, Source } from 'react-map-gl';
import { colores, mapbox_token } from '../../lib/backend/data';
import * as turf from '@turf/turf';
import Pin from '../../subComponents/mapas/pin';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import { BsFillStopCircleFill } from 'react-icons/bs';
import polyline from '@mapbox/polyline';


const MapaRuteo = (props) => {
    const {
        height,
        resultados,
        hovered,
        markers,
        pinType,
        resize,
        point_hovered
    } = props
    const [ marcadores, setMarcadores ] = useState(markers)
    const sesion                    = useSelector(state => state.miusuario)
    const pais                      = useSelector(state => state.pais)
    const defaultViewState          = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef                    = useRef()
    let geometrias                  = []
    let puntos                      = []

    if(resultados) if(resultados.routes) if(Array.isArray(resultados.routes)) if(resultados.routes.length > 0) {
        resultados.routes.forEach((route, iroute) => {

            let paradas = []
            let geometries = []

            if(route.geometrias){
                for( const geometry of route.geometrias){
                    geometries.push({ vehicle: route.vehicle, geometry })
                }
            }
            
            if(route.stops) if(Array.isArray(route.stops)) if(route.stops.length > 0){
                route.stops.forEach(stop => {
                    if(stop.location_metadata) if(typeof stop.location_metadata === "object") if(stop.location_metadata.supplied_coordinate){
                        paradas.push({
                            lng: stop.location_metadata.supplied_coordinate[0],
                            lat: stop.location_metadata.supplied_coordinate[1]
                        })
                    }
                })
            }

            puntos.push({
                vehicle: route.vehicle,
                paradas,
                geometries
            })

        })
    }

    const centrarMapa = (centro, max_zoom) => {
        console.log({ centro, max_zoom })
        const nuevo_zoom = defaultViewState.zoom + (max_zoom ? max_zoom : 3)
        mapRef.current?.flyTo({center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000});
        setTimeout(() => {
          setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
        }, 2010);
      }

    const ajustarCentro = (markers) => {
        console.log({ markers })
        if(markers.length < 1) return false
        if(markers.length === 1){
            return centrarMapa({ latitude: markers[0].latitude, longitude: markers[0].longitude  }, 2)
          }
        setZoomInit(true)
        const points = markers.map(marker => turf.point([marker.longitude, marker.latitude]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 30 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      setTimeout(() => {
        mapRef.current?.fitBounds(bounds, options);
      }, 200);
    }

    const centrarComportamientoMapa = () => {
        if(puntos.length > 0){
            let points_to_fit = []
                for( const stop of puntos ){
                    points_to_fit = [...points_to_fit, ...stop.paradas]
            }
            return ajustarCentro(points_to_fit.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
        } else {
            ajustarCentro(marcadores.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
        }
    }

      useEffect(() => {
            if(mapRef) if(mapRef.current) mapRef.current.resize()
            setTimeout(() => {
                centrarComportamientoMapa()
            }, 500);
      }, [ resize ])

      const refrescarListado = async ()=>{
        console.log("se dispara")
        return fetch(`${urlapi}/conductores/vehicles-actives`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return res.json() //dispatch(cerrarSesio())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                return false
            } else if(Array.isArray(res) !== false){
                ajustarCentro(res.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
                setMarcadores(prev => [...[], ...res])
            }
            return false
        })
        .catch(error => {
            return false
        })
    }

    const showPin = () => {
        const tipo = typeof pinType !== "undefined" ? pinType : false
        switch (tipo) {
            case "uber":
                return <BsFillStopCircleFill size={15} />
            default:
                return <Pin />
        }
    }

    const seleccionarPunto = (id) => {
        if(props.onSelect) props.onSelect(id)
        setMarcadores(prev => {
            let actual = [...prev]
            actual = actual.filter(marker => marker._id !== id)
            return [...[], ...actual]
        })
    }

    return <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: height ? height : 600}}
    // pitch={25}
    // scrollZoom={false}
    // dragPan={false}
    mapStyle="mapbox://styles/mapbox/light-v9"
    // mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
  >
    {
       puntos.length > 0 ? false : marcadores.map(marker => {
            return <Marker
                    key={marker._id}
                    longitude={marker.lng}
                    latitude={marker.lat}
                >
                <div onClick={() => seleccionarPunto(marker._id)} >
                    {showPin()}
                </div>
            </Marker>
        })
    }
    {
        puntos.map((marker,imarker) => {
            
            if(hovered) if(marker.vehicle !== hovered) return false
            return marker.paradas.map((mark,im) => {
                if(point_hovered){
                    if(marker.vehicle !== point_hovered.id_vehicle) return false
                    if(im !== point_hovered.i_point) return false
                }
                return <Marker
                    key={`${marker.vehicle}-mark-${im}`}
                    longitude={mark.lng}
                    latitude={mark.lat}
                >
                <div className='mt-0 mb-0' style={{ borderRadius: "50%", margin:0, padding: 5, backgroundColor: "white", fontWeight: "bold", border: `1px solid ${colores[imarker]}` }}><Title style={{ margin: 0, fontSize: 13, color: colores[imarker], }} level={5} >{(im+1)}</Title></div>
            </Marker>
            })
        })
    }

{
                puntos.map((marker,imarker) => {

                    return marker.geometries.map((geometry,ir) => {

                        if(point_hovered) return false

                        const decodedCoords = polyline.decode(geometry.geometry)

                        if(hovered) if(marker.vehicle !== hovered) return false
                        console.log({ color: colores[imarker] })
                        return <Source
                          id={`route-${imarker}-${ir}`}
                          type='geojson'
                          data={{
                              type: 'Feature',
                              properties: {},
                              geometry: {
                                  type: "LineString",
                                  coordinates: decodedCoords.map(co => [co[1], co[0]])
                              }
                            }}
                      >
                          <Layer
                          id={`route-layer-${imarker}-${ir}`}
                          type="line"
                          layout={{
                              'line-join': 'round',
                              'line-cap': 'round',
                            }}                
                          paint={{
                              'line-color': colores[imarker],
                              'line-width': 4,
                              'line-opacity': 0.75
                          }}
                      />
                      </Source>
                    })
                })
              }
  </Map>
}

const RutaRuteoAsignado = (props) => {
    const {
        id_ruta,
        typeView
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ condicionViajes, setCondicionViajes ] = useState(false)
    const [ estados, setEstados ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ resultados, setResultados ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const [ marcadores, setMarcadores ] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const pais = useSelector(state => state.pais)
    const icon = null
    const title = "Resumen de ruta"
    const slug = "Resumen de ruta"

    const module = 'rutas'


    const obtenerDatos = async ()=>{
        if(!id_ruta) return setLoading(false)
        setLoading(true)
        return fetch(`${urlapi}/${module}/ruteo-asignado?id=${id_ruta}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                setResultados([res])
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }
    
    useEffect(() => {
        obtenerDatos()
    },[  ])

    const boxEstados = () => {
        if(!resultados) return <div style={{ textAlign: "center" }}>
            
            <Title level={4} >No hay información para mostrar</Title>
        </div>

        return <div>
            <MapaRuteo  height={300} markers={marcadores} resultados={{ routes: resultados }} />
        </div>
    }

    const mostrarEstados = () => {
        if(loading) return <CargandoTabla />

        return <div>
            {boxEstados()}
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarEstados()}
    </div>
}

export default RutaRuteoAsignado

