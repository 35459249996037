export const calcularIncidencias = (estados) => {
    if(!estados) return 0;
    if(!Array.isArray(estados)) return 0;
    if(estados.length < 0) return 0;

    let ids_recursos_unicos = []

    for( const estado of estados ){
        if(estado.notificar_planel_flota !== true) continue
        if(ids_recursos_unicos.includes(estado.idrecurso)) continue
        ids_recursos_unicos.push(estado.idrecurso)
    }

    return ids_recursos_unicos.length
}