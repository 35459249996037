import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Breadcrumb, Button, Card, Col, Form, Input, Row, Select, Switch, message } from "antd"
import { HomeOutlined, LockOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import { obtenerPrefijoMovil } from "../../lib/helpers/data/moviles"
import ReactFlagsSelect from "react-flags-select"
import { paises_codigos_telefonos, paises_sistema } from "../../lib/global/data"
import { Link, useParams } from "react-router-dom"
import CargandoTabla from "./cargando"

const DetallesUsuario = (props) => {
    const [ usuario, setUsuario ] = useState({})
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ loading, setLoading ] = useState(false)
    const [ showPassword, setShowPassword ] = useState(false)
    const [permisosOtorgados, setPermisosOtorgados] = useState([])
    const [resetingPass, setResetingPass] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [credenciales, setCredenciales] = useState({})
    const [messageApi, contextHolder] = message.useMessage();
    const { id } = useParams()
    const dispatch = useDispatch()

    const obtenerUsuario = async () => {
        return fetch(`${urlapi}/usuarios?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingMaster(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingMaster(false)
            } else if(res._id){
                setUsuario(res)
//                obtenerEstructuraSoporte(res._id)
                if(res.permisos){
                    if(Array.isArray(res.permisos) !== false){
                        setPermisosOtorgados(res.permisos.map(p => ({ slug: p.module, actions: p.actions })))
                    }
                }
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    useEffect(() => {
        obtenerUsuario()
    }, [])

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        usuario[name] = value
        return setUsuario(usuario)
    }

    const handleChangeUsuarioSelect = (name, value) => {
        usuario[name] = value
        return setUsuario(usuario)
    }

    const reiniciarClaveUsuario = async () => {
        const { password, repeat_password } = credenciales
        if(!password) return messageApi.error(`Ingresa una nueva contraseña`)
        if(password.toString().length < 4) return messageApi.error(`Ingresa una nueva contraseña válida, al menos 4 carácteres`)
        if(!repeat_password) return messageApi.error(`Repite tu contraseña`)
        if(password !== repeat_password) return messageApi.error("Contraseñas no coinciden")
        
        setResetingPass(true)
        return fetch(`${urlapi}/usuarios/reset-password`,{
            method:'PUT',
            body: JSON.stringify({
                id: usuario._id,
                password: password,
                password_confirm: repeat_password
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setResetingPass(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setResetingPass(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
            }
            return setResetingPass(false)
        })
        .catch(error => {
                messageApi.error("Error al consultar la información, intente nuevamente")
                return setResetingPass(false)
        })
    }

    const actualizarUsuario = async () => {
        const requeridos = [
            { value:'nombres', label: 'Nombres' },
            { value:'apellidos', label: 'Apellidos' },
            { value:'movil', label: 'Móvil' },
            { value:'role', label: 'Rol de usuario' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)
        usuario.permisos = permisosOtorgados
        
        setLoading(true)
        return fetch(`${urlapi}/usuarios`,{
            method:'PUT',
            body: JSON.stringify(usuario),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const handleChangePassRepeat = (e) => {
        const { name, value } = e.target
        credenciales[name] = value
        return setCredenciales(credenciales)
    }

    const render = () => {
        if(loadingMaster) return <Card><CargandoTabla /></Card>
        
        return <div>
            <Card className="mb-3">
            <Breadcrumb
                items={[
                { title: <Link to={rutas.dashboard.slug}><HomeOutlined /></Link> },
                { title: <Link to={rutas.usuarios.slug}>Usuarios</Link> },
                { title: 'Detalles de usuario' }
                ]}
            />
            <Title className="mb-2 mt-0">Detalles de usuario</Title>

            </Card>

            <Card>
                <Row gutter={15}>
                    <Col md={12}>
                    <Title level={4} className="mt-0 mb-3"><UserOutlined /> Datos personales</Title>
                    <Form>
            <Row gutter={10}>
                <Col md={12} xs={24}><Input mb={3} className="mb-3" placeholder="Nombres" name="nombres" defaultValue={usuario.nombres} onChange={handleChangeUsuario} /></Col>
                <Col md={12} xs={24}><Input mb={3} className="mb-3" placeholder="Apellidos" variant="filled" name="apellidos" defaultValue={usuario.apellidos} onChange={handleChangeUsuario} /></Col>
                <Col md={12} xs={24}><Select className="mb-3" name="role" style={{ width: "100%" }} defaultValue={usuario.role} options={[{ value: "user", label: "Usuario" }, { value: "admin", label: "Administrador"}]} onChange={(e) => handleChangeUsuarioSelect('role', e)} /></Col>
                <Col md={12} xs={24}><Select className="mb-3" name="status" placeholder="Estado" style={{ width: "100%" }} defaultValue={usuario.status} options={[{ value: "active", label: "Activo" }, { value: "inactive", label: "Inactivo"}, { value:"suspended", label: "Suspendido" }]} onChange={(e) => handleChangeUsuarioSelect('status', e)} /></Col>
                <Col md={24} xs={24}><Input mb={3} className="mb-3" variant="filled" placeholder="Email" name="email" disabled defaultValue={usuario.email} /></Col>
            </Row>
            
            <Row className="mb-3" gutter={10}>
                <Col xs={10}>
                        <ReactFlagsSelect
                            countries={paises_sistema}
                            customLabels={paises_codigos_telefonos}
                            placeholder="CÓDIGO PAÍS"
                            searchPlaceholder="Buscar"
                            selected={usuario.pais_code}
                            onSelect={value => {
                                const prefijo = obtenerPrefijoMovil(value)
                                usuario.codigo_movil    = prefijo
                                usuario.pais_code       = value
                                console.log(usuario)
                                return setUsuario({...{}, ...usuario})
                            }}
                            />
                </Col>
                <Col xs={14}>
                <Input mb={3} variant="filled" style={{ height: 38 }} name="movil" defaultValue={usuario.movil} onChange={handleChangeUsuario}  />
                </Col>
            </Row>        
               
            <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => actualizarUsuario()} ><SaveOutlined /> GUARDAR CAMBIOS</Button>
            </Form>


                    </Col>
                    <Col md={12}>
                        <Title level={4} className="mt-0 mb-3"><LockOutlined /> Seguridad</Title>
                    <Switch
                    checked={showPassword}
                    checkedChildren="Mostrar contraseña"
                    unCheckedChildren="Ocultar contraseña"
                    onChange={() => {
                        setShowPassword(showPassword ? false : true);
                    }}
                    className="mb-3"
                    />
                    <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Contraseña'
                    className="mb-3"
                    name="password"
                    autoComplete="off"
                    onChange={handleChangePassRepeat}
                    />
                    <Input
                        type={showPassword ? 'text' : 'password'}
                        placeholder='Repetir Contraseña'
                        className="mb-3"
                        name="repeat_password"
                        onChange={handleChangePassRepeat}
                    />
                    <Button type="primary" loading={resetingPass} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => reiniciarClaveUsuario()} ><LockOutlined /> CAMBIAR CONTRASEÑA</Button>
                    </Col>
                </Row>
            
            
            </Card>
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default DetallesUsuario