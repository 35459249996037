const dias = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];
const horariosVacios = dias.map(dia => ({
    dia,
    horario1: { inicio: '', final: '' },
    horario2: { inicio: '', final: '' }
}));

module.exports = {
    dias,
    horariosVacios
}