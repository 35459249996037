const { url_images } = require("../../lib/backend/data");

const main_data = {
    slug_module: "formularios",
    key_interfaz: "categorias",
    key_esquema: "CATEGORIAS",
    sizeIcon: 25,
}

const elementos_interior_vehiculos = [
    {
      key_module: "interior",
      slug: "select",
      title: "Cabina",
      label: "Cabina",
      icon: `${url_images}/icons/simulacion-de-coche.png`,
      sizeIcon: 35,
      values: [
        { value: "Limpia", slug: "limpia" },
        { value: "Suciedad leve", slug: "suciedad-leve" },
        { value: "Suciedad severa", slug: "suciedad-severa" },
        { value: "Daños visibles", slug: "danos-visibles" }
      ]
    },
    {
      key_module: "interior",
      slug: "select",
      title: "Cinturones de seguridad",
      label: "Cinturones de seguridad",
      icon: `${url_images}/icons/cinturon-de-seguridad.png`,
      sizeIcon: 35,
      values: [
        { value: "En buen estado", slug: "buen-estado" },
        { value: "Desgaste leve", slug: "desgaste-leve" },
        { value: "Desgaste severo", slug: "desgaste-severo" },
        { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
      ]
    },
    {
      key_module: "interior",
      slug: "select",
      title: "Espejos",
      label: "Espejos",
      icon: `${url_images}/icons/espejo-retrovisor (3).png`,
      sizeIcon: 35,
      values: [
        { value: "Intactos", slug: "intactos" },
        { value: "Grietas leves", slug: "grietas-leves" },
        { value: "Grietas severas", slug: "grietas-severas" },
        { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
      ]
    },
    {
      key_module: "interior",
      slug: "select",
      title: "Sistema de dirección",
      label: "Sistema de dirección",
      icon: `${url_images}/icons/volante.png`,
      sizeIcon: 35,
      values: [
        { value: "Funcionando correctamente", slug: "funcionando-correctamente" },
        { value: "Juego leve", slug: "juego-leve" },
        { value: "Juego excesivo", slug: "juego-excesivo" },
        { value: "Requiere ajuste", slug: "requiere-ajuste" }
      ]
    },
    {
      key_module: "interior",
      slug: "select",
      title: "Parabrisas",
      label: "Parabrisas",
      icon: `${url_images}/icons/parabrisas (1).png`,
      sizeIcon: 35,
      values: [
        { value: "Intacto", slug: "intacto" },
        { value: "Grietas leves", slug: "grietas-leves" },
        { value: "Grietas severas", slug: "grietas-severas" },
        { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
      ]
    },
    {
      key_module: "interior",
      slug: "select",
      title: "Limpiaparabrisas",
      label: "Limpiaparabrisas",
      icon: `${url_images}/icons/limpiaparabrisas (1).png`,
      sizeIcon: 35,
      values: [
        { value: "Funcionando", slug: "funcionando" },
        { value: "Desgaste leve", slug: "desgaste-leve" },
        { value: "Desgaste severo", slug: "desgaste-severo" },
        { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
      ]
    }
]

const elementos_exterior_vehiculos = [
    {
      key_module: "exterior",
      slug: "select",
      title: "Llantas",
      label: "Llantas",
      icon: `${url_images}/icons/neumatico.png`,
      sizeIcon: 35,
      values: [
        { value: "Buen estado", slug: "buen-estado" },
        { value: "Desgaste leve", slug: "desgaste-leve" },
        { value: "Desgaste severo", slug: "desgaste-severo" },
        { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
      ]
    },
    {
      key_module: "exterior",
      slug: "select",
      title: "Puertas",
      label: "Puertas",
      icon: `${url_images}/icons/ventanilla-del-coche.png`,
      sizeIcon: 35,
      values: [
        { value: "Funcionando", slug: "funcionando" },
        { value: "Dificultad para abrir", slug: "dificultad-para-abrir" },
        { value: "Dificultad para cerrar", slug: "dificultad-para-cerrar" },
        { value: "Requiere ajuste", slug: "requiere-ajuste" }
      ]
    },
    {
      key_module: "exterior",
      slug: "select",
      title: "Faros delanteros",
      label: "Faros delanteros",
      icon: `${url_images}/icons/faros-de-coche.png`,
      sizeIcon: 35,
      values: [
        { value: "Funcionando", slug: "funcionando" },
        { value: "Luz baja", slug: "luz-baja" },
        { value: "Luz alta", slug: "luz-alta" },
        { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
      ]
    },
    {
      key_module: "exterior",
      slug: "select",
      title: "Luces traseras",
      label: "Luces traseras",
      icon: `${url_images}/icons/faros.png`,
      sizeIcon: 35,
      values: [
        { value: "Funcionando", slug: "funcionando" },
        { value: "Luz baja", slug: "luz-baja" },
        { value: "Luz alta", slug: "luz-alta" },
        { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
      ]
    },
    {
      key_module: "exterior",
      slug: "select",
      title: "Retrovisores",
      label: "Retrovisores",
      icon: `${url_images}/icons/espejo-retrovisor.png`,
      sizeIcon: 35,
      values: [
        { value: "Intactos", slug: "intactos" },
        { value: "Grietas leves", slug: "grietas-leves" },
        { value: "Grietas severas", slug: "grietas-severas" },
        { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
      ]
    },
    {
      key_module: "exterior",
      slug: "select",
      title: "Intermitentes",
      label: "Intermitentes",
      icon: `${url_images}/icons/intermitentes.png`,
      sizeIcon: 35,
      values: [
        { value: "Funcionando", slug: "funcionando" },
        { value: "Luz baja", slug: "luz-baja" },
        { value: "Luz alta", slug: "luz-alta" },
        { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
      ]
    }
  ];

  const elementos_sistema_electrico = [
    {
      key_module: "electricidad",
      slug: "select",
      title: "Frenos",
      label: "Frenos",
      icon: `${url_images}/icons/freno-de-disco.png`,
      sizeIcon: 35,
      values: [
        { value: "Operativos", slug: "operativos" },
        { value: "Frenado lento", slug: "frenado-lento" },
        { value: "Frenado desigual", slug: "frenado-desigual" },
        { value: "Requiere revisión", slug: "requiere-revision" }
      ]
    },
    {
      key_module: "electricidad",
      slug: "select",
      title: "Motor",
      label: "Motor",
      icon: `${url_images}/icons/motor-del-coche.png`,
      sizeIcon: 35,
      values: [
        { value: "Sonido normal", slug: "sonido-normal" },
        { value: "Sonido inusual", slug: "sonido-inusual" },
        { value: "Pérdida de potencia", slug: "perdida-potencia" },
        { value: "Requiere mantenimiento", slug: "requiere-mantenimiento" }
      ]
    },
    {
      key_module: "electricidad",
      slug: "select",
      title: "Suspensión",
      label: "Suspensión",
      icon: `${url_images}/icons/suspension.png`,
      sizeIcon: 35,
      values: [
        { value: "En buen estado", slug: "buen-estado" },
        { value: "Desgaste leve", slug: "desgaste-leve" },
        { value: "Desgaste severo", slug: "desgaste-severo" },
        { value: "Requiere reparación", slug: "requiere-reparacion" }
      ]
    },
    {
      key_module: "electricidad",
      slug: "select",
      title: "Sistema eléctrico",
      label: "Sistema eléctrico",
      icon: `${url_images}/icons/electricidad.png`,
      sizeIcon: 35,
      values: [
        { value: "Funcionando correctamente", slug: "funcionando-correctamente" },
        { value: "Problemas menores", slug: "problemas-menores" },
        { value: "Problemas severos", slug: "problemas-severos" },
        { value: "Requiere reparación", slug: "requiere-reparacion" }
      ]
    },
    {
      key_module: "electricidad",
      slug: "select",
      title: "Escape",
      label: "Escape",
      icon: `${url_images}/icons/tubo-de-escape.png`,
      sizeIcon: 35,
      values: [
        { value: "Sin fugas", slug: "sin-fugas" },
        { value: "Fugas leves", slug: "fugas-leves" },
        { value: "Fugas severas", slug: "fugas-severas" },
        { value: "Requiere reparación", slug: "requiere-reparacion" }
      ]
    },
    {
      key_module: "electricidad",
      slug: "select",
      title: "Batería",
      label: "Batería",
      icon: `${url_images}/icons/bateria-de-coche.png`,
      sizeIcon: 35,
      values: [
        { value: "Cargada", slug: "cargada" },
        { value: "Bajo voltaje", slug: "bajo-voltaje" },
        { value: "Desgaste", slug: "desgaste" },
        { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
      ]
    }
  ];

  const elementos_seguridad = [
    {
      key_module: "seguridad",
      slug: "select",
      title: "Extintor",
      label: "Extintor",
      icon: `${url_images}/icons/extintor-de-incendios.png`,
      sizeIcon: 35,
      values: [
        { value: "Presente y operativo", slug: "presente-operativo" },
        { value: "Presente pero descargado", slug: "presente-descargado" },
        { value: "Ausente", slug: "ausente" }
      ]
    },
    {
      key_module: "seguridad",
      slug: "select",
      title: "Botiquín de primeros auxilios",
      label: "Botiquín de primeros auxilios",
      icon: `${url_images}/icons/botiquin-medico.png`,
      sizeIcon: 35,
      values: [
        { value: "Completo", slug: "completo" },
        { value: "Incompleto", slug: "incompleto" },
        { value: "Ausente", slug: "ausente" }
      ]
    },
    {
      key_module: "seguridad",
      slug: "select",
      title: "Triángulos de seguridad",
      label: "Triángulos de seguridad",
      icon: `${url_images}/icons/mecanico.png`,
      sizeIcon: 35,
      values: [
        { value: "Presentes y en buen estado", slug: "presentes-buen-estado" },
        { value: "Presentes pero dañados", slug: "presentes-danados" },
        { value: "Ausentes", slug: "ausentes" }
      ]
    },
    {
      key_module: "seguridad",
      slug: "select",
      title: "Luces de emergencia",
      label: "Luces de emergencia",
      icon: `${url_images}/icons/senal-de-alerta.png`,
      sizeIcon: 35,
      values: [
        { value: "Funcionando", slug: "funcionando" },
        { value: "No funcionan", slug: "no-funcionan" }
      ]
    },
    {
      key_module: "seguridad",
      slug: "select",
      title: "Cinturones de seguridad",
      label: "Cinturones de seguridad",
      icon: `${url_images}/icons/cinturon-de-seguridad.png`,
      sizeIcon: 35,
      values: [
        { value: "En buen estado", slug: "buen-estado" },
        { value: "Desgaste leve", slug: "desgaste-leve" },
        { value: "Desgaste severo", slug: "desgaste-severo" },
        { value: "Requiere reemplazo", slug: "requiere-reemplazo" }
      ]
    }
  ]

module.exports = {
    main_data,
    elementos_interior_vehiculos,
    elementos_exterior_vehiculos,
    elementos_sistema_electrico,
    elementos_seguridad
}