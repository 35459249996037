const stringByStatus = (status) => {
    switch (status) {
        case "inactive":
            return 'Inactivo'
        case "active":
            return 'Activo'
        default:
            return 'Sin información'
    }
}

module.exports = {
    stringByStatus
}