import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Avatar, Button, Card, Col, Form, Input, Pagination, Progress, Row, Table, Tooltip, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { calcularPorcentaje, fechaATextoSimple } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import { FileExcelFilled, QuestionCircleOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons"
import { formatearDNIPorPais, formatearMovilPorPais, nombreDNIPorPais } from "../../lib/helpers/data/internationa"
import { BsBoxArrowInUpRight } from "react-icons/bs"
import ButtonGroup from "antd/es/button/button-group"
import HelperModal from "../../subComponents/general/helperModal"

const ListadoConductoresRastreo = (props)=> {
    const {
        condicion_default,
        tableSize,
        hideExporter,
        hideSearch,
        tituloHoverDriver,
        hideHeader,
        typeView
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [filtros, setFiltros] = useState([])
    const [intervalo, setIntervalo] = useState(false)
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ isFiltered, setIsFiltered ] = useState(false)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const trash = props.trash ? props.trash : false
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'
    let titulo_tooltip = tituloHoverDriver ? tituloHoverDriver : "Toca para ver en el mapa"

    const obtenerConductores = async (page, query, cargando)=>{
        const cargar = typeof cargando !== "undefined" ? cargando : true
        setLoadingConductores(cargar)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        setCondicionBusqueda(condicion)
        return fetch(`${urlapi}/conductores/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page,
                include: ['vehicles']
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
                if(res.filtros) setFiltros(res.filtros)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerConductores(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination showSizeChanger={false} current={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const seleccionarRegistro = data => {
        if(props.onSelect) props.onSelect(data)
    }

    const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <b className="hover" onClick={() => seleccionarRegistro(data)}>{data.nombres} {data.apellido_p ? data.apellido_p : ''}</b>
            default:
                return <Link to={`${rutas.drivers.slug}/${data._id}`}>{data.nombres} {data.apellido_p ? data.apellido_p : ''}</Link>
        }
    }

    const filtrarRegistros = (filtro) => {
        if(!filtro.condicion) return false
        if(typeof filtro.condicion !== "object") return false
        obtenerConductores(1, filtro.condicion)
    }

    const header = () => {
        if(hideHeader === true) return false
        return <Row gutter={15}>
            {
                filtros.map((filtro,i) => {


                    const porcentaje = calcularPorcentaje(total, filtro.cantidad)
                    return <Col className="hover" md={3} key={`filtro-${i}`} onClick={() => filtrarRegistros(filtro)} >
                    <Card size="small" className="mb-3">
                        <Paragraph level={3} style={{ fontSize: 12 }} className="m-0">{filtro.label}
                        {
                            filtro.help_text ? <Tooltip title={filtro.help_text}>
                           <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                          </Tooltip> : false
                        }
                        </Paragraph>
                        <Row>
                            <Col xs={12}><Title className="m-0">{filtro.cantidad}</Title></Col>
                            <Col xs={12} style={{ textAlign: "right", marginTop:8 }}> <Progress size={35} type="circle" percent={porcentaje} /></Col>
                        </Row>
                    </Card>
                </Col>
                })
            }
        </Row>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/drivers`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

      const reiniciarIntervalo = (pagina, cond) => {
        clearInterval(intervalo)
        const id_intervalo = setInterval(() => {
            obtenerConductores(pagina, cond, false)
          }, 4000);
        setIntervalo(id_intervalo)
      }


      const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setIsFiltered(false)
            setCondicionBusqueda(condicion_default);
            setPagina(1);
            return obtenerConductores(1, condicion_default)
        }
        setIsFiltered(true)
        const condicion = {
            ...condicion_default,
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerConductores(1, condicion);        
      };
      
      const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit}>
        <Row gutter={15}>
            <Col md={10}>
                <Form.Item label="Buscar">
                    <Input autoFocus defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Nombre, apellido, email, teléfono o ${nombreDNIPorPais(pais)}`} />
                </Form.Item>
            </Col>
            <Col md={14}>
            <Form.Item>
                    <ButtonGroup style={{ width: "100%"}}>
                    <Button onClick={handleSubmit} type="primary" ><SearchOutlined /> BUSCAR</Button>
                    <Button disabled={!isFiltered} onClick={() => {
                        setIsFiltered(false)
                        obtenerConductores(1, {})
                        setCampoBusqueda('')
                        }} ><SearchOutlined /> REINICIAR BÚSQUEDA</Button>
                    </ButtonGroup>
                </Form.Item>
            </Col>
        </Row>
               
                </Form>
      }

      const mostrarExportador = () => {
        if(hideExporter === true) return false
        return <div><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green" type="primary" size="small" onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></div>
      }

      const mostrarInfoVehiculo = (veh) => {
        if(!veh) return false
        if(typeof veh !== "object") return false
        let modelo      = ''
        let marca       = ''

        if(typeof veh.modelo === "object") if(veh.modelo.titulo) modelo = veh.modelo.titulo
        if(typeof veh.marca === "object") if(veh.marca.titulo) marca = veh.marca.titulo

        return <div>{marca} {modelo} {veh.patente} <a target='_blank' href={`${rutas.vehicles.slug}/${veh._id}`}><BsBoxArrowInUpRight /></a></div>
    }

    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />

        return <div>

            {header()}
            <Col md={6}>
                <Card size="small" className="mb-3">
                    <Paragraph level={3} style={{ fontSize: 12 }} className="m-0">Total de conductores filtrados</Paragraph>
                    <Title className="m-0">{total}</Title>
                </Card>
            </Col>
            {mostrarExportador()}
            {buscador()}
            {paginacion(data.pagina, total)}
            <Table size={tableSize ? tableSize : "middle"} dataSource={conductores} pagination={false} scroll={true} locale={{ emptyText: <HelperModal tipo="conductores" /> }}>
                <Column title="Perfil" render={(data) => {
                    return <div>
                        <Avatar shape="circle" style={{ color: '#f5222d' }} src={data.imagen_perfil} icon={<UserOutlined />} />
                    </div>
                }} />

                <Column title="Nombre y Apellidos" render={(data) => {
                    return <div>
                        <Tooltip title={titulo_tooltip}>
                        <div>{showByType(typeView, data)} <a target='_blank' href={`${rutas.drivers.slug}/${data._id}`}><BsBoxArrowInUpRight /></a></div>
                        </Tooltip>
                        </div>
                }} />
                <Column title="Movil" render={(data) => {
                    return <div>{formatearMovilPorPais(data.phone, pais)}</div>
                }} />
                <Column title="Estado" render={(data) => {
                    return <div>{data.status === "active" ? "ACTIVO" : "INACTIVO"}</div>
                }} />
            </Table>
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoConductoresRastreo