import { useEffect, useState } from "react"
import { url_images, urlapi } from "../../../lib/backend/data"
import { useDispatch, useSelector } from "react-redux"
import { Card, Col, Progress, Row, Skeleton, Tabs, message } from "antd"
import { cerrarSesion } from "../../../redux/actions/sesion"
import { calcularPorcentaje } from "../../../lib/helpers/helpers"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { botones } from "../../../lib/global/data"

const GraficosCamposPersonalizados = (props) => {
    const {
        tipo,
    } = props
    const [ campos, setCampos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()


    const obtenerDatos = async () => {
        setLoading(true)
        return fetch(`${urlapi}/campos-personalizados/graficos?tipo=${tipo}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setCampos(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const MostrarGrafico = (props) => {
        const { 
            campo
        } = props
        if(campo.datos.length < 1) return <div><Title className="mt-0 mb-3" level={4}>Sin información</Title></div>
        
                return <div>
                    
                    <Row gutter={15}>
                    {
                        campo.datos.map((filtro,i) => {
                            const porcentaje = calcularPorcentaje(filtro.total, filtro.cantidad)
                            return <Col md={4} key={`filtro-${i}`}>
                            <Card size="small" className="mb-3">
                                <Row>
                                    <Col xs={18}>
                                        <Paragraph className="m-0">{filtro.label.toUpperCase()}</Paragraph>
                                        <Title level={5} className="m-0">{filtro.cantidad}</Title>
                                    </Col>
                                    <Col xs={6} style={{ textAlign: "right", marginTop:8 }}> <Progress size={35} type="circle" percent={porcentaje.toFixed(0)} /></Col>
                                </Row>
                            </Card>
                        </Col>
                        })
                    }
                    </Row>
                    
            </div>
    }

    if(loading) return <Skeleton active />

    const items = campos.map((campo,i) => {
        return {
            key: i,
            label: campo.label.toUpperCase(),
            children: <MostrarGrafico campo={campo} />
        }
    })

    if(items.length < 1) return <div style={{ textAlign: "center" }}>
        <img src={`${url_images}/animations/Search Not Found.gif`} style={{ width: 150, maxWidth: "80%" }} />
        <Title className="mt-0 mb-0" level={4}>Sin información</Title>
        <Paragraph>Actualmente no hay información para mostrar</Paragraph>
    </div>

    return <div>
        <Tabs defaultActiveKey="0" items={items} />
    </div>
}

export default GraficosCamposPersonalizados