import { useCallback, useEffect, useState } from "react"
import PantallaCargando from "../general/pantalla_cargando"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { debounce } from "@mui/material"
import SelectorEstados from "./selector"
import { cerrarSesion } from "../../redux/actions/sesion"
import Structure from "../../components/Structure"
import { Card, Col, Row, Spin } from "antd"
import Title from "antd/es/typography/Title"

const ConfiguracionTorreControl = (props) => {
    const estructura = {
        modulos: [
            {
                nombre: "Órdenes Anuladas",
                payload_positive: { annulment: true },
                payload_negative: { annulment: false },
                options: []
            },
            {
                nombre: "Órdenes Finalizadas",
                payload_positive: { finalizado: true },
                payload_negative: { finalizado: false },
                options: []
            },
            {
                nombre: "Incidencias",
                payload_positive: { notificar_planel_flota: true },
                payload_negative: { notificar_planel_flota: false },
                options: []
            },
            {
                nombre: "Contabiliza intento",
                payload_positive: { count_as_tried: true },
                payload_negative: { count_as_tried: false },
                options: []
            },
            {
                nombre: "En tránsito",
                payload_positive: { "estado_entrega._id": { $nin: ["", null] }},
                payload_negative: { "estado_entrega._id": { $in: ["", null] }},
                options: []
            },
        ]
    }

    const [ id, setId ] = useState("")
    const [ estructuraActual, setEstructuraActual ] = useState(estructura)
    const [ loading, setLoading ] = useState(false)
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const gris = '#e5e5e5'
    const sub_tipo = "torre-control"

    useEffect(() => {
        obtenerDatos(sub_tipo)
    }, [])

    const obtenerDatos = async (modulo)=>{
        setLoading(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'POST',
            body: JSON.stringify({
                tipo: "logistica",
                sub_tipo: modulo,
                detalles: estructura
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setId(res._id)

                for( const modulo of estructura.modulos){
                    const i = res.detalles.modulos.findIndex(mod => mod.nombre === modulo.nombre)
                    if(i > -1){
                        let crear_modulo = res.detalles.modulos[i]
                        Object.keys(modulo).forEach(key => {
                            if(typeof crear_modulo[key] === "undefined") crear_modulo[key] = modulo[key]
                        })
                        console.log(crear_modulo)
                        res.detalles.modulos[i] = crear_modulo
                    } else {
                        res.detalles.modulos.push(modulo)
                    }
                }

                setEstructuraActual(res.detalles)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }
    
    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);


    const handleChangeStatus = (e,i_movulo) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.modulos[i_movulo].options = e
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }
    
    const render = () => {
        if(loading) return <PantallaCargando />

    return <div>
        <Row gutter={15}>
            <Col><p>Actualiza la visualización por módulo y por campos </p></Col>
            <Col className="text-right text-secondary"><p>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>¡Guardado!</b> } { loadingGuardado ? <Spin size="sm" animation="border" variant="primary" /> : false }</p></Col>
        </Row>
        
        <Row gutter={15}>
            {
                estructuraActual.modulos.map((modulo,imodulo) => {
                    let defaultValue = []
                    if(Array.isArray(modulo.options)) if(modulo.options.length > 0){
                        defaultValue = modulo.options.map(e => e._id)
                    }
                    return <Col xs={12} key={`modulo-${imodulo}`}  className="mb-3">
                        <Card className="p-3">
                        <Title level={4} className="mb-0 mt-0" style={{ fontWeight: "bold" }}>{modulo.nombre}</Title>
                        <SelectorEstados tipo_dato="subestados" key={`modulo-${imodulo}`} defaultValue={defaultValue} isMulti={true} full_payload={true} onChange={(e) => handleChangeStatus(e, imodulo)} />
                        </Card>
                    </Col>
                })
            }
        </Row>
        
    </div>
    }

    return <Structure component={render()} />
}

export default ConfiguracionTorreControl