const estilo_moving_truck = {
    colors: {
        primary: "#f91a1a",
        titles: "#d54a4a",
        danger: "#ce1515",
        color_third: "green",
        blue: "#0070ff",
        success: "green"
    },
    scroll_row: { x: 'max-content' }
}

module.exports = {
    estilo_moving_truck
}