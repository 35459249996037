import { message, Select, Spin } from "antd"
import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { debounce } from "@mui/material"
import Title from "antd/es/typography/Title"

const BuscadorLocalidad = (props) => {
    const {
      defaultValue
    } = props
    const [ loading, setLoading ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ value, setValue ] = useState([])
    const session = useSelector(state => state.miusuario)
    const [ defaultValues, setDefaultValues ] = useState(defaultValue ? defaultValue : [])
    const [ seleccion, setSeleccion ] = useState(defaultValues)
    const [ opciones, setOpciones ] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ keyInput, setKeyInput ] = useState(0)
    const dispatch = useDispatch()
    const selectRef = useRef(null)


    useEffect(() => {
      setTimeout(() => {
          if(defaultValue) {
            setKeyInput(keyInput + 1)
            setDefaultValues(defaultValue)
            setOpciones(defaultValue)
            setSeleccion(defaultValue)
          }
        }, 100);
    }, [ defaultValue])

    const obtenerDatos = async (condicion) => {
        setLoading(true)
        return fetch(`${urlapi}/geodata/zonas/list/search`, {
          method: 'POST',
          body: JSON.stringify({
            condicion
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              setOpciones(res.filter(m => m.titulo).map(op => ({ value: op._id, label: op.titulo.toUpperCase() })))
            }
            return setLoading(false);
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
    }
      

    const busquedaAutomatica = useCallback(debounce((data) => obtenerDatos(data), 500), []);

    const onSearch = (value) => {
        busquedaAutomatica({ titulo: { $regex: value, $options: "i" } })
    }

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const onChange = (e) => {
      const nuevas_opciones = opciones.filter(op => e.includes(op.value))
      setSeleccion(prev => {
            const nuevas = [...prev]
            nuevas.push(...nuevas_opciones)
            const agrupar = nuevas.filter(val => e.includes(val.value))
            const individualizar = agrupar.reduce((acc, val) => {
              acc[val.value] = val
              return acc
            }, {})
            const unicos = Object.keys(individualizar).map(val => individualizar[val])
            setValue(unicos)
            if(props.onChange) props.onChange(unicos.map(val => ({ ...val, _id: val.value, titulo: val.label })))
            return nuevas
        })
    }

    return <div>
        {contextHolder}
        <Select
            ref={selectRef}
            key={keyInput}
            showSearch
            mode="multiple"
            style={{ width: "100%" }}
            placeholder="Selecciona una localidad"
            // optionFilterProp="children"
            onChange={onChange}
            defaultValue={defaultValues}
            // onFocus={onFocus}
            // onBlur={onBlur}
            options={opciones}
            onSearch={onSearch}
            filterOption={filterOption}
            notFoundContent={loading ? <div><Title className="mt-0 mb-0" level={5}><Spin size="small" /> Buscando opciones...</Title></div> : null}
        />
    </div>
}

export default BuscadorLocalidad