import { Spin, message } from "antd"
import { useEffect, useState } from "react"
import { urlapi } from "../../lib/backend/data"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/sesion"
import ModalSeleccionContactos from "../../components/Contactos/modal_seleccion"

const ModalRelacionadorMiddleware = (props) => {
    const {
        condicion
    } = props
    const [ loading, setLoading ] = useState(true)
    const sesion = useSelector(state => state.miusuario)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion ? condicion : {})
    const [ condicionRelaciones, setCondicionRelaciones ] = useState(null)
    const [ messageApi, contextHolder ] = message.useMessage()
    const dispatch = useDispatch()
    const modulo = 'general/relaciones'

    const obtenerRegistros = async (cond)=>{
        setLoading(true)
        const enviar_condicion = cond ? cond : condicion_busqueda
        return fetch(`${urlapi}/${modulo}/query`,{
            method:'POST',
            body: JSON.stringify({
                condicion: enviar_condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.condicion){
                if(typeof res.condicion === "object") setCondicionRelaciones(res.condicion)
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const onSelectContactos = (data) => {
        if(props.onSelect) props.onSelect(data)
    }

    useEffect(() => {
        obtenerRegistros(condicion)
    }, [  ])

    if(loading) return <Spin />
    if(!condicionRelaciones) return false

    return <div>
        <ModalSeleccionContactos slug="asociar_contactos" typeView="modal" condicion_busqueda={condicionRelaciones} onSelect={(data) => onSelectContactos(data)} />
    </div>
}

export default ModalRelacionadorMiddleware