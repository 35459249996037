// import { Badge, Button, ButtonGroup, Divider, Heading, List, ListIcon, ListItem, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { Button, Divider, List, Modal, Tag, Typography } from 'antd'
import ButtonGroup from 'antd/es/button/button-group'
import Paragraph from 'antd/es/typography/Paragraph'
import Title from 'antd/es/typography/Title'
import { useState } from 'react'
import { MdArrowForwardIos } from 'react-icons/md'
import { RiFileExcelLine } from 'react-icons/ri'
import * as XLSX from 'xlsx'

const InformacionEsquema = (props) => {
    const {
        titulo,
        esquema
    } = props
    const titulo_doc = typeof titulo === "string" ? titulo : "Modelo"
    const [ showModal, setShowModal ] = useState(false)

    const handleDownload = () => {
        // Crear el archivo de Excel
        const wb = XLSX.utils.book_new();
        const wsData = [ esquema.filter(e => !e.denieUpload).map(e => e.label) ]
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
    
        // Convertir el libro de trabajo a un archivo binario
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    
        // Crear un Blob con los datos binarios
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    
        // Generar un objeto URL del Blob
        const url = URL.createObjectURL(blob);
    
        // Crear un enlace de descarga invisible y hacer clic en él
        const a = document.createElement('a');
        a.href = url;
        a.download = `${titulo_doc}.xlsx`;
        a.click();
    
        // Liberar el objeto URL
        URL.revokeObjectURL(url);
      };
    
      const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };

      const mostrarInformacion = () => {
        setShowModal(true)
      }

      const mostrarModal = () => {
        return <Modal open={showModal} onCancel={() => setShowModal(false)} footer={[]}>
            <div>
            <Title as="h2" className="mt-0 mb-0">Información del modelo excel</Title>
            <Paragraph className="mt-0 mb-3">Estos son las columnas que tu documento excel debe tener</Paragraph>
            <Divider style={{ marginBottom: 10, marginTop: 10 }} />
            <List dataSource={esquema.filter(e => !e.denieUpload)} renderItem={(item => {
                        return <List.Item>
                          {item.label} {item.optionalUpload ? <Tag >OPCIONAL</Tag> : <Tag color='red'>OBLIGATORIO</Tag> }
                          {item.info ? <div><Typography style={{ fontWeight: "bold" }}>{item.info}</Typography> </div> : false}
                          </List.Item>
                    })} >
            </List>
            </div>
        </Modal>
    }

      if(!esquema) return false

    return <div>
        {mostrarModal()}
        <ButtonGroup>
            <Button size="xs" leftIcon={<RiFileExcelLine /> } onClick={() => handleDownload()} >DESCARGAR MODELO</Button>
            <Button size="xs" onClick={() => mostrarInformacion()}>INFORMACIÓN DEL MODELO</Button>
        </ButtonGroup>
    </div>
}

export default InformacionEsquema