import { Button, Modal } from "antd";
import Typography from "antd/es/typography/Typography";
import { useState } from "react";
import { GoDeviceMobile } from "react-icons/go";

const MostrarMoviles = (props) => {
    const {
        size,
        datos
    } = props
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const sin_datos = <Typography><GoDeviceMobile /> Sin datos</Typography>
    
    if(!datos) return sin_datos
    if(Array.isArray(datos) !== true) return sin_datos
    if(datos.length < 1) return sin_datos
    
    return (
        <>
        <Button size={size ? size : false} icon={<GoDeviceMobile />} onClick={showModal}>VER TELÉFONOS</Button>
        <Modal title="Números telefónicos" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
            {datos.map((num,i) => {
                return <div key={`nuim-${i}`}><GoDeviceMobile /> +{num.pais_codigo} {num.movil}</div>
            })}
        </Modal>
        </>
    );
  }

export default MostrarMoviles