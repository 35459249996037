const { urlapi } = require("../../lib/backend/data")

const modificarNotificaciones = objeto => {
    return {
        type: 'modificarNotificaciones',
        payload: objeto
    }
}

const consultarNotificaciones = (token) => {
    return dispatch => {
        return fetch(`${urlapi}/notificaciones/list`, {
            method: "POST",
            body: JSON.stringify({
                condicion: { checked: false }
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`,
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res) return false
            if(typeof res !== "object") return false
            if(typeof res.total !== "undefined"){
                if(!isNaN(res.total)) dispatch(modificarNotificaciones(res.total))
            }
            return false
        })
        .catch(error => error)
    }
}

const marcarNotificacionesLeidas = (ids, token) => {
    return dispatch => {
        return fetch(`${urlapi}/notificaciones/marcar`, {
            method: "POST",
            body: JSON.stringify({
                ids
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`,
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res) return false
            if(typeof res !== "object") return false
            if(typeof res.total !== "undefined"){
            }
            return false
        })
        .catch(error => error)
    }
}

module.exports = {
    modificarNotificaciones,
    consultarNotificaciones,
    marcarNotificacionesLeidas
}