const { obtenerCodigoIdioma } = require("../internacional")
const { lang } = require("./lang")

const TEXT_LANG = (name, pais_code, values) => {
    const localizacion = obtenerCodigoIdioma(pais_code)
    let string = lang[name] ? lang[name][localizacion] : name
    if(values){
        if(Array.isArray(values) !== false){
            if(values.length > 0){
                values.map((val,i) => {
                    const buscar = `{{${i+1}}}`
                    string = string.replace(buscar, val)
                })
            }
        }
    }
    return string
}

module.exports = {
    TEXT_LANG
}