import React, { Component, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { userService } from '../../services/user.service'
import { validateEmail } from '../../lib/helpers/helpers'
import { Link, Navigate, useSearchParams } from 'react-router-dom'
import guardarUsuario from '../../redux/actions/guardarUsuario'
import { Button, Col, Divider, Form, Input, Result, Row, Skeleton, Spin, Switch, message } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Title from 'antd/es/typography/Title'
import Paragraph from 'antd/es/typography/Paragraph'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { gradient_primary } from '../../lib/global/style'
import { cambiarPais } from '../../redux/actions/pais'
import { validarDNIBrasil } from '../../lib/helpers/data/brasil'
import { rutas } from '../../lib/routes/routes'
import { urlapi } from '../../lib/backend/data'
import AuthCode from 'react-auth-code-input'

const CambiarPassword = (props) => {
    const [ email, setEmail ] = useState('')
    const [ showPassword, setShowPassword ] = useState(false)
    const [ password, setPassword ] = useState('')
    const [ passwordRepeat, setPasswordRepeat ] = useState('')
    const [ codigo, setCodigo ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const [ loadingMaster, setLoadingMaser ] = useState(true)
    const [ success, setSuccess ] = useState(false)
    const [ solicitud, setSolicitud ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const id_enlace = searchParams.get('id')


    useEffect(() => {
        consultarSolicitud()
    }, [])
    
    dispatch(guardarUsuario(false))

    const consultarSolicitud = () => {
        
        if(!id_enlace) return setLoadingMaser(false)       
        return fetch(`${urlapi}/auth/reset-password?id=${id_enlace}`, {
            method: "GET",
            headers: {
                'Content-type': "application/json"
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.message){
                messageApi.error(res.message)
            } else if(res._id){
                setSolicitud(res)
            }
            setLoadingMaser(false)
        })
        .catch(error => {
            setLoadingMaser(false)
        })
    }

    const handleSubmit = async (e) => {
        if(e) e.preventDefault()
        if(!password) return messageApi.error("Introduce tu contraseña")        
        if(!passwordRepeat) return messageApi.error("Introduce 2 veces tu contraseña")        
        if(password !== passwordRepeat) return messageApi.error("Tus contraseñas no coinciden")        
        setLoading(true)
        return fetch(`${urlapi}/auth/reset-password`, {
            method: "POST",
            body: JSON.stringify({
                id_solicitud: solicitud._id,
                password
            }),
            headers: {
                'Content-type': "application/json"
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.message){
                messageApi.error(res.message)
            } else if(res._id){
                setSuccess(res._id)
            }
            setLoading(false)
        })
        .catch(error => {
            setLoading(false)
        })
    }

    const handleChangeEmail = (e) => {
        const { value } = e.target
        setEmail(value)
    }

    const handleChangePassword = (e) => {
        const { value } = e.target
        setPassword(value)
    }
    const handleChangePasswordRepeat = (e) => {
        const { value } = e.target
        setPasswordRepeat(value)
    }
    
    const MensajeEnviado = () => {
        
        const handleOnChange = (res) => {
            if(props.onChange) props.onChange(res)
        }

        const handleChangeCodigo = (e) => {
            const { value } = e.target
            if(props.onChange) props.onChange(value)
        }

        return <div>
            <Result
                status="success"
                title="Modificación exitosa"
                subTitle="Ahora solo te queda iniciar sesión con tu nueva contraseñ"
                extra={[
                <Button type="primary" key="console">
                    <Link to={rutas.login.slug}>Volver al inicio</Link>
                </Button>
                ]}
            />
        </div>
    }

    const handleChangeShowPassword = (e) => {
        setShowPassword(e)
    }

    const formulario = () => {

        if(loadingMaster) return <Skeleton active />
        if(!solicitud) return <div>
            <Title level={4}>Esta solicitud no está disponible, intenta de nuevo</Title>
            <Title level={5}><Link to={rutas.login.slug} style={{ fontWeight: 'bold' }}>Iniciar sesión </Link> </Title>
        </div>

        if(success) return <MensajeEnviado onChange={(val) => setCodigo(val)} />

        return <div>
            <Form onSubmit={handleSubmit} 
                className="login-form"
            >
                <Title  className='mb-2'>Reinicia tu contraseña</Title>
                <Paragraph  size='sm' mb={3}>Puedes escribir tu nueva contraseña ahora</Paragraph>
                <Switch style={{ marginBottom: 15 }} checkedChildren="OCULTAR CONTRASEÑA" unCheckedChildren="MOSTRAR CONTRASEÑA" defaultChecked={showPassword} onChange={handleChangeShowPassword} />
                <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Contraseña'
                    className="mb-3"
                    name="password"
                    autoComplete="off"
                    onChange={handleChangePassword}
                />
                <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Repetir Contraseña'
                    className="mb-3"
                    name="repeat_password"
                    onChange={handleChangePasswordRepeat}
                />
                <Button loading={loading} style={{ width: "100%" }} type='primary' onClick={() => handleSubmit()} >REESTABLECER CONTRASEÑAS</Button>
                <Title level={5}>¿Tienes un usuario? <Link to={rutas.login.slug} style={{ fontWeight: 'bold' }}>Iniciar sesión </Link> </Title>
            </Form>
        </div>
    }
    
    return <div >
        {contextHolder}
        <Row style={{ height: "100vh" }}>
        <Col md={8} style={{ display: "flex", padding: 30, alignItems: "center" }}>
        {formulario()}
        </Col>
        <Col md={16} xs={0} style={{ background: gradient_primary, display: "flex", alignItems: "center"}}>
            <img style={{ width: "55%", margin: "0 auto"}} className='hidden-mobile' src="/images/3.png" />
        </Col>
        </Row>
    </div>
}

export default CambiarPassword