import Map, { FullscreenControl, GeolocateControl, Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CarFilled } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { Button, Card, Col, Divider, Popconfirm, Popover, Row, Spin, message } from 'antd';
import Moment from 'react-moment';
import Paragraph from 'antd/es/typography/Paragraph';
import SelectorZona from '../../RadioOperacion/selector_zona';
import { fitBounds } from 'viewport-mercator-project';
import bbox from '@turf/bbox';
import { cerrarSesion } from '../../../redux/actions/sesion';
import { obtenerCentroMapaPorPais } from '../../../lib/helpers/data/internationa';

const MapaZonasOperacionMantenedor = (props) => {
    const {
        height,
        redzone,
        tipo_operacion_default,
        hideControls
    } = props
    const [ zonasBD, setZonasBD ] = useState([])
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ geocercas, setGeoCercas ] = useState([])
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ coleccion, setColeccion ] = useState({
      type: 'FeatureCollection',
      features: []
    })
    const [messageApi, contextHolder] = message.useMessage();
    const sesion = useSelector(state => state.miusuario)
    const [viewState, setViewState] = useState(obtenerCentroMapaPorPais(pais));
    const [ zoomInit, setZoomInit ] = useState(false) 
    const dispatch = useDispatch()
    const mapRef = useRef()
    const tipo_operacion = tipo_operacion_default ? tipo_operacion_default : "operacion"


    useEffect(() => {
        if (coleccion.features.length > 0) {

          const instancia_geocercas = JSON.parse( JSON.stringify( coleccion.features ))
          const coordinates = instancia_geocercas.reduce((acc, geofence) => {
            const geofenceCoords = geofence.geometry.coordinates;
            if (geofenceCoords.length === 1) {
              // Polygon
              acc.push(geofenceCoords[0]);
            } else {
              // MultiPolygon
              geofenceCoords.forEach((coords) => {
                if(coords.length < 1) return false
                acc.push(coords[0]);
              });
            }
            return acc;
          }, []);
    
          const combinedFeatures = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Polygon',
                  coordinates,
                },
              },
            ],
          };
  
            const bounds = bbox(combinedFeatures);
            const newViewport = {
              ...viewState,
              latitude: (bounds[1] + bounds[3]) / 2,
              longitude: (bounds[0] + bounds[2]) / 2
            };
      
            const options = {
              padding: 30 // Ajusta el valor de padding según tus necesidades
            };
      
            setViewState(newViewport);
            mapRef.current.fitBounds(bounds, options);

          } else {
            obtenerGeocercaBD()
          }
    }, [ coleccion ])

    const mostrarGeocercas = () => {
      if(loadingMaster) return <Spin />
      if(geocercas.length < 1 ) return <div className='mb-3' style={{ padding: "15px 15px 0px 15px" }} ><Title className='mb-0 mt-0' level={4}>Sin zonas cargadas</Title></div>

      return <div>
        <div className='mb-3' style={{ padding: "15px 15px 0px 15px" }} ><Title className='mb-0 mt-0' level={4}>{geocercas.length} zonas cargadas</Title></div>
          {
            geocercas.map((geo,pos) => {
              return <div key={geo._id} className='mb-0' style={{ padding: "15px 15px 0px 15px" }} >
                  <Paragraph style={{ fontSize: 17, fontWeight: "bold", marginBottom: 0 }} className='mb-0 mt-0'>{geo._id ? false : <Spin style={{ marginRight: 5 }}/> } {geo.titulo}</Paragraph>
                  <Popconfirm
                    title="Eliminar zona"
                    description="¿Estás seguro que deseas eliminar esta zona de tu operación?"
                    onConfirm={() => eliminarGeocerca(geo._id)}
                    onCancel={() => false}
                    okText="SI"
                    cancelText="NO"
                  >
                  <Button loading={geo.deleting === true ? true : false} size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
                  { geocercas.length === (pos+1) ? false : <Divider className='mb-0' />  }
                  </div>
            })
          }
      </div>
    }

    const obtenerGeocercaBD = async () => {
      return fetch(`${urlapi}/geodata/zonas/list`, {
        method: 'POST',
        body: JSON.stringify({ 
          condicion: {
            tipo_asignacion: tipo_operacion
          }
         }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then(async (res) => {
          if (!res) {
              messageApi.error("Sin datos obtenidos")
          } else if (res.errorMessage) {
              messageApi.error(res.errorMessage)
          } else if(Array.isArray(res) !== false){
            setGeoCercas(res)
            setColeccion((prevCollection) => ({
              ...prevCollection,
              features: [...prevCollection.features, ...res],
            }))
          }
          return setLoadingMaster(false)
        })
        .catch((error) => {
          messageApi.error("Error al consultar la información, intente nuevamente")
          return setLoadingMaster(false)
        })
    }

    const eliminarGeocerca = async (id) => {
      const instancia_geocercas = JSON.parse( JSON.stringify( geocercas ))
      const i = instancia_geocercas.findIndex(g => g._id === id)
      if(i > -1){
        geocercas[i].deleting = true
      } else {
        return false
      }
      return fetch(`${urlapi}/geodata/zonas/asociar?id=${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then(async (res) => {
          if (!res) {
              messageApi.error("Sin datos obtenidos")
          } else if (res.errorMessage) {
              messageApi.error(res.errorMessage)
          } else if(res._id){
            messageApi.success("Eliminado exitosamente")
              instancia_geocercas.splice(i,1)
              console.log(instancia_geocercas)
              setColeccion((prevCollection) => ({
                ...prevCollection,
                features: [...[], ...instancia_geocercas],
              }))
              setGeoCercas([...[], ...instancia_geocercas])
          }
          return false
        })
        .catch((error) => {
          messageApi.error("Error al consultar la información, intente nuevamente")
          return false
        })
    }

    const guardarGeocercaBD = async (id, tipo, nuevo_arreglo) => {
      return fetch(`${urlapi}/geodata/zonas/asociar?id=${id}&tipo=${tipo}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then(async (res) => {
          if (!res) {
              messageApi.error("Sin datos obtenidos")
          } else if (res.errorMessage) {
              messageApi.error(res.errorMessage)
          } else if(res._id){
            messageApi.success("Guardado exitosamente")
            const i = nuevo_arreglo.findIndex(geo => geo.id_geocerca === id)
            if(i > -1 ){
              nuevo_arreglo[i]._id = res._id
              setGeoCercas([...[], ...nuevo_arreglo])
            }
          }
          return false
        })
        .catch((error) => {
          messageApi.error("Error al consultar la información, intente nuevamente")
          return false
        })
    }

    const onAgregarZona = (data) => {
      const buscar = geocercas.findIndex(geo => geo.id_geocerca === data._id)
      if(buscar > -1) return messageApi.error("Esta geocerca ya fue agregada")
        const nueva = {
            id_geocerca: data._id,
            titulo: data.titulo,
            type: "Feature",
            properties: data.properties,
            geometry: {
              type: data.region.type,
              coordinates: data.region.coordinates
            }
          }
          const nuevo_arreglo = [...geocercas, ...[nueva]]
          console.log({nuevo_arreglo})
          setGeoCercas(nuevo_arreglo)
          coleccion.features = nuevo_arreglo
          setColeccion((prevCollection) => ({
            ...prevCollection,
            features: [...prevCollection.features, nueva],
          }))
          guardarGeocercaBD(data._id, tipo_operacion, nuevo_arreglo)
    }

    const mostrarAcciones = () => {
      if(hideControls === true) return false
      return <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
      <Title level={4} className='mb-3 mt-0'>AGREGA ZONAS A LA ZONA DE OPERACIÓN</Title>
      <Row gutter={15}>
        <Col md={16}>
          <SelectorZona title="Zonas del país" condicion={{ }} onSelected={data => onAgregarZona(data)} />
        </Col>
          </Row>
    </div>
    }

    return <div>
      {contextHolder}
        {mostrarAcciones()}
        <Row>
          <Col md={6} style={{ background: "white" }}>
          {mostrarGeocercas()}
          </Col>
          <Col md={18}>
          <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: height ? height : 500}}
    pitch={40}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
  >
    <GeolocateControl position="top-left" />
    <FullscreenControl position="top-left" />
    <NavigationControl position="top-left" />
        <Source
                key={"ajsfkn"}
                id={"geocerca._id"}
                type="geojson"
                data={coleccion}
                >
                    
                    {
                      redzone === true ? false : <Layer
                      id="geocercas-layer"
                      type="line"
                      paint={{
                          'line-width': 4,
                          'line-color': '#0080ef'
                        }}                      
                      />
                    }
                    {
                      redzone === true ? false : <Layer
                      id="geocercas-layer"
                      type="fill"
                      paint={{
                          'fill-color': 'black',
                          'fill-opacity': 0.5
                        }}                      
                      />
                    }
                        {
                          redzone === true ? <Layer
                          id="geocercas-layer"
                          type="fill"
                          paint={{
                              'fill-color': 'red',
                              'fill-opacity': 0.2
                            }}                      
                          /> : false
                        }
                </Source>
                    
          
  </Map>
          </Col>
        </Row>

        
        
  </div>
}

export default MapaZonasOperacionMantenedor