import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Card, Col, Collapse, Row, Skeleton, Table, Tabs, Tag, message } from "antd"
import Paragraph from "antd/es/typography/Paragraph"
import Title from "antd/es/typography/Title"
import { fechaUTCATexto } from "../../lib/helpers/helpers"
import Column from "antd/es/table/Column"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { MdOpenInNew } from "react-icons/md"
import { estilo_moving_truck } from "../../lib/estilo_sitio"

const ContadorVehiculosConectados = (props) => {
    const {
        marcadores
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ datos, setDatos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const pais = useSelector(state => state.pais)
    const [messageApi, contextHolder] = message.useMessage();
    const idioma = useSelector(state => state.idioma)

    const obtenerDatos = async ()=>{
        return fetch(`${urlapi}/conductores/counter-vehiculos`,{
            method:'POST',
            body: JSON.stringify({}),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                setDatos(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [ marcadores ])

    if(loading) return <div><Skeleton active /></div>
    if(datos.length < 1) return <div>
        <Title level={3} className="m-0">No hay información de vehículos</Title>
    </div>

    const total = datos.reduce((prev, next) => {
        const cantidad = next.cantidad
        if(isNaN(cantidad)) return prev
        return cantidad + prev
    }, 0)

    const mostrarVehiculos = (registros) => {
        return <div>
            <Table dataSource={registros} size="small" pagination={false} scroll={estilo_moving_truck.scroll_row} >
                
                <Column title="Marca" render={(data) => {
                    return <div>{data.marca?.titulo}</div>
                }} />
                <Column title="Modelo" render={(data) => {
                    return <div>{data.modelo?.titulo}</div>
                }} />
                <Column title="Patente" render={(data) => {
                    return <div><Link target="_blank" to={`${rutas.vehicles.slug}/${data._id}`}><MdOpenInNew /> {data.patente}</Link> </div>
                }} />
                {/* <Column title="Eliminar" render={(data) => {
                    return <div>
                        <Popconfirm
                    title="Eliminar"
                    description="¿Estás seguro que deseas eliminar recurso? Esta acción no se puede deshacer"
                    onConfirm={() => eliminarRecurso(data._id)}
                    onCancel={() => false}
                    okText="SI"
                    style={{ display: "inline-block"}}
                    cancelText="NO"
                  >
                  <Button loading={deleting} size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
                  </div>
                }} /> */}
            </Table>
        </div>
    }

    return <div>
        <Title level={3} className='mt-0 mb-3'>{total} Vehículos en ruta</Title>
        {/* <Row gutter={2}>
        {
            datos.map(vehiculo => {
                return <Col key={vehiculo._id} style={{ }} item md={4} xs={8}>
                    <Card size="small">
                            { vehiculo.icon ? <img style={{ width: "70%", marginBottom: 0 }} alt={vehiculo.descripcion} src={vehiculo.icon} /> : false}
                            <Title className="m-0" style={{ fontSize: 12 }} level={5}>{vehiculo.tipo}</Title>
                            <Paragraph className="mb-0">{vehiculo.cantidad}</Paragraph> {fechaUTCATexto}
                            </Card>
                        </Col>
            })
        }
        </Row> */}
            <div >
            <Collapse accordion defaultActiveKey="1" items={datos.map((item,i) => {

                const label = <Title className="m-0" level={5}>{item.tipo} <Tag color="blue" >{item.cantidad}</Tag></Title>

            return {
                key: (i+1),
                label,
                children: mostrarVehiculos(item.vehiculos)
            }
        })} />
            </div>
    </div>

}

export default ContadorVehiculosConectados