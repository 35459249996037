import Map, { FullscreenControl, GeolocateControl, Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import data, { limit_page_options, mapbox_token, urlapi } from '../../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AlertFilled, CarFilled, PushpinOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { Button, Card, Col, Divider, Modal, Pagination, Popconfirm, Popover, Row, Segmented, Skeleton, Spin, Tooltip, message } from 'antd';
import Moment from 'react-moment';
import Paragraph from 'antd/es/typography/Paragraph';
import SelectorZona from '../../RadioOperacion/selector_zona';
import { fitBounds } from 'viewport-mercator-project';
import bbox from '@turf/bbox';
import { cerrarSesion } from '../../../redux/actions/sesion';
import { obtenerCentroMapaPorPais } from '../../../lib/helpers/data/internationa';
import { fechaUTCATexto } from '../../../lib/helpers/helpers';
import { calcularPuntoCentral } from '../../../lib/helpers/maps/geocercas';
import ButtonGroup from 'antd/es/button/button-group';
import ModalAlertasZona from './modal_alerta';
import ListadoAlertas from '../../GeoAlertas/listado';
import { tituloPorTipoNotificacion } from '../../../lib/helpers/alertas';
import HelperModal from '../../../subComponents/general/helperModal';
import { FaList } from "react-icons/fa";
import { MdOutlineGridView } from "react-icons/md";
import { Link } from 'react-router-dom';
import { rutas } from '../../../lib/routes/routes';
import Navigation from './navigation';
import SortBy from '../../../subComponents/general/sort_by';
import { obtenerLimitesPagina } from '../../../lib/helpers/main';

const tipos_vista = [
  {
    label: "Ver por alertas",
    tipo: "alertas"
  },
  {
    label: "Ver por geocercas",
    tipo: "zonas"
  }
]
const MapaZonasAlertasMantenedor = (props) => {
    const {
        height,
        redzone,
        tipo_operacion_default,
        hideControls
    } = props
    const [ zonasBD, setZonasBD ] = useState([])
    const [ pagina, setPagina ] = useState(1)
    const [ tipoVista, setTipoVista ] = useState("alertas")
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ focusOnMap, setFocusOnMap ] = useState(false)
    const [ alertas, setAlertas ] = useState([])
    const [ geocercas, setGeoCercas ] = useState([])
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ total, setTotal ] = useState(0)
    const [ coleccion, setColeccion ] = useState({
      type: 'FeatureCollection',
      features: []
    })
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const [messageApi, contextHolder] = message.useMessage();
    const sesion = useSelector(state => state.miusuario)
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const dispatch = useDispatch()
    const mapRef = useRef()
    const tipo_operacion = tipo_operacion_default ? tipo_operacion_default : "operacion"
    const [ condicion_busqueda, setCondicionBusqueda ] = useState({})
    const [ showModalAlertas, setShowModalAlertas ] = useState(false)
    const [ condicionAlertas, setCondicionAlertas ] = useState(false)
    const modulo = 'geo-alertas'
    const default_limit_page                = obtenerLimitesPagina(limit_page_options, 'limit_page', interfaz_usuario, modulo)

    const ajustarMapaAGeocercas = (datos) => {
      const instancia_geocercas = JSON.parse( JSON.stringify( datos ))
      if(instancia_geocercas.length < 1) return false
          const coordinates = instancia_geocercas.reduce((acc, geofence) => {
            const geofenceCoords = geofence.geometry.coordinates;
            if (geofenceCoords.length === 1) {
              // Polygon
              acc.push(geofenceCoords[0]);
            } else {
              // MultiPolygon
              geofenceCoords.forEach((coords) => {
                if(coords.length < 1) return false
                acc.push(coords[0]);
              });
            }
            return acc;
          }, []);
    
          const combinedFeatures = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Polygon',
                  coordinates,
                },
              },
            ],
          };
  
            const bounds = bbox(combinedFeatures);
            const newViewport = {
              ...viewState,
              latitude: (bounds[1] + bounds[3]) / 2,
              longitude: (bounds[0] + bounds[2]) / 2
            };
      
            const options = {
              padding: 30 // Ajusta el valor de padding según tus necesidades
            };
      
            setViewState(newViewport);
            mapRef.current?.fitBounds(bounds, options);
    }

    useEffect(() => {
      obtenerGeocercaBD(1, false, true)
    }, [])

    const handleChangeTipoVista = (e) => {
      const i = tipos_vista.findIndex(t => t.label === e)
      setTipoVista(tipos_vista[i].tipo)
    }

    const iconoPorTipoAlerta = (type) => {
      const fontSize = 25
      switch (type) {
        case "info":
          return <AlertFilled style={{ color: "blue", fontSize }} />
        case "warning":
          return <AlertFilled style={{ color: "orange", fontSize }} />
        case "danger":
          return <AlertFilled style={{ color: "orange", fontSize }} />
        default:
          return <AlertFilled style={{ color: "red", fontSize }} />
      }
    }

    const centrarMapa = (centro, max_zoom) => {
      const nuevo_zoom = defaultViewState.zoom + (max_zoom ? max_zoom : 3)
      setFocusOnMap(true)
      mapRef.current?.flyTo({center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000});
      setTimeout(() => {
        setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
      }, 2010);
    }

    const visualizacionAlertas  = () => {

      return <div className='mb-4'>
          <Card size="small" style={{ marginTop: 10, marginBottom: 10 }}>
          <Title className='mb-0 mt-0' level={5}><AlertFilled /> {total} alertas en total</Title>
          <Title className='mb-0 mt-0' level={4}>Se visualizan {alertas.length} alertas</Title>
          {resetMapView()}
          </Card>
        {
          alertas.map((alerta,pos) => {
            return <div key={alerta._id} >
              <span style={{ fontSize: 9 }} className='mb-0'>{fechaUTCATexto(alerta.createdAt)}</span>
              <Paragraph style={{ fontSize: 12, fontWeight: "bold" }} className='mt-0'>{alerta.mensaje}</Paragraph>
              <Button size="small" icon={<PushpinOutlined />} style={{ width: "100%", fontSize:10 }} onClick={() => centrarMapa({ longitude: alerta.location.coordinates[0], latitude: alerta.location.coordinates[1] }, 5)} >VER EN MAPA</Button>
              { alertas.length === (pos+1) ? false : <Divider className='mb-0' />  }
            </div>
          })
        }
      </div>
    }

    const resetMapView = () => {
      if(!focusOnMap) return false
      return <Button size="small" type="primary" onClick={() => {
        setFocusOnMap(false)
        ajustarMapaAGeocercas(coleccion.features)
      }}>VOLVER A MAPA COMPLETO</Button>
    }

    const abrirAlertasCondicionadas = (cond) => {
      setCondicionAlertas(cond)
      return setTimeout(() => {
        setShowModalAlertas(true)
      }, 200);
    }

    const handleCloseModal = () => {
      setShowModalAlertas(false)
    }

    const handleCancel = () => {
      setShowModalAlertas(false)
    }

    const modalAlertas = () => {
      return <Modal width={"80%"} open={showModalAlertas} footer={[]} onCancel={() => handleCloseModal()}>
        {JSON.stringify()}
          <ListadoAlertas condicion_default={condicionAlertas} />
    </Modal>
    }

    const visualizacionZonas = () => {
      return <div className='mb-4'>
        <Card size="small" style={{ marginTop: 10, marginBottom: 10 }}>
          <Title className='mb-0 mt-0' level={5}><AlertFilled style={{ color: "red" }} /> {geocercas.length} Zonas con alertas</Title>
          {resetMapView()}
          </Card>
        {
          geocercas.map((geo,pos) => {
            let titulo_zona = 'Sin información de la zona'
            if(geo.zona){
              if(typeof geo.zona === "object"){
                if(geo.zona.titulo) titulo_zona = geo.zona.titulo
              }
            }
            let coordenadas = []
            for( const arreglo of geo.geometry.coordinates ){
                if(Array.isArray(arreglo) !== false){
                  for( const item of arreglo ){
                    coordenadas.push({ latitude: item[1], longitude: item[0] })
                  }
                }
            }
            const centro = calcularPuntoCentral(coordenadas)
            return <div key={geo._id} className='mb-0' >
              <span style={{ fontSize: 9 }} className='mb-0'>Zona</span>
                <Paragraph style={{ fontSize: 17, fontWeight: "bold", marginBottom: 10, lineHeight: "10px" }} className='mt-0'>{geo._id ? false : <Spin style={{ marginRight: 5 }}/> } {titulo_zona.toString().toUpperCase()}</Paragraph>
                <Button size="small" icon={<PushpinOutlined />} style={{ width: "100%" }} onClick={() => centrarMapa(centro)} >VER EN MAPA</Button>
                <ModalAlertasZona condicion_default={{ id_zona: geo.id_zona }} />
                {modalAlertas()}
                { geocercas.length === (pos+1) ? false : <Divider className='mb-0' />  }
                </div>
          })
        }
      </div>
    }

    const visualizarDatos = () => {
      if(tipoVista === "alertas") return visualizacionAlertas()
      if(tipoVista === "zonas") return visualizacionZonas()
    }

    const mostrarListado = () => {
      if(loadingMaster) return <Skeleton active />
      if(geocercas.length < 1 ) return <div className='mb-3' style={{ padding: "15px 15px 0px 15px" }} ><Title className='mb-0 mt-0' level={4}>Sin datos</Title></div>

      const seleccionado = tipos_vista[tipos_vista.findIndex(t => t.tipo === tipoVista)].label
      return <div>
        {paginacion(data.pagina, total)}
        {visualizarDatos()}
        {paginacion(data.pagina, total)}
      </div>
    }

    const obtenerGeocercaBD = async (page, query, centrar, limit) => {
        setLoadingMaster(true)
        const condicion = query ? query : condicion_busqueda
      return fetch(`${urlapi}/geodata/alertas/geo/map/list`, {
        method: 'POST',
        body: JSON.stringify({ 
          condicion,
          pagina: page
         }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma,
          limit: limit ? limit : default_limit_page
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then(async (res) => {
          if (!res) {
              messageApi.error("Sin datos obtenidos")
          } else if (res.errorMessage) {
              messageApi.error(res.errorMessage)
          } else if(Array.isArray(res.datos) !== false){

            const extraer_geocercas = res.geocercas.map(ge => {
              ge.geometry = ge.region
              return ge
            })

            setAlertas(res.datos)
            setGeoCercas(extraer_geocercas)
            setTotal(res.total)
            setColeccion((prevCollection) => ({
              ...prevCollection,
              features: [...[], ...extraer_geocercas],
            }))
            if(centrar === true){
              ajustarMapaAGeocercas(extraer_geocercas)
            }
          }

          return setLoadingMaster(false)
        })
        .catch((error) => {
          messageApi.error("Error al consultar la información, intente nuevamente")
          return setLoadingMaster(false)
        })
    }

    const eliminarGeocerca = async (id) => {
      const instancia_geocercas = JSON.parse( JSON.stringify( geocercas ))
      const i = instancia_geocercas.findIndex(g => g._id === id)
      if(i > -1){
        geocercas[i].deleting = true
      } else {
        return false
      }
      return fetch(`${urlapi}/geodata/zonas/asociar?id=${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then(async (res) => {
          if (!res) {
              messageApi.error("Sin datos obtenidos")
          } else if (res.errorMessage) {
              messageApi.error(res.errorMessage)
          } else if(res._id){
            messageApi.success("Eliminado exitosamente")
              instancia_geocercas.splice(i,1)
              setColeccion((prevCollection) => ({
                ...prevCollection,
                features: [...[], ...instancia_geocercas],
              }))
              setGeoCercas([...[], ...instancia_geocercas])
          }
          return false
        })
        .catch((error) => {
          messageApi.error("Error al consultar la información, intente nuevamente")
          return false
        })
    }

    const guardarGeocercaBD = async (id, tipo, nuevo_arreglo) => {
      return fetch(`${urlapi}/geodata/zonas/asociar?id=${id}&tipo=${tipo}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then(async (res) => {
          if (!res) {
              messageApi.error("Sin datos obtenidos")
          } else if (res.errorMessage) {
              messageApi.error(res.errorMessage)
          } else if(res._id){
            messageApi.success("Guardado exitosamente")
            const i = nuevo_arreglo.findIndex(geo => geo.id_geocerca === id)
            if(i > -1 ){
              nuevo_arreglo[i]._id = res._id
              setGeoCercas([...[], ...nuevo_arreglo])
            }
          }
          return false
        })
        .catch((error) => {
          messageApi.error("Error al consultar la información, intente nuevamente")
          return false
        })
    }

    const onAgregarZona = (data) => {
      const buscar = geocercas.findIndex(geo => geo.id_geocerca === data._id)
      if(buscar > -1) return messageApi.error("Esta geocerca ya fue agregada")
        const nueva = {
            id_geocerca: data._id,
            titulo: data.titulo,
            type: "Feature",
            properties: data.properties,
            geometry: {
              type: data.region.type,
              coordinates: data.region.coordinates
            }
          }
          const nuevo_arreglo = [...geocercas, ...[nueva]]
          console.log({nuevo_arreglo})
          setGeoCercas(nuevo_arreglo)
          coleccion.features = nuevo_arreglo
          setColeccion((prevCollection) => ({
            ...prevCollection,
            features: [...prevCollection.features, nueva],
          }))
          guardarGeocercaBD(data._id, tipo_operacion, nuevo_arreglo)
    }

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerGeocercaBD(e, condicion_busqueda)
    }

    const sortBy = (data, type) => {
      if(type === "sort_config"){
          const {
              sort_column,
              sort_order
          } = data
          setPagina(1)
          obtenerGeocercaBD(1, null, null, { sort_column, sort_order })

      } else if( type === "limit_pagination" ){
          setPagina(1)
          obtenerGeocercaBD(1, null, null, data.limit_page)            
      }
  }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div style={{ marginTop: 15, marginBottom: 15 }}>
          <Row gutter={15} style={{ marginBottom: 20 }}>
            <Col span="auto"><SortBy type={modulo} onChange={(data, type) => sortBy(data, type)} /></Col>
          </Row>
            <Pagination showSizeChanger={false} defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const mostrarAcciones = () => {
      if(hideControls === true) return false
      return <div style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 20 }}>
      <Title level={4} className='mb-3 mt-0'>AGREGA ZONAS A LA ZONA DE OPERACIÓN</Title>
      <Row gutter={15}>
        <Col md={16}>
          <SelectorZona title="Zonas del país" condicion={{ }} onSelected={data => onAgregarZona(data)} />
        </Col>
          </Row>
    </div>
    }

    const mostrarAlertas = (marker) => {
      if(!marker) return false
      if(!marker.alertas) return false
      if(Array.isArray(marker.alertas) !== true) return false
      if(marker.alertas.length < 1) return <h4 className='mb-0 mt-0'>Sin datos</h4>
      return <div>
        {
          marker.alertas.map((alerta,i) => {
            return <div key={`alert-${i}`}>
              <span style={{ fontSize: 9 }}>{fechaUTCATexto(alerta.createdAt)}</span>
              <Paragraph >{alerta.mensaje}</Paragraph>
            </div>
          })
        }
      </div>
    }

    const mostrarPuntosAlertas = () => {
      if(!coleccion) return false
      if(typeof coleccion !== "object") return false
      if(!coleccion.features) return false
      if(Array.isArray(coleccion.features) !== true) return false

      return alertas.map(marker => {
        try {
          
            let longitude = 0
            let latitude = 0

            if(marker.location){
              if(typeof marker.location === "object"){
                if(marker.location.coordinates){
                  if(Array.isArray(marker.location.coordinates) !== false){
                    if(marker.location.coordinates.length > 1){
                      latitude = marker.location.coordinates[1]
                      longitude = marker.location.coordinates[0]
                    }
                  }
                }
              }
            }
            if(!latitude || !longitude) return false
            console.log({longitude, latitude})
          
            const content = (
              <div>
                <span>{fechaUTCATexto(marker.createdAt)}</span>
                <Paragraph className='mb-0'>{marker.mensaje}</Paragraph>
              </div>
              )
              return <Marker
                      key={`${marker._id}-alert`}
                      longitude={longitude}
                      latitude={latitude}
                  >
              <Popover content={content} title={tituloPorTipoNotificacion(marker.tipo_notificacion)}>
                {iconoPorTipoAlerta(marker.tipo_notificacion)}
              </Popover>
              </Marker>
          
        } catch (error) {
          console.log(error)
          return false
        }
      })
    }

    const mostrarPuntosGeoZonas = () => {
      return coleccion.features.map(marker => {
        try {
          let coordenadas = []
          for( const arreglo of marker.geometry.coordinates ){
              if(Array.isArray(arreglo) !== false){
                for( const item of arreglo ){
                  coordenadas.push({ latitude: item[1], longitude: item[0] })
                }
              }
          }
          const centro = calcularPuntoCentral(coordenadas)
          let titulo_zona = 'Zona sin información'

          if(marker.zona){
            if(typeof marker.zona === "object") if(marker.zona.titulo) titulo_zona = `Zona ${marker.zona.titulo}`
          }
          if(centro){
            const content = (
              <div>{mostrarAlertas(marker)}</div>
              )
              return <Marker
                      key={`${marker._id}-alert`}
                      longitude={centro.longitude}
                      latitude={centro.latitude}
                  >
              <Popover content={content} title={titulo_zona}>
              <div style={{ padding: 30, textAlign: "center" }}>
                <div style={{ height: 20 }}></div>
              <Paragraph style={{ backgroundColor: "#00000069", borderRadius: 5, padding: "0px 10px", color: "white" }}>{titulo_zona}</Paragraph>
              </div>
              </Popover>
              </Marker>
          }
        } catch (error) {
          console.log(error)
          return false
        }
      })
    }

    return <div style={{ height: "100%" }}>
      {contextHolder}
        <Row style={{ height: "100%" }} >
          <Col md={8} style={{ background: "white", padding: 20, height: "100%", overflowY: "scroll", overflowX: "hidden" }}>
            <Title className='mb-1 mt-0' level={3}>Mapa de alertas</Title>
            <HelperModal tipo="alertas" style="modal" />

            <Navigation />
            

          {mostrarListado()}
          </Col>
          <Col md={16} style={{ height: "100%" }}>
          <Map
            ref={mapRef}
            {...viewState}
            onMove={evt => setViewState(evt.viewState)}
            style={{width: "100%", height: "100%"}}
            scrollZoom={false}
            mapStyle="mapbox://styles/mapbox/light-v9"
            mapboxAccessToken={mapbox_token}
          >
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
                <Source
                        id={"geocerca._id"}
                        type="geojson"
                        data={coleccion}
                        >
                            {mostrarPuntosAlertas()}
                            {mostrarPuntosGeoZonas()}
                            <Layer
                                id="geocercas-layer"
                                type="line"
                                paint={{
                                    'line-width': 4,
                                    'line-color': '#0080ef'
                                  }}                      
                                />
                        </Source>
                            
                  
          </Map>
          </Col>
        </Row>

        
        
  </div>
}

export default MapaZonasAlertasMantenedor