import React from "react";
import { useState, useContext } from 'react';
import { TbCircleArrowRightFilled } from "react-icons/tb";
import { BiSolidHelpCircle } from "react-icons/bi";
import { estilo_moving_truck } from "../../lib/estilo_sitio";
import { Button, Col, Modal, Row } from "antd";
import Title from "antd/es/typography/Title";
import { url_images } from "../../lib/backend/data";

const LISTA_MODULOS = [
    {
        slug: "disponibilidad-recursos",
        title: "Encontrar vehículos",
        descripcion: "En este módulo podrás administrar los usuarios de tu empresa que pueden realizar acciones administrativas en la cuenta",
        image: `${url_images}/illustrations/business-competition.svg`
    },
    {
        slug: "usuarios",
        title: "Usuarios",
        descripcion: "En este módulo podrás administrar los usuarios de tu empresa que pueden realizar acciones administrativas en la cuenta",
        image: `${url_images}/illustrations/business-competition.svg`
    },
    {
        slug: "alertas",
        title: "Geo Alertas",
        descripcion: "Mantente informado en tiempo real y offline de donde del progreso en las rutas de tus vehículos, recibe notificaciones sobre los puntos de control por donde conduce tu flota",
        image: `${url_images}/illustrations/gps-navigator-with-pin-location.svg`
    },
    {
        slug: "vehiculos",
        title: "Flota de vehículos",
        descripcion: "Mantente informado en tiempo real y offline de donde del progreso en las rutas de tus vehículos, recibe notificaciones sobre los puntos de control por donde conduce tu flota",
        image: `${url_images}/illustrations/truck-loading.svg`
    },
    {
        slug: "equipos",
        title: "Equipos",
        descripcion: "Mantente informado en tiempo real y offline de donde del progreso en las rutas de tus vehículos, recibe notificaciones sobre los puntos de control por donde conduce tu flota",
        image: `${url_images}/illustrations/cargo-truck.svg`
    },
    {
        slug: "clientes",
        title: "Clientes",
        descripcion: "Mantente informado en tiempo real y offline de donde del progreso en las rutas de tus vehículos, recibe notificaciones sobre los puntos de control por donde conduce tu flota",
        image: `${url_images}/illustrations/financial-advisor-helping-clients-to-manage-their-finances-and-investments.svg`
    },
    {
        slug: "contactos",
        title: "Contactos",
        descripcion: "Administra las personas de valor dentro de las empresas a las que provees tus servicios",
        image: `${url_images}/illustrations/business-analysts-to-clients.svg`
    },
    {
        slug: "conductores",
        title: "Conductores",
        descripcion: "Administra de forma integral la información de los conductores de tu flota",
        image: `${url_images}/illustrations/delivery-person-following-address-route-in-maps-in-smartphone-for-delivering-the-parcel.svg`
    },
    {
        slug: "viajes",
        title: "Viajes",
        descripcion: "Aquí podrás gestionar todas tus órdenes de transporte, visualizar el estado, filtrar, ver reportes y exportar los datos para las tomas de desiciones.",
        image: `${url_images}/illustrations/truck-delivery-service.svg`
    },
    {
        slug: "automatizaciones",
        title: "Automatizaciones",
        descripcion: "Configura las rutas para tus órdenes y recibe notificaciones en cada punto de control, whatsapp, email y mucho más.",
        image: `${url_images}/illustrations/smart-logistics-app-for-delivery-tracking.svg`
    },
    {
        slug: "facturacion",
        title: "Facturación",
        descripcion: "Administra los gastos que genera tu flota y segmentalos como desees.",
        image: `${url_images}/illustrations/smart-logistics-app-for-delivery-tracking.svg`
    },
    {
        slug: "costos",
        title: "Centro de costos",
        descripcion: "Administra los gastos que genera tu flota y segmentalos como desees.",
        image: `${url_images}/illustrations/smart-logistics-app-for-delivery-tracking.svg`
    },
    {
        slug: "productos",
        title: "Productos",
        descripcion: "Administra tu maestro de productos y recibe reportes personalizados.",
        image: `${url_images}/illustrations/smart-logistics-app-for-delivery-tracking.svg`
    },
    {
        slug: "disponibilidades",
        title: "Buscar vehículos",
        descripcion: "Disponibiliza tu flota para que otras empresa puedan solicitar tus servicios.",
        image: `${url_images}/illustrations/smart-logistics-app-for-delivery-tracking.svg`
    },
    {
        slug: "disponibilidades-solicitudes",
        title: "Solicitudes de gestión de carga",
        descripcion: "Administra las solicitudes de gestión de carga que te llegan de otras empresas.",
        image: `${url_images}/illustrations/smart-logistics-app-for-delivery-tracking.svg`
    },
    {
        slug: "calificaciones",
        title: "Calificaciones",
        descripcion: "Accede a calificaciones que proveen los clientes de vehículos y empresas a nivel nacional.",
        image: `${url_images}/illustrations/smart-logistics-app-for-delivery-tracking.svg`
    },
]

const HelperModal = (props) => {
    const {
        tipo,
        style
    } = props
    const alto_imagen = 200
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    if(!tipo) return false
    const i = LISTA_MODULOS.findIndex(mo => mo.slug === tipo)
    if(i < 0) return false

    const info = LISTA_MODULOS[i]

    if(style === "modal") return <div>
        <div style={{  }}><Button type="dashed" onClick={() => setIsOpen(true)} size="small" icon={<BiSolidHelpCircle style={{ verticalAlign: "middle" }} />}> MÁS INFORMACIÓN</Button></div>
        <Modal open={isOpen} size="lg" onCancel={()=>handleClose()} footer={[]} >
        <div style={{ maxWidth: "100%", textAlign: "center", margin: "0 auto" }}>
        <Row>
                        <Col md={24} xs={24}>
                        { info.image ? <img src={info.image} style={{ height: alto_imagen }} /> : false }
                        <Title level={1} className=" mb-0 mt-0" style={{ }}>{info.title}</Title>
                        <Title level={2} className="mt-0 mb-3" style={{ fontWeight: 400, fontSize: 15 }}>{info.descripcion}</Title>
                        {
                            info.links ? <Row className='justify-content-center'>
                                {
                                    info.links.map((item,it) => {
                                        const styleIcon = { size: 30, color: estilo_moving_truck.colors.titles }
                                        const Icono = item.icon ? item.icon : false
                                        return <Col md={2} key={`item-${it}`} className='mb-3'>
                                                <Title style={{ fontSize: 12 }} className='mb-0 mt-2'><TbCircleArrowRightFilled /> {item.title}</Title>
                                        </Col>
                                    })  
                                }
                            </Row> : false
                        }
                        </Col>
                    </Row>
        </div>
      </Modal>
    </div>

    
    return <div className="text-center">
        <div style={{ maxWidth: "70%", margin: "0 auto", textAlign: "center" }}>
        <Row>
                        <Col md={24} xs={24}>
                        { info.image ? <img src={info.image} style={{ height: alto_imagen }} /> : false }
                        <Title level={1} className=" mb-0 mt-0" style={{ }}>{info.title}</Title>
                        <Title level={2} className="mt-0 mb-3" style={{ fontWeight: 400, fontSize: 15 }}>{info.descripcion}</Title>
                        {
                            info.links ? <Row className='justify-content-center'>
                                {
                                    info.links.map((item,it) => {
                                        const styleIcon = { size: 30, color: estilo_moving_truck.colors.titles }
                                        const Icono = item.icon ? item.icon : false
                                        return <Col md={2} key={`item-${it}`} className='mb-3'>
                                                <Title style={{ fontSize: 12 }} className='mb-0 mt-2'><TbCircleArrowRightFilled /> {item.title}</Title>
                                        </Col>
                                    })  
                                }
                            </Row> : false
                        }
                        </Col>
                    </Row>
        </div>
    </div>
};

export default HelperModal