import { Form, Select } from "antd"
import ListadoCalificaciones from "./listado"
import { useState } from "react"

const AdvanceFilterRating = (props) => {
    const { id } = props
    const condiciones_predefinidas = {
        todos: { $or: [
            { id_origin: id },
            { id_destination: id }
        ] },
        recibidas: { id_destination: id },
        generadas: { id_origin: id }
    } 
    const [ condicion, setCondicion ] = useState(condiciones_predefinidas.todos)

    const options = [
        { label: "Todos", value: "todos" },
        { label: "Calificaciones recibidas", value: "recibidas" },
        { label: "Calificaciones generadas", value: "generadas" }
    ]
    return <div>
        <Form layout="vertical">
            <Form.Item label="Selecciona el tipo de calificación">
                <Select options={options} onChange={e => setCondicion(condiciones_predefinidas[e])} />
            </Form.Item>
        </Form>
        <ListadoCalificaciones showFilters={false} hideSearch={true} condicion_default={condicion} />
    </div>

}

export default AdvanceFilterRating