import { Alert, AutoComplete, Button, Col, Form, Input, message, Row, Spin, Table, Tabs, Tag } from "antd";
import SinInformacion from "../../subComponents/general/sin_informacion";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { fechaATexto, fechaATextoSimpleExperimental, formatYMD_UTC } from "../../lib/helpers/helpers";
import { FaFileInvoice, FaRegStickyNote, FaRegTrashAlt } from "react-icons/fa";
import Column from "antd/es/table/Column";
import { LoadingOutlined } from "@ant-design/icons";
import { IoIosAttach, IoMdSend } from "react-icons/io";
import { formatoMoneda } from "../../lib/helpers/main";
import { MdOutlineNotes } from "react-icons/md";
import { IoShareSocialOutline } from "react-icons/io5";
import TagSinDatos from "../../subComponents/general/tag_sin_datos";
import ProductosVacios from "./productosVacios";


const VisualizarCotizacion = (props) => {
    const {
        quote
    } = props

    const footer = () => {
        const subtotal = quote.items.reduce((acc, item) => {
            const cantidad = parseFloat(item.cantidad) || 0;
            const precio = parseFloat(item.precio) || 0;
            const subtotal = cantidad * precio;
            return acc + subtotal;
        } , 0)
        const impuesto = quote.taxRate ? quote.taxRate : 19
        const factorImpuesto = 1 + (impuesto / 100);
        const valorImpuesto = impuesto / 100
        const total = subtotal * factorImpuesto
        const taxRate = quote.taxRate ? quote.taxRate : 19

        return <div style={{ fontWeight: "bold" }}>
        <Row>
            <Col md={12}>
            
                <Form.Item label={ <Title level={3} className="mt-0 mb-0"><FaRegStickyNote /> Notas </Title> }>
                { quote.notes ? <Paragraph>{quote.notes}</Paragraph> : <TagSinDatos /> }
                </Form.Item>
                
        </Col>
            <Col md={9} style={{ textAlign: "right" }}>
                <Paragraph><b>SUB TOTAL</b></Paragraph>
                <Paragraph><b>IMPUESTO {taxRate}%</b></Paragraph>
                <Paragraph><b>TOTAL</b></Paragraph>
            </Col>
            <Col md={3} style={{ textAlign: "right" }}>
                <Paragraph>{formatoMoneda(subtotal)}</Paragraph>
                <Paragraph>{formatoMoneda(subtotal * valorImpuesto)}</Paragraph>
                <Paragraph>{formatoMoneda(total)}</Paragraph>
            </Col>
        </Row>
    </div>
    }

    if(!quote) return <div style={{ textAlign: "center" }}><SinInformacion /></div>

    return <div>
        { quote.null === true ? <Alert className="mb-3" message="Cotización anulada" description="Esta cotización ha sido anulada, en ocasiones podemos anular una cotización mientras revisamos los detalles" type="error" showIcon /> : false }
        <Form layout="vertical">

        <Row gutter={15}>
            <Col md={6}>
                <Form.Item label="Número" className="mt-0">
                <b>{quote.id}</b>
                </Form.Item>
            </Col>        
            <Col md={6}>
                <Form.Item label="Creador" className="mt-0">
                <b>{quote.creador?.nombres}</b>
                </Form.Item>
            </Col>        
            <Col md={6}>
                <Form.Item label="Creación" className="mt-0">
                <b>{fechaATextoSimpleExperimental(quote.createdAt)}</b>
                </Form.Item>
            </Col>        
            <Col md={6}>
                <Form.Item label="Válido hasta" className="mb-0">
                    {quote.expiration_date ? <b>{fechaATextoSimpleExperimental(quote.expiration_date)}</b> : <TagSinDatos /> }
                </Form.Item>
            </Col>        
        </Row>
        
       
        <Table size="large"  className="mt-3" footer={footer} dataSource={quote.items} pagination={false} bordered  locale={{ emptyText: <div><Title level={4} className="mt-0 mb-0">Aún no hay items en esta cotización</Title></div> }} >
                <Column title="Título" render={(item,o,i) => {
                    return <div>
                        <Title level={5} className="mt-0 mb-0">{item.titulo}</Title>
                        </div>
                }} />
                <Column title="Descripción" render={(item,o,i) => {
                    return <div><Title level={5} className="mt-0 mb-0">{item.descripcion}</Title> </div>
                }} />
                <Column title="Cantidad" render={(item,o,i) => {
                    return <div><Title level={5} className="mt-0 mb-0">{formatoMoneda(item.cantidad)}</Title></div>
                }} />
                <Column title="Precio" render={(item,o,i) => {
                    return <div><Title level={5} className="mt-0 mb-0">{formatoMoneda(item.precio)}</Title></div>
                }} />
                <Column title="Sub total" render={(item) => {
                    
                    const cantidad = parseFloat(item.cantidad) || 0;
                    const precio = parseFloat(item.precio) || 0;
                    const subtotal = cantidad * precio;

                    return <div><Title level={5} className="mt-0 mb-0">{formatoMoneda(subtotal.toFixed(0))}</Title></div>
                }} />
        </Table>

        </Form>
    </div>
}

export default VisualizarCotizacion