import { useEffect, useRef, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { formatearRUTChileno, limpiarRUTChileno, validarRUTChileno } from "../../lib/helpers/data/chile";
import { mapbox_token, urlapi } from "../../lib/backend/data";
import guardarUsuario from "../../redux/actions/guardarUsuario";
import { Button, Card, Col, Form, Input, Row, message } from "antd";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { gradient_primary } from "../../lib/global/style";
import { limpiarStringLetrasNumeros } from "../../lib/helpers/helpers";
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios";
import { formatoAyudaDNIPorPais, nombreDNIPorPais } from "../../lib/helpers/data/internationa";


const CompletarRegistro = (props) => {
    mapboxgl.accessToken = mapbox_token
    const [ empresa, setEmpresa ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [ sedePrincipal, setSedePrincipal ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()
    let agregado = false
    let empresaRef = useRef(empresa)

    const handleGeocoderResult = (event) => {
        if (event.result && event.result.geometry && event.result.geometry.coordinates) {
          const [lng, lat] = event.result.geometry.coordinates;
          
            const sede = {
                direccion: event.result.place_name,
                geocode: event.result,
                tipo: "sede-principal",
                location: event.result.geometry,
                pais_code: pais,
                titulo: "Sede principal"
            }
            empresaRef.current.direccion = event.result.place_name
            console.log(empresaRef.current)
            setEmpresa(empresaRef.current)
            setSedePrincipal(sede)
        }
      };

      const agregarGeocoder = () => {
        if(agregado) return false
        agregado = true
        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false, // Desactivar el marcador de ubicación seleccionada
            placeholder: "Escribe la dirección y selcciona la opción",
            countries: pais,
          });
          geocoder.on('result', handleGeocoderResult);
          geocoder.addTo('#geocoderaddress')
      }

    useEffect(() => {
        agregarGeocoder()
    }, [])

    const handleChangeEmpresa = (e) => {
        const { name, value } = e.target
        empresa[name] = value
        if(name === "rut"){
            empresa[name] = limpiarStringLetrasNumeros(value)
        }
        empresaRef.current = empresa
        return setEmpresa({...{}, ...empresa})
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        const requeridos = [
            { value:'razon_social', label: 'Razón social' },
            { value:'rut', label: 'Rut' },
            { value:'direccion', label: 'Dirección' },
            { value:'cargo', label: 'Cargo' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!empresa[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)
        empresa.sede = sedePrincipal
        setLoading(true)
        const url = `${urlapi}/empresas`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(empresa),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                let copia_session = JSON.parse( JSON.stringify(session) )
                copia_session.data.empresa = res
                console.log(copia_session)
                dispatch(guardarUsuario(copia_session))
                return window.location = '/redirect/validate/business'
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    return <div>
        <Row style={{ height: "100vh" }}>
        <Col md={8} style={{ display: "flex", padding: 30, alignItems: "center" }}>
        <Form layout="vertical">
        <Title level={4}>Completa la información de tu empresa</Title>
        <Paragraph>Completar registro de empresa</Paragraph>
            <CamposObligatoriosLeyenda />
        <Row gutter={10}>
            <Col md={24} xs={24} >
            <Form.Item label="Nombre de la empresa" required style={{ marginBottom: 5 }}>
            <Input variant="filled" name="razon_social" onChange={handleChangeEmpresa} />
            </Form.Item>
            </Col>
            <Col md={24} xs={24}>
            <Form.Item label={`${nombreDNIPorPais(pais)}`} tooltip={formatoAyudaDNIPorPais(pais)} required style={{ marginBottom: 5 }}>
            <Input variant="filled" value={empresa.rut} id="rut_chileno" name="rut" onChange={handleChangeEmpresa} />
            </Form.Item>
            </Col>
            <Col md={24} xs={24}>
            <Form.Item label={"Cargo que desempeñas en la empresa"} required style={{ marginBottom: 5 }}>
            <Input variant="filled" name="cargo" onChange={handleChangeEmpresa} />
            </Form.Item>
            </Col>
            <Col md={24} xs={24}>
            <Form.Item label={"Dirección"} required style={{ marginBottom: 5 }}>
            <div style={{ width: "100%", marginBottom: 10 }} id="geocoderaddress"></div>
            </Form.Item>
            <Form.Item label={"Número de oficina o local"} style={{ marginBottom: 5 }}>
            <Input mb={3} className="mb-3"  variant="filled" name="direccion_2" onChange={handleChangeEmpresa} />
            </Form.Item>
            <Form.Item label={"Observaciones"} style={{ marginBottom: 5 }}>
            <Input mb={3} className="mb-3" variant="filled" name="observaciones" onChange={handleChangeEmpresa} />
            </Form.Item>
            </Col>
        </Row>
        
        
        <Button disabled={sedePrincipal ? false : true} loading={loading} style={{ width: "100%" }} type="primary" onClick={() => handleSubmit()} >GUARDAR</Button>
        </Form>
        </Col>
        <Col md={16} xs={0} style={{ background: gradient_primary, display: "flex", alignItems: "center" }}>
        <img style={{ width: "55%", margin: "0 auto"}} className='hidden-mobile' src="/images/4.png" />
        </Col>
        </Row>
        {contextHolder}
    </div>
}

export default CompletarRegistro