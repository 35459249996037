import { Button, Col, Divider, Form, Input, Modal, Row, Select, Spin, Switch, message } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import { SaveOutlined } from "@ant-design/icons";
import CargandoTabla from "./cargando";
import Title from "antd/es/typography/Title";
import { fechaATextoExperimental, fechaUTCATextoSimple, filterOption, formatYMD, formatYMD_UTC } from "../../lib/helpers/helpers";
import { campos_fecha, modulo, requeridos } from "./data";
import { FaTruckFront, FaTruckRampBox } from "react-icons/fa6";
import { FaChevronRight, FaInfoCircle, FaRegTrashAlt } from "react-icons/fa";
import ModalSeleccionVehiculo from "../Vehiculos/modal_seleccion";
import SelectorZonaAvanzadoInternacional from "../RadioOperacion/selector_zona_avanzado_internacional";
import { GiHandTruck } from "react-icons/gi";
import { DateTime } from "luxon";
import Paragraph from "antd/es/typography/Paragraph";
import { tipos_disponibilidad } from "../Disponibilidades/data";
import ListadoVehiculos from "../Vehiculos/listado";
import { MdOutlinePlace } from "react-icons/md";
// import MapaDetalleDisponibilidad from "./mapa_detalles_disponibilidad";
import { estilo_moving_truck } from "../../lib/estilo_sitio";
import VisualizarViaje from "../Viajes/visualizar";
import { stringByStatusSolicitud } from "../SolicitudesDisponibilidadesExternas/data";
import AccionesSolicitud from "../SolicitudesDisponibilidadesExternas/accionesSolicitud";
import SelectorGlobal from "../../subComponents/general/selector";

const VerSolicitudRapido = (props) => {
    const {
        id_registro
    } = props
    const [ campo, setCampo ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ vehiculos, setVehiculos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ estadoSeleccionado, setEstadoSeleccionado ] = useState(false)
    const [ loadingAgregado, setLoadingAgregado ] = useState(false)
    const [ estadosCanal, setEstadosCanal ] = useState([])
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ origenes, setOrigenes ] = useState([])
    const [ detallesViaje, setDetallesViaje ] = useState(false)
    const [ loadingEliminado, setLoadingEliminado ] = useState(false)
    const [ guardandoEstado, setGuardandoEstado ] = useState(false)
    const [ propietarioBusqueda, setPropietarioBusqueda ] = useState(session.data.empresa)
    const [ destinos, setDestinos ] = useState([])
    const dispatch = useDispatch()
    const module = modulo

    const guardarEstadoACanal = async (id) => {
        const i = estadosCanal.findIndex(estado => estado._id === id)
        setGuardandoEstado(id)
        return fetch(`${urlapi}/bodega/canal/estado`,{
            method: "PUT",
            body: JSON.stringify(estadosCanal[i]),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                const i = estadosCanal.findIndex(channel => channel._id === id)
            }
            return setGuardandoEstado(false)
        })
        .catch(error => {
            messageApi.error(error.message)
            setGuardandoEstado(false)
        })
    }


    const obtenerDatos = async () => {
        if(!id_registro) return messageApi.error("ID no válido")
        setLoading(true)
        return fetch(`${urlapi}/bodega/canal?id=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setCampo(res)
                if(res.vehiculos) setVehiculos(res.vehiculos)
                if(res.origenes) if(Array.isArray(res.origenes)) setOrigenes(res.origenes)
                if(res.destinos) if(Array.isArray(res.destinos)) setDestinos(res.destinos)
                if(res.orden){
                    setDetallesViaje(res.orden)
                }
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    const guardarCambios = async () => {
        let guardar_campo = {...{}, ...campo}
        let faltantes = []
        requeridos.map(field => {
            if(!guardar_campo[field.value]) faltantes.push(field.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  

        setLoadingSave(true)
        guardar_campo.vehiculos = vehiculos
        
        return fetch(`${urlapi}/${module}`,{
            method:'PUT',
            body: JSON.stringify(guardar_campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                setIsModalOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoadingSave(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
      obtenerDatos()
    };

    const handleOk = () => {
        return guardarCambios()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

      const eliminarEstadoACanal = async (id) => {
        setLoadingEliminado(id)
        return fetch(`${urlapi}/bodega/canal/estado?id=${id}`,{
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Eliminado exitosamente")
                const i = estadosCanal.findIndex(channel => channel._id === id)
                if(i > -1){
                    estadosCanal.splice(i,1)
                    setEstadosCanal(prev => [...[], ...estadosCanal])
                }
            }
            return setLoadingEliminado(false)
        })
        .catch(error => {
            messageApi.error(error.message)
            setLoadingEliminado(false)
        })
    }

    const agregarEstadoACanal = async () => {

        const i = estadosCanal.findIndex(estado => estado.id_estado === estadoSeleccionado._id)
        if(i > -1) return messageApi.error("Ya se encuentra asignado")

        let guardar = JSON.parse(JSON.stringify(estadoSeleccionado))
        delete guardar._id
        guardar.id_canal = id_registro
        guardar.id_estado = estadoSeleccionado._id
        guardar.propietario = propietarioBusqueda.rut
        guardar.cliente = propietarioBusqueda.razon_social
        setLoadingAgregado(true)
        return fetch(`${urlapi}/bodega/canal/estado`,{
            method: "POST",
            body: JSON.stringify(guardar),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                setEstadosCanal(prev => [...prev, ...[res]])
            }
            return setLoadingAgregado(false)
        })
        .catch(error => {
            messageApi.error(error.message)
            setLoadingAgregado(false)
        })
    }


      const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = { ...prev }
            actual[name] = value
            return { ...actual, ...{} }
        })
    }

    const mostrarEsquemaEstados = () => {
        if(estadosCanal.length < 1) return false

        const empresas = []

        for( const registro of estadosCanal){
            const i = empresas.findIndex(empresa => empresa.rut === registro.propietario)
            if(i < 0){
                empresas.unshift({
                    rut: registro.propietario,
                    titulo: registro.cliente,
                    estados: [
                        registro
                    ]
                })
            } else {
                if(!empresas[i].titulo) empresas[i].titulo = registro.cliente
                empresas[i].estados.unshift(registro)
            }
        }

        return <div className='mt-3'>
            <h4 className='mb-2'>{estadosCanal.length} Estados asignados a este canal</h4>
            <hr className='mb-2 mt-0' />
            {
                empresas.map((empresa,iemp) => {
                    return <div key={`empresa-${iemp}`}>
                        <h4 className='mb-2'><i class="fas fa-arrow-circle-right"></i> {empresa.titulo}</h4>
                        <div className='pl-3'>
                        {
                            empresa.estados.map((estado,iestado) => {
                                return <div className='card p-3 mb-3' key={estado._id}>
                                    <Row>
                                        <Col md={16}><Title level={5} className="mb-0 mt-0"><i style={{ color: estado.color ? estado.color: 'black' }} class="fas fa-circle"></i> {estado.titulo}</Title></Col>
                                        <Col md={8} style={{ textAlign: "right" }}>{ loadingEliminado === estado._id ? <Spin animation='border' /> : <Button type="dashed" size="small" danger onClick={() => eliminarEstadoACanal(estado._id)} icon={<FaRegTrashAlt style={{ verticalAlign: "middle" }} />} > ELIMINAR</Button> }</Col>
                                    </Row>
                                </div>
                            })
                        }
                        </div>
                    </div>
                })
            }
        </div>
    }


    const formulario = () => {
        if(loading) return <CargandoTabla />

        return <div>
            <Form layout="vertical">
            <Row gutter={15} >
                    {/* <Col md={24}>
                        <Form.Item label="Estado" className="mb-2">
                        <Select options={[{ value: 'active', label: "Activo" },{ value: "inactive", label: "Inactivo" }]} showSearch filterOption={filterOption} defaultValue={!campo.status ? "" : campo.status} name="status" onChange={(e) => handleChangeSelect("status", e)} />
                        </Form.Item>
                    </Col> */}
                    <Col md={12}>
                        <Form.Item label="Título" required className="mb-3">
                        <Input name="titulo" value={campo.titulo} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Descripción" required className="mb-3">
                        <Input name="descripcion" value={campo.descripcion} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Title level={4} className="mt-0 mb-0">Asignar app conductor</Title>
                        <Paragraph style={{ fontSize: 12 }} >Asignar órdenes al conductor a través en App Conductor</Paragraph>
                        <Form.Item className="mb-2" required>
                            <Switch checkedChildren="SI" defaultChecked={campo.asignar_app === true} unCheckedChildren="NO" onChange={(e) => handleChange({ target: { name:"asignar_app", value: e } })} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Title level={4} className="mt-0 mb-0">Asignar app conductor</Title>
                        <Paragraph style={{ fontSize: 12 }} >Las órdenes se reasignarán a una nueva ruta, saldrán de la ruta actual</Paragraph>
                        <Form.Item className="mb-2" required>
                            <Switch checkedChildren="SI" defaultChecked={campo.crear_ruta === true} unCheckedChildren="NO" onChange={(e) => handleChange({ target: { name:"crear_ruta", value: e } })} />
                        </Form.Item>
                    </Col>
                    <Col md={24}>
                        <Title level={4} className="mt-0 mb-0">Estados asignados a este canal</Title>
                        <Paragraph style={{ fontSize: 12 }} >Estos son los estados que se disparan al confirmar órdenes dentro de este canal.</Paragraph>
                        <Title level={4} className="mt-0 mb-0"><FaInfoCircle style={{ verticalAlign: "middle" }} /> Una vez creado el canal podrás asignar estado</Title>
                        <Row gutter={15}>
                            <Col md={16}><SelectorGlobal style={{ width: "100%" }} titulo="Estado de carga"  condicion={{ }} module="protocolos" key_label="titulo" key_value="_id" onChange={(data) => setEstadoSeleccionado(data)} /></Col>
                            <Col md={8}>
                                <Paragraph className="mb-2">Click para agregar</Paragraph>
                                <Button  type="primary" disabled={ estadoSeleccionado ? false : true } loading={loadingAgregado} onClick={() => agregarEstadoACanal()}  >AGREGAR</Button>
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
                    {mostrarEsquemaEstados()}
            </Form>
        </div>
    }
    
    return <div>
        <Button size="small" className="m-0" onClick={showModal}>DETALLES</Button>
        <Modal 
            width="80%"
            title="Detalles" 
            open={isModalOpen} 
            onOk={() => guardarEstadoACanal()} 
            onCancel={handleCancel}
            confirmLoading={loading}
            footer={[]}
        >
        {formulario()}
      </Modal>
      {contextHolder}
    </div>
}

export default VerSolicitudRapido