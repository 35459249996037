import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  Appointments,
  AppointmentTooltip,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import { useState, useEffect } from "react";
import { Col, Divider, message, Modal, Row, Spin } from "antd";
import { fechaATextoSimpleExperimental, formatYMD_UTC } from "../../lib/helpers/helpers";
import { DateTime } from "luxon";
import { urlapi } from "../../lib/backend/data";
import { getMonthRange, obtenerRangos } from "../../lib/helpers/dates";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { MdOpenInNew, MdOutlinePlace } from "react-icons/md";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import { FaTruck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import VisualizarViaje from "../../components/Viajes/visualizar";

const AgendaRecurso = (props) => {
  const { condicion_default } = props;
  const [events, setEvents] = useState([]);
  const [ signal, setSignal ] = useState(false)
  const [ eventoSeleccionado, setEventoSeleccionado ] = useState(false)
  const [ viajeSeleccionado, setViajeSeleccionado ] = useState(false)
  const [ loadingViaje, setLoadingViaje ] = useState(false)
  const [loading, setLoading] = useState(false);
  const [ viajes, setViajes ] = useState([])
  const [currentDate, setCurrentDate] = useState(formatYMD_UTC(DateTime.now().startOf('day').toISO()));
  const [currentMonth, setCurrentMonth] = useState(DateTime.now().month);
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch()
  const session = useSelector(state => state.miusuario)
  const locale = "es-ES";

  useEffect(() => {
    const dates = getMonthRange(currentMonth)
    const cond = { ...condicion_default, fecha_retiro_desde: { $gte: dates.firstDate, $lte: dates.lastDate } }
    obtenerDatos(cond)
  },[])

  const mostrarViaje = () => {
    if(loadingViaje) return <Spin size="large" />
    if(!viajeSeleccionado) return false
    return <div>
      <VisualizarViaje orden={viajeSeleccionado} />
    </div>
  }

  const mostrarModalEvento = () => {

    if(!eventoSeleccionado) return false

    return <Modal
            width="80%"
            title={`VIAJE ${eventoSeleccionado.title}`}
            open={eventoSeleccionado ? true : false} 
            onCancel={() => {
                if(signal) if(signal.abort) signal.abort()
                setEventoSeleccionado(false)
                setViajeSeleccionado(false)
            }}
            footer={[]}
        >
          {mostrarViaje()}
        {/* <Title level={3} className="mt-0 mb-0"><FaTruck style={{ verticalAlign: "middle" }} /> {vehiculos.length} Vehículos disponibles</Title> */}
    </Modal>
}


  const obtenerDatosViaje = async (id) => {
    setLoadingViaje(true)
    const controller = new AbortController();
    setSignal(controller)
    return fetch(`${urlapi}/viajes?id=${id}`,{
        signal: controller.signal,
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            messageApi.error('Sin datos')
        } else if(res.errorMessage){
            messageApi.error(res.errorMessage)
        } else if(res.viaje){
            setViajeSeleccionado(res)
        }
        return setLoadingViaje(false)
    })
    .catch(error => {
        messageApi.error("Error al consultar la información, intente nuevamente")
        return setLoadingViaje(false)
    })
}


const obtenerDatos = async (cond) => {
    setLoading(true)
    return fetch(`${urlapi}/vehiculos/agenda/list`,{
        method:'POST',
        body: JSON.stringify({
          condicion: cond
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            messageApi.error('Sin datos')
        } else if(res.errorMessage){
            messageApi.error(res.errorMessage)
        } else if(Array.isArray(res)){
          const eventos = res.map(vi => {
            const fecha = {...vi, startDate: DateTime.fromISO(vi.fecha_retiro_desde).toJSDate(), endDate: DateTime.fromISO(vi.fecha_retiro_desde).plus({ minutes: 30 }).toJSDate(), title: vi.internal_id }
            return fecha
          })
          console.log(eventos)
          setViajes(eventos)
        }
        return setLoading(false)
    })
    .catch(error => {
        console.log(error.message)
        return setLoading(false)
    })
}


  // Detecta el cambio de mes
  const handleDateChange = (newDate) => {
    const newMonth = DateTime.fromJSDate(newDate).month;
    if (newMonth !== currentMonth) {
      const dates = getMonthRange(newMonth)
      const cond = { ...condicion_default, fecha_retiro_desde: { $gte: dates.firstDate, $lte: dates.lastDate } }
      obtenerDatos(cond)
      setCurrentMonth(newMonth);
    }
    setCurrentDate(newDate);
  };


  const AppointmentContent = ({ data, ...restProps }) => (
    <Appointments.AppointmentContent {...restProps} data={data}>
      <div style={{ fontSize: 11 }}>
        <div><b>VIAJE {data.title}</b></div>
        {/* <Row gutter={10}>
            <Col span="auto"><div style={{ fontSize: 12 }}><MdPlace style={{ verticalAlign: "middle" }} /> {data.places}</div></Col>
            <Col span="auto"><div style={{ fontSize: 12 }}><FaTruckFront style={{ verticalAlign: "middle" }} /> {data.vehiculos.length} Vehículos</div></Col>
            <Col span="auto"></Col>
        </Row> */}
      </div>
    </Appointments.AppointmentContent>
  );

  const handleAppointmentClick = (appointmentData) => {
    obtenerDatosViaje(appointmentData._id)
    setEventoSeleccionado(appointmentData)
}



  const Appointment = ({ children, style, data, ...restProps }) => {
    return <Appointments.Appointment
    {...restProps}
    style={{
      ...style,
      backgroundColor: data.color,
      color: 'white', // Asegura que el texto sea visible
      borderRadius: '8px', // Agrega un poco de estilo para diferenciar las citas
      padding: '5px', // Asegura que haya espacio suficiente para el texto

    }}
    onClick={() => handleAppointmentClick(data)}
  >
    {/* {children} */}
    <AppointmentContent data={data} />
  </Appointments.Appointment>
};

  return (
    <div>
      {contextHolder}
      <Spin spinning={loading} size="large" >
      <Scheduler data={viajes} locale={locale} height={660}>
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={handleDateChange}
          defaultCurrentViewName="Month"
        />
        <DayView startDayHour={0} endDayHour={24} displayName="DÍA" />
        <WeekView startDayHour={0} endDayHour={24} displayName="SEMANA" />
        <MonthView startDayHour={0} endDayHour={24} displayName="MES" />
        <Toolbar />
        <DateNavigator />
        <TodayButton messages={{ today: "Hoy" }} />
        <ViewSwitcher />
        <Appointments appointmentComponent={Appointment} />
        <AppointmentTooltip />
      </Scheduler>
      </Spin>
      {mostrarModalEvento()}
    </div>
  );
};

export default AgendaRecurso