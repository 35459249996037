import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Button, Card, Divider, Tabs } from 'antd';
import ListadoConductores from "./listado";
import { FileExcelFilled, PlusCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import ListadoAlertas from "./listado";
import HelperModal from "../../subComponents/general/helperModal";
import Navigation from "../Mapas/alertas/navigation";

const Alertas = (props) => {

    const items = [
        {
          key: '1',
          label: `NOTIFICACIÓN`,
          children: <ListadoAlertas condicion_default={{ tipo_notificacion: "info" }} />,
        },
        {
          key: '2',
          label: `ADVERTENCIAS`,
          children: <ListadoAlertas condicion_default={{ tipo_notificacion: "warning" }} />,
        },
        {
          key: '3',
          label: `CRITICAS`,
          children: <ListadoAlertas condicion_default={{ tipo_notificacion: "error" }} />,
        },
      ];

    const render = () => {
        return <div>
            <Card>
            <Title className="mb-2 mt-0">Alertas</Title>
            <HelperModal tipo="alertas" style="modal" />
            <Navigation />
            <div className="mt-3"><ListadoAlertas /></div>
            </Card>
        </div>
    }
    
    return <Structure component={render()} />
}

export default Alertas