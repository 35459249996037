import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import data, { urlapi } from '../../../lib/backend/data'
// import {  Form, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillSignal } from 'react-icons/ai' 
import Select from 'react-select';
import { formatYMD } from '../../../lib/helpers/helpers';
import { DateTime } from 'luxon';
import SelectorEstados from '../../estados_carga/selector';
import BuscadorVehiculos from '../../conductores/buscador.js';
import TablaEstadosCarga from '../../estados_carga/detail_table';
import { cerrarSesion } from '../../../redux/actions/sesion.js';
import PantallaCargando from '../../general/pantalla_cargando.js';
import { Alert, Button, Card, Col, Collapse, Input, Pagination, Row } from 'antd';
import Title from 'antd/es/typography/Title.js';
import Structure from '../../../components/Structure/index.js';
import { modificarInterfaz } from '../../../redux/actions/interfaz.js';
import Paragraph from 'antd/es/typography/Paragraph.js';
import SinInformacion from '../../general/sin_informacion.js';

const ListadoEstadosCargaTiempoReal = (props) => {
    const {
        condicion_default,
        iterar_conductores
    } = props
    const condicion = condicion_default ? condicion_default : {}
    const maximo_historial = 20
    const [ estados, setEstados ] = useState([])
    const [ loadingEstados, setLoadingEstados ] = useState(true)
    const [ proveedores, setProveedores ] = useState([])
    const [ conductores, setConductores ] = useState([])
    const [ loadingProtocolo, setLoadingProtocolo ] = useState(true)
    const [ filtroProtocolo, setFiltroProtocolo ] = useState([])
    const [ opcionesProtocolo, setOpcionesProtocolo ] = useState(null)
    const [ protocolo, setProtocolo ] = useState(null)
    const [ cargadorMas, setCargarMas ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [fechaFiltro, setFechaFiltro] = useState(formatYMD(DateTime.now().toUTC().toISO()))
    const token = session.tokenSession
    const interfaz_usuario = useSelector(state => state.esquema_tablas)
    const [ tipoBusqueda, setTipoBusqueda ] = useState(interfaz_usuario?.type_search_audit_statuses ? interfaz_usuario.type_search_audit_statuses : 'protocolo')
    const [mostrarConfiguracion, setMostrarConfiguracion] = useState(false);
    const [filtroEstado, setFiltroEstado] = useState([])
    const [states, setStates] = useState([])
    const key_busqueda_bd = 'codigo_subestado'
    const dispatch = useDispatch()
    const [condicionBusqueda, setCondicionBusqueda] = useState(condicion)
    const [ keyReset, setKeyReset ] = useState(1)
    const selectProtocol = useRef()
    const selectEstados = useRef()
    const [columnas, setColumnas] = useState({
        Estado: true,
        SubEstado: true,
        Creado: true,
        Canal: true,
        Referencia: true,
        Destinatario: true,
        Fecha: true,
        Hora: true,
        Ruta: true,
        RutaProveedor: true,
        Proveedor: true,
        Otif: true,
        Tracking: true,
        Tickets: true
      });

    const handleSwitchChange = (columna) => {
    setColumnas({
        ...columnas,
        [columna]: !columnas[columna]
    });
    };

    const handleChangeSelectEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroEstado(opciones)
    }

    const getEstados = async (condicion_nueva, page, first_load) => {
        const cond = condicion_nueva ? condicion_nueva : condicionBusqueda
        // if(paginar === true) setCargarMas(true)
        setCargarMas(true)
        const url = `${data.urlapi}/estadoscarga/list-v2`;
        return fetch(url,{
            method:'POST',
            body: JSON.stringify({
                // offset,
                limit: maximo_historial,
                condicion: cond,
                iterar_conductores,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(pros => {
            // if(paginar === true) setCargarMas(false)
            return pros.json()}
        )
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
            } else if(pros.errorMessage){
                toast.error(`Ocurrió un error inesperado: ${pros.errorMessage}`, )
            } else if(Array.isArray(pros.datos) !== false){
                let allow = true
                if(first_load === true){
                    if(interfaz_usuario?.default_statuses_filter) if(Array.isArray(interfaz_usuario.default_statuses_filter)) if(interfaz_usuario.default_statuses_filter.length > 0){
                        allow = false
                    }
                }
                if(allow){
                    setEstados(pros.datos)
                    setTotal(pros.total)
                    if(pros.empresas) if(Array.isArray(pros.empresas)) if(pros.empresas.length > 0) {
                        setProveedores(prev => {
                            let actual = [ ...prev ]
                            for( const veh of pros.empresas){
                                const i = actual.findIndex(e => e.rut === veh.rut)
                                if(i < 0) actual.push(veh)
                            }
                            return actual
                        })
                    }
                    if(pros.conductores) if(Array.isArray(pros.conductores)) if(pros.conductores.length > 0) {
                        setConductores(prev => {
                            let actual = [ ...prev ]
                            for( const veh of pros.conductores){
                                const i = actual.findIndex(e => e._id === veh._id)
                                if(i < 0) actual.push(veh)
                            }
                            return actual
                        })
                    }
                }
            }
            setLoadingEstados(false)
            setCargarMas(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoadingEstados(false)
            setCargarMas(false)
        })
    }

    const obtenerEstados = () => {
        return fetch(`${data.urlapi}/estadoscarga/details`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            if(!data){

            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return this.setState({ loadingFormulario: false })
            } else if( Array.isArray(data.estados) !== false){
                const items = data.estados.map(e => ({ value: e.codigo_estado, label: e.titulo }))
                setStates(items)
                verificarEstadosDefault()
            }
            return setLoadingEstados(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información")
            return setLoadingEstados(false)
        })
    }

    const filtrar = (filters_protocolo) => {
        let cond = { ...condicionBusqueda }

        const procotol_filter = filters_protocolo ? filters_protocolo : filtroProtocolo

        if(procotol_filter.length > 0){
            const encontrar = protocolo.detalles.modulos.filter(e => procotol_filter.includes(e.nombre))
            if(encontrar.length > 0){
                const condiciones = encontrar.reduce((prev,next) => {
                    return [...prev, ...next.options.map(e => e.codigo_estado)]
                }, [])
                cond[key_busqueda_bd] = { $in: condiciones }
            }
        }
        const fechaFiltro_desde = DateTime.fromISO(fechaFiltro).startOf('day').toUTC().toISO()
        const fechaFiltro_hasta = DateTime.fromISO(fechaFiltro).endOf('day').toUTC().toISO()
        const condicion = { ...condicionBusqueda, createdAt: { $gte: fechaFiltro_desde, $lte: fechaFiltro_hasta }  }
        setCondicionBusqueda(condicion)
        getEstados(cond, 1)
    }

    const verificarProtocoloDefault = (res) => {
        setTimeout(() => {
            if(res?.detalles) if(res.detalles?.modulos) if(Array.isArray(res.detalles.modulos)){
                if(interfaz_usuario?.default_protocol_filter) if(Array.isArray(interfaz_usuario.default_protocol_filter)) {
                        console.log({ valores: [ ], select: selectProtocol?.current })
                        if(selectProtocol?.current) {
                            const valores = interfaz_usuario.default_protocol_filter
                            selectProtocol.current.setValue(valores)
                        }
                }
            }
        }, 200);
    }
    const verificarEstadosDefault = () => {
        setTimeout(() => {
            if(interfaz_usuario?.default_statuses_filter){
                if(selectEstados?.current) selectEstados?.current.setValue(interfaz_usuario.default_statuses_filter)
                // default_value = interfaz_usuario.default_statuses_filter.map(e => e.value)
            }
        }, 200);
    }
  
    const obtenerProtocolo = async (modulo)=>{
        setLoadingProtocolo(true)
        return fetch(`${urlapi}/configuracion?tipo=logistica&subtipo=torre-control`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setProtocolo(res)
                let options = []
                if(res?.detalles) if(res.detalles?.modulos) if(Array.isArray(res.detalles.modulos)){
                    for( const modulo of res.detalles.modulos){
                        options.push({ value: modulo.nombre, label: modulo.nombre })
                    }
                }
                setOpcionesProtocolo(options)
                verificarProtocoloDefault(res)
            }
            return setLoadingProtocolo(false)
        })
        .catch(error => {
            return setLoadingProtocolo(false)
        })
    }


    useEffect(() => {
        const fechaFiltro_desde = DateTime.fromISO(fechaFiltro).startOf('day').toUTC().toISO()
        const fechaFiltro_hasta = DateTime.fromISO(fechaFiltro).endOf('day').toUTC().toISO()
        const condicion = { ...condicionBusqueda, createdAt: { $gte: fechaFiltro_desde, $lte: fechaFiltro_hasta }  }
        setCondicionBusqueda(condicion)
        getEstados(condicion, 1, true)
        obtenerEstados()
        obtenerProtocolo()
    }, [])

    const onDelete = (id) => {
        const i = estados.findIndex(e => e._id === id)
        if(i < 0) return false
        estados.splice(i,1)
        return setEstados([...[], ...estados])
    }

    const obtenerPedido = (id) => {
        if(props.obtenerPedido) return props.obtenerPedido(id)
        return false
    }

    const handleChangeAudit = (e, key) => {
        const val = e.target.checked
        const estado = e ? e._id : null
                    setCondicionBusqueda(prev => {
                        let actual = {...prev}
                        if(val === true){
                            actual[key] = true
                            delete actual.id_subestado
                            setKeyReset(keyReset + 1)
                        } else {
                            delete actual[key]
                        }
                        getEstados(actual, 1)
                        return {...actual}
                    })
    }

    const refrescarRegistros = (fecha) => {
        setFechaFiltro(fecha)
        const fechaFiltro_desde = DateTime.fromISO(fecha).startOf('day').toUTC().toISO()
        const fechaFiltro_hasta = DateTime.fromISO(fecha).endOf('day').toUTC().toISO()
        const condicion = { ...condicionBusqueda, createdAt: { $gte: fechaFiltro_desde, $lte: fechaFiltro_hasta }  }
        setCondicionBusqueda(condicion)
        return getEstados(condicion, false)
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        getEstados(null, page)
    }

    const handleChangePagina = (e) => {
        setPagina(e)
        getEstados(condicionBusqueda, e)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination showSizeChanger={false} defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const guardarPreferenciaProtocolo = estados => {
        const normailzado = estados
        const instancia_esquema = JSON.parse(JSON.stringify(interfaz_usuario))
        instancia_esquema.default_protocol_filter = normailzado
        return dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
    }

    const guardarPreferenciaEstados = estados => {
        const normailzado = estados.map(e => ({ value: e._id, label: e.titulo }))
        const instancia_esquema = JSON.parse(JSON.stringify(interfaz_usuario))
        instancia_esquema.default_statuses_filter = normailzado
        return dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
    }

    const mostrarRegistros = () => {
        if(cargadorMas) return <PantallaCargando />
        if(estados.length < 1) return  <div className='text-center text-primary p-3'>
            <SinInformacion />
        </div>

        return <div className="table-responsive">
        <TablaEstadosCarga estados={estados} />
        </div>
    }

    const mostrarOpcionesProtocolo = () => {
        if(tipoBusqueda !== "protocolo") return false
        
        return <Col md={8} xs={12} className='mb-2'>
            <label className='form-control-label'>Protocolo de notificación</label>
            <Select ref={selectProtocol} isClearable isMulti placeholder="Selecciona para filtrar protocolo" options={opcionesProtocolo} onChange={(e) => {
                const valores = e.map(e => e.value)
                guardarPreferenciaProtocolo(e,'')
                setFiltroProtocolo(valores)
                filtrar(valores)
            }} />
        </Col>
    }

    const personalizacionColumnas = () => {


        const items = [
            {
                label: <div>
                    <div><i className="fa-solid fa-circle-info"></i> Personaliza las columnas con la información que quieres ver en la tabla</div>
                    <div><i className="fa-solid fa-sort"></i></div>
                </div>,
                children: <div>
                    <div className='d-flex text-primary'>
                    {Object.keys(columnas).map((columna) => (
                    <table className='px-2 mb-0'>
                        {/* <Form.Check 
                        style={{fontSize:12}}
                        type="switch"
                        id={`switch-${columna}`}
                        label={columna}
                        checked={columnas[columna]}
                        onChange={() => handleSwitchChange(columna)}
                        className='d-flex align-items-center'
                        /> */}
                    </table>
                    ))}
                </div>
                </div>
            }
        ]

        return <div>
            <Collapse items={items} accordion />
        </div>
    }

    // if(loadingEstados) return <LoadingAppStatuses />

    const render = () => {
        let default_value = []
    if(interfaz_usuario?.default_statuses_filter){
        default_value = interfaz_usuario.default_statuses_filter.map(e => e.value)
    }

    let opciones = [{ value: 'protocolo', label: 'Por protocolo' }, { value: 'estado', label: 'Por estado' }]
    let seleccionado = null
    if(tipoBusqueda) seleccionado = opciones.find(e => e.value === tipoBusqueda)

    return <div>
        {/* {personalizacionColumnas()} */}
        <Row className='mb-3' gutter={15}>
            <Col md={4} xs={24} className='mb-2'>
                <Paragraph className='mb-3'>Fecha de consulta</Paragraph>
                <Input disabled={cargadorMas} type="date" className='form-control' value={fechaFiltro} onChange={(e) => refrescarRegistros(e.target.value)} />
            </Col>
            <Col md={4} xs={24} className='mb-2'>
                <Paragraph className='mb-3'>Tipo de búsqueda</Paragraph>
                <Select placeholder="Selecciona" options={opciones} value={seleccionado} onChange={(e) => {
                    const valor = e.value
                    setTipoBusqueda(valor)
                    const instancia_esquema = JSON.parse(JSON.stringify(interfaz_usuario))
                    instancia_esquema.type_search_audit_statuses = valor
                    dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
                    if(valor === "protocolo"){
                        verificarProtocoloDefault(protocolo)
                    }
                }} />
            </Col>
            { tipoBusqueda === "estado" ? <Col md={8} xs={12}>
            <SelectorEstados defaultValue={default_value} isMulti={true} key={keyReset} tipo_dato="subestados" full_payload={true} titulo="Seleccionar sub estado" onChange={(e) => {
                    guardarPreferenciaEstados(e)
                    const estado = e.length > 0 ? { $in: e.map(estatus => estatus.codigo_estado) } : null
                    setCondicionBusqueda(prev => {
                        let actual = {...prev }
                        let key = key_busqueda_bd
                        if(estado){
                            actual[key] = estado
                        } else {
                            delete actual[key]
                        }
                        getEstados(actual, 1)
                        return {...actual}
                    })
            }} />
            </Col> : false }
            
            {mostrarOpcionesProtocolo()}
            <Col md={6} className='mb-2'>
            <Paragraph className='mb-3'>Conductor</Paragraph>
            <BuscadorVehiculos extraOptions={conductores.map(e => ({ value: e._id, label: `${e.nombres}${e.apellidos ? ` ${e.apellidos}` : ''}${e.patente ? ` ${e.patente}` : ''}` }))} placeholderText="Filtra por conductor" hideLabel={true} isMulti={true} token={token} onChange={(data) => {
                setCondicionBusqueda(prev => {
                    let actual = { ...prev }
                    if(data.length > 0){
                        actual.id_creador = { $in: data.map(e => e._id) }
                    } else {
                        delete actual.id_creador
                    }
                    getEstados(actual, 1, null)
                    return {...actual}
                })
            }} />
            </Col>
            {
                proveedores.length > 0 ? <Col md={5}>
                <label className='form-control-label'>Proveedor</label>
                <Select isClearable placeholder="Selecciona para filtrar proveedor" options={proveedores.map(e => ({ value: e.rut, label: e.razon_social }))} onChange={(e) => {
                    setCondicionBusqueda(prev => {
                        let actual = { ...prev }
                        if(e){
                            actual.rut_proveedor = e.value
                        } else {
                            delete actual.rut_proveedor
                        }
                        getEstados(actual, 1, null)
                        return {...actual}
                    })
                }} />
                </Col> : false
            }
            <Col xs={12}>
            <Button loading={cargadorMas} size="middle" type="primary" onClick={() => filtrar()}>ACTUALIZAR</Button>
            </Col>
            
        </Row>
    
        {/* <Alert className='mb-3' message="Esta pantalla funciona en tiempo real" /> */}
        {paginacion(data.pagina, total)}
        {mostrarRegistros()}
    
    </div>
    }

    return <Structure component={render()} />

}

export default ListadoEstadosCargaTiempoReal