import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Card, Modal } from "antd"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { TEXT_LANG } from "../../lib/lang/main"
import { BsCardChecklist } from "react-icons/bs"
import { botones } from "../../lib/global/data"
import ListadoContactos from "./listado"
import ListadoRequisitosModelos from "./esquema_agregar"

const ModalSeleccionRequisitos = (props) => {
    const {
        slug,
        icon,
        typeView,
        boton,
        condicion_busqueda
    } = props
    const pais = useSelector(state => state.pais)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
      };
      
    const onSelect = (data) => {
        setIsModalOpen(false)
        if(props.onSelect) props.onSelect(data)
    }

    const mostrarBoton = () => {
        if(!boton) return <div>
        <Card size="small" className=" hover" onClick={() => setIsModalOpen(true)} >
        { icon ? icon : <BsCardChecklist size={20} />}
        <Title level={5} className="m-0" >{TEXT_LANG(botones[slug]?.label, pais)}</Title>
        </Card>
        </div>

        return <div onClick={() => setIsModalOpen(true)}>
            {boton}
        </div>
    }

    const visualizacionTipoModal = () => {
        return <div>
            <Modal
            width="80%"
            footer={[]}
            title={TEXT_LANG(botones[slug]?.label, pais)}
            open={isModalOpen} 
            onCancel={handleCancel}
        >
        <ListadoRequisitosModelos show_filter={true} onSelect={(data) => onSelect(data)} condicion_default={condicion_busqueda} />
        </Modal>
        {mostrarBoton()}
        </div>
    }

    const visualizarDatos = () => {
        if(typeView==="modal") return visualizacionTipoModal()
        return <div></div>
    }

    return <div>
        {visualizarDatos()}
    </div>
}

export default ModalSeleccionRequisitos