import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Button, Card, Modal } from "antd"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { TEXT_LANG } from "../../lib/lang/main"
import { BsCardChecklist } from "react-icons/bs"
import { botones } from "../../lib/global/data"
import ListadoContactos from "./listado"
import { TbProgressAlert } from "react-icons/tb"
import { PiCursorClickFill } from "react-icons/pi"

const ModalSeleccionContactos = (props) => {
    const {
        slug,
        icon,
        typeView,
        condicion_busqueda
    } = props
    const pais = useSelector(state => state.pais)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
      };

      const seleccionarRegistro = (data) => {
        if(props.onSelect) props.onSelect(data)
        // relacionar(data)
        setIsModalOpen(false)
        // setSeleccionado(data)
    }

    const visualizacionTipoModal = () => {
        return <div>
            <Modal
            width="80%"
            footer={[]}
            title={TEXT_LANG(botones[slug]?.label, pais)}
            open={isModalOpen} 
            onCancel={handleCancel}
        >
        <Title level={5} className="mt-0 mb-3"><PiCursorClickFill /> Toca el título para seleccionar uno</Title>
        <ListadoContactos typeView="funcion" onSelect={data => seleccionarRegistro(data)} hideActions={true} condicion_default={condicion_busqueda} />
        </Modal>
        <Button type="primary" className="mt-3 mb-3" onClick={() => setIsModalOpen(true)} icon={<TbProgressAlert />} >EMITIR CANAL</Button>
        </div>
    }

    const visualizarDatos = () => {
        if(typeView==="modal") return visualizacionTipoModal()
        return <div></div>
    }

    return <div>
        {visualizarDatos()}
    </div>
}

export default ModalSeleccionContactos