const tipos_campos_personalizados = [
    { value: "viaje", label: "Crear orden" },
]

const tipos_ingreso = [
    { value: "", label: "Escritura" },
    { value: "seleccion", label: "Seleccion" },
]

module.exports = {
    tipos_campos_personalizados,
    tipos_ingreso
}