import { Button, Form, Input, Modal, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import { PlusCircleFilled, SaveOutlined } from "@ant-design/icons";
import CargandoTabla from "./cargando";

const EditarNuevoCampoPersonalizado = (props) => {
    const {
        id_registro
    } = props
    const [ campo, setCampo ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(true)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const slug_module                   = 'categorias'

    const obtenerDatos = async () => {
        if(!id_registro) return messageApi.error("ID no válido")
        setLoading(true)
        return fetch(`${urlapi}/${slug_module}?id=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setCampo(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    const guardarCambios = async () => {
        if(!campo.title) return messageApi.error("Escribe un título")
        if(campo.title.length < 3) return messageApi.error("Escribe un título válido")
        setLoadingSave(true)
        return fetch(`${urlapi}/${slug_module}`,{
            method:'PUT',
            body: JSON.stringify(campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                setIsModalOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoadingSave(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
      obtenerDatos()
    };

    const handleOk = () => {
        return guardarCambios()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }


    const handleChangeSelect = (name, value) => {
        campo[name] = value
        return setCampo(campo)
    }
    
    const formulario = () => {
        if(loading) return <CargandoTabla />

        return <div>
            <Form layout="vertical">
                <Form.Item label="Nombre">
                    <Input name="title" defaultValue={campo.title} onChange={handleChange} />
                </Form.Item>
                <Button type="primary" loading={loadingSave} onClick={() => guardarCambios()} ><SaveOutlined />GUARDAR CAMBIOS</Button>
            </Form>
        </div>
    }
    
    return <div>
        <Button  className="mb-3" type="link" onClick={showModal}>EDITAR</Button>
        <Modal 
            title="Crear nueva categoría" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
            footer={[]}
        >
        {formulario()}
      </Modal>
      {contextHolder}
    </div>
}

export default EditarNuevoCampoPersonalizado