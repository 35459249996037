import { message, Spin, Tag } from "antd"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { MdOutlinePlace, MdPlace } from "react-icons/md"
import { DateTime } from 'luxon'
import { FaLocationCrosshairs } from "react-icons/fa6"
import { FaCircle } from "react-icons/fa"
import { urlapi } from "../../lib/backend/data"

type idVehiculo = string

interface MyComponentProps {
    id_vehiculo: idVehiculo
}

const UbicacionVehiculo: React.FC<MyComponentProps> = ({ id_vehiculo }) => {
    const [ loading, setLoading ] = useState(false)
    const [ ubicacion, setUbicacion ] = useState(null)
    const session                               = useSelector((state) => state.miusuario)
    const [ messageApi, contextHolder]          = message.useMessage();

    const consultarUbicacion = async ()=>{
        if(!id_vehiculo) return
        setLoading(true)
        return fetch(`${urlapi}/vehiculos/ubicacion`,{
            method:'POST',
            body: JSON.stringify({
                id: id_vehiculo
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session?.tokenSession}`,
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res?.place_id){
                setUbicacion(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar ubicación")
            return setLoading(false)
        })
    }


    useEffect(() => {
        consultarUbicacion()
    }, [ id_vehiculo ])

    const diffDateInMinutes = (date) => {
        if(!date) return false
        const now = DateTime.now()
        const fecha = DateTime.fromISO(date)
        const diff = now.diff(fecha, ['minutes']).toObject()
        const minutos = Math.round(diff.minutes) 
        return minutos < 15 ? <p style={{ display: "inline" }}><FaCircle color="green" /> <b>Conectado ahora</b></p> : false
    }

    const mostrarUbicacion = () => {
        if(!ubicacion) return <div><FaLocationCrosshairs /> Ubicación no disponible</div>

        const fecha = DateTime.fromISO(ubicacion.updatedAt)
        const tiempo = diffDateInMinutes(ubicacion.updatedAt)
        return <div>
            <Tag className="mb-3">{ tiempo ? tiempo : <div style={{ display: "inline" }}><MdPlace /> Última ubicación {fecha.toRelative({ base: DateTime.now() })}</div> }  en <b>{ubicacion?.formatted_address}</b></Tag>
        </div>
    }   
    return <div>
        { loading ? <Spin /> : mostrarUbicacion() }
    </div>
}

export default UbicacionVehiculo