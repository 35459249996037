import { useCallback, useEffect, useState } from "react"
import PantallaCargando from "../general/pantalla_cargando"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { debounce } from "@mui/material"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import { Card, Col, Divider, Form, Row, Spin, Switch } from "antd"
import { cerrarSesion } from "../../redux/actions/sesion"
import Paragraph from "antd/es/typography/Paragraph"
import Title from "antd/es/typography/Title"
import TextArea from "antd/es/input/TextArea"

const PersonalizarEtiqueta = (props) => {
    const estructura = {
        modulos: [
            {
                nombre: "Logotipo",
                slug: "logotipo",
                activo: true,
                allowPhoto: true
            },
            {
                nombre:"Código QR",
                slug: "qr",
                activo: true,
            },
            {
                nombre: "Resumen",
                slug: "resumen",
                activo: true,
                onlyRead: true
            },
            {
                nombre: "Código de barras",
                slug: "barcode",
                activo: true
            }
        ],
        campos: [
            {
                nombre: "ID",
                slug: "id",
                activo: true
            },
            {
                nombre: "Referencia",
                slug: "referencia",
                activo: true
            },
            // {
            //     nombre: "Teléfono",
            //     slug: "telefono",
            //     activo: true
            // },
            // {
            //     nombre: "Dirección",
            //     slug: "direccion",
            //     activo: true
            // },
            {
                nombre: "Descripción producto",
                slug: "producto",
                activo: true
            },
            {
                nombre: "Indicador de bulto",
                slug: "bulto",
                activo: true
            },
        ]
    }

    const [ id, setId ] = useState("")
    const [ estructuraActual, setEstructuraActual ] = useState(estructura)
    const [ loading, setLoading ] = useState(false)
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const gris = '#e5e5e5'
    const sub_tipo = "etiqueta"

    useEffect(() => {
        obtenerDatos(sub_tipo)
    }, [])

    const obtenerDatos = async (modulo)=>{
        setLoading(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'POST',
            body: JSON.stringify({
                tipo: "personalizacion",
                sub_tipo: modulo,
                detalles: estructura
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setId(res._id)

                for( const modulo of estructura.modulos){
                    const i = res.detalles.modulos.findIndex(mod => mod.slug === modulo.slug)
                    if(i > -1){
                        let crear_modulo = res.detalles.modulos[i]
                        Object.keys(modulo).forEach(key => {
                            if(typeof crear_modulo[key] === "undefined") crear_modulo[key] = modulo[key]
                        })
                        console.log(crear_modulo)
                        res.detalles.modulos[i] = crear_modulo
                    } else {
                        res.detalles.modulos.push(modulo)
                    }
                }

                setEstructuraActual(res.detalles)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }
    
    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const handleChangeExtra = (e, type, campo) => {
        const valor = e.target.value
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                 setPendienteGuardar(true)
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].extra = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    const handleCheck = (e, type, campo) => {
        const valor = e
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                if(estructuraActual.modulos[i].onlyRead === true) return toast.warn("Este módulo no puede ser desactivado")
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].activo = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        } else if(type === "campos"){
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            if( i_ > -1 ){
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.campos[i_].activo = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const subirFoto = (url, name, type, campo) => {
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].imagen = url
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const opcionesImagen = (type, campo) => {
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                if(estructuraActual.modulos[i].allowPhoto === true){
                    return <Col className="mt-3">
                        <Paragraph className="mt-0 mb-0">Imagen</Paragraph>
                        {/* <CargaImagenes onUploaded={(url, name) => subirFoto(url, name, type, campo)} /> */}
                        { estructuraActual.modulos[i].imagen ? <div className="mt-3" style={{ backgroundImage: `url(${estructuraActual.modulos[i].imagen})`, display: "inline-block", width: 80, height: 80, borderRadius: 10, verticalAlign: "middle", backgroundPosition: "center center", backgroundSize: 'cover' }} ></div> : false }
                    </Col>
                }
            }
        }
    }

    const mostrarBoton = (type, campo) => {
        if(type === "modulo"){
            let activo = false
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            let titulo  = ""
            let extra   = ""
            let onlyRead = false
            if(i > -1) {
                titulo      = estructuraActual.modulos[i].nombre
                extra       = estructuraActual.modulos[i].extra
                onlyRead    = estructuraActual.modulos[i].onlyRead === true
                if(estructuraActual.modulos[i].activo === true){
                    activo = true
                }
            }
            return <div>
                <Row gutter={15}>
                    <Col md={15}><Title level={4} style={{ fontWeight: "bold", color: estilo_moving_truck.colors.primary }} className="mb-0 mt-0">{titulo}</Title></Col>
                    <Col md={9} style={{ textAlign: "right" }}>
                    {/* <Form.Group key={`${type}-${campo}`} className="mb-1" controlId={`${type}-${campo}`}>
                        <Form.Check type="switch" disabled={onlyRead} checked={activo} label={ activo === true ? "Activo" : "Inactivo"} onChange={(e) => handleCheck(e,type,campo)} />
                    </Form.Group> */}
                    <Switch checked={activo} disabled={onlyRead} onChange={(e) => handleCheck(e,type,campo)} />
                    </Col>
                    <Col xs={24}>
                        <Paragraph >Contenido extra al final del módulo</Paragraph>
                        <TextArea className="form-control" value={extra} onChange={(e) => handleChangeExtra(e,type, campo)}></TextArea>
                    </Col>
                    {opcionesImagen(type, campo)}
                </Row>
            </div>
        } else if(type === "campos"){
            let activo = false
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            let titulo = ""
            let onlyRead = false
            if(i_ > -1) {
                titulo = estructuraActual.campos[i_].nombre
                onlyRead = estructuraActual.campos[i_].onlyRead === true
                if(estructuraActual.campos[i_].activo === true){
                    activo = true
                }
            }
            return <div>
                <Form.Item label={titulo} className="mb-0">
                <Switch checked={activo} disabled={onlyRead} onChange={(e) => handleCheck(e,type,campo)} />
                </Form.Item>
            </div>
        }
    }

    if(loading) return <PantallaCargando />

    return <div>
        <Row gutter={15}>
            <Col><p>Actualiza la visualización por módulo y por campos </p></Col>
            <Col className="text-right text-secondary"><p>{pendienteGuardar ? <b>Pendiente de guardar cambios</b> : <b className="text-success" style={{ fontWeight: "bold" }}>¡Guardado!</b> } { loadingGuardado ? <Spin size="sm" animation="border" variant="primary" /> : false }</p></Col>
        </Row>
        
        <Row gutter={15}>
            <Col xs={14}>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3  mb-4">
                    {mostrarBoton('modulo',"logotipo")}
                </Card>
            </Col>
            <Col xs={10}>
                <Card style={{ border: "none", height: "90%", backgroundColor: gris }} className="p-3  mb-4">
                    {mostrarBoton('modulo',"qr")}
                </Card>
            </Col>
            <Col xs={24}>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3  mb-4">
                    {mostrarBoton('modulo',"resumen")}
                    <Divider className="mt-3 mb-3" />
                    {mostrarBoton('campos',"id")}
                    {mostrarBoton('campos',"referencia")}
                    {mostrarBoton('campos',"producto")}
                    {mostrarBoton('campos',"bulto")}
                </Card>
            </Col>
            <Col xs={24}>
                <Card style={{ border: "none", backgroundColor: gris }} className="p-3  mb-4">{mostrarBoton('modulo',"barcode")}</Card>
            </Col>
        </Row>
        
    </div>
}

export default PersonalizarEtiqueta