import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Card, Col, Form, Input, Pagination, Popconfirm, Row, Table, Tooltip, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { fechaATextoSimple } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import EditarNuevoCampoPersonalizado from "./editar"
import { FileExcelFilled, SearchOutlined } from "@ant-design/icons"
import ButtonGroup from "antd/es/button/button-group"
import { Esquema } from "../../lib/esquemas/tablas"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import { IoMdRemoveCircle } from 'react-icons/io'
import { TbHelpCircleFilled } from "react-icons/tb"
import { modificarInterfaz } from "../../redux/actions/interfaz"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import { main_data } from "./data"

const ListadoSubCategorias = (props)=> {
    const {
        condicion_default,
        hideSearch
    } = props
    const dispatch = useDispatch()
    const sesion                    = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [deleting, setDeleting]   = useState(false)
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ]     = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ]       = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const pais                      = useSelector(state => state.pais)
    const session                   = useSelector(state => state.miusuario)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const idioma                    = useSelector(state => state.idioma)
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const trash                     = props.trash ? props.trash : false
    const tipo                      = props.tipo ? props.tipo : "link"
    const titulo                    = props.title ? props.title : 'registros'
    const key_interfaz              = main_data.key_interfaz
    const key_esquema               = main_data.key_esquema
    const slug_module               = main_data.slug_module

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/subcategory`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const obtenerRegistros = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${slug_module}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    const eliminarRecurso = async (id) => {
        setDeleting(true)
        return fetch(`${urlapi}/${slug_module}?id=${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(res._id){
              messageApi.success("Eliminado exitosamente")
              obtenerRegistros(1, false)
            }
            return setDeleting(false)
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setDeleting(false)
          })
      }
  

    useEffect(() => {
        obtenerRegistros(1, condicion_default)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerRegistros(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination showSizeChanger={false} defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const activarDesactivarTabla = (value, column) => {
        const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario) )
        const i = instancia_esquema[key_interfaz].findIndex(e => e.key === column.llave)
        if(i > -1){
            instancia_esquema[key_interfaz][i].active = value
        }
        console.log({ instancia_esquema, i, column })
        return dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
    }

    const EncabezadoPesonalizado = (model) => {

        const { label, helptext } = model

        const object_delete = <Tooltip title={"No mostrar columna"}><IoMdRemoveCircle style={{ verticalAlign: "middle" }} size={20} className="hover" onClick={() => activarDesactivarTabla(false, model)} /></Tooltip>

        if(helptext) {
            return <div>
            <Tooltip title={helptext}>
            <Paragraph className="mb-0 mt-0">{label} <TbHelpCircleFilled /></Paragraph>
            </Tooltip>
            {object_delete}            
            </div>
        }

        return <>
            <Paragraph className="mb-0 mt-0">{object_delete} {label}</Paragraph>
            
        </>
    }
    
    const obtenerDetallesEsquema = (slug) => {
        const i = Esquema[ key_esquema ].findIndex(e => e.key === slug)
        if( i > -1){
            return {
                ...Esquema[ key_esquema ][i],
                llave: Esquema[ key_esquema ][i].key
            }
        }
        return {}
    }

    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />

        const component_title = <Column title={<EncabezadoPesonalizado {...obtenerDetallesEsquema("title") }  />} render={(data) => {
            return <div>{data.title}</div>
        }} />

        const component_categoria = <Column title={<EncabezadoPesonalizado {...obtenerDetallesEsquema("id_categoria") }  />} render={(data) => {
            return <div>{data.categoria}</div>
        }} />


        return <div>
            {paginacion(data.pagina, total)}
            <Table dataSource={conductores} pagination={false} >
                
                { encontrarLlaveValorEnEsquema("title", interfaz_usuario[ key_interfaz ]) === false ? false : component_title }
                { encontrarLlaveValorEnEsquema("id_categoria", interfaz_usuario[ key_interfaz ]) === false ? false : component_categoria }
                
                <Column title="Editar" render={(data) => {
                    return <div><EditarNuevoCampoPersonalizado id_registro={data._id} onCreate={() => obtenerRegistros(1, false)} /></div>
                }} />
                <Column title="Eliminar" render={(data) => {
                    return <div>
                        <Popconfirm
                    title="Eliminar"
                    description="¿Estás seguro que deseas eliminar recurso? Esta acción no se puede deshacer"
                    onConfirm={() => eliminarRecurso(data._id)}
                    onCancel={() => false}
                    okText="SI"
                    style={{ display: "inline-block"}}
                    cancelText="NO"
                  >
                  <Button loading={deleting} size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
                  </div>
                }} />
            </Table>
        </div>
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setCondicionBusqueda(condicion_default);
            setPagina(1);
            return obtenerRegistros(1, condicion_default);
        }
        const condicion = {
            ...condicion_default,
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerRegistros(1, condicion);
      };

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

    const buscador = () => {
        if(hideSearch === true) return false
        return <Col span="auto"><Form onSubmitCapture={handleSubmit}>
                    <Row gutter={15}>
                        <Col md={14}>
                            <Form.Item label="Buscar">
                                <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Buscar por palabras clave`} />
                            </Form.Item>
                        </Col>
                        <Col md={10}>
                            <Form.Item>
                                <Button onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
        </Col>
      }

    return <div>
        {contextHolder}
        <Row gutter={15}>
            {buscador()}
            <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
            <Col span="auto"><Button className="mb-3" disabled ><FileExcelFilled /> DESCARGAR PDF INFORMATIVO</Button></Col>
            <Col span="auto"><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green"  onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col>
        </Row>
        
        {mostrarRegistros()}
    </div>
}
export default ListadoSubCategorias