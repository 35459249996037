// const modificarInterfaz = objeto => {
//     return {
//         type: 'modificarInterfaz',
//         payload: objeto
//     }
// }
import { urlapi } from "../../lib/backend/data"

const modificarEstado = objeto => {
    return {
        type: "modificarEsquema",
        payload: objeto
    }
}

export const modificarEsquemaPlano = objeto => {
    return {
        type: "modificarEsquema",
        payload: objeto
    }
}

export const reiniciarEsquema = objeto => {
    return {
        type: "reiniciarEsquema",
        payload: objeto
    }
}

export const inicializarInterfaz = objeto => {
    return async dispatch => dispatch(modificarEstado(objeto))
}

export const modificarInterfaz = (objeto, token) => {
    return async dispatch => {
        dispatch(modificarEstado(objeto))
        const url = `${urlapi}/configuracion/interfaz`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(objeto),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => res.json())
        .then(res => res)
        .catch(error => error)
    }
}
