import React, { useState } from "react";
import { message, Button, Modal, Card, Row, Col, Avatar } from "antd";
import { AntDesignOutlined, ArrowDownOutlined, MobileOutlined, PlusOutlined } from "@ant-design/icons"
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";

const ModalButtonListado = (props) => {
  const {
    defaultValue,
    showSelection,
    exclude,
    hideSeleccionados,
    condicion_default,
    label,
    ComponenteListado, 
    tituloText, 
  } = props;
  const [seleccionado, setSeleccionado] = useState(defaultValue ? defaultValue : false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ condicion, setCondicion ] = useState(condicion_default ? condicion_default : {})
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const removerSeleccion = () => {
    if (props.onRemove) props.onRemove();
    return setSeleccionado(false);
  };

  const mostrarSeleccionado = () => {
    if (hideSeleccionados === true) return false;
    if (!seleccionado) return false;

    return (
      <Card size="small" style={{ marginTop: 20 }}>
        <Row>
          <Col md={20} xs={18}>
            <Paragraph className="mb-0">Conductor seleccionado</Paragraph>
            <Title level={4} className="mb-0 mt-0">
              {seleccionado.nombres} {seleccionado.apellido_p} {seleccionado.apellido_m}{" "}
              <Link target="_blank" to={`${rutas.drivers.slug}/${seleccionado._id}`}>
                <BsBoxArrowInUpRight />
              </Link>
            </Title>
            <Paragraph className="mb-0">
              <MobileOutlined /> {seleccionado.phone}
            </Paragraph>
            <Button onClick={() => removerSeleccion()}>REMOVER</Button>
          </Col>
          <Col md={4} xs={6} style={{ textAlign: "right" }}>
            <Avatar
              src={seleccionado.imagen_perfil}
              size={{ xs: 80, sm: 70, md: 30, lg: 64, xl: 80, xxl: 100 }}
              icon={<AntDesignOutlined />}
            />
          </Col>
        </Row>
      </Card>
    );
  };

  const seleccionarRegistro = (data) => {
    if (props.onSelect) props.onSelect(data);
    setIsModalOpen(false);
    setSeleccionado(data);
  };

  return (
    <div>
      <Button type="dashed"  onClick={showModal}  icon={<PlusOutlined />}>
        {label || "SELECCIONAR CONDUCTOR"}
      </Button>
      {mostrarSeleccionado()}
      <Modal width={"80%"} open={isModalOpen} footer={[]} onCancel={handleCancel}>
        <Title level={2} className="mt-0 mb-0">
          {tituloText || "Listado"}
        </Title>
        <ComponenteListado condicion_default={condicion ? condicion : {}} onSelect={(data) => seleccionarRegistro(data)} />
      </Modal>
    </div>
  );
};

export default ModalButtonListado;
