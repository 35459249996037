import Map, { FullscreenControl, GeolocateControl, Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { geojson_rm } from '../../lib/global/data';
import { estilo_moving_truck } from '../../lib/estilo_sitio';
import bbox from '@turf/bbox';

const MapaDetalleDisponibilidad = (props) => {
    const {
        origenes,
        destinos
    } = props
    const origenes_ = []
    const destinos_ = []
    if(origenes) if(Array.isArray(origenes)){
            for( const dato of origenes ){
                origenes_.push({
                    type: "Feature",
                    geometry: {
                        type: dato.region.type,
                        coordinates: dato.region.coordinates
                      }
                })
            }
    }
    if(destinos) if(Array.isArray(destinos)){
        for( const dato of destinos ){
            destinos_.push({
                type: "Feature",
                geometry: {
                    type: dato.region.type,
                    coordinates: dato.region.coordinates
                    }
            })
        }
    }
    const [ marcadores, setMarcadores ] = useState([])
    const sesion = useSelector(state => state.miusuario)
    const [viewState, setViewState] = useState({
        longitude: -70.670112,
        latitude: -33.447471,
        zoom: 10
      });
    const [ zoomInit, setZoomInit ] = useState(false) 
    const [ origen, setOrigen ] = useState({
        type: 'FeatureCollection',
        features: origenes_
      })
    const [ destino, setDestino ] = useState({
        type: 'FeatureCollection',
        features: destinos_
      })
    const mapRef = useRef()

    const extraerCoordenadas = (instancia_geocercas) => {
        const coordinates = instancia_geocercas.reduce((acc, geofence) => {
            const geofenceCoords = geofence.geometry.coordinates;
            if (geofenceCoords.length === 1) {
              // Polygon
              const filtradas_validas = geofenceCoords[0].filter(g => {
                if(Array.isArray(g) !== true) return false
                if(g.length < 2) return false
                if(g.filter(cc => !cc).length > 0) return false
                return true
              })
              acc.push(filtradas_validas);
            } else {
              // MultiPolygon
              geofenceCoords.forEach((coords) => {
                if(coords.length < 1) return false
                acc.push(coords[0]);
              });
            }
            return acc;
          }, [])

          return coordinates
    }

    const ajustarMapaAGeocercas = () => {

        const origins = extraerCoordenadas(origen.features)
        const destinations = extraerCoordenadas(destino.features)
        let coordinates = [ ...origins, ...destinations ]

          const combinedFeatures = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Polygon',
                  coordinates,
                },
              },
            ],
          };
  
            const bounds = bbox(combinedFeatures);
            console.log({ bounds })
            const newViewport = {
              ...viewState,
              latitude: (bounds[1] + bounds[3]) / 2,
              longitude: (bounds[0] + bounds[2]) / 2
            };
      
            const options = {
              padding: 30 // Ajusta el valor de padding según tus necesidades
            };
      
            setViewState(newViewport);
            setTimeout(() => {
              mapRef.current?.fitBounds(bounds, options)
            }, 300);
      }
  

    useEffect(() => {
        ajustarMapaAGeocercas()
    },[ origenes, destinos ])

    return <div>
        <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: 300}}
    // pitch={65}
    mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
  >
    <GeolocateControl position="top-left" />
    <FullscreenControl position="top-left" />
    <NavigationControl position="top-left" />
    <Source
            key={"origen"}
            id={"origen"}
            type="geojson"
            data={origen}
          >

            <Layer
              id="geocercas-origen"
              type="line"
              paint={{
                'line-width': 4,
                'line-color': 'orange'
              }}
            />
          </Source>
    <Source
            key={"destino"}
            id={"destino"}
            type="geojson"
            data={destino}
          >

            <Layer
              id="geocercas-destino"
              type="line"
              paint={{
                'line-width': 4,
                'line-color': estilo_moving_truck.colors.blue
              }}
            />
          </Source>
  </Map>
    </div>
}

export default MapaDetalleDisponibilidad