import React, { Component, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { userService } from '../../services/user.service'
import { validateEmail } from '../../lib/helpers/helpers'
import { Link, Navigate } from 'react-router-dom'
import guardarUsuario from '../../redux/actions/guardarUsuario'
import { Button, Col, Divider, Form, Input, Row, Spin, message } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Title from 'antd/es/typography/Title'
import Paragraph from 'antd/es/typography/Paragraph'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { gradient_primary } from '../../lib/global/style'
import { cambiarPais } from '../../redux/actions/pais'
import { validarDNIBrasil } from '../../lib/helpers/data/brasil'
import { rutas } from '../../lib/routes/routes'
import { inicializarInterfaz } from '../../redux/actions/interfaz'

const Login = (props) => {
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()
    dispatch(guardarUsuario(false))

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        
        if (!email)     return messageApi.error("Email es requerido")
        if (!password)  return messageApi.error("Contraseña es requerida")
        
        if(validateEmail(email) !== true) return messageApi({
            title: "Email inválido",
            status: "error"
        })
        setLoading(true)
        userService.login(email, password, { pais, idioma } )
            .then(
                user => {
                    if(!user){
                        messageApi.error("Sin datos obtenidos")
                    } else if(user.errorMessage){
                        messageApi.error(user.errorMessage)
                    } else if (user.tokenSession) {
                        if (user.configuracion_interfaz) {
                            if (typeof user.configuracion_interfaz === "object") {
                                if (user.configuracion_interfaz.detalles) {
                                    if (typeof user.configuracion_interfaz.detalles === "object") {
                                        dispatch(inicializarInterfaz(user.configuracion_interfaz.detalles))
                                    }
                                }
                            }
                        }
                        dispatch(cambiarPais(user.data.pais_code))
                        dispatch(guardarUsuario(user))
                    }
                    return setLoading(false)
                },
                error => {
                    return setLoading(false)
                }
            )
    }

    const handleChangeEmail = (e) => {
        const { value } = e.target
        setEmail(value)
    }

    const handleChangePassword = (e) => {
        const { value } = e.target
        setPassword(value)
    }

    
    return <div >
        {contextHolder}
        <Row style={{ height: "100vh" }}>
        <Col md={8} style={{ display: "flex", padding: 30, alignItems: "center" }}>
        <Form onSubmit={handleSubmit} 
            className="login-form"
        >
        <Title  className='mb-2'>Iniciar sesión</Title>
        <Paragraph  size='sm' mb={3}>Comienza a distribuir en canales de venta tus productos.</Paragraph>
        <Input mb={3} className='mb-3' type='email' placeholder='Email' prefix={<UserOutlined className="site-form-item-icon" />} onChange={handleChangeEmail} />
        <Input mb={3} className='mb-3' type='password' placeholder='Contraseña' prefix={<LockOutlined className="site-form-item-icon" />} onChange={handleChangePassword} />
        <Button loading={loading} style={{ width: "100%" }} type='primary' onClick={() => handleSubmit()} >INICIAR SESIÓN</Button>
        <Divider>O</Divider>
        <Row gutter={20}>
            <Col xs={24}><Button disabled style={{ width: "100%" }} >GOOGLE</Button></Col>
        </Row>
        <Title level={5}>¿Aún no tienes un usuario? <Link to="/register" style={{ fontWeight: 'bold' }}>Registrarme </Link> </Title>
        <Title level={5} className='mt-0'><Link to={rutas.forget_password.slug} style={{ fontWeight: 'bold' }}>Olvidé mi contraseña </Link> </Title>
        </Form>
        </Col>
        <Col md={16} xs={0} style={{ background: gradient_primary, display: "flex", alignItems: "center"}}>
            <img style={{ width: "55%", margin: "0 auto"}} className='hidden-mobile' src="/images/3.png" />
        </Col>
        </Row>
    </div>
}

export default Login