import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Alert, Breadcrumb, Button, Card, Col, Divider, Form, Input, Layout, Popconfirm, Row, Select, Tabs, Tag, Upload, message } from "antd"
import { CameraOutlined, CarFilled, HomeOutlined, InfoCircleFilled, LoadingOutlined, PlusOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { limpiarStringLetrasNumeros, limpiarStringNumeros, siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import { config_s3, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import { nombreDNIPorPais } from "../../lib/helpers/data/internationa"
import S3FileUpload from 'react-s3';
import SelectorMarcas from "./selectores/marca"
import SelectorTipoMotor from "./selectores/tipo_motor"
import SelectorTipo from "./selectores/tipo_vehiculo"
import SelectorColor from "./selectores/color"
import SelectorModelo from "./selectores/modelo"
import ModalSeleccionVehiculos from "../Conductores/modal_seleccion"
import CargandoTabla from "./cargando"
import { useParams } from "react-router-dom"
import ListadoRequisitos from "../Requisitos/listado"
import { TbFileCertificate, TbTag } from 'react-icons/tb'
import ListadoRequisitosModelos from "../Requisitos/esquema_agregar"
import CamposPersonalizadosRecurso from "../../subComponents/campos_personalizados/listado_recurso"
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main"
import SelectorGeneral from "../General/selector"
import { calcularVolumen } from "../../lib/helpers/main"

const DetallesEquipo = (props) => {
    const {
        id
    } = useParams()
    const [ loading, setLoading ] = useState(false)
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ loadingDefinicion, setLoadingDefinicion ] = useState(false)
    const [ loadingImagen, setLoadingImagen ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ usuario, setUsuario ] = useState(false)
    const dispatch = useDispatch()
    const [ condicion_sub_categoria, setCondicionSubCategoria ] = useState({ tipo: "equipo" })
    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
    const unidades_medida_volumen       = obtenerUnidadesMedidaPorTipoPais("volumen", pais).map(e => e)
    const requeridos = [
        { value: 'patente', label: 'Patente' },
    ];
    const module = 'equipos'

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/${module}?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res){
                setUsuario(res)
                if(res.detalles_conductor){
                    if(typeof res.detalles_conductor === "object"){
                        if(res._id) setConductor(res.detalles_conductor)
                    }
                }
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }
    
    const eliminarRecurso = async () => {
        setLoadingMaster(true)
        return fetch(`${urlapi}/equipos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                return window.location = rutas.equipment.slug
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const handleChangeSelectSimple = (e,name) => {
        usuario[name] = e
        return setUsuario({...{}, ...usuario})
    }
    const handleChangeVehiculoCustom = (e,name) => {
        console.log({e,name})
        usuario[name] = e._id
        if(name === "id_tipo") usuario.tipo = e.tipo
        if(name === "marca") usuario.modelo = ""
        console.log(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const seleccionarConductor = (data) => {
            usuario.id_usuario = data._id
            console.log(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const handleChangeSelect = (e,name) => {
        console.log(e)
        usuario[name] = e
        return setUsuario({...{}, ...usuario})
    }

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        return setUsuario(prev => {
            let actual = {...prev}
            if(name === "rut"){
                actual[name] = limpiarStringLetrasNumeros(value)
            } else if(name === "phone"){
                actual[name] = limpiarStringNumeros(value)
            } else {
                actual[name] = value
            }
            actual.volumen = calcularVolumen(actual).volumen
            return {...{}, ...actual}
        })
    }

    const uploadButton = (
        <div>
          {loadingImagen ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Subir foto
          </div>
        </div>
      );

    const handleChangeUsuarioSelect = (name, value) => {
        usuario[name] = value
        return setUsuario(usuario)
    }

    const crearConductorNuevo = async () => {
        
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
        usuario.autorizado = "si"

        setLoading(true)
        const url = `${urlapi}/${module}`
        return fetch(url, {
            method: "PUT",
            body: JSON.stringify(usuario),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                return setTimeout(() => {
                    return window.location = `${rutas.equipment.slug}/${res._id}`
                }, 1000);
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    const cambiarVehiculoConductor = async () => {
        setLoadingDefinicion(true)
        return fetch(`${urlapi}/vehiculos/definir-seleccionado`, {
            method: 'POST',
            body: JSON.stringify({
              id: usuario._id
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer: ${session.tokenSession}`,
              'country': pais,
              'lang': idioma
            },
          })
            .then((res) => {
              if (res.status === 401) return dispatch(cerrarSesion());
              return res.json();
            })
            .then((res) => {
              if (!res) {
                messageApi.error('Sin datos');
              } else if (res.errorMessage) {
                messageApi.error(res.errorMessage);
              } else if (res._id) {
                usuario.selected = true
                messageApi.success("Realizado exitosamente")
                setUsuario(prev => ({...{}, ...usuario}));
              }
              return setLoadingDefinicion(false);
            })
            .catch((error) => {
              messageApi.error('Error al consultar la información, intente nuevamente');
              return setLoadingDefinicion(false);
            })
      }

    const handleChangeArchivo = async (e) => {

        const archivo = e.file.originFileObj
        setLoadingImagen(true)
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            messageApi.error('No pudimos cargar tu imagen')
            return setLoadingImagen(false)
        }

        usuario.imagen_perfil = data.location
        setUsuario({...{}, ...usuario})
        return setLoadingImagen(false)

        })
        .catch(err => {
            console.log(err)
            setLoadingImagen(false)
            return messageApi.error('No pudimos cargar tu imagen')
        })
    }

    const handleChangeCat = (e) => {
        usuario.id_categoria = e._id
        usuario.id_sub_categoria = ''
        setUsuario(usuario)
        setCondicionSubCategoria(prev => ({...prev, id_categoria: e._id }))
    }

    const handleChangeSubCat = (e) => {
        usuario.id_sub_categoria = e._id
        setUsuario({...{}, ...usuario})
    }

    const handleChangeCustom = (e, name) => {
        setUsuario(prev => {
            let actual = {...prev}
            actual[name] = e?._id
            return { ...{}, ...actual}
        })
    }

    const render = () => {
        if(loadingMaster) return <Card><CargandoTabla /></Card>
        if(!usuario) return <Card><Title>No hay suficientes datos para mostrar esta pantalla</Title> </Card>

        const calculo_volumen = calcularVolumen(usuario, usuario.unidad_medida )

        return <div>
            <Card className="mb-3">
            <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.equipment.slug,
                    title: <span>Equipo</span>,
                },
                {
                    title: 'Detalles de equipo',
                },
                ]}
            />
            <Title className="mb-2 mt-0">Detalles de equipo</Title>
            <Popconfirm
                    title="Eliminar definitivamente"
                    description="¿Estás seguro que deseas eliminar este recurso? Esta opción no se puede deshacer"
                    onConfirm={() => eliminarRecurso()}
                    onCancel={() => false}
                    okText="SI"
                    cancelText="NO"
                  >
                  <Button size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
            </Card>
            <Layout style={{ padding: 20 }}>
            <Row gutter={20}>
                <Col md={12}>
                        <Card size="small">
                    {/* <ModalSeleccionVehiculos onRemove={() => {
                        setConductor(false)
                        usuario.id_usuario = ''
                        usuario.selected = false
                        return setUsuario({...{}, ...usuario})
                    }} defaultValue={conductor} onSelect={data => seleccionarConductor(data)} />
                    <Divider /> 
                {autoSeleccionado()}
                */}
            <Form layout="vertical">
            <Row gutter={10}>
                <Col md={8} xs={24}>
                    <Form.Item label="Título" >
                    <Input mb={3} name="titulo" defaultValue={usuario.titulo} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Patente" >
                    <Input mb={3} name="patente" defaultValue={usuario.patente} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                
                <Col md={8} xs={24}>
                    <Form.Item label="Ejes" >
                    <Input mb={3} type="number" variant="filled" name="ejes" defaultValue={usuario.ejes}  onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Año" >
                    <Input mb={3} variant="filled" type="number" name="ano" defaultValue={usuario.ano} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item label="Color" >
                        <SelectorColor onChange={(e) => handleChangeVehiculoCustom(e,"color")} defaultValue={usuario.color} />
                    </Form.Item>
                </Col>
                {/*
                <Col md={8} xs={24}>
                    <Form.Item label="Marca" >
                        <SelectorMarcas onChange={(e) => handleChangeVehiculoCustom(e,"marca")} defaultValue={usuario.marca} />
                    </Form.Item>
                </Col>
                 <Col md={8} xs={24}>
                    <Form.Item label="Modelo" >
                        <SelectorModelo filterBy={usuario.marca} value={usuario.modelo} filterByKey="id_marca" onChange={(e) => handleChangeVehiculoCustom(e,"modelo")} />
                    </Form.Item>
                </Col> */}
                
                {/* <Col md={8} xs={24}>
                    <Form.Item label="Tipo " >
                        <SelectorTipo onChange={(e) => handleChangeVehiculoCustom(e,"id_tipo")} defaultValue={usuario.id_tipo} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Tipo de motor" >
                        <SelectorTipoMotor onChange={(e) => handleChangeVehiculoCustom(e,"tipo_motor")} defaultValue={usuario.tipo_motor} />
                    </Form.Item>
                </Col> */}
                <Col md={12} xs={24}>
                    <Form.Item label="Número de chasis" >
                    <Input mb={3} variant="filled" name="chasis" defaultValue={usuario.chasis} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                <Form.Item label="Tipo de motor" >
                    
                    <SelectorTipoMotor onChange={(e) => handleChangeCustom(e,"id_tipomotor")} defaultValue={usuario.id_tipomotor} />
                </Form.Item>
                    {/* <SelectorGeneral module="categorias" value={usuario.id_tipomotor} titulo="Tipo de motor" condicion_default={{ tipo: "tipomotor" }} onChange={handleChangeCat} /> */}
                </Col>
                <Col md={12} xs={24}>
                    <SelectorGeneral module="categorias" value={usuario.id_categoria} titulo="Categoría" condicion_default={{ tipo: "equipo" }} onChange={handleChangeCat} />
                </Col>
                <Col md={12} xs={24}>
                    <SelectorGeneral module="sub-categorias" value={usuario.id_sub_categoria} titulo="Sub Categoría" condicion_default={condicion_sub_categoria} onChange={handleChangeSubCat} />
                </Col>
                <Col md={6} xs={24}>
                    <SelectorGeneral module="categorias" titulo="Marca" value={usuario.id_marca} condicion_default={{ tipo: "marca-equipo" }} onChange={(data) => handleChangeCustom(data, "id_marca")} />
                </Col>
                <Col md={6} xs={24}>
                    <SelectorGeneral module="categorias" titulo="Modelo" value={usuario.id_modelo} condicion_default={{ tipo: "modelo-equipo" }} onChange={(data) => handleChangeCustom(data, "id_modelo")} />
                </Col>
                <Col md={6} xs={24}>
                    <Form.Item label="Peso del equipo" >
                    <Input mb={3} type="number" variant="filled" defaultValue={usuario.peso_equipo} name="peso_equipo" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={6} xs={24} >
                    <Form.Item label="Pallets" required={siEsRequeridoEnEsquema('pallets',requeridos)} >
                    <Input mb={3} variant="filled" type="number" name="pallets" defaultValue={usuario.pallets} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Title level={4}>Medidas del equipo</Title>
                    <Form.Item label="Unidad de medida de longitud" required={siEsRequeridoEnEsquema('unidad_medida',requeridos)} >
                        <Select defaultValue={usuario.unidad_medida} options={unidades_medida.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelect(e,"unidad_medida")} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Ancho" >
                    <Input addonBefore={usuario.unidad_medida} mb={3} variant="filled" type="number" defaultValue={usuario.medida_ancho} name="medida_ancho" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Alto" >
                    <Input addonBefore={usuario.unidad_medida} mb={3} variant="filled" type="number" defaultValue={usuario.medida_alto} name="medida_alto" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Largo" >
                    <Input addonBefore={usuario.unidad_medida} mb={3} variant="filled" type="number" defaultValue={usuario.medida_largo} name="medida_largo" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col xs={24}><Title level={4}>Capacidad de carga</Title></Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Ancho" >
                    <Input mb={3} addonBefore={usuario.unidad_medida} variant="filled" type="number" name="ancho" defaultValue={usuario.ancho} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Alto" >
                    <Input mb={3} addonBefore={usuario.unidad_medida} variant="filled" type="number" name="alto" defaultValue={usuario.alto} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Largo" >
                    <Input mb={3} addonBefore={usuario.unidad_medida} variant="filled" type="number" name="largo" defaultValue={usuario.largo} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item label="Unidad de medida de peso" required={siEsRequeridoEnEsquema('unidad_medida_peso',requeridos)} >
                        <Select defaultValue={usuario.unidad_medida_peso} options={unidades_medida_peso.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelect(e,"unidad_medida_peso")} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item label="Capacidad de peso" >
                    <Input mb={3} addonBefore={usuario.unidad_medida_peso} variant="filled" type="number" name="peso" defaultValue={usuario.peso} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    <Title className="mt-0 mb-0" level={4}>Volumen de carga</Title>
                    <Title className="mt-0 mb-3 " level={4}>{calculo_volumen.volumen.toFixed(2)} <Tag>{calculo_volumen.unidadVolumen}</Tag> </Title>
                </Col>
                {/* <Col md={12} xs={24}>
                    <Form.Item label="Unidad de medida de volumen" required={siEsRequeridoEnEsquema('unidad_medida_volumen',requeridos)} >
                        <Select defaultValue={usuario.unidad_medida_volumen} options={unidades_medida_volumen.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelect(e,"unidad_medida_volumen")} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item label="Volumen de carga" >
                    <Input mb={3} addonBefore={usuario.unidad_medida_volumen} variant="filled" type="number" name="volumen" defaultValue={usuario.volumen} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col> */}
                <Col md={8} xs={24}>
                    <Form.Item label="Estado" >
                    <Select className="mb-0" name="status" placeholder="Estado" style={{ width: "100%" }} defaultValue={usuario.status} options={[{ value: "active", label: "Activo" }, { value: "trash", label: "Inactivo"}]} onChange={(e) => handleChangeSelectSimple(e,'status')} />
                    </Form.Item>
                </Col>
            </Row>     
               
            <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => crearConductorNuevo()} ><SaveOutlined /> GUARDAR CAMBIOS</Button>
            </Form>
            </Card>

                </Col>
                <Col md={12}>
                    <Card size="small">
                    <Tabs defaultActiveKey="1" items={[
                        {
                            key:"1",
                            label: <Card size="small" style={{ textAlign: "center" }}><TbFileCertificate  style={{ fontSize: 20 }} /> <Title level={4} className="m-0">REQUISITOS</Title></Card>,
                            children: <div>
                                <ListadoRequisitos condicion_default={{ id_target: usuario._id }}/>
                                <Divider/>
                                <Title level={4} className="mb-0 mt-0">Otros requisitos que podrías agregar</Title>
                                <ListadoRequisitosModelos id_target={usuario._id} condicion_default={{ tipo: "equipo", status: "active" }} />
                            </div> 
                        },
                        {
                            key: "2",
                            label: <Card size="small" style={{ textAlign: "center" }}><TbTag style={{ fontSize: 20 }} /> <Title level={4} className="m-0">CAMPOS PERSONALIZADOS</Title></Card>,
                            children: <CamposPersonalizadosRecurso id_target={id} tipo="equipo" />
                        },
                    ]} />
                    </Card>
                </Col>
            </Row>
            </Layout>
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default DetallesEquipo