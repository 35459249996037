import { Button, Form, Input, Modal, Select, message } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import { PlusCircleFilled } from "@ant-design/icons";
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios";
import SelectorGeneral from "../General/selector";
import SelectorGlobal from "../../subComponents/general/selector";

const CrearNuevoRegistro = (props) => {
    const {
        tipo
    } = props
    const [ campo, setCampo ] = useState({})
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const slug_module               = 'sub-categorias'

    const crearCampo = async () => {
        if(!campo.id_categoria) return messageApi.error("Selecciona una categoría")
        if(!campo.title) return messageApi.error("Escribe un título")
        if(campo.title.length < 3) return messageApi.error("Escribe un título válido")
        setLoading(true)
        return fetch(`${urlapi}/${slug_module}`,{
            method:'POST',
            body: JSON.stringify({...campo, tipo }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                setIsModalOpen(false)
                setCampo({...{}, ...{}})
                if(props.onCreate) props.onCreate()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
        return crearCampo()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }

    const handleChangeCategory = (e) => {
        campo.id_categoria = e._id
        return setCampo(campo)
    }

    const handleChangeSelect = (name, value) => {
        campo[name] = value
        return setCampo(campo)
    }
    
    return <div>
        <Button className="mb-3" type="primary" size="small" onClick={showModal}><PlusCircleFilled /> CREAR NUEVO</Button>
        <Modal 
            title="Crear categoría" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <CamposObligatoriosLeyenda />
            <Form layout="vertical">
                {/* <Form.Item label="Tipo" required>
                    <Select
                    options={[{ value: "conductor", label: "Conductor" },{ value:"vehiculo", label: "Vehículo" },{ value:"equipo", label: "Equipo" }]}
                    onChange={(e) => handleChangeSelect('tipo',e)} 
                    />
                </Form.Item> */}
                {/* <SelectorGlobal condicion_default={{ tipo }}  onChange={handleChangeCategory} module="products" titulo="Categoría" /> */}
                <SelectorGlobal titulo="Categoría" condicion={{ tipo }} module="categorias" key_label="title" key_value="_id" onChange={(data) => handleChangeCategory(data)} />
                <Form.Item label="Nombre" required>
                    <Input name="title" value={campo.title} onChange={handleChange} />
                </Form.Item>
            </Form>
      </Modal>
      {contextHolder}
    </div>
}

export default CrearNuevoRegistro