import Title from "antd/es/typography/Title";
import { url_images } from "../../lib/backend/data";

const SinInformacion = () => {

    return <div>
        <img src={`${url_images}/animations/Search Not Found.gif`} alt="Sin información" style={{ maxHeight: 200 }} />
        <Title level={4} className="mt-0 mb-0">Nada por aquí...</Title>
    </div>
}

export default SinInformacion;