import { useState } from "react";
import { Button, Card, Col, Modal, Row, Spin, Select, Input, Switch, Tooltip, message, Skeleton, Form } from "antd";
import { toast } from "react-toastify";
import { eventos, eventoToString } from "../../lib/helpers/integraciones";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import { fechaATexto } from "../../lib/helpers/helpers";
import Paragraph from "antd/es/typography/Paragraph";

const BoxWebhook = (props) => {
    const session = useSelector((state) => state.miusuario);
    const token = session?.tokenSession
    const [webhook, setWebhook] = useState(props.webhook ? props.webhook : false);
    const [editar, setEditar] = useState(false);
    const [removiendo, setRemoviendo] = useState(false);
    const [newWebhook, setNewWebhook] = useState(webhook);
    const [loadingCreacion, setLoadingCreacion] = useState(false);
    const dispatch = useDispatch();

    if (!webhook) return null;

    if (removiendo) return <Skeleton height={200} />;

    const editarWH = () => {
        setEditar(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewWebhook((prev) => ({ ...prev, [name]: value }));
    };

    const confirmarEliminado = async (id) => {
        setRemoviendo(true);
        try {
            const response = await fetch(`${urlapi}/integraciones/webhook?id=${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer: ${token}`
                }
            });

            const result = await response.json();

            if (response.status === 401) {
                return dispatch(cerrarSesion());
            }

            if (result.errorMessage) {
                toast.error(result.errorMessage);
            } else if (result._id && props.onDeleteItem) {
                props.onDeleteItem(result._id);
            }
        } catch (error) {
            toast.error("Error al consultar la información, intente nuevamente");
        } finally {
            setRemoviendo(false);
        }
    };

    const solicitarEliminar = (id) => {
        Modal.confirm({
            title: "¿Estás seguro?",
            content: "Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer",
            onOk: () => confirmarEliminado(id),
            onCancel: () => {}
        });
    };

    const guardarCambios = async () => {
        setLoadingCreacion(true);
        try {
            const response = await fetch(`${urlapi}/integraciones/webhook`, {
                method: 'PUT',
                body: JSON.stringify(newWebhook),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer: ${token}`
                }
            });

            const result = await response.json();

            if (response.status === 401) {
                return dispatch(cerrarSesion());
            }

            if (result.errorMessage) {
                toast.error(result.errorMessage);
            } else {
                toast.success('Guardado exitosamente');
                setWebhook(result);
                setEditar(false);
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoadingCreacion(false);
        }
    };

    const handleChangeSwitch = (checked) => {
        setNewWebhook((prev) => ({ ...prev, active: checked }));
    };

    const formularioEditar = () => (
        <Form layout="vertical">
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Button onClick={() => setEditar(false)}>Cancelar</Button>
                <Button danger onClick={() => solicitarEliminar(webhook._id)}>Eliminar</Button>
            </Col>
            <Col span={24}>
                <Form.Item label="Url">
                <Input defaultValue={webhook.url} name="url" onChange={handleChange} />
                </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item label="Evento">
                <Select
                    defaultValue={webhook.evento}
                    name="evento"
                    onChange={(value) => handleChange({ target: { name: "evento", value } })}
                >
                    {eventos.map((e, i) => (
                        <Select.Option key={i} value={e.value}>{e.label}</Select.Option>
                    ))}
                </Select>
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item label="Activo">
                <Switch checked={newWebhook.active} onChange={handleChangeSwitch} />
                </Form.Item>
            </Col>
            <Col span={12}>
                <label>Formato</label>
                <p>{webhook.format ? webhook.format.toUpperCase() : 'Sin información'}</p>
            </Col>
            <Col span={12}>
                <label>Versión del API</label>
                <p>{webhook.version}</p>
            </Col>
            <Col span={24}>
                {loadingCreacion ? (
                    <Spin />
                ) : (
                    <Button type="primary" onClick={guardarCambios}>Guardar Cambios</Button>
                )}
            </Col>
        </Row>
        </Form>
    );

    if (editar) return formularioEditar();

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}><Button onClick={editarWH}>Editar</Button></Col>
            <Col span={24}>
                <Paragraph className="mb-0"><b>Url</b></Paragraph>
                <Paragraph className="mb-0">{webhook.url}</Paragraph>
            </Col>
            <Col span={12}>
                <Paragraph className="mb-0"><b>Evento</b></Paragraph>
                <Paragraph className="mb-0">{eventoToString(webhook.evento)}</Paragraph>
            </Col>
            <Col span={12}>
                <Paragraph className="mb-0"><b>Estado</b></Paragraph>
                {webhook.active ? (
                    <p style={{ color: "green" }}>Activo</p>
                ) : (
                    <Tooltip title={`Pausado el ${fechaATexto(webhook.ultima_fecha_pausado)}`}>
                        <p style={{ color: "gray" }}>Pausado</p>
                    </Tooltip>
                )}
            </Col>
            <Col span={12}>
                <Paragraph className="mb-0"><b>Formato</b></Paragraph>
                <Paragraph className="mb-0">{webhook.format ? webhook.format.toUpperCase() : 'Sin información'}</Paragraph>
            </Col>
            <Col span={12}>
                <Paragraph className="mb-0"><b>Versión del API</b></Paragraph>
                <Paragraph className="mb-0">{webhook.version}</Paragraph>
            </Col>
        </Row>
    );
};

export default BoxWebhook;
