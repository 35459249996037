const { validateRut } = require("@fdograph/rut-utilities");
const { validarDNIArgentina } = require("./argentina");
const { validarDNIBrasil, formatearCPF, formatearTelefonoMovilBrasil } = require("./brasil");
const { validarRUTChileno, formatearRUTChileno, formatearTelefonoMovilChile } = require("./chile");
const { validarDNIColombia, formatearTelefonoMovilColombia } = require("./colombia");
const { validarDNIMexico, formatearTelefonoMovilMexico } = require("./mexico");

const formatearDNIPorPais = (value, pais_code) => {
    if(!value) return ''
    switch (pais_code) {
        case "CL":
            return formatearRUTChileno(value)
        case "AR":
            return value
        case "BR":
            return formatearCPF(value)
        case "MX":
            return value
        case "CO":
            return value
        default:
            break;
    }
}

const validarDNIPorPais = (value, pais_code) => {
    switch (pais_code) {
        case "CL":
            return validateRut(value)
        case "AR":
            return validarDNIArgentina(value)
        case "BR":
            return validarDNIBrasil(value)
        case "MX":
            return validarDNIMexico(value)
        case "CO":
            return validarDNIColombia(value)
        default:
            return true
    }
}

const nombreDNIPorPais = (pais_code) => {
    switch (pais_code) {
        case "CL":
            return "RUT"
        case "AR":
            return "DNI"
        case "BR":
            return "CPF"
        case "MX":
            return "CURP"
        case "CO":
            return "CÉDULA"
        default:
            break;
    }
}

const formatoAyudaDNIPorPais = (pais_code) => {
    switch (pais_code) {
        case "CL":
            return "Formato: 22222222K Solo letras y números"
        case "AR":
            return "Solo letras y números"
        case "BR":
            return "Apenas letras e números"
        case "MX":
            return "Solo letras y números"
        case "CO":
            return "Solo letras y números"
        default:
            break;
    }
}

const formatoAyudaMovilPorPais = (pais_code) => {
    switch (pais_code) {
        case "CL":
            return "Formato: 912345678"
        case "AR":
            return "Formato: "
        case "BR":
            return "Formatar: 55123412349"
        case "MX":
            return "Formato: "
        case "CO":
            return "Formato: "
        default:
            break;
    }
}

const obtenerCentroMapaPorPais = (pais_code) => {
    switch (pais_code) {
        case "CL":
            return { longitude: -70.670112, latitude: -33.447471, zoom: 10 }
        case "CO":
            return { longitude: -74.072786, latitude: 4.707831, zoom: 10 }
        case "MX":
            return { longitude: -99.135499, latitude: 19.426035, zoom: 10 }
        case "BR":
            return { longitude: -47.894820, latitude: -15.797968, zoom: 10 }
        default:
            return { longitude: -70.670112, latitude: -33.447471, zoom: 10 }
    }
}


const formatearMovilPorPais = (value, pais_code) => {
    if(!value) return ''
    switch (pais_code) {
        case "CL":
            return formatearTelefonoMovilChile(value)
        case "AR":
            return value
        case "BR":
            return formatearTelefonoMovilBrasil(value)
        case "MX":
            return formatearTelefonoMovilMexico(value)
        case "CO":
            return formatearTelefonoMovilColombia(value)
        default:
            return value
    }
}

const stringByTipoNotification = (type, lang) => {
    switch (type) {
        case "info":
            return "INFORMATIVA"
        case "warning":
            return "ADVERTENCIA"
        case "error":
            return "CRÍTICA"
        default:
            return "POR DEFECTO"
    }
}

module.exports = {
    stringByTipoNotification,
    validarDNIPorPais,
    obtenerCentroMapaPorPais,
    formatoAyudaMovilPorPais,
    formatearMovilPorPais,
    formatoAyudaDNIPorPais,
    formatearDNIPorPais,
    nombreDNIPorPais
}