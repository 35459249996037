import { url_images } from '../../lib/global/data'
import Title from 'antd/es/typography/Title'

const PantallaCargando = () => {
    return <div className='text-center'>
        <img src={`${url_images}/animations/Package.gif`} style={{ maxWidth: 200, marginBottom: -20, marginTop: -20 }} />
        <Title level={4} className='mb-0 mt-0' style={{ fontWeight: "bold" }}>Cargando información...</Title>
    </div>
}

export default PantallaCargando