import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Card, Col, Divider, Row } from 'antd';
import { PlusCircleFilled } from "@ant-design/icons";
import ListadoCamposPersonalizados from "./listado";
import { useState } from "react";
import CrearNuevoCampoPersonalizado from "./crear";
import ButtonGroup from "antd/es/button/button-group";

const Endpoints = (props) => {
    const [ condicion_busqueda, setCondicionBusqueda ] = useState({})

    const render = () => {
        return <div>
            <Card>
            <Title className="mb-2 mt-0">Endpoints</Title>
            <ButtonGroup>
            <CrearNuevoCampoPersonalizado onCreate={() => setCondicionBusqueda(prev => ({...{}, ...{}}))} />
            </ButtonGroup>
            <Divider />
                <ListadoCamposPersonalizados condicion_default={condicion_busqueda} />
            </Card>
        </div>
    }
    
    return <Structure component={render()} />
}

export default Endpoints