import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../../redux/actions/sesion"
import { Alert, Button, Card, Col, Divider, Form, Input, Layout, Pagination, Popconfirm, Row, Table, message } from "antd"
import CargandoTabla from "../cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { fechaATexto, fechaATextoSimple, fechaUTCATexto, fechaUTCATextoSimple } from "../../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { FileExcelFilled, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { obtenerFechaHoraZonaHorariaLocal } from "../../../lib/helpers/dates"
import ContadoresVehiculos from "../contadores"
import SelectorColor from "../selectores/color"
import GraficosCamposPersonalizados from "../../../subComponents/graficos/conductores"
import ModalPersonalizado from "../../../subComponents/general/modales/modal_personalizado"
import { rutas } from "../../../lib/routes/routes"
import SelectorGeneral from "../../General/selector"

const ListadoRelaciones = (props)=> {
    const {
        condicion_default,
        showFilters,
        showCustomFieldsData,
        showCounters,
        hideSearch
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ conductores, setRegistros] = useState([])
    const [ loadingRegistros, setLoadingRegistros] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const [deleting, setDeleting]   = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const trash = props.trash ? props.trash : false
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const tipo = props.tipo ? props.tipo : "link"
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const titulo = props.title ? props.title : 'registros'
    const module = 'equipos/relaciones'

    const obtenerDatos = async (page, query)=>{
        setLoadingRegistros(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${module}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingRegistros(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingRegistros(false)
            }
            if(Array.isArray(res.datos) !== false){
                setRegistros(res.datos)
                setTotal(res.total)
            }
            return setLoadingRegistros(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingRegistros(false)
        })
    }

    useEffect(() => {
        obtenerDatos(1, false)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerDatos(e, condicion_busqueda)
    }
    
    const refrescarBusqueda = () => {
        setPagina(1)
        let cond = {}
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            }
        })
        obtenerDatos(1, cond)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green" size="small" onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button>
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination current={pagina} total={(cantidad*10)} showSizeChanger={false} onChange={handleChangePagina} />
        </div>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/vehicles`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setPagina(1)
            setCondicionBusqueda({})
            return obtenerDatos(1, {})
        }
        const condicion = {
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerDatos(1, condicion);
      };

      const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

      
    const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit} >
        {/* <Title className="mb-0 mt-0" level={5}>BUSCAR</Title> */}
        <Row gutter={4}>
            <Col md={24}>
                <Form.Item className="mb-2">
                    <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Patente, chasis, número de motor`} />
                </Form.Item>
            </Col>
            <Col md={24}>
                <Form.Item >
                    <Button  onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                </Form.Item>
            </Col>
        </Row>
               <Divider className="mt-0" />
                </Form>
      }

      const eliminarRecurso = async (id) => {
        setDeleting(true)
        return fetch(`${urlapi}/${module}/unasign?id=${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(res._id){
              messageApi.success("Eliminado exitosamente")
              obtenerDatos(1, false)
            }
            return setDeleting(false)
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setDeleting(false)
          })
      }

      const mostrarTabla = () => {
          if(loadingRegistros) return <CargandoTabla />

          return <Table dataSource={conductores} pagination={false} scroll={{ x:"50vw" }}>
            <Column title="Título" render={(data) => {
                  return <div><Link to={`${rutas.equipment.slug}/${data._id}`}>{data.titulo ? data.titulo : 'VER DETALLES'}</Link></div>
            }} />
          <Column title="Patente" render={(data) => {
              return <div>{data.patente ? data.patente.toUpperCase() : 'VER DETALLES'}</div>
          }} />
          <Column title="Categoría" render={(data) => {
              return <div>{data.categoria ? data.categoria : "SIN INFORMACIÓN"}</div>
          }} />
          <Column title="Sub Categoría" render={(data) => {
              return <div>{data.sub_categoria ? data.sub_categoria : "SIN INFORMACIÓN"}</div>
          }} />
          {/* <Column title="Marca" render={(data) => {
              return <div>{data.marca ? data.marca.toUpperCase() : "SIN INFORMACIÓN"}</div>
          }} /> */}
          <Column title="Año" render={(data) => {
              return <div>{data.ano}</div>
          }} />
          <Column title="Registro" render={(data) => {
              return <div>{fechaUTCATextoSimple(data.createdAt)}</div>
          }} />
          <Column title="Acciones" fixed="right" render={(data) => {
                    return <div>
                        <Popconfirm
                    title="Eliminar"
                    description="¿Estás seguro que deseas eliminar recurso? Esta acción no se puede deshacer"
                    onConfirm={() => eliminarRecurso(data.id_relacion)}
                    onCancel={() => false}
                    okText="SI"
                    style={{ display: "inline-block"}}
                    cancelText="NO"
                  >
                  <Button loading={deleting} size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
                  </div>
                }} />
      </Table>
      }

      const agregarFiltro = (key, value) => {
        filtroBusqueda[key] = { $in: value.map(v => v._id) }
        console.log(filtroBusqueda)
        setFiltroBusqueda(filtroBusqueda)
      }

      const mostrarFiltrosBusqueda = () => {
        return <div style={{ marginBottom: 20 }} className="mt-0">
            <Title className="mb-0 mt-0" level={5}>FILTRAR</Title>
            <Row gutter={10}>
                <Col md={24} className="mb-3">
                    <Title className="mt-0" level={5}>Categoría</Title>
                    <SelectorGeneral hideLabel={true} mode="multiple" module="categorias" titulo="Categoría" condicion_default={{ tipo: "equipo" }} onChange={(data) => agregarFiltro('id_categoria', data)} />
                </Col>
                <Col md={24} className="mb-3">
                    <Title className="mt-0" level={5}>Sub categoría</Title>
                    <SelectorGeneral hideLabel={true} mode="multiple" module="sub-categorias" titulo="Categoría" condicion_default={{ tipo: "equipo" }} onChange={(data) => agregarFiltro('id_sub_categoria', data)} />
                </Col>
                <Col md={24} className="mb-3">
                    <Title className="mt-0" level={5}>Color</Title>
                    <SelectorColor mode="multiple" onChange={(data) => agregarFiltro("color", data)} placeholder="Color" />
                </Col>
            </Row>
            <Button onClick={() => refrescarBusqueda()} style={{ width: "100%" }} icon={<SearchOutlined />} >ACTUALIZAR</Button>
        </div>
      }

      const mostrarContadores = () => {

        const component = <ContadoresVehiculos onFilter={(condition) => {
            setPagina(1)
            setCondicionBusqueda(condition)
            obtenerDatos(1, condition)
        }} />

        return showCounters === true ? <Col><ModalPersonalizado typeView="modal" slug="distrubion" component={component} /></Col> : false
      }
      
      const mostrarDatosPersonalizados = () => {
        const component = <GraficosCamposPersonalizados typeView="modal" tipo="equipo" />
        return showCustomFieldsData === true ? <Col><ModalPersonalizado typeView="modal" slug="informe_personalizado" component={component} /></Col> : false
      }

    const mostrarRegistros = () => {

        return <div>
            <Divider />
            {
                showFilters === true ? <Row gutter={15}>
                <Col md={6}>
                    {buscador()}
                    {mostrarFiltrosBusqueda()}
                </Col>
                <Col md={18}>
                    <Row gutter={15} className="mb-3">
                    {mostrarContadores()}
                    {mostrarDatosPersonalizados()}
                    </Row>
                    {paginacion(data.pagina, total)}
                    {mostrarTabla()}
                </Col>
            </Row> : <div>
                    {mostrarContadores()}
                    {buscador()}
                    {paginacion(data.pagina, total)}
                    {mostrarTabla()}
                </div>
            }
            
            
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoRelaciones