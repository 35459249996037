const stringByStatus = (status) => {
    switch (status) {
        case "inactive":
            return 'Inactivo'
        case "active":
            return 'Activo'
        default:
            return 'Sin información'
    }
}

const requeridos = [
    { value: 'titulo', label: 'Título' },
    { value: 'tipo_disponibilidad', label: 'Tipo' },
];
const campos_fecha = [
    'fecha_carga_desde',
    'fecha_carga_hasta',
    'fecha_descarga_desde',
    'fecha_descarga_hasta'
]

const modulo = 'disponibilidades/recursos'

const tipos_disponibilidad = [{ value: 'recurrente', label: "Recurrente" },{ value: "unica", label: "Única" }]

module.exports = {
    stringByStatus,
    modulo,
    requeridos,
    campos_fecha,
    tipos_disponibilidad
}