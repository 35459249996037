import { Button, Modal } from "antd";
import Typography from "antd/es/typography/Typography";
import { useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { GoDeviceMobile } from "react-icons/go";

const MostrarEmails = (props) => {
    const {
        size,
        datos
    } = props
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const sin_datos = <Typography><AiOutlineMail /> Sin datos</Typography>

    if(!datos) return sin_datos
    if(Array.isArray(datos) !== true) return sin_datos
    if(datos.length < 1) return sin_datos
    
    return (
        <>
        <Button size={size ? size : false} icon={<AiOutlineMail />} onClick={showModal}>VER EMAILS</Button>
        <Modal title="Emails" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
            {datos.map((num,i) => {
                return <div key={`nuim-${i}`}><AiOutlineMail /> {num.email}</div>
            })}
        </Modal>
        </>
    );
  }

export default MostrarEmails