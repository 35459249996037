import { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { obtenerPrefijoMovil } from "../../lib/helpers/data/moviles";
import { Link } from "react-router-dom";
import { urlapi } from "../../lib/backend/data";
import guardarUsuario from "../../redux/actions/guardarUsuario";
import { validateEmail } from "../../lib/helpers/helpers";
import { Button, Card, Checkbox, Col, Form, Input, Row, Skeleton, message } from "antd";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { paises_codigos_telefonos, paises_sistema } from "../../lib/global/data";
import { gradient_primary } from "../../lib/global/style";

const TerminosCondiciones = (props) => {
    const [ usuario, setUsuario ] = useState({})
    const [ configuracion, setConfiguracion ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ showPassword, setShowPassword ] = useState(false)
    const [ terminosCondiciones, setTerminosCondiciones ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [messageApi, contextHolder] = message.useMessage();
    const [ pais_seleccionado, setPaisSeleccionado ] = useState(pais)
    
    const dispatch = useDispatch()

    useEffect(() => {
        obtenerDatos({ tipo: "general", sub_tipo: "terminos_condiciones" })
    }, [])

    const onChangeTerminosCondiciones = (e) => {
        const valor = e.target.checked
        setTerminosCondiciones(valor)
    }
    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        usuario[name] = value
        return setUsuario(usuario)
    }

    const obtenerDatos = async (condicion, pais_code) => {
        setLoading(true)
        const url = `${urlapi}/configuracion/details`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify({condicion}),
            headers: {
                'Content-type': "application/json",
                'country': pais_code ? pais_code : pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                setConfiguracion(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    const mostrarInformacion = () => {
        if(loading) return <Skeleton active />
        if(!configuracion) return <Paragraph>Sin datos para mostrar</Paragraph>
        if(!configuracion.valor) return <Paragraph>Sin datos para mostrar</Paragraph>
        return <Paragraph>{configuracion.valor}</Paragraph>
    }

    const selectorPais = () => {
        return <div>
            <ReactFlagsSelect
                className="selector_pais"
                selected={pais_seleccionado}
                onSelect={(code) => {
                    console.log(code)
                    setPaisSeleccionado(code)
                    setTimeout(() => {
                        obtenerDatos({ tipo: "general", sub_tipo: "terminos_condiciones" }, code)
                    }, 200);
                }}
                countries={["BR", "CL", "CO", "MX"]}
                // customLabels={{ US: "EN-US", GB: "EN-GB", FR: "FR", DE: "DE", IT: "IT" }}
                placeholder="Selecciona país"

            />
        </div>
    }

    return <div>
        {contextHolder}
    <Row style={{ height: "100vh" }}>
        <Col md={8} style={{ padding: 30, alignItems: "center" }}>
            <Title className='mb-2'>Términos y condiciones</Title>
            {selectorPais()}
            {mostrarInformacion()}            
        </Col>
        <Col md={16} xs={0} style={{ background: gradient_primary, display: "flex", alignItems: "center" }}>
        <img style={{ width: "55%", margin: "0 auto"}} className='hidden-mobile' src="/images/2.png" />
        </Col>
        </Row>
    </div>
}

export default TerminosCondiciones