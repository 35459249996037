const default_num = 0

function reducer( state = default_num, { type, payload } ){
    switch( type ){
        case 'modificarNotificaciones': {
            return payload
        }
        default:
            return state
    }
}

export default reducer