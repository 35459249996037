import { useEffect, useState } from "react";
import { Button, Card, Col, Modal, Row, Spin, Select, Input, Flex, Form, message } from "antd";
import { toast } from "react-toastify";
import { eventos } from "../../lib/helpers/integraciones";
import BoxWebhook from "./box-webhook";
import { useDispatch, useSelector } from "react-redux";
import Cargando from "../general/cargando";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import Structure from "../../components/Structure";
import Title from "antd/es/typography/Title";

const Webhooks = (props) => {
    const payload_default = {
        format: 'json',
        version: '2022-09'
    };
    const {
        display_full
    } = props
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingForm, setLoadingForm] = useState(false);
    const [ messageApi, contextHolder] = message.useMessage();
    const [webhooks, setWebhooks] = useState([]);
    const [newWebhook, setNewWebhook] = useState(payload_default);
    const session = useSelector((state) => state.miusuario);
    const token = session?.tokenSession
    const dispatch = useDispatch();

    useEffect(() => {
        obtenerWebhooks();
    }, []);

    const obtenerWebhooks = async () => {
        try {
            const response = await fetch(`${urlapi}/integraciones/webhook/list`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer: ${token}`
                },
            });
            const result = await response.json();
            if (!result || result.errorMessage) {
                messageApi.error(result?.errorMessage || "Error al consultar la información");
            } else {
                setWebhooks(result);
            }
        } catch (error) {
            messageApi.error("Error al consultar la información");
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewWebhook((prev) => ({ ...prev, [name]: value }));
    };

    const crearWebHook = async () => {
        const requeridos = [
            { value: "evento", label: "Evento" },
            { value: "format", label: "Formato" },
            { value: "url", label: "Url" },
            { value: "version", label: "Versión de API" }
        ];

        const faltantes = requeridos.filter(campo => !newWebhook[campo.value]).map(campo => campo.label);

        if (faltantes.length > 0) {
            return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`);
        }

        setLoadingForm(true);
        try {
            const response = await fetch(`${urlapi}/integraciones/webhook`, {
                method: 'POST',
                body: JSON.stringify(newWebhook),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer: ${token}`
                }
            });
            const result = await response.json();

            if (response.status === 401) {
                return dispatch(cerrarSesion());
            }

            if (result?.errorMessage) {
                messageApi.error(result.errorMessage);
            } else {
                messageApi.success('Creada exitosamente');
                setWebhooks((prev) => [result, ...prev]);
                setNewWebhook(payload_default);
                setOpenModal(false);
            }
        } catch (error) {
            messageApi.error(error.message);
        } finally {
            setLoadingForm(false);
        }
    };

    const crearWebhook = () => (
        <Modal
            title="Crear nuevo webhook"
            visible={openModal}
            onCancel={handleClose}
            footer={null}
        >
            <Form layout="vertical">
            <Row gutter={[16, 16]}>
                <Col md={12}>
                    <Form.Item label="Evento">
                    <Select
                        className="w-100"
                        placeholder="Seleccione"
                        name="evento"
                        onChange={(value) => handleChange({ target: { name: "evento", value } })}
                    >
                        {eventos.map((e, i) => (
                            <Select.Option key={`op-${i}`} value={e.value}>{e.label}</Select.Option>
                        ))}
                    </Select>
                    </Form.Item>
                </Col>
                <Col md={12}>
                    <Form.Item label="Formato">
                    <Select
                        className="w-100"
                        value={newWebhook.format}
                        onChange={(value) => handleChange({ target: { name: "format", value } })}
                    >
                        <Select.Option value="json">JSON</Select.Option>
                    </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="URL">
                    <Input
                        name="url"
                        onChange={handleChange}
                    />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Versión">
                    <Select
                        className="w-100"
                        value={newWebhook.version}
                        onChange={(value) => handleChange({ target: { name: "version", value } })}
                    >
                        <Select.Option value="2022-09">2022-09</Select.Option>
                    </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    {loadingForm ? (
                        <Spin />
                    ) : (
                        <Button type="primary" onClick={crearWebHook}>
                            CREAR
                        </Button>
                    )}
                </Col>
            </Row>
                </Form>
        </Modal>
    );

    const onDeleteItem = (id) => {
        setWebhooks((prev) => prev.filter(w => w._id !== id));
    };

    const mostrarWebhooks = () => {
        if (loading) return <Cargando />;
        if (webhooks.length < 1) {
            return (
                <div>
                    <h5>Nada por aquí</h5>
                    <p>No hay registros en esta sección</p>
                </div>
            );
        }

        return (
            <div>
                <Title level={4} className="mt-0 mb-3">{webhooks.length} Registros creados</Title>
                {webhooks.map(w => (
                    <Card key={w._id} className="mb-3">
                        <BoxWebhook webhook={w} onDeleteItem={(id) => onDeleteItem(id)} />
                    </Card>
                ))}
            </div>
        );
    };

    const render = () => {
        return (
            <div>
                <Title level={3} className="mt-0 mb-3">Webhooks <Button onClick={() => setOpenModal(true)}>Crear</Button></Title>
                {crearWebhook()}
                {mostrarWebhooks()}
            </div>
        );
    }

    if(display_full === true ){

        const renderizar = <div>
            {contextHolder}
            <Card>{render()}</Card>
        </div>

        return <Structure component={renderizar} />
    } else {
        return render()
    }
};

export default Webhooks