import { Button, Card, Col, Form, Input, Modal, Row, Select, Tag } from "antd"
import Paragraph from "antd/es/typography/Paragraph"
import Title from "antd/es/typography/Title"
import { useState } from "react"
import { maps_key, urlapi } from "../../lib/backend/data"
import ReactGoogleAutocomplete from "react-google-autocomplete"
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps"
import { optiones_estricto_tiempo } from "./data"
import { MdOutlinePlace, MdPlace } from "react-icons/md"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/sesion"
import SelectorGeneral from "../General/selector"

const DetallePunto = (props) => {
    const {
        data,
        origin
    } = props
    const [ show, setShow ] = useState(false)
    const [ punto, setPunto ] = useState(data)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const longitud_string = 30
    
    const actualizarPunto = async (pay) => {
        return fetch(`${urlapi}/viajes/punto`,{
            method:'POST',
            body: JSON.stringify(pay),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => res)
        .catch(error =>  error)
    }


    const showModal = () => {
        setShow(true);
    };

    const handleOk = () => {
        setShow(false);
    };

    const handleCancel = () => {
        setShow(false);
    };


    const handleChangeSelect = (val, key) => {
        return setPunto(prev => {
            let actual = {...prev}
            actual[key] = val
            if(props.onChange) props.onChange(actual)
            return actual
        })
    }
    
    const handleChangeSelectSpecial = (val, key) => {
        return setPunto(prev => {
            let actual = {...prev}
            actual[key] = val
            if(props.onChange) props.onChange(actual)
            return actual
        })
    }

    const procesarGeoParada = (place) => {
        if(!place) return false
        return setPunto(prev => {
            let actual = {...prev}
            const geo_data = procesarGeoDatosGeocode(place)

            actual.start_point      = {
                                        type: "Point",
                                        coordinates: [
                                            geo_data.lng, geo_data.lat
                                        ]
                                    }
            actual.location         = {
                                        latitude:geo_data.lat,
                                        longitude:geo_data.lng
                                    }
            actual.description      = place.formatted_address
            actual.geo_data         = geo_data
            actual.details          = place
            actualizarPunto(actual)
            if(props.onChange) props.onChange(actual)
            return actual
        })
                                
                            
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setPunto(prev => {
            let actual = {...prev}
                actual[name] = value
                if(props.onChange) props.onChange(actual)
                return actual
        })
    }

    const eliminarPunto = () => {
        if(props.onDelete) props.onDelete(punto._id)
        handleCancel()
    }

    let error = 0
    if(typeof punto !== "object") error++
    if(!punto.location){
        error++
    } else {
        const longitude = punto.location.longitude
        const latitude = punto.location.latitude
        if(typeof longitude !== "number") error++
        if(typeof latitude !== "number") error++
    }

    return <div>
        <Modal title="Configura esta parada" open={show} onOk={handleOk} onCancel={handleCancel} footer={[]} >
        <Tag color="blue" className="mb-3">{punto.tipo === "entrega" ? "ENTREGA" : "RECOGIDA"}</Tag>
        <Button size="small" onClick={() => eliminarPunto()} >ELIMINAR</Button>
        <ReactGoogleAutocomplete
                    className="ant-input inputlocation"
                    defaultValue={punto.description}
                    options={{
                        types: ["address"],
                        // cokmponentRestrictions: { country: pais },
                    }}                    
                    apiKey={maps_key}
                    style={{ marginBottom: 20 }}
                    onPlaceSelected={(place) => procesarGeoParada(place)}
                    />
            <Card size="small" className="p-3 mb-3">
                <Paragraph className="mb-0 mt-0">Lugar de recogida</Paragraph>
                <Title level={5} className="mb-0 mt-0">{origin ? origin?.description : "No has definido el punto de partida de la ruta"}</Title>
            </Card>

            <Form layout="vertical">
                <Row gutter={15}>
                <Col span={24}>
                        <Title className="mt-0" level={5}><b style={{ color: estilo_moving_truck.colors.primary }} >ENTREGA</b> Ventana horaria personalizada </Title>
                    <Row gutter={15}>
                        <Col span={8}>
                            <Form.Item label="Desde">
                                <Input type="time" value={data.dropoff_earliest} name="dropoff_earliest"  onChange={handleChange} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                        <Form.Item label="Hasta">
                                <Input type="time" value={data.dropoff_latest} name="dropoff_latest" onChange={handleChange} />
                        </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Tiempo de entrega">
                                <Input type="number" name="duration_dropoff" defaultValue={punto.duration_dropoff} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Peso de la carga">
                                <Input type="number" name="peso_vol" defaultValue={punto.peso_vol} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Cantidad de bultos">
                                <Input type="number" name="bultos" defaultValue={punto.bultos} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                
                {/* <Col span={24}>
                            <Title className="mt-0" level={5}><b style={{ color: estilo_moving_truck.colors.primary }} >RECOGIDA</b> Ventana horaria personalizada</Title>
                        <Row gutter={15}>
                            <Col span={8}>
                                <Form.Item label="Desde">
                                    <Input type="time" name="pickup_earliest" value={data.pickup_earliest}  onChange={handleChange} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                            <Form.Item label="Hasta">
                                    <Input type="time" name="pickup_latest" value={data.pickup_latest} onChange={handleChange} />
                            </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Tiempo">
                                    <Input type="number" name="duration_pickup" value={punto.duration_pickup} onChange={handleChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col> */}
                    
                </Row>
                    <SelectorGeneral mode="multiple" module="categorias" value={data.capabilities ? data.capabilities.map(e => e._id) : []} titulo="Capacidades requeridas para atender este punto" condicion_default={{ tipo: "capacidades" }} onChange={(val) => handleChangeSelectSpecial(val, "capabilities")} />
                <Form.Item className="mt-3" label="Es estricto el tiempo de llegada en relación a la duración de la parada?">
                    <Select defaultValue={punto.type} options={optiones_estricto_tiempo} onChange={(val) => handleChangeSelect(val,'type')} />
                </Form.Item>
            </Form>
      </Modal>
        <div id={`parada-${data._id}`} onClick={() => showModal()}>
            <Paragraph className="mt-0 hover mb-0"><Tag style={{ marginTop: 5 }} color={punto.tipo === "entrega" ? "blue" : "orange"}><MdPlace /> {punto.tipo === "entrega" ? "ENTREGA" : "RECOGIDA"}</Tag> {punto.description.substring(0, longitud_string) }{punto.description.length >= longitud_string ? "..." : ""}</Paragraph>
            { error > 0 ? <Tag style={{ marginTop: 5 }} color="red">COORDENADAS INVÁLIDAS</Tag> : false }
        </div>
    </div>
}

export default DetallePunto