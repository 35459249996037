import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Checkbox, Col, Form, Input, Pagination, Popconfirm, Row, Table, Tag, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import { fechaATextoSimple } from "../../lib/helpers/helpers"
import EditarNuevoCampoPersonalizado from "./editar"
import { FileExcelFilled, SearchOutlined } from "@ant-design/icons"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado"
import ModalPersonalizado from "../../subComponents/general/modales/modal_personalizado"
import Paragraph from "antd/es/typography/Paragraph"
import TagSinDatos from "../../subComponents/general/tag_sin_datos"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import { estilo_moving_truck } from "../../lib/estilo_sitio"

const ListadoBodegas = (props)=> {
    const {
        condicion_default,
        hideSearch,
        showSelection,
    } = props
    const dispatch = useDispatch()
    const sesion                    = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [deleting, setDeleting]   = useState(false)
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ]     = useState(1)
    const [ loadingRegistros, setLoadingRegistros] = useState([])
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ]       = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const pais                      = useSelector(state => state.pais)
    const session                   = useSelector(state => state.miusuario)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const idioma                    = useSelector(state => state.idioma)
    const trash                     = props.trash ? props.trash : false
    const tipo                      = props.tipo ? props.tipo : "link"
    const titulo                    = props.title ? props.title : 'registros'
    const key_interfaz              = 'tabla_bodegas'
    const key_esquema               = 'BODEGAS'
    const module                    = 'bodegas'
    const exclude = []

    const obtenerRegistros = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${module}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    const eliminarRecurso = async (id) => {
        setDeleting(true)
        return fetch(`${urlapi}/${module}?id=${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(res._id){
              messageApi.success("Eliminado exitosamente")
              obtenerRegistros(1, false)
            }
            return setDeleting(false)
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setDeleting(false)
          })
      }
  

    useEffect(() => {
        obtenerRegistros(1, false)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerRegistros(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination showSizeChanger={false} defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const deseleccionarTodos = (e) => {
        setConductores([...[], ...conductores.map(veh => { 
            if(exclude){
                if( Array.isArray(exclude) !== false ){
                    if(exclude.includes(veh._id)) return veh
                }
            }
            veh.seleccionado = e.target.checked
            return veh 
        })])
      }

      const checkForEnabledValue = (id) => {
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const validarChecked = (seleccionado, id) => {
        if(seleccionado === true) return true
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const handleChangeSeleccion = (e) => {
        const { name } = e.target
        const valor = e.target.checked
        const i = conductores.findIndex(ve => ve._id === name )
        conductores[i].seleccionado = valor
        setConductores([...[], ...conductores])
      }

      const eliminarMultiples = async ()=>{
        setLoadingRegistros(true)

        const seleccionados = conductores.filter(e => e.seleccionado).map(e => e._id)
        return fetch(`${urlapi}/${module}/masive`,{
            method:'DELETE',
            body: JSON.stringify({
                ids: seleccionados
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.deletedCount){
                return window.location.reload()
            }
            return setLoadingRegistros(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingRegistros(false)
        })
    }

      const accionesEspeciales = () => {
        const seleccionados = conductores.filter(e => e.seleccionado)
        return <div style={{ marginBottom: 20 }}>
            <Button disabled={seleccionados.length < 1} loading={loadingRegistros} style={{ marginRight: 10 }} size="small" onClick={() => eliminarMultiples()} >ELIMINAR</Button>
        </div>
      }

    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />

        const component_title = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="titulo" />} render={(data) => {
            return <div>{data.titulo}</div>
        }} />
        const component_ciudad = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="ciudad" />} render={(data) => {
            return <div>{data.geodata?.level3}</div>
        }} />
        const component_pais = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="pais" />} render={(data) => {
            return <div>{data.geodata?.pais}</div>
        }} />
        const component_cliente = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="cliente" />} render={(data) => {
            return <div>{data.cliente?.razon_social}</div>
        }} />

        const component_direccion = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="direccion" />} render={(data) => {
            return <div>{data.direccion}</div>
        }} />
        const component_encargado = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="encargado" />} render={(data) => {
            return <div>{data.encargado}</div>
        }} />
        const component_cargo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="cargo" />} render={(data) => {
            return <div>{data.cargo ? <b><br/>{data.cargo}</b> : <TagSinDatos /> }</div>
        }} />
        const component_email_encargado = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="email_encargado" />} render={(data) => {
            return <div>{data.email}</div>
        }} />
        const component_movil_encargado = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="movil_encargado" />} render={(data) => {
            return <div><b>{data.pais_codigo ? `+${data.pais_codigo}` : ''}</b>{data.telefono}</div>
        }} />

        const component_horarios = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="horarios" />} render={(data) => {
            
            const campo = data
            const component_h = <div>
                {campo.horarios && Array.isArray(campo.horarios) && campo.horarios.map((horario, index) => {

                    if(!horario.horario1?.inicio && !horario.horario1?.final && !horario.horario2?.inicio && !horario.horario2?.final ) return <div>
                        <Title level={4} className="mt-0 mb-0"><b>{horario.dia}</b></Title>
                        <Paragraph>Sin información</Paragraph>
                        </div>

                    let texto_horario = ''

                    if(horario.horario1?.inicio) texto_horario = `Desde las ${horario.horario1?.inicio}`
                    if(horario.horario1?.final) texto_horario = `${texto_horario} hasta las ${horario.horario1?.final}`
                    if(horario.horario2?.inicio) texto_horario = `${texto_horario} y desde las ${horario.horario2?.inicio}`
                    if(horario.horario2?.final) texto_horario = `${texto_horario} hasta las ${horario.horario2?.final}`

                    return <div md={8} key={`c-${index}`}>
                        <Title level={4} className="mt-0 mb-0"><b>{horario.dia}</b></Title>
                        <Paragraph className="mb-0">{texto_horario}</Paragraph>
                    </div>})}
                    
            </div>

            return <div><ModalPersonalizado typeView="modal"  version="v2" title="VER HORARIOS" component={component_h}  /> </div>
        }} />

        return <div>
            {paginacion(data.pagina, total)}
            {accionesEspeciales()}
            <Table dataSource={conductores} pagination={false} size="small" bordered scroll={estilo_moving_truck.scroll_row} >
            {
            showSelection !== true ? false : <Column title={<Checkbox onChange={deseleccionarTodos} />} render={(data) => {
                return <Checkbox disabled={checkForEnabledValue(data._id)} checked={validarChecked(data.seleccionado, data._id)} name={data._id} onChange={handleChangeSeleccion}/>
            }} />
            }
                { encontrarLlaveValorEnEsquema("titulo", interfaz_usuario[ key_interfaz ]) === false ? false : component_title }
                { encontrarLlaveValorEnEsquema("ciudad", interfaz_usuario[ key_interfaz ]) === false ? false : component_ciudad }
                { encontrarLlaveValorEnEsquema("pais", interfaz_usuario[ key_interfaz ]) === false ? false : component_pais }
                { encontrarLlaveValorEnEsquema("cliente", interfaz_usuario[ key_interfaz ]) === false ? false : component_cliente }
                { encontrarLlaveValorEnEsquema("direccion", interfaz_usuario[ key_interfaz ]) === false ? false : component_direccion }
                { encontrarLlaveValorEnEsquema("encargado", interfaz_usuario[ key_interfaz ]) === false ? false : component_encargado }
                { encontrarLlaveValorEnEsquema("cargo", interfaz_usuario[ key_interfaz ]) === false ? false : component_cargo }
                { encontrarLlaveValorEnEsquema("email_encargado", interfaz_usuario[ key_interfaz ]) === false ? false : component_email_encargado }
                { encontrarLlaveValorEnEsquema("movil_encargado", interfaz_usuario[ key_interfaz ]) === false ? false : component_movil_encargado }
                { encontrarLlaveValorEnEsquema("horarios", interfaz_usuario[ key_interfaz ]) === false ? false : component_horarios }
                
                <Column title="Editar" render={(data) => {
                    return <div><EditarNuevoCampoPersonalizado id_registro={data._id} onCreate={() => obtenerRegistros(1, false)} /></div>
                }} />
                <Column title="Eliminar" render={(data) => {
                    return <div>
                        <Popconfirm
                    title="Eliminar"
                    description="¿Estás seguro que deseas eliminar recurso? Esta acción no se puede deshacer"
                    onConfirm={() => eliminarRecurso(data._id)}
                    onCancel={() => false}
                    okText="SI"
                    style={{ display: "inline-block"}}
                    cancelText="NO"
                  >
                  <Button loading={deleting} size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
                  </div>
                }} />
            </Table>
        </div>
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setCondicionBusqueda(condicion_default);
            setPagina(1);
            return obtenerRegistros(1, condicion_default);
        }
        const condicion = {
            ...condicion_default,
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerRegistros(1, condicion);
      };

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

    const buscador = () => {
        if(hideSearch === true) return false
        return <Col span="auto"><Form onSubmitCapture={handleSubmit}>
                    <Row gutter={15}>
                        <Col md={14}>
                            <Form.Item label="Buscar">
                                <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Buscar por palabras clave`} />
                            </Form.Item>
                        </Col>
                        <Col md={10}>
                            <Form.Item>
                                <Button onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
        </Col>
      }

      const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/bodegas`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-bodegas-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }


    return <div>
        {contextHolder}
        <Row gutter={15}>
            {buscador()}
            <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
            {/* <Col span="auto"><Button className="mb-3" ><FileExcelFilled /> DESCARGAR PDF INFORMATIVO</Button></Col> */}
            <Col span="auto"><Button loading={loadingExcel} className="mb-3" onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col>
        </Row>
        
        {mostrarRegistros()}
    </div>
}
export default ListadoBodegas