import { Button, Col, Form, Input, Modal, Row, Select, Switch, Tag } from "antd"
import Paragraph from "antd/es/typography/Paragraph"
import Title from "antd/es/typography/Title"
import { useState } from "react"
import { maps_key } from "../../lib/backend/data"
import ReactGoogleAutocomplete from "react-google-autocomplete"
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps"
import { opciones_origenes, optiones_estricto_tiempo } from "./data"
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main"
import { useSelector } from "react-redux"
import { siEsRequeridoEnEsquema } from "../../lib/helpers/helpers"
import { formatoMoneda } from "../../lib/helpers/main"
import ButtonGroup from "antd/es/button/button-group"
import { MdDeleteOutline } from "react-icons/md"
import SelectorGeneral from "../General/selector"

const DetalleVehiculo = (props) => {
    const {
        data
    } = props
    const [ show, setShow ] = useState(false)
    const [ edit, setEdit ] = useState(false)
    const punto = data
    const pais = useSelector(state => state.pais)
    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
    const requeridos = []
    const showModal = () => {
        setShow(true);
    };

    const handleOk = () => {
        setShow(false);
    };

    const handleCancel = () => {
        setShow(false);
    };

    const minutos = punto.duration / 60

    const handleChangeSelect = (val, key) => {
        let actual = {...punto}
            actual[key] = val
            if(props.onChange) props.onChange(actual)
    }

    const handleChangeSelectSpecial = (val, key) => {
        let actual = {...punto}
        actual[key] = val
        if(props.onChange) props.onChange(actual)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        let actual = {...punto}
        actual[name] = value
        if(props.onChange) props.onChange(actual)
    }
    
    const handleCheckDescanso = (e) => {
        let actual = {...punto}
        actual.descanso = e
        if(props.onChange) props.onChange(actual)
    }

    const mostrarCapacidad = () => {

        const boton = <Col md={24} xs={24}><Button onClick={() => setEdit(!edit)}>EDITAR</Button> </Col>

        if(edit){
            return <Row gutter={15}>
            <Col md={8} xs={24}>
                <Form.Item label="Ancho" required={siEsRequeridoEnEsquema('ancho',requeridos)} >
                <Input addonBefore={punto.unidad_medida} mb={3} variant="filled" type="number" name="ancho" value={punto.ancho} onChange={handleChange} />
                </Form.Item>
            </Col>
            <Col md={8} xs={24}>
                <Form.Item label="Alto" required={siEsRequeridoEnEsquema('alto',requeridos)} >
                <Input addonBefore={punto.unidad_medida} mb={3} variant="filled" type="number" name="alto" value={punto.alto} onChange={handleChange} />
                </Form.Item>
            </Col>
            <Col md={8} xs={24}>
                <Form.Item label="Largo" required={siEsRequeridoEnEsquema('largo',requeridos)} >
                <Input addonBefore={punto.unidad_medida} mb={3} variant="filled" type="number" name="largo" value={punto.largo} onChange={handleChange} />
                </Form.Item>
            </Col>
            <Col md={12} xs={24}>
                <Form.Item label="Capacidad de peso" required={siEsRequeridoEnEsquema('peso',requeridos)} >
                <Input mb={3} addonBefore={punto.unidad_medida_peso} variant="filled" type="number" name="peso" value={punto.peso} onChange={handleChange} />
                </Form.Item>
            </Col>
            {boton}
            </Row>
        }


        return <Row gutter={15}>
        <Col md={8} xs={24}>
            <Paragraph className="mb-0">Ancho</Paragraph>
            <Title level={3} className="mb-0 mt-0">{punto.ancho} <Tag className="mb-3">{punto.unidad_medida}</Tag></Title>
        </Col>
        <Col md={8} xs={24}>
            <Paragraph className="mb-0">Alto</Paragraph>
            <Title level={3} className="mb-0 mt-0">{punto.alto} <Tag className="mb-3">{punto.unidad_medida}</Tag></Title>
        </Col>
        <Col md={8} xs={24}>
            <Paragraph className="mb-0">Largo</Paragraph>
            <Title level={3} className="mb-0 mt-0">{punto.largo} <Tag className="mb-3">{punto.unidad_medida}</Tag></Title>
        </Col>
        <Col md={12} xs={24}>
            <Paragraph className="mb-0">Capacidad de peso</Paragraph>
            <Title level={3} className="mb-0 mt-0">{formatoMoneda(punto.peso)} <Tag className="mb-3">{punto.unidad_medida_peso}</Tag></Title>
        </Col>
        {boton}
        </Row>
    }

    const isError = (val) => punto.descanso === true && ! val
    const errorMsg = "Ingresa un valor"

    const componente_descanso = <div>
        <Form layout="vertical">
        <Row gutter={15}>
                    <Col span={24}>
                            <Title className="mt-0" level={5}>Horario de descanso</Title>
                        <Row gutter={15}>
                            <Col span={8}>
                                <Form.Item label="Desde" validateStatus={ isError(punto.break_desde) ? "error" : "success" } help={isError(punto.break_desde) ? errorMsg : null} >
                                <Input type="time" name="break_desde" defaultValue={punto.break_desde} onChange={handleChange} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Hasta" validateStatus={ isError(punto.break_hasta) ? "error" : "success" } help={isError(punto.break_hasta) ? errorMsg : null} >
                                <Input type="time" name="break_hasta" defaultValue={punto.break_hasta} onChange={handleChange} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Duración en minutos" validateStatus={ isError(punto.break_duration) ? "error" : "success" } help={isError(punto.break_duration) ? errorMsg : null} >
                                <Input type="number" name="break_duration" defaultValue={punto.break_duration} onChange={handleChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
        </Form>
    </div>

    return <div>
        <Modal title="Configura el vehículo" open={show} onOk={handleOk} onCancel={handleCancel} footer={[]} >
            
            <Title level={4} className="mt-0">{punto.titulo}</Title>
            <Form layout="vertical">
                <Row gutter={15}>
                    <Col span={24}>
                            <Title className="mt-0" level={5}>Horario de trabajo permitido</Title>
                        <Row gutter={15}>
                            <Col span={8}>
                                <Form.Item label="Desde">
                                <Input type="time" name="horario_desde" defaultValue={punto.horario_desde} onChange={handleChange} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Hasta">
                                <Input type="time" name="horario_hasta" defaultValue={punto.horario_hasta} onChange={handleChange} />
                                </Form.Item>
                            </Col>
                            <Col md={8}>
                                <Form.Item label="Punto de llegada">
                                    <Select value={punto.end_location ? punto.end_location : ""} options={opciones_origenes} onChange={(val) => handleChangeSelect(val,'end_location')} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Switch className="mb-3" checkedChildren="DESCANSO ACTIVADO" unCheckedChildren="TOCA PARA PERSONALIZAR HORARIO DE DESCANSO" onChange={handleCheckDescanso} defaultChecked={punto.descanso===true} />

                { punto.descanso === true ? componente_descanso : false }
                
                <Row gutter={15}>
                    {/* <Col md={12}>
                        <Form.Item label="Punto de partida" >
                            <Select value={punto.start_location ? punto.start_location : "warehouse"} options={opciones_origenes} onChange={(val) => handleChangeSelect(val,'start_location')} />
                        </Form.Item>
                    </Col> */}
                    <Col md={24}>
                    <SelectorGeneral mode="multiple" module="categorias" value={punto.capabilities ? punto.capabilities.map(e => e._id) : []} titulo="Capacidades" condicion_default={{ tipo: "capacidades" }} onChange={(val) => handleChangeSelectSpecial(val, "capabilities")} />
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Cantidad máxima de bultos" required={siEsRequeridoEnEsquema('alto',requeridos)} >
                        <Input mb={3} variant="filled" type="number" name="bultos" value={punto.bultos} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                </Row>
                {mostrarCapacidad()}
            </Form>
      </Modal>
        <div>
            <Title level={4} className="mt-0 hover mb-0">{punto.titulo}</Title>
            { punto.capabilities ? punto.capabilities.map(item => <Tag style={{ marginTop: 10 }}>{item.title}</Tag> ) : false }
            <ButtonGroup className="mt-3">
                <Button onClick={() => showModal()}>CONFIGURAR</Button>
                <Button type="dashed" className="text-danger" onClick={() => props.onDelete(punto)} icon={<MdDeleteOutline />} >REMOVER</Button>
            </ButtonGroup>
        </div>
    </div>
}

export default DetalleVehiculo