import Title from "antd/es/typography/Title"
import ListadoConductores from "./listado"
import { AntDesignOutlined, ArrowDownOutlined, MobileOutlined, PlusOutlined } from "@ant-design/icons"
import Paragraph from "antd/es/typography/Paragraph"
import { BsBoxArrowInUpRight } from "react-icons/bs"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import ListadoRutas from "./listado"

const { message, Button, Modal, Card, Row, Col, Avatar } = require("antd")
const { useState } = require("react")
const { useDispatch, useSelector } = require("react-redux")

const ModalSeleccionRuta = (props) => {
    const {
        defaultValue,
        exclude,
        hideSeleccionados
    } = props
    const [ seleccionado , setSeleccionado ] = useState(defaultValue ? defaultValue : false)
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };

      const removerSeleccion = () => {
        if(props.onRemove) props.onRemove()
        return setSeleccionado(false)
      }
    
    const mostrarSeleccionado = () => {
        if(hideSeleccionados === true) return false
        if(!seleccionado) return false

        return <Card size="small" style={{ marginTop: 20 }} >
            <Paragraph className="mb-0">Ruta seleccionada</Paragraph>
            <Title level={4} className="mb-0 mt-0">{seleccionado.id} <Link target="_blank" to={`${rutas.rutas.slug}/${seleccionado._id}`}><BsBoxArrowInUpRight /></Link></Title>
            <Button onClick={() => removerSeleccion()}>REMOVER</Button>
        </Card>
    }

    const seleccionarRegistro = (data) => {
        if(props.onSelect) props.onSelect(data)
        setIsModalOpen(false)
        setSeleccionado(data)
    }

    return <div>
        <Button type="dashed" onClick={showModal} icon={<PlusOutlined />} >SELECCIONAR RUTA</Button>

        {mostrarSeleccionado()}

        <Modal width={"80%"} open={isModalOpen} footer={[]} onCancel={handleCancel}>
            <Title level={2} className="mt-0 mb-0"><ArrowDownOutlined /> Selecciona una ruta</Title>
            <Paragraph>Toca el identificador de la ruta para seleccionar</Paragraph>
            <ListadoRutas typeView="funcion" hideHeader={true} onSelect={data => seleccionarRegistro(data)} />
      </Modal>
    </div>
}

export default ModalSeleccionRuta