import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Card, Col, Divider, Row } from 'antd';
import { useState } from "react";
import ButtonGroup from "antd/es/button/button-group";
import ListadoSubCategorias from "./listado";
import CrearNuevoRegistro from "./crear";
import MenuCategorias from "../../subComponents/general/menu_categorias";
import { rutas } from "../../lib/routes/routes";

const SubCategoriasProductos = (props) => {
    const {
        tipo,
        titulo
    } = props
    const [ condicion_busqueda, setCondicionBusqueda ] = useState({ tipo })
    const titular = titulo ? titulo : "Sub Categorías"

    const render = () => {
        return <div>
            <Card>
            <Title className="mb-2 mt-0">{titular}</Title>
            <ButtonGroup>
            <CrearNuevoRegistro tipo={tipo} onCreate={() => setCondicionBusqueda(prev => ({...{}, ...condicion_busqueda}))} />
            </ButtonGroup>
            <MenuCategorias default_selected="sub-categorias" menu_categorias={rutas.products_categories.slug} menu_sub_categorias={rutas.products_subcategories.slug} />
            <Divider />
                <ListadoSubCategorias tipo={tipo} condicion_default={condicion_busqueda} />
            </Card>
        </div>
    }
    
    return <Structure component={render()} />
}

export default SubCategoriasProductos