import { useDispatch, useSelector } from "react-redux"
import data, { limit_page_options, urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Checkbox, Col, Divider, Form, Input, Pagination, Row, Table, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import { fechaUTCATexto } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { FileExcelFilled, SearchOutlined } from "@ant-design/icons";
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import ContadoresVehiculos from "./contadores"
import { Esquema } from "../../lib/esquemas/tablas"
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores"
import ModalPersonalizado from "../../subComponents/general/modales/modal_personalizado"
import { rutas } from "../../lib/routes/routes"
import SelectorGeneral from "../General/selector"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import { modificarInterfaz } from "../../redux/actions/interfaz"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado"
import MostrarMoviles from "../../lib/helpers/mostrar_moviles"
import MostrarEmails from "../../lib/helpers/mostrar_emails"
import HelperModal from "../../subComponents/general/helperModal"
import SortBy from "../../subComponents/general/sort_by"
import { obtenerLimitesPagina } from "../../lib/helpers/main"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import TagSinDatos from "../../subComponents/general/tag_sin_datos"

const ListadoClientes = (props)=> {
    const {
        condicion_default,
        showFilters,
        showCustomFieldsData,
        showCounters,
        hideSearch,
        showSelection
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ conductores, setRegistros] = useState([])
    const [ loadingRegistros, setLoadingRegistros] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const pais = useSelector(state => state.pais)
    const trash                     = props.trash ? props.trash : false
    const idioma                    = useSelector(state => state.idioma)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const tipo                      = props.tipo ? props.tipo : "link"
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const titulo                    = props.title ? props.title : 'registros'
    const module = 'clientes'
    const key_interfaz              = 'tabla_clientes'
    const key_esquema               = 'CLIENTES'
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const exclude = []
    const default_limit_page                = obtenerLimitesPagina(limit_page_options, 'limit_page', interfaz_usuario, module)

    const deseleccionarTodos = (e) => {
        setRegistros([...[], ...conductores.map(veh => { 
            if(exclude){
                if( Array.isArray(exclude) !== false ){
                    if(exclude.includes(veh._id)) return veh
                }
            }
            veh.seleccionado = e.target.checked
            return veh 
        })])
    }

    const checkForEnabledValue = (id) => {
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

    const activarDesactivarTabla = (value, column) => {
        const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario) )
        const i = instancia_esquema[key_interfaz].findIndex(e => e.key === column.llave)
        if(i > -1){
            instancia_esquema[key_interfaz][i].active = value
        }
        console.log({ instancia_esquema, i, column })
        return dispatch(modificarInterfaz(instancia_esquema, sesion.tokenSession))
    }

    const obtenerDetallesEsquema = (slug) => {
        const i = Esquema[ key_esquema ].findIndex(e => e.key === slug)
        if( i > -1){
            return {
                ...Esquema[ key_esquema ][i],
                llave: Esquema[ key_esquema ][i].key
            }
        }
        return {}
    }

    const obtenerDatos = async (page, query, limit)=>{
        setLoadingRegistros(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${module}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma,
                limit: limit ? limit : default_limit_page
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingRegistros(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingRegistros(false)
            }
            if(Array.isArray(res.datos) !== false){
                setRegistros(res.datos)
                setTotal(res.total)
            }
            return setLoadingRegistros(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingRegistros(false)
        })
    }

    useEffect(() => {
        obtenerDatos(1, false)
    }, [ condicion_default ])

    const sortBy = (data, type) => {
        if(type === "sort_config"){
            const {
                sort_column,
                sort_order
            } = data
            setPagina(1)
            obtenerDatos(1, null, { sort_column, sort_order })

        } else if( type === "limit_pagination" ){
            setPagina(1)
            obtenerDatos(1, null, data.limit_page)            
        }
    }

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerDatos(e, condicion_busqueda)
    }
    
    const refrescarBusqueda = () => {
        setPagina(1)
        let cond = {}
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            }
        })
        obtenerDatos(1, cond)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Row gutter={15}>
                <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
                <Col span="auto"><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green"  onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col>
                <Col span="auto"><SortBy type="clientes" onChange={(data, type) => sortBy(data, type)} /></Col>
            </Row>
            
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination current={pagina} total={(cantidad*10)} showSizeChanger={false} onChange={handleChangePagina} />
        </div>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/clients`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setPagina(1)
            setCondicionBusqueda({})
            return obtenerDatos(1, {})
        }
        const condicion = {
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerDatos(1, condicion);
      };

      const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

      
    const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit} >
        {/* <Title className="mb-0 mt-0" level={5}>BUSCAR</Title> */}
        <Row gutter={10}>
            <Col md={6}>
                <Form.Item className="mb-2">
                    <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Razón social o identificador fiscal`} />
                </Form.Item>
            </Col>
            <Col md={4}>
                <Form.Item >
                    <Button  onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                </Form.Item>
            </Col>
        </Row>
                </Form>
      }

      const eliminarMultiples = async ()=>{
        setLoadingRegistros(true)

        const seleccionados = conductores.filter(e => e.seleccionado).map(e => e._id)
        return fetch(`${urlapi}/${module}/masive`,{
            method:'DELETE',
            body: JSON.stringify({
                ids: seleccionados
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingRegistros(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingRegistros(false)
            } else if(res.deletedCount){
                return window.location.reload()
            }
            return setLoadingRegistros(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingRegistros(false)
        })
    }


      const accionesEspeciales = () => {
        const seleccionados = conductores.filter(e => e.seleccionado)
        return <div style={{ marginBottom: 20 }}>
            <Button disabled={seleccionados.length < 1} loading={loadingRegistros} style={{ marginRight: 10 }} size="small" onClick={() => eliminarMultiples()} >ELIMINAR</Button>
        </div>
      }

      const handleChangeSeleccion = (e) => {
        const { name } = e.target
        const valor = e.target.checked
        setRegistros(prev => {
            let actual = [...prev]
            const i = actual.findIndex(ve => ve._id === name )
            actual[i].seleccionado = valor
            return actual
        })
      }

      const validarChecked = (seleccionado, id) => {
        if(seleccionado === true) return true
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const mostrarTabla = () => {
        if(loadingRegistros) return <CargandoTabla />

        const component_razon_social = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="razon_social" />} render={(data) => {
                    return <div><Link to={`${rutas.clients.slug}/${data._id}`}>{data.razon_social}</Link></div>
        }} />

        const component_nombre_comercial = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="nombre_comercial" />} render={(data) => {
                    return <div>{data.nombre_comercial}</div>
        }} />

        const component_ciudad = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="ciudad" />} render={(data) => {
            return <div>{data.geodata?.level1}</div>
        }} />
        const component_estado = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="estado" />} render={(data) => {
            return <div>{data.geodata?.level2}</div>
        }} />
        const component_localidad = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="localidad" />} render={(data) => {
            return <div>{data.geodata?.level3}</div>
        }} />
        const component_pais = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="pais" />} render={(data) => {
            return <div>{data.geodata?.pais}</div>
        }} />
        const component_web = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="web" />} render={(data) => {
            return <div>{data.web}</div>
        }} />

        const component_rut = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="rut" />} render={(data) => {
                    return <div>{data.rut}</div>
        }} />
        const component_categoria = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="categoria" />} render={(data) => {
                    return <div>{data.categoria ? data.categoria : <TagSinDatos /> }</div>
        }} />
        const component_subcategoria = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="subcategoria" />} render={(data) => {
                    return <div>{data.sub_categoria ? data.sub_categoria : <TagSinDatos /> }</div>
        }} />

        const component_direccion = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="direccion" />} render={(data) => {
                    return <div>{data.direccion}</div>
        }} />
        const component_moviles_adicionales = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="moviles_adicionales" />} render={(data) => {
                    return <div><MostrarMoviles datos={data.moviles_adicionales} /></div>
        }} />
        const component_emails_adicionales = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="emails_adicionales" />} render={(data) => {
                    return <div><MostrarEmails datos={data.emails_adicionales} /> </div>
        }} />

        const component_fecha = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="createdAt" />} render={(data) => {
            return <div>{fechaUTCATexto(data.createdAt)}</div>
        }} />

          return <Table dataSource={conductores} tableLayout="fixed" pagination={false} size="small" bordered scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="clientes" /> }}>
            { showSelection === true ? <Column title={<Checkbox onChange={deseleccionarTodos} />} render={(data) => {
                return <Checkbox disabled={checkForEnabledValue(data._id)} checked={validarChecked(data.seleccionado, data._id)} name={data._id} onChange={handleChangeSeleccion}/>
            }} /> : false }
            { encontrarLlaveValorEnEsquema("razon_social", interfaz_usuario[ key_interfaz ]) === false ? false : component_razon_social }
            { encontrarLlaveValorEnEsquema("nombre_comercial", interfaz_usuario[ key_interfaz ]) === false ? false : component_nombre_comercial }
            { encontrarLlaveValorEnEsquema("rut", interfaz_usuario[ key_interfaz ]) === false ? false : component_rut }
            { encontrarLlaveValorEnEsquema("direccion", interfaz_usuario[ key_interfaz ]) === false ? false : component_direccion }
            { encontrarLlaveValorEnEsquema("ciudad", interfaz_usuario[ key_interfaz ]) === false ? false : component_ciudad }
            { encontrarLlaveValorEnEsquema("estado", interfaz_usuario[ key_interfaz ]) === false ? false : component_estado }
            { encontrarLlaveValorEnEsquema("localidad", interfaz_usuario[ key_interfaz ]) === false ? false : component_localidad }
            { encontrarLlaveValorEnEsquema("pais", interfaz_usuario[ key_interfaz ]) === false ? false : component_pais }
            { encontrarLlaveValorEnEsquema("categoria", interfaz_usuario[ key_interfaz ]) === false ? false : component_categoria }
            { encontrarLlaveValorEnEsquema("subcategoria", interfaz_usuario[ key_interfaz ]) === false ? false : component_subcategoria }
            { encontrarLlaveValorEnEsquema("web", interfaz_usuario[ key_interfaz ]) === false ? false : component_web }
            { encontrarLlaveValorEnEsquema("moviles_adicionales", interfaz_usuario[ key_interfaz ]) === false ? false : component_moviles_adicionales }
            { encontrarLlaveValorEnEsquema("emails_adicionales", interfaz_usuario[ key_interfaz ]) === false ? false : component_emails_adicionales }
            { encontrarLlaveValorEnEsquema("createdAt", interfaz_usuario[ key_interfaz ]) === false ? false : component_fecha }
      </Table>
      }

      const agregarFiltro = (key, value) => {
        filtroBusqueda[key] = { $in: value.map(v => v._id) }
        console.log(filtroBusqueda)
        setFiltroBusqueda(filtroBusqueda)
      }

      const resetFilters = () => {
        setFiltroBusqueda({})
        setKeyInputFilter(key_input_filter + 1)
        obtenerDatos(1, condicion_default ? condicion_default : {})
    }

      const mostrarFiltrosBusqueda = () => {
        const mostrarButtonReset = Object.keys(filtroBusqueda).length > 0 ? true : false
        return <div style={{ marginBottom: 20 }} className="mt-0">
            <Row gutter={10}>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Categoría</Title>
                    <SelectorGeneral key={key_input_filter} hideLabel={true} mode="multiple" module="categorias" titulo="Categoría" condicion_default={{ tipo: "cliente" }} onChange={(data) => agregarFiltro('id_categoria', data)} />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Sub categoría</Title>
                    <SelectorGeneral key={key_input_filter} hideLabel={true} mode="multiple" module="sub-categorias" titulo="Categoría" condicion_default={{ tipo: "cliente" }} onChange={(data) => agregarFiltro('id_sub_categoria', data)} />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Filtrar</Title>
                    <Button loading={loadingRegistros} onClick={() => refrescarBusqueda()} style={{ width: "100%" }} icon={<SearchOutlined />} >ACTUALIZAR</Button>
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mb-2 mt-0" level={5}>FILTRAR</Title>
                    <Button disabled={!mostrarButtonReset}  style={{ width: "100%" }} onClick={() => resetFilters()}>BORRAR FILTROS</Button>
                </Col>
            </Row>
        </div>
      }

      const mostrarContadores = () => {
        return false
        const component = <ContadoresVehiculos onFilter={(condition) => {
            setPagina(1)
            setCondicionBusqueda(condition)
            obtenerDatos(1, condition)
        }} />

        return showCounters === true ? <Col><ModalPersonalizado typeView="modal" slug="distrubion" component={component} /></Col> : false
      }
      
      const mostrarDatosPersonalizados = () => {
        const component = <GraficosCamposPersonalizados typeView="modal" tipo="cliente" />
        return showCustomFieldsData === true ? <Col><ModalPersonalizado typeView="modal" slug="informe_personalizado" component={component} /></Col> : false
      }

    const mostrarRegistros = () => {

        return <div>
            <Divider />
            {
                showFilters === true ? <Row gutter={15}>
                <Col md={24}>
                    {mostrarFiltrosBusqueda()}
                    {buscador()}
                </Col>
                <Col md={24}>
                    <Row gutter={15} className="mb-3">
                    {mostrarContadores()}
                    {mostrarDatosPersonalizados()}
                    </Row>
                    {paginacion(data.pagina, total)}
                    {accionesEspeciales()}
                    {mostrarTabla()}
                </Col>
            </Row> : <div>
                    {mostrarContadores()}
                    {buscador()}
                    {paginacion(data.pagina, total)}
                    {accionesEspeciales()}
                    {mostrarTabla()}
                </div>
            }
            
            
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoClientes