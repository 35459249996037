import { Button, Card, Col, Input, Row, Upload, Cascader, DatePicker, Form, InputNumber, Mentions, Select, TreeSelect, Divider, Layout, Checkbox, Modal, Tooltip, Tag, Switch, Drawer, Space, message, } from "antd";
import React, { useEffect, useState } from "react";
import Structure from "../Structure";
import Title from "antd/es/typography/Title";
import { TbSelect } from "react-icons/tb";
import { FaCloudUploadAlt, FaHeading, FaRegTrashAlt } from "react-icons/fa";
import { BsCalendarDate, BsInputCursorText } from "react-icons/bs";
import { RxDividerHorizontal } from "react-icons/rx";
import { RiCheckboxLine } from "react-icons/ri";
import { MdOutlineDateRange, MdOutlineEmail } from "react-icons/md";
import { BiText } from "react-icons/bi";
import Paragraph from "antd/es/typography/Paragraph";
import ButtonGroup from "antd/es/button/button-group";
import { urlapi } from "../../lib/backend/data";
import { main_data } from "./data";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";

const CrearFormulario = (props) => {
    
    const sizeIcon = 25

    const elementos = [
        {
            id: "",
            slug: "input",
            title: "Campo de texto",
            icon: <BsInputCursorText size={sizeIcon}  />
        },
        {
            id: "",
            slug: "email",
            title: "Email",
            icon: <MdOutlineEmail size={sizeIcon}  />
        },
        {
            id: "",
            slug: "texto",
            title: "Texto",
            icon: <BiText size={sizeIcon}  />
        },
        {
            id: "",
            slug: "title",
            title: "Título",
            icon: <FaHeading size={sizeIcon}  />
        },
        {
            id: "",
            slug: "archivo",
            title: "Carga",
            icon: <FaCloudUploadAlt size={sizeIcon}  />
        },
        {
            id: "",
            slug: "divisor",
            title: "Divisor",
            icon: <RxDividerHorizontal size={sizeIcon}  />
        },
        {
            id: "",
            slug: "select",
            title: "Selector",
            icon: <TbSelect size={sizeIcon}  />
        },
        {
            id: "",
            slug: "checkbox",
            title: "Checkbox",
            icon: <RiCheckboxLine  size={sizeIcon} />
        },
        {
            id: "",
            slug: "datepicker",
            title: "Datepicker",
            icon: <MdOutlineDateRange size={sizeIcon}  />
        },
        {
            id: "",
            slug: "rangepicker",
            title: "Range Picker",
            icon: <BsCalendarDate size={sizeIcon}  />
        },
    ]
    const [ campos, setCampos ] = useState([])
    const [ indexSeleccionado, setIndexSeleccionado ] = useState(-1)
    const [open, setOpen] = useState(false)
    const [ loading, setLoading ] = useState()
    const [ registro, setRegistro ] = useState({})
    const [messageApi, contextHolder] = message.useMessage()
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)


    const crearRegistro = async ()=>{
        if(!registro.title) return messageApi.error("Escribe un título")
        const instancia = { ...registro }
        instancia.formulario = campos
        setLoading(true)
        return fetch(`${urlapi}/${main_data.slug_module}`,{
            method:'POST',
            body: JSON.stringify(instancia),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success('Registro creado correctamente')
                setRegistro({})
                setCampos([])
                setOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    const handleCancel = () => {
        setIndexSeleccionado(-1);
    }
    
    const agregarOpcionSelector = () => {
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual[indexSeleccionado].values.push({})
            return actual
        }) 
    }

    const eliminarValorOpcion = (i) => {
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual[indexSeleccionado].values.splice(i, 1)
            return actual
        })
    }

    const handleChangeValorSelector = (e, i) => {
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual[indexSeleccionado].values[i].value = e.target.value
            return actual
        })
    }

    const renderModalContent = (componenteSeleccionado) => {
        if (!componenteSeleccionado) return null;

        switch (componenteSeleccionado.slug) {
            case 'input':
                return <div className="mb-3">
                <Form.Item className="mb-3" label="Valor de ejemplo">
                    <Input type="text" value={componenteSeleccionado.example_value} name="example_value" onChange={handleChange} />
                </Form.Item>
                <Form.Item className="mb-3" label="Valor predeterminado">
                    <Input type="text" value={componenteSeleccionado.default_value} name="default_value" onChange={handleChange} />
                </Form.Item>
            </div>
            case 'select': 
                return <div className="mb-3">
                    <Form.Item className="mb-3" label="Opciones">
                    {
                        componenteSeleccionado.values.map((opcion, i) => {
                            return <Input addonBefore={<FaRegTrashAlt className="hover" onClick={() => i ? eliminarValorOpcion(i) : false } />} className="mb-3" placeholder="Escribe un valor" value={opcion.value} onChange={(e) => handleChangeValorSelector(e, i)} />
                        })
                    }
                    </Form.Item>
                    <Button style={{ width: "100%" }} onClick={() => agregarOpcionSelector()}>AGREGAR OPCIÓN</Button>
                </div>
            case 'checkbox':
                return <div className="mb-3">
                    <Switch checkedChildren="ACTIVO POR DEFECTO" unCheckedChildren="INACTIVO POR DEFECTO" checked={componenteSeleccionado.default_checked === true} onChange={(e) => handleChange({ target: { name: "default_checked", value: e } })} />
                </div>
            default:
                return <div>Este componente no es editable.</div>;
        }
    }

    const agregarElemento = (elm) => {
        const nuevo_elemento = {
            id: "",
            label: "",
            example_value: "",
            default_value: "",
            values : [{}],
            min_max: 0,
            default_checked: false,
            max_val: 0,
            slug : elm.slug,
            id_elemento : elm.id,
            required: false
        }
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual.push(nuevo_elemento)
            return actual
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampos(prev => {
            let actual = [ ...prev ]
            actual[indexSeleccionado][name] = value
            return actual
        })
    }
    
    const handleChangeRegistro = (e) => {
        const { name, value } = e.target
        return setRegistro(prev => {
            let actual = { ...prev }
            actual[name] = value
            return actual
        })
    }

    const mostrarModal = () => {
        let objeto = indexSeleccionado > -1 ? campos[indexSeleccionado] : {}

        return <Modal
            title="Editar Componente"
            open={indexSeleccionado > -1}
            onOk={handleCancel}
            footer={[]}
            onCancel={handleCancel}
        >
            <Form layout="vertical">
            <Form.Item className="mb-3" label="Etiqueta">
            <Input type="text" value={objeto.label} name="label" onChange={handleChange} />
            </Form.Item>
            <Form.Item className="mb-3" label="Texto de ayuda">
            <Input type="text" value={objeto.help_text} name="help_text" onChange={handleChange} />
            </Form.Item>
            {renderModalContent(objeto)}
            <Switch checkedChildren="CAMPO OBLIGATORIO" unCheckedChildren="CAMPO OPCIONAL" checked={objeto.required === true} onChange={(e) => handleChange({ target: { name: "required", value: e } })} />
            </Form>
        </Modal>
    }

    const verConfiguracion = (i) => {
        setIndexSeleccionado(i)
    }

    const eliminarCampo = (i) => {
        setCampos(prev => {
            let actual = [ ...prev ]
            actual.splice(i, 1)
            return actual
        })
    }
    const render = () => {
        return (
            <div>

                <Form layout="vertical">
                    <Form.Item className="mb-3" label="Título">
                        <Input type="text" value={registro.title} name="title" onChange={handleChangeRegistro} />
                    </Form.Item>
                </Form>
                <Row gutter={15}>
                <Col md={12}>
                <Card >
                <Title className="mt-0" level={3}>Formulario</Title>
                {
                    campos.map((campo, i) => {
                        return <Card className="hover" key={`campo-${i}`} size="small" style={{ marginBottom: 10 }} >
                            <Row>
                                <Col>
                                    <Row>
                                        <Col><Tag className="mt-0 mb-2" style={{ textTransform: "uppercase" }} level={5}>{campo.slug} {campo.required === true ? <b style={{ color: "red" }}>*</b> : false } </Tag></Col>
                                        <Col>{ campo.label ? <Title className="mt-0 mb-3" level={5}>{campo.label}</Title> : false }</Col>
                                    </Row>
                                </Col>
                                <Col>
                                </Col>
                            </Row>
                                <ButtonGroup>
                                    <Button size="small" onClick={() => verConfiguracion(i)}>CONFIGURAR</Button>
                                    <Button size="small" icon={<FaRegTrashAlt />} onClick={() => eliminarCampo(i)}>ELIMINAR</Button>
                                </ButtonGroup>
                            </Card>
                    })
                }
                    {mostrarModal()}
                </Card>
                </Col>
                <Col md={12}>
                <Card>
                <Title className="mt-0" level={3}>Elementos</Title>
                <Row gutter={10}>
                    {
                        elementos.map((elm,i) => {
                            return <Col md={12} className="hover" key={`el-${i}`} style={{ marginBottom: 10 }}>
                                <Card size="small" onClick={() => agregarElemento(elm)} >
                                {elm.icon}
                                <Paragraph style={{ margin: 0 }}><b>{elm.title}</b></Paragraph>
                                </Card>
                            </Col>
                        })
                    }
                </Row>
                </Card>
                </Col>
                </Row>
            </div>
        );
    };

    const showDrawer = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }

    return <div>
        <Button type="primary" onClick={showDrawer}>CREAR NUEVO</Button>
        <Drawer title="CREAR NUEVO" onClose={onClose} open={open} size="large" extra={
          <Space>
            <Button disabled={loading} onClick={onClose}>CANCELAR</Button>
            <Button loading={loading} onClick={() => crearRegistro()} type="primary"  >GUARDAR</Button>
          </Space>
        } >
                {render()}    
        </Drawer>
        {contextHolder}
    </div>
};

export default CrearFormulario