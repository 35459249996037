export const getNombreDestinatario = (pedido) => {
    let destinatario = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    destinatario = `${pedido.orden.billing.first_name ? pedido.orden.billing.first_name : ''} ${pedido.orden.billing.last_name ? pedido.orden.billing.last_name : ''}`
                }
            }
        }
    }
    return destinatario
}

export const getDireccion = (pedido) => {
    let direccion = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    direccion = `${pedido.orden.billing.address_1} ${pedido.orden.billing.address_2 ? pedido.orden.billing.address_2 : ''}`
                }
            }
        }
    }
    return direccion
}

export const getDireccion1 = (pedido) => {
    let direccion = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    direccion = pedido.orden.billing.address_1
                }
            }
        }
    }
    return direccion
}

export const getDireccion2 = (pedido) => {
    let direccion = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    direccion = pedido.orden.billing.address_2
                }
            }
        }
    }
    return direccion
}

export const getEmail = (pedido) => {
    let valor = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    valor = `${pedido.orden.billing.email}`
                }
            }
        }
    }
    return valor
}

export const getTelefono = (pedido) => {
    let valor = ''
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.billing){
                if(typeof pedido.orden.billing === 'object'){
                    valor = `${pedido.orden.billing.phone}`
                }
            }
        }
    }
    return valor
}

export const canalEstadosString = (str = String) => {
    switch (str) {
        case 'app_conductor':
            return 'App Conductor'
        case 'sistema-web':
            return 'Sistema Web'
        default:
            return str
    }
}

export const showByTipoServicio = (tipo, tipos) => {
    const i = tipos.findIndex(tip => tip.value === tipo)
    if(i > -1) return tipos[i].label
    return `No identificado`
}

export const obtenerGeoValor = (pedido, key) => {
    let direccion = ''
    if(!key) return "Llave es requerida"
    if(pedido.orden){
        if(typeof pedido.orden === 'object'){
            if(pedido.orden.geo_datos){
                if(typeof pedido.orden.geo_datos === 'object'){
                        direccion = pedido.orden.geo_datos[key]
                }
            }
        }
    }
    return direccion ? direccion : "Sin información"
}