import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Card } from "antd"
import ListadoNotificaciones from "./listado"

const Notificaciones = (props) => {

    const render = () => {
        return <div>
            <Card>
            <Title className="mb-2 mt-0">Notificaciones</Title>
            <ListadoNotificaciones />
            </Card>
        </div>
    }
    
    return <Structure component={render()} />
}

export default Notificaciones