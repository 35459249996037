import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { maps_key, urlapi } from "../../lib/backend/data";
import AutoComplete from "react-google-autocomplete";
import { SaveOutlined } from "@ant-design/icons";
import CargandoTabla from "./cargando";
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps";
import { AiOutlineMail } from "react-icons/ai";
import Title from "antd/es/typography/Title";
import { GoDeviceMobile } from "react-icons/go";
import { codigos_area } from "../../lib/internacional";
import { filterOption, siEsRequeridoEnEsquema } from "../../lib/helpers/helpers";
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main";
import { main_data } from "./data";
import SelectorGlobal from "../../subComponents/general/selector";
import SelectorClientes from "../Clientes/selector";

const EditarContactoRapido = (props) => {
    const {
        id_registro
    } = props
    const [ campo, setCampo ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ showSelectorCliente, setShowSelectorCliente ] = useState(true)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(true)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
    const dispatch = useDispatch()
    const module = main_data.module

    const requeridos = [
        { value: 'identificador', label: 'Descripción' }
    ];

    const obtenerDatos = async () => {
        if(!id_registro) return messageApi.error("ID no válido")
        setLoading(true)
        return fetch(`${urlapi}/${module}?id=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setCampo(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    const guardarCambios = async () => {

        let faltantes = []
        requeridos.map(field => {
            if(!campo[field.value]) faltantes.push(field.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  

        
        setLoadingSave(true)
        return fetch(`${urlapi}/${module}`,{
            method:'PUT',
            body: JSON.stringify(campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                setIsModalOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoadingSave(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
      obtenerDatos()
    };

    const handleOk = () => {
        return guardarCambios()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChangeSelectProducto = (e,name) => {
        console.log(e)
        campo[name] = e
        return setCampo({...{}, ...campo})
    }
    
    const handleChange = (e) => {
        const { name, value } = e.target
        campo[name] = value
        return setCampo({...{}, ...campo})
    }

    const handleChangeCustom = (value,name) => {
        campo[name] = value
        console.log(campo)
        return setCampo({...{}, ...campo})
    }

    const handleChangeCategory = (e, name) => {
        campo[name] = e._id
        return setCampo(campo)
    }

    const mostrarSelectorClientes = () => {
        if(!showSelectorCliente) return false

        return <div>
            <SelectorClientes value={campo.id_cliente} titulo="Cliente" onChange={(data) => handleChangeCustom(data,'id_cliente') } condicion={{}} />
        </div>
    }
    
    const formulario = () => {
        if(loading) return <CargandoTabla />

        return <div>
            {mostrarSelectorClientes()}
            <Form layout="vertical">
                <Row gutter={15} >
                <Col md={24}>
                    <Form.Item label="Descripción" required={siEsRequeridoEnEsquema('identificador',requeridos)} className="mb-0">
                    <Input className=' mb-3' name="identificador"  value={campo.identificador} onChange={handleChange} />
                    </Form.Item>
                </Col>
                <Col md={24}>
                    <Form.Item label="Observaciones" required={siEsRequeridoEnEsquema('observaciones',requeridos)} className="mb-0">
                    <Input className=' mb-3' name="observaciones"  value={campo.observaciones} onChange={handleChange} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                        <Form.Item label="Tarifa" required={siEsRequeridoEnEsquema('tarifa',requeridos)} >
                        <Input mb={3}   value={campo.tarifa} variant="filled" type="number" name="tarifa" onChange={handleChange} />
                        </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                        <Form.Item label="Tarifa confirmada" required={siEsRequeridoEnEsquema('tarifa_confirmada',requeridos)} >
                        <Input mb={3}   value={campo.tarifa_confirmada} variant="filled" type="number" name="tarifa_confirmada" onChange={handleChange} />
                        </Form.Item>
                </Col>
                <Col md={12}>
                <SelectorGlobal titulo="Categoría" value={campo.tipo} condicion={{ tipo: main_data.module }} module="categorias" key_label="title" key_value="_id" onChange={(data) => handleChangeCategory(data, 'tipo')} />
                </Col>
                <Col md={12}>
                <SelectorGlobal titulo="Sub Categoría" value={campo.subtipo} condicion={{ tipo: main_data.module }} module="sub-categorias" key_label="title" key_value="_id" onChange={(data) => handleChangeCategory(data, 'subtipo')} />
                </Col>
                    <Col md={24}>
                        <Button type="primary" loading={loadingSave} onClick={() => guardarCambios()} ><SaveOutlined />GUARDAR CAMBIOS</Button>
                    </Col>
                </Row>
                
            </Form>
        </div>
    }
    
    return <div>
        <Button  className="m-0" size="small" type="dashed" onClick={showModal}>EDITAR</Button>
        <Modal 
            title="Editar" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
            footer={[]}
        >
        {formulario()}
      </Modal>
      {contextHolder}
    </div>
}

export default EditarContactoRapido