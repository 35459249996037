import { Button, Modal } from "antd"
import { useEffect, useState } from "react"
import ListadoAlertas from "../../GeoAlertas/listado"
import { AlertFilled } from "@ant-design/icons"

const ModalAlertasZona = (props) => {
    const {
        show,
        condicion_default
    } = props
    const [ showModal, setShowModal ] = useState(false)

    const handleCancel = () => {
        setShowModal(false)
    }

    return <div>
        <Button size="small" style={{ width: "100%" }} icon={<AlertFilled />} onClick={() => setShowModal(true)}>ALERTAS</Button>
        <Modal width={"80%"} open={showModal} footer={[]} onCancel={handleCancel}>
            <ListadoAlertas condicion_default={condicion_default} />
      </Modal>
    </div>
}

export default ModalAlertasZona