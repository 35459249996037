import Title from "antd/es/typography/Title"
import { AntDesignOutlined, ArrowDownOutlined, MobileOutlined, PlusOutlined } from "@ant-design/icons"
import Paragraph from "antd/es/typography/Paragraph"
import ListadoTiposVehiculo from "./listado"
import ListadoEquipos from "./listado"
import { cerrarSesion } from "../../redux/actions/sesion"
import { urlapi } from "../../lib/backend/data"

const { message, Button, Modal, Card, Row, Col, Avatar } = require("antd")
const { useState } = require("react")
const { useDispatch, useSelector } = require("react-redux")

const ModalCompartirRuta = (props) => {
    const {
        defaultValue,
        isStatic,
        id_vehiculo,
        exclude,
        showSelection,
        hideSeleccionados
    } = props
    const [ seleccionado , setSeleccionado ] = useState(defaultValue ? defaultValue : false)
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ loading, setLoading ] = useState(false)
    const module = 'equipos/asign'

    const relacionar = async (equipos)=>{
        setLoading(true)
        return fetch(`${urlapi}/${module}/`,{
            method:'POST',
            body: JSON.stringify({
                id_vehiculo,
                equipos: equipos.map(e => e._id)
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                if(props.onCreate) props.onCreate()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            setIsModalOpen(false)
            return setLoading(false)
        })
    }

    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };

      const removerSeleccion = () => {
        if(props.onRemove) props.onRemove()
        return setSeleccionado(false)
      }
    
    const mostrarSeleccionado = () => {
        if(hideSeleccionados === true) return false
        if(!seleccionado) return false

        return <Card size="small" style={{ marginTop: 20 }} >
            <Row>
                <Col md={20} xs={18}>
                    <Paragraph className="mb-0">Seleccionado</Paragraph>
                    <Title level={4} className="mb-0 mt-0">{seleccionado.titulo}</Title>
                    <Button onClick={() => removerSeleccion()}>REMOVER</Button>
                </Col>
                <Col md={4} xs={6} style={{ textAlign: "right" }}>
                <Avatar
                    src={seleccionado.imagen_perfil}
                    size={{ xs: 80, sm: 70, md: 30, lg: 64, xl: 80, xxl: 100 }}
                    icon={<AntDesignOutlined />}
                />
                </Col>
            </Row>
        </Card>
    }

    const seleccionarRegistro = (data) => {
        if(props.onSelect) props.onSelect(data)
        if(isStatic === true){

        } else {
            relacionar(data)
        }
        setIsModalOpen(false)
        // setSeleccionado(data)
    }

    return <div>
        <Button loading={loading} disabled={loading} type="dashed" onClick={showModal} icon={<PlusOutlined />} >ASOCIAR EQUIPO</Button>

        <Modal width={"80%"} open={isModalOpen} footer={[]} onCancel={handleCancel}>
            <Title level={2} className="mt-0 mb-0"><ArrowDownOutlined /> Selecciona un equipo</Title>
            <Paragraph>Toca el registro que deseas seleccionar</Paragraph>
            <ListadoEquipos exclude={exclude} showSelection={showSelection} typeView="funcion" hideHeader={true} onSelect={data => seleccionarRegistro(data)} />
      </Modal>
      {contextHolder}
    </div>
}

export default ModalCompartirRuta