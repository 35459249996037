import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"
import ProcesarCanalModal from "../Canales/modal_seleccion"
import EscaneoBultoRemoverInput from "./escaneo_remover_bulto"
import PantallaCargando from "../../subComponents/general/pantalla_cargando"
import { url_images, urlapi } from "../../lib/backend/data"
import { Button, Card, Col, Divider, Row, Spin, message } from "antd"
import socket from "../../lib/websockets"
import Structure from "../Structure"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import EscaneoBultoInput from "./escaneo_bulto"
import { BsBox } from "react-icons/bs"
import { FaCheckCircle, FaChevronRight, FaMobile } from "react-icons/fa"
import { MdError } from "react-icons/md"

const ExpedicionesControl = (props) => {
    const session = useSelector(state => state.miusuario)
    const [ conductores, setConductores ] = useState([])
    const [ pedidos, setPedidos ] = useState([])
    const [ bultos, setBultos ] = useState([])
    const [ conductorSeleccionado, setConductorSeleccionado ] = useState(false)
    const [ emitiendoCanal, setEmitiendoCanal ] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loadingMaster, setLoadingMaster ] = useState(true)

    const obtenerDatos = async () => {
        setLoadingMaster(true)
        return fetch(`${urlapi}/bodega/expediciones/list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
            console.log(pros)
              if(!pros){
                toast.error('Sin datos del servidor')
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
              } else if(pros.pedidos){
                if(Array.isArray(pros.pedidos) !== false){
                    setPedidos(pros.pedidos)
                }
                if(Array.isArray(pros.conductores) !== false){
                    setConductores(pros.conductores)
                }
                if(Array.isArray(pros.bultos) !== false){
                    setBultos(pros.bultos)
                }
              }
              return setLoadingMaster(false)
          })
          .catch(async error => {
            console.log(error)
              toast.error("No pudimos consultar la información de este pedido, intente de nuevo recargando la página")
                return setLoadingMaster(false)
          })
    }

    const validarPedido = async (id, idconductor) => {
        return fetch(`${urlapi}/viajes/details`, {
            method: 'POST',
            body: JSON.stringify({
                id,
                campos: [ 'bultos', 'refrigerado', 'congelado', 'pedido']
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Sin datos del servidor')
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
              } else if(pros._id){
                const bultos = conteoBultos(pros)
                pedidos.push({
                    _id: pros._id,
                    pedido: pros.identificador ? pros.identificador : pros.internal_id,
                    bultos: pros.bultos.length,
                    idconductor
                })
                console.log(pedidos)
                return setPedidos([...[], ...pedidos])
              }
          })
          .catch(async error => {
              console.log(error)
            return toast.error("No pudimos consultar la información de este pedido, intente de nuevo recargando la página")
          })
    }

    const eliminarBulto = (idbulto) => {
        console.log({idbulto,conductores})
        const i = bultos.findIndex(b => b._id === idbulto)
        bultos.splice(i,1)

        conductores.map((c,po) => {
            if(!c.bultos) return false
            c.bultos.map((bu,i) => {
                if(bu.idbulto === idbulto) conductores[po].bultos.splice(i,1)
            })
        })
        setConductores([...[], ...conductores])
        return setBultos([...[], ...bultos])
    }

    const procesarBulto = async (etiqueta) => {
        console.log(etiqueta)
        const ya_existe = bultos.filter(b => b.idbulto === etiqueta.idbulto)
        if(ya_existe.length > 0) return true
        bultos.unshift(etiqueta)
        const i = conductores.findIndex(con => con._id === etiqueta.idconductor)
        if(i > -1){
            if(!conductores[i].bultos) conductores[i].bultos = []
            conductores[i].bultos.push(etiqueta)
            const ipedido = pedidos.findIndex(flete => flete._id === etiqueta.idrecurso)
            if(ipedido < 0) validarPedido(etiqueta.idrecurso, etiqueta.idconductor)
        } else {
            const nuevo_conductor = etiqueta.conductor
            nuevo_conductor.bultos = [etiqueta]
            conductores.unshift(nuevo_conductor)
            const ipedido = pedidos.findIndex(flete => flete._id === etiqueta.idrecurso)
            if(ipedido < 0) validarPedido(etiqueta.idrecurso, etiqueta.idconductor)
        }
        setBultos([...[], ...bultos])
        return setConductores([...[], ...conductores])
    }
    
    useEffect(() => {
        obtenerDatos()
        const identificador_socket_1 = `${session.data.propietario}_escaneo_bultos`
        console.log(identificador_socket_1)
        socket.on(identificador_socket_1, etiqueta => {
            return procesarBulto(etiqueta)
        })
        return () => {
            socket?.off(identificador_socket_1)
        }
    }, [])

    const conteoBultos = (flete) => {
        let cantidad_bultos = 0
        let bultos_secos = parseInt(flete.bultos)
        let bultos_congelado = parseInt(flete.congelado)
        let bultos_refrigerado = parseInt(flete.refrigerado)
        if(isNaN(bultos_secos) !== true) cantidad_bultos = cantidad_bultos + bultos_secos
        if(isNaN(bultos_congelado) !== true) cantidad_bultos = cantidad_bultos + bultos_congelado
        if(isNaN(bultos_refrigerado) !== true) cantidad_bultos = cantidad_bultos + bultos_refrigerado
        cantidad_bultos = cantidad_bultos ? cantidad_bultos : 1
        return cantidad_bultos
    }

    

    const mostrarListadoConductores = () => {

        let seleccionado = ''
        if(conductorSeleccionado) seleccionado = conductorSeleccionado._id
        return <div>
            <Title level={3} className="mb-0">Conductores</Title>
            <Title level={3} className="mb-0">{conductores.length} activos</Title>
            <Paragraph className="mb-0">Los conductores a continuación tienen bultos escaneados desde la app pendientes de gestionar</Paragraph>
            <Divider className="mb-2 mt-2" />
            {
                conductores.map((conductor,i) => {
                    return <Card size="small" className={`shadow hover ${seleccionado === conductor._id ? 'text-white' : ''}`} bg={seleccionado === conductor._id ? 'primary' : 'light'} key={conductor._id} onClick={() => setConductorSeleccionado(conductor)}>
                        <Row gutter={15}>
                            <Col md={16}>
                            <Title level={4} className="mb-0 mt-0">{conductor.nombres}</Title>
                            <Title level={4} className="mb-0 mt-0">BULTOS: {conductor.bultos.length}</Title>
                            </Col>
                            <Col md={8} style={{ textAlign: "right", verticalAlign: "middle"}}>
                            <FaChevronRight/>
                            </Col>
                        </Row>
                        { (i + 1) === conductores.length ? false : <Divider className="mt-3 mb-3" /> }
                    </Card>
                })
            }
        </div>
    }

    const removerPedido = (id) => {
        const i = pedidos.findIndex(p => p._id === id)
        if(i < 0) return false
        pedidos[i].idconductor = false
        return setPedidos([...[], ...pedidos])
    }

    const emitirCanal = async (idcanal, pedidos_conductor) => {
        
        if(pedidos_conductor.length < 1) return toast.error('Sin pedidos para aprobar')
            let incompletos = []
        for(const pedido of pedidos_conductor){
            const bultos_escaneados = bultos.filter(b => b.idconductor === conductorSeleccionado._id && b.idrecurso === pedido._id)
            if(bultos_escaneados.length !== pedido.bultos) incompletos.push(pedido.pedido)
            }
        if(incompletos.length > 0) return toast.error(`Pedidos presentan discordancias. ${incompletos.join(', ')}`)
            const pedidos_enviar = pedidos_conductor.map(p => p._id)
        const payload_enviar = {
            pedidos: pedidos_enviar,
            idconductor: conductorSeleccionado._id,
            idcanal
        }
        return messageApi.error('Función no habilitada, falta despliegue de parte de DELPA')
        setEmitiendoCanal(true)
        return fetch(`${urlapi}/bodega/canal/emitir`, {
            method: 'POST',
            body: JSON.stringify(payload_enviar),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Sin datos del servidor')
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
              } else if(pros.success === true){
                toast.success(`Enviado exitosamente órdenes escaneadas por ${pros.conductor}`, { autoClose: false })    
            }
            // this.obtenerExpediciones()
            //this.setState({ [loading]: false, id_conductor_seleccionado: false })
            return setEmitiendoCanal(false)
          })
          .catch(async error => {
            setEmitiendoCanal(false)
            return toast.error("No pudimos consultar la información de este pedido, intente de nuevo recargando la página")
          })
    }
    
    const mostrarDetallesEscaneoConductor = () => {
        if(!conductorSeleccionado) return <div className='p-5'>
        <Row justify="center" style={{ textAlign: 'center' }}>
            <Col md={8}>
                <img src={`${url_images}/animations/Man scanning parcel using Code Reader.gif`} style={{ width: "50%" }} />
                <Title level={4} className="mb-0 mt-0">Selecciona un conductor para visualizar su operación</Title>
            </Col>
        </Row>
        </div>

        const i_driver = conductores.findIndex(driver => driver._id === conductorSeleccionado._id)
        if(i_driver < 0) return false
        const driver = conductores[i_driver]
        const pedidos_conductor = pedidos.filter(ped => ped.idconductor === conductorSeleccionado._id)
        const bultos_conductor = bultos.filter(ped => ped.idconductor === conductorSeleccionado._id)

        return <div>
            <Card bg="primary" className="text-light mb-3">
            <Row gutter={15}>
                <Col md={24}>
                <Title className="mb-0 mt-0">{driver.nombres ? driver.nombres : ''} {driver.apellidos ? driver.apellidos : ''}</Title>
                <Title className="mb-3 mt-0" style={{ padding: "5px 10px", display: "inline-block", border: "1px solid", fontSize:15 }}><FaMobile style={{ verticalAlign: "middle" }} /> MÓVIL: {driver.codigo_movil}{driver.movil}</Title>
                </Col>
                <Col md={4}>
                <Title level={4} className="mb-0 mt-0">Pedidos</Title>
                <Title level={3} className="mb-0 mt-0">{pedidos_conductor.length}</Title>
                </Col>
                <Col md={4}>
                <Title level={4} className="mb-0 mt-0">Bultos</Title>
                <Title level={3} className="mb-0 mt-0">{bultos_conductor.length}</Title>
                </Col>
            </Row>

            
            {
                emitiendoCanal ? <div className="p-2"><Spin animation="border" /></div> : <ProcesarCanalModal typeView="modal" onSelect={(idcanal) => emitirCanal(idcanal, pedidos_conductor)} />
            }
            
            
            <Title level={4} className="mb-0 mt-0">Progreso de expedición de {conductorSeleccionado.nombres}</Title>
            <Row gutter={15}>
                
            {
                pedidos_conductor.map(ped => {
                    const bultos_escaneados = bultos.filter(b => b.idconductor === conductorSeleccionado._id && b.idrecurso === ped._id)
                    return <Col md={6}>
                        <Card size="small" className={`p-3 mt-3 text-white shadow ${ bultos_escaneados.length ===  ped.bultos ? 'bg-success' : 'bg-danger' }`}>
                        <Paragraph className="mb-0 mt-0" style={{ fontSize: 11 }}>REFERENCIA</Paragraph>
                        <Title level={5} className='mb-0 mt-0'>{ped.pedido}</Title>
                        <Title level={5} className='mb-0 mt-0'><BsBox style={{ verticalAlign: "middle" }} /> {bultos_escaneados.length} de {ped.bultos}
                        { bultos_escaneados.length ===  ped.bultos ? <FaCheckCircle style={{ verticalAlign: "middle", color: "green" }} /> : <MdError style={{ verticalAlign: "middle", color: "red" }} /> }
                        </Title>
                        {bultos_escaneados.length === 0 ? <Button size="small" className='btn btn-outline-white' onClick={()=>removerPedido(ped._id)}>REMOVER PEDIDO</Button> : false }
                    </Card>
                    </Col>
                })
            }
            </Row>
            </Card>
        </div>
    }

    
    const render = ()  => {
        if(loadingMaster === true) return <PantallaCargando />
        return <div>
            {contextHolder}
            <Card>
            <Title className="mb-2 mt-0"><BsBox style={{ verticalAlign: "middle" }} /> Expediciones</Title>
            <Row gutter={15}>
                <Col md={24}>
                    <Row>
                        <Col md={2}>
                        <Title level={4} className="mb-0 mt-0">Bultos</Title>
                        <Title level={3} className="mb-0 mt-0">{bultos.length}</Title>
                        </Col>
                        <Col md={2}>
                        <Title level={4} className="mb-0 mt-0">Pedidos</Title>
                        <Title level={3} className="mb-0 mt-0">{bultos.length}</Title>
                        </Col>
                    </Row>
                </Col>
                <Col md={24}>
                    <Divider className="mb-3 mt-3"/>
                    <Row gutter={15}>
                        <Col md={12}><EscaneoBultoInput onScanned={data => procesarBulto(data)}  /></Col>
                        <Col md={12}><EscaneoBultoRemoverInput onScanned={idbulto => eliminarBulto(idbulto)}  /></Col>
                    </Row>
                </Col>
                <Col xs={24} className="mt-3">
                    <Row gutter={15}>
                        <Col md={6}>
                            {mostrarListadoConductores()}
                        </Col>
                        <Col md={18}>
                            {mostrarDetallesEscaneoConductor()}
                        </Col>
                    </Row>
                </Col>
            </Row>
            </Card>
        </div>
    }

return <Structure component={render()} />
}

export default ExpedicionesControl