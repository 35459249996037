import { useDispatch, useSelector } from "react-redux";
import { urlapi } from "../../lib/backend/data";
import { useEffect, useState } from "react";
import { cerrarSesion } from "../../redux/actions/sesion";
import { Form, Select, message } from "antd";

const SelectorGeneral = (props) => {
    const {
        static_options,
        defaultValue,
        mode,
        placeholder,
        hideLabel,
        error,
        tipo,
        module,
        titulo,
        condicion_default,
        value,
        key
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ opcionesComplete, setOpcionesComplete ] = useState([])
    const [ opciones, setOpciones ] = useState([])
    const pais =  useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()
    const label = titulo ? titulo : 'Opción'
    const llave = key ? `${module}-${titulo}-${key}` : `${module}-${titulo}`

    const obtenerDatos = async () => {
        if(static_options) {
          setOpcionesComplete(static_options)
          setOpciones(static_options.filter(m => m.title).map(op => ({ value: op._id, label: op.title.toUpperCase() })))
          return setLoading(false)
        }
        setLoading(true)
        return fetch(`${urlapi}/${module}/list/select`, {
          method: 'POST',
          body: JSON.stringify({
            condicion: condicion_default
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              setOpcionesComplete(res)
              setOpciones(res.filter(m => m.title).map(op => ({ value: op._id, label: op.title.toUpperCase() })))
            }
            return setLoading(false);
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }

      const onChangeValue = (e) => {
        if(mode === "multiple"){
          let valores = []
          for( const id of e ){
            const i = opcionesComplete.findIndex(m => m._id === id)
            valores.push(opcionesComplete[i])
          }
          if(props.onChange) props.onChange(valores)
        } else {
          const i = opcionesComplete.findIndex(m => m._id === e)
          if(props.onChange) props.onChange(opcionesComplete[i])
        }
      }

      useEffect(() => {
        obtenerDatos()
      }, [ condicion_default ])

      const componente = <Select
      key={llave}
      mode={mode ? mode : ""}
      value={value}
      allowClear
      style={ error === true ? {
        width: "100%",
        border: "1.5px solid red",
        borderRadius: 8
    } : { width: "100%" }}
    placeholder={placeholder ? placeholder : ""}
      filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
      showSearch={true}
      loading={loading}
      options={opciones}
      onChange={onChangeValue}
      />

      return <div>
        {contextHolder}
        <Form layout="vertical">
        {
          hideLabel === true ? componente : <Form.Item label={label} required>
            {componente}
          </Form.Item>
        }
        </Form>
      </div>
}

export default SelectorGeneral