import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Alert, Button, Card, Checkbox, Col, Divider, Form, Input, Modal, Row, Select, Spin, Steps, Tooltip, message } from 'antd';
import { useEffect, useState } from "react";
import HelperModal from "../../subComponents/general/helperModal";
import { rutas } from "../../lib/routes/routes";
import { Link } from "react-router-dom";
import { FileExcelFilled } from "@ant-design/icons";
import { main_data } from "./datos";
import Paragraph from "antd/es/typography/Paragraph";
import SelectorGlobal from "../../subComponents/general/selector";
import { urlapi } from "../../lib/backend/data";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { FaTruckFast } from "react-icons/fa6";
import { HiCursorClick } from "react-icons/hi";
import { DateTime } from "luxon";
import { fechaUTCATexto, fechaUTCATextoSimple, filterOption, validateEmail } from "../../lib/helpers/helpers";
import { IoMailOpenOutline, IoPeopleCircleOutline } from "react-icons/io5";
import { FaHandshake, FaRegClock } from "react-icons/fa";
import { AiFillMinusCircle, AiOutlineMail } from "react-icons/ai";
import { codigos_area } from "../../lib/internacional";
import { GrAdd } from "react-icons/gr";
import { CiMobile3 } from "react-icons/ci";

const Eta = (props) => {
    const {
        showCreateButton,
        showImportButton,
        display_full,
        condicion,
    } = props
    const default_payload = { tipo: "tracking", sub_tipo: "eta" }
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion ? condicion : {})
    const [ loadingEstados, setLoadingEstados ] = useState(false)
    const [ loadingLineasTiempo, setLoadingLineasTiempo ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const [ tipoServicioSeleccionado, setTipoServicioSeleccionado ] = useState(null)
    const [ estados, setEstados ] = useState([])
    const [ lineaTiempoSeleccionada, setLineaTiempoSeleccionada ] = useState(null)
    const [ showModal, setShowModal ] = useState(false)
    const [ savingLineaTiempo, setSavingLineaTiempo ] = useState(false)
    const [ lineasTiempo, setLineasTiempo ] = useState([])
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const modulo = main_data.module

    useEffect(() => {
        obtenerLineasTiempo()
    }, [])

    const obtenerEstados = async () => {
        setLoadingEstados(true)
        return fetch(`${urlapi}/details`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res.estados) !== false){
                setEstados(res.estados)
            }
            return setLoadingEstados(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingEstados(false)
        })
    }

    const guardarCambios = () => {
        let actual = {...lineaTiempoSeleccionada}
        // delete actual.detalles
        actualizarLineaTiempo(actual)
    }

    const actualizarLineaTiempo = async (linea_tiempo) => {
        setSavingLineaTiempo(true)
        return fetch(`${urlapi}/configuracion/advance`,{
            method:'PUT',
            body: JSON.stringify({ 
                condicion_busqueda: {...default_payload, valor: tipoServicioSeleccionado?._id },
                payload: {...linea_tiempo, valor: tipoServicioSeleccionado?._id }
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                const i = lineasTiempo.findIndex(item => item.valor === res.valor)
                if( i > -1){
                    setLineasTiempo(prev => {
                        let actual = [...prev]
                        actual[i] = res
                        return actual
                    })
                } else {
                    setLineasTiempo(prev => {
                        let actual = [...prev]
                        actual.push(res)
                        return actual
                    })
                
                }
            }
            return setSavingLineaTiempo(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setSavingLineaTiempo(false)
        })
    }

    const obtenerLineasTiempo = async () => {
        setLoadingLineasTiempo(true)
        return fetch(`${urlapi}/configuracion/advance/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion: default_payload
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setLineasTiempo(res)
            }
            return setLoadingLineasTiempo(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingLineasTiempo(false)
        })
    }

    const mostrarBotonCreacion = () => {
        if(showCreateButton === false) return false
        return <div>
            <Row gutter={15}>
              <Col span="auto">
                {/* <CrearNuevoRegistro showClientSelector={showClientSelector} default_payload={default_payload ? default_payload : {}} onCreate={() => setCondicionBusqueda(prev => {
                let actual = {...prev}
                return {...{}, ...actual}
              })} /> */}
              </Col>
              { showImportButton === false ? false :  <Col span="auto"><Button style={{ marginRight: 10 }} size="small"><Link to={rutas.import_products.slug}><FileExcelFilled /> IMPORTADOR</Link></Button></Col> }
              <Col span="auto"><HelperModal tipo={modulo} style="modal" /></Col>
            </Row>
            <Divider />
        </div>
    }

    const seleccionarTipoServicio = (data) => {
        setTipoServicioSeleccionado(false)
        const i = lineasTiempo.findIndex(item => item.valor === data?._id)
        if( i > -1){
            setLineaTiempoSeleccionada(lineasTiempo[i])
        } else {
            setLineaTiempoSeleccionada(default_payload)
        }
        setTimeout(() => {
            setTipoServicioSeleccionado(data)
        }, 200);
    }

    const agregar = (estado_completo) => {
        return setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.hitos.push(estado_completo)
            actualizarLineaTiempo(actual)
            return {...{}, ...actual}
        })
    }
    
    const handleChangeMovil = (e, pos, name) => {
        let value = e
        if(typeof e === "object") if(e.target) value = e.target.value
        return setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.detalles.moviles_adicionales[pos][name] = value
            return actual
        })
    }

    const removerMovil = (i) => {
        // lineaTiempoSeleccionada.moviles_adicionales.splice(i,1)
        return setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.detalles.moviles_adicionales.splice(i,1)
            return actual
        }) 
    }
    
    const anadirMovilAdicional = () => {
        // if(!lineaTiempoSeleccionada.moviles_adicionales) lineaTiempoSeleccionada.moviles_adicionales = []
        // lineaTiempoSeleccionada.moviles_adicionales.unshift({
        //     pais_codigo: '',
        //     movil: ''
        // })
        return setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            if(!actual.detalles) actual.detalles = {}
            if(!actual.detalles.moviles_adicionales) actual.detalles.moviles_adicionales = []
            actual.detalles.moviles_adicionales.unshift({
                pais_codigo: '',
                movil: ''
            })
            return actual
        })
    }

    const anadirEmailAdicional = () => {
        
        return setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            if(!actual.detalles) actual.detalles = {}
            if(!actual.detalles.emails_adicionales) actual.detalles.emails_adicionales = []
            actual.detalles.emails_adicionales.unshift({
                email: ''
            })
            return actual
        })
    }

    const removerEmail = (i) => {
        // lineaTiempoSeleccionada.emails_adicionales.splice(i,1)
        return setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.detalles.emails_adicionales.splice(i,1)
            return actual
        
        }) 
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        // lineaTiempoSeleccionada.emails_adicionales[pos][name] = value
        // lineaTiempoSeleccionada.emails_adicionales[pos].valid = validateEmail(value)
        return setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            actual.detalles.emails_adicionales[pos][name] = value
            actual.detalles.emails_adicionales[pos].valid = validateEmail(value)
            return actual
        })
    }

    const mostrarEmailsAdicionales = () => {
        if(!lineaTiempoSeleccionada.detalles?.emails_adicionales) return false
        return <div>
            {
                lineaTiempoSeleccionada.detalles?.emails_adicionales.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-3">
                        <Card size="small" title="Email" className="mb-3" extra={<Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>} >
                                <Form.Item label="" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el email" prefix={<AiOutlineMail />} className='mb-0' status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} />
                                </Form.Item>
                        </Card>
                    </div>
                })
            }
        </div>
    }

    const mostrarMovilesAdicionales = () => {

        

        if(!lineaTiempoSeleccionada.detalles?.moviles_adicionales) return false
        return <div>
            {
                lineaTiempoSeleccionada.detalles?.moviles_adicionales.map((field,i) => {
                    return <div key={`movil-${i}`} className="mt-3">
                    <Card size="small" title="Móvil" className="mb-3" extra={<Button type="ghost" onClick={() => removerMovil(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />}>REMOVER</Button>} >
                        <Row gutter={15}>
                            <Col md={8}>
                                <Form.Item label="Código de área" required={true} className="mb-0">
                                <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={field.pais_codigo} onChange={(e) => handleChangeMovil(e,i,"pais_codigo")} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                                </Form.Item>
                            </Col>
                            <Col md={16}>
                                <Form.Item label="Número" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el número telefónico" className='form-control mb-3' name="movil" value={field.movil} onChange={(e) => handleChangeMovil(e,i,"movil")} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    </div>
                })
            }
        </div>
    }

    const formatearCampo = (name, value) => {
        if(name === "flexibilidad") return parseInt(value)
        if(name === "flexibilidad_dias") return parseInt(value)
        if(name === "flexibilidad_horas") return parseInt(value)
        if(name === "flexibilidad_minutos") return parseInt(value)
        if(isNaN(value)) return ''
        return value
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            if(!actual.detalles) actual.detalles = {}
            actual.detalles[name] = formatearCampo(name,value)
            return actual
        })
    }
    
    const handleChangeCheck = (e) => {
        const { name } = e.target
        const value = e.target.checked
        return setLineaTiempoSeleccionada(prev => {
            let actual = {...prev}
            if(!actual.detalles) actual.detalles = {}
            actual.detalles[name] = value
            return actual
        })
    }

    const mostrarLineaTiempo = () => {
        if(!tipoServicioSeleccionado) return <div>
            <Title className="mt-0" level={5}>Selecciona un tipo de servicio para ver la configuración asociada al ETA</Title>
        </div>
        
        return <div>
            <Title level={3} className="mt-0 mb-0">Esta es tu configuración para <b>{savingLineaTiempo ? <Spin /> : false } {tipoServicioSeleccionado?.title}</b></Title>
            {/* <Alert className="mb-3" message={<div><FaRegHandPointer /> Reordena los estados arrastrandolos hacia arriba y abajo. <Button onClick={() => setShowModal(true)}>PREVISUALIZAR</Button></div>} type="info" /> */}
            <Divider />
            <Alert style={{ marginBottom: 20 }} message={<div><Title className="mt-0 mb-0" level={3}><FaRegClock style={{ verticalAlign: "middle" }} /> Flexibilidad en los tiempos de entrega</Title> </div>} description="Los tiempos de entrega son analizados en base a la distancia e información del tráfico, configura el tiempo de flexibilidad que el sistema tomará en cuenta antes de considerar una orden como retrasada" />
            {/* <Title level={4} className="mt-0 mb-0"><FaRegClock style={{ verticalAlign: "middle" }} /> Flexibilidad en los tiempos de entrega</Title>
            <Paragraph className="mt-0">Los tiempos de entrega son analizados en base a la distancia e información del tráfico, configura el tiempo de flexibilidad que el sistema tomará en cuenta antes de considerar una orden como retrasada</Paragraph> */}
            <Form layout="vertical">
                <Row gutter={15}>
                    <Col md={8}>
                        <Form.Item label="Días">
                            <Input type="number" defaultValue={lineaTiempoSeleccionada.detalles?.flexibilidad_dias} name="flexibilidad_dias" onChange={handleChange} />
                        </Form.Item>   
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Horas">
                            <Input type="number" defaultValue={lineaTiempoSeleccionada.detalles?.flexibilidad_horas} name="flexibilidad_horas" onChange={handleChange} />
                        </Form.Item>   
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Minutos">
                            <Input type="number" defaultValue={lineaTiempoSeleccionada.detalles?.flexibilidad_minutos} name="flexibilidad_minutos" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
                <Title level={4} className="mt-0 mb-3"><FaTruckFast /> Notificar al conductor</Title>
                <Row gutter={15}>
                    <Col md={8}><Checkbox defaultChecked={lineaTiempoSeleccionada.detalles?.conductor_push === true} name="conductor_push"  onChange={handleChangeCheck} >Notificación PUSH</Checkbox></Col>
                    <Col md={8}><Checkbox defaultChecked={lineaTiempoSeleccionada.detalles?.conductor_whatsapp === true} name="conductor_whatsapp" onChange={handleChangeCheck} >Notificar Whatsapp</Checkbox></Col>
                    <Col md={8}><Checkbox defaultChecked={lineaTiempoSeleccionada.detalles?.conductor_email === true} name="conductor_email" onChange={handleChangeCheck} >Notificar Email</Checkbox></Col>
                </Row>
                <Divider />
                <Title level={4} className="mt-0 mb-3"><FaHandshake /> Notificar al cliente</Title>
                <Row gutter={15}>
                    <Col md={8}><Checkbox defaultChecked={lineaTiempoSeleccionada.detalles?.cliente_push === true} name="cliente_push" onChange={handleChangeCheck}>Notificación PUSH</Checkbox></Col>
                    <Col md={8}><Checkbox defaultChecked={lineaTiempoSeleccionada.detalles?.cliente_whatsapp === true} name="cliente_whatsapp" onChange={handleChangeCheck}>Notificar Whatsapp</Checkbox></Col>
                    <Col md={8}><Checkbox defaultChecked={lineaTiempoSeleccionada.detalles?.cliente_email === true} name="cliente_email" onChange={handleChangeCheck}>Notificar Email</Checkbox></Col>
                </Row>
                <Divider />
                <Title level={4} className="mt-0 mb-3"><IoPeopleCircleOutline /> Destinatarios adicionales</Title>
                <Row gutter={15}>
                    <Col md={12}>
                        <Title level={5} className="mt-0 mb-0"><IoMailOpenOutline /> Emails</Title>
                        <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirEmailAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarEmailsAdicionales()}</Col>
                    <Col md={12}>
                        <Title level={5} className="mt-0 mb-0"><CiMobile3 /> Móviles</Title>
                        <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirMovilAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarMovilesAdicionales()}</Col>
                </Row>
                <Divider />
                <Button loading={savingLineaTiempo} onClick={() => guardarCambios()}>GUARDAR CAMBIOS</Button>
            </Form>
        </div>

    }

    const handleCancel = () => setShowModal(false)
    
    const render = () => {
        return <div>
            <Title className="mb-2 mt-0">Configuración de ETA</Title>
            <Paragraph>Configura el análisis de tiempo estimado de entrega por tipo de servicio, esto ayudará al sistema a detectar automáticamente posibles retrasos al evaluar el tránsito.</Paragraph>
            <SelectorGlobal titulo="Tipo de servicio" condicion={{ tipo: "servicios" }} module="estadoscarga" key_label="title" key_value="_id" onChange={(data) => seleccionarTipoServicio(data)} />
            {mostrarLineaTiempo()}
        </div>
    }

    if(display_full === true ){

        const renderizar = <div>
            {contextHolder}
            <Card>{render()}</Card>
        </div>

        return <Structure component={renderizar} />
    } else {
        return render()
    }
}

export default Eta