import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import data, { key_local_storage_user } from '../../lib/backend/data'
import 'moment/locale/es';
import { showByTipoServicio } from '../../lib/helpers/pedidos/pedidos'
import { BsArrowRepeat, BsCircle, BsFillTrashFill, BsSendCheckFill, BsWhatsapp } from 'react-icons/bs'
import { AiFillCheckCircle, AiOutlineFileSearch, AiOutlineMail } from 'react-icons/ai'
import { BiSolidHelpCircle } from 'react-icons/bi'
import { MdOutlineCancel, MdPublic, MdSettingsInputAntenna } from 'react-icons/md'
import HelperModal from '../../subComponents/general/helperModal'
import { FiSettings } from 'react-icons/fi'
import { url_images } from '../../lib/global/data'
import { Button, Card, Col, Collapse, Input, Popconfirm, Select, Row, Spin, Table, Tabs, Tooltip, Form, Switch, Tag, Divider } from 'antd'
import ButtonGroup from 'antd/es/button/button-group'
import { cerrarSesion } from '../../redux/actions/sesion';
import Structure from '../Structure';
import SelectorGlobal from '../../subComponents/general/selector';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { FaRegListAlt, FaRegSave, FaRegTrashAlt } from "react-icons/fa";
import { GoPlus, GoPlusCircle } from "react-icons/go";
import { IoMdStarOutline } from 'react-icons/io';

const opciones_tipo_estado = [
    { value: "", label:"Selecciona" },
    { value: "web", label:"Web" },
    { value: "movil", label:"Móvil" },
    { value: "todos", label:"Todos" },
]
class EstadosCarga extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: props.user,
            loadingEstados: true,
            loadingMore: false,
            total: 0,
            usuarios: [],
            parametro_busqueda: '',
            loadingBusqueda: false,
            filtro_rol: '',
            loadingSubestados: true,
            rutcliente: '',
            loadingUpload: false,
            clienteconsultar: { value: this.props.idconsulta, label: this.props.razonsocial },
            tipovisualizacion: 'estados',
            estados: [],
            subestados: [],
            nuevo_estado: {},
            nuevo_subestado: {},
            id_estado_crear_subestado: false,
            eliminando: false,
            loadingAcciones: true,
            acciones: [],
            acciones_subestado_actual: [],
            eliminandoAcciones: false,
            tipos_servicio: [],
            filtro_tipo_servicio: '',
            configuracion: {},
            loadingConfiguracionNotificaciones: true,
            loadingConfiguracion: true,
            plantillas_ws: [],
            loadingPlantillasWhatsapp: true
        }

        this.handleChange = this.handleChange.bind(this)
        this.buscarUsuario = this.buscarUsuario.bind(this)
        this.handlechangeStatus = this.handlechangeStatus.bind(this)
        this.handleChangeSelectTipoServicio = this.handleChangeSelectTipoServicio.bind(this)
        this.handleChangeSelectTipoServicioFiltro = this.handleChangeSelectTipoServicioFiltro.bind(this)
        this.handleChangeAccion = this.handleChangeAccion.bind(this)
        this.handleChangeAccionArray = this.handleChangeAccionArray.bind(this)
        this.handlechangeSubestado = this.handlechangeSubestado.bind(this)
        this.handleChangeConfig = this.handleChangeConfig.bind(this)
        this.handleChangeCondicion = this.handleChangeCondicion.bind(this)

    }

    handleChange(e) {
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeAccion(e) {
        const { name, value, id } = e.target
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        acciones_subestado_actual[i][name] = value
        console.log(acciones_subestado_actual)
        return this.setState({ acciones_subestado_actual })
    }

    handleChangeCondicion(e) {
        const { name, value, id } = e.target
        const pos = e.target.getAttribute('pos')
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        acciones_subestado_actual[i].conditions[parseInt(pos)][name] = value
        console.log(acciones_subestado_actual)
        return this.setState({ acciones_subestado_actual })
    }

    eliminarCondicion(id, pos) {
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        acciones_subestado_actual[i].conditions.splice(pos, 1)
        return this.setState({ acciones_subestado_actual })
    }

    handleChangeAccionArray(e) {
        const { value, id } = e.target
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        acciones_subestado_actual[i].valor = value
        this.setState({ acciones_subestado_actual })
        const tienecoma = value.indexOf(',')
        if (tienecoma < 0) return false
        const dividir = value.split(',')

        if (!acciones_subestado_actual[i].opciones) acciones_subestado_actual[i].opciones = []
        acciones_subestado_actual[i].opciones.push(dividir[0])

        acciones_subestado_actual[i].valor = ''
        return this.setState({ acciones_subestado_actual })
    }

    componentDidMount() {
        this.getEstados()
        this.getConfiguracion('servicios', 'tipos-servicio', 'tipos_servicio')
        this.getAcciones()
        this.getPlantillasWhatsapp()
    }

    getPlantillasWhatsapp() {
        const { user } = this.state
        const loading = 'loadingPlantillasWhatsapp'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/whatsapp/plantillas`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
            .then(pros => pros.json())
            .then(data => {
                if (!data) {
                    // toast.warn('No hay configuración de notificaciones creada para este subestado', this.state.toaststyle)
                } else if (data.errorMessage) {
                    toast.error(data.errorMessage)
                    return this.setState({ [loading]: false })
                } else if (Array.isArray(data) !== false) {
                    this.setState({ plantillas_ws: data })
                }
                return this.setState({ [loading]: false })
            })
            .catch(error => {
                toast.error('No pudimos cargar las plantillas de whatsapp', this.state.toaststyle)
                this.setState({ [loading]: false })
            })
    }
/*
return fetch(`${urlapi}/${module}/list/select`, {
          method: 'POST',
          body: JSON.stringify({
            condicion
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              setOpciones(res)
            }
            return setLoading(false);
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
          */
    async getConfiguracion(tipo, subtipo, estado) {
        const { user } = this.state
        const loading = 'loadingConfiguracion'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/list/select`, {
            method: "POST",
            body: JSON.stringify({
                condicion: { tipo }
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
            .then(pros => pros.json())
            .then(data => {
                if (data.errorMessage) {
                    toast.error(data.errorMessage)
                    return this.setState({ loadingFormulario: false })
                } else if (Array.isArray(data) !== false) {
                    if (data.length > 0) {
                        const datos = data.map(e => ({ value: e._id, label: e.title }))
                        console.log({ estos: datos })
                        this.setState({ [estado]: datos })
                    }
                }
                return this.setState({ [loading]: false })
            })
            .catch(error => {
                toast.error('No pudimos cargar la información', this.state.toaststyle)
                this.setState({ [loading]: false })
            })
    }

    getEstados(propietario) {
        const { user } = this.state
        const loading = 'loadingEstados'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/details`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
            .then(pros => pros.json())
            .then(data => {
                if (data.errorMessage) {
                    toast.error(data.errorMessage)
                    return this.setState({ loadingFormulario: false })
                }
                if (data.estados.length < 1) toast.warn('No hay registros', this.state.toaststyle)
                this.setState({ [loading]: false, subestados: data.subestados, estados: data.estados })
            })
            .catch(error => {
                toast.error('No pudimos cargar la información', this.state.toaststyle)
                this.setState({ [loading]: false })
            })
    }

    buscar() {
        const { parametro_busqueda, user } = this.state
        if (!parametro_busqueda) return toast.error('El valor de búsqueda es requerido')
        if (parametro_busqueda.length < 3) return toast.error('Ingresa un valor de búsqueda válido')
        let loading = 'loadingBusqueda'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
            body: JSON.stringify({ parametro_busqueda })
        })
            .then(res => {
                if (res.status === 401) return this.props.cerrarSesion()
                return res.json()
            })
            .then(res => {
                if (!res) {
                    toast.error('Sin datos')
                    return this.setState({ [loading]: false })
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return this.setState({ [loading]: false })
                }
                if (res.length < 1) {
                    toast.warn('No se encontraron resultados')
                    return this.setState({ [loading]: false })
                }
                return this.setState({ usuarios: res, filtro_rol: '', [loading]: false })
            })
            .catch(error => {
                this.setState({ [loading]: false })
                toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
            })
    }


    buscarUsuario(e) {
        e.preventDefault()
        return this.buscar()
    }

    sinRegistros() {
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                    <h4>Aún no tienes usuarios</h4>
                    <p>Puedes crear diversos usuarios y asignar permisos a cada uno de ellos</p>
                </Col>
            </Row>
        </div>
    }

    crearEstado() {
        const { nuevo_estado, clienteconsultar, user, estados } = this.state
        if (!nuevo_estado.titulo) return toast.error('Título es requerido', this.state.toaststyle)
        if (!nuevo_estado.color) return toast.error('color es requerido', this.state.toaststyle)
        if (!nuevo_estado.codigo_estado) return toast.error('Código es requerido', this.state.toaststyle)
        if (!nuevo_estado.tipo) return toast.error('Tipo es requerido', this.state.toaststyle)
        if (!nuevo_estado.tipos_servicio) return toast.error('tipo servicio es requerido', this.state.toaststyle)
        if (nuevo_estado.tipos_servicio.length < 1) return toast.error('tipo servicio es requerido', this.state.toaststyle)
        if (nuevo_estado.tipo.length < 1) return toast.error('tipo es requerido', this.state.toaststyle)
        nuevo_estado.propietario = clienteconsultar.value
        this.setState({ loadingFormulario: true })
        return fetch(`${data.urlapi}/estadoscarga/create-or-edit-estado`, {
            method: 'POST',
            body: JSON.stringify(nuevo_estado),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                if (!pros) {
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
                } else if (pros.errorMessage) {
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
                }
                toast.success(`Realizado exitosamente`, this.state.toaststyle)
                if (!nuevo_estado._id) {
                    if (pros._id) estados.push(pros)
                }
                return this.setState({ loadingFormulario: false, estados, tipovisualizacion: 'estados' })
            })
            .catch(async error => {
                console.log(error)
                this.setState({ loadingFormulario: false })
                return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
            })
    }

    handlechangeStatus(e) {
        console.log(e)
        const { name, value } = e.target
        const { nuevo_estado } = this.state
        nuevo_estado[name] = value
        return this.setState({ nuevo_estado })
    }

    handleChangeSelectTipoServicioFiltro(e) {
        return this.setState({ filtro_tipo_servicio: e.value })
    }

    handleChangeSelectTipoServicio(e) {
        const { nuevo_estado } = this.state
        let opciones = []
        if (e) {
            e.map(valor => {
                return opciones.push(valor._id)
            })
        }
        nuevo_estado.tipos_servicio = opciones
        return this.setState({ nuevo_estado })
    }

    formularioCreacion() {
        const { loadingFormulario, loadingConfiguracion, nuevo_estado, tipos_servicio } = this.state

        if (!nuevo_estado) return false
        if (loadingFormulario) return <Spin />

        let opciones_tipo_servicio_elegidas = []

        if (nuevo_estado.tipos_servicio) {
            if (Array.isArray(nuevo_estado.tipos_servicio) !== false) {
                if (nuevo_estado.tipos_servicio.length > 0) {
                    opciones_tipo_servicio_elegidas = tipos_servicio.filter(op => {
                        return nuevo_estado.tipos_servicio.includes(op.value)
                    })
                }
            }
        }

        return <div >

            <h4>{nuevo_estado._id ? `Editar ${nuevo_estado.titulo}` : `Crear nuevo estado`}</h4>
            <Button className="btn btn-sm btn-outline-danger mb-3" onClick={() => this.setState({ tipovisualizacion: 'estados' })} >ATRÁS</Button>
            <Form layout='vertical'>
            <Row gutter={15}>
                <Col md={5} className="mb-3">
                    <Form.Item label="Título" required={true} >
                    <Input className='form-control' name="titulo" defaultValue={nuevo_estado.titulo} onChange={this.handlechangeStatus} />
                    </Form.Item>
                </Col>

                <Col md={3} className="mb-3">
                    <Form.Item label="Código" required={true} >
                    <Input className='form-control' name="codigo_estado" defaultValue={nuevo_estado.codigo_estado} onChange={this.handlechangeStatus} />
                    </Form.Item>
                </Col>

                <Col md={3} className="mb-3">
                    <Form.Item label="Color" required={true} >
                    <Input className='form-control' type="color" name="color" defaultValue={nuevo_estado.color} onChange={(e) => this.handlechangeStatus({ target: { name: "color", value: e.target.value} })} />
                    </Form.Item>
                </Col>

                <Col md={3} className="mb-3">
                    <Form.Item label="Tipo" required={true} >
                    <Select name="tipo" defaultValue={nuevo_estado.tipo} onChange={(e) => this.handlechangeStatus({ target: { name: "tipo", value: e } })}
                        options={opciones_tipo_estado}
                    />
                    </Form.Item>
                </Col>

                <Col md={6} className="mb-3">
                    <Switch className='mb-3' checkedChildren="Mostrar en la línea de tiempo de tracking" unCheckedChildren="Mostrar en la línea de tiempo de tracking"  checked={nuevo_estado.mostrar_linea_tiempo === true} onChange={(e) => this.setBoleanPropertyEstado("mostrar_linea_tiempo")} />
                    <Switch className='mb-3' checkedChildren="Opcional en la linea de tiempo" unCheckedChildren="Opcional en la linea de tiempo"  checked={nuevo_estado.opcional_linea_tiempo === true} onChange={(e) => this.setBoleanPropertyEstado("opcional_linea_tiempo")} />
                </Col>

                {
                    nuevo_estado.mostrar_linea_tiempo === true ? <Col md={6} className="mb-3">
                        <Form.Item label="Posición en la línea de tiempo" required={true} >
                        <Input className='form-control' name="posicion_linea_tiempo" defaultValue={nuevo_estado.posicion_linea_tiempo} onChange={this.handlechangeStatus} />
                        </Form.Item>
                    </Col> : false
                }

                <Col md={6} className="mb-3">
                    <SelectorGlobal value={nuevo_estado.tipos_servicio} titulo="Tipo de servicio" condicion={{ tipo: "servicios" }} isMulti={true} module="estadoscarga" key_label="title" key_value="_id" onChange={(data) => this.handleChangeSelectTipoServicio(data)} />
                </Col>

                <Col md={24} className="mb-3">
                    <Button className='btn btn-success' onClick={() => this.crearEstado()}>{nuevo_estado._id ? 'GUARDAR CAMBIOS' : 'CREAR'}</Button>
                </Col>
            </Row>
            </Form>
        </div>
    }

    editarEstado(nuevo_estado) {
        return this.setState({ tipovisualizacion: 'nuevoestado', nuevo_estado })
    }

    eliminarRegistro(id) {
        const { estados, user } = this.state
        const estado_loading = 'eliminando'
        this.setState({ [estado_loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/eliminar-estado?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
            .then(res => res.json())
            .then(res => {
                if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return this.setState({ [estado_loading]: false })
                }
                toast.success('Eliminado exitosamente', this.state.toaststyle)
                const i = estados.findIndex(a => a._id === id)
                if (i > -1) estados.splice(i, 1)
                return this.setState({ [estado_loading]: false, estados })
            })
            .catch(error => {
                toast.error("Ocurrió un error de servidor", this.state.toaststyle)
                return this.setState({ [estado_loading]: false })
            })
    }

    // solicitarEliminarAccion(id) {
    //     return confirmAlert({
    //         title: '¿Estás seguro?',
    //         message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
    //         buttons: [
    //             {
    //                 label: 'CONFIRMAR',
    //                 onClick: () => this.eliminarAccion(id)
    //             },
    //             {
    //                 label: 'CANCELAR',
    //                 onClick: () => false
    //             },
    //         ],
    //     })
    // }

    // solicitarEliminar(id) {
    //     return confirmAlert({
    //         title: '¿Estás seguro?',
    //         message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
    //         buttons: [
    //             {
    //                 label: 'CONFIRMAR',
    //                 onClick: () => this.eliminarRegistro(id)
    //             },
    //             {
    //                 label: 'CANCELAR',
    //                 onClick: () => false
    //             },
    //         ],
    //     })
    // }

    eliminarAccion(id) {
        const { acciones_subestado_actual } = this.state
        const estado_loading = 'eliminandoAcciones'
        this.setState({ [estado_loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/eliminar-accion?id=${id}`)
            .then(res => res.json())
            .then(res => {
                if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return this.setState({ [estado_loading]: false })
                }
                toast.success('Eliminado exitosamente', this.state.toaststyle)
                const i = acciones_subestado_actual.findIndex(a => a._id === id)
                if (i > -1) acciones_subestado_actual.splice(i, 1)
                return this.setState({ [estado_loading]: false, acciones_subestado_actual })
            })
            .catch(error => {
                toast.error("Ocurrió un error de servidor", this.state.toaststyle)
                return this.setState({ [estado_loading]: false })
            })
    }

    mostrarTiposServicioConcatenados(tipos) {
        const { tipos_servicio } = this.state
        if (!tipos) return false
        if (Array.isArray(tipos) !== true) return false
        if (tipos.length < 1) return false
        return tipos.map(tipo => <Tag className='mr-2' style={{ fontSize: 11 }}>{showByTipoServicio(tipo, tipos_servicio)}</Tag>)
    }

    nuevoSubEstado(id_estado_crear_subestado) {
        this.getConfiguracionNotificaciones(false)
        return this.setState({ tipovisualizacion: 'nuevosubestado', id_estado_crear_subestado, nuevo_subestado: {} })
    }

    async getConfiguracionNotificaciones(idsubestado) {
        const { user } = this.state
        const loading = 'loadingConfiguracionNotificaciones'
        if (!idsubestado) return this.setState({ [loading]: false })
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/notificacion-por-subestado?idsubestado=${idsubestado}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
            .then(pros => pros.json())
            .then(data => {
                if (!data) {
                    // toast.warn('No hay configuración de notificaciones creada para este subestado', this.state.toaststyle)
                } else if (data.errorMessage) {
                    toast.error(data.errorMessage)
                }
                return this.setState({ [loading]: false, configuracion: data ? data : {} })
            })
            .catch(error => {
                toast.error('No pudimos cargar la información', this.state.toaststyle)
                this.setState({ [loading]: false })
            })
    }

    editarSubEstado(nuevo_subestado) {
        this.setState({
            tipovisualizacion: 'nuevosubestado',
            nuevo_subestado,
            id_estado_crear_subestado: nuevo_subestado.idparent,
            acciones_subestado_actual: []
        })
        this.getConfiguracionNotificaciones(nuevo_subestado._id)
        return this.getAccionesPorSubEstado(nuevo_subestado._id)
    }

    getAccionesPorSubEstado(idsubestado) {
        const { user } = this.state
        const loading = 'loadingAcciones'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/acciones-por-subestado?idsubestado=${idsubestado}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
            .then(pros => pros.json())
            .then(data => {
                console.log(data)
                if (data.errorMessage) {
                    toast.error(data.errorMessage)
                    return this.setState({ [loading]: false })
                }
                this.setState({ [loading]: false, acciones_subestado_actual: data })
            })
            .catch(error => {
                toast.error('No pudimos cargar la información', this.state.toaststyle)
                this.setState({ [loading]: false })
            })
    }

    getAcciones() {
        const { user } = this.state
        const loading = 'loadingAcciones'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/acciones`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
            .then(pros => pros.json())
            .then(data => {
                if (data.errorMessage) {
                    toast.error(data.errorMessage)
                    return this.setState({ [loading]: false })
                }
                if (data.length < 1) toast.warn('No hay acciones disponibles para crear formularios', this.state.toaststyle)
                this.setState({ [loading]: false, acciones: data })
            })
            .catch(error => {
                toast.error('No pudimos cargar la información', this.state.toaststyle)
                this.setState({ [loading]: false })
            })
    }

    guardarCambiosAccion(id) {
        const { acciones_subestado_actual, user } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        const loading = 'loadingAcciones'
        acciones_subestado_actual[i].valor = ''
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/actualizar-accion`, {
            method: 'POST',
            body: JSON.stringify(acciones_subestado_actual[i]),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                if (!pros) {
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ [loading]: false })
                } else if (pros.errorMessage) {
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ [loading]: false })
                }
                toast.success(`Operación realizada exitosamente`, this.state.toaststyle)
                return this.setState({ [loading]: false })
            })
            .catch(async error => {
                console.log(error)
                this.setState({ [loading]: false })
                return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
            })
    }

    obligatorio(id) {
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        acciones_subestado_actual[i].required = acciones_subestado_actual[i].required === true ? false : true
        return this.setState({ acciones_subestado_actual })
    }

    removerOpcionAccion(_id, i) {
        const { acciones_subestado_actual } = this.state
        const pos = acciones_subestado_actual.findIndex(acc => acc._id === _id)
        if (pos < 0) return false
        acciones_subestado_actual[pos].opciones.splice(i, 1)
        return this.setState({ acciones_subestado_actual })
    }

    mostrarAccionesDisponibles(nuevo_subestado) {
        if (!nuevo_subestado._id) return false
        const { acciones, loadingAcciones } = this.state
        if (loadingAcciones) return <Spin animation='border' />
        if (acciones.length < 1) return <h4>No hay acciones disponibles para agregar al formulario</h4>

        return <div>
            
            <Title level={2} className='mb-2'><GoPlusCircle /> Toca la opción para agregar a este subestado</Title>
            {
                acciones.map((accion, i) => {
                    return <Button key={`accion-${i}`} onClick={() => this.crearAccion(accion.tipo_accion, nuevo_subestado._id)} className='p-2 hover' style={{ backgroundColor: 'white', border: '1px solid #767676', marginRight: 10 }} >{accion.titulo}</Button>
                })
            }
        </div>
    }

    eliminarSubEstado(id) {
        const { subestados } = this.state
        const estado_loading = 'eliminando'
        this.setState({ [estado_loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/eliminar-subestado?id=${id}`)
            .then(res => res.json())
            .then(res => {
                if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return this.setState({ [estado_loading]: false })
                }
                toast.success('Eliminado exitosamente', this.state.toaststyle)
                const i = subestados.findIndex(a => a._id === id)
                if (i > -1) subestados.splice(i, 1)
                return this.setState({ [estado_loading]: false, subestados })
            })
            .catch(error => {
                toast.error("Ocurrió un error de servidor", this.state.toaststyle)
                return this.setState({ [estado_loading]: false })
            })
    }

    // solicitarEliminarSubEstado(id) {
    //     return confirmAlert({
    //         title: '¿Estás seguro?',
    //         message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
    //         buttons: [
    //             {
    //                 label: 'CONFIRMAR',
    //                 onClick: () => this.eliminarSubEstado(id)
    //             },
    //             {
    //                 label: 'CANCELAR',
    //                 onClick: () => false
    //             },
    //         ],
    //     })
    // }

    crearSubEstado() {
        const { nuevo_subestado, subestados, user, clienteconsultar, id_estado_crear_subestado } = this.state
        if (!nuevo_subestado.titulo) return toast.error('Título es requerido', this.state.toaststyle)
        if (!nuevo_subestado.codigo_estado) return toast.error('Código es requerido', this.state.toaststyle)
        if (!nuevo_subestado.tipo) return toast.error('tipo es requerido', this.state.toaststyle)
        nuevo_subestado.propietario = clienteconsultar.value
        nuevo_subestado.idparent = id_estado_crear_subestado
        this.setState({ loadingFormulario: true })
        return fetch(`${data.urlapi}/estadoscarga/create-or-edit-subestado`, {
            method: 'POST',
            body: JSON.stringify(nuevo_subestado),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                console.log(pros)
                if (!pros) {
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
                } else if (pros.errorMessage) {
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
                }
                toast.success(`Operación realizada exitosamente`, this.state.toaststyle)
                if (!nuevo_subestado._id) {
                    if (pros._id) subestados.push(pros)
                }
                return this.setState({ loadingFormulario: false, subestados, tipovisualizacion: 'estados' })
            })
            .catch(async error => {
                this.setState({ loadingFormulario: false })
                return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
            })
    }

    setBoleanPropertyEstado(name) {
        const { nuevo_estado } = this.state
        nuevo_estado[name] = nuevo_estado[name] === true ? false : true
        return this.setState({ nuevo_estado })
    }

    setBoleanProperty(name) {
        const { nuevo_subestado } = this.state
        nuevo_subestado[name] = nuevo_subestado[name] === true ? false : true
        return this.setState({ nuevo_subestado })
    }

    handlechangeSubestado(e) {
        const { name, value } = e.target
        const { nuevo_subestado } = this.state
        nuevo_subestado[name] = value
        console.log(nuevo_subestado)
        return this.setState({ nuevo_subestado })
    }

    agregarCondicion(id) {
        const { acciones_subestado_actual } = this.state

        const i = acciones_subestado_actual.findIndex(e => e._id === id)

        if (i < 0) return false

        if (!acciones_subestado_actual[i].conditions) acciones_subestado_actual[i].conditions = []

        acciones_subestado_actual[i].conditions.push({
            accion: '',
            operador: '',
            campo: ''
        })

        return this.setState({ acciones_subestado_actual })
    }

    handleChangeChecked(e, name, id){
        const value = e
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        acciones_subestado_actual[i][name] = value
        return this.setState({ acciones_subestado_actual })
    }

    formularioCreacionSubestado() {
        const { loadingFormulario, loadingAcciones, eliminandoAcciones, acciones_subestado_actual, estados, nuevo_subestado, id_estado_crear_subestado } = this.state

        let titulo_estado = ''
        const iestado = estados.findIndex(es => es._id === id_estado_crear_subestado)
        if (iestado > -1) titulo_estado = estados[iestado].titulo

        const estilo_icono = {
            backgroundColor: "white",
            padding: 15,
            borderRadius: "50%",
            boxShadow: "0px 0px 17px -10px black",
            marginBottom: 10
        }

        const estilo_icono_check = {
            // display: "block",
            // margin: "0 auto",
            // marginTop: -21,
            // marginLeft: 61
        }

        const estilo_texto = {
            fontWeight: "bold",
            fontSize: 11,
            display: "inline",
            backgroundColor: "white",
            borderRadius: 5,
            padding: "5px 15px",
        }

        const propiedades_estado = [
            {
                slug: "notificaciones",
                titulo: "Notificaciones",
                items: [
                    {
                        slug: "email",
                        titulo: "Envía correo electrónico",
                        help_text: "Envía un mensaje de correo electrónico notificando este evento",
                        icon: <AiOutlineMail size={50} style={estilo_icono} />
                    },
                    {
                        slug: "whatsapp",
                        titulo: "Envía whatsapp",
                        help_text: "Envía un mensaje de whatsapp notificando este evento",
                        icon: <BsWhatsapp size={50} style={estilo_icono} />
                    }
                ]
            },
            {
                slug: "trazabilidad",
                titulo: "Trazabilidad",
                items: [
                    {
                        slug: "allor_repeat",
                        titulo: "Es un punto de control",
                        help_text: "Esto permite repetir el estado múltiples veces y no cambia el estado actual del pedido",
                        icon: <BsArrowRepeat size={50} style={estilo_icono} />
                    },
                    {
                        slug: "enable_tracking_analyse",
                        titulo: "Activa el rastreo",
                        help_text: "Cargas B2B e Internacionales, inicia el análisis de ruta para el informe final",
                        icon: <MdSettingsInputAntenna size={50} style={estilo_icono} />
                    }
                ]
            },
            {
                slug: "control",
                titulo: "Control",
                items: [
                    {
                        slug: "publico",
                        titulo: "Público ",
                        help_text: "Clientes ven este estado en el tracking",
                        icon: <MdPublic size={50} style={estilo_icono} />
                    },
                    {
                        slug: "annulment",
                        titulo: "Estado de anulación",
                        help_text: "Anula el viaje",
                        icon: <MdOutlineCancel size={50} style={estilo_icono} />
                    },
                    {
                        slug: "notificar_planel_flota",
                        titulo: "Requiere auditarse por Flota",
                        help_text: "Estos estados llegarán a una pantalla especial para una revisión manual",
                        icon: <AiOutlineFileSearch size={50} style={estilo_icono} />
                    },
                    {
                        slug: "finalizar",
                        titulo: "Estado final ",
                        help_text: "Considerado como finalizado el servicio, necesario para las métricas y los informes de trazabilidad",
                        icon: <BsSendCheckFill size={50} style={estilo_icono} />
                    },
                    {
                        slug: "calificar",
                        titulo: "Enviar email de calificación ",
                        help_text: "El destinatario recibirá un email para calificar los servicios",
                        icon: <IoMdStarOutline size={50} style={estilo_icono} />
                    },
                ]
            },

        ]

        if (loadingFormulario) return <Spin animation='border' />

        const component_config = {
            label: "Configuración",
            children: <div>
            <Paragraph>Selecciona las opciones a continuación para activarlas</Paragraph>
                        <Tabs defaultActiveKey="notificaciones" items={
                                propiedades_estado.map((prop,i) => {
                                    return {
                                        key: (i+1),
                                        label: prop.titulo,
                                        children: <div>
                                        <Row>
                                            {
                                                prop.items.map((item, i) => {

                                                    const activo = nuevo_subestado[item.slug] === true
                                                    const overlay_item = <Tooltip
                                                        key={item.help_text}
                                                        placement={'bottom'}
                                                        title={item.help_text}
                                                        ><BiSolidHelpCircle size={13} color='#a7a7a7' /></Tooltip>

                                                    return <Col md={6} key={`est-${i}-${prop.slug}`} className='hover p-3 mb-3'>
                                                        <div className='text-center' onClick={() => this.setBoleanProperty(item.slug)}>
                                                            {item.icon}
                                                            <div></div>

                                                            <Title level={4} className='mt-0 mb-0' >{item.titulo} {item.help_text ? overlay_item : false}</Title>
                                                            {activo ? <Title level={5} style={estilo_texto}><AiFillCheckCircle className='text-success' style={{...estilo_icono_check, color: "#60c13d" }} /> ACTIVO</Title> : <Title level={5} style={estilo_texto}><AiFillCheckCircle color='#cbcbcb' style={estilo_icono_check} /> INACTIVO</Title>}
                                                        </div>
                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </div>
                                    }
                                })
                            } />
        </div>
        }

        const component_compromiso = {
            label: "Compromiso",
            children: <div>
                <p>Puedes modificar automáticamente las fechas de compromiso de una orden al recibir este estado</p>
                <label className='form-control-label'>Agregar días a la fecha de compromiso</label>
                <Input className='form-control' type="number" name="sumar_dias" defaultValue={nuevo_subestado.sumar_dias} onChange={this.handlechangeSubestado} />
            </div>
        }

        const component_formulario = {
            label: "Formulario",
            children: <div>
                {this.mostrarAccionesDisponibles(nuevo_subestado)}
                    {acciones_subestado_actual.length > 0 ? <div>
                        <hr className='hr' />
                        <Row gutter={15}>
                        {
                            acciones_subestado_actual.map((accion, i) => {
                                return <Col md={12} className='mb-3' ><Card size="small">
                                        <Tag>{accion.tipo_accion}</Tag>
                                        <Title className='mt-0 mb-0' level={5}>Título</Title>
                                        <Input className='form-control mb-2' name="titulo" defaultValue={accion.titulo} id={accion._id} onChange={this.handleChangeAccion} />
                                        {accion.tipo_accion === 'selector' ? <div>
                                            <Paragraph className='form-control-label d-block mb-0 mt-0' >Opciones (Separa cada valor con coma)</Paragraph>
                                            <Input className='form-control mb-2' name="valor" placeholder='Escribe tu opción y a continuación escribe ,' value={accion.valor} id={accion._id} onChange={this.handleChangeAccionArray} />
                                            {
                                                accion.opciones.map((opcion, i) => {
                                                    return <div key={`opcion-${i}`} className="p-2 mr-2" style={{ display: 'inline-block', borderRadius: 6, backgroundColor: '#535353' }}>
                                                        <h4 className='text-light m-0' style={{ padding: "0px 0px 0 20px", color: "white" }}>{opcion} <Button style={{ marginLeft: 10 }} className='btn btn-sm btn-danger ml-3' onClick={() => this.removerOpcionAccion(accion._id, i)}>REMOVER</Button></h4>
                                                    </div>
                                                })
                                            }
                                            <div className='mb-3'></div>
                                        </div> : false}

                                        <Switch className='mb-3' checkedChildren="OBLIGATORIO" unCheckedChildren="OBLIGATORIO"  checked={accion.required === true} onChange={(e) => this.handleChangeChecked(e, "required", accion._id)} />

                                        {
                                            accion.conditions ? accion.conditions.map((con, pos) => {
                                                return <div key={`condicion-${pos}`}>
                                                    <Card className='p-2 mb-2'>
                                                        <Row>
                                                            <Col md={4}>
                                                                <label className='form-control-label d-block'>Borrar</label>
                                                                <Button size='sm' variant='danger' onClick={() => this.eliminarCondicion(accion._id, pos)} ><BsFillTrashFill /> </Button>
                                                            </Col>
                                                            <Col md={4}>
                                                                <label className='form-control-label d-block'>Accion</label>
                                                                <select className='form-control' name="action" value={con.action} id={accion._id} pos={pos} onChange={this.handleChangeCondicion} >
                                                                    <option value="">Acción </option>
                                                                    <option value="mostrar">Mostrar</option>
                                                                    <option value="ocultar">Ocultar</option>
                                                                </select>
                                                            </Col>
                                                            <Col md={12}>
                                                                <label className='form-control-label d-block'>Campo</label>
                                                                <select className='form-control' name="field" value={con.field} id={accion._id} pos={pos} onChange={this.handleChangeCondicion} >
                                                                    <option value="">Selecciona un campo </option>
                                                                    {
                                                                        acciones_subestado_actual.filter(ac => ac._id !== accion._id).map((option, iop) => {
                                                                            return <option key={`id${accion._id}-${iop}`} value={option._id}>{option.titulo ? option.titulo : "Sin título aún"}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </Col>
                                                            <Col md={12}>
                                                                <label className='form-control-label d-block'>Operador de comparación</label>
                                                                <select className='form-control' name="operator" value={con.operator} id={accion._id} pos={pos} onChange={this.handleChangeCondicion} >
                                                                    <option value="">Selecciona un operador </option>
                                                                    <option value="=">Igual a {"(=)"}</option>
                                                                    <option value="!=">No igual a {"(!=)"}</option>
                                                                    <option value=">">Mayor que {"(>)"}</option>
                                                                    <option value="<">Menor que {"(<)"}</option>
                                                                    <option value=">=">Mayor o igual que {"(>=)"}</option>
                                                                    <option value="<=">Menor o igual que {"(<=)"}</option>
                                                                </select>
                                                            </Col>
                                                            <Col md={12}>
                                                                <label className='form-control-label d-block'>Valor</label>
                                                                <Input className='form-control' name="value" value={con.value} id={accion._id} pos={pos} onChange={this.handleChangeCondicion} /></Col>
                                                        </Row>
                                                    </Card>
                                                </div>
                                            }) : false
                                        }

                                        {/* <div className='mb-3'><Button size="sm" variant="light" onClick={() => this.agregarCondicion(accion._id)} >AGREGAR CONDICION</Button></div> */}
                                        <div></div>
                                        <ButtonGroup>
                                            <Button onClick={() => this.guardarCambiosAccion(accion._id)} disabled={loadingAcciones}>{loadingAcciones ? "ESPERE UN MOMENTO..." : "GUARDAR"}</Button>
                                            <Popconfirm
                                                title="¿Estás seguro?"
                                                description="`¿Deseas eliminar este item? Esta opción no se puede deshacer"
                                                onConfirm={() => this.eliminarAccion(accion._id)}
                                                onCancel={() => false}
                                                okText="SI"
                                                cancelText="NO"
                                            >
                                            <Button disabled={eliminandoAcciones} icon={<FaRegTrashAlt /> }>{!eliminandoAcciones ? "ELIMINAR" : "ESPERE UN MOMENTO"}</Button>
                                            </Popconfirm>
                                        </ButtonGroup>
                                    </Card></Col>
                            })
                        }
                        </Row>
                    </div> : <p className='mt-3'>No hay acciones para este sub estado</p>}
            </div>
        }

        const component_notificaciones = {
            label: "Notificaciones",
            children: this.mostrarNotificacionesConfig()
        }

        const component_ficha = {
            label: "Detalles",
            children: <Form layout='vertical'>
            <Row gutter={15}>
                <Col md={4}>
                    <Form.Item label="Título" required={true} >
                    <Input className='form-control' name="titulo" defaultValue={nuevo_subestado.titulo} onChange={this.handlechangeSubestado} />
                    </Form.Item>
                </Col>

                <Col md={4}>
                    <Form.Item label="Código" required={true} >
                    <Input className='form-control' name="codigo_estado" defaultValue={nuevo_subestado.codigo_estado} onChange={this.handlechangeSubestado} />
                    </Form.Item>
                </Col>


                <Col md={4}>
                    <Form.Item label="Tipo" required={true} >
                    <Select name="tipo" defaultValue={nuevo_subestado.tipo} onChange={(e) => this.handlechangeSubestado({ target: { name: "tipo", value: e } })}
                        options={opciones_tipo_estado}
                    />
                    </Form.Item>
                </Col>

                <Col md={4}>
                    <Form.Item label="Color" required={true} >
                    <Input className='form-control' type="color" defaultValue={nuevo_subestado.color} onChange={(e) => this.handlechangeSubestado({ target: { name: "color", value: e.target.value } })} />
                    </Form.Item>
                </Col>

                <Col md={24} className='mb-3'><Button icon={<FaRegSave />} loading={loadingFormulario} onClick={() => this.crearSubEstado()}>{nuevo_subestado._id ? 'GUARDAR CAMBIOS' : 'CREAR'}</Button></Col>

            </Row>
            </Form>
        }

        const componentes = [ component_ficha, component_config, component_compromiso, component_formulario, component_notificaciones ].map((e,posi) => ({ key: (posi+1), label: <div><Title className='mt-0 mb-0' level={4}>{e.label}</Title></div>, children: e.children }))

        return <div >
            <Title className='mt-0 mb-0'> {nuevo_subestado._id ? <div>Editar subestado <Tag>{nuevo_subestado.titulo} · {titulo_estado}</Tag></div> : `Crear nuevo subestado dentro de ${titulo_estado}`} </Title>
            <Button className="btn btn-sm btn-outline-danger mb-3" onClick={() => this.setState({ tipovisualizacion: 'estados', nuevo_subestado: {}, acciones_subestado_actual: [] })} >Atrás</Button>
            
            <Card size="small"><Tabs defaultActiveKey={1} items={componentes} /></Card>
        </div>
    }

    setVar(status, texto) {
        const { configuracion } = this.state
        const valora_actual = configuracion[status] ? `${configuracion[status]} ` : ''
        configuracion[status] = `${valora_actual}${texto}`
        this.setState({ configuracion })
    }

    opciones_plantillas(status) {
        return <div>
            <p>Click para agregar una etiqueta de contenido dinámico</p>
            <Button size="sm" variant="outline-light" className='mr-3' onClick={() => this.setVar(status, '{{nombre_cliente}}')} >{'nombre_cliente'}</Button>
            <Button size="sm" variant="outline-light" className='mr-3' onClick={() => this.setVar(status, '{{nombre_direccion}}')} >{'nombre_direccion'}</Button>
            <Button size="sm" variant="outline-light" className='mr-3' onClick={() => this.setVar(status, '{{referencia}}')} >{'referencia'}</Button>
            <Button size="sm" variant="outline-light" className='mr-3' onClick={() => this.setVar(status, '{{ot}}')} >{'ot'}</Button>
            <Button size="sm" variant="outline-light" className='mr-3' onClick={() => this.setVar(status, '{{notas}}')} >{'notas'}</Button>
        </div>
    }

    handleChangeConfig(e) {
        const { configuracion } = this.state
        configuracion.whatsapp_message = e ? e : ""
        return this.setState({ configuracion })
    }

    async guardarCambiosConfiguracion() {
        const { configuracion, nuevo_subestado } = this.state
        const loading = 'loadingConfiguracionNotificaciones'
        configuracion.idsubestado = nuevo_subestado._id
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/notificacion-subestado`, {
            method: 'PUT',
            body: JSON.stringify(configuracion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                if (!pros) {
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ [loading]: false })
                } else if (pros.errorMessage) {
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ [loading]: false })
                }
                toast.success(`Operación realizada exitosamente`, this.state.toaststyle)
                return this.setState({ [loading]: false })
            })
            .catch(async error => {
                console.log(error)
                this.setState({ [loading]: false })
                return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
            })
    }

    mostrarNotificacionesConfig() {
        const { loadingConfiguracionNotificaciones, loadingPlantillasWhatsapp, configuracion, plantillas_ws } = this.state
        if (loadingConfiguracionNotificaciones === true) return <Spin animation="border" />

        return <div className='col-md-12'>
            <Card className='shadow-sm rounded p-3 mb-3'>
                            {/* <img src={`${url_images}/Customer review.gif`} className='img-fluid w-50' /> */}
                                <div className=''><Title className='mt-0 mb-0'><BsWhatsapp /><b> Whatsapp</b></Title></div>
                                <div className=' text-right'><HelperModal tipo="whatsapp" style="modal" /></div>
                                <Paragraph style={{ fontWeight: 700 }}>Selecciona uno de los siguientes mensajes aprobados por whatsapp, si no tienes ninguno comunícate con soporte para activarlos</Paragraph>
                                <Form.Item label="Mensaje de whatsapp a enviar" >
                                <Select name="whatsapp_message" value={configuracion.whatsapp_message} allowClear onChange={this.handleChangeConfig} options={plantillas_ws.map(ws => {
                                            return {
                                                value: ws._id,
                                                label: ws.mensaje
                                            }
                                        })} />
                                </Form.Item>
                                <Button className='btn btn-secondary shadow-sm w-100' onClick={() => this.guardarCambiosConfiguracion()}><b>GUARDAR CAMBIOS</b></Button>
                        
            </Card>

            {/* <Card className='p-3 text-white mb-3' bg="dark">
                <h5><i className="far fa-envelope"></i> Configuración de notificaciones</h5>
                <hr className='hr' />

                <h6>Asunto</h6>
                {this.opciones_plantillas('subject')}
                <Input placeholder='Escribe el asunto de tu email de notificación' className='form-control mb-3 mt-4' name="subject" value={configuracion.subject} onChange={this.handleChangeConfig} />

                <h6>Mensaje</h6>
                {this.opciones_plantillas('message')}
                <Input placeholder='Escribe el contenido del mensaje principal' className='form-control mb-3 mt-4' name="message" value={configuracion.message} onChange={this.handleChangeConfig} />

                <Button className='btn btn-success' onClick={() => this.guardarCambiosConfiguracion()}>GUARDAR CAMBIOS</Button>
            </Card> */}

        </div>
    }

    crearAccion(tipo_accion, idsubestado) {
        const { acciones_subestado_actual } = this.state
        const loading = 'loadingAcciones'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/crear-accion`, {
            method: 'POST',
            body: JSON.stringify({ tipo_accion, idsubestado }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                console.log(pros)
                if (!pros) {
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ [loading]: false })
                } else if (pros.errorMessage) {
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ [loading]: false })
                }
                toast.success(`Operación realizada exitosamente`, this.state.toaststyle)
                if (pros._id) acciones_subestado_actual.push(pros)
                return this.setState({ [loading]: false, acciones_subestado_actual })
            })
            .catch(async error => {
                console.log(error)
                this.setState({ [loading]: false })
                return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
            })
    }

    tablaEstados(estados, subestados) {
        const { eliminando } = this.state
        if (estados.length < 1) return <div>
            <Title level={4} className="mb-0 mt-0">No hay estados de carga asociados</Title>
        </div>

        return <div>
            <Title className='mb-3' level={3}>{estados.length} Protocólos encontrados </Title>
            <Collapse items={estados.map((estado, iestado) => {

                    const subest = subestados.filter(sub => sub.idparent === estado._id)

                    const opcion_eliminar = <Popconfirm
                            title="¿Estás seguro?"
                            description="`¿Deseas eliminar este item? Esta opción no se puede deshacer"
                            onConfirm={() => this.eliminarRegistro(estado._id)}
                            onCancel={() => false}
                            okText="SI"
                            cancelText="NO"
                        >
                        <Button variant="outline-danger"  size="sm" loading={eliminando} className='mb-' style={{ fontSize: 10, float: 'right', clear: 'both' }}icon={<FaRegTrashAlt /> }>ELIMINAR</Button>
                        </Popconfirm>

                    const header = <div className="pl-3 pr-3 pb-0">
                            <Title level={4} className='mb-0 mt-0'>{estado.titulo} · <b>{estado.codigo_estado}</b> <i style={{ color: estado.color }} className="fas fa-circle ml-3"></i> <Button size="sm" className='mb- ml-3 mr-3'  onClick={() => this.editarEstado(estado)}>EDITAR</Button>
                                {opcion_eliminar} {this.mostrarTiposServicioConcatenados(estado.tipos_servicio)}
                            </Title>
                        </div>
                    
                    const content = <div>
                        <Button size="sm" className='mb-3' onClick={() => this.nuevoSubEstado(estado._id)} icon={<GoPlus />} >CREAR SUBESTADO</Button>
                        <Divider className='mt-0 mb-2' />
                                {
                                    subest.length > 0 ? subest.map((subestado, isub) => {
                                        return <div key={`subestado-${subestado._id}`}>
                                            <Title level={4} className='mb-0 mt-0' >{subestado.titulo} · <b>{subestado.codigo_estado}</b> <i style={{ color: subestado.color }} className="fas fa-circle"></i></Title>
                                            <ButtonGroup>
                                            <Button size="sm" variant="link" className='p-0 mr-3 mb-2' onClick={() => this.editarSubEstado(subestado)}>EDITAR</Button>
                                            <Popconfirm
                                                    title="¿Estás seguro?"
                                                    description="`¿Deseas eliminar este item? Esta opción no se puede deshacer"
                                                    onConfirm={() => this.eliminarSubEstado(subestado._id)}
                                                    onCancel={() => false}
                                                    okText="SI"
                                                    cancelText="NO"
                                                >
                                            <Button size="sm" variant="link" className='mb-2'  icon={<FaRegTrashAlt /> }>ELIMINAR</Button>
                                                </Popconfirm>
                                                </ButtonGroup>
                                            {(isub + 1) === subest.length ? false : <hr className='hr' />}
                                        </div>
                                    }) : <Title level={4} className='mt-0'>No hay sub estados</Title>
                                }
                    </div>

                    return {
                        key: `est-${(iestado+1)}`,
                        label: header,
                        children: content
                    }

                })}
            />
        </div>

    }
    
    agregarFiltro(data){
        if(!data) return this.setState({ filtro_tipo_servicio: "" })
        if(typeof data !== "object") return this.setState({ filtro_tipo_servicio: "" })
        return this.setState({ filtro_tipo_servicio: data._id })
    }

    mostrarEstados() {

        const { estados, subestados, filtro_tipo_servicio, tipos_servicio, loadingEstados } = this.state

        if (loadingEstados === true) return <Spin />

        const estados_mostrar = filtro_tipo_servicio ? estados.filter(e => {
            if (e.tipos_servicio) {
                if (Array.isArray(e.tipos_servicio) !== false) {
                    if (e.tipos_servicio.length > 0) {
                        return e.tipos_servicio.includes(filtro_tipo_servicio)
                    }
                }
            }
            return false
        }) : estados

        return <Row>
            <Col md={24}>
                <Button size="sm" className='mb-3' onClick={() => this.setState({ tipovisualizacion: 'nuevoestado', nuevo_estado: {} })}>CREAR NUEVO</Button>
            </Col>
            <Col md={24}>
                <SelectorGlobal titulo="Tipo de servicio" condicion={{ tipo: "servicios" }} module="estadoscarga" key_label="title" key_value="_id" onChange={(data) => this.agregarFiltro(data)} />
                {this.tablaEstados(estados_mostrar, subestados)}
            </Col>

        </Row>
    }

    mostrarVista() {
        const { tipovisualizacion, owner } = this.state
        if (tipovisualizacion === 'estados') return this.mostrarEstados()
        if (tipovisualizacion === 'nuevoestado') return this.formularioCreacion()
        if (tipovisualizacion === 'nuevosubestado') return this.formularioCreacionSubestado()
    }

    render() {

        const renderizar = <div>
            <Card>
            <Title className="mb-2 mt-0"><FaRegListAlt /> Protocolos de entrega</Title>
            {this.mostrarVista()}
            </Card>
        </div>

        return <div>
            <Structure component={renderizar} />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        cerrarSesion: () => dispatch(cerrarSesion())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EstadosCarga);