const stringByStatusTrip = (val) => {
    switch (val) {
        case  "draft":
            return 'Borrador'
        case "unassigned":
            return 'Sin asignar'
        case "on_way_origin":
            return 'En tránsito a origen'
        case "arrived_origin":
            return 'En origen'
        case "transit":
            return 'En tránsito'
        case "finished":
            return 'Finalizado'
        case "programmed":
            return 'Programado'
        case "dismissed":
            return 'Descartado'
        case "cancelled":
            return 'Cancelado' 
        default:
            return val
    }
}

const canalEstadosString = (str = String) => {
    switch (str) {
        case 'app_conductor':
            return 'App Conductor'
        case 'sistema-web':
            return 'Sistema Web'
        default:
            return str
    }
}


module.exports = {
    stringByStatusTrip,
    canalEstadosString
}