import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { mapbox_token } from '../../../lib/backend/data';
import mapboxGlDraw from '@mapbox/mapbox-gl-draw';
import { obtenerCentroMapaPorPais } from '../../../lib/helpers/data/internationa';
import { useSelector } from 'react-redux';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import Paragraph from 'antd/es/typography/Paragraph';
import Map, { Layer, Marker, Source } from 'react-map-gl';
import SelectorZonaAvanzado from '../selector_zona_avanzado';
import { Button, Col, Divider, Dropdown, Form, Input, Popconfirm, Row, Space, Spin, message } from 'antd';
import * as turf from '@turf/turf';
import Title from 'antd/es/typography/Title';
import { calcularPuntoCentral } from '../../../lib/helpers/maps/geocercas';
import ButtonGroup from 'antd/es/button/button-group';
import IngresoDirecciones from '../../Zonas/direcciones/ingresa_direcciones';
import { recuperarCoordenadas } from '../../../lib/helpers/geo';
import polyline from '@mapbox/polyline';
import { AiOutlineMenu } from 'react-icons/ai';
import AgregarPunto from '../agregar_punto_control';


mapboxgl.accessToken = mapbox_token

const MapaDibujoPoligonoAvanzado = (props) => {
  const {
    default_geojson,
    height,
    redzone
  } = props
  const pais = useSelector(state => state.pais)
  const initialViewport = obtenerCentroMapaPorPais(pais)
  const [viewport, setViewport] = useState(initialViewport);
  const initialViewState = obtenerCentroMapaPorPais(pais)
  const [viewState, setViewState] = useState(initialViewState);
  const [messageApi, contextHolder] = message.useMessage();
  const [geometrias, setGeometrias] = useState([]);
  const [ focusOnMap, setFocusOnMap ] = useState(false)
  const [ coleccion, setColeccion ] = useState( default_geojson ? default_geojson :{
    type: 'FeatureCollection',
    features: []
  })
    const mapRef = useRef(null);
    const drawRef = useRef(null);
    const controls = []
    const geocercas = useRef([])

    const handleGeocoderResult = (event) => {
      if (event.result && event.result.geometry && event.result.geometry.coordinates) {
        const [lng, lat] = event.result.geometry.coordinates;
        setViewState({ ...viewState, longitude: lng, latitude: lat, zoom: 12 });
      }
    };

    const ajustarMapaAGeocercas = (datos_procesar) => {

      let data = datos_procesar ? datos_procesar : coleccion.features
      const instancia_geocercas = JSON.parse( JSON.stringify( data ))
      const coordinates = instancia_geocercas.reduce((acc, geofence) => {
        const geofenceCoords = recuperarCoordenadas(geofence.geometry.coordinates)
        return [...acc, ...geofenceCoords];
      }, []);
      
      console.log({ coordenadas_finales: coordinates })
      if (coordinates.length > 0) {
        
          const points = coordinates.map(marker => turf.point([marker[0], marker[1]]));
          const collection = turf.featureCollection(points);
          const bounds = turf.bbox(collection);
          console.log({ bounds, collection })
          const newViewport = {
              ...viewState,
              latitude: (bounds[1] + bounds[3]) / 2,
              longitude: (bounds[0] + bounds[2]) / 2
          };

          const options = {
            padding: 30 // Ajusta el valor de padding según tus necesidades
          };

          setViewState(newViewport);
          mapRef.current?.fitBounds(bounds, options);
    }
    }

    const handleMapLoad = () => {

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false, // Desactivar el marcador de ubicación seleccionada
            placeholder: "Busca una dirección",
            countries: pais
          });
          
          // Manejar el evento de resultado de búsqueda
          geocoder.on('result', handleGeocoderResult);
    
          let instancia_creacion = {
            displayControlsDefault: false,
            defaultMode: 'draw_polygon',
            controls: {
              polygon: true, // Habilitar dibujo de polígonos
              trash: true, // Habilitar la herramienta de borrado
            }
          }
          if(default_geojson) instancia_creacion.data = default_geojson
          const dibujo = new mapboxGlDraw(instancia_creacion);
    
          drawRef.current = dibujo; // Guardar la referencia del objeto draw en el ref
  
          
        if(!controls.includes('geocode')) mapRef.current?.addControl(geocoder, "top-right"); // Agregar el controlador de búsqueda en la esquina superior derecha
        controls.push('geocode')

        if(!controls.includes('draw')) mapRef.current?.addControl(dibujo, 'top-left'); // Agregar la herramienta de dibujo en la esquina superior izquierda
        controls.push('draw')

        // Asegurarse de que el mapa esté completamente cargado antes de acceder a drawRef.current
        mapRef.current?.on('draw.create', handleDraw); // Evento al crear un polígono
        mapRef.current?.on('draw.update', handleDraw); // Evento al actualizar un polígono
        mapRef.current?.on('draw.delete', handleDraw); // Evento al borrar un polígono
        mapRef.current?.resize();

    }

    useEffect(() => {
      ajustarMapaAGeocercas()
    }, [ coleccion ] )

    const onAgregarZona = (data) => {

      const buscar = geocercas.current.findIndex(geo => geo.id_geocerca === data._id)
        if(buscar > -1) return messageApi.error("Esta geocerca ya fue agregada")
          const nueva = {
              id_geocerca: data._id,
              titulo: data.titulo,
              type: "Feature",
              properties: data.properties,
              geometry: {
                type: data.region.type,
                coordinates: data.region.coordinates
              }
            }
            const nuevo_arreglo = [...geocercas.current, ...[nueva]]
            
            if (drawRef.current) {
              const data = drawRef.current.getAll(); // Obtener todas las geometrías dibujadas
              const geocercas_juntas = [...data.features, ...nuevo_arreglo]
              console.log({geocercas_juntas})
              const nueva_collection = {
                  features: geocercas_juntas
              }
              
              if(props.onChange) props.onChange(nueva_collection)
            }

              geocercas.current = nuevo_arreglo
              setColeccion((prevCollection) => ({
                ...prevCollection,
                features: nuevo_arreglo,
              }))
        }

    const handleDraw = () => {
      if (drawRef.current) {
        const data = drawRef.current.getAll(); // Obtener todas las geometrías dibujadas
        const geocercas_juntas = [...data.features, ...geocercas.current]

        const collection = {
            features: geocercas_juntas
        }
        
        if(props.onChange) props.onChange(collection)

        drawRef.current.deleteAll()
        geocercas.current = geocercas_juntas
        setColeccion((prevCollection) => ({
          ...prevCollection,
          features: geocercas_juntas,
        }))
      }
    };

    const centrarMapa = (centro, coordinates) => {

      const combinedFeatures = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
          },
        ],
      }

      const bounds = turf.bbox(combinedFeatures);
        const newViewport = {
          ...viewState,
          latitude: (bounds[1] + bounds[3]) / 2,
          longitude: (bounds[0] + bounds[2]) / 2
        }
  
        const options = {
          padding: 130 // Ajusta el valor de padding según tus necesidades
        }
  
        setViewState(newViewport);
        return setTimeout(() => {
          mapRef.current?.fitBounds(bounds, options)
        }, 300)

      if(!centro) return false
      if(!centro.longitude || !centro.latitude) return messageApi.info("Geocerca multi polígono está en desarrollo")
      if(isNaN(centro.longitude) || isNaN(centro.latitude)) return messageApi.info("Geocerca multi polígono está en desarrollo")
      const nuevo_zoom = initialViewState.zoom + 3
      const nuevo_centro = { center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000}
      mapRef.current?.flyTo(nuevo_centro);
      setTimeout(() => {
        setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
      }, 2010);
    }

    const resetMapView = () => {
      if(!focusOnMap) return false
      return <Button size="small" onClick={() => {
        setFocusOnMap(false)
        ajustarMapaAGeocercas()
      }}>VOLVER A MAPA COMPLETO</Button>
    }

    const handleChangeGeocerca = (e, id) => {
      const { value } = e.target
      const pos = e.target.getAttribute('pos')
      geocercas.current[pos].titulo = value

      const nueva_collection = {
        features: geocercas.current
    }
    
    if(props.onChange) props.onChange(nueva_collection)      
    }

    const eliminarGeocerca = (i) => {
      const instancia_geocerca = JSON.parse( JSON.stringify(geocercas.current))
      instancia_geocerca.splice(i,1)
      const nueva_collection = {
        features: instancia_geocerca
      }


      const instancia_coleccion = JSON.parse( JSON.stringify( coleccion ))
      instancia_coleccion.features = [...[], ...instancia_geocerca]
      geocercas.current = instancia_geocerca
      setColeccion(prev => ({...{}, ...instancia_coleccion }))
      if(props.onChange) props.onChange(nueva_collection) 
    }

    const mostrarGeocercas = () => {

      if(geocercas.current.length < 1 ) return <div className='mb-3' style={{ padding: "15px 15px 0px 15px" }} ><Title className='mb-0 mt-0' level={4}>Sin geocercas cargadas</Title></div>

      return <div>
        <div className='mb-3' style={{ padding: "15px 15px 0px 15px" }} ><Title className='mb-0 mt-0' level={4}>{geocercas.current.length} geocercas cargadas</Title></div>
        {resetMapView()}
          {
            geocercas.current.map((geo,pos) => {

              let coords = []
              let geofence_coordinates = []
              for( const arreglo of geo.geometry.coordinates ){
                  if(Array.isArray(arreglo) !== false){
                    for( const item of arreglo ){
                      if(!item) break
                      if(item.length < 2) continue
                      coords.push({ latitude: item[1], longitude: item[0] })
                      geofence_coordinates.push([ item[0], item[1] ])
                    }
                  }
              }

              if(coords.length < 1) return false

              const centro = calcularPuntoCentral(coords)

              const items = [
                {
                  label: "VER EN MAPA",
                  onClick: () => centrarMapa(centro, geofence_coordinates)
                },
                {
                  label: <Popconfirm
                  title="Eliminar geocerca"
                  description="¿Estás seguro que deseas eliminar esta geocerca de tu operación?"
                  onConfirm={() => eliminarGeocerca(pos)}
                  onCancel={() => false}
                  okText="SI"
                  cancelText="NO"
                  >
                <Button size="small" type="text" danger>ELIMINAR</Button>
                </Popconfirm>
                },
              ].map((e,i) => ({...e, key: (i+1) }))
              const menuProps = {
                items,
                // onClick: handleMenuClick,
              };
              
              return <div key={geo._id} className='mb-3' >
                <Row>
                  <Col md={19}>
                    <Form layout="vertical">
                    <Input mb={3} placeholder="Nombre" pos={pos} name="titulo" value={ geo.titulo ? geo.titulo : `GEO ${pos+1}` } onChange={handleChangeGeocerca} />    </Form>
                  </Col>
                  <Col xs={4}><Dropdown menu={menuProps} trigger={['click']}><Button><Space><AiOutlineMenu /></Space></Button></Dropdown></Col>
                </Row>
                </div>
            })
          }
      </div>
    }

    const onSelectPoint = (data) => {
      const { coordenadas, route, puntos, geometries } = data
      // ajustarMapaAGeocercas(coordenadas)
      // const nuevas_geometrias = [...geometrias, ...geometries]
      // setGeometrias(prev => [...prev, ...geometries.map(geo => geo.geometry)])
      // if(props.onChangeGeometries) props.onChangeGeometries(nuevas_geometrias)
      const nuevo_arreglo = [...geocercas.current, ...coordenadas]
              
              if (drawRef.current) {
                const data = drawRef.current.getAll(); // Obtener todas las geometrías dibujadas
                const geocercas_juntas = [...data.features, ...nuevo_arreglo]
                console.log({geocercas_juntas})
                const nueva_collection = {
                    features: geocercas_juntas
                }
                
                if(props.onChange) props.onChange(nueva_collection)
              }
  
                geocercas.current = nuevo_arreglo
                setColeccion((prevCollection) => ({
                  ...prevCollection,
                  features: nuevo_arreglo,
                }))
    }

    const onSelectRoute = (data) => {
      const { coordenadas, route, puntos, geometries } = data
      ajustarMapaAGeocercas(coordenadas)
      const nuevas_geometrias = [...geometrias, ...geometries]
      setGeometrias(prev => [...prev, ...geometries.map(geo => geo.geometry)])
      if(props.onChangeGeometries) props.onChangeGeometries(nuevas_geometrias)
      const nuevo_arreglo = [...geocercas.current, ...coordenadas]
              
              if (drawRef.current) {
                const data = drawRef.current.getAll(); // Obtener todas las geometrías dibujadas
                const geocercas_juntas = [...data.features, ...nuevo_arreglo]
                console.log({geocercas_juntas})
                const nueva_collection = {
                    features: geocercas_juntas
                }
                
                if(props.onChange) props.onChange(nueva_collection)
              }
  
                geocercas.current = nuevo_arreglo
                setColeccion((prevCollection) => ({
                  ...prevCollection,
                  features: nuevo_arreglo,
                }))
    }
  
    return (<div style={{ height: '100vh', width: '100%' }}>
      <div style={{ padding: "10px 25px" }}>
        <Paragraph>Una vez que dibujes tus geocercas presiona <b>Guardar Cambios</b></Paragraph>
      </div>
      <Row gutter={15}>
        <Col md={6} style={{ background: "white", height: "100vh", overflowY: "scroll" }}>
        <SelectorZonaAvanzado condicion={{ local_name: "region" }} onSelected={(data) => onAgregarZona(data)} />
        <IngresoDirecciones onSelectRoute={(data) => onSelectRoute(data)} />
        <AgregarPunto onSelectPoint={(data) => onSelectPoint(data)} />
        {mostrarGeocercas()}
        </Col>
        <Col md={18} style={{ height: "100vh", overflowY: "scroll" }}>
        <Map
              ref={mapRef}
              {...viewState}
              onMove={evt => setViewState(evt.viewState)}
              style={{width: "100%", height: height ? height : 500}}
              mapStyle="mapbox://styles/mapbox/streets-v9"
              mapboxAccessToken={mapbox_token}
              onLoad={handleMapLoad}
            >
              {
            geometrias.map((geometry,ir) => {

                const decodedCoords = polyline.decode(geometry)
                
                return <Source
                    id={`route-${ir}`}
                    type='geojson'
                    data={{
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: "LineString",
                            coordinates: decodedCoords.map(co => [co[1], co[0]])
                        }
                      }}
                >
                    <Layer
                    id={`route-layer-${ir}`}
                    type="line"
                    layout={{
                        'line-join': 'round',
                        'line-cap': 'round',
                      }}                
                    paint={{
                        'line-color': 'black',
                        'line-width': 4,
                        'line-opacity': 0.75
                    }}
                />
                </Source>
            })
        }
                  <Source
                          key={"ajsfkn"}
                          id={"geocerca._id"}
                          type="geojson"
                          data={coleccion}
                          >
                              
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="line"
                                paint={{
                                    'line-width': 4,
                                    'line-color': '#0080ef'
                                  }}                      
                                />
                              }
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="fill"
                                paint={{
                                    'fill-color': 'black',
                                    'fill-opacity': 0.5
                                  }}                      
                                />
                              }
                                  {
                                    redzone === true ? <Layer
                                    id="geocercas-layer"
                                    type="fill"
                                    paint={{
                                        'fill-color': 'red',
                                        'fill-opacity': 0.2
                                      }}                      
                                    /> : false
                                  }
                          </Source>
            </Map>
        </Col>
      </Row>
      </div>);
  };
  

export default MapaDibujoPoligonoAvanzado