import { Button, Form, Input, Modal, Select, message, Row, Col  } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { maps_key, urlapi } from "../../lib/backend/data";
import AutoComplete from "react-google-autocomplete";
import { SaveOutlined } from "@ant-design/icons";
import CargandoTabla from "./cargando";
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps";
import { TimePicker } from 'antd';
import moment from 'moment';
import { horariosVacios } from "./data";
import SelectorClientes from "../Clientes/selector";
import InputMovil from "../../subComponents/general/formularios/input_telefono";
const EditarNuevoCampoPersonalizado = (props) => {
    const {
        id_registro
    } = props
    const [ campo, setCampo ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(true)
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const module = 'bodegas'

    const obtenerDatos = async () => {
        if(!id_registro) return messageApi.error("ID no válido")
        setLoading(true)
        return fetch(`${urlapi}/${module}?id=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setKeyInputFilter(prev => prev+1)
                if(!res.horarios){
                    res.horarios = horariosVacios
                } else if(Array.isArray(res.horarios)){
                    if(res.horarios.length < 1) res.horarios = horariosVacios
                }
                console.log(res)
                setCampo(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    const guardarCambios = async () => {
        if(!campo.direccion) return messageApi.error("Selecciona una dirección")
        if(!campo.titulo) return messageApi.error("Escribe un título")
        if(campo.titulo.length < 3) return messageApi.error("Escribe un título válido")
        setLoadingSave(true)
        return fetch(`${urlapi}/${module}`,{
            method:'PUT',
            body: JSON.stringify(campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                setIsModalOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoadingSave(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
      obtenerDatos()
    };

    const handleOk = () => {
        return guardarCambios()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }
    
    const handleChangeMovil = (e) => {
        const { pais_codigo, movil } = e
        return setCampo(prev => {
            let actual = {...prev}
            actual.pais_codigo = pais_codigo
            actual.telefono = movil
            return {...{}, ...actual}
        })
    }


    const handleChangeSelect = (name, value) => {
        campo[name] = value
        return setCampo(campo)
    }
    
    const handleChangeCustom = (value,name) => {
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            console.log(actual)
            return {...{}, ...actual}
        })
    }

   const formulario = () => {
    if(loading) return <CargandoTabla />

    return <div>
        <SelectorClientes value={campo.id_cliente} key={key_input_filter} titulo="Cliente" onChange={(data) => handleChangeCustom(data,'id_cliente') } condicion={{}} />
        <Form layout="vertical">
            <Form.Item label="Nombre">
                <Input name="titulo" defaultValue={campo.titulo} onChange={handleChange} />
            </Form.Item>
            <Form.Item label="Dirección" required={true} >
                <AutoComplete
                defaultValue={campo.direccion}
                className="ant-input inputlocation"
                options={{
                    types: ["address"],
                    // cokmponentRestrictions: { country: pais },
                  }}                    
                apiKey={maps_key}
                onPlaceSelected={(place) => {
                    if(!place) return false
                        const geo_data = procesarGeoDatosGeocode(place)
                        return setCampo(prev => {
                            let actual          = {...prev}
                            actual.direccion    = place.formatted_address
                            actual.geodata      = geo_data
                            actual.location     = {
                                type: "Point",
                                coordinates: [
                                    geo_data.lng, geo_data.lat
                                ]
                            }
                        return actual
                    })
                }}
                />
            </Form.Item>
            <Form.Item label="Nombre del encargado" className="mb-2" required={true}>
                <Input name="encargado" defaultValue={campo.encargado} onChange={handleChange} />
            </Form.Item>
            <InputMovil onChange={handleChangeMovil} />
            <Form.Item label="Email" className="mb-2" required={true}>
                <Input name="email" defaultValue={campo.email} onChange={handleChange} />
            </Form.Item>
            <Form.Item label="Cargo" className="mb-2" required={true} >
                <Input name="cargo" defaultValue={campo.cargo} onChange={handleChange} />
            </Form.Item>
            <Form.Item label="Horarios de funcionamiento" className="mb-2" required={true}>
          {campo.horarios && campo.horarios.map((horario, index) => (
        <div key={index}>
       <p style={{ marginBottom: '5px', fontSize: '16px', fontWeight: 'normal' }}>{horario.dia}</p>
         <Row gutter={30}>
            <Col span={6}>
            <Input type="time" name={`inicio1_${horario.dia}`} placeholder="Desde" style={{ width: '100%' }} value={horario.horario1?.inicio} onChange={(e) => setCampo(prev => {
                                        const newHorarios = prev.horarios.map(h => 
                                            h.dia === horario.dia 
                                                ? {...h, horario1: {...h.horario1, inicio: e.target.value}} 
                                                : h);
                                        return {...prev, horarios: newHorarios};
                                    })} 
                                />
                            </Col>
                            <Col span={6}>
            <Input type="time" name={`final1_${horario.dia}`} placeholder="Hasta" style={{ width: '100%' }} value={horario.horario1?.final} onChange={(e) => setCampo(prev => {
                                        const newHorarios = prev.horarios.map(h => 
                                            h.dia === horario.dia 
                                                ? {...h, horario1: {...h.horario1, final: e.target.value}} 
                                                : h);
                                        return {...prev, horarios: newHorarios};
                                    })} 
                                />
                            </Col>
                            <Col span={6}>
            <Input type="time" name={`inicio2_${horario.dia}`} placeholder="Desde" style={{ width: '100%' }} value={horario.horario2?.inicio} onChange={(e) => setCampo(prev => {
                                        const newHorarios = prev.horarios.map(h => 
                                            h.dia === horario.dia 
                                                ? {...h, horario2: {...h.horario2, inicio: e.target.value}} 
                                                : h);
                                        return {...prev, horarios: newHorarios};
                                    })} 
                                />
                            </Col>
                            <Col span={6}>
            <Input type="time" name={`final2_${horario.dia}`} placeholder="Hasta" style={{ width: '100%' }} value={horario.horario2?.final} onChange={(e) => setCampo(prev => {
                                        const newHorarios = prev.horarios.map(h => 
                                            h.dia === horario.dia 
                                                ? {...h, horario2: {...h.horario2, final: e.target.value}} 
                                                : h);
                                        return {...prev, horarios: newHorarios};
                                    })} 
                                />
                            </Col>
                        </Row>
                    </div>))}
            </Form.Item>
            <Button type="primary" loading={loadingSave} onClick={() => guardarCambios()} ><SaveOutlined />GUARDAR CAMBIOS</Button>
        </Form>
    </div>
}
    return <div>
        <Button  className="mb-3" type="link" onClick={showModal}>EDITAR</Button>
        <Modal 
            title="Editar" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
            footer={[]}
        >
        {formulario()}
      </Modal>
      {contextHolder}
    </div>
}

export default EditarNuevoCampoPersonalizado