import Map, { FullscreenControl, GeolocateControl, Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CarFilled, DownOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { Button, Card, Col, Divider, Dropdown, Form, Input, Popconfirm, Popover, Row, Space, Spin, message } from 'antd';
import Moment from 'react-moment';
import Paragraph from 'antd/es/typography/Paragraph';
import SelectorZona from '../../RadioOperacion/selector_zona';
import { fitBounds } from 'viewport-mercator-project';
import bbox from '@turf/bbox';
import { cerrarSesion } from '../../../redux/actions/sesion';
import { obtenerCentroMapaPorPais } from '../../../lib/helpers/data/internationa';
import ButtonGroup from 'antd/es/button/button-group';
import { calcularPuntoCentral } from '../../../lib/helpers/maps/geocercas';
import SelectorZonaAvanzado from '../../RadioOperacion/selector_zona_avanzado';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import polyline from '@mapbox/polyline';
import { AiOutlineMenu } from 'react-icons/ai';
import AgregarPunto from '../../RadioOperacion/agregar_punto_control';


mapboxgl.accessToken = mapbox_token


const MapaZonasDetalle = (props) => {
    const {
        height,
        redzone,
        tipo_operacion_default,
        zona,
        default_geojson,
        geometries,
        geocercas_defecto
    } = props

    const [ zonasBD, setZonasBD ] = useState([])
    const [ loadingMaster, setLoadingMaster ] = useState(false)
    const [geometrias, setGeometrias] = useState(geometries ? geometries : []);
    const [ geocercas, setGeoCercas ] = useState(geocercas_defecto ? geocercas_defecto :[])
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ coleccion, setColeccion ] = useState( default_geojson ? default_geojson :{
      type: 'FeatureCollection',
      features: []
    })
    const [messageApi, contextHolder] = message.useMessage();
    const sesion = useSelector(state => state.miusuario)
    const initialViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(initialViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const [ focusOnMap, setFocusOnMap ] = useState(false)
    const dispatch = useDispatch()
    const mapRef = useRef()
    const tipo_operacion = tipo_operacion_default ? tipo_operacion_default : "operacion"
    let [ mounted, setMounted ] = useState(false)
    const controls = []
    

    const ajustarMapaAGeocercas = () => {

      if (coleccion.features.length > 0) {

        const instancia_geocercas = JSON.parse( JSON.stringify( coleccion.features ))

        const coordinates = instancia_geocercas.reduce((acc, geofence) => {
          const geofenceCoords = geofence.geometry.coordinates;
          if (geofenceCoords.length === 1) {
            // Polygon
            const filtradas_validas = geofenceCoords[0].filter(g => {
              if(Array.isArray(g) !== true) return false
              if(g.length < 2) return false
              if(g.filter(cc => !cc).length > 0) return false
              return true
            })
            acc.push(filtradas_validas);
          } else {
            // MultiPolygon
            geofenceCoords.forEach((coords) => {
              if(coords.length < 1) return false
              acc.push(coords[0]);
            });
          }
          return acc;
        }, []);
  
        console.log('coordinates', coordinates)
        const combinedFeatures = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Polygon',
                coordinates,
              },
            },
          ],
        };

          const bounds = bbox(combinedFeatures);
          const newViewport = {
            ...viewState,
            latitude: (bounds[1] + bounds[3]) / 2,
            longitude: (bounds[0] + bounds[2]) / 2
          };
    
          const options = {
            padding: 30 // Ajusta el valor de padding según tus necesidades
          };
    
          setViewState(newViewport);
          setTimeout(() => {
            mapRef.current?.fitBounds(bounds, options)
          }, 300);
          
        }
    }

    const handleGeocoderResult = (event) => {
      if (event.result && event.result.geometry && event.result.geometry.coordinates) {
        const [lng, lat] = event.result.geometry.coordinates;
        setViewState({ ...viewState, longitude: lng, latitude: lat, zoom: 12 });
      }
    };

    const geocodificador = () => {
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: false, // Desactivar el marcador de ubicación seleccionada
        placeholder: "Busca una dirección",
        countries: pais
      });
      geocoder.on('result', handleGeocoderResult);
      setTimeout(() => {
        if(controls.length < 1) mapRef.current?.addControl(geocoder, "top-right")
        controls.push('geocoder')
      }, 200);
    }

    const handleLoadMap = () => {
      geocodificador()
    }
    useEffect(() => {
      
      ajustarMapaAGeocercas()
      
    }, [ coleccion ])

    const resetMapView = () => {
      if(!focusOnMap) return false
      return <Button size="small" onClick={() => {
        setFocusOnMap(false)
        ajustarMapaAGeocercas()
      }}>VOLVER A MAPA COMPLETO</Button>
    }

    const centrarMapa = (centro, coordinates) => {
      

      const combinedFeatures = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Polygon',
              coordinates: [coordinates],
            },
          },
        ],
      }

      const bounds = bbox(combinedFeatures);
        const newViewport = {
          ...viewState,
          latitude: (bounds[1] + bounds[3]) / 2,
          longitude: (bounds[0] + bounds[2]) / 2
        }
  
        const options = {
          padding: 130 // Ajusta el valor de padding según tus necesidades
        }
  
        setViewState(newViewport);
        return setTimeout(() => {
          mapRef.current?.fitBounds(bounds, options)
        }, 300)

      if(!centro) return false
      if(!centro.longitude || !centro.latitude) return messageApi.info("Geocerca multi polígono está en desarrollo")
      if(isNaN(centro.longitude) || isNaN(centro.latitude)) return messageApi.info("Geocerca multi polígono está en desarrollo")
      const nuevo_zoom = initialViewState.zoom + 3
      const nuevo_centro = { center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000}
      mapRef.current?.flyTo(nuevo_centro);
      setTimeout(() => {
        setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
      }, 2010);
    }

    const handleChangeGeocerca = (e, id) => {
      const { value } = e.target
      const i = geocercas.findIndex(g => g._id === id)
      if(i < 0 ) return false
      geocercas[i].titulo = value
      if(props.onChangeGeocerca) props.onChangeGeocerca(geocercas)
      return setGeoCercas(geocercas)
    }

    const mostrarGeocercas = () => {
      if(loadingMaster) return <Spin />
      if(geocercas.length < 1 ) return <div className='mb-3' style={{ padding: "15px 15px 0px 15px" }} ><Title className='mb-0 mt-0' level={4}>Sin geocercas cargadas</Title></div>

      return <div>
        <div className='mb-3' style={{ padding: "15px 15px 0px 15px" }} ><Title className='mb-0 mt-0' level={4}>{geocercas.length} geocercas cargadas</Title></div>
        {resetMapView()}
          {
            geocercas.map((geo,pos) => {

              let coords = []
              let geofence_coordinates = []
              for( const arreglo of geo.geometry.coordinates ){
                  if(Array.isArray(arreglo) !== false){
                    for( const item of arreglo ){
                      if(!item) continue
                      if(item.length < 2) continue
                      coords.push({ latitude: item[1], longitude: item[0] })
                      geofence_coordinates.push([ item[0], item[1] ])
                    }
                  }
              }
              const centro = calcularPuntoCentral(coords)

              const items = [
                {
                  label: "VER EN MAPA",
                  onClick: () => centrarMapa(centro, geofence_coordinates)
                },
                {
                  label: <Popconfirm
                  title="Eliminar zona"
                  description="¿Estás seguro que deseas eliminar esta zona de tu operación?"
                  onConfirm={() => eliminarGeocerca(geo._id)}
                  onCancel={() => false}
                  okText="SI"
                  cancelText="NO"
                  >
                <Button loading={geo.deleting === true ? true : false} size="small" type="text" danger>ELIMINAR</Button>
                </Popconfirm>
                },
              ].map((e,i) => ({...e, key: (i+1) }))
              const menuProps = {
                items,
                // onClick: handleMenuClick,
              };
              
              
              return <div key={geo._id} className='mb-3' >
                    <Row>
                      <Col md={19}>
                        <Form layout="vertical">
                          <Input mb={3} placeholder="Nombre" name="titulo" defaultValue={ geo.titulo ? geo.titulo : `GEO ${pos+1}` } onChange={(e) => handleChangeGeocerca(e,geo._id)} />
                        </Form>
                      </Col>
                      <Col xs={4}><Dropdown menu={menuProps} trigger={['click']}><Button><Space><AiOutlineMenu /></Space></Button></Dropdown></Col>
                    </Row>
                  </div>
            })
          }
      </div>
    }


    const eliminarGeocerca = async (id) => {
      const instancia_geocercas = JSON.parse( JSON.stringify( geocercas ))
      const i = instancia_geocercas.findIndex(g => g._id === id)
      if(i > -1){
        geocercas[i].deleting = true
      } else {
        return false
      }
      return fetch(`${urlapi}/geodata/zonas/empresa/geocerca?id=${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then(async (res) => {
          if (!res) {
              messageApi.error("Sin datos obtenidos")
          } else if (res.errorMessage) {
              messageApi.error(res.errorMessage)
          } else if(res._id){
            messageApi.success("Eliminado exitosamente")
              instancia_geocercas.splice(i,1)

              setColeccion((prevCollection) => ({
                ...prevCollection,
                features: [...[], ...instancia_geocercas ],
              }))
              setGeoCercas([...[], ...instancia_geocercas])
          }
          return false
        })
        .catch((error) => {
          messageApi.error("Error al consultar la información, intente nuevamente")
          return false
        })
    }

    const guardarGeocercaBD = async (id, tipo, nuevo_arreglo) => {
      if(!zona) return false
      if(!zona._id) return false
      return fetch(`${urlapi}/geodata/zonas/asociar-geo-zona?id=${id}&idzona=${zona._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then(async (res) => {
          if (!res) {
              messageApi.error("Sin datos obtenidos")
          } else if (res.errorMessage) {
              messageApi.error(res.errorMessage)
          } else if(res._id){
            messageApi.success("Guardado exitosamente")
            const i = nuevo_arreglo.findIndex(geo => geo.id_geocerca === id)
            if(i > -1 ){
              nuevo_arreglo[i]._id = res._id
              setGeoCercas([...[], ...nuevo_arreglo])
            }
          }
          return false
        })
        .catch((error) => {
          messageApi.error("Error al consultar la información, intente nuevamente")
          return false
        })
    }

    const onAgregarZona = (data) => {

      const buscar = geocercas.findIndex(geo => geo.id_geocerca === data._id)
      if(buscar > -1) return messageApi.error("Esta geocerca ya fue agregada")
        const nueva = {
            id_geocerca: data._id,
            titulo: data.titulo,
            type: "Feature",
            properties: data.properties,
            geometry: {
              type: data.region.type,
              coordinates: data.region.coordinates
            }
          }
          const nuevo_arreglo = [...geocercas, ...[nueva]]
          setGeoCercas(nuevo_arreglo)
          coleccion.features = nuevo_arreglo
          setColeccion((prevCollection) => ({
            ...prevCollection,
            features: [...prevCollection.features, nueva],
          }))
          guardarGeocercaBD(data._id, tipo_operacion, nuevo_arreglo)
    }

    const onSelectPoint = (data) => {
      const { coordenadas, route, puntos, geometries } = data
      
      const nuevo_arreglo = [...geocercas, ...coordenadas]
      setGeoCercas(nuevo_arreglo)
      setColeccion((prevCollection) => ({
        ...prevCollection,
        features: [...prevCollection.features, ...coordenadas],
      }))
      if(props.onNewPoints) props.onNewPoints(coordenadas)
    }


    const condicion_por_pais = (country) => {
      switch (country) {
        case "BR":
          return { local_name: "region" }
        default:
          return { local_name: "region" }
      }
    }

    return <div style={{ height: '100vh', width: '100%' }}>
      {contextHolder}
        <Row gutter={15}>
          <Col md={6} style={{ background: "white", height: "90vh", overflowY: "scroll" }}>
          <AgregarPunto onSelectPoint={(data) => onSelectPoint(data)} />
          <SelectorZonaAvanzado condicion={condicion_por_pais(pais)} onSelected={(data) => onAgregarZona(data)} />
          {mostrarGeocercas()}
          </Col>
          <Col md={18} style={{ height: "90vh", overflowY: "scroll" }}>
          <Map
              ref={mapRef}
              {...viewState}
              onMove={evt => setViewState(evt.viewState)}
              style={{width: "100%", height: height ? height : 500}}
              mapStyle="mapbox://styles/mapbox/streets-v9"
              mapboxAccessToken={mapbox_token}
              onLoad={handleLoadMap}
            >
              <GeolocateControl position="top-left" />
              <FullscreenControl position="top-left" />
              <NavigationControl position="top-left" />
              {
                  geometrias.map((geometry,ir) => {

                      const decodedCoords = polyline.decode(geometry.geometry)
                      
                      return <Source
                          id={`route-${ir}`}
                          type='geojson'
                          data={{
                              type: 'Feature',
                              properties: {},
                              geometry: {
                                  type: "LineString",
                                  coordinates: decodedCoords.map(co => [co[1], co[0]])
                              }
                            }}
                      >
                          <Layer
                          id={`route-layer-${ir}`}
                          type="line"
                          layout={{
                              'line-join': 'round',
                              'line-cap': 'round',
                            }}                
                          paint={{
                              'line-color': 'black',
                              'line-width': 4,
                              'line-opacity': 0.75
                          }}
                      />
                      </Source>
                  })
              }
                  <Source
                          key={"ajsfkn"}
                          id={"geocerca._id"}
                          type="geojson"
                          data={coleccion}
                          >
                              
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="line"
                                paint={{
                                    'line-width': 4,
                                    'line-color': '#0080ef'
                                  }}                      
                                />
                              }
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="fill"
                                paint={{
                                    'fill-color': 'black',
                                    'fill-opacity': 0.5
                                  }}                      
                                />
                              }
                                  {
                                    redzone === true ? <Layer
                                    id="geocercas-layer"
                                    type="fill"
                                    paint={{
                                        'fill-color': 'red',
                                        'fill-opacity': 0.2
                                      }}                      
                                    /> : false
                                  }
                          </Source>
            </Map>
          </Col>
        </Row>
  </div>
}

export default MapaZonasDetalle