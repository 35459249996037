import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Checkbox, Col, Form, Input, Pagination, Popconfirm, Row, Table, Tag, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import { fechaATextoSimple } from "../../lib/helpers/helpers"
import { FileExcelFilled, SearchOutlined } from "@ant-design/icons"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado"
import EditarContactoRapido from "./editar"
import MostrarEmails from "../../lib/helpers/mostrar_emails"
import MostrarMoviles from "../../lib/helpers/mostrar_moviles"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { PiCursorClickFill } from 'react-icons/pi'
import ModalPersonalizado from "../../subComponents/general/modales/modal_personalizado"
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores"
import SelectorClientes from "../Clientes/selector"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import SelectorGeneral from "../General/selector"
import HelperModal from "../../subComponents/general/helperModal"
import { codigos_pais } from "../../lib/internacional"
import { stringByStatus } from "./data"
import { estilo_moving_truck } from "../../lib/estilo_sitio"

const ListadoContactos = (props)=> {
    const {
        condicion_default,
        showCustomFieldsData,
        hideSearch,
        showFilters,
        showSelection,
        exclude,
        hideActions,
        componentAction,
        loadingActions,
        typeView,
        hideColumns,
        hideExcelButton
    } = props
    const dispatch = useDispatch()
    const sesion                    = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [deleting, setDeleting]   = useState(false)
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ]     = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ]       = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const pais                      = useSelector(state => state.pais)
    const session                   = useSelector(state => state.miusuario)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const idioma                    = useSelector(state => state.idioma)
    const trash                     = props.trash ? props.trash : false
    const tipo                      = props.tipo ? props.tipo : "link"
    const titulo                    = props.title ? props.title : 'registros'
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const key_interfaz              = 'tabla_contactos'
    const key_esquema               = 'CONTACTOS'
    const module                    = 'contactos'
    const [ key_input_filter, setKeyInputFilter ] = useState(0)

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/contacts`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const obtenerRegistros = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${module}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    const eliminarRecurso = async (id) => {
        setDeleting(true)
        return fetch(`${urlapi}/${module}?id=${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                messageApi.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                messageApi.error(res.errorMessage)
            } else if(res._id){
              messageApi.success("Eliminado exitosamente")
              obtenerRegistros(1, false)
            }
            return setDeleting(false)
          })
          .catch((error) => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setDeleting(false)
          })
      }
  

    useEffect(() => {
        obtenerRegistros(1, condicion_default)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerRegistros(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination showSizeChanger={false} defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const deseleccionarTodos = (e) => {
        setConductores([...[], ...conductores.map(veh => { 
            if(exclude){
                if( Array.isArray(exclude) !== false ){
                    if(exclude.includes(veh._id)) return veh
                }
            }
            veh.seleccionado = e.target.checked
            return veh 
        })])
      }

      const checkForEnabledValue = (id) => {
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const handleChangeSeleccion = (e) => {
        const { name } = e.target
        const valor = e.target.checked
        const i = conductores.findIndex(ve => ve._id === name )
        conductores[i].seleccionado = valor
        setConductores([...[], ...conductores])
      }

      const validarChecked = (seleccionado, id) => {
        if(seleccionado === true) return true
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

    const seleccionarRegistro = (data, action) => {
        deseleccionarTodos({ target: false })
        let datos = data
        if(Array.isArray(datos) !== true) datos = [data]
        if(props.onSelect) props.onSelect(datos, action)
    }

    const seleccionarEspecial = (action) => {
        return seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true), action)
      }

      const accionButtons = () => {
        if(showSelection !== true) return false
        const seleccionados = conductores.filter(veh => veh.seleccionado === true).length
        const enable = seleccionados > 0 ? false : true

        if(componentAction){
            return <div style={{ marginTop: 10, marginBottom: 10 }} onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>
                {componentAction.map(it => {
                    return <Button disabled={enable} loading={ loadingActions === true } size="small" style={{ marginRight: 10 }} onClick={() => seleccionarEspecial(it.label)} >{it.label}</Button>
                })}
            </div>
        }

        return <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Row gutter={15}>
                <Col span="auto"><Button disabled={enable} size="small" onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>SELECCIONAR</Button></Col>
                <Col span="auto"><Title level={5} className="mt-0 mb-0"><PiCursorClickFill /> Toca el título para seleccionar uno</Title></Col>
            </Row>
        </div>
      }

      const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <b className="hover" onClick={() => seleccionarRegistro(data)}>{data.nombres ? data.nombres : 'SIN INFORMACIÓN'}{data.apellidos ? ` ${data.apellidos}` : 'SIN INFORMACIÓN'}</b>
            default:
                return <Link to={`${rutas.contacts.slug}/${data._id}`}>{data.nombres} {data.apellidos}</Link>
        }
    }

    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />

        const component_nombres = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="nombres" />} render={(data) => {
            return <div>{showByType(typeView, data)}</div>
        }} />

        const component_apellidos = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="apellidos" />} render={(data) => {
            return <div>{data.apellidos}</div>
        }} />
        
        const component_status = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="status" />} render={(data) => {
            return <div>{stringByStatus(data.status)}</div>
        }} />

        const component_email = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="email" />} render={(data) => {
            return <div>{data.email}</div>
        }} />
        const component_pais = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="pais" />} render={(data) => {

            let dato_pais = 'Sin información'
            const i = codigos_pais.findIndex(p => p.value === data.pais)
            if(i > -1) dato_pais = codigos_pais[i].label
            return <div>{dato_pais}</div>
        }} />
        const component_direccion = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="direccion" />} render={(data) => {
            return <div>{data.direccion}</div>
        }} />
        const component_ciudad = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="ciudad" />} render={(data) => {
            return <div>{data.ciudad}</div>
        }} />
        const component_cliente = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="cliente" />} render={(data) => {
            let empresas = []
            if(data.empresas) if(Array.isArray(data.empresas) !== false) empresas = data.empresas

            return <div>{empresas.map(em => em.razon_social).join(',')}</div>
        }} />
        const component_cargo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="cargo" />} render={(data) => {
            return <div>{data.cargo}</div>
        }} />
        const component_area = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="area" />} render={(data) => {
            return <div>{data.area}</div>
        }} />

        const component_moviles_adicionales = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="moviles_adicionales" />} render={(data) => {
            return <div><MostrarMoviles datos={data.moviles_adicionales} /></div>
        }} />
        const component_emails_adicionales = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="emails_adicionales" />} render={(data) => {
            return <div><MostrarEmails datos={data.emails_adicionales} /></div>
        }} />

        const component_movil = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="movil" />} render={(data) => {
            return <div>{data.pais_codigo}{data.movil}</div>
        }} />
        const component_creador = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="creador" />} render={(data) => {
            return <div>{data.usuario?.nombres} {data.usuario?.apellidos}</div>
        }} />
        const component_fecha = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="createdAt" />} render={(data) => {
            return <div>{fechaATextoSimple(data.createdAt)}</div>
        }} />

        const acciones_botones = <Column title="Acciones" fixed="right" ellipsis render={(data) => {
            return <div>
                <Row gutter={15}>
                    <Col span="auto"><EditarContactoRapido id_registro={data._id} onCreate={() => obtenerRegistros(1, false)} /></Col>
                    <Col span="auto">
                    <Popconfirm
            title="Eliminar"
            description="¿Estás seguro que deseas eliminar recurso? Esta acción no se puede deshacer"
            onConfirm={() => eliminarRecurso(data._id)}
            onCancel={() => false}
            okText="SI"
            style={{ display: "inline-block" }}
            cancelText="NO"
          >
          <Button loading={deleting} size="small" className="m-0" type="dashed" danger>ELIMINAR</Button>
          </Popconfirm>
                    </Col>
                </Row>
                
          </div>
        }} />

        return <div>
            {paginacion(data.pagina, total)}
            {accionButtons()}
            <Table dataSource={conductores} pagination={false} size="small"  bordered scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="contactos" /> }} >
            {
            showSelection !== true ? false : <Column title={<Checkbox onChange={deseleccionarTodos} />} render={(data) => {
                return <Checkbox disabled={checkForEnabledValue(data._id)} checked={validarChecked(data.seleccionado, data._id)} name={data._id} onChange={handleChangeSeleccion}/>
            }} />
            }
                { encontrarLlaveValorEnEsquema("nombres", interfaz_usuario[ key_interfaz ]) === false ? false : component_nombres }
                {/* { encontrarLlaveValorEnEsquema("apellidos", interfaz_usuario[ key_interfaz ]) === false ? false : component_apellidos } */}
                { encontrarLlaveValorEnEsquema("status", interfaz_usuario[ key_interfaz ]) === false ? false : component_status }
                { encontrarLlaveValorEnEsquema("pais", interfaz_usuario[ key_interfaz ]) === false ? false : component_pais }
                { encontrarLlaveValorEnEsquema("direccion", interfaz_usuario[ key_interfaz ]) === false ? false : component_direccion }
                { encontrarLlaveValorEnEsquema("cliente", interfaz_usuario[ key_interfaz ]) === false ? false : component_cliente }
                { encontrarLlaveValorEnEsquema("ciudad", interfaz_usuario[ key_interfaz ]) === false ? false : component_ciudad }
                { encontrarLlaveValorEnEsquema("cargo", interfaz_usuario[ key_interfaz ]) === false ? false : component_cargo }
                { encontrarLlaveValorEnEsquema("area", interfaz_usuario[ key_interfaz ]) === false ? false : component_area }
                { encontrarLlaveValorEnEsquema("email", interfaz_usuario[ key_interfaz ]) === false ? false : component_email }
                { encontrarLlaveValorEnEsquema("movil", interfaz_usuario[ key_interfaz ]) === false ? false : component_movil }
                { encontrarLlaveValorEnEsquema("moviles_adicionales", interfaz_usuario[ key_interfaz ]) === false ? false : component_moviles_adicionales }
                { encontrarLlaveValorEnEsquema("emails_adicionales", interfaz_usuario[ key_interfaz ]) === false ? false : component_emails_adicionales }
                { encontrarLlaveValorEnEsquema("createdAt", interfaz_usuario[ key_interfaz ]) === false ? false : component_fecha }
                { encontrarLlaveValorEnEsquema("creador", interfaz_usuario[ key_interfaz ]) === false ? false : component_creador }
                { hideActions === true ? false : acciones_botones }
                
            </Table>
        </div>
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setCondicionBusqueda(condicion_default);
            setPagina(1);
            return obtenerRegistros(1, condicion_default);
        }
        const condicion = {
            ...condicion_default,
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerRegistros(1, condicion);
      };

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

    const buscador = () => {
        if(hideSearch === true) return false
        return <Col span="auto"><Form onSubmitCapture={handleSubmit}>
                    <Row gutter={15}>
                        <Col md={14}>
                            <Form.Item label="Buscar">
                                <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Buscar por palabras clave`} />
                            </Form.Item>
                        </Col>
                        <Col md={10}>
                            <Form.Item>
                                <Button onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                            </Form.Item>
                        </Col>
                    </Row>
            </Form>
        </Col>
      }

      const agregarFiltro = (key, value) => {
        filtroBusqueda[key] = { $in: value.map(v => v._id) }
        console.log(filtroBusqueda)
        setFiltroBusqueda(filtroBusqueda)
      }

      const refrescarBusqueda = () => {
        setPagina(1)
        let cond = {}
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            }
        })
        obtenerRegistros(1, cond)
    }

    const resetFilters = () => {
        setFiltroBusqueda({})
        setKeyInputFilter(key_input_filter + 1)
        obtenerRegistros(1, condicion_default ? condicion_default : {})
    }
    
      const mostrarDatosPersonalizados = () => {
        const component = <GraficosCamposPersonalizados typeView="modaxl" tipo="contacto" />
        return showCustomFieldsData === true ? <Col><ModalPersonalizado typeView="modal" slug="informe_personalizado" component={component} /></Col> : false
      }

      const mostrarFiltrosBusqueda = () => {

        const mostrarButtonReset = Object.keys(filtroBusqueda).length > 0 ? true : false

        return <div style={{ marginBottom: 20 }} className="mt-0">
            <Row gutter={10}>
                <Col md={6}>
                    <div className="mt-2"><SelectorClientes key={key_input_filter} titulo="Cliente" condicion={{ }} onChange={(data) => agregarFiltro('id_cliente', [{ _id: data.value }])} /></div>
                </Col>
                {/* <Col md={6}>
                        <Form layout="vertical" className="mt-0">
                        <SelectorGeneral key={key_input_filter} mode="multiple" module="categorias" titulo="Cargo" condicion_default={{ tipo: "cargo" }} onChange={(data) => agregarFiltro('id_cargo', data)} />
                        </Form>
                </Col> */}
                <Col md={6}>
                    <Title className="mb-2 mt-0" level={5}>FILTRAR</Title>
                    <Button onClick={() => refrescarBusqueda()} style={{ width: "100%" }} icon={<SearchOutlined />} >ACTUALIZAR</Button>
                </Col>
                <Col md={6}>
                    <Title className="mb-2 mt-0" level={5}>Limpiar</Title>
                    <Button disabled={!mostrarButtonReset}  style={{ width: "100%" }} onClick={() => resetFilters()}>BORRAR FILTROS</Button>
                </Col>
            </Row>
            
        </div>
      }

      const encabezado = () => {
        return <Row gutter={15}>
            {buscador()}
            { hideColumns === true ? false : <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col> }
            { hideExcelButton === true ? false : <Col span="auto"><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green"  onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col> }
        </Row>
      }

      const render = <div>
        {encabezado()}
        <Row gutter={15} className="mb-3">
        {mostrarDatosPersonalizados()}
        </Row>
        {mostrarRegistros()}
      </div>

    return <div>
        {contextHolder}
        {
                showFilters === true ? <Row gutter={15}>
                <Col md={24}>
                    {mostrarFiltrosBusqueda()}
                </Col>
                <Col md={24}>{render}</Col>
            </Row> : <div>{render}</div>
        }
    </div>
}
export default ListadoContactos