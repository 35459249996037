const empresa_default = false

function reducer( state = empresa_default, { type, payload } ){
    switch( type ){
        case 'actualizarEmpresa': {
            return payload
        }
        default:
            return state
    }
}

export default reducer