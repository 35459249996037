import { Button, Card, Col, Form, Input, Row, Select, Skeleton, message } from "antd"
import { useEffect, useState } from "react"
import { urlapi } from "../../lib/backend/data"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/sesion"
import Title from "antd/es/typography/Title"
import CrearCampoPersonalizado from "./crear"

const CamposPersonalizadosRecurso = (props) => {
    const {
        tipo,
        id_target,
        saveOnBlur,
        titulo,
        hideActions
    } = props
    const [ campos, setCampos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ loadingGuardando, setLoadingGuardando ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()

    const obtenerDatos = async () => {
        if(!id_target) return setLoading(false)
        if(!tipo) return setLoading(false)
        setLoading(true)
        return fetch(`${urlapi}/campos-personalizados/recurso`,{
            method:'POST',
            body: JSON.stringify({
                condicion: { tipo },
                id_target
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                setCampos(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }
    const guardarCambios = async () => {
        setLoadingGuardando(true)
        const enviar = campos.filter(c => c.value).map(campo => {
            campo.id_target = id_target
            return campo
        })
        return fetch(`${urlapi}/campos-personalizados/recurso`,{
            method:'PUT',
            body: JSON.stringify(enviar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.success){
                messageApi.success(res.success)
            }
            return setLoadingGuardando(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingGuardando(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        const id =  name
        const i = campos.findIndex(campo => campo._id === id)
        if(i < 0) return false
        campos[i].value = value
        console.log(campos)
        return setCampos([...[], ...campos])
    }
    
    const handleChangeSelect = (value, name) => {
        const id =  name
        const i = campos.findIndex(campo => campo._id === id)
        if(i < 0) return false
        campos[i].value = value
        console.log(campos)
        return setCampos([...[], ...campos])
    }

    const accionarBlur = () => {
        if(saveOnBlur !== true) return
        return guardarCambios()
    }

    const mostrarSegunSeleccion = (campo) => {


        if(campo.tipo_ingreso === "seleccion"){

            return <div>
                <Select 
                    options={campo.opciones.map(e => ({ value: e.value, label: e.value }))}
                    defaultValue={campo.value}
                    onChange={(e) => handleChangeSelect(e,campo._id)} 
                    onBlur={() => accionarBlur()}
                 />
            </div>
        }
    
        return <div>
        <Input name={campo._id} onChange={handleChange} defaultValue={campo.value} onBlur={() => accionarBlur()} />
        </div>
    }

    const visualizarCampos = () => {
        if(loading) return <Skeleton active />
        if(campos.length < 1) return <div><Title level={4} className="mb-0 mt-0">No hay resultados</Title> </div>

        return <div>
            <Form layout="vertical">
            <Row gutter={10}>
            {
                campos.map(campo => {
                    return <Col md={6} key={campo._id}>
                        <Form.Item label={campo.title} >
                        {mostrarSegunSeleccion(campo)}
                        </Form.Item>
                    </Col>
                })
            }
            </Row>
            { saveOnBlur === true ? false : <Button loading={loadingGuardando} type="primary" onClick={() => guardarCambios()} >GUARDAR CAMBIOS</Button>}
            </Form>
        </div>
    }

    const mostrarAcciones = () => {
        if(hideActions === true) return false
        return <div>
            <CrearCampoPersonalizado tipo={tipo} id_target={id_target} onCreate={() => obtenerDatos()} />
        </div>
    }
    return <div>
        {contextHolder}
        <Title className="mb-3 mt-0" level={4}>{ titulo ? titulo : "Campos personalizados"}</Title>
        {mostrarAcciones()}
        {visualizarCampos()}
    </div>
}

export default CamposPersonalizadosRecurso