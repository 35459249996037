import { ArrowDownOutlined } from "@ant-design/icons"
import Modal from "antd/es/modal/Modal"
import ListadoRutas from "../Rutas/listado"
import Paragraph from "antd/es/typography/Paragraph"
import Title from "antd/es/typography/Title"
import { useState } from "react"
import { Spin, message } from "antd"
import { urlapi } from "../../lib/backend/data"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/sesion"

const ModaAsignacionViajes = (props) => {
    const {
        show, 
        registros,
        showSelection,
        funcion_execute,
        typeViewList
    } = props
    const [ loading, setLoading ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const sesion = useSelector(state => state.miusuario)
    const pais                      = useSelector(state => state.pais)
    const idioma                    = useSelector(state => state.idioma)
    let tipo_vista                = typeViewList ? typeViewList : "funcion"
    const dispatch = useDispatch()

    const handleCancel = () => {
        if(props.onCancel) props.onCancel()
    }

    const moverARuta = async (ids_pedidos, id_ruta) => {
        console.log()
        return fetch(`${urlapi}/rutas/asign-multiple`,{
            method:'POST',
            body: JSON.stringify({
                ids_pedidos, 
                id_ruta
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.modifiedCount){
                messageApi.success('Se han asignado los viajes a la ruta')
                if(props.onSuccess) props.onSuccess()
                handleCancel()
            }
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
        })
    }

    const seleccionarRegistro = async (data) => {
        const funcion_default = funcion_execute ? funcion_execute : moverARuta
        const ids = registros.map(r => r._id)
        setLoading(true)
        await funcion_default(ids, data._id)
        setLoading(false)

    }

    const contenidoModal = () => {
        if(loading) return <div>
            <Spin size="large" />
            <Title level={4}>Cargando...</Title>
        </div>

        return <ListadoRutas showSelection={false} typeView={tipo_vista} hideHeader={true} onSelect={data => seleccionarRegistro(data)} />
    }

    return <div>
        {contextHolder}
        <Modal width={"80%"} open={show} footer={[]} onCancel={handleCancel}>
            <Title level={2} className="mt-0 mb-0"> Selecciona una ruta</Title>
            <Paragraph>Toca el nombre ID de la ruta para seleccionarla</Paragraph>
            {contenidoModal()}
      </Modal>
    </div>
}

export default ModaAsignacionViajes