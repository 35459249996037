import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Alert, Button, Card, Checkbox, Col, Divider, Form, Input, Layout, Pagination, Row, Table, Tag, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { fechaATexto, fechaATextoSimple, fechaUTCATexto } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { FileExcelFilled, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import SelectorColor from "./selectores/color"
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores"
import ModalPersonalizado from "../../subComponents/general/modales/modal_personalizado"
import { rutas } from "../../lib/routes/routes"
import SelectorGeneral from "../General/selector"
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import ContadoresEquipos from "./contadores"
import HelperModal from "../../subComponents/general/helperModal"
import { calcularVolumen, formatoMoneda, stringByStatus } from "../../lib/helpers/main"
import { estilo_moving_truck } from "../../lib/estilo_sitio"

const ListadoEquipos = (props)=> {
    const {
        condicion_default,
        typeView,
        showSelection,
        showFilters,
        showCustomFieldsData,
        showCounters,
        exclude,
        hideSearch,
        componentAction,
        loadingActions,
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ conductores, setRegistros] = useState([])
    const [ loadingRegistros, setLoadingRegistros] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const trash = props.trash ? props.trash : false
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const tipo = props.tipo ? props.tipo : "link"
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const titulo = props.title ? props.title : 'registros'
    const module = 'equipos'
    const key_interfaz              = 'tabla_equipos'
    const key_esquema               = 'EQUIPOS'
    const [ key_input_filter, setKeyInputFilter ] = useState(0)

    const obtenerDatos = async (page, query)=>{
        setLoadingRegistros(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${module}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingRegistros(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingRegistros(false)
            }
            if(Array.isArray(res.datos) !== false){
                setRegistros(res.datos)
                setTotal(res.total)
            }
            return setLoadingRegistros(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingRegistros(false)
        })
    }

    useEffect(() => {
        obtenerDatos(1, false)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerDatos(e, condicion_busqueda)
    }
    
    const refrescarBusqueda = () => {
        setPagina(1)
        let cond = {}
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            }
        })
        obtenerDatos(1, cond)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination current={pagina} total={(cantidad*10)} showSizeChanger={false} onChange={handleChangePagina} />
        </div>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/equipment`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setPagina(1)
            setCondicionBusqueda({})
            return obtenerDatos(1, {})
        }
        const condicion = {
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerDatos(1, condicion);
      };

      const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

      const seleccionarRegistro = (data, action) => {
        deseleccionarTodos({ target: false })
        let datos = data
        if(Array.isArray(datos) !== true) datos = [data]
        if(props.onSelect) props.onSelect(datos, action)
    }

    const seleccionarEspecial = (action) => {
        return seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true), action)
      }

      const accionButtons = () => {
        if(showSelection !== true) return false
        const seleccionados = conductores.filter(veh => veh.seleccionado === true).length
        const enable = seleccionados > 0 ? false : true

        if(componentAction){
            return <div style={{ marginTop: 10, marginBottom: 10 }} onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>
                {componentAction.map(it => {
                    return <Button disabled={enable} loading={ loadingActions === true } size="small" style={{ marginRight: 10 }} onClick={() => seleccionarEspecial(it.label)} >{it.label}</Button>
                })}
            </div>
        }

        return <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Button disabled={enable} size="small" onClick={() => seleccionarRegistro(conductores.filter(veh => veh.seleccionado === true))}>SELECCIONAR</Button>
        </div>
      }
      
    const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit} >
        {/* <Title className="mb-0 mt-0" level={5}>BUSCAR</Title> */}
        <Row gutter={4}>
            <Col md={6}>
                <Form.Item className="mb-2">
                    <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Patente, chasis, número de motor`} />
                </Form.Item>
            </Col>
            <Col md={4}>
                <Form.Item >
                    <Button  onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                </Form.Item>
            </Col>
        </Row>
                </Form>
      }

      const deseleccionarTodos = (e) => {
        setRegistros([...[], ...conductores.map(veh => { 
            if(exclude){
                if( Array.isArray(exclude) !== false ){
                    if(exclude.includes(veh._id)) return veh
                }
            }
            veh.seleccionado = e.target.checked
            return veh 
        })])
      }

      const checkForEnabledValue = (id) => {
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const validarChecked = (seleccionado, id) => {
        if(seleccionado === true) return true
        if(exclude){
            if( Array.isArray(exclude) !== false ){
                if(exclude.includes(id)) return true
            }
        }
        return false
      }

      const handleChangeSeleccion = (e) => {
        const { name } = e.target
        const valor = e.target.checked
        const i = conductores.findIndex(ve => ve._id === name )
        conductores[i].seleccionado = valor
        setRegistros([...[], ...conductores])
      }

      const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <b className="hover" onClick={() => seleccionarRegistro(data)}>{data.titulo ? data.titulo : 'SIN INFORMACIÓN'}</b>
            default:
                return <Link to={`${rutas.equipment.slug}/${data._id}`}>{data.titulo ? data.titulo : 'VER DETALLES'}</Link>
        }
    }

      const mostrarTabla = () => {
            if(loadingRegistros) return <CargandoTabla />

            const component_titulo = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="titulo" />} render={(data) => {
                    return <div>{showByType(typeView, data)}</div>
            }} />

            const component_patente = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="patente" />} render={(data) => {
                return <div>{data.patente ? data.patente.toUpperCase() : 'VER DETALLES'}</div>
            }} />
            
            const component_categoria = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="categoria" />} render={(data) => {
                return <div>{data.categoria ? data.categoria : "SIN INFORMACIÓN"}</div>
            }} />

            const component_sub_categoria = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="sub_categoria" />} render={(data) => {
                return <div>{data.sub_categoria ? data.sub_categoria : "SIN INFORMACIÓN"}</div>
            }} />

            const component_ano = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="ano" />} render={(data) => {
                return <div>{data.ano}</div>
            }} />

            const component_marca = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="marca" />} render={(data) => {
                return <div>{data.marca}</div>
            }} />

            const component_modelo = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="modelo" />} render={(data) => {
                return <div>{data.modelo}</div>
            }} />
            
            const component_ejes = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="ejes" />} render={(data) => {
                return <div>{formatoMoneda(data.ejes)}</div>
            }} />

            const component_volumen = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="volumen" />} render={(data) => {
                const vol = calcularVolumen(data, data.unidad_medida)
                return <div><b style={{ fontWeight: "bold" }}>{vol.unidadVolumen}</b> {vol.volumen.toFixed(2)}</div>
            }} />

            const component_peso = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="peso" />} render={(data) => {
                return <div> <b style={{ fontWeight: "bold" }}>{data.unidad_medida_peso}</b> {formatoMoneda(data.peso)} </div>
            }} />
          
            const component_pallets = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="pallets" />} render={(data) => {
                return <div>{formatoMoneda(data.pallets)}</div>
            }} />
          
            const component_medidas_equipo = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="medidas_equipo" />} render={(data) => {
                return <div>
                    { data.medida_ancho ? <Tag>ANCHO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.medida_ancho}</Tag> : false }
                    { data.medida_alto ? <Tag>ALTO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.medida_alto}</Tag> : false }
                    { data.medida_largo ? <Tag>LARGO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.medida_largo}</Tag> : false }
                </div>
            }} />

            const component_medidas = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="medidas" />} render={(data) => {
                return <div>
                    { data.ancho ? <Tag>ANCHO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.ancho}</Tag> : false }
                    { data.alto ? <Tag>ALTO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.alto}</Tag> : false }
                    { data.largo ? <Tag>LARGO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.largo}</Tag> : false }
                </div>
            }} />

            const component_peso_bruto = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="peso_bruto" />} render={(data) => {
                return <div><b>{data.unidad_medida_peso}</b> {formatoMoneda(data.peso_equipo)}</div>
            }} />
            const component_estado = <Column  title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="estado" />} render={(data) => {
                return <div>{stringByStatus(data.status)}</div>
            }} />

            const component_fecha = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="createdAt" />} render={(data) => {
                return <div>{fechaUTCATexto(data.createdAt)}</div>
            }} />

            const component_tipo_motor = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="id_tipomotor" />} render={(data) => {
                return <div>{data.detalle_tipo_motor ? data.detalle_tipo_motor : "SIN INFORMACIÓN"}</div>
            }} />
            
            const component_creador = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="creador" />} render={(data) => {
                return <div>{data.creador?.nombres ? `${data.creador?.nombres} ${data.creador?.apellidos ? data.creador?.apellidos : ""}` : "SIN INFORMACIÓN"}</div>
            }} />
           
          return <Table dataSource={conductores} pagination={false} scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="equipos" /> }} >
            {
            showSelection !== true ? false : <Column title={<Checkbox onChange={deseleccionarTodos} />} render={(data) => {
                return <Checkbox disabled={checkForEnabledValue(data._id)} checked={validarChecked(data.seleccionado, data._id)} name={data._id} onChange={handleChangeSeleccion}/>
            }} />
            }

            { encontrarLlaveValorEnEsquema("titulo", interfaz_usuario[ key_interfaz ]) === false ? false : component_titulo }
            { encontrarLlaveValorEnEsquema("patente", interfaz_usuario[ key_interfaz ]) === false ? false : component_patente }
            { encontrarLlaveValorEnEsquema("categoria", interfaz_usuario[ key_interfaz ]) === false ? false : component_categoria }
            { encontrarLlaveValorEnEsquema("sub_categoria", interfaz_usuario[ key_interfaz ]) === false ? false : component_sub_categoria }
            { encontrarLlaveValorEnEsquema("ano", interfaz_usuario[ key_interfaz ]) === false ? false : component_ano }
            { encontrarLlaveValorEnEsquema("marca", interfaz_usuario[ key_interfaz ]) === false ? false : component_marca }
            { encontrarLlaveValorEnEsquema("modelo", interfaz_usuario[ key_interfaz ]) === false ? false : component_modelo }     
            { encontrarLlaveValorEnEsquema("id_tipomotor", interfaz_usuario[ key_interfaz ]) === false ? false : component_tipo_motor }     
            { encontrarLlaveValorEnEsquema("ejes", interfaz_usuario[ key_interfaz ]) === false ? false : component_ejes }
            { encontrarLlaveValorEnEsquema("volumen", interfaz_usuario[ key_interfaz ]) === false ? false : component_volumen }
            { encontrarLlaveValorEnEsquema("peso", interfaz_usuario[ key_interfaz ]) === false ? false : component_peso }
            { encontrarLlaveValorEnEsquema("pallets", interfaz_usuario[ key_interfaz ]) === false ? false : component_pallets }
            { encontrarLlaveValorEnEsquema("medidas_equipo", interfaz_usuario[ key_interfaz ]) === false ? false : component_medidas_equipo }
            { encontrarLlaveValorEnEsquema("medidas", interfaz_usuario[ key_interfaz ]) === false ? false : component_medidas }
            { encontrarLlaveValorEnEsquema("peso_bruto", interfaz_usuario[ key_interfaz ]) === false ? false : component_peso_bruto }
            { encontrarLlaveValorEnEsquema("estado", interfaz_usuario[ key_interfaz ]) === false ? false : component_estado } 
            { encontrarLlaveValorEnEsquema("creador", interfaz_usuario[ key_interfaz ]) === false ? false : component_creador }    
            { encontrarLlaveValorEnEsquema("createdAt", interfaz_usuario[ key_interfaz ]) === false ? false : component_fecha }    
          
      </Table>
      
      }
     
      const agregarFiltro = (key, value) => {
        filtroBusqueda[key] = { $in: value.map(v => v._id) }
        console.log(filtroBusqueda)
        setFiltroBusqueda(filtroBusqueda)
      }

      const resetFilters = () => {
        setFiltroBusqueda({})
        setKeyInputFilter(key_input_filter + 1)
        obtenerDatos(1, condicion_default ? condicion_default : {})
    }

      const mostrarFiltrosBusqueda = () => {
        const mostrarButtonReset = Object.keys(filtroBusqueda).length > 0 ? true : false
        return <div style={{ marginBottom: 20 }} className="mt-0">
            <Row gutter={10}>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Filtrar por Categoría</Title>
                    <SelectorGeneral key={key_input_filter} hideLabel={true} mode="multiple" module="categorias" titulo="Categoría" condicion_default={{ tipo: "equipo" }} onChange={(data) => agregarFiltro('id_categoria', data)} />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Filtrar por Sub categoría</Title>
                    <SelectorGeneral key={key_input_filter} hideLabel={true} mode="multiple" module="sub-categorias" titulo="Categoría" condicion_default={{ tipo: "equipo" }} onChange={(data) => agregarFiltro('id_sub_categoria', data)} />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Filtrar por Color</Title>
                    <SelectorColor key={key_input_filter} mode="multiple" onChange={(data) => agregarFiltro("color", data)} placeholder="Color" />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Búsqueda</Title>
                    <Button onClick={() => refrescarBusqueda()} style={{ width: "100%" }} icon={<SearchOutlined />} >ACTUALIZAR</Button>
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mb-2 mt-0" level={5}>FILTRAR</Title>
                    <Button disabled={!mostrarButtonReset}  style={{ width: "100%" }} onClick={() => resetFilters()}>BORRAR FILTROS</Button>
                </Col>
            </Row>
        </div>
      }

      const mostrarContadores = () => {
        // return false
        const component = <ContadoresEquipos onFilter={(condition) => {
            setPagina(1)
            setCondicionBusqueda(condition)
            obtenerDatos(1, condition)
        }} />

        return showCounters === true ? <Col><ModalPersonalizado typeView="modal" slug="distrubion" component={component} /></Col> : false
      }
      
      const mostrarDatosPersonalizados = () => {
        const component = <GraficosCamposPersonalizados typeView="modal" tipo="equipo" />
        return showCustomFieldsData === true ? <Col><ModalPersonalizado typeView="modal" slug="informe_personalizado" component={component} /></Col> : false
      }

    const mostrarRegistros = () => {

        const botones = <Row gutter={15}>
        <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
        <Col span="auto"><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green" onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col>
        </Row>

        return <div>
            <Divider />
            {
                showFilters === true ? <Row gutter={15}>
                <Col md={24}>
                    {mostrarFiltrosBusqueda()}
                    {buscador()}
                </Col>
                <Col md={24}>
                    <Row gutter={15} className="mb-3">
                    {mostrarContadores()}
                    {mostrarDatosPersonalizados()}
                    </Row>
                    
                    {botones}
                    
                    {paginacion(data.pagina, total)}
                    {accionButtons()}
                    {mostrarTabla()}
                </Col>
            </Row> : <div>
                    {mostrarContadores()}
                    {buscador()}
                    {botones}
                    {paginacion(data.pagina, total)}
                    {accionButtons()}
                    {mostrarTabla()}
                </div>
            }
            
            
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoEquipos