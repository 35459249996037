const parsearGeocercaMapa = (data) => {
/*
  if(!data.region) return false
  if(!data.region.coordinates) return false
  if(Array.isArray(data.region.coordinates) !== true) return false
  if(data.region.coordinates.length < 1) return false
  for( const cood of data.region.coordinates ){
    if(!cood) return false
    if(Array.isArray(cood) !== false ){
      for( const subcoord of cood ){
        if(!subcoord) return false
      }
    }
  }
  */

    return {
        _id: data._id,
        titulo: data.titulo,
        type: "Feature",
        properties: data.properties,
        geometry: {
          type: data.region.type,
          coordinates: data.region.coordinates
        }
      }
}

const calcularPuntoCentral = (geocerca) => {
  if (!geocerca || geocerca.length === 0) {
    return false
  }

  const totalPuntos = geocerca.length;
  let sumaLatitudes = 0;
  let sumaLongitudes = 0;

  // Calcular la suma de todas las latitudes y longitudes
  for (const punto of geocerca) {
    sumaLatitudes += punto.latitude;
    sumaLongitudes += punto.longitude;
  }

  // Calcular el promedio de las latitudes y longitudes
  const latitudPromedio = sumaLatitudes / totalPuntos;
  const longitudPromedio = sumaLongitudes / totalPuntos;

  // Retornar el punto central como un objeto con las propiedades latitude y longitude
  return {
    latitude: latitudPromedio,
    longitude: longitudPromedio,
  };
};

const degToRad = deg => deg * (Math.PI / 180);

const calcularDistancia = (coordenada1, coordenada2) => {
  const R = 6371; // Radio de la Tierra en kilómetros
  const dLat = degToRad(coordenada2.latitude - coordenada1.latitude);
  const dLon = degToRad(coordenada2.longitude - coordenada1.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRad(coordenada1.latitude)) *
      Math.cos(degToRad(coordenada2.latitude)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distancia = R * c;
  return distancia;
};


const coordenadasDentroDeRadio = (coordenadaBase, distanciaKm, coordenadaObjetivo) => {
  const distancia = calcularDistancia(coordenadaBase, coordenadaObjetivo);
  return distancia <= distanciaKm;
};

const verificarMismoRadio = ( primera, distance, varios ) => {
  let dentro = 0
    for( const coord of varios ){
      const estaDentroDelRadio = coordenadasDentroDeRadio(primera, distance, coord )
      if(estaDentroDelRadio) dentro++
    }
    return varios.length === dentro ? true : false
}

module.exports = {
  verificarMismoRadio,
  calcularDistancia,
  coordenadasDentroDeRadio,
  parsearGeocercaMapa,
  calcularPuntoCentral
}