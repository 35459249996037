export const estilo_last_mile = {
    color_success: "green",
    color_danger: "red",
    color_primary: "#1B3665",
    color_primary_dark: "#122951",
    color_primary_dark_: "#13284c",
    color_secondary: "#e07420",
    color_third: "#dd0c15",
    color_dark: "#3c0d00",
    bold: { fontWeight: "bold" },
    icon: { verticalAlign: "middle" },
}