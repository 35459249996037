import Map, { FullscreenControl, GeolocateControl, Layer, NavigationControl, Source } from 'react-map-gl';
import { mapbox_token } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import bbox from '@turf/bbox';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = mapbox_token

const MapaGeoJSON = (props) => {
    const {
        redzone,
        default_geojson,
        height,
        features
    } = props

    const pais = useSelector(state => state.pais)
    const sesion = useSelector(state => state.miusuario)
    const idioma = useSelector(state => state.idioma)
    const [ coleccion, setColeccion ] = useState( default_geojson ? default_geojson :{
      type: 'FeatureCollection',
      features
    })
    const [viewState, setViewState] = useState(obtenerCentroMapaPorPais(pais));
    const mapRef = useRef()

    const ajustarMapaAGeocercas = () => {
      if (coleccion.features.length > 0) {

        const instancia_geocercas = JSON.parse( JSON.stringify( coleccion.features ))

        const coordinates = instancia_geocercas.reduce((acc, geofence) => {
          const geofenceCoords = geofence.geometry.coordinates;
          if (geofenceCoords.length === 1) {
            // Polygon
            acc.push(geofenceCoords[0]);
          } else {
            // MultiPolygon
            geofenceCoords.forEach((coords) => {
              if(coords.length < 1) return false
              acc.push(coords[0]);
            });
          }
          return acc;
        }, []);
  
        const combinedFeatures = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Polygon',
                coordinates,
              },
            },
          ],
        };

          const bounds = bbox(combinedFeatures);
          const newViewport = {
            ...viewState,
            latitude: (bounds[1] + bounds[3]) / 2,
            longitude: (bounds[0] + bounds[2]) / 2
          };
    
          const options = {
            padding: 30 // Ajusta el valor de padding según tus necesidades
          };
    
          setViewState(newViewport);
          setTimeout(() => {
            mapRef.current?.fitBounds(bounds, options)
          }, 300);
          
        }
    }

    useEffect(() => {
      
      ajustarMapaAGeocercas()
      
    }, [ features ])

    return <div style={{ height: '100vh', width: '100%' }}>
        <Map
              ref={mapRef}
              {...viewState}
              onMove={evt => setViewState(evt.viewState)}
              style={{width: "100%", height: height ? height : 500}}
              mapStyle="mapbox://styles/mapbox/streets-v9"
              mapboxAccessToken={mapbox_token}
              scrollZoom={false}
              dragPan={false}
            >
              <GeolocateControl position="top-left" />
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
                  <Source
                          key={"ajsfkn"}
                          id={"geocerca._id"}
                          type="geojson"
                          data={coleccion}
                          >
                              
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="line"
                                paint={{
                                    'line-width': 4,
                                    'line-color': '#0080ef'
                                  }}                      
                                />
                              }
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="fill"
                                paint={{
                                    'fill-color': 'black',
                                    'fill-opacity': 0.5
                                  }}                      
                                />
                              }
                                  {
                                    redzone === true ? <Layer
                                    id="geocercas-layer"
                                    type="fill"
                                    paint={{
                                        'fill-color': 'red',
                                        'fill-opacity': 0.2
                                      }}                      
                                    /> : false
                                  }
                          </Source>
            </Map>
  </div>
}

export default MapaGeoJSON