import Title from "antd/es/typography/Title"
import { Col, Progress, Row, message } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import { Link } from "react-router-dom"
import CargandoTabla from "./cargando"
import Paragraph from "antd/es/typography/Paragraph"
import guardarUsuario from "../../redux/actions/guardarUsuario"
import { stringByStatusBusiness } from "../../lib/helpers/data/empresas"
import { BsBoxArrowInUpRight } from "react-icons/bs"

const PorcentajeProgresoEmpresa = () => {
    const [ loadingMaster, setLoadingMaster ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [ empresa, setEmpresa ] = useState(session.data.empresa)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()

    const obtenerUsuario = async () => {
        return fetch(`${urlapi}/empresas/account`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingMaster(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingMaster(false)
            } else if(res._id){
                setEmpresa(res)
                const instancia = JSON.parse( JSON.stringify(session) )
                instancia.data.empresa = res
                dispatch(guardarUsuario(instancia))
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    useEffect(() => {
        obtenerUsuario()
    }, [])

    const simpleView = () => {
        const fontSize = 12
        return <div>
                <Title level={5} style={{ fontSize }} className='m-0 mb-3'>{empresa.razon_social} · {stringByStatusBusiness(empresa.status)} <Link to={rutas.business.slug}><BsBoxArrowInUpRight /></Link></Title>
                {/* <Paragraph className="mb-0" style={{ fontSize: 13 }}>Este es el progreso de tu empresa a nivel documental</Paragraph> */}

                <Paragraph style={{ fontSize, lineHeight: 1, marginBottom: 5, fontWeight: "bold" }}>Requisitos enviados</Paragraph>
                <Progress strokeWidth={10} className="mb-3"percent={empresa.progreso.porcentaje_progreso} />

                <Paragraph style={{ fontSize, lineHeight: 1, marginBottom: 5, fontWeight: "bold" }}>Requisitos aprobados</Paragraph>
                <Progress strokeWidth={10} strokeColor={"#52c41a"} percent={empresa.progreso.porcentaje_aprobado} />

        </div>
    }

    const mostrarInformacion = () => {
        if(loadingMaster) return <div><CargandoTabla /></div>
        if(!empresa) return false
        if(!empresa.progreso) return false

        return simpleView()

       return <Row gutter={15}>
                <Col xs={16}>
                    <Title level={5} className='m-0'>{empresa.razon_social} · {stringByStatusBusiness(empresa.status)} <Link to={rutas.business.slug}><BsBoxArrowInUpRight /></Link></Title>
                    <Paragraph className="mb-0" style={{ fontSize: 13 }}>Este es el progreso de tu empresa a nivel documental</Paragraph>
                </Col>
                <Col xs={4} style={{ textAlign: "right" }}>
                    <Paragraph style={{ fontSize: 13, lineHeight: 1, marginBottom: 5, fontWeight: "bold" }}>Requisitos enviados</Paragraph>
                    <Progress strokeWidth={10} className="mb-3"percent={empresa.progreso.porcentaje_progreso} />
                </Col>
                <Col xs={4} style={{ textAlign: "right" }}>
                    <Paragraph style={{ fontSize: 13, lineHeight: 1, marginBottom: 5, fontWeight: "bold" }}>Requisitos aprobados</Paragraph>
                    <Progress strokeWidth={10} strokeColor={"#52c41a"} percent={empresa.progreso.porcentaje_aprobado} />
                </Col>
            </Row>
    }

    return <div>
        {mostrarInformacion()}
    </div>
}

export default PorcentajeProgresoEmpresa