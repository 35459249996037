import { Button, message } from "antd";
import ButtonGroup from "antd/es/button/button-group"
import Title from "antd/es/typography/Title";
import { FaCheckCircle, FaRegTrashAlt } from "react-icons/fa";
import { estilo_moving_truck } from "../../lib/estilo_sitio";
import { urlapi } from "../../lib/backend/data";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { useState } from "react";
import { IoMdAlert } from "react-icons/io";

const AccionesSolicitud = (props) => {
    const {
        id_solicitud,
        status,
        type
    } = props
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const estilo_icono = { verticalAlign: "middle" }
    const dispatch = useDispatch()
    const global_content = <div>{contextHolder}</div>
    const levelTitle = 3

    const performarAccion = async (new_status) => {
        setLoading(true)
        return fetch(`${urlapi}/disponibilidades/solicitudes/status`,{
            method:'POST',
            body: JSON.stringify({
                status: new_status,
                id_solicitud,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                if(props.onChange) props.onChange(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    const formularioCliente = () => {
        if(status === "pendiente-cliente"){
            return <div>
                {global_content}
                <ButtonGroup>
                <Button loading={loading} type="primary" onClick={() => performarAccion("aceptada-cliente")} icon={<FaCheckCircle style={estilo_icono} />} >ACEPTAR SOLICITUD</Button>
                <Button loading={loading}  onClick={() => performarAccion("rechazada-cliente")} icon={<FaRegTrashAlt />}>RECHAZAR SOLICITUD</Button>
                </ButtonGroup>
            </div>
        } else if(status === "aceptada-cliente"){
            return <div>
                {global_content}
                <Title  level={levelTitle}>Solicitud Aceptada <FaCheckCircle style={estilo_icono} color="green" /></Title>
                <ButtonGroup>
                <Button loading={loading} type="primary" onClick={() => performarAccion("rechazada-cliente")} icon={<FaRegTrashAlt />}>RECHAZAR SOLICITUD</Button>
                </ButtonGroup>
            </div>
        } else if(status === "rechazada-cliente"){
            return <div>
                {global_content}
                <Title  level={levelTitle}>Solicitud Rechazada <IoMdAlert style={estilo_icono} color="red" /></Title>
                <Button loading={loading} type="primary" onClick={() => performarAccion("aceptada-cliente")} icon={<FaCheckCircle style={estilo_icono} />}>ACEPTAR SOLICITUD</Button>
            </div>
        } else if(["aceptada-proveedor","rechazada-proveedor","pendiente-proveedor"].includes(status)){
            return <div>
                {global_content}
                <ButtonGroup>
                <Button loading={loading} type="primary" onClick={() => performarAccion("rechazada-cliente")} icon={<FaRegTrashAlt />}>RECHAZAR SOLICITUD</Button>
                </ButtonGroup>
            </div>
        }
    }

    const formularioProveedor = () => {
        if(status === "pendiente-proveedor"){
            return <div>
                {global_content}
                <ButtonGroup>
                <Button loading={loading} type="primary" onClick={() => performarAccion("aceptada-proveedor")} icon={<FaCheckCircle style={estilo_icono} />}>ACEPTAR SOLICITUD</Button>
                <Button loading={loading}  onClick={() => performarAccion("rechazada-proveedor")} icon={<FaRegTrashAlt />}>RECHAZAR SOLICITUD</Button>
                </ButtonGroup>
            </div>
        } else if(status === "aceptada-proveedor"){
            return <div>
                {global_content}
                <Title  level={levelTitle}>Solicitud Aceptada <FaCheckCircle style={{ verticalAlign: "middle" }} color="green" /></Title>
                <ButtonGroup>
                <Button loading={loading} type="primary" onClick={() => performarAccion("rechazada-proveedor")} icon={<FaRegTrashAlt />}>RECHAZAR SOLICITUD</Button>
                </ButtonGroup>
            </div>
        } else if(status === "rechazada-proveedor"){
            return <div>
                {global_content}
                <Title  level={levelTitle}>Solicitud Rechazada <IoMdAlert style={{ verticalAlign: "middle" }} color="red" /></Title>
                <Button loading={loading} type="primary" onClick={() => performarAccion("aceptada-proveedor")} icon={<FaCheckCircle />}>ACEPTAR SOLICITUD</Button>
            </div>
        
        }
    }

    const mostrarAcciones = () => {
        if(type === "proveedor"){
            return formularioProveedor()
        } else if(type === "cliente"){
            return formularioCliente()
        }
    } 
    
    if(!id_solicitud) return <div></div>

    return <div>{mostrarAcciones()}</div>
} 

export default AccionesSolicitud