import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Alert, Breadcrumb, Button, Card, Col, Divider, Form, Input, Layout, Popconfirm, Row, Select, Tabs, Upload, message } from "antd"
import { CameraOutlined, CarFilled, HomeOutlined, InfoCircleFilled, LoadingOutlined, PlusOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { limpiarStringLetrasNumeros, limpiarStringNumeros, siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import { config_s3, maps_key, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import AutoComplete from "react-google-autocomplete";
import S3FileUpload from 'react-s3';
import CargandoTabla from "./cargando"
import { useParams } from "react-router-dom"
import CamposPersonalizadosRecurso from "../../subComponents/campos_personalizados/listado_recurso"
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main"
import SelectorGeneral from "../General/selector"
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps"
import { AiFillMinusCircle, AiOutlineMail } from "react-icons/ai"
import { GrAdd, GrDocumentText } from "react-icons/gr"
import { codigos_area } from "../../lib/internacional"
import { GoDeviceMobile } from "react-icons/go"
import { LiaWarehouseSolid } from "react-icons/lia"
import Bodegas from "../Bodegas"
import Contactos from "../Contactos"
import { BiUser } from "react-icons/bi"
import { opciones_tamano } from "./data"
import Relacionador from "../../subComponents/general/relacionador"

const DetallesCliente = (props) => {
    const {
        id
    } = useParams()
    const [ loading, setLoading ] = useState(false)
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ loadingDefinicion, setLoadingDefinicion ] = useState(false)
    const [ loadingImagen, setLoadingImagen ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ usuario, setUsuario ] = useState(false)
    const dispatch = useDispatch()
    const [ condicion_sub_categoria, setCondicionSubCategoria ] = useState({ tipo: "cliente" })
    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
    const unidades_medida_volumen       = obtenerUnidadesMedidaPorTipoPais("volumen", pais).map(e => e)
    const requeridos = [
        { value: 'razon_social', label: 'Razón social' },
        { value: 'rut', label: 'Identificador fiscal' }
      ];
    const module = 'clientes'

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/${module}?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res){
                setUsuario(res)
                if(res.detalles_conductor){
                    if(typeof res.detalles_conductor === "object"){
                        if(res._id) setConductor(res.detalles_conductor)
                    }
                }
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }
    
    const eliminarRecurso = async () => {
        setLoadingMaster(true)
        return fetch(`${urlapi}/clientes?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                return window.location = rutas.clients.slug
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const handleChangeSelectSimple = (e,name) => {
        usuario[name] = e
        return setUsuario({...{}, ...usuario})
    }
    const handleChangeVehiculoCustom = (e,name) => {
        console.log({e,name})
        usuario[name] = e._id
        if(name === "id_tipo") usuario.tipo = e.tipo
        if(name === "marca") usuario.modelo = ""
        console.log(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const seleccionarConductor = (data) => {
            usuario.id_usuario = data._id
            console.log(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const handleChangeSelect = (e,name) => {
        return setUsuario(prev => {
            let actual = {...prev}
            actual[name] = e
            return {...{}, ...actual}
        
        })
    }

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        
        return setUsuario(prev => {
            let actual = {...prev}
            if(name === "rut"){
                actual[name] = limpiarStringLetrasNumeros(value)
            } else if(name === "phone"){
                actual[name] = limpiarStringNumeros(value)
            } else {
                actual[name] = value
            }
            return {...{}, ...actual}
        
        })
    }

    const uploadButton = (
        <div>
          {loadingImagen ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Subir foto
          </div>
        </div>
      );

    const handleChangeUsuarioSelect = (name, value) => {
        usuario[name] = value
        return setUsuario(usuario)
    }

    const crearConductorNuevo = async () => {
        
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
        usuario.autorizado = "si"

        if(usuario.emails_adicionales) if(usuario.emails_adicionales.length > 0){
            const invalidos = usuario.emails_adicionales.filter(e => !e.valid || !e.email)
            if(invalidos.length > 0) return messageApi.error(`Emails inválidos: ${invalidos.map(ma => ma.email ? ma.email : "Email vacío").join(', ')}`)  
        }
        
        if(usuario.moviles_adicionales) if(usuario.moviles_adicionales.length > 0){
            const invalidos = usuario.moviles_adicionales.filter(e => !e.pais_codigo ||!e.movil)
            if(invalidos.length > 0) return messageApi.error(`Números de teléfono inválidos: ${invalidos.map(ma => {
                const codigo = ma.pais_codigo ? ma.pais_codigo : "Código area vacío"
                const numero = ma.movil ? ma.movil : "Número vacío"
                return `${codigo} ${numero}`
            }).join(', ')}`)  
        }

        setLoading(true)
        const url = `${urlapi}/${module}`
        return fetch(url, {
            method: "PUT",
            body: JSON.stringify(usuario),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                return setTimeout(() => {
                    return window.location = `${rutas.clients.slug}/${res._id}`
                }, 1000);
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    const cambiarVehiculoConductor = async () => {
        setLoadingDefinicion(true)
        return fetch(`${urlapi}/vehiculos/definir-seleccionado`, {
            method: 'POST',
            body: JSON.stringify({
              id: usuario._id
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer: ${session.tokenSession}`,
              'country': pais,
              'lang': idioma
            },
          })
            .then((res) => {
              if (res.status === 401) return dispatch(cerrarSesion());
              return res.json();
            })
            .then((res) => {
              if (!res) {
                messageApi.error('Sin datos');
              } else if (res.errorMessage) {
                messageApi.error(res.errorMessage);
              } else if (res._id) {
                usuario.selected = true
                messageApi.success("Realizado exitosamente")
                setUsuario(prev => ({...{}, ...usuario}));
              }
              return setLoadingDefinicion(false);
            })
            .catch((error) => {
              messageApi.error('Error al consultar la información, intente nuevamente');
              return setLoadingDefinicion(false);
            })
      }


    const handleChangeArchivo = async (e) => {

        const archivo = e.file.originFileObj
        setLoadingImagen(true)
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            messageApi.error('No pudimos cargar tu imagen')
            return setLoadingImagen(false)
        }

        usuario.imagen_perfil = data.location
        setUsuario({...{}, ...usuario})
        return setLoadingImagen(false)

        })
        .catch(err => {
            console.log(err)
            setLoadingImagen(false)
            return messageApi.error('No pudimos cargar tu imagen')
        })
    }

    const handleChangeCat = (e) => {
        usuario.id_categoria = e._id
        usuario.id_sub_categoria = ''
        setUsuario(usuario)
        setCondicionSubCategoria(prev => ({...prev, id_categoria: e._id }))
    }

    const handleChangeSubCat = (e) => {
        usuario.id_sub_categoria = e._id
        setUsuario({...{}, ...usuario})
    }

    const handleChangeMovil = (e, pos, name) => {
        let value = e
        if(typeof e === "object") if(e.target) value = e.target.value
        usuario.moviles_adicionales[pos][name] = value
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const removerMovil = (i) => {
        usuario.moviles_adicionales.splice(i,1)
        return setUsuario(prev => ({...{}, ...usuario})) 
    }
    
    const anadirMovilAdicional = () => {
        if(!usuario.moviles_adicionales) usuario.moviles_adicionales = []
        usuario.moviles_adicionales.unshift({
            pais_codigo: '',
            movil: ''
        })
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const anadirEmailAdicional = () => {
        if(!usuario.emails_adicionales) usuario.emails_adicionales = []
        usuario.emails_adicionales.unshift({
            email: ''
        })
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const removerEmail = (i) => {
        usuario.emails_adicionales.splice(i,1)
        return setUsuario(prev => ({...{}, ...usuario})) 
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        usuario.emails_adicionales[pos][name] = value
        usuario.emails_adicionales[pos].valid = validateEmail(value)
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const mostrarEmailsAdicionales = () => {
        if(!usuario.emails_adicionales) return false
        return <div>
            {
                usuario.emails_adicionales.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-3">
                        <Card size="small" title="Email" className="mb-3" extra={<Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>} >
                                <Form.Item label="" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el email" prefix={<AiOutlineMail />} className='mb-0' status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} />
                                </Form.Item>
                        </Card>
                    </div>
                })
            }
        </div>
    }

    const mostrarMovilesAdicionales = () => {

        const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

        if(!usuario.moviles_adicionales) return false
        return <div>
            {
                usuario.moviles_adicionales.map((field,i) => {
                    return <div key={`movil-${i}`} className="mt-3">
                    <Card size="small" title="Móvil" className="mb-3" extra={<Button type="ghost" onClick={() => removerMovil(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />}>REMOVER</Button>} >
                        <Row gutter={15}>
                            <Col md={8}>
                                <Form.Item label="Código de área" required={true} className="mb-0">
                                <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={field.pais_codigo} onChange={(e) => handleChangeMovil(e,i,"pais_codigo")} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                                </Form.Item>
                            </Col>
                            <Col md={16}>
                                <Form.Item label="Número" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el número telefónico" className='form-control mb-3' name="movil" value={field.movil} onChange={(e) => handleChangeMovil(e,i,"movil")} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    </div>
                })
            }
        </div>
    }

    const render = () => {
        if(loadingMaster) return <Card><CargandoTabla /></Card>
        if(!usuario) return <Card><Title>No hay suficientes datos para mostrar esta pantalla</Title> </Card>

        return <div>
            <Card className="mb-3">
            <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.clients.slug,
                    title: <span>Clientes</span>,
                },
                {
                    title: 'Detalles de cliente',
                },
                ]}
            />
            <Title className="mb-2 mt-0">Detalles de cliente</Title>
            <Popconfirm
                    title="Eliminar definitivamente"
                    description="¿Estás seguro que deseas eliminar este recurso? Esta opción no se puede deshacer"
                    onConfirm={() => eliminarRecurso()}
                    onCancel={() => false}
                    okText="SI"
                    cancelText="NO"
                  >
                  <Button size="small" type="dashed" danger>ELIMINAR</Button>
                  </Popconfirm>
            </Card>
            <Layout style={{ padding: 20 }}>
            <Row gutter={20}>
                <Col md={12}>
                        <Card size="small">
                    {/* <ModalSeleccionVehiculos onRemove={() => {
                        setConductor(false)
                        usuario.id_usuario = ''
                        usuario.selected = false
                        return setUsuario({...{}, ...usuario})
                    }} defaultValue={conductor} onSelect={data => seleccionarConductor(data)} />
                    <Divider /> 
                {autoSeleccionado()}
                */}
            <Form layout="vertical">
            <Row gutter={10}>
                <Col xs={24}>
                    <Form.Item label="Razón social" >
                    <Input mb={3} name="razon_social" defaultValue={usuario.razon_social} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item label="Nombre comercial" >
                    <Input mb={3} name="nombre_comercial" defaultValue={usuario.nombre_comercial} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item label="Sitio web" >
                    <Input mb={3} name="web" defaultValue={usuario.web} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    <Form.Item label="Tamaño de empresa" required={siEsRequeridoEnEsquema('tamano',requeridos)} >
                    <Select defaultValue={usuario.tamano} options={opciones_tamano} onChange={(e) => handleChangeSelect(e,"tamano")} />
                    </Form.Item>
                </Col>
                <Col xs={24}>
                <Form.Item label="Dirección principal" required={siEsRequeridoEnEsquema('patente',requeridos)} >
                    <AutoComplete
                    defaultValue={usuario.direccion}
                    className="ant-input inputlocation"
                    options={{
                        types: ["address"],
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                            const geo_data = procesarGeoDatosGeocode(place)

                        return setUsuario(prev => {
                            let actual = {...prev}
                            actual.direccion = place.formatted_address
                            actual.geodata = geo_data
                            actual.location = {
                                type: "Point",
                                coordinates: [
                                    geo_data.lng, geo_data.lat
                                ]
                            }

                            return actual
                        })
                    }}
                    />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item label="Identificador fiscal" >
                    <Input mb={3} variant="filled" name="rut" defaultValue={usuario.rut} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item label="Estado" >
                    <Select className="mb-0" name="status" placeholder="Estado" style={{ width: "100%" }} defaultValue={usuario.status} options={[{ value: "active", label: "Activo" }, { value: "trash", label: "Inactivo"}]} onChange={(e) => handleChangeSelectSimple(e,'status')} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <SelectorGeneral module="categorias" value={usuario.id_categoria} titulo="Categoría" condicion_default={{ tipo: "cliente" }} onChange={handleChangeCat} />
                </Col>
                <Col md={12} xs={24}>
                    <SelectorGeneral module="sub-categorias" value={usuario.id_sub_categoria} titulo="Sub Categoría" condicion_default={condicion_sub_categoria} onChange={handleChangeSubCat} />
                </Col>
                
                <Col md={24} xs={24}>
                    <Title className="mt-0" level={4}>Datos de contacto</Title>
                    <Row gutter={15}>
                        <Col md={24}>
                        <Title level={4} className="mt-0" ><GoDeviceMobile /> Teléfonos</Title>
                        <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirMovilAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarMovilesAdicionales()}
                        </Col>
                        <Col md={24}>
                        <Title level={4} className="mt-0" ><AiOutlineMail /> Emails</Title>
                        <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirEmailAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarEmailsAdicionales()}
                        </Col>
                    </Row>
                </Col>
                
                
            </Row>     
               
            <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => crearConductorNuevo()} ><SaveOutlined /> GUARDAR CAMBIOS</Button>
            </Form>
            </Card>

                </Col>
                <Col md={12}>
                    <Card size="small">
                    <Tabs defaultActiveKey="1" items={[
                        {
                            key: "1",
                            label: <div  style={{ textAlign: "center" }}><GrDocumentText style={{ fontSize: 20 }} /> <Title level={4} className="m-0">MÁS INFORMACIÓN</Title></div>,
                            children: <CamposPersonalizadosRecurso id_target={id} tipo="cliente" />
                        },
                        {
                            key: "2",
                            label: <div style={{ textAlign: "center" }}><LiaWarehouseSolid style={{ fontSize: 20 }} /> <Title level={4} className="m-0">BODEGAS</Title></div>,
                            children: <Bodegas id_cliente={id} default_payload={{ id_cliente: id }} />
                        },
                        {
                            key: "3",
                            label: <div style={{ textAlign: "center" }}><BiUser style={{ fontSize: 20 }} /> <Title level={4} className="m-0">CONTACTOS</Title></div>,
                            children: <div>
                                <Relacionador payload_origin_default={{ id_origin: id, type_origin: "empresa" }} type_destination="contacto" condicion_default={{ id_origin: id }} />
                            </div>
                            // <Contactos showCustomFieldsData={false} showFilters={false} default_payload={{ id_cliente: id }} condicion={{ id_cliente: id }} />
                        },
                    ]} />
                    </Card>
                </Col>
            </Row>
            </Layout>
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default DetallesCliente