import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  Appointments,
  AppointmentTooltip,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  TodayButton,
  Resources,
} from "@devexpress/dx-react-scheduler-material-ui";
import { useState } from "react";
import { Col, Divider, Modal, Row, Spin, Tabs } from "antd";
import { DateTime } from "luxon";
import { fechaATextoSimple, formatYMD_UTC } from "../../lib/helpers/helpers";
import Title from "antd/es/typography/Title";
import { FaCircle, FaTruck } from "react-icons/fa";
import { MdOutlinePlace, MdPlace } from "react-icons/md";
import { GiHandTruck } from "react-icons/gi";
import { FaTruckFront, FaTruckRampBox } from "react-icons/fa6";
import Paragraph from "antd/es/typography/Paragraph";
import ListadoVehiculos from "../Vehiculos/listado";


const AgendaDisponibilidad = (props) => {
    const {
        data,
        stopPropagation
    } = props
    const color_carga = "orange"
    const color_descarga = "#0070ff"
    const color_indefinido = "#74c574"
    const cargas = data.map((event) => {
        if(event.tipo_disponibilidad === "unica"){
            return {
                title: "Carga",
                startDate: event.fecha_carga_desde,
                endDate: event.fecha_carga_hasta,
                color: color_carga,
                places: `En ${event.ids_origin.map(e => e.titulo).join(', ')}`,
                ...event
            }
        }
        return {
            title: "Carga",
            startDate: DateTime.now().plus({ days: 1 }).startOf('day').toISO(),
            endDate: event.vencimiento,
            color: color_carga,
            places: `En ${event.ids_origin.map(e => e.titulo).join(', ')}`,
            ...event
        }
    }).filter(event => event)

    const descargas = data.map((event) => {
        if(event.tipo_disponibilidad === "unica"){
            return {
                title: "Descarga",
                startDate: event.fecha_descarga_desde,
                endDate: event.fecha_descarga_hasta,
                color: color_descarga,
                places: `En ${event.ids_destination.map(e => e.titulo).join(', ')}`,
                ...event
            }
        }
        return {
            title: "Descarga",
            startDate: DateTime.now().plus({ days: 1 }).startOf('day').toISO(),
            endDate: event.vencimiento,
            color: color_descarga,
            places: `En ${event.ids_destination.map(e => e.titulo).join(', ')}`,
            ...event
        }
    }).filter(event => event)
    const [ loadEvents, setLoadEvents ] = useState([ ...cargas, ...descargas ])
    const [ loading, setLoading ] = useState(false)
    const [ eventoSeleccionado, setEventoSeleccionado ] = useState(false)
    const locale = "es-ES"

    const mostrarModalEvento = () => {

        if(!eventoSeleccionado) return false
        let vehiculos = []
        if(eventoSeleccionado) if(eventoSeleccionado.vehiculos) if(Array.isArray(eventoSeleccionado.vehiculos)) vehiculos = eventoSeleccionado.vehiculos

        return <Modal
                width="80%"
                title={`${eventoSeleccionado.title} ${eventoSeleccionado.places}`}
                open={eventoSeleccionado ? true : false} 
                onCancel={() => {
                    setEventoSeleccionado(false)
                    // setShowModalVehiculo(false)
                }}
                // confirmLoading={loading}
                footer={[]}
            >
            {/* <Title level={3} className="mt-0 mb-0">{eventoSeleccionado.title}</Title> */}
            { eventoSeleccionado.startDate ? <div>
                <Title level={4} className="mt-0 mb-0">Desde {fechaATextoSimple(eventoSeleccionado.startDate)} {eventoSeleccionado.endDate ? `hasta ${fechaATextoSimple(eventoSeleccionado.endDate)}` : false}</Title>
            </div> : <div>
            { eventoSeleccionado.vencimiento ? fechaATextoSimple(eventoSeleccionado.vencimiento) : false }
            </div> }
            <Paragraph><MdOutlinePlace /> {eventoSeleccionado.places}</Paragraph>
            <Divider className="mt-3 mb-3" />
            <Title level={3} className="mt-0 mb-0"><FaTruck style={{ verticalAlign: "middle" }} /> {vehiculos.length} Vehículos disponibles</Title>
            <ListadoVehiculos onSelect={(data => false)} typeView="funcion" condicion_default={{ _id: { $in: vehiculos.map(veh => veh._id) } }} />
        </Modal>
    }

    const AppointmentContent = ({ data, ...restProps }) => (
        <Appointments.AppointmentContent {...restProps} data={data}>
          <div style={{ color: 'white', fontSize: 11 }}>
            <div><b>{data.title}</b> {`${new Date(data.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${new Date(data.endDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}</div>
            <Row gutter={10}>
                <Col span="auto"><div style={{ fontSize: 12 }}><MdPlace style={{ verticalAlign: "middle" }} /> {data.places}</div></Col>
                <Col span="auto"><div style={{ fontSize: 12 }}><FaTruckFront style={{ verticalAlign: "middle" }} /> {data.vehiculos.length} Vehículos</div></Col>
                <Col span="auto"></Col>
            </Row>
          </div>
        </Appointments.AppointmentContent>
      );

      const Content = (({
        children, appointmentData, ...restProps
      }) => (
        <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
          <div>
            {JSON.stringify(appointmentData)}
          </div>
        </AppointmentTooltip.Content>
      ));

    const CustomAppointmentTooltipContent = ({ appointmentData, ...restProps }) => (
  <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
    <div>
      <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>{JSON.stringify(appointmentData)}</div>
      <div>
        <strong>Start:</strong> {`${new Date(appointmentData.startDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
      </div>
      <div>
        <strong>End:</strong> {`${new Date(appointmentData.endDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`}
      </div>
      <div style={{ marginTop: '8px' }}>
        <strong>Description:</strong>
      </div>
    </div>
  </AppointmentTooltip.Content>
);


    const Appointment = ({ children, style, data, ...restProps }) => {
        return <Appointments.Appointment
        {...restProps}
        style={{
          ...style,
          backgroundColor: data.color,
          color: 'white', // Asegura que el texto sea visible
          borderRadius: '8px', // Agrega un poco de estilo para diferenciar las citas
          padding: '5px', // Asegura que haya espacio suficiente para el texto

        }}
        onClick={() => handleAppointmentClick(data)}
      >
        {/* {children} */}
        <AppointmentContent data={data} />
      </Appointments.Appointment>
    };

    const handleAppointmentClick = (appointmentData) => {
        if(stopPropagation === true) return false
        setEventoSeleccionado(appointmentData)
    }

      const mostrarCalendarioDatos = (eventos) => {
        return <div>
            <Scheduler data={eventos}
          locale={locale}
          height={660}>
          {/* Aqui se agrega el calendario para mostrar los datos en el mismo */}
          <ViewState
            defaultCurrentDate={formatYMD_UTC(DateTime.now().startOf('day').toISO())}
            defaultCurrentViewName="Month"
          />{" "}
            {/* <DayView    startDayHour={0} endDayHour={24} displayName="DÍA" /> */}
            {/* <WeekView   startDayHour={0} endDayHour={24} displayName="SEMANA"/> */}
            <MonthView  startDayHour={0} endDayHour={24} displayName="MES" />
            <Toolbar />
            <DateNavigator />
            <TodayButton messages={{today:'Hoy'}} />
            {/* <ViewSwitcher /> */}
            <Appointments  appointmentComponent={Appointment} />
            <AppointmentTooltip contentComponent={Content} showCloseButton />
        </Scheduler>
        </div>
      }

      const items_resultados = [
        {
            label: <div><Title level={4} className="mt-0 mb-0" ><GiHandTruck style={{ verticalAlign: "middle" }} /> DISPONIBILIDAD DE CARGAR</Title> </div>,
            children: <div>{mostrarCalendarioDatos(cargas)}</div>
        },
        {
            label: <div><Title level={4} className="mt-0 mb-0" ><FaTruckRampBox style={{ verticalAlign: "middle" }} /> DISPONIBILIDAD DE DESCARGAR</Title> </div>,
            children: <div>{mostrarCalendarioDatos(descargas)}</div>
        }
        ].map((item,i) => ({ ...item, key: (i+1) }) )

    return <div>
        {/* {JSON.stringify(loadEvents)} */}
        <Divider className="mb-3 mt-3" />
        <Row gutter={15}>
            <Col span="auto">
                <Title className="mt-0 mb-0" level={4}><FaCircle color={color_carga} style={{ verticalAlign: "middle" }} /> Disponible para carga</Title>
            </Col>
            <Col span="auto">
                <Title className="mt-0 mb-0" level={4}><FaCircle color={color_descarga} style={{ verticalAlign: "middle" }} /> Disponible para descarga</Title>
            </Col>
        </Row>
        {mostrarModalEvento()}
        <Tabs defaultActiveKey={1} items={items_resultados} />
    </div>
}

export default AgendaDisponibilidad