export const centerMapCoordinates = (coords) => {
    if (coords.length === 1) {
      return coords[0];
    }
  
    let x = 0.0;
    let y = 0.0;
    let z = 0.0;
  
    for (let coord of coords) {
      let latitude = coord.lat * Math.PI / 180;
      let longitude = coord.lng * Math.PI / 180;
  
      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    }
  
    let total = coords.length;
  
    x = x / total;
    y = y / total;
    z = z / total;
  
    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);
  
    return {
      lat: centralLatitude * 180 / Math.PI,
      lng: centralLongitude * 180 / Math.PI
    };
  }

export const procesarGeoDatosGeocode = (place) => {
    let pais = ''
    let codigo_pais = ''
    let level1 = ''
    let level2 = ''
    let level3 = ''
    const lat = place.geometry.location.lat()
    const lng = place.geometry.location.lng()
    let formatted_address = ''

    if(place.formatted_address){
        formatted_address = place.formatted_address
    }

    const i_pais = place.address_components.findIndex(a => a.types.includes('country'))
    if(i_pais > -1){
        pais = place.address_components[i_pais].long_name
        codigo_pais = place.address_components[i_pais].short_name
    }

    const l1 = place.address_components.findIndex(a => a.types.includes('administrative_area_level_1'))
    if(l1 > -1) level1 = place.address_components[l1].long_name
    
    const l2 = place.address_components.findIndex(a => a.types.includes('administrative_area_level_2'))
    if(l2 > -1) level2 = place.address_components[l2].long_name
    
    const l3 = place.address_components.findIndex(a => a.types.includes('administrative_area_level_3'))
    if(l3 > -1) level3 = place.address_components[l3].long_name

    return {
        pais,
        codigo_pais,
        formatted_address,
        level1,
        level2,
        level3,
        lat,
        lng
    }
}