import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Badge, Breadcrumb, Button, Card, Col, Form, Input, Layout, Row, Select, Switch, Upload, message } from "antd"
import { CameraOutlined, HomeOutlined, LoadingOutlined, PlusOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { limpiarStringLetrasNumeros, limpiarStringNumeros, siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import { config_s3, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import { obtenerPrefijoMovil, validarLongitudNumeroMovil } from "../../lib/helpers/data/moviles"
import Paragraph from "antd/es/typography/Paragraph"
import ReactFlagsSelect from "react-flags-select"
import { paises_codigos_telefonos, paises_sistema } from "../../lib/global/data"
import { formatoAyudaDNIPorPais, formatoAyudaMovilPorPais, nombreDNIPorPais, validarDNIPorPais } from "../../lib/helpers/data/internationa"
import S3FileUpload from 'react-s3';
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios"



const CrearConductor = (props) => {
    const [ loading, setLoading ] = useState(false)
    const [ showPassword, setShowPassword ] = useState(false)
    const [ loadingImagen, setLoadingImagen ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ usuario, setUsuario ] = useState({
        pais_code: pais,
        codigo_movil: obtenerPrefijoMovil(pais)
    })
    const dispatch = useDispatch()

    const requeridos = [
        { value:'nombres', label: 'Nombres' },
        { value:'apellido_p', label: 'Primer Apellido' },
        { value:'apellido_m', label: 'Segundo Apellido' },
        { value:'rut', label: 'DNI' },
        { value:'phone', label: 'Móvil' }
    ]
    
    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        if(name === "rut"){
            usuario[name] = limpiarStringLetrasNumeros(value)
        } else if(name === "phone"){
            usuario[name] = limpiarStringNumeros(value)
        } else {
            usuario[name] = value
        }
        return setUsuario({...{}, ...usuario})
    }

    const uploadButton = (
        <div>
          {loadingImagen ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Subir foto
          </div>
        </div>
      );

    const handleChangeUsuarioSelect = (name, value) => {
        usuario[name] = value
        return setUsuario(usuario)
    }

    const crearConductorNuevo = async () => {
        
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  

        const phone = `${usuario.codigo_movil}${usuario.phone}`

        if(usuario.email) if(!validateEmail(usuario.email)) return messageApi.error(`Email inválido`)  
        //if(!validarDNIPorPais(usuario.rut, pais)) return messageApi.error(`${nombreDNIPorPais(pais)} inválido`)  
        // if(!validarLongitudNumeroMovil(usuario.phone, pais)) return messageApi.error(`Número móvil inválido`)  

        const usuario_crear = JSON.parse( JSON.stringify(usuario))
        usuario_crear.status = "active"
        usuario_crear.validado = true
        usuario_crear.phone = phone

        setLoading(true)
        const url = `${urlapi}/conductores`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(usuario_crear),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                return setTimeout(() => {
                    return window.location = `${rutas.drivers.slug}/${res._id}`
                }, 1000);
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    const handleChangeArchivo = async (e) => {

        const archivo = e.file.originFileObj
        setLoadingImagen(true)
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            messageApi.error('No pudimos cargar tu imagen')
            return setLoadingImagen(false)
        }

        usuario.imagen_perfil = data.location
        setUsuario({...{}, ...usuario})
        return setLoadingImagen(false)

        })
        .catch(err => {
            console.log(err)
            setLoadingImagen(false)
            return messageApi.error('No pudimos cargar tu imagen')
        })
    }
    

    const render = () => {
        return <div>
            <Card className="mb-3">
            <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.drivers.slug,
                    title: <span>Conductores</span>,
                },
                {
                    title: 'Crear conductor',
                },
                ]}
            />
            <Title className="mb-2 mt-0">Crear conductor</Title>
            </Card>
            <Layout style={{ padding: 20 }}>
            <Row gutter={20}>
                <Col md={12}>
                        <Card size="small">
                    <Title level={4} className="mt-0 mb-3"><UserOutlined /> Datos personales</Title>
                        <CamposObligatoriosLeyenda />
            <Form layout="vertical">
            <Row gutter={10}>
                <Col md={8} xs={24}>
                    <Form.Item label="Nombres" required={siEsRequeridoEnEsquema('nombres',requeridos)} >
                    <Input mb={3} placeholder="Nombres" name="nombres" defaultValue={usuario.nombres} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                
                <Col md={8} xs={24}>
                    <Form.Item label="Apellido paterno" required={siEsRequeridoEnEsquema('apellido_p',requeridos)} >
                    <Input mb={3} placeholder="Apellido Paterno" variant="filled" name="apellido_p" defaultValue={usuario.apellido_p} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Apellido materno" required={siEsRequeridoEnEsquema('apellido_m',requeridos)} >
                    <Input mb={3} placeholder="Apellido Materno" variant="filled" name="apellido_m" defaultValue={usuario.apellido_m} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label={`${nombreDNIPorPais(pais)}`} tooltip={formatoAyudaDNIPorPais(pais)} required={siEsRequeridoEnEsquema('rut',requeridos)} >
                    <Input mb={3} variant="filled" name="rut" value={usuario.rut} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                
                <Col md={8} xs={24}>
                    <Form.Item label="Sexo" required={siEsRequeridoEnEsquema('sexo',requeridos)} >
                    <Select
                    options={[{ value: "H", label: "Hombre" },{ value:"M", label: "Mujer" }]}
                    onChange={(e) => handleChangeUsuarioSelect('sexo',e)} 
                    />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Email" required={siEsRequeridoEnEsquema('email',requeridos)} >
                    <Input mb={3} variant="filled" placeholder="Email" name="email" defaultValue={usuario.email} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
            </Row>
            
                <Form.Item label="Móvil" tooltip={formatoAyudaMovilPorPais(pais)} required={siEsRequeridoEnEsquema('phone',requeridos)} >
            <Row className="mb-3" gutter={10}>
                <Col xs={10}>
                        <ReactFlagsSelect
                            countries={paises_sistema}
                            customLabels={paises_codigos_telefonos}
                            placeholder="CÓDIGO PAÍS"
                            searchPlaceholder="Buscar"
                            disabled
                            selected={usuario.pais_code}
                            onSelect={value => {
                                const prefijo = obtenerPrefijoMovil(value)
                                usuario.codigo_movil    = prefijo
                                usuario.pais_code       = value
                                console.log(usuario)
                                return setUsuario({...{}, ...usuario})
                            }}
                            />
                </Col>
                <Col xs={14}>
                <Input mb={3} variant="filled" style={{ height: 38 }} name="phone" value={usuario.phone} onChange={handleChangeUsuario}  />
                </Col>
            </Row>        
            </Form.Item>
               
            <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => crearConductorNuevo()} ><SaveOutlined /> CREAR</Button>
            </Form>
            </Card>

                </Col>
                <Col md={12}>
                        <Card size="small">
                    <Title level={4} className="mt-0 mb-3"><CameraOutlined /> Fotografía</Title>
                    <Upload
                    name="avatar"
                    listType="picture-circle"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={(e) => console.log(e)}
                    onChange={handleChangeArchivo}
                >
                    {usuario.imagen_perfil ? (
                    <img
                        src={usuario.imagen_perfil}
                        alt="avatar"
                        style={{
                        width: '100%',
                        }}
                    />
                    ) : (
                    uploadButton
                    )}
                </Upload>
                    </Card>
                </Col>
            </Row>
            </Layout>
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default CrearConductor