const tipos_notificacion_alertas = [
    {
        value: "info",
        label: "Alerta informativa"
    },
    {
        value: "warning",
        label: "Alerta de advertencia"
    },
    {
        value: "danger",
        label: "Alerta crítica"
    }
]

module.exports = {
    tipos_notificacion_alertas
}