import React, { useEffect, useRef, useState } from 'react'
import Map, { FullscreenControl, GeolocateControl, Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import { cerrarSesion } from '../../redux/actions/sesion'
import { useDispatch, useSelector } from 'react-redux'
import Structure from '../Structure'
import Title from 'antd/es/typography/Title'
import { Avatar, Button, Card, Col, DatePicker, Form, Popover, Row, Spin, Tabs, TimePicker, Tooltip, message } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import { AntDesignOutlined, CarFilled, CaretLeftOutlined, CaretRightOutlined, ForwardOutlined, MobileOutlined, SearchOutlined, StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import { BsBoxArrowInUpRight } from "react-icons/bs"
import { ArrowUpOutlined, CarOutlined, ClockCircleOutlined, GatewayOutlined, HeatMapOutlined, IdcardOutlined, NotificationOutlined, PlusCircleFilled } from '@ant-design/icons'
import { mapbox_token, timezone, urlapi } from '../../lib/backend/data'
import { rutas } from '../../lib/routes/routes'
import * as turf from '@turf/turf';
import Moment from 'react-moment';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import 'dayjs/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';
import { Link } from 'react-router-dom';
import ListadoConductoresRastreo from './listado';
import dayjs from 'dayjs';
import { formatDateHoy, obtenerRangos } from '../../lib/helpers/dates';
import { fechaUTCATexto } from '../../lib/helpers/helpers';
import { estilo_moving_truck } from '../../lib/estilo_sitio';
import { BsStopCircleFill } from 'react-icons/bs'
import { DateTime } from 'luxon';
import ButtonGroup from 'antd/es/button/button-group';
import { verificarMismoRadio } from '../../lib/helpers/maps/geocercas';
import { GiClick } from 'react-icons/gi'

mapboxgl.accessToken = mapbox_token

const AuditoriaConductor = (props) => {
    const sesion = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const [ loading, setLoading] = useState(true)
    const [messageApi, contextHolder] = message.useMessage();
    const ayer = DateTime.now().setZone(timezone).minus({ day: 1 }).toJSDate().toISOString()
    const [ contadores, setContadores] = useState({})
    const [ conductorSeleccionado, setConductorSeleccionado ] = useState(false)
    const [ loadingCoordenadas, setLoadingCoordenadas ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ horaConsulta, setHoraConsulta ] = useState(false)
    const [ fechaConsulta, setFechaConsulta ] = useState(formatDateHoy(ayer))
    const [ coordenadas, setCoordenadas ] = useState([])
    const { data, tokenSession } = useSelector(state => state.miusuario)
    const [ saltarCoordenadas, setSaltarCoordenadas] = useState(0)
    const [ mensajeDetenido, setMensajeDetenido] = useState(false)
    const empresa = data.empresa
    const [ marcadores, setMarcadores ] = useState([])
    const initialViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(initialViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()
    const controls = []

    const handleGeocoderResult = (event) => {
        if (event.result && event.result.geometry && event.result.geometry.coordinates) {
          const [lng, lat] = event.result.geometry.coordinates;
          setViewState({ ...viewState, longitude: lng, latitude: lat, zoom: 12 });
        }
      };

    const geocodificador = () => {
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          marker: false, // Desactivar el marcador de ubicación seleccionada
          placeholder: "Busca una dirección",
          countries: pais
        });
        geocoder.on('result', handleGeocoderResult);
        setTimeout(() => {
          if(controls.length < 1) mapRef.current?.addControl(geocoder, "top-right")
          controls.push('geocoder')
        }, 200);
      }

      const handleLoadMap = () => {
        geocodificador()
      }

      const showButtonWithToolTip = (showTooltip) => {
        const button = <Button size="medium" loading={loadingCoordenadas} onClick={() => obtenerInformacionCoordenadas()} type="primary">{saltarCoordenadas ? <CaretRightOutlined /> : <div><CaretRightOutlined /> OBTENER DATOS</div> }</Button>

        if(showTooltip){
            return <Tooltip title="Siguiente segmento">{button}</Tooltip>
        }
        return button
      }

      const mostrarSeleccionado = () => {
        if(!conductorSeleccionado) return false

        return <Card size="small" style={{ marginTop: 20, marginBottom: 20 }} >
            <Row>
                <Col md={20} xs={18}>
                    <Paragraph className="mb-0">Conductor seleccionado</Paragraph>
                    <Title level={4} className="mb-0 mt-0">{conductorSeleccionado.nombres} {conductorSeleccionado.apellido_p} {conductorSeleccionado.apellido_m} <Link target="_blank" to={`${rutas.drivers.slug}/${conductorSeleccionado._id}`}><BsBoxArrowInUpRight /></Link></Title>
                    <Paragraph className="mb-0"><MobileOutlined /> {conductorSeleccionado.phone}</Paragraph>
                    <Button onClick={() => setConductorSeleccionado(false)}>REMOVER</Button>
                </Col>
                <Col md={4} xs={6} style={{ textAlign: "right" }}>
                <Avatar
                    src={conductorSeleccionado.imagen_perfil}
                    size={{ xs: 80, sm: 70, md: 30, lg: 64, xl: 80, xxl: 100 }}
                    icon={<AntDesignOutlined />}
                />
                </Col>
                <Col md={24}>
                    
                <Form layout="vertical" style={{ marginTop: 20 }}>
                            <Row>
                                <Col md={6}>
                                    <Form.Item label="Fecha de revisión" >
                                    <DatePicker format="YYYY-MM-DD" locale={locale} defaultValue={dayjs(fechaConsulta, 'YYYY-MM-DD')} onChange={handleChangeDate} />
                                    </Form.Item>
                                </Col>
                                <Col md={6}>
                                    <Form.Item label="Hora de inicio" >
                                    <TimePicker changeOnBlur placeholder='Hora desde ' minuteStep={5} locale={locale} defaultValue={horaConsulta ? dayjs(`${horaConsulta.hora}:${horaConsulta.minuto}`, "HH:mm") : '' } format="HH:mm" onChange={handleChangeHorario} />
                                    </Form.Item>
                                </Col>
                                <Col md={6}>
                                    <Form.Item label="Click para buscar" >
                                        <ButtonGroup>
                                    { saltarCoordenadas ? <Tooltip title="Volver al inicio"><Button size="medium" disabled={loadingCoordenadas} onClick={() => reiniciarReloj()} ><StepBackwardOutlined /></Button></Tooltip> : false}
                                    { saltarCoordenadas ? <Tooltip title="Segmento anterior"><Button size="medium" disabled={loadingCoordenadas} onClick={() => obtenerInformacionCoordenadas( Math.max(0, saltarCoordenadas - (250*2) ) )} ><CaretLeftOutlined /></Button></Tooltip> : false}
                                    { showButtonWithToolTip(saltarCoordenadas) }
                                        </ButtonGroup>
                                    </Form.Item>
                                </Col>
                            </Row>
                        
                    </Form>
                </Col>
            </Row>
        </Card>
    }

    const ajustarCentro = (markers) => {
        console.log({ markers })
        if(markers.length < 1) return false
        if(markers.length === 1){
            return centrarMapa({ latitude: markers[0].location.coordinates[1], longitude: markers[0].location.coordinates[0]  }, 2)
          }
        setZoomInit(true)
        const points = markers.map(marker => turf.point([marker.location.coordinates[0], marker.location.coordinates[1]]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 30 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current.fitBounds(bounds, options);
    }

    const estaEnMismoLugar = (datos) => {
        const filtrar_validas = datos.filter(d => {
            if(!d) return false
            if(typeof d !== "object") return false
            if(!d.location) return false
            if(typeof d.location !== "object") return false
            if(!d.location.coordinates) return false
            if(Array.isArray(d.location.coordinates) !== true) return false
            if(d.location.coordinates.length < 2) return false
            return true
        }).filter(d => d)
        if(filtrar_validas.length < 1) return false

        const formar = filtrar_validas.map(g => ({ latitude: g.location.coordinates[1], longitude: g.location.coordinates[0] }))
        const verificar_radio = verificarMismoRadio( formar[0], 0.2, formar.filter((g,i) => i) )
        return verificar_radio
    }
  
    const obtenerInformacionCoordenadas = async (skip)=>{
        const { desde, hasta } = obtenerRangos(fechaConsulta, fechaConsulta)
        const inicio    = DateTime.fromFormat(fechaConsulta, 'yyyy-MM-dd').startOf("day")
        const final     = DateTime.fromFormat(fechaConsulta, 'yyyy-MM-dd').endOf("day")
        let fecha = DateTime.fromISO(inicio)
        if(horaConsulta){

            fecha = fecha.startOf("day").plus({ hours: horaConsulta.hora, minute: horaConsulta.minuto, second: 0 })
        }

        const condicion_fechas = {
            createdAt: { $gte: fecha.toUTC().toISO(), $lte: final.toUTC().toISO() }
        }
        
        if(!conductorSeleccionado) return messageApi.error("Selecciona un conductor ")
        setLoadingCoordenadas(true)
        return fetch(`${urlapi}/conductores/buscar/coordenadas`,{
            method:'POST',
            body: JSON.stringify({
                id_driver: conductorSeleccionado._id,
                skip: typeof skip !== "undefined" ? skip : saltarCoordenadas,
                condicion: condicion_fechas
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res.coordenadas)){
                if(res.coordenadas.length < 1) messageApi.info("Sin datos encontrados en este rango horario")
                const antes = typeof skip !== "undefined" ? skip : saltarCoordenadas
                const sumar = antes + res.coordenadas.length
                setSaltarCoordenadas(sumar)

                const no_mapear = estaEnMismoLugar(res.coordenadas)
                setMensajeDetenido(no_mapear === true ? `Desde ${fechaUTCATexto(res.coordenadas[0].date)} a ${fechaUTCATexto(res.coordenadas[ (res.coordenadas.length - 1 ) ].date)}` : false)
                if(no_mapear === true){
                    const nuevo_array = [ res.coordenadas[ (res.coordenadas.length - 1 ) ] ]
                    setCoordenadas(prev => [...[], ...nuevo_array])
                } else {
                    setCoordenadas(prev => [...[], ...res.coordenadas])
                }
            }
            return setLoadingCoordenadas(false)
        })
        .catch(error => {
            messageApi.error("Ocurrió un problema al cargar la información")
            return setLoadingCoordenadas(false)
        })
    }

    const consultarContador = async ()=>{
        return fetch(`${urlapi}/conductores/counter/actives`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.data){
                setContadores(res.data)
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Ocurrió un problema al cargar la información")
            return setLoading(false)
        })
    }
    

    useEffect(() => {

        ajustarCentro(coordenadas)
    }, [ coordenadas ])

    const seleccionarConductor = (driver) => {
        setSaltarCoordenadas(0)
        setConductorSeleccionado(driver)
        setCoordenadas([...[], ...[]])
        var divElement = document.getElementById("box_conductores");
        divElement.scrollTo({
            top: 0,
            behavior: "smooth" // Agrega una animación suave
        });
    }


    const mostrarInfoVehiculo = (veh) => {
        if(!veh) return false
        if(typeof veh !== "object") return false
        let modelo      = ''
        let marca       = ''

        if(typeof veh.modelo === "object") if(veh.modelo.titulo) modelo = veh.modelo.titulo
        if(typeof veh.marca === "object") if(veh.marca.titulo) marca = veh.marca.titulo

        return <div>
            <Title className='mt-0 mb-0' level={5} style={{ fontSize: 13 }}>VEHÍCULO {marca} {modelo} {veh.patente} <a target='_blank' href={`${rutas.vehicles.slug}/${veh._id}`}><BsBoxArrowInUpRight /></a></Title>
        </div>
    }

    const puntoFinal = (valor) => {
        if((valor+1) === coordenadas.length) return <BsStopCircleFill size={25} />
    }
        
    const centrarMapa = (centro, max_zoom) => {
        console.log({ centro, max_zoom })
        const nuevo_zoom = initialViewState.zoom + (max_zoom ? max_zoom : 3)
        mapRef.current?.flyTo({center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000});
        setTimeout(() => {
          setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
        }, 2010);
      }

      const reiniciarReloj = () => {
        setSaltarCoordenadas(0)
        obtenerInformacionCoordenadas(0)
      }

      const handleChangeHorario = (e) => {
        if(!e){
            setHoraConsulta(false)
            return setSaltarCoordenadas(0)
        }
        const setear = {
            hora: e['$H'],
            minuto: e['$m']
        }
        setHoraConsulta(setear)
        setSaltarCoordenadas(0)
      }

      const handleChangeDate = (e) => {
        let valor = ''
        if(e) valor = formatDateHoy(e)
        setFechaConsulta(valor)
        setSaltarCoordenadas(0)
      }

      const lines = coordenadas.map((point) => point.location.coordinates);
    const render = () => {
        
        return <div style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}>
            
            <Row gutter={0} style={{ height: "100%" }} >
            <Col md={12} xs={24} className='mb-3' >

            <Map
                ref={mapRef}
                {...viewState}
                onMove={evt => setViewState(evt.viewState)}
                style={{width: "100%", height: "100vh" }}
                pitch={0}
                mapStyle="mapbox://styles/mapbox/light-v9"
                mapboxAccessToken={mapbox_token}
                onLoad={handleLoadMap}
            >
                <GeolocateControl position="top-left" />
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
               <Source type="geojson" data={{ type: 'Feature', geometry: { type: 'LineString', coordinates: lines } }}>
                <Layer
                id="lineLayer"
                type="line"
                source="my-data"
                layout={{
                    'line-join': 'round',
                    'line-cap': 'round',
                }}
                paint={{
                    'line-color': estilo_moving_truck.colors.primary,
                    'line-width': 4,
                }}
                />
            </Source>
                {
                    coordenadas.map((marker,pos) => {

                        const content = (
                            <div>
                                {mensajeDetenido ? mensajeDetenido : fechaUTCATexto(marker.createdAt)}
                                {mostrarInfoVehiculo(marker.vehiculo)}
                                <a href={`https://www.google.com/maps?q=${marker.location.coordinates[1]},${marker.location.coordinates[0]}`} target='_blank'><BsBoxArrowInUpRight /> VER EN GOOGLE</a>
                            </div>
                        )
                        return <Marker
                                key={marker._id}
                                longitude={marker.location.coordinates[0]}
                                latitude={marker.location.coordinates[1]}
                            >
                        <Popover content={content} title={"Sin movimiento detectado"}>
                            <div style={{ minHeight:15, minWidth: 15 }}>{puntoFinal(pos)}</div>
                        </Popover>
                        </Marker>
                    })
                }
            </Map>
           
            </Col>
            
            <Col md={12} id="box_conductores" className='mb-3' style={{ height: "100%", overflowY: "scroll", overflowX: "hidden" }}>
            <div style={{ paddingLeft: 20, paddingTop:10, paddingRight: 20 }} className='mt-3'>
            <Title level={2} className='m-0'>Auditoría</Title>
            <Title level={3} className='m-0'>Conductores</Title>
                    <Paragraph className='mb-0'>Aquí podrás ver los conductores de tu flota y el estado de conexión</Paragraph>
                    <Title level={3} className='mt-0 mb-0' style={{ color: estilo_moving_truck.colors.primary }}><GiClick /> Toca al conductor para auditarlo</Title>
                    {mostrarSeleccionado()}
                    <ListadoConductoresRastreo tituloHoverDriver="Toca para seleccionar y auditar" onSelect={(driver) => seleccionarConductor(driver)} tableSize="small" typeView="funcion" hideExporter={true} condicion_default={{}} hideHeader={true}  />
            </div>
            </Col>
            </Row>
            {contextHolder}
        </div>
    }

    return <Structure component={render()} />

}

export default AuditoriaConductor