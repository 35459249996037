import React, { useEffect, useRef, useState } from 'react'
import Map, { FullscreenControl, GeolocateControl, Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import { cerrarSesion } from '../../../redux/actions/sesion'
import { useDispatch, useSelector } from 'react-redux'
import Structure from '../../Structure'
import Title from 'antd/es/typography/Title'
import { Avatar, Button, Card, Col, DatePicker, Drawer, Form, Popover, Row, Space, Spin, Tabs, Tag, TimePicker, Tooltip, message } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import { AntDesignOutlined, CarFilled, MobileOutlined, SearchOutlined } from '@ant-design/icons';
import { BsBoxArrowInUpRight } from "react-icons/bs"
import { mapbox_token, urlapi } from '../../../lib/backend/data'
import { rutas } from '../../../lib/routes/routes'
import * as turf from '@turf/turf';
import Moment from 'react-moment';
import { obtenerCentroMapaPorPais } from '../../../lib/helpers/data/internationa'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import 'dayjs/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';
import { Link } from 'react-router-dom';
import ListadoConductoresRastreo from './listado';
import dayjs from 'dayjs';
import { formatDateHoy, obtenerRangos } from '../../../lib/helpers/dates';
import { duracionTiempoString, fechaUTCATexto, fechaUTCATiempo } from '../../../lib/helpers/helpers';
import { estilo_moving_truck } from '../../../lib/estilo_sitio';
import { BsStopCircleFill } from 'react-icons/bs'
import { DateTime } from 'luxon';
import ButtonGroup from 'antd/es/button/button-group';
import { verificarMismoRadio } from '../../../lib/helpers/maps/geocercas';
import ListadoVehiculosRastreo from './listado_vehiculos';

mapboxgl.accessToken = mapbox_token

const EstadisticaTrackingConductor = (props) => {
    const sesion = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const [ loading, setLoading] = useState(true)
    const [messageApi, contextHolder] = message.useMessage();
    const [ contadores, setContadores] = useState({})
    const [ conductorSeleccionado, setConductorSeleccionado ] = useState(false)
    const [ loadingCoordenadas, setLoadingCoordenadas ] = useState(false)
    const [ inicio, setInicio ] = useState(null)
    const [ termino, setTermino ] = useState(null)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ horaConsulta, setHoraConsulta ] = useState(false)
    const [open, setOpen] = useState(true)
    const [ paradas, setParadas ] = useState([])
    const [ fechaConsulta, setFechaConsulta ] = useState(formatDateHoy(new Date()))
    const [ coordenadas, setCoordenadas ] = useState([])
    const [ poligonos, setPoligonos ] = useState([])
    const { data, tokenSession } = useSelector(state => state.miusuario)
    const [ saltarCoordenadas, setSaltarCoordenadas] = useState(0)
    const [ mensajeDetenido, setMensajeDetenido] = useState(false)
    const empresa = data.empresa
    const [ marcadores, setMarcadores ] = useState([])
    const initialViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(initialViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()
    const controls = []

    const showDrawer = () => {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
    }

    const handleGeocoderResult = (event) => {
        if (event.result && event.result.geometry && event.result.geometry.coordinates) {
          const [lng, lat] = event.result.geometry.coordinates;
          setViewState({ ...viewState, longitude: lng, latitude: lat, zoom: 12 });
        }
      };

    const geocodificador = () => {
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          marker: false, // Desactivar el marcador de ubicación seleccionada
          placeholder: "Busca una dirección",
          countries: pais
        });
        geocoder.on('result', handleGeocoderResult);
        setTimeout(() => {
          if(controls.length < 1) mapRef.current?.addControl(geocoder, "top-right")
          controls.push('geocoder')
        }, 200);
      }

      const handleLoadMap = () => {
        geocodificador()
      }

      const mostrarSeleccionado = () => {
        if(!conductorSeleccionado) return false

        return <Card size="small" style={{ marginTop: 20 }} >
            <Row>
                <Col md={20} xs={18}>
                    <Paragraph className="mb-0">Conductor seleccionado</Paragraph>
                    <Title level={4} className="mb-0 mt-0">{conductorSeleccionado.nombres} {conductorSeleccionado.apellido_p} {conductorSeleccionado.apellido_m} <Link target="_blank" to={`${rutas.drivers.slug}/${conductorSeleccionado._id}`}><BsBoxArrowInUpRight /></Link></Title>
                    <Paragraph className="mb-0"><MobileOutlined /> {conductorSeleccionado.phone}</Paragraph>
                    <Button onClick={() => setConductorSeleccionado(false)}>REMOVER</Button>
                </Col>
                <Col md={4} xs={6} style={{ textAlign: "right" }}>
                <Avatar
                    src={conductorSeleccionado.imagen_perfil}
                    size={{ xs: 80, sm: 70, md: 30, lg: 64, xl: 80, xxl: 100 }}
                    icon={<AntDesignOutlined />}
                />
                </Col>
            </Row>
        </Card>
    }

    const ajustarCentro = (markers) => {
        console.log(markers)
        if(markers.length < 1) return false
        if(markers.length === 1){
            return centrarMapa({ latitude: markers.coordinates[1], longitude: markers.coordinates[0] }, 2)
          }
        setZoomInit(true)
        const points = markers.map(marker => turf.point([marker[0], marker[1]]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: { top: 130, bottom: 130, left: 50 } // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current.fitBounds(bounds, options);
    }

    const estaEnMismoLugar = (datos) => {
        const filtrar_validas = datos.filter(d => {
            if(!d) return false
            if(typeof d !== "object") return false
            if(!d.location) return false
            if(typeof d.location !== "object") return false
            if(!d.location.coordinates) return false
            if(Array.isArray(d.location.coordinates) !== true) return false
            if(d.location.coordinates.length < 2) return false
            return true
        }).filter(d => d)
        if(filtrar_validas.length < 1) return false

        const formar = filtrar_validas.map(g => ({ latitude: g.location.coordinates[1], longitude: g.location.coordinates[0] }))
        const verificar_radio = verificarMismoRadio( formar[0], 0.2, formar.filter((g,i) => i) )
        return verificar_radio
    }
  
    const obtenerInformacionCoordenadas = async (skip)=>{
        const { desde, hasta } = obtenerRangos(fechaConsulta, fechaConsulta)
        const inicio    = DateTime.fromFormat(fechaConsulta, 'yyyy-MM-dd').startOf("day")
        const final     = DateTime.fromFormat(fechaConsulta, 'yyyy-MM-dd').endOf("day")
        let fecha = DateTime.fromISO(inicio)
        if(horaConsulta){

            fecha = fecha.startOf("day").plus({ hours: horaConsulta.hora, minute: horaConsulta.minuto, second: 0 })
        }

        const condicion_fechas = {
            createdAt: { $gte: fecha.toUTC().toISO(), $lte: final.toUTC().toISO() }
        }
        
        /*
        */
        if(!conductorSeleccionado) return messageApi.error("Selecciona un conductor ")
        setLoadingCoordenadas(true)
        return fetch(`${urlapi}/conductores/buscar/coordenadas`,{
            method:'POST',
            body: JSON.stringify({
                id_driver: conductorSeleccionado._id,
                skip: typeof skip !== "undefined" ? skip : saltarCoordenadas,
                condicion: condicion_fechas
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res.coordenadas)){
                if(res.coordenadas.length < 1) messageApi.info("Sin datos encontrados en este rango horario")
                const antes = typeof skip !== "undefined" ? skip : saltarCoordenadas
                const sumar = antes + res.coordenadas.length
                setSaltarCoordenadas(sumar)

                const no_mapear = estaEnMismoLugar(res.coordenadas)
                setMensajeDetenido(no_mapear === true ? `Detenido desde ${fechaUTCATexto(res.coordenadas[0].date)} a ${fechaUTCATexto(res.coordenadas[ (res.coordenadas.length - 1 ) ].date)}` : false)
                if(no_mapear === true){
                    const nuevo_array = [ res.coordenadas[ (res.coordenadas.length - 1 ) ] ]
                    setCoordenadas(prev => [...[], ...nuevo_array])
                } else {
                    setCoordenadas(prev => [...[], ...res.coordenadas])
                }
            }
            return setLoadingCoordenadas(false)
        })
        .catch(error => {
            messageApi.error("Ocurrió un problema al cargar la información")
            return setLoadingCoordenadas(false)
        })
    }

    const consultarContador = async ()=>{
        return fetch(`${urlapi}/conductores/counter/actives`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.data){
                setContadores(res.data)
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Ocurrió un problema al cargar la información")
            return setLoading(false)
        })
    }
    

    useEffect(() => {
        let coordinates = []

        poligonos.map(pol => {
            if(!pol.geometry) return false
            if(typeof pol.geometry !== "object") return false
            if(!pol.geometry.coordinates) return false
            if(Array.isArray(pol.geometry.coordinates) !== true) return false
            coordinates = [...coordinates, ...pol.geometry.coordinates]
        })
        console.log({ coordinates })
        if(coordinates.length > 0){
            setInicio(coordinates[0])
            setTermino(coordinates[coordinates.length - 1])
        } else {
            setInicio(false)
            setTermino(false)
        }
        ajustarCentro(coordinates)
    }, [ poligonos ])

    const seleccionarConductor = (driver) => {
        if(!driver){
            setParadas([...[], ...[]])
            return setPoligonos([...[], ...[]])
        }
        if(!driver) return false
        if(typeof driver !== "object") return false
        if(!driver.informe) return false
        if(typeof driver.informe !== "object") return false
        
        let stops = []
        if(driver.informe.paradas){
            if( Array.isArray(driver.informe.paradas) !== false ){
                
                driver.informe.paradas.map(step => {
                    if(step.coordenada_inicial){
                        if(typeof step.coordenada_inicial === "object"){
                            if(step.coordenada_inicial.location){
                                if(typeof step.coordenada_inicial.location === "object" ){
                                    if(step.coordenada_inicial.location.coordinates){
                                        if( Array.isArray(step.coordenada_inicial.location.coordinates) !== false){
                                            if(step.coordenada_inicial.location.coordinates.length > 1){
                                                const validas = step.coordenada_inicial.location.coordinates.filter(e => e)
                                                if(validas.length < 2) return false
                                                stops.push({
                                                    desde: step.coordenada_inicial.createdAt,
                                                    hasta: step.coordenada_final.createdAt,
                                                    coordinates: step.coordenada_inicial.location.coordinates
                                                })
                                            }
                                        }
                                    }
                                }   
                            }
                        }
                    }
                })

            }
        }

        setParadas(stops)
        
        if(driver.informe.poligonos){
            if( Array.isArray(driver.informe.poligonos) !== false ){
                setPoligonos(driver.informe.poligonos)
            }
        }
        
        return
    }


    const mostrarInfoVehiculo = (veh) => {
        if(!veh) return false
        if(typeof veh !== "object") return false
        let modelo      = ''
        let marca       = ''

        if(typeof veh.modelo === "object") if(veh.modelo.titulo) modelo = veh.modelo.titulo
        if(typeof veh.marca === "object") if(veh.marca.titulo) marca = veh.marca.titulo

        return <div>
            <Title className='mt-0 mb-0' level={5} style={{ fontSize: 13 }}>{marca} {modelo} {veh.patente} <a target='_blank' href={`${rutas.vehicles.slug}/${veh._id}`}><BsBoxArrowInUpRight /></a></Title>
        </div>
    }

    const puntoFinal = (valor) => {
        if((valor+1) === coordenadas.length) return <BsStopCircleFill size={25} />
    }
        
    const centrarMapa = (centro, max_zoom) => {
        const nuevo_zoom = initialViewState.zoom + (max_zoom ? max_zoom : 3)
        mapRef.current?.flyTo({center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000});
        setTimeout(() => {
          setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
        }, 2010);
      }

      const reiniciarReloj = () => {
        setSaltarCoordenadas(0)
        obtenerInformacionCoordenadas(0)
      }

      const handleChangeHorario = (e) => {
        if(!e){
            setHoraConsulta(false)
            return setSaltarCoordenadas(0)
        }
        const setear = {
            hora: e['$H'],
            minuto: e['$m']
        }
        setHoraConsulta(setear)
        setSaltarCoordenadas(0)
      }

      const handleChangeDate = (e) => {
        let valor = ''
        if(e) valor = formatDateHoy(e)
        setFechaConsulta(valor)
        setSaltarCoordenadas(0)
      }

      const mostrarMarcador = (point,title) => {
        if(!point) return false
        if(!Array.isArray(point)) return false
        if(point.length < 2) return false
        return <Marker
            key={point[0]}
            longitude={point[0]}
            latitude={point[1]}
        ><Tag color='black' >{title}</Tag></Marker>
      }

      const lines = coordenadas.map((point) => point.location.coordinates);
    const render = () => {
        
        return <div style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}>
            

            <Row gutter={0} style={{ height: "100%" }} >
            <Col md={24} xs={24} className='mb-3' >

            <Map
                ref={mapRef}
                {...viewState}
                onMove={evt => setViewState(evt.viewState)}
                style={{width: "100%", height: "100vh" }}
                pitch={0}
                mapStyle="mapbox://styles/mapbox/light-v9"
                mapboxAccessToken={mapbox_token}
                onLoad={handleLoadMap}
            >
                <GeolocateControl position="top-left" />
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
                <div style={{ position: "absolute", top: 200, left: 10 }}>
                    <div className='mb-3'>
                    <ListadoConductoresRastreo fecha={fechaConsulta} tituloHoverDriver="Toca para seleccionar y auditar" onSelect={(driver) => seleccionarConductor(driver)} tableSize="small" typeView="funcion" hideExporter={true} condicion_default={{}} hideHeader={true}  />
                    </div>

                    <ListadoVehiculosRastreo fecha={fechaConsulta} tituloHoverDriver="Toca para seleccionar y auditar" onSelect={(driver) => seleccionarConductor(driver)} tableSize="small" typeView="funcion" hideExporter={true} condicion_default={{}} hideHeader={true}  />

                    </div>
                {
                    paradas.map((marker,pos) => {
                        const inicio = DateTime.fromISO(marker.desde)
                        const fin = DateTime.fromISO(marker.hasta)
                        let diferencia = "Parada"
                        const diferencia_minutos = fin.diff(inicio, "minutes")
                        if(diferencia_minutos.values){
                            if(typeof diferencia_minutos.values === "object"){
                                if(diferencia_minutos.values.minutes) diferencia = `Parada de ${duracionTiempoString(diferencia_minutos.values.minutes)}`
                            }
                        }

                        const content = (<div>
                            <Paragraph className='mb-0'>{`Desde ${fechaUTCATiempo(marker.desde)}`}</Paragraph>
                            <Paragraph>{`Hasta ${fechaUTCATiempo(marker.hasta)}`}</Paragraph>
                            <a href={`https://www.google.com/maps?q=${marker.coordinates[1]},${marker.coordinates[0]}`} target='_blank'><BsBoxArrowInUpRight /> VER EN GOOGLE</a>
                        </div>)
                        return <Marker
                                key={marker._id}
                                longitude={marker.coordinates[0]}
                                latitude={marker.coordinates[1]}
                            >
                        <Popover content={content} title={diferencia}>
                            <BsStopCircleFill size={25} />
                        </Popover>
                        </Marker>
                    })
                }
                {mostrarMarcador(inicio, "Inicio")}
                {mostrarMarcador(termino, "Final")}
               {
                    poligonos.map( (poligono,i) => {
                        return <Source key={`pol-${i}`} type="geojson" data={poligono}>
                                <Layer
                                id={`pol-${i}`}
                                type="line"
                                source="my-data"
                                layout={{
                                    'line-join': 'round',
                                    'line-cap': 'round',
                                }}
                                paint={{
                                    'line-color': estilo_moving_truck.colors.primary,
                                    'line-width': 4,
                                }}
                                />
                            </Source>
                    })
               }
            </Map>
            {/* <Drawer title="VER LISTADO" onClose={onClose} open={open} size="large" extra={
            <Space>
                <Button disabled={loading} onClick={onClose}>CANCELAR</Button>
                <Button loading={loading} onClick={() => crearRegistro()} type="primary"  >GUARDAR</Button>
            </Space>
            } >
                <div style={{ paddingLeft: 20, paddingTop:10, paddingRight: 20 }} className='mt-3'>
                <Title level={2} className='m-0'>Informe de rastreo</Title>
                <Title level={3} className='m-0'>Conductores</Title>
                        <Paragraph>Aquí podrás ver los conductores de tu flota y el estado de conexión</Paragraph>
                </div>
            </Drawer> */}

            </Col>
            
            </Row>
            {contextHolder}
        </div>
    }

    return <Structure component={render()} />

}

export default EstadisticaTrackingConductor