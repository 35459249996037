import { Breadcrumb, Button, Card, Col, Divider, Form, Input, List, Row, Select, Switch, message } from "antd";
import Structure from "../../../Structure";
import MapaDibujoPoligono from "./MapaDibujoPoligono";
import { useEffect, useState } from "react";
import { siEsRequeridoEnEsquema, validateEmail } from "../../../../lib/helpers/helpers";
import { DeleteOutlined, HomeOutlined } from "@ant-design/icons";
import { rutas } from "../../../../lib/routes/routes";
import Title from "antd/es/typography/Title";
import { urlapi } from "../../../../lib/backend/data";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../../../redux/actions/sesion";
import Paragraph from "antd/es/typography/Paragraph";
import ModalSeleccionVehiculos from "../../../Conductores/modal_seleccion";
import ModalSeleccionTiposVehiculos from "../../../../subComponents/vehiculos/tipos/modal_seleccion_tipo";
import { Link } from "react-router-dom";
import { BsBoxArrowInUpRight } from "react-icons/bs";
import MapaDibujoPoligonoAvanzado from "../mapa_dibujo_poligono";
import CamposObligatoriosLeyenda from "../../../../subComponents/general/formularios/campos_obligatorios";
import { AiFillMinusCircle, AiOutlineMail } from "react-icons/ai";
import { GoDeviceMobile } from "react-icons/go";
import { codigos_area } from "../../../../lib/internacional";
import { GrAdd } from "react-icons/gr";
import { tipo_zonas } from "../../../Zonas/data";
import ModalSeleccionVehiculo from "../../../Vehiculos/modal_seleccion";

  const MantenedorGeocerca = () => {
    const [ geocerca, setGeocerca ] = useState({ tipos_vehiculo: [], conductores: [], vehiculos: [] })
    const [ geojson, setGeoJson] = useState(false)
    const [ loading, setLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const pais = useSelector(state => state.pais)
    const [ geometrias, setGeometrias] = useState([])
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    const requeridos = [
      { value: "titulo", label: "Título" },
      { value: "tipo", label: "Tipo de geocerca" },
      { value: "tipo_rastreo", label: "Tipo de rastreo" },
    ]

    const handleChange = (e) => {
      const { name, value } = e.target
      geocerca[name] = value
      return setGeocerca({...{}, ...geocerca})
    }

    const handleChangeSelect = (e,name) => {
      geocerca[name] = e
      return setGeocerca({...{}, ...geocerca})
    }

    const crearGeocerca = async () => {
      let faltantes = []
      requeridos.map(campo => {
          if(!geocerca[campo.value]) faltantes.push(campo.label)
          return true
      })
      if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
      if(!geojson) return messageApi.error(`Dibuja al menos una zona en el mapa con la herramienta de polígono`)  

      const instancia = JSON.parse( JSON.stringify( geocerca ))
      instancia.geocercas = geojson.features
      instancia.geometrias = geometrias
      setLoading(true)
      const url = `${urlapi}/geodata/zonas/empresa`
      return fetch(url, {
          method: "POST",
          body: JSON.stringify(instancia),
          headers: {
              'Content-type': "application/json",
              'Authorization': `Bearer: ${session.tokenSession}`,
              'country': pais,
              'lang': idioma
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.json()
      })
      .then(async res => {
          if(!res){
              messageApi.error("Sin datos obtenidos")
          } else if(res.errorMessage){
              messageApi.error(res.errorMessage)
          } else if(res._id){
              messageApi.success("Creado exitosamente")
              setTimeout(() => {
                  return window.location = `${rutas.zones.slug}/${res._id}`
              }, 1000);
          }
          return setLoading(false)
      })
      .catch(error => {
          messageApi.error("No se pudo efectuar la operación")
          return setLoading(false)
      })

    }

    const agregarVehiculo = (driver) => {

      if(Array.isArray(driver) !== false){
        
        return setGeocerca(prev => {
          let actual = {...prev}
          if(!actual.vehiculos) actual.vehiculos = []
          for( const conductor_elegido of driver ){
            const i = actual.vehiculos.findIndex(c => c._id === conductor_elegido._id)
            if(i > -1) continue
            actual.vehiculos.unshift(conductor_elegido)
          }
          return actual
        })

      } else {
        return setGeocerca(prev => {
          let actual = {...prev}
          if(!actual.vehiculos) actual.vehiculos = []
          const i = actual.vehiculos.findIndex(c => c._id === driver._id)
          if(i > -1) {
            messageApi.info("Ya agregaste este registro")
          } else {
            actual.vehiculos.unshift(driver)
          }
          return actual
        })
      }

    }

    const agregarConductor = (driver) => {
      if(!geocerca.conductores) geocerca.conductores = []

      if(Array.isArray(driver) !== false){

        for( const conductor_elegido of driver ){
          const i = geocerca.conductores.findIndex(c => c._id === conductor_elegido._id)
          if(i > -1) continue
          geocerca.conductores.unshift(conductor_elegido)
        }

      } else {
        const i = geocerca.conductores.findIndex(c => c._id === driver._id)
        if(i > -1) return messageApi.info("Ya agregaste este registro")
        geocerca.conductores.unshift(driver)
    }
    
      return setGeocerca({...{}, ...geocerca })
    }

    const agregarTipoVehiculos = (registros) => {
      if(!geocerca.tipos_vehiculo) geocerca.tipos_vehiculo = []

      for( const driver of registros ){
        const i = geocerca.tipos_vehiculo.findIndex(c => c._id === driver._id)
        if(i > -1) continue
        geocerca.tipos_vehiculo.unshift(driver)
      }
      return setGeocerca({...{}, ...geocerca })
    }

    const removerItem = (pos, name) => {
      geocerca[name].splice(pos,1)
      return setGeocerca({...{}, ...geocerca})
    }

    const mostrarTiposVehiculo = () => {
      if(!geocerca.tipos_vehiculo) return false
      if(Array.isArray(geocerca.tipos_vehiculo) !== true ) return false
      return <div style={{ marginTop: 10 }}>
        <List
          header={false}
          footer={false}
          bordered
          dataSource={geocerca.tipos_vehiculo}
          renderItem={(driver,i) => (
            <List.Item>
              <Paragraph className="mb-0 mt-0" style={{ fontSize: 12 }}>{driver.tipo}</Paragraph>
              <Button type="dashed" size="small" style={{ fontSize: 12 }} icon={<DeleteOutlined />} onClick={() => removerItem(i,'tipos_vehiculo')} >REMOVER</Button>
            </List.Item>
          )}
        />
      </div>
    }

    const handleChangeMovil = (e, pos, name) => {
      let value = e
      if(typeof e === "object") if(e.target) value = e.target.value
      geocerca.moviles_adicionales[pos][name] = value
      return setGeocerca(prev => ({...{}, ...geocerca}))
  }

  const removerMovil = (i) => {
      geocerca.moviles_adicionales.splice(i,1)
      return setGeocerca(prev => ({...{}, ...geocerca})) 
  }
  
  const anadirMovilAdicional = () => {
      if(!geocerca.moviles_adicionales) geocerca.moviles_adicionales = []
      geocerca.moviles_adicionales.unshift({
          pais_codigo: '',
          movil: ''
      })
      return setGeocerca(prev => ({...{}, ...geocerca}))
  }

  const anadirEmailAdicional = () => {
      if(!geocerca.emails_adicionales) geocerca.emails_adicionales = []
      geocerca.emails_adicionales.unshift({
          email: ''
      })
      return setGeocerca(prev => ({...{}, ...geocerca}))
  }

  const removerEmail = (i) => {
      geocerca.emails_adicionales.splice(i,1)
      return setGeocerca(prev => ({...{}, ...geocerca})) 
  }

  const handleChangeEmail = (e) => {
      const { name, value } = e.target
      const pos = parseInt(e.target.getAttribute('pos'))
      geocerca.emails_adicionales[pos][name] = value
      geocerca.emails_adicionales[pos].valid = validateEmail(value)
      return setGeocerca(prev => ({...{}, ...geocerca}))
  }

  const mostrarEmailsAdicionales = () => {
      if(!geocerca.emails_adicionales) return false
      return <div>
          {
              geocerca.emails_adicionales.map((field,i) => {
                  return <div key={`email-${i}`} className="mt-3">
                      <Card size="small" title="Email" className="mb-3" extra={<Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>} >
                              <Form.Item label="" required={true} className="mb-0">
                              <Input placeholder="Escribe aquí el email" prefix={<AiOutlineMail />} className='mb-0' status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} />
                              </Form.Item>
                      </Card>
                  </div>
              })
          }
      </div>
  }

  const mostrarMovilesAdicionales = () => {

      const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

      if(!geocerca.moviles_adicionales) return false
      return <div>
          {
              geocerca.moviles_adicionales.map((field,i) => {
                  return <div key={`movil-${i}`} className="mt-3">
                  <Card size="small" title="Móvil" className="mb-3" extra={<Button type="ghost" onClick={() => removerMovil(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />}>REMOVER</Button>} >
                      <Row gutter={15}>
                          <Col md={8}>
                              <Form.Item label="COD. Área" required={true} className="mb-0">
                              <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={field.pais_codigo} onChange={(e) => handleChangeMovil(e,i,"pais_codigo")} 
                                  filterOption={filterOption}
                                  options={codigos_area}
                              />
                              </Form.Item>
                          </Col>
                          <Col md={16}>
                              <Form.Item label="Número" required={true} className="mb-0">
                              <Input placeholder="Escribe aquí el número telefónico" className='form-control mb-3' name="movil" value={field.movil} onChange={(e) => handleChangeMovil(e,i,"movil")} />
                              </Form.Item>
                          </Col>
                      </Row>
                  </Card>
                  </div>
              })
          }
      </div>
  }

    const mostrarVehiculos = () => {
      if(!geocerca.vehiculos) return false
      if(Array.isArray(geocerca.vehiculos) !== true ) return false
      return <div style={{ marginTop: 10 }}>
        <List
          header={false}
          footer={false}
          bordered
          dataSource={geocerca.vehiculos}
          renderItem={(driver,i) => (
            <List.Item>
              <Paragraph className="mb-0 mt-0" style={{ fontSize: 12 }}>{driver.nombres} {driver.marca} {driver.modelo} {driver.patente} <Link target="_blank" to={`${rutas.vehicles.slug}/${driver._id}`}><BsBoxArrowInUpRight /></Link></Paragraph>
              <Button type="dashed" size="small" style={{ fontSize: 12 }} icon={<DeleteOutlined />} onClick={() => removerItem(i,'vehiculos')} >REMOVER</Button>
            </List.Item>
          )}
        />
      </div>
    }

    const mostrarConductores = () => {
      if(!geocerca.conductores) return false
      if(Array.isArray(geocerca.conductores) !== true ) return false
      return <div style={{ marginTop: 10 }}>
        <List
          header={false}
          footer={false}
          bordered
          dataSource={geocerca.conductores}
          renderItem={(driver,i) => (
            <List.Item>
              <Paragraph className="mb-0 mt-0" style={{ fontSize: 12 }}>{driver.nombres} {driver.apellido_p} {driver.apellido_m} <Link target="_blank" to={`${rutas.drivers.slug}/${driver._id}`}><BsBoxArrowInUpRight /></Link></Paragraph>
              <Button type="dashed" size="small" style={{ fontSize: 12 }} icon={<DeleteOutlined />} onClick={() => removerItem(i,'conductores')} >REMOVER</Button>
            </List.Item>
          )}
        />
      </div>
    }

    const obtenerIDS = (valores) => {
      if(!valores) return []
      if(Array.isArray(valores) !== true) return []
      const ids = valores.map(e => e._id).filter(e => e)
      return ids
    }

    const formularioGeocerca = () => {
      const tipos_vehiculos_seleccionados = obtenerIDS(geocerca.tipos_vehiculo)

      return <div>
        <Card className="mb-3">
         <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.zones.slug,
                    title: <span>Zonas de operación</span>,
                },
                {
                    title: 'Crear nueva zona',
                },
                ]}
            />
            <Title className="mb-2 mt-0">Crear zona</Title>
        </Card>
        <Form layout="vertical" style={{ padding: 20 }}>
        <Row gutter={10}>
                <Col md={24} xs={24}>
        <CamposObligatoriosLeyenda />
                  <Form.Item label="Título" required={siEsRequeridoEnEsquema('titulo',requeridos)} >
                    <Input mb={3} name="titulo" onChange={handleChange} />
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                <Form.Item label="Tipo de rastreo" required={siEsRequeridoEnEsquema('tipo_rastreo',requeridos)} >
                  <Select style={{ width: "100%" }} options={[{ value: "zona", label: "Zona" }, { value: "ruta", label: "Ruta"}]} onChange={(e) => handleChangeSelect(e,'tipo_rastreo')} />
                  </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                <Form.Item label="Tipo de zona" required={siEsRequeridoEnEsquema('tipo',requeridos)} >
                  <Select style={{ width: "100%" }} options={tipo_zonas} onChange={(e) => handleChangeSelect(e,'tipo')} />
                  </Form.Item>
                </Col>

                <Col md={24} xs={24}>
                <Form.Item label="Seleccionar tipos de vehículo específicos" required={siEsRequeridoEnEsquema('tipo_destino',requeridos)} >
                    <ModalSeleccionTiposVehiculos exclude={tipos_vehiculos_seleccionados} showSelection={true} hideSeleccionados={true} onSelect={(data) => agregarTipoVehiculos(data)} />
                    {mostrarTiposVehiculo()}
                </Form.Item>
                <Form.Item label="Seleccionar conductores específicos" required={siEsRequeridoEnEsquema('tipo_destino',requeridos)} >
                    <ModalSeleccionVehiculos hideSeleccionados={true} onSelect={(driver) => agregarConductor(driver)} />
                    {mostrarConductores()}
                </Form.Item>
                <Form.Item label="Seleccionar vehículos específicos" >
                    <ModalSeleccionVehiculo isMulti={true} hideSeleccionados={true} onSelect={(driver) => agregarVehiculo(driver)} />
                    {mostrarVehiculos()}
                </Form.Item>
                </Col>

                <Col md={24} xs={24}><Title level={4} className="mt-0">¿Cuando notificar?</Title></Col>
                <Col md={8} xs={24}>
                <Form.Item label="Notificar al entrar" required={siEsRequeridoEnEsquema('notificar_entrar',requeridos)} >
                <Switch
                    checked={geocerca.notificar_entrar ? true : false}
                    checkedChildren="SI"
                    unCheckedChildren="NO"
                    onChange={() => {
                      geocerca.notificar_entrar = geocerca.notificar_entrar === true ? false : true
                      console.log(geocerca)
                      return setGeocerca({...{}, ...geocerca})
                    }}
                    className="mb-3"
                    />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                <Form.Item label="Notificar al salir" required={siEsRequeridoEnEsquema('notificar_salir',requeridos)} >
                <Switch
                    checked={geocerca.notificar_salir ? true : false}
                    checkedChildren="SI"
                    unCheckedChildren="NO"
                    onChange={() => {
                      geocerca.notificar_salir = geocerca.notificar_salir === true ? false : true
                      console.log(geocerca)
                      return setGeocerca({...{}, ...geocerca})
                    }}
                    className="mb-3"
                    />
                    </Form.Item>
                </Col>
                  <Col md={24} xs={24}><Title level={4} className="mt-0">¿Cómo notificar?</Title></Col>
                  <Col md={8} xs={24}>
                <Form.Item label="Por email" required={siEsRequeridoEnEsquema('notificar_email',requeridos)} >
                <Switch
                    checked={geocerca.notificar_email ? true : false}
                    checkedChildren="SI"
                    unCheckedChildren="NO"
                    onChange={() => {
                      geocerca.notificar_email = geocerca.notificar_email === true ? false : true
                      console.log(geocerca)
                      return setGeocerca({...{}, ...geocerca})
                    }}
                    className="mb-3"
                    />
                    </Form.Item>
                </Col>
                  <Col md={8} xs={24}>
                <Form.Item label="Por whatsapp" required={siEsRequeridoEnEsquema('notificar_whatsapp',requeridos)} >
                <Switch
                    checked={geocerca.notificar_whatsapp ? true : false}
                    checkedChildren="SI"
                    unCheckedChildren="NO"
                    onChange={() => {
                      geocerca.notificar_whatsapp = geocerca.notificar_whatsapp === true ? false : true
                      console.log(geocerca)
                      return setGeocerca({...{}, ...geocerca})
                    }}
                    className="mb-3"
                    />
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    <Title className="mt-0" level={4}>Datos de contacto</Title>
                    <Row gutter={15}>
                        <Col md={24}>
                        <Title level={4} className="mt-0" ><GoDeviceMobile /> Teléfonos</Title>
                        <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirMovilAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarMovilesAdicionales()}
                        <Divider />
                        </Col>
                        <Col md={24}>
                        <Title level={4} className="mt-0" ><AiOutlineMail /> Emails</Title>
                        <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirEmailAdicional()} >AÑADIR NUEVO</Button>
                        {mostrarEmailsAdicionales()}
                        </Col>
                    </Row>
                </Col>
                
                <Col xs={24}>
                  <Button loading={loading} type="primary" onClick={() => crearGeocerca()} >CREAR ZONA</Button>
                </Col>
        </Row>
        </Form>
      </div>
    }

    const onChangeGeometries = (data) => {
      return setGeometrias(data)
    }

    const render = () => {
      return <div>
        {contextHolder}
        <Row gutter={15}>
          <Col md={8} style={{ height: "100vh", overflowY: "scroll", overflowX: "hidden" }}>
          {formularioGeocerca()}
          </Col>
          <Col md={16} style={{ height: "100vh", overflowY: "scroll", overflowX: "hidden" }}>
            <MapaDibujoPoligonoAvanzado height="100vh" onChange={data => setGeoJson(data)} onChangeGeometries={(data) => onChangeGeometries(data)} />
          </Col>
        </Row>
      </div>
    }
    
    return <Structure component={render()} />
  };
  

export default MantenedorGeocerca