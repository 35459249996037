import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Button, Card, Checkbox, Col, DatePicker, Divider, FloatButton, Form, Input, List, Modal, Row, Spin, Switch, Table, Tabs, Tooltip, Typography, message, notification } from 'antd';
import { ArrowDownOutlined, DeleteOutlined, FileExcelFilled, InboxOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import * as XLSX from 'xlsx'
import { TbAlertCircleFilled, TbCircleCheckFilled, TbHelpCircleFilled } from "react-icons/tb"
import { BiUpload } from "react-icons/bi"
import { formatoAyudaDNIPorPais, formatoAyudaMovilPorPais, nombreDNIPorPais } from "../../lib/helpers/data/internationa";
import { useDispatch, useSelector } from "react-redux";
import Dragger from "antd/es/upload/Dragger";
import React,{ useEffect, useMemo, useState } from "react";
import Column from "antd/es/table/Column";
import { urlapi } from "../../lib/backend/data";
import { cerrarSesion } from "../../redux/actions/sesion";
import Paragraph from "antd/es/typography/Paragraph";
import { formatYMD, sliceIntoChunks, validateEmail } from "../../lib/helpers/helpers";
import { obtenerPrefijoMovil, validarLongitudNumeroMovil } from "../../lib/helpers/data/moviles";
import locale from 'antd/es/date-picker/locale/es_ES';
import ExcelHelper from "../General/excel_helper";
import InformacionEsquema from "../../subComponents/general/modal_esquema_excel";
import dayjs from "dayjs"
import MostrarMoviles from "../../lib/helpers/mostrar_moviles";
import { extraerNumeros } from "../../lib/helpers/main";
import MostrarEmails from "../../lib/helpers/mostrar_emails";
import MostrarProductos from "../../lib/helpers/mostrar_productos";
import MostrarParadas from "../../lib/helpers/mostrar_paradas";
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main";
import SelectorGlobal from "../../subComponents/general/selector";
import { getLabelForVehicle } from "../../lib/helpers/selectores";
import { AiFillCar, AiFillMinusCircle } from "react-icons/ai";
import ModalSeleccionRequisitos from "../Requisitos/modal_seleccion";
import { BsTruckFlatbed } from "react-icons/bs";
import CaracteristicasVehiculo from "./requisitos/caracteristicas_vehiculo";
import { GrAdd } from "react-icons/gr";
import { FiUser } from "react-icons/fi";
import SelectorClientes from "../Clientes/selector";
import { DateTime } from "luxon";


const ImportadorViajes = (props) => {
    const [api, contextHolderPush] = notification.useNotification();
    const [ showUploadBox, setShowUploadBox ] = useState(true)
    const [ruta, setRuta ] = useState({})
    const [ procesados, setProcesados ] = useState(0)
    const [ modalProgress, setModalProgess ] = useState(false)
    const [ modalAsignacionColumna, setModalAsignacion ] = useState(false)
    const [ datos, setDatos ] = useState([])
    const [ llaveAsignar, setLlaveAsignar ] = useState(false)
    const [ validando, setValidando ] = useState(false)
    const [ datosExcelSinProcesar, setDatosExcelSinProcesar ] = useState([])
    const [ errores, setErrores ] = useState([])
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ columnasExcel, setColumnasExcel ] = useState([])
    const [ loadingValidacion, setModalValidacion ] = useState(false)
    const [ mensajeValidacion, setMensajeValidacion ] = useState('')
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ requisitos, setRequisitos ] = useState([])
    const [ caracteristicas, setCaracteristicas ] = useState({})
    const [messageApi, contextHolder] = message.useMessage();

    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)

    const addRequeriment = (nuevo) => {
        const i = requisitos.findIndex(re => re._id === nuevo._id)
        if(i > -1) return 
        return setRequisitos(prev => [...prev, ...[nuevo]])
    } 

    const onSelectRequisito = (data) => {
        addRequeriment(data)
    }

    const removerReq = (id) => {
        const registros = requisitos
        const i = registros.findIndex(re => re._id === id)
        if(i < 0) return 
        registros.splice(i,1)
        return setRequisitos(prev => [...registros, ...[]])
    }

    const visualizarRequisitosPorTipo = (tipo) => {


        const filtrados = requisitos.filter(e => e.tipo === tipo)

        return <div>
            <Row gutter={15}>
            {
                filtrados.map((req,i) => {
                    return <Col md={4} key={`req-${i}`}>
                        <Card size="small">
                        <Title level={5} className="m-0">{req.titulo}</Title>
                        <Paragraph level={5} className="m-0">{req.descripcion}</Paragraph>
                        <Button size="small" type="text" icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} /> } onClick={() => removerReq(req._id)} >ELIMINAR</Button>
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    const visualizarRequisitos = () => {

        const boton = <Button icon={<GrAdd /> } className="mb-3" >AÑADIR NUEVO</Button>

        const items = [
            {
                label: <div style={{ textAlign: "center" }}><AiFillCar style={{ fontSize: 20 }} /> <Title level={4} className="m-0">DOCUMENTACIÓN DEL VEHÍCULO</Title></div>,
                children: <div>
                    <ModalSeleccionRequisitos boton={boton} slug="subir_requisitos_vehiculo" typeView="modal" onSelect={(data) => onSelectRequisito(data)} condicion_busqueda={{ tipo: "vehiculo", status: "active" }} /> 
                    {visualizarRequisitosPorTipo('vehiculo')}
                </div>
            },
            {
                label: <div style={{ textAlign: "center" }}><FiUser style={{ fontSize: 20 }} /> <Title level={4} className="m-0">DOCUMENTACIÓN DEL CONDUCTOR</Title></div>,
                children: <div>
                    <ModalSeleccionRequisitos boton={boton} slug="subir_requisitos_conductor" typeView="modal" onSelect={(data) => onSelectRequisito(data)} condicion_busqueda={{ tipo: "conductor", status: "active" }} /> 
                    {visualizarRequisitosPorTipo('conductor')}
                </div>
            },
            {
                label: <div style={{ textAlign: "center" }}><BsTruckFlatbed style={{ fontSize: 20 }} /> <Title level={4} className="m-0">CARACTERÍSTICA DEL VEHÍCULO</Title></div>,
                children: <div>
                    <Paragraph >Solo los vehículos con las características que selecciones debajo podrán transportas esta carga</Paragraph>
                    <CaracteristicasVehiculo defaultData={caracteristicas} onChange={(data) => setCaracteristicas(data)} />
                    {visualizarRequisitosPorTipo('caracteristica_vehiculo')}
                </div>
            },
        ].map((item,i) => ({ key: (i+1), ...item}))

        return <div>
            <Title level={4} className="mt-0 mb-3">Requisitos</Title>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    }

    const openNotification = (message, description) => {
        api.info({
            message,
          description,
          placement: "bottom",
        });
    }
    
    const esquema = [
        { value: 'identificador', label: 'Identificador', validate: true },
        { value: 'origen', label: 'Origen' },
        { value: 'destinos', label: 'Destinos', info: "Utiliza la barra (/) para separar varios puntos de entrega" },
        { value: 'puntos_carga', label: 'Puntos de carga', info: "Utiliza la barra (/) para separar varios puntos", optionalUpload: true },
        { value: 'unidad_medida', label: 'Unidad medida', producto: true, optionalUpload: true, info: "Solo usar: MTR, INCH, CM, SQFT, SQM" , validation: { type: "string", key: "", values: unidades_medida.map(e => e.code) } },
        { value: 'ancho', label: 'Ancho', producto: true, optionalUpload: true },
        { value: 'alto', label: 'Alto', producto: true, optionalUpload: true },
        { value: 'largo', label: 'Largo', producto: true, optionalUpload: true },
        { value: 'unidad_medida_peso', producto: true, label: 'Unidad medida peso', optionalUpload: true, info: "Solo usar: KG, LB, KGPCM, LBPCIN", validation: { type: "string", key: "", values: unidades_medida_peso.map(e => e.code) } },
        { value: 'peso', label: 'Peso', producto: true, optionalUpload: true },
        { value: 'cantidad', label: 'Cantidad', producto: true, optionalUpload: true },
        { value: 'descripcion', label: 'Producto', producto: true, info: "Descripción del producto" },
        { value: "fecha_retiro_desde", label: "Fecha retiro desde" },
        { value: "fecha_retiro_hasta", label: "Fecha retiro hasta" },
        { value: "fecha_entrega_desde", label: "Fecha entrega desde" },
        { value: "fecha_entrega_hasta", label: "Fecha entrega hasta" },
        { value: "moviles_adicionales", label: "Moviles adicionales", optionalUpload: true, info: "Utiliza la coma (,) para separar varios números" },
        { value: "emails_adicionales", label: "Emails adicionales", optionalUpload: true, info: "Utiliza la coma (,) para separar varios emails" },
        { value: "productos", label: "Productos", denieUpload: true },
        { value: "rutcliente", label: "Rut cliente", optionalUpload: true, validate: true },
        { value: "servicio", label: "Servicio", optionalUpload: true, info: "Título del servicio", validate: true },
        { value: "observaciones", label: "Observaciones", optionalUpload: true, info: "Observaciones de la dirección" },
        { value: "conductor", label: "Conductor", optionalUpload: true, info: "Nombre y apellido del conductor" , validate: true},
        { value: "vehiculo", label: "Vehículo", optionalUpload: true, info: "Placa patente del vehículo", validate: true },
    ]

    const obtenerNombrePorLlaveIdioma = (key) => {
        const i = esquema.findIndex(e => e.value === key)
        if(i > -1){
            return esquema[i].label
        }
        return key
    }

    const obtenerTextoAyudaPorLlaveIdioma = (key) => {
        const i = esquema.findIndex(e => e.value === key)
        if(i > -1){
            return esquema[i].helptext
        }
        return key
    }

    const validarCamposExcel = async (campos) => {
        let errors = []
        setModalValidacion(true)
        for( const campo of campos ){
            setMensajeValidacion(`Validando ${campo.label}`)
            const dividir = sliceIntoChunks(campo.values, 50)
            for( const field of dividir ){
                const validar = await validarCampos({ key: campo.value, values: field })
                if(validar) if(Array.isArray(validar)){
                    for( const error of validar ){
                        errors.push(`${campo.label} ${error} no válido`)
                    }
                }
            }
        }
        setMensajeValidacion('')
        setModalValidacion(false)
        return errors
    }

    const procesarExcel = async (target) => {

        let reader = new FileReader()
            reader.readAsArrayBuffer(target)
            let hojas = []
            reader.onloadend = async (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array', cellDates:true, dateNF:'dd.mm.yyyy' });
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return messageApi.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return messageApi.error('No se encontraron datos')
            if(hojas[0].length < 1) return messageApi.error('No se encontraron datos')
            if(!hojas[0][0]) return messageApi.error('No se encontraron datos')
            
            let errores_            = []
            let registros           = []
            let nombres_columnas    = []
            
            if(errores_.length > 0) return messageApi.error(errores_.join(', ')) // SI HAY ERRORES DETENER
            if(hojas[0].length > 1000) return messageApi.error('Carga un archivo de máximo 1000 registros') // SI HAY MAS DE 1000 REGISTROS DETENER

            let pos = 0
            let campos_validar = []
            for( const hoja of hojas[0] ){

                // RECOPILAMOS LAS LLAVES DEL EXCEL
                Object.keys(hoja).map(key => {
                    const llave_limpia = key.toLocaleLowerCase()
                    if(!nombres_columnas.includes(llave_limpia)) nombres_columnas.push(llave_limpia)
                })

                pos++

                let objeto = {
                    tipo_seleccion_productos: "avanzado"
                }
                
                for( const key of esquema.filter(e => !e.producto) ){
                    if(key.denieUpload === true ) continue
                    const llaves_obtenidas = Object.keys(hoja)
                    const i = llaves_obtenidas.findIndex(llave => llave.toLowerCase() === key.label.toLocaleLowerCase())
                    if(i < 0){
                        if(key.optionalUpload === true) continue
                        errores_.push(`${key.label} no encontrado en la columna ${pos}`)
                        continue
                    } else {
                        let valor_columna = hoja[ llaves_obtenidas[i] ].toString()
                        objeto[key.value] = valor_columna
                        if(key.validate === true){
                            const i_ = campos_validar.findIndex(e => e.value === key.value)
                            if(i_ > -1 ){
                                const i_value = campos_validar[i_].values.findIndex(e => e === valor_columna)
                                if(i_value < 0) campos_validar[i_].values.push(valor_columna)
                            } else {
                                campos_validar.push({ value: key.value, label: key.label, values: [ valor_columna ] })
                            }
                        }
                    }
                }

                let producto = {}
                
                esquema.filter(e => e.producto === true).map((key) => {
                    const llaves_obtenidas = Object.keys(hoja)
                    const i = llaves_obtenidas.findIndex(llave => llave.toLowerCase() === key.label.toLocaleLowerCase())
                    if(i > -1){
                        const valor = hoja[ llaves_obtenidas[i] ]

                        if(key.validation){
                            if( typeof valor !== key.validation.type ){
                                errores_.push(`${key.label} con tipo de dato no válido en la columna ${pos}`)
                            } else if( !key.validation.values.includes( valor.toString().toUpperCase() )) {
                                errores_.push(`${valor.toString().toUpperCase()} para asignar ${key.label} en columna ${pos} es inválido`)
                            }
                        }

                        producto[key.value] = valor.toString().toUpperCase()
                    }
                })

                // MOVILES

                if(objeto.moviles_adicionales){
                    objeto.moviles_adicionales = objeto.moviles_adicionales.toString().split(',').map(numero => {
                        const number = extraerNumeros(numero)
                        return { movil: number }
                    })
                }
                
                // PUNTOS
                if(objeto.destinos){
                    objeto.destinos = objeto.destinos.split('/').map(direccion => {
                        return { description: direccion }
                    })
                }
                if(objeto.puntos_carga){
                    objeto.puntos_carga = objeto.puntos_carga.split('/').map(direccion => {
                        return { description: direccion }
                    })
                }
                
                if(objeto.emails_adicionales){
                    objeto.emails_adicionales = objeto.emails_adicionales.split(',').map(valor => {
                        if(!valor) return false
                        return validateEmail(valor.trim()) ? { email: valor.trim() } : false
                    }).filter(e => e)
                }

                const i_existe = registros.findIndex(re => re.identificador === objeto.identificador)
                if(i_existe > -1){
                    registros[i_existe].productos.push(producto)
                } else {
                    registros.push({ ...objeto, productos: [ producto ] } )
                }

            }

            if(campos_validar.length > 0){
                const errors = await validarCamposExcel(campos_validar)
                errores_ = [ ...errores_, ...errors ]
            }

            if(errores_.length > 0){
                setErrores([...[], ...errores_])
                setShowModalErrores(true)
                return openNotification('Observaciones encontradas en tu archivo', "No te preocupes, puedes corregiros ahora")
            } else {
                // openNotification("¡Atención!","Debes completar algunos campos ahora desde la pantalla, ¡adelante! ya casi estás")
            }

            setColumnasExcel(nombres_columnas)
            const limpiar_llaves_para_filtro = hojas[0].map(info => {
                let objeto_limpio = {}
                Object.keys(info).map(ll => {
                    objeto_limpio[ll.toLowerCase()] = info[ll]
                })
                return objeto_limpio
            })
            setDatosExcelSinProcesar(limpiar_llaves_para_filtro)
            setShowUploadBox(false)
            console.log({registros})
            return setDatos(registros)
        }
    }

      const props_file_upload = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        onChange(e) {
            if(e.event) return false
            procesarExcel(e.file.originFileObj)
        }
      };
    
      const handleChangeSpecial = (name, objeto, pos) => {
        datos[pos][name] = objeto._id
        if(name === "id_tipo") datos[pos].tipo = objeto.tipo
        return setDatos([...[], ...datos])
      }
      const handleChangeDate = (name, e, pos) => {
        const { value } = e.target
        const valor = value ? DateTime.fromISO(value).toJSDate() : ''
        datos[pos][name] = valor
        return setDatos([...[], ...datos])
      }

      const handleChange = (e,pos) => {
        const { name, value } = e.target
        datos[pos][name] = value
        return setDatos([...[], ...datos])
      }

      const revisarLlavesFuncionPersonalizada = (registros, llave, funcion, params) => {
        let invalidos = []
        for( const registro of registros ){
            if(params){
                if(Array.isArray(params) !== false){
                    if(params.length > 0){
                        if(!funcion(registro[llave].toString(), ...params)) invalidos.push(registro[llave].toString())
                    }
                }
            } else {
                if(!funcion(registro[llave])) invalidos.push(registro[llave])
            }
        }
        return invalidos
      }

      const subirInformacion = async () => {
        setErrores([...[], ...[]])
        const instancia_datos = JSON.parse( JSON.stringify(datos))

        const invalidos = instancia_datos.filter(dato => {
            for( const key of esquema ){
                if(key.optionalUpload === true) return false
                const llaves_obtenidas = Object.keys(dato)
                const i = llaves_obtenidas.findIndex(llave => llave.toLowerCase() === key.value.toLocaleLowerCase())
                if(i < 0) return true
            }
            return false
        })

        
        if(invalidos.length > 0){
            return openNotification("Error", "Tienes registros inválidos, corrije la información e intenta de nuevo")
        }
        
        // let validations = []
        // for( const u of datos){
        //     validations = [...validations, ...[
        //         { key: "sku", value: u.sku.toString().toUpperCase() }
        //     ]]
        // }
        // const validacion_previa = await validarCampos(validations)
        // if(!validacion_previa) return setValidando(false)
        setModalProgess(true)
        const ruta = await crearRuta()
        if(!ruta){
            openNotification("Error", "No se pudo crear la ruta")
            return setValidando(false)
        }
        
        
        let imposibles = []
        // for( const user of datos ){
        //     const exito = await crearUsuairoIndividual(user, { id_ruta: ruta._id })
        //     if(!exito) imposibles.push(1)
        // }

        const dividir = sliceIntoChunks(datos, 10)

        for( const user of dividir ){
            const exito = await crearUsuairoIndividual(user, { id_ruta: ruta._id })
            // if(!exito) imposibles.push(1)
        }

        setModalProgess(false)
        setTimeout(() => {
            setProcesados(0)
        }, 500);
        if(errores.length > 0 || imposibles.length > 0){
            openNotification("Proceso finalizado con errores", "Revisa los errores que ocurrieron")
            return setShowModalErrores(true)
        } else {
            openNotification("Proceso finalizado", "Datos cargados")
            setTimeout(() => {
                window.location = `${rutas.rutas.slug}/${ruta._id}`
            }, 1000);
        }
        setShowUploadBox(true)
        setDatos([...[], ...[]])
        return setDatosExcelSinProcesar([...[], ...[]])
      }

      const validarCampos = async (arreglo)=>{
        return fetch(`${urlapi}/viajes/validate-fields`, {
            method:'POST',
            body: JSON.stringify(arreglo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => res.json())
        .then(res => {
            if(Array.isArray(res) !== false){
                return res    
            }
            return false
        })
        .catch(error => false)
    }

      const crearRuta = async ()=>{

        return fetch(`${urlapi}/rutas`,{
            method:'POST',
            body: JSON.stringify(ruta),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                setErrores([...[], ...[res.errorMessage]])
                return false
            } else if(res._id){
                return res
            }
        })
        .catch(error => {
            return false
        })
    }

    const crearUsuairoIndividual = async (datos, otros_datos)=>{
        const progreso = procesados + datos.length

        const iterar = datos.map((usuario,i) => {

            let usuario_crear = JSON.parse( JSON.stringify(usuario))

            if( otros_datos ) if( typeof otros_datos === "object") usuario_crear = {...usuario_crear, ...otros_datos}

            let payload_enviar = {...usuario_crear, requisitos, caracteristicas}

            if(ruta.id_conductor) payload_enviar.id_conductor = ruta.id_conductor
            if(ruta.id_vehiculo) payload_enviar.id_vehiculo = ruta.id_vehiculo
            if(ruta.postullable) payload_enviar.postullable = ruta.postullable
            if(ruta.tipo) payload_enviar.tipo = ruta.tipo
            if(ruta.id_cliente) payload_enviar.id_cliente = ruta.id_cliente

            return payload_enviar
        })

        return fetch(`${urlapi}/viajes/new/complete/batch`,{
            method:'POST',
            body: JSON.stringify({
                create: iterar
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            setProcesados(progreso => progreso + datos.length)
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                setErrores([...[], ...[res.errorMessage]])
                return false
            } else if(res._id){
                return res._id
            }
        })
        .catch(error => {
            return false
        })
    }
    
    const handleCloseAsignacion = () => setModalAsignacion(false)

    const mostrarErrores = () => {
        return <Modal  style={{ padding: 30 }} open={showModalErrores} footer={[]} onCancel={() => {
            setShowModalErrores(false)
            setErrores([...[], ...[]])
        }}>
            <TbAlertCircleFilled style={{ fontSize: 40, color:"red" }} />
        <Title level={2} className="mt-0 mb-0"> Errores encontrados</Title>
        <Title level={5} className="mt-0 mb-3">Encontramos los siguientes errores al validar la información que intentas cargar, corrígelos y vuelve a intentarlo</Title>
        <List
            className="mt-3"
            dataSource={errores}
            renderItem={(item) => (
                <List.Item>{item}</List.Item>
            )}
            />
        </Modal>
    }

    const mostrarModalProgreso = () => {
        return <Modal  style={{ textAlign: "center", padding: 30 }} open={modalProgress} footer={[]} onCancel={() => false}>
            <Spin size="large"/>
        <Title level={2} className="mt-0 mb-0"> Cargando tu información</Title>
        <Title level={3} className="mt-0 mb-0">{procesados} procesados de {datos.length}</Title>
        <Title level={4} className="mb-0 mt-0">No cierres la ventana por favor...</Title>
        </Modal>
    }
    const mostrarModalValidacion = () => {
        return <Modal  style={{ textAlign: "center", padding: 30 }} open={loadingValidacion} footer={[]} onCancel={() => false}>
            <Spin size="large"/>
        <Title level={2} className="mt-0 mb-0">Cargando tu información</Title>
        { mensajeValidacion ? <Title level={4} className="mb-0 mt-0">{mensajeValidacion}</Title> : false }
        <Title level={4} className="mb-0 mt-0">No cierres la ventana por favor...</Title>
        </Modal>
    }

    const asignarLlaveExcelADatos = (key) => {
        const instancia_datos = JSON.parse( JSON.stringify(datos))
        const nuevo_arreglo = instancia_datos.map((dato,i) => {
            dato[llaveAsignar] = datosExcelSinProcesar[i][key]
            return dato
        })
        setDatos([...[], ...nuevo_arreglo])
        return setModalAsignacion(false)
    }
    
    const mostrarModalAsignacion = () => {
        return <Modal  style={{ textAlign: "center", padding: 30 }} open={modalAsignacionColumna} footer={[]} onCancel={handleCloseAsignacion}>
        <Title level={2} className="mt-0 mb-2"> Asigna una columna de tu excel al campo {obtenerNombrePorLlaveIdioma(llaveAsignar)}</Title>
        {
            columnasExcel.map(key => {
                return <Button style={{ margin: 4}} key={key} onClick={() => asignarLlaveExcelADatos(key)} >{key}</Button>
            })
        }
        </Modal>
    }

    const abrirAsignacion = (key) => {
        setLlaveAsignar(key)
        return setModalAsignacion(true)
    }

    const EncabezadoPesonalizado = ({ titulo, campo, helptext, esquema }) => {

        if(helptext) {
            return <Tooltip title={helptext}>
            <Paragraph className="mb-0 mt-0">{titulo} <TbHelpCircleFilled /></Paragraph>
            { esquema.denieUpload === true ? <Button size="small" disabled style={{ fontSize: 9 }} >CAMBIAR ASIGNACIÓN COLUMNA</Button> : <Button size="small" style={{ fontSize: 9 }} onClick={() => abrirAsignacion(campo)} >CAMBIAR ASIGNACIÓN COLUMNA</Button> }
            
            </Tooltip>
        }

        return <>
            <Paragraph className="mb-0 mt-0">{titulo}</Paragraph>
            { esquema.denieUpload === true ? <Button size="small" disabled style={{ fontSize: 9 }} >CAMBIAR ASIGNACIÓN COLUMNA</Button> : <Button size="small" style={{ fontSize: 9 }} onClick={() => abrirAsignacion(campo)} >CAMBIAR ASIGNACIÓN COLUMNA</Button> }
        </>
    }

    const eliminarColumna = (i) => {
        datos.splice(i,1)
        return setDatos([...[], ...datos])
    }

    const agregarFiltro = (key, value) => {
        return setRuta(prev => {
            let actual = {...prev}
            if(value){
                if(typeof value === "object"){
                    actual[key] = value._id
                } else {
                    actual[key] = value
                }
            } else {
                delete actual[key]
            }
            return actual
        })
    }
    
    const mostrarSegmendoPostulacionesActivas = () => {
        if(!ruta) return false
        if(!ruta.postullable) return false

        return <Col md={24}>
            <Card size="small">
                <Title className="mt-0 mb-0">Requisitos</Title>
                {visualizarRequisitos()}
            </Card>
        </Col>
    }

      const mostrarDatos = () => {
        if(datos.length < 1) return false

        return <div className="mt-3">
            <Title level={3} className="mt-0 mb-2">Datos cargados</Title>


            <Row gutter={15}>
                <Col md={6}>
                <SelectorGlobal fields_search={[ "nombres","apellido_m","apellido_p" ]} titulo="Conductor" condicion={{ }} module="conductores" key_label="nombres" key_value="_id" onChange={(data) => agregarFiltro('id_conductor', data)} />
                </Col>
                <Col md={6}>
                <SelectorGlobal titulo="Vehículo" labelFunction={getLabelForVehicle} condicion={{ }} module="vehiculos" key_label="patente" key_value="_id" onChange={(data) => agregarFiltro('id_vehiculo', data)} />
                </Col>
                <Col md={6}>
                <SelectorGlobal titulo="Tipo de servicio" condicion={{ tipo: "servicios" }} module="estadoscarga" key_label="title" key_value="_id" onChange={(e) => agregarFiltro('tipo', e?._id)} />
                </Col>
                <Col md={6}>
                <SelectorClientes onChange={(id) => agregarFiltro('id_cliente', id)} titulo="Cliente" condicion={{}} />
                </Col>
                <Col md={6}>
                <Form.Item label="Acepta postulaciones de terceros">
                <Switch checkedChildren="ACEPTAR" unCheckedChildren="NO ACEPTAR" checked={ruta.postullable === true ? true : false} onChange={(e) => agregarFiltro("postullable", e)} />
                </Form.Item>
                </Col>
                {mostrarSegmendoPostulacionesActivas()}
            </Row>


            <Button size="small" loading={validando} type="primary" onClick={() => subirInformacion()} ><BiUpload style={{ marginRight: 5 }} /> CREAR {datos.length} REGISTROS</Button>
            <Divider />
            <Table dataSource={datos} pagination={false} scroll={{ x:"100vw" }}>
            <Column render={(data,i,p) => {
                    return <div>
                        <DeleteOutlined onClick={() => eliminarColumna(p)} />
                    </div>
                }} />
                {
                    esquema.filter(re => !re.producto).map((esq,posicion) => {

                        if(["fecha_retiro_desde", "fecha_retiro_hasta", "fecha_entrega_desde", "fecha_entrega_hasta"].includes(esq.value)){
                            return <Column key={`esq-${posicion}`} title={<EncabezadoPesonalizado esquema={esq} campo={esq.value} titulo={obtenerNombrePorLlaveIdioma(esq.value)} helptext={obtenerTextoAyudaPorLlaveIdioma(esq.value)} />} render={(data,i,p) => {
                                let default_date = data[esq.value] ? DateTime.fromISO(data[esq.value]).toFormat("yyyy-MM-dd") : ''
                                return <div style={{ minWidth: 120 }}>
                                    {/* <SelectorMarcas error={!data[esq.value] ? true : false} strict={true} onChange={data => handleChangeSpecial(esq.value, data, p)} /> */}
                                    <Form.Item label="Desde">
                                    {/* <DatePicker style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={data[esq.value] ? dayjs(formatYMD(data[esq.value]), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(esq.value, e, p)} /> */}
                                    <Input defaultValue={default_date} type="date" onChange={(e) => handleChangeDate(esq.value, e, p)} />
                                    </Form.Item>
                                    </div>
                            }} />
                        } else if(esq.value === "moviles_adicionales"){
                            return <Column key={`esq-${posicion}`} title={<EncabezadoPesonalizado esquema={esq} campo={esq.value} titulo={obtenerNombrePorLlaveIdioma(esq.value)} helptext={obtenerTextoAyudaPorLlaveIdioma(esq.value)} />} render={(data,i,p) => {
                                
                                let registros_ = []
                                if(data[esq.value]) if(Array.isArray(data[esq.value]) !== false) registros_ = data[esq.value]
                                
                                return <div style={{ minWidth: 120 }}>
                                    <MostrarMoviles datos={registros_} />
                                    </div>
                            }} />
                        } else if(esq.value === "emails_adicionales"){
                            return <Column key={`esq-${posicion}`} title={<EncabezadoPesonalizado esquema={esq} campo={esq.value} titulo={obtenerNombrePorLlaveIdioma(esq.value)} helptext={obtenerTextoAyudaPorLlaveIdioma(esq.value)} />} render={(data,i,p) => {
                                
                                let registros_ = []
                                if(data[esq.value]) if(Array.isArray(data[esq.value]) !== false) registros_ = data[esq.value]
                                
                                return <div style={{ minWidth: 120 }}>
                                    <MostrarEmails datos={registros_} />
                                    </div>
                            }} />
                        } else if(esq.value === "productos"){
                            return <Column key={`esq-${posicion}`} title={<EncabezadoPesonalizado esquema={esq} campo={esq.value} titulo={obtenerNombrePorLlaveIdioma(esq.value)} helptext={obtenerTextoAyudaPorLlaveIdioma(esq.value)} />} render={(data,i,p) => {
                                
                                let registros_ = []
                                if(data[esq.value]) if(Array.isArray(data[esq.value]) !== false) registros_ = data[esq.value]
                                
                                return <div style={{ minWidth: 120 }}>
                                    <MostrarProductos datos={registros_} />
                                    </div>
                            }} />
                        } else if(esq.value === "destinos"){
                            return <Column key={`esq-${posicion}`} title={<EncabezadoPesonalizado esquema={esq} campo={esq.value} titulo={obtenerNombrePorLlaveIdioma(esq.value)} helptext={obtenerTextoAyudaPorLlaveIdioma(esq.value)} />} render={(data,i,p) => {
                                
                                let registros_ = []
                                if(data[esq.value]) if(Array.isArray(data[esq.value]) !== false) registros_ = data[esq.value]
                                
                                return <div style={{ minWidth: 120 }}>
                                    <MostrarParadas datos={registros_} />
                                    </div>
                            }} />
                        } else if(esq.value === "puntos_carga"){
                            return <Column key={`esq-${posicion}`} title={<EncabezadoPesonalizado esquema={esq} campo={esq.value} titulo={obtenerNombrePorLlaveIdioma(esq.value)} helptext={obtenerTextoAyudaPorLlaveIdioma(esq.value)} />} render={(data,i,p) => {
                                
                                let registros_ = []
                                if(data[esq.value]) if(Array.isArray(data[esq.value]) !== false) registros_ = data[esq.value]
                                
                                return <div style={{ minWidth: 120 }}>
                                    <MostrarParadas datos={registros_} />
                                    </div>
                            }} />
                        }

                        return <Column key={`esq-${posicion}`} title={<EncabezadoPesonalizado esquema={esq} campo={esq.value} titulo={obtenerNombrePorLlaveIdioma(esq.value)} helptext={obtenerTextoAyudaPorLlaveIdioma(esq.value)} />} render={(data,i,p) => {
                            return <Input
                            status={!data[esq.value] ? "error": ""} 
                            name={esq.value}
                            value={data[esq.value]} 
                            onChange={(e) => handleChange(e,p)} 
                            prefix={!data[esq.value] ? <TbAlertCircleFilled style={{ color: "red"}} /> : <TbCircleCheckFilled style={{ color: "green"}} /> }
                        />
                        }} />
                    })
                }
                
            </Table>
        </div>
      }
      
    const render = () => {
        return <div>
            <Card>
            <Title className="mb-2 mt-0">Importar viajes</Title>
            <InformacionEsquema esquema={esquema} />
            </Card>
                <div style={{ paddingRight: 20, paddingTop:10, paddingBottom: 10, paddingLeft: 20 }}><ExcelHelper /></div>
            <Card>
                {
                     showUploadBox ? <Dragger {...props_file_upload}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Has click o arrastra el archivo a esta sección</p>
                    <p className="ant-upload-hint">Solo está permitida la subida de archivos de tipo excel</p>
                    </Dragger> : false
                }
            {mostrarDatos()}
            </Card>
            {contextHolder}
            {contextHolderPush}
            {mostrarErrores()}
            {mostrarModalProgreso()}
            {mostrarModalValidacion()}
            {mostrarModalAsignacion()}
        </div>
    }
    
    return <Structure component={render()} />
}

export default ImportadorViajes