import { useDispatch, useSelector } from "react-redux";
import { urlapi } from "../../lib/backend/data";
import { useEffect, useState } from "react";
import { cerrarSesion } from "../../redux/actions/sesion";
import { Alert, Spin, message } from "antd";

const EmpresaValidadaPlataformasAsociadas = (props) => {
    
    const [ loading, setLoading ] = useState(true)
    const [ datos, setDatos ] = useState(false)
    const pais =  useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()

    const propietario = session.data.propietario
    const condicion_default = { tipo: "porta-cliente", pais_code: "CL", sub_tipo: "configuracion-empresa", "detalles.allow_publish": true, propietario }

    const obtenerDatos = async () => {
        
        setLoading(true)
        return fetch(`${urlapi}/configuracion/details`, {
          method: 'POST',
          body: JSON.stringify({
            condicion: condicion_default
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if(res?._id){
                setDatos(res)
            } else {
                if(props.isRestricted) props.isRestricted()
            }
            return setLoading(false);
          })
          .catch((error) => setLoading(false))
      }

      
      useEffect(() => {
        obtenerDatos()
      }, [])

      const render = () => {
        if(loading) return false
        if(!datos) return <Alert className="mb-3" showIcon type="warning" message="¡Atención!" description="Tu empresa no puede publicar vehículos y/o equipos en las plataformas asociadas, verifica si tus requisitos de empresa han sido validados o comunícate con atención al cliente" />
        return <div></div>
      }
      return <div>
        {contextHolder}
        {render()}
      </div>
}

export default EmpresaValidadaPlataformasAsociadas