const optiones_estricto_tiempo = [
    { value: "strict", label: "Estricto" },
    { value: "soft", label: "No es estricto" },
    { value: "soft_start", label: "Hora mínima flexible" },
    { value: "soft_end", label: "Hora máxima flexible" },
]

const opciones_origenes = [
    { value: "", label: "Cualquiera" },
    { value: "warehouse", label: "Inicio de la ruta" },
]

const ruteo_data = {
    optiones_estricto_tiempo,
    opciones_origenes
}

module.exports = ruteo_data