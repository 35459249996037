import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Avatar, Button, Card, Col, Form, Input, Pagination, Progress, Row, Select, Table, Tooltip, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { calcularPorcentaje, fechaATextoSimple } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import { FileExcelFilled, QuestionCircleOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons"
import { formatearDNIPorPais, formatearMovilPorPais, nombreDNIPorPais } from "../../lib/helpers/data/internationa"
import HelperModal from "../../subComponents/general/helperModal"
import { tipo_zonas, tipos_rastreo } from "./data"
import ModalSeleccionZonas from "./modal_seleccion"

const ListadoZonas = (props)=> {
    const {
        condicion_default,
        tableSize,
        hideSearch,
        hideHeader,
        typeView
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [filtros, setFiltros] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const trash = props.trash ? props.trash : false
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'

    const obtenerDatos = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        setCondicionBusqueda(condicion)
        return fetch(`${urlapi}/geodata/zonas/empresa/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
                if(res.filtros) setFiltros(res.filtros)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerDatos(1, false)
    }, [])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerDatos(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div>
            <Pagination defaultCurrent={pagina} total={(cantidad*10)} showSizeChanger={false} onChange={handleChangePagina} />
        </div>
    }

    const seleccionarRegistro = data => {
        if(props.onSelect) props.onSelect(data)
    }

    const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <b className="hover" onClick={() => seleccionarRegistro(data)}>{data.titulo}</b>
            default:
                return <Link to={`${rutas.zones.slug}/${data._id}`}>{data.titulo}</Link>
        }
    }

    const filtrarRegistros = (filtro) => {
        if(!filtro.condicion) return false
        if(typeof filtro.condicion !== "object") return false
        obtenerDatos(1, filtro.condicion)
    }

    const agregarFiltro = (key, value) => {
        if(value.length < 1){
            delete filtroBusqueda[key]
        } else {
            filtroBusqueda[key] = { $in: value.map(v => v._id) }
        }
        setFiltroBusqueda(filtroBusqueda)
      }

      const resetFilters = () => {
        setKeyInputFilter(key_input_filter + 1)
        setFiltroBusqueda({})
        obtenerDatos(1, condicion_default ? condicion_default : {})
    }
    
      const refrescarBusqueda = () => {
        setPagina(1)
        let cond = {}
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            }
        })
        obtenerDatos(1, cond)
    }

    const showFilters = () => {
        const mostrarButtonReset = Object.keys(filtroBusqueda).length > 0 ? true : false
        return <div>
            <Row gutter={15}>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Tipo de zona</Title>
                    <Select key={`tipo${key_input_filter}`} style={{ width: "100%" }} allowClear options={tipo_zonas} onChange={(data) => agregarFiltro("tipo", data ? [ { _id: data } ] : [])} placeholder="Selecciona" />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Tipo de rastreo</Title>
                    <Select key={`tipo_rastreo_${key_input_filter}`} style={{ width: "100%" }} allowClear options={tipos_rastreo} onChange={(data) => agregarFiltro("tipo_rastreo", data ? [ { _id: data } ] : [])} placeholder="Selecciona" />
                </Col>
                <Col md={4} className="mb-3">
                    <Title className="mt-0" level={5}>Estado</Title>
                    <Select key={`status_${key_input_filter}`} style={{ width: "100%" }} allowClear options={[ { value: "active", label: "Activo" }, { value: "inactive", label: "Inactivo" } ]} onChange={(data) => agregarFiltro("status", data ? [ { _id: data } ] : [])} placeholder="Selecciona" />
                </Col>
                <Col md={4} className="mb-3">
                    <Title className="mt-0" level={5}>BUSCAR</Title>
                    <Button loading={loadingConductores} onClick={() => refrescarBusqueda()} style={{ width: "100%" }} icon={<SearchOutlined />} >ACTUALIZAR</Button>
                </Col>
                <Col md={4}>
                    <Title className="mb-2 mt-0" level={5}>Limpiar</Title>
                    <Button disabled={!mostrarButtonReset}  style={{ width: "100%" }} onClick={() => resetFilters()}>BORRAR FILTROS</Button>
                </Col>
            </Row>
        </div>
    }

    const header = () => {
        if(hideHeader === true) return false
        return <Row gutter={15}>
            <Col md={3}>
                <Card size="small" className="mb-3">
                    <Paragraph level={3} style={{ fontSize: 12 }} className="m-0">Total de registros filtrados</Paragraph>
                    <Title className="m-0">{total}</Title>
                </Card>
            </Col>
            {
                filtros.map((filtro,i) => {


                    const porcentaje = calcularPorcentaje(total, filtro.cantidad)
                    return <Col className="hover" md={3} key={`filtro-${i}`} onClick={() => filtrarRegistros(filtro)} >
                    <Card size="small" className="mb-3">
                        <Paragraph level={3} style={{ fontSize: 12 }} className="m-0">{filtro.label}
                        {
                            filtro.help_text ? <Tooltip title={filtro.help_text}>
                           <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                          </Tooltip> : false
                        }
                        </Paragraph>
                        <Row>
                            <Col xs={12}><Title className="m-0">{filtro.cantidad}</Title></Col>
                            <Col xs={12} style={{ textAlign: "right", marginTop:8 }}> <Progress size={35} type="circle" percent={porcentaje} /></Col>
                        </Row>
                    </Card>
                </Col>
                })
            }
        </Row>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/drivers`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };


      const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda) return messageApi.error('Escribe alguna palabra clave');
        const condicion = {
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerDatos(1, condicion);
      };
      
      const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit}>
        <Row gutter={15}>
            <Col md={14}>
                <Form.Item label="Buscar">
                    <Input autoFocus defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Título o palabra clave`} />
                </Form.Item>
            </Col>
            <Col md={4}>
                <Form.Item>
                    <Button onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                </Form.Item>
            </Col>
        </Row>
               
                </Form>
      }

      const mostrarTabla = () => {
        if(loadingConductores) return <CargandoTabla />

        return <div>
            <Table size={tableSize ? tableSize : "middle"} dataSource={conductores} pagination={false} scroll={true} locale={{ emptyText: <HelperModal tipo="automatizaciones" /> }}>
                <Column title="Título" render={(data) => {
                    return <div>{showByType(typeView, data)}</div>
                }} />
                <Column title="Tipo de zona" render={(data) => {
                    let tipos = {}
                    for( const tipo of tipo_zonas ){
                        tipos[tipo.value] = tipo.label
                    }
                    return <div>{tipos[data.tipo] ? tipos[data.tipo] : "Sin información"}</div>
                }} />
                <Column title="Tipo de rastreo" render={(data) => {
                    let tipos = {}
                    for( const tipo of tipos_rastreo ){
                        tipos[tipo.value] = tipo.label
                    }
                    return <div>{tipos[data.tipo_rastreo] ? tipos[data.tipo_rastreo] : "Sin información"}</div>
                }} />
                <Column title="Tipos de vehículo" render={(data) => {
                    const vehiculos = data.tipos_vehiculo ? data.tipos_vehiculo : []
                    return <div>{vehiculos.length}</div>
                }} />
                <Column title="Conductores" render={(data) => {
                    const conductores = data.conductores ? data.conductores : []
                    return <div>{conductores.length}</div>
                }} />
                <Column title="Estado" render={(data) => {
                    return <div>{data.status === "active" ? "ACTIVO" : "INACTIVO"}</div>
                }} />
                <Column title="Fecha de creación" render={(data) => {
                    return <div>{fechaATextoSimple(data.createdAt)}</div>
                }} />
                <Column title="Creador" render={(data) => {
                    return <div>{data.creador?.nombres} {data.creador?.apellidos}</div>
                }} />
            </Table>
        </div>
      }

    const mostrarRegistros = () => {

        return <div>
            {showFilters()}
            {header()}
            {buscador()}
            {paginacion(data.pagina, total)}
            {mostrarTabla()}
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoZonas