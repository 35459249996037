import Map, { FullscreenControl, Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import { colores, mapbox_token, url_images, urlapi_clientes } from '../../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import Pin from '../../../subComponents/mapas/pin';
import { obtenerCentroMapaPorPais } from '../../../lib/helpers/data/internationa';
import { BsFillStopCircleFill } from 'react-icons/bs';
import { Button, Col, Divider, Menu, Modal, Row, Spin, Steps, Tag, message, notification } from 'antd';
import polyline from '@mapbox/polyline';
import Title from 'antd/es/typography/Title';
import { FaCircle, FaDotCircle, FaRegUserCircle } from 'react-icons/fa';
import { estilo_moving_truck } from '../../../lib/estilo_sitio';
import { MdAltRoute, MdOutlinePlace, MdOutlineRoute } from 'react-icons/md';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import Paragraph from 'antd/es/typography/Paragraph';
import { Link } from 'react-router-dom';
import { LuBox } from 'react-icons/lu';
import { formatoMoneda } from '../../../lib/helpers/main';

const MapaViajeDetalle = (props) => {
    const {
        height,
        markers,
        ubicacionConductor,
        routesConductor,
        pinType,
        resize,
        poligonos,
        ruta
    } = props
    const [api, contextHolderNotificaion] = notification.useNotification();
    const [ marcadores, setMarcadores ] = useState(markers)
    const sesion = useSelector(state => state.miusuario)
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState(ruta)
    const [ loadingRuta, setLoadingRuta ] = useState(false)
    const [ focusVisualization, setFocusVisualization ] = useState(false)
    const pais = useSelector(state => state.pais)
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const [ routes, setRoutes ] = useState([]) 
    const [messageApi, contextHolder] = message.useMessage();
    const [ wayPoints, setWayPoints ] = useState([]) 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [puntoSeleccinoado, setPuntoSeleccinoado] = useState(null)
    const pin_truck = <img style={{ height: 40, margin: "0 auto", display: "block"}} src={`${url_images}/truck-up-view.png`} />
    const mapRef = useRef()
    const controls = []

    const handleOk = () => {
      setIsModalOpen(false);
    }

    const handleCancel = () => {
      setIsModalOpen(false);
    }

    const openNotification = (text, description) => {
        api.info({
          message: text,
          description,
          placement: "bottomRight",
        });
    }    

    const mostrarHojaRuta = (punto) => {
        let current = 0
        const puntos_visita = [...marcadores.filter(p => p.tipo === "origen"), ...marcadores.filter(p => p.tipo === "entrega")]
        const i = puntos_visita.findIndex(p => p._id === punto._id)
        if(i > -1 ) current = i

        return <div>
            <Divider style={{  marginTop: 15, marginBottom: 15 }} />
            <Title className='mt-0 mb-3' level={4}><MdOutlineRoute style={{ verticalAlign: "middle" }} /> Hoja de ruta</Title>
            <Steps direction="vertical" current={current} items={puntos_visita.map(p => ({ title: <div><Tag color={ p.tipo === "origen" ? "orange" : "blue" } >{p.tipo}</Tag> {p.description}</div>, description: p.description }))} />
        </div>
    }


    const mostrarModal = () => {
        if(!puntoSeleccinoado) return false
        return <Modal title="Detalles del punto" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[]}>
        <Paragraph><Tag className='mb-0' color={puntoSeleccinoado.tipo === "entrega" ? "blue" : "orange"} >{puntoSeleccinoado.tipo}</Tag></Paragraph>
        <Title className='mt-0 mb-0' level={3}><MdOutlinePlace style={{ verticalAlign: "middle" }} /> {puntoSeleccinoado.description}</Title>
        <Divider style={{  marginTop: 15, marginBottom: 15 }} />
        <Title className='mt-0 mb-3' level={4}><LuBox style={{ verticalAlign: "middle" }} /> Detalles de la carga</Title>
        <Row gutter={15}>
            <Col md={8}>
                <Title level={5} className='mt-0 mb-0'>Peso volumétrico</Title>
                <Paragraph>{puntoSeleccinoado.peso_vol} {puntoSeleccinoado.unidad_medida_peso}</Paragraph>
            </Col>
            <Col md={8}>
                <Title level={5} className='mt-0 mb-0'>Volumen</Title>
                <Paragraph>{puntoSeleccinoado.volumen} {puntoSeleccinoado.unidad_medida_volumen}</Paragraph>
            </Col>
            <Col md={8}>
                <Title level={5} className='mt-0 mb-0'>Bultos</Title>
                <Paragraph>{formatoMoneda(puntoSeleccinoado.bultos)}</Paragraph>
            </Col>
        </Row>
        {mostrarHojaRuta(puntoSeleccinoado)}
      </Modal>
    }

    const ajustarCentro = (puntos) => {
        if(zoomInit) return false
        if(puntos.length < 1) return false
        setZoomInit(true)
        const points = puntos.map(marker => turf.point([marker.longitude, marker.latitude]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 30 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current?.fitBounds(bounds, options);
    }


    const validarRespuestaRutaMapbox = (sugerencias) => {
        let way_points          = []
        let rutas_consultadas   = []
        let errores_mensajes    = []

        for( const res of sugerencias){
            if(res.uuid){
                if(res.code) if(res.code.toString().toUpperCase() === "OK") {
                    if(res.routes) if(Array.isArray(res.routes) !== false) if(res.routes.length > 0){
                        rutas_consultadas = [...rutas_consultadas, ...[ res.routes[0] ]]
                        way_points = [...way_points, ...res.waypoints]
                    }
                } else {
                    errores_mensajes.push(res.message)
                }
            }
        }
        if(errores_mensajes.length > 0) messageApi.error(errores_mensajes.join(', '))
        setWayPoints(way_points)
        // alinearMapa(way_points)
        if(rutas_consultadas.length > 0) setRoutes(rutas_consultadas)
    }


    const buscarDirecciones = async (origen, puntos, profile) => {
        setLoadingRuta(true)
        // openNotification("Obteniendo información de la ruta...", <div><Title level={5} className='mt-0 mb-0'><Spin /> Verás la información del camino sugerido</Title></div>)
        return fetch(`${urlapi_clientes}/geodata/direcciones`,{
            method:'POST',
            body: JSON.stringify({
                origen,
                puntos,
                profile
            }),
            headers: {
                'Content-Type':'application/json',
                // 'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            // if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                validarRespuestaRutaMapbox(res)
            }
            return setLoadingRuta(false)
        })
        .catch(error => {
            return setLoadingRuta(false)
        })
    }

    const validarRutaOptima = () => {
        if(rutaSeleccionada) return false
        let origen = null

        const i                 = markers.findIndex(marker => marker.tipo === "origen")
        if(i > -1 )      origen = markers[i]

        const puntos            = markers.filter(marker => marker.tipo !== "origen")
        if(!origen)             return

        let actual = null
        const puntos_ordenados  = puntos.map(p => ({...p, location: p.start_point}))

        for( const punto of puntos_ordenados){
            if(punto.finished !== true){
                actual = punto
                break
            }
        }

        buscarDirecciones({...origen, location: origen.start_point }, puntos_ordenados, 'driving-traffic')
    }

      useEffect(() => {
          if(mapRef) if(mapRef.current) mapRef.current.resize()
          setMarcadores(markers)
        validarRutaOptima()
            setTimeout(() => {
                ajustarCentro(markers.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
            }, 500);
      }, [ resize ])

      const refrescarListado = async ()=>{
        console.log("se dispara")
        return fetch(`${urlapi_clientes}/conductores/vehicles-actives`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return res.json() //dispatch(cerrarSesio())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                return false
            } else if(Array.isArray(res) !== false){
                ajustarCentro(res.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
                setMarcadores(prev => [...[], ...res])
            }
            return false
        })
        .catch(error => {
            return false
        })
    }

    const showPin = (color) => {
        const tipo = typeof pinType !== "undefined" ? pinType : false
        switch (tipo) {
            case "uber":
                return <FaCircle size={8} color={color} />
            case "truck":
                return pin_truck
            default:
                return <Pin />
        }
    }

    const centrarMapa = (centro, max_zoom) => {
        const nuevo_zoom = defaultViewState.zoom + (max_zoom ? max_zoom : 3)
        mapRef.current?.flyTo({center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000});
        setTimeout(() => {
          setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
        }, 2010);
      }

    const mostrarConductor = () => {
        if(!ubicacionConductor) return false
        if(!ubicacionConductor.location) return false
        if(!ubicacionConductor.location.coordinates) return false
        if(ubicacionConductor.location.coordinates.length < 2) return false
        centrarMapa({ longitude: ubicacionConductor.location.coordinates[0], latitude: ubicacionConductor.location.coordinates[1] }, 8)
    }

    const mostrarUbicacionConductor = () => {
        if(!ubicacionConductor) return false
        if(!ubicacionConductor.location) return false
        if(!ubicacionConductor.location.coordinates) return false
        if(ubicacionConductor.location.coordinates.length < 2) return false
        return <Marker
            key={"ubicacion-conductor"}
            longitude={ubicacionConductor.location.coordinates[0]}
            latitude={ubicacionConductor.location.coordinates[1]}
            >
            <div style={{ textAlign: "center" }}>
                {pin_truck}
            { ubicacionConductor.patente ? <Tag color='blue'>{ubicacionConductor.patente}</Tag> : false }
            </div>
        </Marker>
    }

    const handleMouseEnter = (key) => {
        setFocusVisualization(key)
    }

    const handleMouseLeave = () => {
        setFocusVisualization(false)
    }

    let distancia_aproximada = null

    if(routes) if(Array.isArray(routes)) if(routes.length > 0) distancia_aproximada = `${Math.round(routes[0].distance / 1000)} km`

    const itemsMenu = [
        {
            key: "rastreo",
            label: <div
                    onMouseEnter={(e) => handleMouseEnter("rastreo")}
                    onMouseLeave={handleMouseLeave}
                >RUTA SUGERIDA { distancia_aproximada !== null ? <b>{distancia_aproximada}</b> : false }</div>
        },
        {
            key: "analisis",
            label: <div
                onMouseEnter={(e) => handleMouseEnter("analisis")}
                onMouseLeave={handleMouseLeave}
            >RUTA A TRANSITAR</div>
        },
        {
            key: "trafico",
            label: <div
            onClick={() => mostrarConductor()}
                // onMouseEnter={(e) => handleMouseEnter("trafico")}
                // onMouseLeave={handleMouseLeave}
            ><FaRegUserCircle color={estilo_moving_truck.colors.primary} /> MOSTRAR CONDUCTOR</div>
        },
    ]

    const onClick = (e) => {
        console.log('click ', e);
        // setCurrent(e.key);
      };

      const handleGeocoderResult = (event) => {
        if (event.result && event.result.geometry && event.result.geometry.coordinates) {
          const [lng, lat] = event.result.geometry.coordinates;
          setViewState({ ...viewState, longitude: lng, latitude: lat, zoom: 12 });
        }
      };

    const geocodificador = () => {

        const findA = document.getElementsByClassName('mapboxgl-ctrl-geocoder').length > 0
        if(findA) return false

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false, // Desactivar el marcador de ubicación seleccionada
            placeholder: "Busca una dirección",
            countries: pais
        });
        geocoder.on('result', handleGeocoderResult);
        setTimeout(() => {
            if(controls.length < 1){
                mapRef.current?.addControl(geocoder, "top-right")
            }
          controls.push('geocoder')
        }, 200);
      }

      const handleLoadMap = () => {
          geocodificador()
      }

    const showModal = (data) => {
        setPuntoSeleccinoado(data)
        setIsModalOpen(true);
    }

    const menuSeleccion = () => {

        const tabBarExtraContent = <Button >MOSTRAR POSICIÓN DEL CONDUCTOR</Button>

        return <div>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <Menu mode="horizontal" items={itemsMenu} />
        </div>
    }

    const mostrarRuta = () => {
        if(focusVisualization) if(focusVisualization !== "rastreo") return false

        return routes.map((ruta,ir) => {

            let pintar = ruta.selected === true
            const decodedCoords = polyline.decode(ruta.geometry)
            if(ruta.checked !== true){
                const hay_seleccionado = routes.filter(r => r.selected === true).length
                if(hay_seleccionado > 0) if(ruta.selected !== true) return false
            } else {
                pintar = true
            }

            const color = "black"
            
            return <Source
                id={`route-${ir}`}
                type='geojson'
                data={{
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: "LineString",
                        coordinates: decodedCoords.map(co => [co[1], co[0]])
                    }
                }}
            >
                <Layer
                id={`route-layer-${ir}`}
                type="line"
                layout={{
                    'line-join': 'round',
                    'line-cap': 'round',
                }}                
                paint={{
                    'line-color': pintar ? color : color,
                    'line-width': 3,
                    'line-opacity': 0.75
                }}
            />
            </Source>
        })
    }

    const mostrarTrayecto = () => {
        if(focusVisualization) if(focusVisualization !== "analisis") return false

        return routesConductor.map((ruta,ir) => {

            let pintar = ruta.selected === true
            const decodedCoords = polyline.decode(ruta.geometry)
            if(ruta.checked !== true){
                const hay_seleccionado = routes.filter(r => r.selected === true).length
                if(hay_seleccionado > 0) if(ruta.selected !== true) return false
            } else {
                pintar = true
            }

            const color = "blue"
            
            return <Source
                id={`route-${ir}-conductor`}
                type='geojson'
                data={{
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: "LineString",
                        coordinates: decodedCoords.map(co => [co[1], co[0]])
                    }
                }}
            >
                <Layer
                id={`route-layer-${ir}-conductor`}
                type="line"
                layout={{
                    'line-join': 'round',
                    'line-cap': 'round',
                }}                
                paint={{
                    'line-color': color,
                    'line-width': 2,
                    'line-opacity': 0.75,
                    'line-dasharray': [2, 2]
                }}
            />
            </Source>
        })
        
    }

    const mostrarTrayecto2 = () => {
        if(!poligonos) return false
        if(!Array.isArray(poligonos)) return false
        if(focusVisualization) if(focusVisualization !== "analisis") return false

        return poligonos.map( (poligono,i) => {
                return <Source key={`pol-${i}`} type="geojson" data={poligono}>
                        <Layer
                        id={`poligono-${i}`}
                        type="line"
                        source="my-data"
                        layout={{
                            'line-join': 'round',
                            'line-cap': 'round',
                        }}
                        paint={{
                            'line-color': "blue",
                            'line-width': 2,
                        }}
                        />
                    </Source>
            })
       
        
    }

    return <div>
        {mostrarModal()}
        {menuSeleccion()}
        {contextHolderNotificaion}
        <Map
        ref={mapRef}
        {...viewState}
        onMove={evt => setViewState(evt.viewState)}
        style={{width: "100%", height: height ? height : 600}}
        // pitch={25}
        scrollZoom={false}
        // dragPan={false}
        mapStyle={ focusVisualization === "trafico" ? "mapbox://styles/mapbox/navigation-day-v1" : "mapbox://styles/mapbox/streets-v12"}
        // mapStyle="mapbox://styles/mapbox/streets-v9"
        onLoad={handleLoadMap}
        mapboxAccessToken={mapbox_token}
    >
        <FullscreenControl position="bottom-right" />
        <NavigationControl position="bottom-right" />
        {mostrarRuta()}
        {mostrarTrayecto()}
        {mostrarTrayecto2()}
        {
            marcadores.map(marker => {
                const color = marker.tipo === "entrega" ? "blue" : "orange"
                return <Marker
                        key={marker._id}
                        longitude={marker.lng}
                        latitude={marker.lat}
                        onClick={() => showModal(marker)}>
                    <div>
                        {showPin(color)}
                    </div>
                </Marker>
            })
        }
        {mostrarUbicacionConductor()}
    </Map>
    </div>
}

export default MapaViajeDetalle