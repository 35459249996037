import { Button, Col, Divider, Form, Input, Modal, Popconfirm, Row, Select, Table, Tag, Tooltip, message, notification } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import { SaveOutlined } from "@ant-design/icons";
import CargandoTabla from "./cargando";
import Title from "antd/es/typography/Title";
import { fechaUTCATexto, filterOption, formatYMD, formatYMD_UTC } from "../../lib/helpers/helpers";
import { campos_fecha, modulo, requeridos } from "./data";
import { FaTruckFront, FaTruckRampBox } from "react-icons/fa6";
import { FaChevronRight, FaRegTrashAlt } from "react-icons/fa";
import ModalSeleccionVehiculo from "../Vehiculos/modal_seleccion";
import SelectorZonaAvanzadoInternacional from "../RadioOperacion/selector_zona_avanzado_internacional";
import { GiHandTruck } from "react-icons/gi";
import { DateTime } from "luxon";
import { estilo_moving_truck } from "../../lib/estilo_sitio";
import HelperModal from "../../subComponents/general/helperModal";
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado";
import Column from "antd/es/table/Column";
import { calcularVolumen, formatoMoneda, stringByStatus } from "../../lib/helpers/main";
import { stringByPalletType } from "../Vehiculos/data";
import BuscadorLocalidad from "../RadioOperacion/buscadorLocalidad";
import { IoAlertCircleSharp } from "react-icons/io5";
import { IoIosHelpCircle } from "react-icons/io";

const EditarContactoRapido = (props) => {
    const {
        id_registro
    } = props
    const [ campo, setCampo ] = useState(false)
    const pais = useSelector(state => state.pais)
    const [ excludesVehicles, setExcludesVehicles ] = useState([])
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ vehiculos, setVehiculos ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [ firstTimeDeleting, setFirstTimeDeleting ] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ notificationApi, contextHolderNotification] = notification.useNotification();
    const dispatch = useDispatch()
    const module = modulo

    const obtenerVehiculosOcupados = async () => {
        return fetch(`${urlapi}/${module}/availability`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
            } else if(res.ocupated){
                setExcludesVehicles(res.ocupated)
            }
            return setLoading(false)
        })
        .catch(error => setLoading(false))

    }


    const obtenerDatos = async () => {
        if(!id_registro) return messageApi.error("ID no válido")
        setLoading(true)
        return fetch(`${urlapi}/${module}?id=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setCampo({ ...{}, ...res })
                if(res.vehiculos) setVehiculos(res.vehiculos)
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    const guardarCambios = async () => {
        let guardar_campo = {...{}, ...campo}
        let faltantes = []
        requeridos.map(field => {
            if(!guardar_campo[field.value]) faltantes.push(field.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  

        setLoadingSave(true)
        guardar_campo.vehiculos = vehiculos
        
        return fetch(`${urlapi}/${module}`,{
            method:'PUT',
            body: JSON.stringify(guardar_campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                setIsModalOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoadingSave(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
      obtenerVehiculosOcupados()
      obtenerDatos()
    };

    const handleOk = () => {
        return guardarCambios()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

      const getDateKeyFromHourKey = (key) => {
        if(key === "hora_carga_desde") return "fecha_carga_desde"
        if(key === "hora_carga_hasta") return "fecha_carga_hasta"
        if(key === "hora_descarga_desde") return "fecha_descarga_desde"
        if(key === "hora_descarga_hasta") return "fecha_descarga_hasta"
        return false
      }

      const convertDateByMomentDate = (key, val) => {
        if(key === "fecha_carga_desde") return DateTime.fromISO(val).endOf('day').toUTC().toISO()
        if(key === "fecha_carga_hasta") return DateTime.fromISO(val).endOf('day').toUTC().toISO()
        if(key === "fecha_descarga_desde") return DateTime.fromISO(val).endOf('day').toUTC().toISO()
        if(key === "fecha_descarga_hasta") return DateTime.fromISO(val).endOf('day').toUTC().toISO()
      }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            const date_key = getDateKeyFromHourKey(name)
            if(date_key){
                const val_date = actual[date_key]
                if(val_date){
                    if(value){
                        const horario = value.split(':')
                        actual[date_key] = DateTime.fromISO(val_date).set({hour: parseInt(horario[0]), minute: parseInt(horario[1]) }).toUTC().toISO()
                    } else {
                        actual[date_key] = convertDateByMomentDate(date_key, val_date)
                    }
                }
            } else {
                if(campos_fecha.includes(name)){
                    if(value){
                        if(actual[name]){
                            const parsear = DateTime.fromISO(actual[name]).toUTC()
                            const fecha = DateTime.fromISO(value).toUTC().set({ hour: parsear.hour, minute: parsear.minute }).toISO()
                            actual[name] = fecha
                        } else {
                            const nueva_fecha = DateTime.fromISO(value).toUTC()
                            actual[name] = convertDateByMomentDate(nueva_fecha, value)
                        }
                    } else {
                        actual[name] = ''
                    }
                } else {
                    actual[name] = value
                }
            }
            return {...{}, ...actual}
        })
    }


    const handleChangeSelect = (name, value) => {
        campo[name] = value
        return setCampo({...{}, ...campo})
    }

    const mostrarMantenedorFechas = () => {
        if(campo.tipo_disponibilidad === "unica"){

            let carga_desde = ''
            let hora_carga_desde = ''
            let carga_hasta = ''
            let hora_carga_hasta = ''

            let descarga_desde = ''
            let hora_descarga_desde = ''
            let descarga_hasta = ''
            let hora_descarga_hasta = ''

            if(campo.fecha_carga_desde) carga_desde = formatYMD_UTC(campo.fecha_carga_desde)
            if(campo.fecha_carga_desde) hora_carga_desde = DateTime.fromISO(campo.fecha_carga_desde).toFormat('HH:mm')
            if(campo.fecha_carga_hasta) carga_hasta = formatYMD_UTC(campo.fecha_carga_hasta)
            if(campo.fecha_carga_hasta) hora_carga_hasta = DateTime.fromISO(campo.fecha_carga_hasta).toFormat('HH:mm')



            if(campo.fecha_descarga_desde) descarga_desde = formatYMD_UTC(campo.fecha_descarga_desde)
            if(campo.fecha_descarga_desde) hora_descarga_desde = DateTime.fromISO(campo.fecha_descarga_desde).toFormat('HH:mm')
            if(campo.fecha_descarga_hasta) descarga_hasta = formatYMD_UTC(campo.fecha_descarga_hasta)
            if(campo.fecha_descarga_hasta) hora_descarga_hasta = DateTime.fromISO(campo.fecha_descarga_hasta).toFormat('HH:mm')



            return <Col md={24}><Row>
                <Col md={24}>
                        <Title level={3} className="mt-0 mb-2"><GiHandTruck style={{ verticalAlign: "middle" }} /> Carga</Title>
                            <Row gutter={15}>
                                <Col xs={24}>
                                    <Row gutter={15}>
                                        <Col md={12}>
                                        <Form.Item label="Desde" className="mb-3">
                                        {/* <DatePicker placeholder="Seleccionar fecha" type style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={campo.fecha_carga_desde ? dayjs(formatYMD_UTC(campo.fecha_carga_desde), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(e,'fecha_carga_desde')} /> */}
                                        <Input type="date" name="fecha_carga_desde" defaultValue={carga_desde} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                        <Col md={12}>
                                        <Form.Item label="Hora" className="mb-3">
                                        <Input type="time" style={{ width: "100%"}} name="hora_carga_desde" defaultValue={hora_carga_desde} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                    </Row>
                                    
                                    
                                </Col>
                                <Col xs={24}>
                                    <Row gutter={15}>
                                        <Col md={12}>
                                        <Form.Item label="Hasta" className="mb-3">
                                        <Input type="date" name="fecha_carga_hasta" defaultValue={carga_hasta} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                        <Col md={12}>
                                        <Form.Item label="Hora" className="mb-3">
                                        <Input type="time" style={{ width: "100%"}} name="hora_carga_hasta" defaultValue={hora_carga_hasta} onChange={handleChange} />
                                        </Form.Item>
                                        </Col>
                                    </Row>
                                   
                                    
                                </Col>
                            </Row>
                    </Col>
                    <Col md={24}>
                        <Title level={3} className="mt-0 mb-2"><FaTruckRampBox style={{ verticalAlign: "middle" }} /> Descarga</Title>
                            <Row gutter={15}>
                                <Col xs={24}>
                                    <Row gutter={15}>
                                    <Col md={12}>
                                    <Form.Item label="Desde" className="mb-3">
                                    {/* <DatePicker placeholder="Seleccionar fecha" type style={{ width: "100%"}} format="YYYY-MM-DD" locale={locale} defaultValue={campo.fecha_carga_desde ? dayjs(formatYMD_UTC(campo.fecha_carga_desde), 'YYYY-MM-DD') : ""}  onChange={(e) => handleChangeDate(e,'fecha_carga_desde')} /> */}
                                    <Input type="date" name="fecha_descarga_desde" defaultValue={descarga_desde} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                    <Form.Item label="Hora" className="mb-3">
                                    <Input type="time" style={{ width: "100%"}} name="hora_descarga_desde" defaultValue={hora_descarga_desde} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                    </Row>
                                </Col>
                                <Col xs={24}>
                                <Row gutter={15}>
                                    <Col md={12}>
                                    <Form.Item label="Hasta" className="mb-3">
                                    <Input type="date" name="fecha_descarga_hasta" defaultValue={descarga_hasta} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                    <Form.Item label="Hora" className="mb-3">
                                    <Input type="time" style={{ width: "100%"}} name="hora_descarga_hasta" defaultValue={hora_descarga_hasta} onChange={handleChange} />
                                    </Form.Item>
                                    </Col>
                                </Row>
                                </Col>
                            </Row>
                    </Col>
            </Row>
            </Col>
        } else if (campo.tipo_disponibilidad === "recurrente"){
            return <Col md={24}>
                <Form.Item label="Vencimiento" className="mb-2">
                <Input type="date" name="vencimiento" defaultValue={formatYMD_UTC(campo.vencimiento)} onChange={handleChange} />
                </Form.Item>
                </Col>
        }
    }

    const removerZona = (key, i) => {
        setCampo(prev => {
            let actual = {...prev}
            actual[key].splice(i,1)
            return actual
        })
    }

    const mostrarUbicaciones = (key) => {
        if(!campo[key]) return false
        if(campo[key].length < 1) return false
        return <div>
            {
                campo[key].map((ubi,i) => <Title level={4} key={`ubi-${i}`} className="mt-0 mb-2"><FaChevronRight style={{ verticalAlign: "middle" }} /> {ubi.titulo} <Button onClick={() => removerZona(key, i)}>ELIMINAR</Button> </Title>)
            }
        </div>
    }

    const agregarUbicacion = (data, key) => {
        console.log({data})
        if(!data) return
        setCampo(prev => {
            let actual = {...prev}
            if(!actual[key]) actual[key] = []
            actual[key] = data
            // const i = actual[key].findIndex(e => e._id === data._id)
            // if(i < 0) actual[key].push({ _id: data._id, titulo: data.titulo, slug: data.slug })
            return actual
        })
    }

    const remover = (i) => {
        if( firstTimeDeleting ) notificationApi.open({
            message: '¡Atención! Toca guardar para aplicar los cambios',
            description: 'Al eliminar un vehículo de una disponibilidad, ya no estará visible en ninguna plataforma para que otros puedan solicitarlo.',
            icon: <IoAlertCircleSharp color="orange" />,
          })
        setFirstTimeDeleting(false)
        setVehiculos(prev => {
            let actual = [...prev]
            actual.splice(i,1)
            return actual
        })
    }
    
    const mostrarVehiculos = () => {
        const key_esquema               = 'VEHICULOS'
        const key_interfaz              = 'tabla_vehiculos'

        const acciones_botones = <Column title="Acciones" fixed="right" ellipsis render={(data, e, iv) => {
            return <div>
                          <Button onClick={() => remover(iv)} size="small" className="m-0" type="dashed" danger>ELIMINAR</Button>
          </div>
        }} />


        const component_patente = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="patente" />} render={(veh) => {
            const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}
            return <div>
                <b className="hover" style={{ textTransform: "uppercase" }}>{data.patente}</b>
            </div>
            }} />

        const component_conductor = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="conductor" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div style={{ textTransform: "uppercase" }} >{data.conductor?.nombres} {data.conductor?.apellido_p} {data.conductor?.apellido_m}</div>
        }} />

        const component_modelo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="modelo" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{data.detalle_modelo ? data.detalle_modelo.toUpperCase() : "SIN INFORMACIÓN"}</div>
        }} />

        const component_marca = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="marca" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{data.detalle_marca ? data.detalle_marca.toUpperCase() : "SIN INFORMACIÓN"}</div>
        }} />
        
        const component_reservas = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="reservado" />} render={(veh) => {
            return <div>
            <Tooltip title="Cantidad de solicitudes pendientes de confirmación">
            <Tag color={ veh?.detalle_vehiculo?.reservas ? "red" : "green"}>{formatoMoneda(veh?.detalle_vehiculo?.reservas)} <IoIosHelpCircle size={15} style={{ verticalAlign: "middle" }} /></Tag> 
            </Tooltip>
            </div>
        }} />

        const component_ano = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="ano" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{data.ano}</div>
        }} />

        const component_tipo = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tipo" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{data.tipo ? data.tipo.toUpperCase() : "SIN INFORMACIÓN"}</div>
        }} />
        
        const component_fecha = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="createdAt" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{fechaUTCATexto(data.createdAt)}</div>
        }} />
        
        const component_tipo_motor = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tipo_motor" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{data.detalle_tipo_motor ? data.detalle_tipo_motor : "SIN INFORMACIÓN"}</div>
        }} />
        const component_volumen = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="volumen" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            const vol = calcularVolumen(data, data.unidad_medida)
            return <div><Tag><b style={{ fontWeight: "bold" }}>{vol.unidadVolumen}</b> {vol.volumen.toFixed(2)}</Tag></div>
        }} />
        const component_peso = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="peso" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div><Tag><b style={{ fontWeight: "bold" }}>{data.unidad_medida_peso}</b> {formatoMoneda(data.peso)}</Tag> </div>
        }} />

        const component_capacidad = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="capacidad" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>
                { data.ancho ? <Tag>ANCHO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.ancho}</Tag> : false }
                { data.alto ? <Tag>ALTO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.alto}</Tag> : false }
                { data.largo ? <Tag>LARGO <b style={{ fontWeight: "bold" }}>{data.unidad_medida}</b> {data.largo}</Tag> : false }
                </div>
        }} />
        const component_creador = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="creador" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div style={{ textTransform: "uppercase" }}>{data.creador ? data.creador?.nombres : "Sin información"}</div>
        }} />
        const component_status = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="status" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{stringByStatus(data.status)}</div>
        }} />
        
        const component_tipo_pallet = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="tipo_pallet" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{stringByPalletType(data.tipo_pallet)}</div>
        }} />
        const component_cantidad_pallet = <Column title={<EncabezadoPesonalizado key_esquema={key_esquema} key_interfaz={key_interfaz} slug="cantidad_pallet" />} render={(veh) => {
                        const data = veh?.detalle_vehiculo ? veh?.detalle_vehiculo : {}

            return <div>{data.cantidad_pallet}</div>
        }} />
        return <div>
            <Divider className="mt-3 mb-3" />

            <Table dataSource={vehiculos} pagination={false} scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="vehiculos" /> }}>
            {component_patente}
            {component_conductor}
            {component_reservas}
            {component_modelo}
            {component_marca}
            {component_ano}
            {component_tipo}
            {component_tipo_pallet}
            {component_cantidad_pallet}
            {component_fecha}
            {component_tipo_motor}
            {component_volumen}
            {component_peso}
            {component_capacidad}
            {component_creador}
            {component_status}
            {acciones_botones}
            </Table>
            {/* {
                vehiculos.map((veh,iv) => {
                    return <div key={`veh-${iv}`}>
                        <Row gutter={15}>
                            <Col md={18}><Title level={5} className="mt-0 mb-2"><FaTruckFront style={{ verticalAlign: "middle" }} /> {veh.identificador}</Title></Col>
                            <Col md={6}><Button size="small" icon={<FaRegTrashAlt />} onClick={() => remover(iv)} >REMOVER</Button> </Col>
                        </Row>
                        { vehiculos.length > 1 ? <Divider className="mt-3 mb-3" /> : false}
                    </div>
                })
            } */}
        </div>
    }
    
    const formulario = () => {
        if(loading) return <CargandoTabla />
        if(!campo) return false
        return <div>
            <Form layout="vertical">
                <Row gutter={15} >
                
                <Col md={12}>
                        <Form.Item label="Título" required className="mb-2">
                        <Input name="titulo" value={campo.titulo} onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item label="Tipo" required className="mb-2">
                        <Select options={[{ value: 'recurrente', label: "Recurrente" },{ value: "unica", label: "Única" }]} showSearch filterOption={filterOption} defaultValue={!campo.tipo_disponibilidad ? "" : campo.tipo_disponibilidad} name="tipo_disponibilidad" onChange={(e) => handleChangeSelect("tipo_disponibilidad", e)} />
                        </Form.Item>
                    </Col>                     
                    <Col md={16}>
                        <Form.Item label="Días de anticipación para reservar" required className="mb-2">
                        <Input type="number" name="anticipacion" value={campo.anticipacion} onChange={handleChange} />
                        </Form.Item>
                    </Col>     
                    
                    <Col md={4}>
                        <Form.Item label="Precio referencial" required className="mb-2">
                        <Input type="number" name="precio_referencial" value={campo.precio_referencial} onChange={handleChange} />
                        </Form.Item>
                    </Col>         
                    <Col md={4}>
                        <Form.Item label="Moneda" required className="mb-2">
                        <Select options={[{ value: 'CLP', label: "CLP" },{ value: "USD", label: "USD" }]} showSearch filterOption={filterOption} defaultValue={!campo.moneda ? "" : campo.moneda} name="moneda" onChange={(e) => handleChangeSelect("moneda", e)} />
                        </Form.Item>
                    </Col>      
                    {mostrarMantenedorFechas()}
                    <Col md={24}>
                        <Divider className="mt-3 mb-3" />
                        <Title level={4} className="mt-0 mb-2">Ubicación de carga</Title>
                        <BuscadorLocalidad defaultValue={campo.ids_origin ? campo.ids_origin.map(e => ({ ...e, value: e._id, label: e.titulo })) : []} onChange={(data) => agregarUbicacion(data,'ids_origin')} />

                        {/* <SelectorZonaAvanzadoInternacional onSelected={(data) => agregarUbicacion(data,'ids_origin')} /> */}
                        {/* {mostrarUbicaciones('ids_origin')} */}
                        <Divider className="mt-3 mb-3" />
                        <Title level={4} className="mt-0 mb-2">Ubicación de descarga</Title>
                        <BuscadorLocalidad defaultValue={campo.ids_destination ? campo.ids_destination.map(e => ({ ...e, value: e._id, label: e.titulo })) : []} onChange={(data) => agregarUbicacion(data,'ids_destination')} />
                        {/* <SelectorZonaAvanzadoInternacional onSelected={(data) => agregarUbicacion(data,'ids_destination')} /> */}
                        {/* {mostrarUbicaciones('ids_destination')} */}
                    </Col>
                    <Col md={24}>
                        <Divider className="mt-3 mb-3" />
                        <Title level={4} className="mt-0 mb-2">Vehículos asociados { vehiculos.length > 0 ? <Button size="small" icon={<FaRegTrashAlt />} onClick={() => setVehiculos([...[], ...[]])} >REMOVER TODO</Button> : false } </Title>
                            <ModalSeleccionVehiculo isMulti={true} showSelection={true} hideSeleccionados={true} exclude={excludesVehicles} onSelect={data => {
                                let valor = data
                                setVehiculos(prev => {
                                    let actual = [ ...prev ]
                                    for( const veh of valor ){
                                        veh.identificador = veh.titulo
                                        const i = actual.findIndex(e => e._id === veh._id || e._id)
                                        if( i < 1 ) actual.push({...veh, detalle_vehiculo: veh }) 
                                    }
                                    return actual
                                })
                            }} />
                        
                        {mostrarVehiculos()}
                    </Col>
                    <Col md={24}>
                        <Button type="primary" loading={loadingSave} onClick={() => guardarCambios()} ><SaveOutlined />GUARDAR CAMBIOS</Button>
                    </Col>
                </Row>
                
            </Form>
        </div>
    }
    
    return <div>
        <Button  className="m-0" size="small" type="dashed" onClick={showModal}>EDITAR</Button>
        <Modal
            width={"70%"}
            title="Editar" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
            footer={[]}
        >
        {formulario()}
      </Modal>
      {contextHolder}
      {contextHolderNotification}
    </div>
}

export default EditarContactoRapido