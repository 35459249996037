import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Button, Card, Col, Divider, Menu, Row } from 'antd';
import { useState } from "react";
import CrearNuevoRegistro from "./crear";
import ButtonGroup from "antd/es/button/button-group";
import ListadoCategorias from "./listado";
import MenuCategorias from "../../subComponents/general/menu_categorias";
import { rutas } from "../../lib/routes/routes";
import CrearFormulario from "./formulario";

const Formularios = (props) => {
    const {
        tipo,
        titulo
    } = props
    const [ condicion_busqueda, setCondicionBusqueda ] = useState({ tipo })
    const titular = titulo ? titulo : "Formularios"

    const render = () => {
        return <div>
            <Card>
            <Title className="mb-2 mt-0">{titular}</Title>
            <CrearFormulario tipo={tipo} onCreate={() => setCondicionBusqueda(prev => ({...{}, ...condicion_busqueda}))} />
            <Divider />
                <ListadoCategorias tipo={tipo} condicion_default={condicion_busqueda} />
            </Card>
        </div>
    }
    
    return <Structure component={render()} />
}

export default Formularios