import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Alert, Breadcrumb, Button, Card, Col, Divider, Form, Input, Layout, Modal, Popconfirm, Row, Select, Spin, Switch, Tabs, Upload, message } from "antd"
import { CameraOutlined, CarFilled, HomeOutlined, InfoCircleFilled, LoadingOutlined, PlusOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { limpiarStringLetrasNumeros, limpiarStringNumeros, siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import { config_s3, maps_key, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import S3FileUpload from 'react-s3';
import CargandoTabla from "./cargando"
import { Link, useParams } from "react-router-dom"
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main"
import { AiFillCar, AiFillMinusCircle, AiOutlineMail } from "react-icons/ai"
import { GrAdd, GrDocumentText } from "react-icons/gr"
import { codigos_area } from "../../lib/internacional"
import { FaRegTrashAlt } from "react-icons/fa";
import ListadoViajes from "../Viajes/listado"
import MapaRutaDetalle from "../Mapas/mapa_ruta_preview"
import { FiUser } from "react-icons/fi"
import CaracteristicasVehiculo from "./requisitos/caracteristicas_vehiculo"
import ModalSeleccionRequisitos from "../Requisitos/modal_seleccion"
import Paragraph from "antd/es/typography/Paragraph"
import { BsTruckFlatbed } from "react-icons/bs"
import SelectorGlobal from "../../subComponents/general/selector"
import { getLabelForVehicle } from "../../lib/helpers/selectores"
import ModalSeleccionEquipos from "../Equipos/modal_seleccion"
import { FaListCheck } from "react-icons/fa6";
import { MdAltRoute, MdOutlinePlace } from "react-icons/md"
import ButtonGroup from "antd/es/button/button-group"
import ModaAsignacionViajes from "../Viajes/modal_asignacion"
import { IoShareSocialOutline } from "react-icons/io5"
import ModalCompartirRuta from "./box_compartir"
import Enlaces from "../EnlacesRuta"

const DetallesRuta = (props) => {
    const {
        id
    } = useParams()
    const [ loading, setLoading ] = useState(false)
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ loadingDefinicion, setLoadingDefinicion ] = useState(false)
    const [ loadingImagen, setLoadingImagen ] = useState(false)
    const [ showModalAsignacion, setShowModalAsignacion ] = useState(false)
    const [ registrosSeleccionados, setRegistrosSeleccionados ] = useState([])
    const [ loadingAcciones, setLoadingAcciones ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ usuario, setUsuario ] = useState(false)
    const dispatch = useDispatch()
    const [ condicion_sub_categoria, setCondicionSubCategoria ] = useState({ tipo: "cliente" })
    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
    const unidades_medida_volumen       = obtenerUnidadesMedidaPorTipoPais("volumen", pais).map(e => e)
    const [ requisitos, setRequisitos ] = useState([])
    const [ caracteristicas, setCaracteristicas ] = useState({})
    const requeridos = [];
    const [ marcadores, setMarcadores ] = useState([])
    const module = 'rutas'

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/${module}?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res){
                setUsuario(res)
                if(res.detalles_conductor){
                    if(typeof res.detalles_conductor === "object"){
                        if(res._id) setConductor(res.detalles_conductor)
                    }
                }

                if(res.requisitos) if(Array.isArray(res.requisitos)) setRequisitos(res.requisitos)
                if(res.caracteristicas) if(typeof res.caracteristicas === "object") setCaracteristicas(res.caracteristicas)

                let markers = []
                if(res.puntos){
                    if(Array.isArray(res.puntos) !== false){
                      for( const orden of res.puntos ){
                        if(orden.location){
                            markers.push({
                                lat: orden.location.latitude,
                                lng: orden.location.longitude,
                                description: orden.description,
                                tipo: orden.tipo,
                            })
                        }
                      }
                    }
                }
                setMarcadores(markers)
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }
    
    const eliminarRecurso = async (forzar) => {
        setLoadingMaster(true)
        return fetch(`${urlapi}/rutas?id=${id}&forzar=${forzar}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Realizado exitosamente")
                return setTimeout(() => {
                    return window.location = rutas.rutas.slug
                }, 300);
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const guardarCambios = (nueva_data) => {
        setTimeout(() => {
            crearConductorNuevo(nueva_data)
        }, 300);
    }
    
    const addRequeriment = (nuevo) => {
        const i = requisitos.findIndex(re => re._id === nuevo._id)
        if(i > -1) return 
        const nuevo_arreglo = [...requisitos, ...[nuevo]]
        setRequisitos(nuevo_arreglo)
        guardarCambios({ requisitos: nuevo_arreglo, caracteristicas })
    } 

    const onSelectRequisito = (data) => {
        addRequeriment(data)
    }

    const removerReq = (id) => {
        const registros = requisitos
        const i = registros.findIndex(re => re._id === id)
        if(i < 0) return 
        registros.splice(i,1)
        return setRequisitos(prev => [...registros, ...[]])
    }

    const visualizarRequisitosPorTipo = (tipo) => {


        const filtrados = requisitos.filter(e => e.tipo === tipo)

        return <div>
            <Row gutter={15}>
            {
                filtrados.map((req,i) => {
                    return <Col md={4} key={`req-${i}`}>
                        <Card size="small">
                        <Title level={5} className="m-0">{req.titulo}</Title>
                        <Paragraph level={5} className="m-0">{req.descripcion}</Paragraph>
                        <Button size="small" type="text" icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} /> } onClick={() => removerReq(req._id)} >ELIMINAR</Button>
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    const visualizarRequisitos = () => {

        const boton = <Button icon={<GrAdd /> } className="mb-3" >AÑADIR NUEVO</Button>

        const items = [
            {
                label: <div style={{ textAlign: "center" }}><AiFillCar style={{ fontSize: 20 }} /> <Title level={4} className="m-0">DOCUMENTACIÓN DEL VEHÍCULO</Title></div>,
                children: <div>
                    <ModalSeleccionRequisitos boton={boton} slug="subir_requisitos_vehiculo" typeView="modal" onSelect={(data) => onSelectRequisito(data)} condicion_busqueda={{ tipo: "vehiculo", status: "active" }} /> 
                    {visualizarRequisitosPorTipo('vehiculo')}
                </div>
            },
            {
                label: <div style={{ textAlign: "center" }}><FiUser style={{ fontSize: 20 }} /> <Title level={4} className="m-0">DOCUMENTACIÓN DEL CONDUCTOR</Title></div>,
                children: <div>
                    <ModalSeleccionRequisitos boton={boton} slug="subir_requisitos_conductor" typeView="modal" onSelect={(data) => onSelectRequisito(data)} condicion_busqueda={{ tipo: "conductor", status: "active" }} /> 
                    {visualizarRequisitosPorTipo('conductor')}
                </div>
            },
            {
                label: <div style={{ textAlign: "center" }}><BsTruckFlatbed style={{ fontSize: 20 }} /> <Title level={4} className="m-0">CARACTERÍSTICA DEL VEHÍCULO</Title></div>,
                children: <div>
                    <Paragraph >Solo los vehículos con las características que selecciones debajo podrán transportas esta carga</Paragraph>
                    <CaracteristicasVehiculo defaultData={caracteristicas} onChange={(data) => {
                        setCaracteristicas(data)
                        guardarCambios({ requisitos, caracteristicas: data })
                    }} />
                    {visualizarRequisitosPorTipo('caracteristica_vehiculo')}
                </div>
            },
        ].map((item,i) => ({ key: (i+1), ...item}))

        return <div>
            <Title level={4} className="mt-0 mb-3"> Requisitos</Title>
            <Tabs defaultActiveKey="1" items={items} />
        </div>
    }

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        if(name === "rut"){
            usuario[name] = limpiarStringLetrasNumeros(value)
        } else if(name === "phone"){
            usuario[name] = limpiarStringNumeros(value)
        } else {
            usuario[name] = value
        }
        return setUsuario({...{}, ...usuario})
    }

    const uploadButton = (
        <div>
          {loadingImagen ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Subir foto
          </div>
        </div>
      );

    const handleChangeUsuarioSelect = (name, value) => {
        usuario[name] = value
        return setUsuario(usuario)
    }

    const crearConductorNuevo = async (nueva_data) => {
        
        setLoading(true)

        const guardar_payload = {...usuario, ...nueva_data }
        const url = `${urlapi}/${module}`
        return fetch(url, {
            method: "PUT",
            body: JSON.stringify(guardar_payload),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    const cambiarVehiculoConductor = async () => {
        setLoadingDefinicion(true)
        return fetch(`${urlapi}/vehiculos/definir-seleccionado`, {
            method: 'POST',
            body: JSON.stringify({
              id: usuario._id
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer: ${session.tokenSession}`,
              'country': pais,
              'lang': idioma
            },
          })
            .then((res) => {
              if (res.status === 401) return dispatch(cerrarSesion());
              return res.json();
            })
            .then((res) => {
              if (!res) {
                messageApi.error('Sin datos');
              } else if (res.errorMessage) {
                messageApi.error(res.errorMessage);
              } else if (res._id) {
                usuario.selected = true
                messageApi.success("Realizado exitosamente")
                setUsuario(prev => ({...{}, ...usuario}));
              }
              return setLoadingDefinicion(false);
            })
            .catch((error) => {
              messageApi.error('Error al consultar la información, intente nuevamente');
              return setLoadingDefinicion(false);
            })
      }

    const handleChangeArchivo = async (e) => {

        const archivo = e.file.originFileObj
        setLoadingImagen(true)
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            messageApi.error('No pudimos cargar tu imagen')
            return setLoadingImagen(false)
        }

        usuario.imagen_perfil = data.location
        setUsuario({...{}, ...usuario})
        return setLoadingImagen(false)

        })
        .catch(err => {
            console.log(err)
            setLoadingImagen(false)
            return messageApi.error('No pudimos cargar tu imagen')
        })
    }

    const handleChangeCat = (e) => {
        usuario.id_categoria = e._id
        usuario.id_sub_categoria = ''
        setUsuario(usuario)
        setCondicionSubCategoria(prev => ({...prev, id_categoria: e._id }))
    }

    const handleChangeSubCat = (e) => {
        usuario.id_sub_categoria = e._id
        setUsuario({...{}, ...usuario})
    }

    const handleChangeMovil = (e, pos, name) => {
        let value = e
        if(typeof e === "object") if(e.target) value = e.target.value
        usuario.moviles_adicionales[pos][name] = value
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const removerMovil = (i) => {
        usuario.moviles_adicionales.splice(i,1)
        return setUsuario(prev => ({...{}, ...usuario})) 
    }
    
    const anadirMovilAdicional = () => {
        if(!usuario.moviles_adicionales) usuario.moviles_adicionales = []
        usuario.moviles_adicionales.unshift({
            pais_codigo: '',
            movil: ''
        })
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const anadirEmailAdicional = () => {
        if(!usuario.emails_adicionales) usuario.emails_adicionales = []
        usuario.emails_adicionales.unshift({
            email: ''
        })
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const removerEmail = (i) => {
        usuario.emails_adicionales.splice(i,1)
        return setUsuario(prev => ({...{}, ...usuario})) 
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        usuario.emails_adicionales[pos][name] = value
        usuario.emails_adicionales[pos].valid = validateEmail(value)
        return setUsuario(prev => ({...{}, ...usuario}))
    }

    const mostrarEmailsAdicionales = () => {
        if(!usuario.emails_adicionales) return false
        return <div>
            {
                usuario.emails_adicionales.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-3">
                        <Card size="small" title="Email" className="mb-3" extra={<Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>} >
                                <Form.Item label="" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el email" prefix={<AiOutlineMail />} className='mb-0' status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} />
                                </Form.Item>
                        </Card>
                    </div>
                })
            }
        </div>
    }

    const mostrarMovilesAdicionales = () => {

        const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

        if(!usuario.moviles_adicionales) return false
        return <div>
            {
                usuario.moviles_adicionales.map((field,i) => {
                    return <div key={`movil-${i}`} className="mt-3">
                    <Card size="small" title="Móvil" className="mb-3" extra={<Button type="ghost" onClick={() => removerMovil(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />}>REMOVER</Button>} >
                        <Row gutter={15}>
                            <Col md={8}>
                                <Form.Item label="Código de área" required={true} className="mb-0">
                                <Select style={{ width: "100%" }} showSearch placeholder="Código de area" name="pais_codigo" value={field.pais_codigo} onChange={(e) => handleChangeMovil(e,i,"pais_codigo")} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                                </Form.Item>
                            </Col>
                            <Col md={16}>
                                <Form.Item label="Número" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el número telefónico" className='form-control mb-3' name="movil" value={field.movil} onChange={(e) => handleChangeMovil(e,i,"movil")} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    </div>
                })
            }
        </div>
    }

    const agregarFiltro = (key, value) => {
        return setUsuario(prev => {
            let actual = {...prev}
            if(value){
                if(typeof value === "object"){
                    actual[key] = value._id
                } else {
                    actual[key] = value
                }
            } else {
                delete actual[key]
            }
            return actual
        })
    }

    const mostrarSegmendoPostulacionesActivas = () => {
        if(!usuario) return false
        if(!usuario.postullable) return false

        return <Col md={24} className="mb-3">
            <Card size="small">
                <Title className="mt-0 mb-0"><FaListCheck /> Requisitos</Title>
                {visualizarRequisitos()}
            </Card>
        </Col>
    }

    const modificarViajes = async (viajes) => {
        setLoadingAcciones(true)
        return fetch(`${urlapi}/viajes/masive`,{
            method:'POST',
            body: JSON.stringify({
                viajes
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res){
                messageApi.success("Actualizado exitosamente")
                setTimeout(() => {
                    window.location.reload()
                }, 300);
            }
            return setLoadingAcciones(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingAcciones(false)
        })
    }

    const activarMovimientoRuta = (viajes) => {
        console.log({viajes})
        setShowModalAsignacion(true)
        return setRegistrosSeleccionados(viajes)
    }

    const componentes_accion = [
        {
            label: "REMOVER DE ESTA RUTA",
            action: modificarViajes
        },
        {
            label: "MOVER A UNA RUTA",
            action: activarMovimientoRuta
        }
    ]

    const onSelect = (datos,action) => {
        const i = componentes_accion.findIndex(it => it.label === action)
        if(i > -1){
            const funcion = componentes_accion[i].action
            funcion(datos.map(vi => ({ _id: vi._id, payload: { id_ruta: "" } })))
        }
    }

    const mostrarEstados = () => {
        if(!usuario.estados) return false
        if(!Array.isArray(usuario.estados)) return false
        if(usuario.estados.lenght < 1) return false
        return <div>
            <Row gutter={15}>
                {
                    usuario.estados.map((estado,ie) => {
                        return <Col md={4} key={`est-ado-${ie}`}>
                            <Card size="small">
                            <Title level={5} className="mb-0 mt-0">{estado.titulo}</Title>
                            <Title level={3} className="mb-0 mt-0">{estado.cantidad}</Title>
                            </Card>
                        </Col>
                    })
                }
            </Row>
        </div>
    }

    const agregarEquipo = (data) => {

        return setUsuario(prev => {

            let actual = {...prev}

            if(!actual.equipos) actual.equipos = []

            for( const equipment of data ){
                if(typeof equipment !== "object") continue
                const i = actual.equipos.findIndex(e => e._id === equipment._id)
                if(i < 0) actual.equipos.unshift(equipment)
            }
            crearConductorNuevo(actual)
            return actual
        })
    }

    const removerEquipo = (i) => {
        return setUsuario(prev => {
            let actual = {...prev}
            actual.equipos.splice(i,1)
            crearConductorNuevo(actual)
            return {...{}, ...actual }
        })
    }

    const visualizarEquiposAsociados = () => {
        if(!usuario.equipos) return false
        if(Array.isArray(usuario.equipos) !== true) return false

        return <div>
            <Row gutter={15} className="mt-3">
            {
                usuario.equipos.map((eq,iq) => {
                    return <Col md={4} key={`equip-${iq}`}>
                        <Card size="small">
                        <Title level={4} className="mt-0 mb-0">{eq.titulo}</Title>
                        <Button size="small" icon={<FaRegTrashAlt />} onClick={() => removerEquipo(iq)} >REMOVER</Button>
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    const render = () => {
        if(loadingMaster) return <Card><CargandoTabla /></Card>
        if(!usuario) return <Card><Title>No hay suficientes datos para mostrar esta pantalla</Title> </Card>

        return <div>
            <Card className="mb-3">
            <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.rutas.slug,
                    title: <span>Rutas</span>,
                },
                {
                    title: 'Detalles de la ruta',
                },
                ]}
            />
            <Title className="mb-2 mt-0">Detalles de la ruta {usuario?.id}</Title>
            <Row gutter={15}>
                <Col md={12}>
                    <Link to={`${rutas.ruteo.slug}/${id}`}><Button icon={<MdAltRoute />} size="middle">RUTEO INTELIGENTE</Button> </Link>
                </Col>
                <Col md={12} style={{ textAlign: "right" }} >
                <ButtonGroup>
                <Popconfirm
                        title="Eliminar definitivamente"
                        description="¿Estás seguro que deseas eliminar este recurso? Esta opción no se puede deshacer"
                        onConfirm={() => eliminarRecurso()}
                        onCancel={() => false}
                        okText="SI"
                        cancelText="NO"
                    >
                    <Button size="small" icon={<FaRegTrashAlt />} >ELIMINAR SOLO RUTA</Button>
                    </Popconfirm>
                <Popconfirm
                        title="Eliminar definitivamente"
                        description="¿Estás seguro que deseas eliminar este recurso? Esta opción no se puede deshacer"
                        onConfirm={() => eliminarRecurso(true)}
                        onCancel={() => false}
                        okText="SI"
                        cancelText="NO"
                    >
                    <Button size="small" type="dashed" danger>ELIMINAR RUTA Y VIAJES</Button>
                    </Popconfirm>
                </ButtonGroup>
                </Col>
            </Row>
            </Card>
            <Layout style={{ padding: 20 }}>
            <Row gutter={20}>
                <Col md={16} style={{ marginBottom: 20 }}>
                    <MapaRutaDetalle height={"100%"} markers={marcadores} />
                </Col>
                <Col md={8}>
                <Card size="small" style={{ marginBottom: 20 }}>
            <Form layout="vertical">
            <Row gutter={10}>
                <Col xs={24}>
                    <Form.Item label="Comentarios" >
                    <Input mb={3} name="comentario" defaultValue={usuario.comentario} onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={24}>
                <SelectorGlobal fields_search={[ "nombres","apellido_m","apellido_p" ]} titulo="Conductor" value={usuario.id_conductor} condicion={{ }} module="conductores" key_label="nombres" key_value="_id" onChange={(data) => agregarFiltro('id_conductor', data)} />
                </Col>
                <Col md={24}>
                <SelectorGlobal titulo="Vehículo" value={usuario.id_vehiculo} labelFunction={getLabelForVehicle} condicion={{ }} module="vehiculos" key_label="patente" key_value="_id" onChange={(data) => agregarFiltro('id_vehiculo', data)} />
                </Col>
                <Col xs={24}>
                <Form.Item label="Acepta postulaciones de terceros">
                <Switch checkedChildren="ACEPTAR" unCheckedChildren="NO ACEPTAR" checked={usuario.postullable === true ? true : false} onChange={(e) => agregarFiltro("postullable", e)} />
                </Form.Item>
                </Col>
            </Row>     
               
            <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => crearConductorNuevo({})} ><SaveOutlined /> GUARDAR CAMBIOS</Button>
            </Form>
            </Card>

                </Col>
                {mostrarSegmendoPostulacionesActivas()}
                <Col md={12} className="mb-3">
                    <Card size="small">
                        <Title className="mt-0 mb-0"><BsTruckFlatbed /> Equipos asociados</Title>
                        <ModalSeleccionEquipos showSelection={true} isStatic={true} onSelect={(data) => agregarEquipo(data)} />
                        {visualizarEquiposAsociados()}
                    </Card>
                </Col>
                <Col md={12} className="mb-3">
                    <Card size="small">
                        <Enlaces default_payload={{ id_ruta: usuario._id }} />
                    </Card>
                </Col>
                <Col md={24} className="mb-3">
                    <Card size="small">
                        <Title className="mt-0 mb-0"><MdOutlinePlace /> Viajes</Title>
                        {mostrarEstados()}
                        <ListadoViajes onSelect={(data,action) => onSelect(data,action)} componentAction={componentes_accion} showSelection={true} condicion_forzed={{ id_ruta: id }} condicion_default={{ id_ruta: id }} />
                    </Card>
                </Col>
            </Row>
            </Layout>
            <Modal  
            footer={[]}
            // title={}
            open={loading} 
            >
                <Spin size="large" />
                <Title level={2} className="mb-0 mt-0">Espera un momento mientras se carga la información</Title>
            </Modal>
            {contextHolder}
            <ModaAsignacionViajes show={showModalAsignacion} registros={registrosSeleccionados} onSuccess={() => window.location.reload()} onCancel={() => {
                setShowModalAsignacion(false)
                return setRegistrosSeleccionados([])
            }} />
        </div>
    }
    
    return <Structure component={render()} />
}

export default DetallesRuta