import Title from "antd/es/typography/Title"
import ListadoConductores from "./listado"
import { AntDesignOutlined, ArrowDownOutlined, MobileOutlined, PlusOutlined } from "@ant-design/icons"
import Paragraph from "antd/es/typography/Paragraph"
import { BsBoxArrowInUpRight } from "react-icons/bs"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import ListadoVehiculos from "./listado"

const { message, Button, Modal, Card, Row, Col, Avatar } = require("antd")
const { useState } = require("react")
const { useDispatch, useSelector } = require("react-redux")

const ModalSeleccionVehiculo = (props) => {
    const {
        style,
        defaultValue,
        showSelection,
        exclude,
        hideSeleccionados,
        isMulti,
        label,
        extraColumns
    } = props
    const [ seleccionado , setSeleccionado ] = useState(defaultValue ? defaultValue : false)
    const columnas_extra = extraColumns ? extraColumns : []
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const titulo = label ? label : "SELECCIONAR VEHÍCULO"
    const estilo = style ? style : {}
    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };

      const removerSeleccion = () => {
        if(props.onRemove) props.onRemove()
        return setSeleccionado(false)
      }
    
    const mostrarSeleccionado = () => {
        if(hideSeleccionados === true) return false
        if(!seleccionado) return false

        return <Card size="small" style={{ marginTop: 20 }} >
            <Row>
                <Col md={20} xs={18}>
                    <Paragraph className="mb-0">Vehiculo seleccionado</Paragraph>
                    <Title level={4} className="mb-0 mt-0">{seleccionado.titulo} <Link target="_blank" to={`${rutas.vehicles.slug}/${seleccionado._id}`}><BsBoxArrowInUpRight /></Link></Title>
                    <Button onClick={() => removerSeleccion()}>REMOVER</Button>
                </Col>
                <Col md={4} xs={6} style={{ textAlign: "right" }}>
                <Avatar
                    src={seleccionado.imagen_perfil}
                    size={{ xs: 80, sm: 70, md: 30, lg: 64, xl: 80, xxl: 100 }}
                    icon={<AntDesignOutlined />}
                />
                </Col>
            </Row>
        </Card>
    }

    const seleccionarRegistro = (data) => {
        if(isMulti === true){
            if(Array.isArray(data)){

                const iterar = data.map(item => { 
                    let objeto = {...item}
                    objeto.titulo = `${item.marca ? item.marca : ""} ${item.modelo ? item.modelo : ""} ${item.patente ? item.patente : ""}`
                    return objeto
                })
                if(props.onSelect) props.onSelect(iterar)
    
            } else {
                let objeto = {...data}
                objeto.titulo = `${data.marca ? data.marca : ""} ${data.modelo ? data.modelo : ""} ${data.patente ? data.patente : ""}`
                if(props.onSelect) props.onSelect([objeto])
            }
        } else {
            if(Array.isArray(data)){

                const iterar = data.map(item => { 
                    let objeto = {...item}
                    objeto.titulo = `${item.marca ? item.marca : ""} ${item.modelo ? item.modelo : ""} ${item.patente ? item.patente : ""}`
                    return objeto
                })
                if(iterar.length > 0) {
                    if(props.onSelect) props.onSelect(iterar[0])
                    setSeleccionado(iterar[0])
                }
    
            } else {
                let objeto = {...data}
                objeto.titulo = `${data.marca ? data.marca : ""} ${data.modelo ? data.modelo : ""} ${data.patente ? data.patente : ""}`
                if(props.onSelect) props.onSelect(objeto)
                setSeleccionado(objeto)
            }
        }

        setIsModalOpen(false)
    }

    return <div>
        <Button style={{ ...estilo }} onClick={showModal} icon={<PlusOutlined />} >{titulo}</Button>
        {mostrarSeleccionado()}
        <Modal width={"80%"} open={isModalOpen} footer={[]} onCancel={handleCancel}>
            <Title level={2} className="mt-0 mb-0"><ArrowDownOutlined /> Selecciona un vehículo</Title>
            <Paragraph>Toca el nombre del vehículo a seleccionar</Paragraph>
            <ListadoVehiculos extra_columns={columnas_extra} exclude={exclude} showSelection={typeof showSelection !== "undefined" ? showSelection : true } typeView="funcion" hideHeader={true} onSelect={data => seleccionarRegistro(data)} />
      </Modal>
    </div>
}

export default ModalSeleccionVehiculo