import { useEffect, useState } from "react"
import { BsLayoutThreeColumns } from "react-icons/bs"
import { Esquema } from "../../lib/esquemas/tablas"
import { useDispatch, useSelector } from "react-redux"
import { Button, Checkbox, Col, Divider, Modal, Row } from "antd"
import Title from "antd/es/typography/Title"
import { modificarInterfaz } from "../../redux/actions/interfaz"
import { BiColumns } from "react-icons/bi"

const PersonalizarColumnas = (props) => {
    const {
        key_interfaz,
        key_esquema
    } = props

    const [ openModal, setOpenModal ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const interfaz = useSelector(state => state.interfaz)
    const dispatch = useDispatch()

    const init = () => {
        const instancia_esquema = JSON.parse( JSON.stringify(interfaz) )
        if(!instancia_esquema[key_interfaz]) instancia_esquema[key_interfaz] = Esquema[key_esquema].map(e => ({...e, active: true }))
        dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
    }

    useEffect(() => {
        init()
    }, [])

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChange = (e, column) => {
        const instancia_esquema = JSON.parse( JSON.stringify(interfaz) )
        if(!instancia_esquema[key_interfaz]) instancia_esquema[key_interfaz] = Esquema[key_esquema]


        const iterar_key = Esquema[key_esquema].map(campo => {
            delete campo.active
            let active = true
            const i = instancia_esquema[key_interfaz].findIndex(el => el.key === campo.key)
            if(i > -1) active = instancia_esquema[key_interfaz][i].active === true
            campo.active = active
            return campo
        })

        const i = iterar_key.findIndex(llave => llave.key === column.key)
        if(i > -1){
            iterar_key[i].active = e.target.checked
        } else {
            iterar_key.push({
                ...column,
                active: e.target.checked
            })
        }
        instancia_esquema[ key_interfaz ] = iterar_key
        return dispatch(modificarInterfaz(instancia_esquema, session.tokenSession))
    }

    const grupos = []

    for ( const item of Esquema[key_esquema] ){
        const {
            group
        } = item
        const i = grupos.findIndex(g => g.titulo === group)
        if(i > -1){
            grupos[i].items.push(item)
        } else {
            grupos.push({
                titulo: group,
                items: [ item ]
            })
        }
    }

    return <div>
        <Button icon={<BiColumns />} size="sm" className='w-100' variant='light' onClick={() => setOpenModal(true)} > COLUMNAS</Button>
        <Modal open={openModal} onCancel={handleClose} footer={[]} >
        <BiColumns size={30} />
        <Title className="m-0" level={3}>Personaliza las columnas que la vista</Title>
            <Row>
                {
                    grupos.map((grupo,ig) => {
                        return <Col xs={24} key={`gr-${ig}`} className="mb-3">
                            <Divider orientation="center" style={{ textTransform: "uppercase" }} >{grupo.titulo}</Divider>
                            <Row>
                            {
                                grupo.items.map((column,i) => {
                                    let checked = false
                                    const pos =  interfaz[key_interfaz]?.findIndex(e => e.key === column.key)
                                    if(pos > -1) checked = interfaz[key_interfaz][pos].active === true ? true : false

                                    return <Col md={8} key={`column-${i}`} className="mb-3">
                                        <Checkbox checked={checked} name={column.key} onChange={(e) => handleChange(e, column)} style={{ textTransform: "uppercase" }}>
                                        {column.label}
                                        </Checkbox>
                                    </Col>
                                })
                            }
                            </Row>
                            {/* { grupos.length === (ig+1) ? false : <Divider /> } */}
                        </Col>
                    })
                }
                
            </Row>        
      </Modal>
    </div>
}

export default PersonalizarColumnas