import { Menu } from "antd";
import { BsMenuDown, BsMenuUp } from "react-icons/bs"
import { Link as RouterLink } from "react-router-dom";

const MenuCategorias = (props) => {
    const {
        menu_categorias,
        menu_sub_categorias,
        default_selected
    } = props

    const items = [
        {
            icon: <BsMenuDown />,
            key: "categorias",
            label: <RouterLink to={`${menu_categorias}`}>CATEGORÍAS</RouterLink>
        },
        {
            icon: <BsMenuUp />,
            key: "sub-categorias",
            label: <RouterLink to={`${menu_sub_categorias}`}>SUB CATEGORÍAS</RouterLink>
        }
    ]

    return <div>
        <Menu selectedKeys={[default_selected]} mode="horizontal" items={items} />
    </div>
}

export default MenuCategorias