import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Button, Card, Col, Divider, Row, Tabs } from 'antd';
import { FileExcelFilled, PlusCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import ListadoZonas from "./listado";
import HelperModal from "../../subComponents/general/helperModal";

const Zonas = (props) => {

    const items = [
        {
          key: '1',
          label: `ACTIVOS`,
          children: <ListadoZonas condicion_default={{ status: "active" }} />,
        }
      ];

    const render = () => {
        return <div>
            <Card>
            <Title className="mb-2 mt-0">Automatizaciones</Title>
            <Row gutter={15} className="mb-3">
              <Col span="auto"><Button style={{ marginRight: 10 }} size="small" type="primary"><Link to={`${rutas.zones.slug}/new`}><PlusCircleFilled /> CREAR NUEVA</Link></Button></Col>
              <Col span="auto"><HelperModal tipo="automatizaciones" style="modal" /></Col>
            </Row>
            
            <ListadoZonas condicion_default={{ status: "active" }} hideHeader={true} />
            </Card>
        </div>
    }
    
    return <Structure component={render()} />
}

export default Zonas