import PersonalizarEtiqueta from "./personalizar_etiqueta"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import { Tabs } from "antd"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import Structure from "../../components/Structure"
import { FaBarcode } from "react-icons/fa"

const Personalizar = (props) => {

    const items = [
        // {
        //     label: "Página de tracking",
        //     action: () => <PersonalizarTracking />
        // },
        // {
        //     label: "Email de estado",
        //     action: () => <PersonalizarEmailEstado />
        // },
        {
            key: "etiquetas",
            label: <div><Title level={4} className="mt-0 mb-0"><FaBarcode style={{ verticalAlign: "middle" }} /> Etiqueta de bulto</Title> </div>,
            children: <PersonalizarEtiqueta />
        }
    ]

    const render = <div>
        <Title level={4} style={{ fontWeight: "bold", color: estilo_moving_truck.colors.primary }}>Personalizar</Title>
        <Paragraph>Selecciona el módulo que deseas personalizar</Paragraph>
        <Tabs items={items} defaultActiveKey="etiquetas"  />
    </div>

    return <Structure component={render} />
}

export default Personalizar