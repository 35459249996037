const stringByStatusBusiness = (status) => {
    switch (status) {

        case "inactive":
            return "INACTIVA"
        case "active":
            return "ACTIVA"
        case "in_process":
            return "EN PROCESO DE APROBACIÓN"
        case "suspended":
            return "SUSPENDIDA TEMPORALMENTE"
        case "pending":
            return "PENDIENTE DE REVISIÓN"
        default:
            return status
    }
}

module.exports = {
    stringByStatusBusiness
}