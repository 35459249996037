import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Button, Card, Col, DatePicker, Form, Row, Select, Table, Tag, message } from 'antd';
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import ListadoViajes from "./listado";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { urlapi } from "../../lib/backend/data";
import HelperModal from "../../subComponents/general/helperModal";
import dayjs from 'dayjs';
import { DateTime } from "luxon";
import GraficoDona from "../../subComponents/facturacion/graficos/dona";
import MapaCalorPuntos from "../Mapas/mapa_calor_puntos";
import Paragraph from "antd/es/typography/Paragraph";
import { formatoMoneda } from "../../lib/helpers/main";
import Column from "antd/es/table/Column";
import { BiStats } from "react-icons/bi";
const { RangePicker } = DatePicker;

// .toFormat('yyyy-MM-dd')

const ViajesReporte = (props) => {
    const initialDate = {
        from: DateTime.now().startOf("day").minus({ days: 8 }),
        to: DateTime.now().endOf("day"),
    }
    const dateFormat = 'YYYY-MM-DD'
    const [ loading, setLoading ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ estados, setEstados ] = useState([])
    const [ equipos, setEquipos ] = useState([])
    const [ catEquipos, setCatEquipos ] = useState([])
    const [ subcatEquipos, setSubCatEquipos ] = useState([])
    const [ marcadores, setMarcadores ] = useState([])
    const [ conductores, setConductores ] = useState([])
    const [ vehiculos, setVehiculos ] = useState([])
    const [ zonas, setZonas ] = useState({})
    const [ dateRange, setDateRange ] = useState(initialDate)
    const [ configVisualizacion, setConfigVisualizacion ] = useState('')
    const [ messageApi, contextHolder] = message.useMessage();
    const idioma                    = useSelector(state => state.idioma)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const [coleccion_ordenes, setColeccionOrdenes] = useState({
        type: 'FeatureCollection',
        features: []
      })
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const module = 'viajes'

    useEffect(() => {
        consultarDatos()
    }, [])

    const consultarDatos = async (condicion_fechas)=>{
        setConfigVisualizacion('')
        let enviar_condicion = condicion_fechas ? condicion_fechas : { createdAt: { $lte: dateRange.to.toUTC().toISO(), $gte: dateRange.from.toUTC().toISO() } }
        setLoading(true)
        return fetch(`${urlapi}/${module}/report`,{
            method:'POST',
            body: JSON.stringify({
                condicion: enviar_condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.data){
                if(res.data.estados) setEstados(res.data.estados)
                setTotal(res.data.total)
                let markers = []

                if(res.data.zonas)                              setZonas(res.data.zonas)
                if(res.data.conductores)                        setConductores(res.data.conductores)
                if(res.data.vehiculos)                          setVehiculos(res.data.vehiculos)
                if(res.data.equipos)                            setEquipos(res.data.equipos)
                if(res.data.categorias_equipos)                 setCatEquipos(res.data.categorias_equipos)
                if(res.data.subcategorias_equipos)              setSubCatEquipos(res.data.subcategorias_equipos)

                const llaves_zonas = Object.keys(res.data.zonas)
                if(llaves_zonas.length > 0) setConfigVisualizacion(llaves_zonas[0])

                if(res.data.paradas){
                    if(Array.isArray(res.data.paradas) !== false){
                        const puntos = res.data.paradas.map(ord => {
                            if (!ord.start_point) return false
                            if (typeof ord.start_point !== "object") return false
                            if (!ord.start_point.type) return false
                            if (!ord.start_point.coordinates) return false
                            if (Array.isArray(ord.start_point.coordinates) !== true) return false
                            if (ord.start_point.coordinates.length < 2) return false
                            if (ord.start_point.coordinates.filter(c => !c).length > 0) return false

                            markers.push({ lat:ord.start_point.coordinates[1], lng: ord.start_point.coordinates[0] })
                            return {
                              type: "Feature",
                              properties: {},
                              geometry: {
                                type: ord.start_point.type,
                                coordinates: ord.start_point.coordinates
                              }
                            }
                          }).filter(p => p)
                
                          const newFeatures = {
                            type: 'FeatureCollection',
                            features: puntos
                          }
                          setColeccionOrdenes({ ...{}, ...newFeatures })
                
                    }
                }
                setMarcadores([...[], ...markers])
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const handleChangeDate = (e) => {
        if(e){
            const from = e[0]['$d']
            const to = e[1]['$d']
            return setDateRange(prev => {
                let actual = {...prev}
                actual.from = DateTime.fromJSDate(from).startOf("day")
                actual.to = DateTime.fromJSDate(to).endOf("day")

                const condicion = { createdAt: { $lte: actual.to.toUTC().toISO(), $gte: actual.from.toUTC().toISO() } }
                consultarDatos(condicion)
                return actual
            })
        }
    }

    const mostrarVehiculos = () => {

        
        return <div>
            <Card size="small">
                <Row gutter={15}>
                    <Col xs={6}>
                        <GraficoDona datos={vehiculos} />
                    </Col>
                    <Col xs={18}>
                    <Title level={2} className="mt-0 mb-0">Vehículos utilizados</Title>
                    <Tag>Se muestra la cantidad de vehículos asignados por conductor</Tag>
                    <Table showHeader={false} size="small" dataSource={vehiculos} pagination={false} >
                    <Column title="Nombre" render={(data) => {
                            return <div style={{ textTransform: "uppercase" }}>{data.titulo}</div>
                    }} />
                    <Column title="Cantidad" render={(data) => {
                            return <div style={{ textTransform: "uppercase" }}>{formatoMoneda(data.total)}</div>
                    }} />
                    </Table>
                    </Col>
                </Row>
            </Card>
        </div>
    }
    const mostrarConductores = () => {

        return <div>
                    <Card size="small">
                <Row gutter={15}>
                    <Col xs={6}>
                        <GraficoDona titulo="Cantidad de operaciones" datos={conductores} />
                    </Col>
                    <Col xs={18}>
                        <Title level={2} className="mt-0 mb-0">Conductores gestionados</Title>
                        <Tag>Se muestra la cantidad de viajes asignados por conductor</Tag>
                    <Table showHeader={false}   size="small" dataSource={conductores} pagination={false} >
                    <Column title="Nombre" render={(data) => {
                            return <div style={{ textTransform: "uppercase" }}>{data.titulo}</div>
                    }} />
                    <Column title="Cantidad" render={(data) => {
                            return <div style={{ textTransform: "uppercase" }}>{formatoMoneda(data.total)}</div>
                    }} />
                    </Table>
                    </Col>
                </Row>
                    </Card>
        </div>
    }
    
    const mostrarEquipos = () => {

        return <div>
                    <Card size="small">
                <Row gutter={15}>
                    <Col xs={6}>
                        <GraficoDona titulo="Cantidad de operaciones" datos={equipos} />
                    </Col>
                    <Col xs={18}>
                        <Title level={2} className="mt-0 mb-0">Equipos utilizados</Title>
                        <Tag>Se muestra la cantidad de viajes asignados por equipo</Tag>
                    <Table showHeader={false}   size="small" dataSource={equipos} pagination={false} >
                    <Column title="Nombre" render={(data) => {
                            return <div style={{ textTransform: "uppercase" }}>{data.titulo}</div>
                    }} />
                    <Column title="Cantidad" render={(data) => {
                            return <div style={{ textTransform: "uppercase" }}>{formatoMoneda(data.total)}</div>
                    }} />
                    </Table>
                    </Col>
                </Row>
                    </Card>
        </div>
    }
    
    const mostrarCatEquipos = () => {

        return <div>
                    <Card size="small">
                <Row gutter={15}>
                    <Col xs={6}>
                        <GraficoDona titulo="Cantidad de operaciones" datos={catEquipos} />
                    </Col>
                    <Col xs={18}>
                        <Title level={2} className="mt-0 mb-0">Categorías de Equipos utilizados</Title>
                        <Tag>Se muestra la cantidad de viajes asignados por categoría de equipos</Tag>
                    <Table showHeader={false}   size="small" dataSource={catEquipos} pagination={false} >
                    <Column title="Nombre" render={(data) => {
                            return <div style={{ textTransform: "uppercase" }}>{data.titulo}</div>
                    }} />
                    <Column title="Cantidad" render={(data) => {
                            return <div style={{ textTransform: "uppercase" }}>{formatoMoneda(data.total)}</div>
                    }} />
                    </Table>
                    </Col>
                </Row>
                    </Card>
        </div>
    }
    const mostrarSubCatEquipos = () => {

        return <div>
                    <Card size="small">
                <Row gutter={15}>
                    <Col xs={6}>
                        <GraficoDona titulo="Cantidad de operaciones" datos={subcatEquipos} />
                    </Col>
                    <Col xs={18}>
                        <Title level={2} className="mt-0 mb-0">Sub categorías de Equipos utilizados</Title>
                        <Tag>Se muestra la cantidad de viajes asignados por sub categoría de equipos</Tag>
                    <Table showHeader={false}   size="small" dataSource={subcatEquipos} pagination={false} >
                    <Column title="Nombre" render={(data) => {
                            return <div style={{ textTransform: "uppercase" }}>{data.titulo}</div>
                    }} />
                    <Column title="Cantidad" render={(data) => {
                            return <div style={{ textTransform: "uppercase" }}>{formatoMoneda(data.total)}</div>
                    }} />
                    </Table>
                    </Col>
                </Row>
                    </Card>
        </div>
    }

    const mostrarReportePorZona = () => {
        if(!zonas) return false
        if(typeof zonas !== "object") return false

        const opciones = Object.keys(zonas).map(key => {
            return {
                value: key,
                label: zonas[key]?.titulo
            }
        })

        let registros = configVisualizacion ? zonas[configVisualizacion].data : []

        return <div>
            <Form layout="vertical">
                <Form.Item label="Tipo de búsqueda">
                <Select defaultValue={configVisualizacion} options={opciones} onChange={(e) => setConfigVisualizacion(e) } />
                </Form.Item>
            </Form>
            <Table size="small" dataSource={registros} pagination={false} >
                <Column title="Nombre" render={(data) => {
                        return <div style={{ textTransform: "uppercase" }}>{data.name}</div>
                }} />
                <Column title="Cantidad" render={(data) => {
                        return <div style={{ textTransform: "uppercase" }}>{formatoMoneda(data.total)}</div>
                }} />
            </Table>
        </div>
    }

    const LoadingScreen = (props) => {
        return <div>
            <Title className="mt-0 mb-0">Cargando reporte</Title>
        </div>
    }

    const mostrarDatos = () => {
        if(loading) return <LoadingScreen />

        return <div>
            <Title level={4} className="mb-0 mt-3">{total} viajes analizados en total</Title>
            <Tag>El sistema puede analizar por solicitud hasta 5.000 registros, tómalo en cuenta al seleccionar las fechas</Tag>
            <Row className="mt-3 mb-3">
                <Col md={24}>
                    <Row gutter={15}>
                        {
                            estados.map((est,ie) => {
                                return <Col md={4} key={`est-${ie}`}>
                                    <Card size="small">
                                    <Title level={5} className="mt-0 mb-0">{est.titulo}</Title>
                                    <Title level={3} className="mt-0 mb-0">{est.total}</Title>
                                    </Card>
                                </Col>
                            })
                        }
                    </Row>
                </Col>
            </Row>
            <Row gutter={15}>
                <Col md={24} className='mb-3'>
                                <Title level={4} className="mt-0 mb-0">Por estado</Title>
                                <Row>
                                    <Col md={4}>
                                    <Title level={5} className='d-block mb-3'>Segmentación</Title>
                                    <GraficoDona datos={estados} />
                                    </Col>
                                    </Row>
                            </Col>
            </Row>
            <Card size="small" >
            <Title level={2} className="mb-0 mt-0">Mapa de calor</Title>
            <Tag className="mb-3">Se muestran los puntos registrados en tus viajes, bien sean de recogida o entrega</Tag>
            <Row gutter={15}>
                            <Col md={18} xs={24}><MapaCalorPuntos height={300} markers={marcadores} coleccion={coleccion_ordenes} /></Col>
                            <Col md={6} xs={24}>
                                {mostrarReportePorZona()}
                            </Col>
            </Row>
            </Card>
            
            <Row gutter={15} className="mt-3">
                <Col md={24} className='mb-3'>{mostrarConductores()}</Col>
                <Col md={24} className='mb-3'>{mostrarVehiculos()}</Col>
                <Col md={24} className='mb-3'>{mostrarEquipos()}</Col>
                <Col md={24} className='mb-3'>{mostrarCatEquipos()}</Col>
                <Col md={24} className='mb-3'>{mostrarSubCatEquipos()}</Col>
            </Row>
            
        </div>
    }

    const render = () => {

        return <div style={{ backgroundColor: "#f3f3f3", padding: 20 }}>
            <Title className="mb-2 mt-0"><BiStats /> Reporte de Viajes</Title>
            <RangePicker
                defaultValue={[dayjs(initialDate.from.toFormat('yyyy-MM-dd'), dateFormat), dayjs(initialDate.to.toFormat('yyyy-MM-dd'), dateFormat)]}
                format={dateFormat}
                onChange={handleChangeDate}
                />
                {mostrarDatos()}
        </div>
    }
    
    return <Structure component={render()} />
}

export default ViajesReporte