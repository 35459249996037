import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Breadcrumb, Button, Card, Col, Form, Input, Row, Select, Switch, Tabs, message } from "antd"
import { HomeOutlined, LockOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { validateEmail } from "../../lib/helpers/helpers"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import { Link, useParams } from "react-router-dom"
import MapaZonasOperacionMantenedor from "../Mapas/operacion/mapa_mantenedor_operacion"

const RadioOperacion = (props) => {
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [credenciales, setCredenciales] = useState({})
    const [messageApi, contextHolder] = message.useMessage();
    const { id } = useParams()
    const dispatch = useDispatch()


    const items = [
        {
          key: "1",
          label: "Radio de operación",
          children: <MapaZonasOperacionMantenedor heigth={"100vh"} tipo_operacion_default="operacion" />
        },
        {
          key: "2",
          label: "Zonas excluidas",
          children: <MapaZonasOperacionMantenedor heigth={"100vh"} redzone={true} tipo_operacion_default="restriccion" />
        }
      ]
  
      
    const render = () => {
        return <div>
            <Card>
            <Breadcrumb
                items={[
                { title: <Link to={rutas.dashboard.slug}><HomeOutlined /></Link> },
                { title: 'Mapa de operación' }
                ]}
            />
            <Title className="mb-2 mt-0">Zonas</Title>
            </Card>
            <Tabs defaultActiveKey='1' items={items} />
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default RadioOperacion