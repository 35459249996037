import { Button, Card, Col, Divider, Form, Input, Modal, Row, Select, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { maps_key, urlapi } from "../../lib/backend/data";
import { PlusCircleFilled } from "@ant-design/icons";
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios";
import AutoComplete from "react-google-autocomplete";
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps";
import SelectorClientes from "../Clientes/selector";
import { modulo } from "./data";
import { AiFillMinusCircle, AiOutlineMail } from "react-icons/ai";
import { filterOption, validateEmail } from "../../lib/helpers/helpers";
import { codigos_area } from "../../lib/internacional";
import Title from "antd/es/typography/Title";
import { GoDeviceMobile } from "react-icons/go";
import { GrAdd } from "react-icons/gr";

const CrearNuevoCampoPersonalizado = (props) => {
    const {
        default_payload
    } = props
    const [ campo, setCampo ] = useState(default_payload ? default_payload : {})
    const [ showInput, setShowInput ] = useState(false)
    const pais = useSelector(state => state.pais)
    const [ showSelectorCliente, setShowSelectorCliente ] = useState(true)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch()
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const addressRef = useRef(null)

    useEffect(() => {
        if(default_payload) if(typeof default_payload === "object") if(default_payload.id_cliente) setShowSelectorCliente(false)
    }, [])

    const crearCampo = async () => {
        if(!campo.titulo) return messageApi.error("Escribe un título")
        if(campo.titulo.length < 3) return messageApi.error("Escribe un título con más de 3 carácteres")

        if(campo.emails_adicionales){
            if(!Array.isArray(campo.emails_adicionales)) return messageApi.error(`Debe ingresar al menos un email`)
            if(campo.emails_adicionales.length < 1) return messageApi.error(`Debe ingresar al menos un email`)

            if(campo.emails_adicionales) if(campo.emails_adicionales.length > 0){
                const invalidos = campo.emails_adicionales.filter(e => !e.valid || !e.email)
                if(invalidos.length > 0) return messageApi.error(`Emails inválidos: ${invalidos.map(ma => ma.email ? ma.email : "Email vacío").join(', ')}`)  
            }
        }
        
        if(campo.moviles_adicionales) if(campo.moviles_adicionales.length > 0){
            const invalidos = campo.moviles_adicionales.filter(e => !e.pais_codigo ||!e.movil)
            if(invalidos.length > 0) return messageApi.error(`Números de teléfono inválidos: ${invalidos.map(ma => {
                const codigo = ma.pais_codigo ? ma.pais_codigo : "Código area vacío"
                const numero = ma.movil ? ma.movil : "Número vacío"
                return `${codigo} ${numero}`
            }).join(', ')}`)  
        }

        setLoading(true)
        return fetch(`${urlapi}/${modulo}`,{
            method:'POST',
            body: JSON.stringify(campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setKeyInputFilter(key_input_filter + 1)
                messageApi.success("Creado exitosamente")
                setIsModalOpen(false)
                setCampo({...{}, ...{}})
                setShowInput(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
        return crearCampo()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setShowInput(false)
        setKeyInputFilter(key_input_filter + 1)
        return setCampo({...{}, ...{}})
      };

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCampo(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }

    const handleChangeCustom = (value,name) => {
        campo[name] = value
        console.log(campo)
        return setCampo(campo)
    }

    const handleChangeMovil = (e, pos, name) => {
        let value = e
        if(typeof e === "object") if(e.target) value = e.target.value
        campo.moviles_adicionales[pos][name] = value
        return setCampo(prev => ({...{}, ...campo}))
    }

    const removerMovil = (i) => {
        campo.moviles_adicionales.splice(i,1)
        return setCampo(prev => ({...{}, ...campo})) 
    }
    
    const anadirMovilAdicional = () => {
        if(!campo.moviles_adicionales) campo.moviles_adicionales = []
        campo.moviles_adicionales.unshift({
            pais_codigo: '',
            movil: ''
        })
        return setCampo(prev => ({...{}, ...campo}))
    }

    const anadirEmailAdicional = () => {
        if(!campo.emails_adicionales) campo.emails_adicionales = []
        campo.emails_adicionales.unshift({
            email: ''
        })
        return setCampo(prev => ({...{}, ...campo}))
    }

    const removerEmail = (i) => {
        campo.emails_adicionales.splice(i,1)
        return setCampo(prev => ({...{}, ...campo})) 
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        campo.emails_adicionales[pos][name] = value
        campo.emails_adicionales[pos].valid = validateEmail(value)
        return setCampo(prev => ({...{}, ...campo}))
    }

    const mostrarEmailsAdicionales = () => {
        if(!campo.emails_adicionales) return false
        return <div>
            {
                campo.emails_adicionales.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-3">
                        <Card size="small" title="Email" className="mb-3" extra={<Button type="ghost" onClick={() => removerEmail(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>} >
                                <Form.Item label="" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el email" prefix={<AiOutlineMail />} className='mb-0' status={field.valid === false ? 'error' : ''} pos={i}  name="email" value={field.email} onChange={handleChangeEmail} />
                                </Form.Item>
                        </Card>
                    </div>
                })
            }
        </div>
    }

    const mostrarMovilesAdicionales = () => {

        if(!campo.moviles_adicionales) return false
        return <div>
            {
                campo.moviles_adicionales.map((field,i) => {
                    return <div key={`movil-${i}`} className="mt-3">
                    <Card size="small" title="Móvil" className="mb-3" extra={<Button type="ghost" onClick={() => removerMovil(i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />}>REMOVER</Button>} >
                        <Row gutter={15}>
                            <Col md={8}>
                                <Form.Item label="Código de área" required={true} className="mb-0">
                                <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={field.pais_codigo} onChange={(e) => handleChangeMovil(e,i,"pais_codigo")} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                                </Form.Item>
                            </Col>
                            <Col md={16}>
                                <Form.Item label="Número" required={true} className="mb-0">
                                <Input placeholder="Escribe aquí el número telefónico" className='form-control mb-3' name="movil" value={field.movil} onChange={(e) => handleChangeMovil(e,i,"movil")} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    </div>
                })
            }
        </div>
    }

    const handleChangeSelect = (name, value) => {
        campo[name] = value
        return setCampo(campo)
    }

    const abrirForm = () => {
        showModal()
        setTimeout(() => {
            setShowInput(true)
        }, 200);
    }
    
    return <div>
        <Button className="mb-3" type="primary" size="small" onClick={() => abrirForm()}><PlusCircleFilled /> CREAR NUEVO</Button>
        <Modal 
            title="Crear nuevo campo personalizado" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <CamposObligatoriosLeyenda />
            <Form layout="vertical">
                <Form.Item label="Título" required>
                    <Input name="titulo" value={campo.titulo} onChange={handleChange} />
                </Form.Item>
                <Title level={4} className="mt-0" ><GoDeviceMobile /> Teléfonos</Title>
                <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirMovilAdicional()} >AÑADIR NUEVO</Button>
                {mostrarMovilesAdicionales()}
                <Divider />
                <Title level={4} className="mt-0" ><AiOutlineMail /> Emails</Title>
                <Button icon={<GrAdd /> } className="mb-3" onClick={() => anadirEmailAdicional()} >AÑADIR NUEVO</Button>
                {mostrarEmailsAdicionales()}
            </Form>
      </Modal>
      {contextHolder}
    </div>
}

export default CrearNuevoCampoPersonalizado