import { debounce } from "@mui/material"
import { Button, Card, Col, Collapse, Form, Input, Layout, message, Row, Select, Spin } from "antd"
import { useCallback, useEffect, useState } from "react"
import { FaPlus, FaRegEnvelope, FaRegUser } from "react-icons/fa6"
import { GoDeviceMobile } from "react-icons/go"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/sesion"
import { codigos_area } from "../../lib/internacional"
import { filterOption } from "../../lib/helpers/helpers"
import { MdOutlinePlace } from "react-icons/md"
import { AiFillMinusCircle } from "react-icons/ai"
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps"
import { FaUserAlt } from "react-icons/fa"
import { urlapi } from "../../lib/backend/data"

const EdicionViaje = (props) => {
    const {
        viaje
    } = props
    const [ usuario, setUsuario ] = useState(false)
    const id = usuario?._id
    const session = useSelector(state => state.miusuario)
    const default_parada = { description: "", tipo: "entrega"}
    const [ paradas, setParadas ] = useState(Array.isArray(viaje.paradas) ? viaje.paradas : [])
    const dispatch = useDispatch()
    const [ loadingAutoSave, setLoadingAutoSave ] = useState(false)
    const [messageApi, contextHolder] = message.useMessage();
    const [ routes, setRoutes ] = useState([])
    const module = 'viajes/new'

    useEffect(() => {
        setUsuario(viaje?.viaje)
        setParadas(Array.isArray(viaje.paradas) ? viaje.paradas : [])
    }, [ viaje?.viaje ])

    const guardarAutomatico = async (trip, detenciones, ruts_) => {
        return false
        const registro = { ...trip }
        if(registro.emails_adicionales) if(registro.emails_adicionales.length > 0){
            registro.emails_adicionales = registro.emails_adicionales.filter(e => e.email)
        }
        
        if(registro.moviles_adicionales) if(registro.moviles_adicionales.length > 0){
            registro.moviles_adicionales = registro.moviles_adicionales.filter(e => e.movil && e.pais_codigo)
        }

        const steps = detenciones ? detenciones.filter(e => e.start_point?.type) : paradas.filter(e => e.start_point?.type)
        // const routes_ = ruts_ ? ruts_ : routes
        setLoadingAutoSave(true)
        const url = `${urlapi}/${module}/autosave`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify({
                viaje: {
                    _id: id,
                    ...registro,
                },
                paradas: steps,
            }),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {

            if(res.origen){
                if(res.origen?._id){
                    setUsuario(prev => {
                        let actual = { ...prev }
                        actual.origen._id = res.origen?._id
                        return actual
                    })
                }
            }

            if(res?.paradas){

                setParadas(prev => {
                    let actual = [...prev]
                    actual = actual.map((stop,i) => {
                        if(!stop._id){
                            const i = res.paradas.findIndex(e => e.description === stop.description)
                            if(i > -1) stop._id = res.paradas[i]._id
                        }
                        return stop
                    })
                    return [ ...actual ]                    
                })
            }

            setLoadingAutoSave(false)
        })
        .catch(error => setLoadingAutoSave(false))
    }

    const guardarAutomaticamente = useCallback(debounce((data, detenciones, ruts_) => guardarAutomatico(data, detenciones, ruts_), 1000), [])

    const handleChangeRemitente = (e) => {
        return setUsuario(prev => {
            let actual = {...prev}
            if(!actual.remitente) actual.remitente = {}
            actual.remitente[e.target.name] = e.target.value
            guardarAutomaticamente(actual)
            return actual
        })
    }

    const handleChangeRemitenteSelect = (e, key) => {
        return setUsuario(prev => {
            let actual = {...prev}
            if(!actual.remitente) actual.remitente = {}
            actual.remitente[key] = e
            guardarAutomaticamente(actual)
            return actual
        })
    }

    const validarRespuestaRutaMapbox = (sugerencias) => {
        let way_points          = []
        let rutas_consultadas   = []
        let errores_mensajes    = []

        for( const res of sugerencias){
            if(res.uuid){
                if(res.code) if(res.code.toString().toUpperCase() === "OK") {
                    if(res.routes) if(Array.isArray(res.routes) !== false) if(res.routes.length > 0){
                        const adherir_uuid = res.routes.map(r => {
                            r.uuid = res.uuid
                            return r
                        })
                        rutas_consultadas = [...rutas_consultadas, ...[ res.routes[0] ] ]
                        way_points = [...way_points, ...res.waypoints]
                    }
                } else {
                    errores_mensajes.push(res.message)
                }
            }
        }
        if(errores_mensajes.length > 0) messageApi.error(errores_mensajes.join(', '))
        // setWayPoints(way_points)
        setRoutes(rutas_consultadas)
        guardarAutomatico(usuario, null, rutas_consultadas)
    }



    const buscarDirecciones = async (origen, puntos) => {
        // setLoadingRoutes(true)
        messageApi.open({
            type: 'loading',
            content: 'Calculando rutas..',
            duration: 0,
        })
        return fetch(`${urlapi}/geodata/direcciones`,{
            method:'POST',
            body: JSON.stringify({
                origen,
                puntos
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                messageApi.error("Sin datos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                validarRespuestaRutaMapbox(res)
            }
            return messageApi.destroy()
        })
        .catch(error => {
            return messageApi.destroy()
        })
    }

    const calcularCaminos = async (origen, puntos) => {
        console.log({ origen, puntos })
        if(!origen?.start_point) return false
        const origin = { location: origen.start_point }
        const destinos = puntos.map(p => ({ location: p?.start_point }))
        if(destinos.filter(e => e.location).length < 1) return false
        buscarDirecciones(origin, destinos)
    }

    const procesarGeoParada = (place,i) => {
        if(!place) return false
                                const geo_data = procesarGeoDatosGeocode(place)

                                const instancia = JSON.parse( JSON.stringify(paradas))
                                const instancia_parada = JSON.parse( JSON.stringify( paradas[i] ))
                                // return console.log({instancia})
                                const payload = {
                                    ...instancia_parada,
                                    tipo: "entrega",
                                    description: place.formatted_address,
                                    location: {
                                        latitude:geo_data.lat,
                                        longitude:geo_data.lng
                                    },
                                    geo_data,
                                    start_point: {
                                        type: "Point",
                                        coordinates: [
                                            geo_data.lng, geo_data.lat
                                        ]
                                    },
                                    details: place
                                }
                                
                                setParadas(prevParadas => {
                                    // Crea una copia del arreglo de paradas
                                    const updatedParadas = [...prevParadas];
                                    // Actualiza el ítem en el índice especificado
                                    updatedParadas[i] = payload;
                                    calcularCaminos(usuario.origen, updatedParadas)
                                    guardarAutomaticamente(usuario, updatedParadas, routes)
                                    return updatedParadas;
                                });
                            
    }


    const agregarContacto = (i) => {
        return setParadas(prev => {
            const actual = [...prev]
            if(!actual[i].contactos) actual[i].contactos = []
            actual[i].contactos.push({})
            return [...[], ...actual]
        })
    }

    const handleChangeSelectParada = (e,name,i) => {
        return setParadas(prev => {
            let actual = [ ...prev ]
            actual[i][name] = e
            return [ ...actual ]
        })
    }

    const handleChangeParada = (e,i) => {
        const { name, value } = e.target
        return setParadas(prev => {
            let actual = [ ...prev ] 
            actual[i][name] = value
            return [ ...actual ]
        })
    }

    const eliminarContacto = (pos_contact, pos_stop) => {
        setParadas(prevParadas => {
                                    // Crea una copia del arreglo de paradas
                                    const updatedParadas = [...prevParadas];
                                    // Actualiza el ítem en el índice especificado
                                    updatedParadas[pos_stop].contactos.splice(pos_contact,1)
                                    guardarAutomaticamente(viaje, updatedParadas)
                                    return updatedParadas;
                                });
    }

    const handleChangeContacto = (e, i_parada, i_contacto) => {
        return setParadas(prev => {
            let actual = [...prev]
            actual[i_parada].contactos[i_contacto][e.target.name] = e.target.value
            guardarAutomaticamente(actual)
            return actual
        })
    }

    const mostrarContactos = (contactos,i) => {
        if(!contactos) return false
        if(Array.isArray(contactos) !== true) return false
        if(contactos.length < 1) return false

        return <div style={{ marginTop:20  }}>
            <h5 level={5} className="mt-0 mb-2"><FaUserAlt /> {contactos.length} Contactos asociados a esta entrega</h5>
            {
                contactos.map((contact,pos) => {
                    return <Card size="small" key={`contacto_${pos}`} className="mb-3" title={`${contact.nombres ? contact.nombres : ""} ${contact.apellidos ? contact.apellidos : ''}`} extra={<Button type="ghost" onClick={() => eliminarContacto(pos,i)} icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} />} >REMOVER</Button>} >
                        <Form layout="vertical">
                            <Row gutter={15}>
                                <Col md={4}>
                                <Form.Item label="Nombres" required={true} className="mb-0">
                                    <Input placeholder="Nombres del contacto" className='mb-3' name="nombres" value={contact.nombres} onChange={(e) => handleChangeContacto(e, i, pos)} />
                                </Form.Item>
                                </Col>
                                <Col md={4}>
                                <Form.Item label="Apellidos"  className="mb-0">
                                    <Input placeholder="Apellidos" className='mb-3' name="apellidos" value={contact.apellidos} onChange={(e) => handleChangeContacto(e, i, pos)} />
                                </Form.Item>
                                </Col>
                                <Col md={6}>
                                <Form.Item label="Email" className="mb-0">
                                    <Input addonBefore={<FaRegEnvelope />} placeholder="Email" className='mb-3' name="email" value={contact.email} onChange={(e) => handleChangeContacto(e, i, pos)} />
                                </Form.Item>
                                </Col>
                                <Col md={10}>
                                <Form.Item label="Móvil" className="mb-0">
                                <Row gutter={15}>
                                    <Col md={12}>
                                        <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={contact.pais_codigo} 
                                                    filterOption={filterOption}
                                                    options={codigos_area}
                                                />
                                    </Col>
                                    <Col md={12}>
                                        <Input addonBefore={<GoDeviceMobile />} name="movil" value={contact.movil} onChange={(e) => handleChangeContacto(e, i, pos)} />
                                    </Col>
                                </Row>
                                </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                })
            }
        </div>
    }

    const visualizarParadas = () => {

        const steps = paradas.map((stop,i) => ({ ...stop, key: i })).filter(e => e.tipo !== "origen" )

        return <div className="mb-3">
            <h5 level={4} className="mt-0 mb-3">Puntos de entrega</h5>
            {/* <Button onClick={() => addStop()} className="mb-3" icon={<AiOutlinePlus /> } >AÑADIR PUNTO</Button> */}
            <Collapse accordion defaultActiveKey={0} items={steps.map((stop) => {
                const i = stop.key
        let condicion_busqueda = {}
        if(usuario.id_cliente) {
            condicion_busqueda.id_cliente = usuario.id_cliente
        }
        
        const contenido = () => <div key={`stop_${i}`} className="mb-3">
            <Row gutter={15}>
                <Col md={12}>
                <Form.Item label="Dirección" required={true} className="mb-0">
                <Input placeholder="Dirección" disabled className='mb-3' name="notas" value={stop.description} />
                {/* <AutoComplete
                    className="ant-input inputlocation"
                    defaultValue={stop.description}
                    options={{
                        types: ["address"],
                        // cokmponentRestrictions: { country: pais },
                    }}                    
                    apiKey={maps_key}
                    
                    onPlaceSelected={(place) => procesarGeoParada(place,i)}
                    /> */}
                    </Form.Item>
                </Col>
                {/* <Col md={4} xs={24}>
                <Form.Item label="Tipo de parada" className="mb-0" required>
                    <Select
                    value={stop.tipo}
                    options={[{ value: "entrega", label: "Entrega" },{ value:"recogida", label: "Recogida" }]}
                    onChange={(e) => handleChangeSelectParada('tipo',e,i)} 
                    />
                </Form.Item>
                </Col> */}
                <Col md={12} xs={24}>
                <Form.Item label="Observaciones" required={true} className="mb-0">
                    <Input placeholder="Observaciones del a dirección" className='mb-3' name="notas" value={stop.notas} onChange={(e) => handleChangeParada(e,i)} />
                </Form.Item>
                </Col>
                {/* <Col md={6} xs={24}>
                { usuario.id_cliente ? <ModalRelacionadorMiddleware 
                                            condicion={{ type_origin: "empresa", type_destination: "contacto", id_origin: { $in: usuario.id_cliente }  }} 
                                            onSelect={(data) => onSelectContactos(data,i)}
                                        /> : <h5 level={5} className="m-0">Selecciona un cliente para añadir contactos de dicho cliente a este punto</h5> }
                </Col> */}
                <Col md={24} xs={24}>
                <Button onClick={() => agregarContacto(i)} icon={ <FaPlus /> }>AGREGAR CONTACTO</Button>
                {mostrarContactos(stop.contactos, i)}
                </Col>
            </Row>
            
        </div>

        const label = <div>
            <Row>
                <Col md={24}><MdOutlinePlace /> {stop.description ? stop.description : `Punto ${(i+1)}`}</Col>
                {/* <Col md={12} style={{ textAlign:"right" }} >{i ? <Button size="small" type="text" icon={<AiFillMinusCircle style={{ verticalAlign: "middle" }} /> } onClick={() => removerParada(i)} >ELIMINAR</Button> : false}</Col> */}
            </Row>
        </div>

        return {
            key: (i+1),
            label,
            children: contenido()
        }
        })} />
        </div>
    }

    const formularioRemitente = () => {
        return <div>
            <Layout>
            <Card size="small" title={<div><FaRegUser style={{ verticalAlign: "middle" }} /> Datos del remitente</div>} className="mb-3">
            <Form layout="vertical">
            <Row gutter={15}>
                <Col md={4}>
                <Form.Item label="Nombres" required={true} className="mb-0">
                    <Input placeholder="Nombres del contacto" className='mb-3' name="nombres" value={usuario.remitente?.nombres} onChange={handleChangeRemitente} />
                </Form.Item>
                </Col>
                <Col md={4}>
                <Form.Item label="Apellidos"  className="mb-0">
                    <Input placeholder="Apellidos" className='mb-3' name="apellidos" value={usuario.remitente?.apellidos} onChange={handleChangeRemitente} />
                </Form.Item>
                </Col>
                <Col md={6}>
                <Form.Item label="Email" className="mb-0">
                    <Input addonBefore={<FaRegEnvelope />} placeholder="Email" className='mb-3' name="email" value={usuario.remitente?.email} onChange={handleChangeRemitente} />
                </Form.Item>
                </Col>
                <Col md={10}>
                <Form.Item label="Móvil" className="mb-0">
                <Row gutter={15}>
                    <Col md={12}>
                        <Select style={{ width: "100%" }} showSearch placeholder="COD. Área" name="pais_codigo" value={usuario.remitente?.pais_codigo} onChange={(e) => handleChangeRemitenteSelect(e,"pais_codigo")} 
                                    filterOption={filterOption}
                                    options={codigos_area}
                                />
                    </Col>
                    <Col md={12}>
                        <Input addonBefore={<GoDeviceMobile />} name="movil" value={usuario.remitente?.movil} onChange={handleChangeRemitente} />
                    </Col>
                </Row>
                </Form.Item>
                </Col>
            </Row>
        </Form>
            </Card>
        </Layout>
        </div>
    }

    if(!usuario) return false
    return <div>
        <Spin spinning={loadingAutoSave} >
        {formularioRemitente()}
        <Form layout="vertical">
        {visualizarParadas()}
        </Form>
        </Spin>
        {contextHolder}
    </div>

}

export default EdicionViaje