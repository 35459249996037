import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Card, Divider } from 'antd';
import { useState } from "react";
import CrearNuevoCampoPersonalizado from "./crear";
import ButtonGroup from "antd/es/button/button-group";
import ListadoBodegas from "./listado";
import { LuWarehouse } from "react-icons/lu";
import { IoShareSocialOutline } from "react-icons/io5";
import Paragraph from "antd/es/typography/Paragraph";

const Enlaces = (props) => {
    const {
        default_payload,
        id_cliente,
        display_full,
        titulo
    } = props
    let default_cond = {}
    if(id_cliente) default_cond.id_cliente = id_cliente
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(default_cond)

    const render = () => {
        return <div>
        <Title className="mb-2 mt-0"><IoShareSocialOutline /> Compartir ruta</Title>
        <Paragraph>Comparte recursos entre colegas o a externos a tu organización.</Paragraph>
            { titulo ? <Title className="mb-2 mt-0">{titulo}</Title> : false }
            <ButtonGroup>
            <CrearNuevoCampoPersonalizado default_payload={default_payload ? default_payload : {}} onCreate={() => setCondicionBusqueda(prev => ({...{}, ...condicion_busqueda}))} />
            </ButtonGroup>
            <Divider />
            <ListadoBodegas condicion_default={condicion_busqueda} />
        </div>
    }

    if(display_full === true ){

        const renderizar = <div><Card>{render()}</Card></div>

        return <Structure component={renderizar} />
    } else {
        return render()
    }
}

export default Enlaces