import Structure from "../../Structure"
import { Card } from 'antd';
import ListadoEquipos from "./listado";
import ModalSeleccionEquipos from "../modal_seleccion";
import { useState } from "react";

const EquiposRelaciones = (props) => {
    const {
        id_vehiculo
    } = props
    const [ condicion_busqueda, setCondicionBusqueda ] = useState({ id_vehiculo })
    const render = () => {
        return <div>
            <Card>
            <ModalSeleccionEquipos showSelection={true} id_vehiculo={id_vehiculo} onCreate={() => setCondicionBusqueda(prev => ({...{}, ...{}}))} />
            <ListadoEquipos showSelection={true} hideSearch={true} condicion_default={condicion_busqueda} />
            </Card>
        </div>
    }
    
    return render()
    return <Structure component={render()} />
}

export default EquiposRelaciones