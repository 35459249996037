import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Button, Card, Col, Form, Input, Pagination, Row, Table, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { fechaATextoSimple } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { SearchOutlined } from "@ant-design/icons"

const ListadoUsuarios = (props)=> {
    const {
        condicion_default,
        hideSearch
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const trash = props.trash ? props.trash : false
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/usuarios/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerConductores(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination defaultCurrent={pagina} showSizeChanger={false} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }
    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setPagina(1)
            setCondicionBusqueda()
            return obtenerConductores(1, condicion_default ? condicion_default : {})
        }
        const condicion = {
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerConductores(1, condicion);
      };

      const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

    const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit} >
        {/* <Title className="mb-0 mt-0" level={5}>BUSCAR</Title> */}
            <Row gutter={10}>
                <Col md={6}>
                    <Form.Item className="mb-2">
                        <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Busca un usuario`} />
                    </Form.Item>
                </Col>
                <Col md={6}>
                    <Form.Item>
                        <Button  onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                    </Form.Item>
                </Col>
            </Row>
            </Form>
      }

    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />

        return <div>

            <Row>
                <Col md={3}>
                    <Card size="small" className="mb-3">
                        <Paragraph level={3} className="m-0">TOTAL</Paragraph>
                        <Title className="m-0">{total}</Title>
                    </Card>
                </Col>
            </Row>

            {paginacion(data.pagina, total)}
            <Table dataSource={conductores} pagination={false} >
                <Column title="Nombres" render={(data) => {
                    return <div><Link to={`/users/${data._id}`}>{data.nombres}</Link> </div>
                }} />
                <Column title="Apellidos" render={(data) => {
                    return <div>{data.apellidos}</div>
                }} />
                <Column title="Email" render={(data) => {
                    return <div>{data.email}</div>
                }} />
                <Column title="Movil" render={(data) => {
                    return <div>{data.codigo_movil}{data.movil}</div>
                }} />
                <Column title="Fecha de creación" render={(data) => {
                    return <div>{fechaATextoSimple(data.createdAt)}</div>
                }} />
            </Table>
        </div>
    }

    return <div>
        {contextHolder}
        {buscador()}
        {mostrarRegistros()}
    </div>
}
export default ListadoUsuarios