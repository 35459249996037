const { tipos_notificacion_alertas } = require("../../components/GeoAlertas/data")

const tituloPorTipoNotificacion = (titulo) => {
    const objeto = {}
    for( const reg of tipos_notificacion_alertas ){
        objeto[reg.value] = reg
    }
    return objeto[titulo]?.label ? objeto[titulo].label : titulo
}

module.exports = {
    tituloPorTipoNotificacion
}