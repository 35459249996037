import { useState, useCallback } from 'react';
import { Button, Modal, QRCode } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { QRCodeSVG } from 'qrcode.react';
import { FaPrint } from 'react-icons/fa';

function useLabelVehicles() {
  const [isOpen, setIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [ vehiculos, setVehiculos ] = useState([])

  const openModal = useCallback((data) => {
    setVehiculos(data)
    setIsOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setModalTitle('');
  }, []);

  const imprimirNaked = (div) => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write('<html><head><title>' + document.title  + '</title><link href="https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap" rel="stylesheet"><meta name="viewport" content="width=device-width, initial-scale=1.0">');
    mywindow.document.write(`<style>#${div} {
        word-wrap: break-word;
        width: 100% !important;
    }
    svg {
      width: 70%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    .page-break {
        page-break-before: always;
    }
    a,span,h1,h2,h3,h4,h5,h6,p,ul,form,input,table,button,label,select{
        font-family: 'Rubik', sans-serif !important;
        margin-bottom: 0px;
      }
        h6, h2, h3, h4, h5, h1, .col, .row, p, table {
        font-size: 24px !important;
        line-height:10px;
        margin-bottom:0px;
        font-weight:400;
    }</style></head><body >`);
    mywindow.document.write(document.getElementById(div).innerHTML);
    mywindow.document.write('</body></html>');
    //mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    setTimeout(() => {
        mywindow.print();
    }, 500);
    setTimeout(() => {
        // mywindow.close()
    }, 1000);
  }


  const RenderModal = useCallback(() => (
    <Modal
      title={"Imprimir códigos"}
      open={isOpen}
      onCancel={closeModal}
      footer={[]}
    //   onOk={closeModal}
    >
      <Paragraph>Imprime los siguientes códigos, desde la aplicación de conductor al escanearlos el vehículo quedará automáticamente asociado al mismo.</Paragraph>
      <Button type="default" icon={<FaPrint/>} style={{ width: "100%", marginBottom: 30 }} onClick={() => imprimirNaked("etiquetas-records")}>IMPRIMIR</Button>
      <div id="etiquetas-records">
      {
        vehiculos.map((veh,i) => {
            return <div key={veh._id} style={{ textAlign: "center" }}>
                <p className='mb-0 mt-0'><b>MOVING TRUCK</b></p>
                <p className='mt-0' style={{ fontSize: 15, marginBottom: 10 }}>Escanea para seleccionar este vehículo en tu app</p>
                <QRCodeSVG value={veh._id} />
                <Title className='mt-0 mb-0' level={4}>{veh.title}</Title>
                { vehiculos.length === (i+1) ? false : <div className='page-break' /> }
            </div>
        })
      }
      </div>
    </Modal>
  ), [isOpen, modalTitle, closeModal]);

  return { openModal, RenderModal };
}

export default useLabelVehicles