const lang = {
    empty_text: {
        es: "Sin datos",
        pt: "não há dados"
    },
    ver_distribucion: {
        es: "Ver distribución",
        pt: ""
    },
    informe_personalizado: {
        es: "Informe personalizado",
        pt: ""
    },
    informe_clasificacion: {
        es: "Informe por clasificación",
        pt: ""
    },
    subir_requisitos_vehiculo: {
        es: "Subir requisito vehículo",
        pt: ""
    },
    subir_requisitos_conductor: {
        es: "Subir requisito conductor",
        pt: ""
    },
    asociar_contactos: {
        es: "Asociar contactos",
        pt: ""
    },
    a_tiempo: {
        es: "A tiempo",
        pt: ""
    },
    retrasadas_entregadas: {
        es: "Retrasadas entregadas",
        pt: ""
    },
    retrasadas_pendientes: {
        es: "Retrasadas pendientes",
        pt: ""
    },
    retrasadas_canceladas: {
        es: "Retrasadas canceladas",
        pt: ""
    },
    retrasadas_devueltas: {
        es: "Retrasadas devueltas",
        pt: ""
    },
    graficos: {
        es: "Vista de gráficos",
        pt: ""
    },
}

module.exports = {
    lang
}