import { useDispatch, useSelector } from "react-redux"
import data, { indexations_types, limit_page_options, urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Alert, Avatar, Button, Card, Col, Form, Input, Pagination, Progress, Row, Select, Table, Tag, Tooltip, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { calcularPorcentaje, fechaATexto, fechaATextoSimple, fechaUTCATexto } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import { FileExcelFilled, QuestionCircleOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons"
import { formatearDNIPorPais, formatearMovilPorPais, nombreDNIPorPais, stringByTipoNotification } from "../../lib/helpers/data/internationa"
import HelperModal from "../../subComponents/general/helperModal"
import { IoMdOpen } from "react-icons/io"
import TagSinDatos from "../../subComponents/general/tag_sin_datos"
import { BsBoxArrowInUpRight } from "react-icons/bs"
import { tipo_zonas, tipos_deteccion_automatizaciones, tipos_rastreo } from "../Zonas/data"
import SelectorFechas from "../../subComponents/general/selector_fechas"
import SelectorGlobal from "../../subComponents/general/selector"
import { getLabelForVehicle } from "../../lib/helpers/selectores"
import ModalSeleccionZonas from "../Zonas/modal_seleccion"
import SortBy from "../../subComponents/general/sort_by"
import { obtenerLimitesPagina } from "../../lib/helpers/main"
import { estilo_moving_truck } from "../../lib/estilo_sitio"

const ListadoAlertas = (props)=> {
    const {
        excludeFilters,
        condicion_default,
        tableSize,
        hideSearch,
        hideHeader,
        typeView
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [filtros, setFiltros] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const excludeFilter = excludeFilters ? excludeFilters : []
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const [ key_input_filter, setKeyInputFilter ] = useState(0)
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const trash = props.trash ? props.trash : false
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const modulo = 'geo-alertas'
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'
    const default_limit_page                = obtenerLimitesPagina(limit_page_options, 'limit_page', interfaz_usuario, modulo)
    const [ key, setKey ] = useState(0)

    const obtenerDatos = async (page, query, limit)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        setCondicionBusqueda(condicion)
        return fetch(`${urlapi}/geodata/alertas/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma,
                limit: limit ? limit : default_limit_page
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
                if(res.filtros) setFiltros(res.filtros)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerDatos(1, false)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerDatos(e, condicion_busqueda)
    }

    const sortBy = (data, type) => {
        if(type === "sort_config"){
            const {
                sort_column,
                sort_order
            } = data
            setPagina(1)
            obtenerDatos(1, null, null, { sort_column, sort_order })
  
        } else if( type === "limit_pagination" ){
            setPagina(1)
            obtenerDatos(1, null, data.limit_page)            
        }
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Row gutter={15} style={{ marginBottom: 20 }}>
            <Col span="auto"><SortBy type={modulo} onChange={(data, type) => sortBy(data, type)} /></Col>
          </Row>
            <Pagination showSizeChanger={false} defaultCurrent={pagina} total={(cantidad*10)} onChange={handleChangePagina} />
        </div>
    }

    const seleccionarRegistro = data => {
        if(props.onSelect) props.onSelect(data)
    }

    const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <b onClick={() => seleccionarRegistro(data)}>{data.nombres} {data.apellido_p ? data.apellido_p : ''}</b>
            default:
                return <Link to={`${rutas.drivers.slug}/${data._id}`}>{data.nombres} {data.apellido_p ? data.apellido_p : ''}</Link>
        }
    }

    const filtrarRegistros = (filtro) => {
        if(!filtro.condicion) return false
        if(typeof filtro.condicion !== "object") return false
        obtenerDatos(1, filtro.condicion)
    }

    const header = () => {
        if(hideHeader === true) return false
        return <Row gutter={15}>
            <Col md={6}>
                <Card size="small" className="mb-3">
                    <Paragraph level={3} style={{ fontSize: 12 }} className="m-0">Total de datos filtrados</Paragraph>
                    <Title className="m-0">{total}</Title>
                </Card>
            </Col>
            {
                filtros.map((filtro,i) => {


                    const porcentaje = calcularPorcentaje(total, filtro.cantidad)
                    return <Col className="hover" md={3} key={`filtro-${i}`} onClick={() => filtrarRegistros(filtro)} >
                    <Card size="small" className="mb-3">
                        <Paragraph level={3} style={{ fontSize: 12 }} className="m-0">{filtro.label}
                        {
                            filtro.help_text ? <Tooltip title={filtro.help_text}>
                           <QuestionCircleOutlined style={{ marginLeft: 5 }} />
                          </Tooltip> : false
                        }
                        </Paragraph>
                        <Row>
                            <Col xs={12}><Title className="m-0">{filtro.cantidad}</Title></Col>
                            <Col xs={12} style={{ textAlign: "right", marginTop:8 }}> <Progress size={35} type="circle" percent={porcentaje} /></Col>
                        </Row>
                    </Card>
                </Col>
                })
            }
        </Row>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/drivers`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };


      const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda) return messageApi.error('Escribe alguna palabra clave');
        const condicion = {
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerDatos(1, condicion);
      };
      
      const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit}>
        <Row gutter={15}>
            <Col md={14}>
                <Form.Item label="Buscar">
                    <Input autoFocus defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Nombre, apellido, email, teléfono o ${nombreDNIPorPais(pais)}`} />
                </Form.Item>
            </Col>
            <Col md={4}>
                <Form.Item>
                    <Button onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                </Form.Item>
            </Col>
        </Row>
               
                </Form>
      }

      const vistaSimple = () => {

        return <div>
            {paginacion(data.pagina, total)}
            {
                conductores.map(noti => {
                    return <Card size="small" className="mb-3" key={noti._id}>
                        <span style={{ fontSize: 10 }}>{fechaUTCATexto(noti.createdAt)}</span>
                        <Alert style={{ fontSize: 11, padding: 10 }} message={stringByTipoNotification(noti.tipo_notificacion)} type={noti.tipo_notificacion} />
                        <Paragraph className="mt-0 mb-0" level={5}>{noti.mensaje}</Paragraph>
                    </Card>
                })
            }
        </div>
      }
    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />
        if(typeView === "simple") return vistaSimple()

        return <div>

            {header()}
            
            {paginacion(data.pagina, total)}
            <Table size={tableSize ? tableSize : "middle"} dataSource={conductores} pagination={false} scroll={estilo_moving_truck.scroll_row} locale={{ emptyText: <HelperModal tipo="alertas" /> }}>
                {/* <Column title="Mensaje" render={(data) => {
                    return <div>{data.mensaje}</div>
                }} /> */}
                <Column title="Ver" render={(marker) => {
                    let lat = null
                    let long = null

                    if(marker.location) if(marker.location?.coordinates) if(marker.location.coordinates.length > 1){
                        lat = marker.location.coordinates[1]
                        long = marker.location.coordinates[0]
                    }

                    if(lat !== null && long !== null){
                        return <div><a href={`https://www.google.com/maps?q=${lat},${long}&entry=ttu`} target='_blank'><BsBoxArrowInUpRight /> VER EN GOOGLE</a></div>
                    }
                    return <div><TagSinDatos /> </div>

                }} />
                <Column title="Detección" render={(data) => {
                    return <div>{data.tipo_deteccion === "entrar" ? "Entrada" : "Salida"}</div>
                }} />
                <Column title="Automatización" render={(data) => {
                    return <div>{data.zona?._id ? <Link target="_blank" to={`${rutas.zones.slug}/${data.zona?._id}`}><IoMdOpen /></Link> : false } {data.zona?.titulo ? data.zona?.titulo : <TagSinDatos />}</div> 
                }} />
                <Column title="Tipo de rastreo" render={(data) => {
                    return <div>{data.zona?.tipo_rastreo ? data.zona?.tipo_rastreo : <TagSinDatos /> }</div> 
                }} />
                <Column title="Conductor" render={(data) => {
                    return <div>{data.conductor?._id ? <Link target="_blank" to={`${rutas.drivers.slug}/${data.conductor?._id}`}><IoMdOpen /></Link> : false } {data.conductor?.nombres ? data.conductor?.nombres : <TagSinDatos />}</div> 
                }} />
                <Column title="Vehículo" render={(data) => {
                    return <div>{data.vehiculo?._id ? <Link target="_blank" to={`${rutas.vehicles.slug}/${data.vehiculo?._id}`}><IoMdOpen /></Link> : false } {data.vehiculo?.titulo ? data.vehiculo?.titulo : <TagSinDatos />}</div> 
                }} />
                <Column title="Tipo de zona" render={(data) => {
                    return <div><Alert message={stringByTipoNotification(data.tipo_notificacion)} type={data.tipo_notificacion} /></div>
                }} />
                <Column title="Fecha de creación" render={(data) => {
                    return <div>{fechaUTCATexto(data.createdAt)}</div>
                }} />
            </Table>
        </div>
    }

    const agregarFiltro = (key, value) => {    
        console.log(value, key)    
        setFiltroBusqueda(prev => {
            let actual = { ...prev }
            if(value.length < 1){
                delete actual[key]
                setCondicionBusqueda(prev => {
                    let current = {...prev}
                    delete current[key]
                    return { ...current }
                })
            } else {
                actual[key] = { $in: value.map(v => v._id) }
            }
            return { ...actual }
        })
      }

    const resetFilters = () => {
        setKeyInputFilter(key_input_filter + 1)
        setFiltroBusqueda({})
        obtenerDatos(1, condicion_default ? condicion_default : {})
        setKey(prev => prev + 1)
    }

    const refrescarBusqueda = () => {
        setPagina(1)
        let cond = { ...condicion_busqueda }
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            }
        })
        obtenerDatos(1, cond)
    }

    const obtenerCondicionDeFiltros = () => {
        let cond = {}
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            } else if(Array.isArray(filtroBusqueda[llave]) !== false){
                    if(filtroBusqueda[llave].length > 0){
                        cond[llave] = { $in: filtroBusqueda[llave] }
                    }
            } else {
                cond[llave] = filtroBusqueda[llave]
            }
            
        })
        return cond
      }

    const actualizarBusqueda = (condicion) => {
        setCondicionBusqueda(prev => {
            let actual = {...prev, ...condicion}
            if(!condicion.createdAt) delete actual.createdAt
            const cond = obtenerCondicionDeFiltros()
            obtenerDatos(1, {...cond, ...actual})
            return {...{}, ...actual}
        
        })
    }

    const showFilter = (slug, component) => {
        if(excludeFilter.includes(slug)) return false
        return component
    }

    const showFilters = () => {
        const mostrarButtonReset = Object.keys(filtroBusqueda).length > 0 ? true : false

        const filter_driver = <Col md={6}>
            <SelectorGlobal key={key_input_filter} fields_search={[ "nombres","apellido_m","apellido_p" ]} titulo="Conductor" condicion={{ }} module="conductores" key_label="nombres" key_value="_id" onChange={(data) => agregarFiltro('id_target', data ? [ data ] : [])} />
        </Col>
        const filtro_conductor = showFilter('conductor', filter_driver)
        const indexacion = !filtro_conductor && condicion_busqueda.id_target ? { query: { type: indexations_types.VEHICLE_DRIVER, id_target: condicion_busqueda.id_target }, key: "id_origin" } : null
        
        return <div>
            
            <SelectorFechas key={key} onChange={(data) => actualizarBusqueda(data)} />
            <Row gutter={15}>
                {filtro_conductor}
                <Col md={6}>
                    <SelectorGlobal key={key_input_filter} titulo="Vehículo" indexarBusqueda={indexacion} labelFunction={getLabelForVehicle} condicion={{ }}  module="vehiculos" key_label="patente" key_value="_id" onChange={(data) => agregarFiltro('id_vehicle', data ? [ data ] : [])} />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Tipo de zona</Title>
                    <Select key={`tipo${key_input_filter}`} style={{ width: "100%" }} allowClear options={tipo_zonas} onChange={(data) => agregarFiltro("tipo_notificacion", data ? [ { _id: data } ] : [])} placeholder="Selecciona" />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Tipo de rastreo</Title>
                    <Select key={`tipo_rastreo_${key_input_filter}`} style={{ width: "100%" }} allowClear options={tipos_rastreo} onChange={(data) => agregarFiltro("tipo_rastreo", data ? [ { _id: data } ] : [])} placeholder="Selecciona" />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Tipo de detección</Title>
                    <Select key={`tipo_rastreo_${key_input_filter}`} style={{ width: "100%" }} allowClear options={tipos_deteccion_automatizaciones} onChange={(data) => agregarFiltro("tipo_deteccion", data ? [ { _id: data } ] : [])} placeholder="Selecciona" />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Automatización</Title>
                    <ModalSeleccionZonas key={key_input_filter} isStatic={true} showSelection={false} onSelect={(data) => agregarFiltro("id_zona", data ? [ { _id: data?._id } ] : [])} />
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Buscar</Title>
                    <Button loading={loadingConductores} onClick={() => refrescarBusqueda()} style={{ width: "100%" }} icon={<SearchOutlined />} >ACTUALIZAR</Button>
                </Col>
                <Col md={6}>
                    <Title className="mb-2 mt-0" level={5}>Limpiar</Title>
                    <Button disabled={!mostrarButtonReset}  style={{ width: "100%" }} onClick={() => resetFilters()}>BORRAR FILTROS</Button>
                </Col>
            </Row>
        </div>
    }

    return <div >
        {contextHolder}
        {showFilters()}
        {mostrarRegistros()}
    </div>
}
export default ListadoAlertas