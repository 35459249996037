import Map, { Marker } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import { Popover, Tooltip } from 'antd'; // Importa Popover y Tooltip de Ant Design
import { estilo_last_mile } from '../../lib/global/styles';
import { RiMapPinTimeFill } from 'react-icons/ri';
import { fechaATexto } from '../../lib/helpers/helpers';
import { rutas } from '../../lib/routes/routes';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';

const MapaEstadosCarga = (props) => {
    const { estados } = props;
    const [marcadores, setMarcadores] = useState([]);
    const sesion = useSelector(state => state.miusuario);
    const pais = useSelector(state => state.pais);
    const defaultViewState = obtenerCentroMapaPorPais(pais);
    const [viewState, setViewState] = useState(defaultViewState);
    const [zoomInit, setZoomInit] = useState(false);
    const mapRef = useRef();

    const ajustarCentro = (puntos) => {
        if (zoomInit) return false;
        if (puntos.length < 1) return false;
        setZoomInit(true);
        const points = puntos.map(marker => turf.point([marker.longitude, marker.latitude]));
        const collection = turf.featureCollection(points);
        const bounds = turf.bbox(collection);

        const newViewport = {
            ...viewState,
            latitude: (bounds[1] + bounds[3]) / 2,
            longitude: (bounds[0] + bounds[2]) / 2
        };

        const options = {
            padding: 30 // Ajusta el valor de padding según tus necesidades
        };

        setViewState(newViewport);
        mapRef.current?.fitBounds(bounds, options);
    };

    useEffect(() => {
        setTimeout(() => {
            const coordenadas = estados.map(e => {
                if (!e.location) return false;
                if (typeof e.location !== "object") return false;
                if (!e.location.coordinates) return false;
                if (!Array.isArray(e.location.coordinates)) return false;
                if (e.location.coordinates.length < 2) return false;
                const lat = e.location.coordinates[0];
                const lng = e.location.coordinates[1];
                return {
                    latitude: lat,
                    longitude: lng
                };
            }).filter(e => e);
            ajustarCentro(coordenadas);
        }, 500);
    }, [estados]);

    return (
        <Map
            ref={mapRef}
            {...viewState}
            onMove={evt => setViewState(evt.viewState)}
            style={{ width: "100%", height: 380 }}
            pitch={25}
            mapStyle="mapbox://styles/mapbox/light-v9"
            mapboxAccessToken={mapbox_token}
        >
            {
                estados.map(e => {
                    if (!e.location) return false;
                    if (typeof e.location !== "object") return false;
                    if (!e.location.coordinates) return false;
                    if (!Array.isArray(e.location.coordinates)) return false;
                    if (e.location.coordinates.length < 2) return false;
                    const lat = e.location.coordinates[0];
                    const lng = e.location.coordinates[1];
                    const color = e.estado_color ? e.estado_color : estilo_last_mile.color_primary;

                    const content = (
                        <div>
                            <h3 style={{ fontSize: 11 }}>{fechaATexto(e.fecha)}</h3>
                            <p>{e.estado} · {e.subestado}</p>
                        </div>
                    );

                    return (
                        <Marker
                            key={e._id}
                            longitude={lng}
                            latitude={lat}
                        >
                            <div>
                                <Popover content={content} title={fechaATexto(e.fecha)}>
                                    <a href={`/${rutas.trips.slug}/${e.idrecurso}`} target='_blank' rel="noopener noreferrer">
                                        <RiMapPinTimeFill style={{ fontSize: 20, color }} />
                                    </a>
                                </Popover>
                            </div>
                        </Marker>
                    );
                })
            }
        </Map>
    );
};

export default MapaEstadosCarga;