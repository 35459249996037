import { Button, Col, Form, Input, Modal, Row, Select, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { maps_key, urlapi } from "../../lib/backend/data";
import AutoComplete from "react-google-autocomplete";
import { SaveOutlined } from "@ant-design/icons";
import CargandoTabla from "./cargando";
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps";
import { AiOutlineMail } from "react-icons/ai";
import Title from "antd/es/typography/Title";
import { GoDeviceMobile } from "react-icons/go";
import { codigos_area } from "../../lib/internacional";
import { filterOption, siEsRequeridoEnEsquema } from "../../lib/helpers/helpers";
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main";
import SelectorGeneral from "../General/selector";
import SelectorClientes from "../Clientes/selector";

const EditarContactoRapido = (props) => {
    const {
        id_registro
    } = props
    const [ campo, setCampo ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ loading, setLoading ] = useState(true)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
    const dispatch = useDispatch()
    const module = 'products'
    const [ condicion_sub_categoria, setCondicionSubCategoria ] = useState({ tipo: module })

    const requeridos = [
        { value: 'descripcion', label: 'Descripción' }
    ];

    const obtenerDatos = async () => {
        if(!id_registro) return messageApi.error("ID no válido")
        setLoading(true)
        return fetch(`${urlapi}/${module}?id=${id_registro}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                setCampo(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            console.log(error.message)
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    const guardarCambios = async () => {
        if(!campo.descripcion) return messageApi.error("Descripción de producto inválida")

        setLoadingSave(true)
        return fetch(`${urlapi}/${module}`,{
            method:'PUT',
            body: JSON.stringify(campo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoading(false)
            } else if(res._id){
                messageApi.success("Actualizado exitosamente")
                setIsModalOpen(false)
                if(props.onCreate) props.onCreate()
            }
            return setLoadingSave(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const showModal = () => {
      setIsModalOpen(true);
      obtenerDatos()
    };

    const handleOk = () => {
        return guardarCambios()
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        return setCampo({...{}, ...{}})
      };

    const handleChangeSelectProducto = (e,name) => {
        console.log(e)
        campo[name] = e
        return setCampo({...{}, ...campo})
    }
    
    const handleChange = (e) => {
        const { name, value } = e.target
        campo[name] = value
        return setCampo({...{}, ...campo})
    }

    const handleChangeCustom = (value,name) => {
        return setCampo(prev => {
            let actual = { ...prev }
            actual[name] = value
            return actual
        })
    }
    const handleChangeCat = (e) => {
        setCampo(prev => {
            const actual = {...prev}
            actual.id_sub_categoria = ''
            actual.id_categoria = e._id
            return actual
        })
        setCondicionSubCategoria(prev => ({...prev, id_categoria: e._id }))
    }

    const handleChangeSubCat = (e) => {
        setCampo(prev => {
            let actual = { ...prev }
            actual.id_sub_categoria = e._id
            return actual
        })
    }


    const formulario = () => {
        if(loading) return <CargandoTabla />

        return <div>
            <Form layout="vertical">
                <Row gutter={15} >
                <Col md={24}>
                <SelectorClientes titulo="Cliente" value={campo.id_cliente} onChange={(data) => handleChangeCustom(data,'id_cliente') } condicion={{}} />
                </Col>
                <Col md={12}>
                    <Form.Item label="Descripción" required={siEsRequeridoEnEsquema('descripcion',requeridos)} className="mb-0">
                    <Input className=' mb-3' name="descripcion"  value={campo.descripcion} onChange={handleChange} />
                    </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="SKU" required={siEsRequeridoEnEsquema('sku',requeridos)} >
                        <Input mb={3}   value={campo.sku} variant="filled" name="sku" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                        <Form.Item label="Cantidad" required={siEsRequeridoEnEsquema('cantidad',requeridos)} >
                        <Input mb={3}   value={campo.cantidad} variant="filled" type="number" name="cantidad" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <SelectorGeneral module="categorias" titulo="Categoría" value={campo.id_categoria} condicion_default={{ tipo: module }} onChange={handleChangeCat} />
                    </Col>
                    <Col md={12} xs={24}>
                        <SelectorGeneral module="sub-categorias" value={campo.id_sub_categoria} titulo="Sub Categoría" condicion_default={condicion_sub_categoria} onChange={handleChangeSubCat} />
                    </Col>
                    <Col md={24} xs={24}>
                        <Form.Item label="Unidad de medida de longitud" required={siEsRequeridoEnEsquema('unidad_medida',requeridos)} >
                            <Select value={campo.unidad_medida} options={unidades_medida.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelectProducto(e,"unidad_medida")} />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Ancho" required={siEsRequeridoEnEsquema('ancho',requeridos)} >
                        <Input addonBefore={campo.unidad_medida} mb={3}   value={campo.ancho} variant="filled" type="number" name="ancho" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Alto" required={siEsRequeridoEnEsquema('alto',requeridos)} >
                        <Input addonBefore={campo.unidad_medida} mb={3}  value={campo.alto}  variant="filled" type="number" name="alto" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={8} xs={24}>
                        <Form.Item label="Largo" required={siEsRequeridoEnEsquema('largo',requeridos)} >
                        <Input addonBefore={campo.unidad_medida} mb={3}   value={campo.largo} variant="filled" type="number" name="largo" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Unidad de medida de peso" required={siEsRequeridoEnEsquema('unidad_medida_peso',requeridos)} >
                            <Select value={campo.unidad_medida_peso} options={unidades_medida_peso.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelectProducto(e,"unidad_medida_peso")} />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item label="Peso" required={siEsRequeridoEnEsquema('peso',requeridos)} >
                        <Input mb={3} addonBefore={campo.unidad_medida_peso} variant="filled" type="number" value={campo.peso}  name="peso" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                        <SelectorGeneral module="categorias" value={campo.id_embalaje} titulo="Tipo de embalaje" condicion_default={{ tipo: "producto-embalaje" }} onChange={(e) => handleChangeSelectProducto(e?._id,'id_embalaje')} />
                    </Col>
                    <Col md={24}>
                        <Button type="primary" loading={loadingSave} onClick={() => guardarCambios()} ><SaveOutlined />GUARDAR CAMBIOS</Button>
                    </Col>
                </Row>
                
            </Form>
        </div>
    }
    
    return <div>
        <Button  className="m-0" size="small" type="dashed" onClick={showModal}>EDITAR</Button>
        <Modal 
            title="Editar" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={loading}
            footer={[]}
        >
        {formulario()}
      </Modal>
      {contextHolder}
    </div>
}

export default EditarContactoRapido