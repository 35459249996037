import { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/sesion";
import { obtenerPrefijoMovil } from "../../lib/helpers/data/moviles";
import { Link } from "react-router-dom";
import { urlapi } from "../../lib/backend/data";
import guardarUsuario from "../../redux/actions/guardarUsuario";
import { validateEmail } from "../../lib/helpers/helpers";
import { Button, Card, Checkbox, Col, Form, Input, Row, message } from "antd";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { paises_codigos_telefonos, paises_sistema } from "../../lib/global/data";
import { gradient_primary } from "../../lib/global/style";
import { cambiarPais } from "../../redux/actions/pais";

const Registrarme = (props) => {
    const [ usuario, setUsuario ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [ showPassword, setShowPassword ] = useState(false)
    const [ terminosCondiciones, setTerminosCondiciones ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [messageApi, contextHolder] = message.useMessage();
    
    const dispatch = useDispatch()

    const onChangeTerminosCondiciones = (e) => {
        const valor = e.target.checked
        setTerminosCondiciones(valor)
    }
    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        usuario[name] = value
        return setUsuario(usuario)
    }

    const registrarme = async () => {
        if(!terminosCondiciones) return messageApi.error("Acepta los términos y condiciones")
        const requeridos = [
            { value:'nombres', label: 'Nombres' },
            { value:'apellidos', label: 'Apellidos' },
            { value:'email', label: 'Email' },
            { value:'codigo_movil', label: 'Código país' },
            { value:'movil', label: 'Móvil' },
            { value:'password', label: 'Contraseña' },
            { value:'repeat_password', label: 'Repetir contraseña' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
        if(!validateEmail(usuario.email)) return messageApi.error(`Email inválido`)  
        if(usuario.password !== usuario.repeat_password) return messageApi.error(`Contraseñas no coinciden`)  
        
        setLoading(true)
        const url = `${urlapi}/auth/register`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(usuario),
            headers: {
                'Content-type': "application/json",
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.data){
                dispatch(cambiarPais(res.data.pais_code))
                dispatch(guardarUsuario(res))
                return window.location = '/'
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    return <div>
        {contextHolder}
    <Row style={{ height: "100vh" }}>
        <Col md={8} style={{ display: "flex", padding: 30, alignItems: "center" }}>
            <Form>
            <Title className='mb-2'>Registrarme</Title>
            <Paragraph>Completar los siguientes datos</Paragraph>
            <Row gutter={10}>
                <Col md={12} xs={24}><Input mb={3} className="mb-3" placeholder="Nombres" name="nombres" onChange={handleChangeUsuario} /></Col>
                <Col md={12} xs={24}><Input mb={3} className="mb-3" placeholder="Apellidos" variant="filled" name="apellidos" onChange={handleChangeUsuario} /></Col>
            </Row>
            <Input mb={3} className="mb-3" variant="filled" placeholder="Email" name="email" onChange={handleChangeUsuario} />
            <Row className="mb-3" gutter={10}>
                <Col xs={10}>
                        <ReactFlagsSelect
                            countries={paises_sistema}
                            customLabels={paises_codigos_telefonos}
                            placeholder="CÓDIGO PAÍS"
                            searchPlaceholder="Buscar"
                            selected={usuario.pais_code}
                            onSelect={value => {
                                const prefijo = obtenerPrefijoMovil(value)
                                usuario.codigo_movil    = prefijo
                                usuario.pais_code       = value
                                console.log(usuario)
                                return setUsuario({...{}, ...usuario})
                            }}
                            />
                </Col>
                <Col xs={14}>
                <Input mb={3} variant="filled" style={{ height: 38 }} name="movil" onChange={handleChangeUsuario}  />
                </Col>
            </Row>        
                <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Contraseña'
                    className="mb-3"
                    name="password"
                    autoComplete="off"
                    onChange={handleChangeUsuario}
                />
                <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Repetir Contraseña'
                    className="mb-3"
                    name="repeat_password"
                    onChange={handleChangeUsuario}
                />
            <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => registrarme()} >REGISTRARME</Button>
            <Checkbox style={{ marginTop: 20 }} onChange={onChangeTerminosCondiciones}>Acepta los <Link to={"/terms-conditions"}>Términos y condiciones</Link> </Checkbox>
            <h4 textAlign="center" mb={3} mt={5}>Ya tengo una cuenta <Link to="/login" style={{ fontWeight: 'bold' }}>Iniciar sesión</Link> </h4>
            </Form>
        </Col>
        <Col md={16} xs={0} style={{ background: gradient_primary, display: "flex", alignItems: "center" }}>
        <img style={{ width: "55%", margin: "0 auto"}} className='hidden-mobile' src="/images/2.png" />
        </Col>
        </Row>
    </div>
}

export default Registrarme