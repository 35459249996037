import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Badge, Breadcrumb, Card, Col, Divider, Layout, Progress, Row, message } from "antd"
import { HomeOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import { Link, useParams } from "react-router-dom"
import CargandoTabla from "./cargando"
import ListadoRequisitos from "../Requisitos/listado"
import { stringByStatusBusiness } from "../../lib/helpers/data/empresas"
import Paragraph from "antd/es/typography/Paragraph"
import { TbAlertCircleFilled, TbSend, TbCircleCheckFilled } from 'react-icons/tb'
import guardarUsuario from "../../redux/actions/guardarUsuario"
import ListadoRequisitosModelos from "../Requisitos/esquema_agregar"

const Empresa = (props) => {
    const [ empresa, setEmpresa ] = useState({})
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch()

    const obtenerUsuario = async () => {
        return fetch(`${urlapi}/empresas/account`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingMaster(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingMaster(false)
            } else if(res._id){
                setEmpresa(res)
                const instancia = JSON.parse( JSON.stringify(session) )
                instancia.data.empresa = res
                dispatch(guardarUsuario(instancia))
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    useEffect(() => {
        obtenerUsuario()
    }, [])

    const render = () => {
        if(loadingMaster) return <Card><CargandoTabla /></Card>
        if(!empresa) return <Card><Title>Sin datos</Title></Card>

        return <div>
            <Card className="mb-3">
            <Breadcrumb
                items={[
                { title: <Link to={rutas.dashboard.slug}><HomeOutlined /></Link> },
                { title: 'Detalles de mi empresa' }
                ]}
            />
            <Title className="mb-2 mt-0">Detalles de mi empresa</Title>

            </Card>

            <Layout style={{ padding: 20 }}>
            <Row gutter={20}>
                <Col md={12}>
                    <Card size="small">
                    <Title level={4} className="mt-0 mb-0">{stringByStatusBusiness(empresa.status)}</Title>
                    <Title level={2} className="mt-0 mb-0">{empresa.razon_social ? empresa.razon_social.toUpperCase() : "SIN INFORMACIÓN"}</Title>
                    <Title level={4} className="mt-0 mb-0">{empresa.rut}</Title>
                    <Paragraph className="mt-0 mb-0">{empresa.direccion}</Paragraph>
                    <Divider />
                    <Title level={4} className="mt-0 mb-0">Estado de avance</Title>
                    <Paragraph className="mt-0">Este es el progreso relacionado a la aprobación de tu empresa para utilizar el 100% de las funciones del sistema</Paragraph>
                    <Row className="mb-3">
                        <Col md={8} style={{ textAlign: "center" }}>
                            <TbSend style={{ fontSize: 15, color: "blue" }} />
                            <Paragraph className="mb-0">REQUISITOS</Paragraph>
                            <Title level={5} className="mt-0">ENVIADOS</Title>
                        <Progress size={100} strokeWidth={10} type="circle" percent={empresa.progreso.porcentaje_progreso} />
                        </Col>
                        <Col md={8} style={{ textAlign: "center" }}>
                            <TbCircleCheckFilled style={{ fontSize: 15, color: "green" }} />
                            <Paragraph className="mb-0">REQUISITOS</Paragraph>
                            <Title level={5} className="mt-0">APROBADOS</Title>
                            <Progress size={100} strokeWidth={10} type="circle" strokeColor={"green"} percent={empresa.progreso.porcentaje_aprobado} />
                        </Col>
                        <Col md={8} style={{ textAlign: "center" }}>
                            <TbAlertCircleFilled style={{ fontSize: 15, color: "red" }} />
                            <Paragraph className="mb-0">REQUISITOS</Paragraph>
                            <Title level={5} className="mt-0">RECHAZADOS</Title>
                            <Progress size={100} strokeWidth={10} type="circle" strokeColor="red" percent={empresa.progreso.porcentaje_rechazados} />
                        </Col>
                    </Row>
                    
                    </Card>
                </Col>
                <Col md={12}>
                    <Card size="small">
                        <Title level={3} className="mt-0 mb-3">Requisitos</Title>
                        <Title level={4} className="mb-3 mt-0">Mis requisitos cargados</Title>
                        <ListadoRequisitos condicion_default={{ id_target: empresa._id }} />
                        <Divider />
                        <Title level={4} className="mb-3 mt-0">Otros requisitos</Title>
                        <ListadoRequisitosModelos id_target={empresa._id} condicion_default={{ tipo: "empresa", status: "active" }} />
                    </Card>
                </Col>
            </Row>

            </Layout>

            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default Empresa