import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Breadcrumb, Button, Card, Col, Divider, Form, Input, Layout, Row, Select, Tag, Upload, message } from "antd"
import { CameraOutlined, HomeOutlined, LoadingOutlined, PlusOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { limpiarStringLetrasNumeros, limpiarStringNumeros, siEsRequeridoEnEsquema, validateEmail } from "../../lib/helpers/helpers"
import { config_s3, urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/sesion"
import { rutas } from "../../lib/routes/routes"
import { nombreDNIPorPais } from "../../lib/helpers/data/internationa"
import S3FileUpload from 'react-s3';
import SelectorMarcas from "./selectores/marca"
import SelectorTipoMotor from "./selectores/tipo_motor"
import SelectorTipo from "./selectores/tipo_vehiculo"
import SelectorColor from "./selectores/color"
import SelectorModelo from "./selectores/modelo"
import ModalSeleccionVehiculos from "../Conductores/modal_seleccion"
import { obtenerUnidadesMedidaPorTipoPais } from "../../lib/helpers/hepler_main"
import CamposObligatoriosLeyenda from "../../subComponents/general/formularios/campos_obligatorios"
import { calcularVolumen } from "../../lib/helpers/main"
import { tamanosCamion, tipos_pallet } from "./data"

const CrearVehiculo = (props) => {
    const [ loading, setLoading ] = useState(false)
    const [ showPassword, setShowPassword ] = useState(false)
    const [ loadingImagen, setLoadingImagen ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const session = useSelector(state => state.miusuario)
    const [messageApi, contextHolder] = message.useMessage();
    const [ usuario, setUsuario ] = useState({})
    const dispatch = useDispatch()
    const unidades_medida               = obtenerUnidadesMedidaPorTipoPais("longitud", pais).map(e => e)
    const unidades_medida_peso          = obtenerUnidadesMedidaPorTipoPais("peso", pais).map(e => e)
    const unidades_medida_volumen       = obtenerUnidadesMedidaPorTipoPais("volumen", pais).map(e => e)

    const requeridos = [
        { value: 'patente', label: 'Patente' },
        { value: 'marca', label: 'Marca' },
        { value: 'ano', label: 'Año' },
        { value: 'id_tipo', label: 'Tipo' },
      ];

    const handleChangeVehiculoCustom = (e,name) => {
        usuario[name] = e?._id
        if(name === "id_tipo") usuario.tipo = e?.tipo
        if(name === "marca") usuario.modelo = ""
        console.log(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const seleccionarConductor = (data) => {
            usuario.id_usuario = data._id
            console.log(usuario)
        return setUsuario({...{}, ...usuario})
    }

    const handleChangeSelect = (e,name) => {
        return setUsuario(prev => {
            const actual = {...prev}
            actual[name] = e
            return actual
        })
    }

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        if(name === "rut"){
            usuario[name] = limpiarStringLetrasNumeros(value)
        } else if(name === "phone"){
            usuario[name] = limpiarStringNumeros(value)
        } else {
            usuario[name] = value
        }
        return setUsuario({...{}, ...usuario})
    }

    const uploadButton = (
        <div>
          {loadingImagen ? <LoadingOutlined /> : <PlusOutlined />}
          <div
            style={{
              marginTop: 8,
            }}
          >
            Subir foto
          </div>
        </div>
      );

    const handleChangeUsuarioSelect = (name, value) => {
        usuario[name] = value
        return setUsuario(usuario)
    }

    const crearConductorNuevo = async () => {
        
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return messageApi.error(`Faltan campos: ${faltantes.join(', ')}`)  
        usuario.autorizado = "si"

        setLoading(true)
        const url = `${urlapi}/vehiculos`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(usuario),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res._id){
                messageApi.success("Creado exitosamente")
                return setTimeout(() => {
                    return window.location = `${rutas.vehicles.slug}/${res._id}`
                }, 1000);
            }
            return setLoading(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setLoading(false)
        })
    }

    

    const handleChangeArchivo = async (e) => {

        const archivo = e.file.originFileObj
        setLoadingImagen(true)
        return S3FileUpload.uploadFile(archivo, config_s3)
        .then( async data => {
            console.log(data)
        if(!data.location) {
            messageApi.error('No pudimos cargar tu imagen')
            return setLoadingImagen(false)
        }

        usuario.imagen_perfil = data.location
        setUsuario({...{}, ...usuario})
        return setLoadingImagen(false)

        })
        .catch(err => {
            console.log(err)
            setLoadingImagen(false)
            return messageApi.error('No pudimos cargar tu imagen')
        })
    }

    const render = () => {

        const calculo_volumen = calcularVolumen(usuario, usuario.unidad_medida )

        return <div>
            <Card className="mb-3">
            <Breadcrumb
                items={[
                {
                    href: rutas.dashboard.slug,
                    title: <HomeOutlined />,
                },
                {
                    href: rutas.vehicles.slug,
                    title: <span>Vehículos</span>,
                },
                {
                    title: 'Crear vehículo',
                },
                ]}
            />
            <Title className="mb-2 mt-0">Crear vehículo</Title>
            </Card>
            <Layout style={{ padding: 20 }}>
            <Row gutter={20}>
                <Col md={24}>
                        <Card size="small">
                    <ModalSeleccionVehiculos showSelection={false} onSelect={data => seleccionarConductor(data)} />
                    <Divider />
            <CamposObligatoriosLeyenda />
            <Form layout="vertical">
            <Row gutter={10}>
                <Col md={8} xs={24}>
                    <Form.Item label="Patente" required={siEsRequeridoEnEsquema('patente',requeridos)} >
                    <Input mb={3} name="patente" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                
                <Col md={8} xs={24}>
                    <Form.Item label="Número de motor" required={siEsRequeridoEnEsquema('numero_motor',requeridos)} >
                    <Input mb={3}  variant="filled" name="numero_motor"  onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Año" required={siEsRequeridoEnEsquema('ano',requeridos)} >
                    <Input mb={3} variant="filled" type="number" name="ano" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Marca" required={siEsRequeridoEnEsquema('marca',requeridos)} >
                        <SelectorMarcas onChange={(e) => handleChangeVehiculoCustom(e,"marca")} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Modelo" required={siEsRequeridoEnEsquema('modelo',requeridos)} >
                        <SelectorModelo filterBy={usuario.marca} value={usuario.modelo} filterByKey="id_marca" onChange={(e) => handleChangeVehiculoCustom(e,"modelo")} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Color" required={siEsRequeridoEnEsquema('color',requeridos)} >
                        <SelectorColor onChange={(e) => handleChangeVehiculoCustom(e,"color")} />
                    </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                    <Form.Item label="Tipo " required={siEsRequeridoEnEsquema('id_tipo',requeridos)} >
                        <SelectorTipo onChange={(e) => handleChangeVehiculoCustom(e,"id_tipo")} />
                    </Form.Item>
                </Col>
                <Col md={5} xs={24}>
                    <Form.Item label="Tipo de motor" required={siEsRequeridoEnEsquema('tipo_motor',requeridos)} >
                        <SelectorTipoMotor onChange={(e) => handleChangeVehiculoCustom(e,"tipo_motor")} />
                    </Form.Item>
                </Col>
                <Col md={5} xs={24}>
                    <Form.Item label="Número de chasis" required={siEsRequeridoEnEsquema('chasis',requeridos)} >
                    <Input mb={3} variant="filled" name="chasis" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={5} xs={24}>
                    <Form.Item label="Tipo de pallet" required={siEsRequeridoEnEsquema('tipo_pallet',requeridos)} >
                    <Select variant="filled" allowClear options={tipos_pallet} onChange={(e) => handleChangeSelect(e,"tipo_pallet")} />
                    </Form.Item>
                </Col>
                <Col md={4} xs={24}>
                <Form.Item label="Cantidad de pallets" required={siEsRequeridoEnEsquema('cantidad_pallet',requeridos)} >
                    <Input mb={3} variant="filled" name="cantidad_pallet" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Tamaño del vehículo" >
                    <Select className="mb-0" name="size" placeholder="Selecciona" style={{ width: "100%" }} options={tamanosCamion} onChange={(e) => handleChangeSelect(e,'size')} allowClear />
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    <Title level={4}>Capacidad de carga</Title>
                    <Form.Item label="Unidad de medida de longitud" required={siEsRequeridoEnEsquema('unidad_medida',requeridos)} >
                        <Select options={unidades_medida.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelect(e,"unidad_medida")} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Ancho" required={siEsRequeridoEnEsquema('ancho',requeridos)} >
                    <Input addonBefore={usuario.unidad_medida} mb={3} variant="filled" type="number" name="ancho" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Alto" required={siEsRequeridoEnEsquema('alto',requeridos)} >
                    <Input addonBefore={usuario.unidad_medida} mb={3} variant="filled" type="number" name="alto" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                    <Form.Item label="Largo" required={siEsRequeridoEnEsquema('largo',requeridos)} >
                    <Input addonBefore={usuario.unidad_medida} mb={3} variant="filled" type="number" name="largo" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                    <Form.Item label="Unidad de medida de peso" required={siEsRequeridoEnEsquema('unidad_medida_peso',requeridos)} >
                        <Select options={unidades_medida_peso.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelect(e,"unidad_medida_peso")} />
                    </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                    <Form.Item label="Capacidad de peso" required={siEsRequeridoEnEsquema('peso',requeridos)} >
                    <Input mb={3} addonBefore={usuario.unidad_medida_peso} variant="filled" type="number" name="peso" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    <Title className="mt-0 mb-0" level={4}>Volumen de carga</Title>
                    <Title className="mt-0 mb-3 " level={4}>{calculo_volumen.volumen.toFixed(2)} <Tag>{calculo_volumen.unidadVolumen}</Tag> </Title>
                </Col>
                {/* <Col md={6} xs={24}>
                    <Form.Item label="Unidad de medida de volumen" required={siEsRequeridoEnEsquema('unidad_medida_volumen',requeridos)} >
                        <Select options={unidades_medida_volumen.map(e => ({ value: e.code, label: e.unit }))} onChange={(e) => handleChangeSelect(e,"unidad_medida_volumen")} />
                    </Form.Item>
                </Col>
                <Col md={6} xs={24}>
                    <Form.Item label="Volumen de carga" required={siEsRequeridoEnEsquema('volumen',requeridos)} >
                    <Input mb={3} addonBefore={usuario.unidad_medida_volumen} variant="filled" type="number" name="volumen" onChange={handleChangeUsuario} />
                    </Form.Item>
                </Col> */}
                
            </Row>     
               
            <Button type="primary" loading={loading} style={{ width: "100%" }} mt={3} colorScheme="green" onClick={() => crearConductorNuevo()} ><SaveOutlined /> CREAR</Button>
            </Form>
            </Card>

                </Col>

            </Row>
            </Layout>
            {contextHolder}
        </div>
    }
    
    return <Structure component={render()} />
}

export default CrearVehiculo