import Paragraph from "antd/es/typography/Paragraph"
import { fechaATexto } from "../../lib/helpers/helpers"
import Title from "antd/es/typography/Title"
import { FaCircle } from "react-icons/fa"
import { canalEstadosString } from "../../lib/helpers/viajes"
import { Card, Col, Divider, Image } from "antd"

const BoxEstado = (props) => {
    const {
        estado
    } = props

    const showComponentByTipoFormulario = (campo) =>{
        switch (campo.tipo_accion) {
          case 'campo-texto':
            if(!campo.valor) return false
            return <div className="text-primary">
              <Title level={5} className='mb-0 mt-0' style={{fontWeight:900}}>{campo.titulo}</Title>
              <Paragraph className='mb-0 mt-0'>{campo.valor ? campo.valor : 'Sin información'}</Paragraph>
            </div>
          case 'selector':
            if(!campo.valor) return false
            return <div className="text-primary">
              <Title level={5} className='mb-0 mt-0' style={{fontWeight:900}}>{campo.titulo}</Title>
              <Paragraph className='mb-0 mt-0'>{campo.valor ? campo.valor : 'Sin información'}</Paragraph>
            </div>
          case 'carga-imagenes':
            if(campo.valores.length < 1) return false
            return <div className="text-primary">
              <Title level={5} className='mb-0 mt-0' style={{fontWeight:900}}>{campo.titulo}</Title>
              
              {
                campo.valores.length > 0 ? campo.valores.map((foto,i) => {
                  return <div md={3} key={`foto-estado-${i}`} style={{ display: "inline-block" }}>
                    <Image src={foto.url} width={100} />
                    </div>
                }) : <Col md={12}><p className='text-danger' style={{fontWeight:700}}><i className="fa-solid fa-triangle-exclamation text-warning"></i> Sin información</p></Col>
              }
            </div>
          default:
            break;
        }
    }

    const mostrarMetaDatosSubEstado = (metadatos) => {
        if(!metadatos) return false
        if(Array.isArray(metadatos) !== true) return false
        if(metadatos.length < 1) return false
  
        return metadatos.map((meta,i) => {
          return <div key={`meta-${i}`}>
            <Divider className="mt-0 mb-2" />
            {showComponentByTipoFormulario(meta)}
          </div>
        })
      }

    if(!estado) return false
    if(typeof estado !== 'object') return false

    let lat = 0
                let lng = 0

                if(estado.location){
                if(Array.isArray(estado.location.coordinates) !== false){
                    if(estado.location.coordinates.length > 1){
                    lat = estado.location.coordinates[0]
                    lng = estado.location.coordinates[1]
                    }
                }
                }

                const label = <div>
                            <Paragraph className="mb-0 mt-0">{fechaATexto(estado.createdAt)}</Paragraph>
                                    <Title level={4} style={{ textTransform: "uppercase" }} className="mb-0 mt-0">{estado.estado}</Title>
                        </div>

                const contenido = <div className="p-0 mx-3 shadow-sm text-left">

                    <div className="pl-2 pb-0">
                    <span style={{ fontWeight: 700 }}>{fechaATexto(estado.createdAt)} · <a href={`https://google.com/maps?q=${lat},${lng}`} target="_blank"><i className="fas text-primary fa-map-marker-alt"></i></a></span>
                    <Title level={5} className="mb-0 mt-0" style={{ textTransform: "uppercase", fontWeight:900}}><FaCircle color={estado.estado_color} /> <b>{estado.estado}</b> <b>{estado.subestado}</b></Title>
                    </div>

                        <Paragraph className="mb-0"><b>Canal:</b> {canalEstadosString(estado.canal)}</Paragraph>
                        <Paragraph className="mb-0"><b>Por :</b> {estado.identificador_creador}</Paragraph>
                        {
                            mostrarMetaDatosSubEstado(estado.metadatos)
                        }
                        
                </div>

    return <div>
        <Card size="small">
        {label}
        {contenido}
        </Card>
    </div>
}

export default BoxEstado