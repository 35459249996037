import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Avatar, Card, Col, Pagination, Row, Table, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { fechaAHora, fechaATexto, fechaATextoSimple, formatYMD } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { AlertFilled, CheckCircleFilled } from "@ant-design/icons"

const ListadoRegistrosFotograficos = (props)=> {
    const {
        condicion_default
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const trash = props.trash ? props.trash : false
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/conductores/reporte-fotografico`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [])

    const handleChangePagina = (e) => {
        console.log(e)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination total={cantidad} defaultCurrent={pagina} onChange={handleChangePagina} />
        </div>
    }

    const mostrarRegistros = () => {
        if(loadingConductores) return <CargandoTabla />

        return <div>

            <Row>
                <Col md={3}>
                    <Card size="small" className="mb-3">
                        <Paragraph level={3} className="m-0">TOTAL</Paragraph>
                        <Title className="m-0">{total}</Title>
                    </Card>
                </Col>
            </Row>

            {paginacion(data.pagina, total)}
            <Row gutter={10}>
            {
                conductores.map(registro => {
                    return <Col xs={8} key={registro._id} className="mb-3" >
                        <Card size="small">
                        <Row>
                            <Col md={8}><Avatar size={70} shape="square" src={registro.url_imagen} /></Col>
                            <Col md={16}>
                            <p className="m-0" style={{ fontSize:10, lineHeight: -20 }} >{formatYMD(registro.fecha_local)}</p>
                            <p className="m-0" style={{ fontSize:10, lineHeight: -20 }} ><b>{fechaAHora(registro.fecha_local)}</b></p>
                            <Title level={5} style={{ fontSize:11 }} className="m-0">FIDELIDAD: <b>{registro.nivel_fidelidad.toFixed(0)}</b></Title>
                            {registro.estado_validacion === true ? <Title level={5} style={{ fontSize:11, color: "green" }} className="m-0"><CheckCircleFilled /> ACCESO CONCEDIDO</Title> : <Title level={5} style={{ fontSize:11, color: "red" }} className="m-0"><AlertFilled /> ACCESO DENEGADO</Title>}
                            </Col>
                        </Row>
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoRegistrosFotograficos