import Title from "antd/es/typography/Title"
import { useEffect, useState } from "react"
import { urlapi } from "../../lib/backend/data"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/sesion"
import { message } from "antd"
import CargandoTabla from "./cargando"
import ListadoDisponibilidadRecursos from "./listado"

const AsignacionOrden = (props) => {
    const {
        trip,
        autoSearch,
        id_orden_gestion
    } = props
    const dispatch                      = useDispatch()
    const session                       = useSelector(state => state.miusuario)
    const pais                          = useSelector(state => state.pais)
    const idioma                        = useSelector(state => state.idioma)
    const [messageApi, contextHolder]   = message.useMessage()
    const [ validating, setValidating ] = useState(false)
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const [ filtroBusquedaMetaData, setFiltroBusquedaMetaData ] = useState({})
    const key_requisitos_vehiculo        = "requisito_vehiculo"
    const key_requisitos_conductor       = "requisito_conductor"

    const consultarDatosImportantes = () => {
        if(!trip.paradas) return false
        if(Array.isArray(trip.paradas) !== true) return false
        if(trip.paradas.length < 1) return false
        if(!trip.viaje?.origen) return false
        const origen = trip.viaje?.origen
        validarDatos(origen, trip.paradas)
    }

    const obtenerDatosFiltro = () => {
        let condicion = {}
        let condicion_meta = {}
        if(trip.requisitos) if(Array.isArray(trip.requisitos)) if(trip.requisitos.length > 0){
            const requisitos_vehiculo = trip.requisitos.filter(re => re.tipo === "vehiculo")
            condicion[key_requisitos_vehiculo] = { $in: requisitos_vehiculo.map(e => e._id) }
            condicion_meta[key_requisitos_vehiculo] = requisitos_vehiculo.map(e => ({ _id: e._id, titulo: e.titulo }))
        }

        if(trip.requisitos) if(Array.isArray(trip.requisitos)) if(trip.requisitos.length > 0){
            const requisitos_conductor = trip.requisitos.filter(re => re.tipo === "conductor")
            condicion[key_requisitos_conductor] = { $in: requisitos_conductor.map(e => e._id) }
            condicion_meta[key_requisitos_conductor] = requisitos_conductor.map(e => ({ _id: e._id, titulo: e.titulo }))
        }
        setFiltroBusquedaMetaData(prev => ({ ...prev, ...condicion_meta}))
        setFiltroBusqueda(prev => ({ ...prev, ...condicion }))
    }

    const validarDatos = async (origin, puntos) => {
        setValidating(true)
        const url = `${urlapi}/disponibilidades/geocercas`
        return fetch(url, {
            method: "POST",
            body: JSON.stringify({
                origin, 
                puntos
            }),
            headers: {
                'Content-type': "application/json",
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(async res => {
            if(!res){
                messageApi.error("Sin datos obtenidos")
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
            } else if(res.geocerca_origin){
                if(Array.isArray(res.geocerca_origin)) if(res.geocerca_origin.length > 0) if(Array.isArray(res.geocercas_destino)) if(res.geocercas_destino.length > 0){

                    const condicion_meta = {
                        "ids_origin._id": res.geocerca_origin,
                        "ids_destination._id": res.geocercas_destino
                    }
                    const condicion_filtro = {
                        "ids_origin._id": { $in: res.geocerca_origin.map(e => e._id) },
                        "ids_destination._id": { $in: res.geocercas_destino.map(e => e._id) }
                    }

                    setFiltroBusquedaMetaData(prev => ({ ...prev, ...condicion_meta }))
                    setFiltroBusqueda(prev => ({ ...prev, ...condicion_filtro }))

                } else {
                    messageApi.error("No se pudo obtener la información necesaria")
                }
            }
            return setValidating(false)
        })
        .catch(error => {
            messageApi.error("No se pudo efectuar la operación")
            return setValidating(false)
        })

    }

    useEffect(() => {
        obtenerDatosFiltro()
        consultarDatosImportantes()
    }, [ trip ])


    const validarFormulario = () => {
        const sin_paradas               = <Title level={4} className="mt-0 mb-0">No hay paradas asignadas</Title>
        const sin_origen                = <Title level={4} className="mt-0 mb-0">Debes definir un origen </Title>
        if(!trip.paradas)               return sin_paradas
        if(trip.paradas.length < 1)     return sin_paradas
        if(!trip.viaje?.origen)         return sin_origen

        return <div>
            
        </div>

    }

    if(!trip) return <div></div>
    if(validating) return <CargandoTabla />
    if(Object.keys(filtroBusqueda).includes("ids_origin._id").length < 1) return <div></div>
    if(Object.keys(filtroBusqueda).includes("ids_destination._id").length < 1) return <div></div>

    return <div>
        {contextHolder}
        <ListadoDisponibilidadRecursos id_orden_gestion={id_orden_gestion} autoSearch={autoSearch} showFilters={true} condicion_filtro_meta={filtroBusquedaMetaData} condicion_filtro={filtroBusqueda} />
    </div>
}

export default AsignacionOrden;