import { getDaysInMonth } from "date-fns"

export const procesarDatosGraficosEstadisticaConductor = ordenes => {
    if(!ordenes) return { datos:[], colores:[], encabezados:[] }
    if(Array.isArray(ordenes) !== true) return { datos:[], colores:[], encabezados:[] }
    if(ordenes.length < 1) return { datos:[], colores:[], encabezados:[] }

    const primera_fecha = new Date(ordenes[0].createdAt)
    const dias = getDaysInMonth(new Date())
    const array_dias = Array.from(Array(dias).keys())
    console.log(array_dias)

    console.log({primera_fecha})

    let encabezados = ['Día','Operaciones']
    let colores = []
    let resultados_asignadas = [encabezados]
    let resultados_completadas = [encabezados]
    let resultados_retrasadas = [encabezados]
    for( const day of array_dias ){
        const num_dia = day+1
        const cantidad_asignada = ordenes.filter(o => {
            if(!o.createdAt) return false
            const dia = new Date(o.createdAt)
            const num = dia.getDate()
            return num === num_dia ? true : false
        }).length
        const cantidad_completada = ordenes.filter(o => {
            if(!o.fecha_finalizado) return false
            const dia = new Date(o.fecha_finalizado)
            const num = dia.getDate()
            return num === num_dia ? true : false
        }).length
        const hoy = new Date()
        const cantidad_rechazada = ordenes.filter(o => {
            const dia = new Date(o.fecha_finalizado)
            const num = dia.getDate()
            if(num !== num_dia) return false

            if(!o.fecha_max_entrega) return false
            if(o.finalizado === true){
                if(!o.fecha_finalizado) return false
                if(o.fecha_finalizado > o.fecha_max_entrega) return true
            }
            const fecha = new Date(o.fecha_max_entrega)
            if(fecha < hoy) return true
            return false
        }).length
        let dato = [ num_dia.toString(), cantidad_asignada ]
        let dato_completado = [ num_dia.toString(), cantidad_completada ]
        let dato_rechazado = [ num_dia.toString(), cantidad_rechazada ]
        resultados_asignadas.push(dato)
        resultados_completadas.push(dato_completado)
        resultados_retrasadas.push(dato_rechazado)
    }    
    return { asignadas: resultados_asignadas, completadas: resultados_completadas, rechazadas: resultados_retrasadas }
}

export const obtenerIdentificadorConductor = (driver) => {
    const default_texto = 'Conductor'
    let texto = ''
    if(!driver) return default_texto
    if(typeof driver !== 'object') return default_texto
    if(driver.nombres) texto = driver.nombres.toString()
    if(driver.apellido_p) texto = `${texto} ${driver.apellido_p}`
    if(driver.apellido_m) texto = `${texto} ${driver.apellido_m}`
    return texto
  }

  export const obtenerIdentificadorVehiculo = (driver) => {
    const default_texto = 'Conductor'
    let texto = ''
    if(!driver) return default_texto
    if(typeof driver !== 'object') return default_texto
    if(driver.detalle_modelo) texto = driver.detalle_modelo.toString()
    if(driver.marca) texto += ` ${driver.marca}`
    if(driver.ano) texto += ` ${driver.ano}`
    if(driver.patente) texto += ` · ${driver.patente}`
    return texto
  }