import Title from "antd/es/typography/Title"
import Structure from "../Structure"
import { Button, Card, Col, Row, Tabs } from 'antd';
import ListadoUsuarios from "./listado";
import { PlusCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import HelperModal from "../../subComponents/general/helperModal";
import { FaRegUser } from "react-icons/fa";

const Usuarios = (props) => {

    const items = [
        {
          key: '1',
          label: `ACTIVOS`,
          children: <ListadoUsuarios condicion_default={{ status: "active" }} />,
        },
        {
          key: '2',
          label: `INACTIVOS`,
          children: <ListadoUsuarios condicion_default={{ status: "inactive" }} />,
        },
        {
          key: '3',
          label: `SUSPENDIDOS`,
          children: <ListadoUsuarios condicion_default={{ status: "suspended" }} />,
        },
      ];

    const render = () => {
        return <div>
            <Card>
            <Title className="mb-2 mt-0"><FaRegUser /> Usuarios</Title>
            <Row gutter={15}>
              <Col span="auto"><Button size="small"><Link to={`/users/new`}><PlusCircleFilled /> CREAR NUEVO</Link></Button></Col>
              <Col span="auto"><HelperModal tipo="usuarios" style="modal" /></Col>
            </Row>
            
            
            <Tabs defaultActiveKey="1" items={items} />
            </Card>
        </div>
    }
    
    return <Structure component={render()} />
}

export default Usuarios