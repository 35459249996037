import { Button, Col, Row, Tooltip } from "antd"
import ButtonGroup from "antd/es/button/button-group"
import Title from "antd/es/typography/Title"
import { FaList, FaMapMarkerAlt } from "react-icons/fa"
import { MdOutlineGridView } from "react-icons/md"
import { Link } from "react-router-dom"
import { rutas } from "../../../lib/routes/routes"

const Navigation = (props) => {

    return <div>
        <Title level={4} style={{ marginTop:10 }} className="mb-0">Visualización</Title>
        <ButtonGroup className='mt-3'>
            <Tooltip title="Listado"><Link to={`${rutas.alerts.slug}`}><Button icon={<FaList />}></Button></Link></Tooltip>
            <Tooltip title="Mapa"><Link to={`${rutas.map_alerts.slug}`}><Button icon={<FaMapMarkerAlt />}></Button></Link></Tooltip>
        </ButtonGroup>
    </div>
}

export default Navigation