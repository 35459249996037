import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import { cerrarSesion } from "../../redux/actions/sesion"
import { Alert, Button, Card, Col, Divider, Form, Input, Layout, Modal, Pagination, Row, Select, Table, Tooltip, message } from "antd"
import CargandoTabla from "./cargando"
import Column from "antd/es/table/Column"
import Title from "antd/es/typography/Title"
import Paragraph from "antd/es/typography/Paragraph"
import { fechaUTCATexto } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { FileExcelFilled, PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import ContadoresVehiculos from "./contadores"
import { Esquema } from "../../lib/esquemas/tablas"
import SelectorColor from "./selectores/color"
import GraficosCamposPersonalizados from "../../subComponents/graficos/conductores"
import ModalPersonalizado from "../../subComponents/general/modales/modal_personalizado"
import { rutas } from "../../lib/routes/routes"
import SelectorGeneral from "../General/selector"
import { encontrarLlaveValorEnEsquema } from "../../lib/helpers/interfaz"
import { IoMdRemoveCircle } from "react-icons/io"
import { modificarInterfaz } from "../../redux/actions/interfaz"
import { TbHelpCircleFilled } from "react-icons/tb"
import PersonalizarColumnas from "../../subComponents/interfaz/columnas_personalizar"
import EncabezadoPesonalizado from "../../subComponents/general/tablas/encabezado"
import { GoDeviceMobile } from "react-icons/go"
import MostrarMoviles from "../../lib/helpers/mostrar_moviles"
import MostrarEmails from "../../lib/helpers/mostrar_emails"
import HelperModal from "../../subComponents/general/helperModal"
import BoxEstado from "./boxlist"
import SelectorGlobal from "../../subComponents/general/selector"

const ListadoClientes = (props)=> {
    const {
        condicion_default,
        showFilters,
        showCustomFieldsData,
        showCounters,
        hideSearch
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ conductores, setRegistros] = useState([])
    const [ loadingRegistros, setLoadingRegistros] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ messageApi, contextHolder] = message.useMessage();
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const pais = useSelector(state => state.pais)
    const trash                     = props.trash ? props.trash : false
    const idioma                    = useSelector(state => state.idioma)
    const interfaz_usuario          = useSelector(state => state.interfaz)
    const tipo                      = props.tipo ? props.tipo : "link"
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const titulo                    = props.title ? props.title : 'registros'
    const module = 'estadoscarga'
    const key_interfaz              = 'tabla_clientes'
    const key_esquema               = 'CLIENTES'
    const [ key_input_filter, setKeyInputFilter ] = useState(0)

    const activarDesactivarTabla = (value, column) => {
        const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario) )
        const i = instancia_esquema[key_interfaz].findIndex(e => e.key === column.llave)
        if(i > -1){
            instancia_esquema[key_interfaz][i].active = value
        }
        console.log({ instancia_esquema, i, column })
        return dispatch(modificarInterfaz(instancia_esquema, sesion.tokenSession))
    }

    const obtenerDetallesEsquema = (slug) => {
        const i = Esquema[ key_esquema ].findIndex(e => e.key === slug)
        if( i > -1){
            return {
                ...Esquema[ key_esquema ][i],
                llave: Esquema[ key_esquema ][i].key
            }
        }
        return {}
    }

    const obtenerDatos = async (page, query)=>{
        setLoadingRegistros(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${urlapi}/${module}/list-all`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                messageApi.error('Sin datos')
                return setLoadingRegistros(false)
            } else if(res.errorMessage){
                messageApi.error(res.errorMessage)
                return setLoadingRegistros(false)
            }
            if(Array.isArray(res.datos) !== false){
                setRegistros(res.datos)
                setTotal(res.total)
            }
            return setLoadingRegistros(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingRegistros(false)
        })
    }

    useEffect(() => {
        obtenerDatos(1, false)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerDatos(e, condicion_busqueda)
    }
    
    const refrescarBusqueda = () => {
        setPagina(1)
        let cond = {}
        Object.keys(filtroBusqueda).map(llave => {
            if(filtroBusqueda[llave]['$in']){
                if(Array.isArray(filtroBusqueda[llave]['$in']) !== false){
                    if(filtroBusqueda[llave]['$in'].length > 0) cond[llave] = filtroBusqueda[llave]
                }
            }
        })
        obtenerDatos(1, cond)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            {/* <Row gutter={15}>
                <Col span="auto"><PersonalizarColumnas key_interfaz={key_interfaz} key_esquema={key_esquema} /></Col>
                <Col span="auto"><Button loading={loadingExcel} style={{ marginRight: 10, marginBottom: 15 }} color="green"  onClick={() => descargarExcel()}><FileExcelFilled /> EXPORTAR</Button></Col>
            </Row> */}
            
            <Title level={5} className="mt-0" >TOTAL <b style={{ fontWeight: "bold" }}>{total}</b></Title>
            <Pagination current={pagina} total={(cantidad*10)} showSizeChanger={false} onChange={handleChangePagina} />
        </div>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/clients`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            messageApi.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda){
            setPagina(1)
            setCondicionBusqueda({})
            return obtenerDatos(1, {})
        }
        const condicion = {
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerDatos(1, condicion);
      };

      const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };

      
    const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmitCapture={handleSubmit} >
        {/* <Title className="mb-0 mt-0" level={5}>BUSCAR</Title> */}
        <Row gutter={10}>
            <Col md={6}>
                <Form.Item className="mb-2">
                    <Input defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Razón social o identificador fiscal`} />
                </Form.Item>
            </Col>
            <Col md={4}>
                <Form.Item >
                    <Button  onClick={handleSubmit} style={{ width: "100%"}}><SearchOutlined /> BUSCAR</Button>
                </Form.Item>
            </Col>
        </Row>
                </Form>
      }



      const mostrarTabla = () => {
        if(loadingRegistros) return <CargandoTabla />

          return <div>
            <Row gutter={15}>
            {
                conductores.map((registro, index) => {
                    return <Col sm={12} xs={24} key={`registro-${index}`} className="mb-3">
                        <BoxEstado estado={registro} />
                        </Col>
                })
            }
            </Row>
          </div>
      }

      const agregarFiltro = (key, value) => {
        if(!value){
            delete filtroBusqueda[key]
        } else if(Array.isArray(value) !== false){
            if(value.length < 1){
                delete filtroBusqueda[key]
            } else {
                filtroBusqueda[key] = { $in: value.map(v => v._id) }
            }
        } else {
            filtroBusqueda[key] = value
        }
        setFiltroBusqueda(filtroBusqueda)
      }

      const resetFilters = () => {
        setFiltroBusqueda({})
        setKeyInputFilter(key_input_filter + 1)
        obtenerDatos(1, condicion_default ? condicion_default : {})
    }

    const handleChange = (data) => {
        let valor = false

        if(data === true){
            valor = { $in: [true] }
        } else if(data === false){
            valor = { $in: [false] }
        }

        agregarFiltro('notificar_planel_flota', valor)
    }

      const mostrarFiltrosBusqueda = () => {
        const mostrarButtonReset = Object.keys(filtroBusqueda).length > 0 ? true : false
        return <div style={{ marginBottom: 20 }} className="mt-0">
            <Form layout="vertical" >
            <Row gutter={10}>
                <Col md={6} className="mb-3">
                    <SelectorGlobal key={key_input_filter} fields_search={[ "nombres","apellido_m","apellido_p" ]} titulo="Conductor" condicion={{ }} isMulti={true} module="conductores" key_label="nombres" key_value="_id" onChange={(data) => agregarFiltro('id_creador', data)} />
                </Col>
                <Col md={6} className="mb-3">
                    {/* <SelectorGlobal key={key_input_filter} fields_search={[ "nombres","apellido_m","apellido_p" ]} titulo="Conductor" condicion={{ }} isMulti={true} module="conductores" key_label="nombres" key_value="_id" onChange={(data) => agregarFiltro('id_creador', data)} /> */}
                    <Form.Item label="Auditoria"><Select options={[
                        { value: "all", label: "Cualquiera" },
                        { value: false, label: "Solo eventos regulares" },
                        { value: true, label: "Solo eventos para auditar" },
                    ]} onChange={handleChange} /> 
                    </Form.Item>
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mt-0" level={5}>Filtrar</Title>
                    <Button loading={loadingRegistros} onClick={() => refrescarBusqueda()} style={{ width: "100%" }} icon={<SearchOutlined />} >ACTUALIZAR</Button>
                </Col>
                <Col md={6} className="mb-3">
                    <Title className="mb-2 mt-0" level={5}>FILTRAR</Title>
                    <Button disabled={!mostrarButtonReset}  style={{ width: "100%" }} onClick={() => resetFilters()}>BORRAR FILTROS</Button>
                </Col>
            </Row>
            </Form>
        </div>
      }
      

    const mostrarRegistros = () => {

        return <div>
            <Divider />
            {
                showFilters === true ? <Row gutter={15}>
                <Col md={24}>
                    {mostrarFiltrosBusqueda()}
                    {/* {buscador()} */}
                </Col>
                <Col md={24}>
                    <Row gutter={15} className="mb-3">
                    </Row>
                    {paginacion(data.pagina, total)}
                    {mostrarTabla()}
                    {paginacion(data.pagina, total)}
                </Col>
            </Row> : <div>
                    {/* {buscador()} */}
                    {paginacion(data.pagina, total)}
                    {mostrarTabla()}
                    {paginacion(data.pagina, total)}
                </div>
            }
            
            
        </div>
    }

    return <div>
        {contextHolder}
        {mostrarRegistros()}
    </div>
}
export default ListadoClientes